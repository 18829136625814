import {amf} from "../../emilkm-amfjs/amf";
// import {connect} from "./FetchConnectionHandler";
// import {connect} from "./AxiosConnectionHandler";
import {connect} from "./connection.handlers/XHRConncectionHandler";
import {injectable} from "inversify";
import {ResponseResultDTO} from "../dto/ResponseResultDTO";
import {environment} from "../../environments/environment";


@injectable()
export class AMFConnectionBroker {
	private readonly endpoint = environment.endpoint;
	
	constructor() {
	
	}
	
	call(targetURI: string, data: Array<any>) {
		console.log(`AMFConnectionBroker.call2: ${targetURI}: ${data}`);
		const serResult = this.generatePacket(targetURI, data);
		const buffer = new Uint8Array(serResult).buffer;
		return connect("POST", this.endpoint, buffer)
			.then(
				arrayBuffer => {
					try {
						const deserializer = new amf.Deserializer(new Uint8Array(arrayBuffer));
						// let message: amf.ActionMessage, body, deserializer = new amf.Deserializer(new Uint8Array(arrayBuffer));
						const message: amf.ActionMessage = deserializer.readMessage();
						const body0data: ResponseResultDTO = message.bodies[0].data;
						return body0data;
					} catch (error) {
						// throw error parsing result AMF message
						const err: IBrokerError = {id: BrokerErrors.PARSING, message: "Error parsing AMF message: " + error};
						throw err;
					}
				},
				error => {
					// throw error connection to service
					throw {id: BrokerErrors.CONNECTION, message: "Error connecting"};
				});
	}
	
	public registerDTO(name: string, class1: any) {
		amf.registerClass(name, class1);
	}
	
	private generatePacket(targetURI, data) {
		const amf2 = amf;
		const messageBody = new amf2.MessageBody();
		messageBody.targetURI = targetURI;
		messageBody.responseURI = "/1";
		messageBody.data = data;
		
		const actionMessage = new amf2.ActionMessage();
		actionMessage.version = 3;
		actionMessage.headers = [];
		actionMessage.bodies.push(messageBody);
		
		const ser = new amf2.Serializer();
		const serResult = ser.writeMessage(actionMessage);
		
		return serResult;
	}
	
	
}

export enum BrokerErrors {
	PARSING,
	CONNECTION
}

export interface IBrokerError {
	id: number;
	message: string;
}

// {
//   "code": "SERVER.PROCESSING",
//   "level": "error",
//   "description": "Could not find a suitable method with name Cgm",
//   "details": "   at FluorineFx.MethodHandler.GetMethod(Type type, String methodName, IList arguments, Boolean exactMatch, Boolean throwException, Boolean traceError)\r\n   at FluorineFx.Messaging.Endpoints.Filter.ProcessFilter.Invoke(AMFContext context)",
//   "type": "FluorineFx.Exceptions.FluorineException"
// }