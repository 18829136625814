import {Quaternion, Vector3} from "three";
import {TileDimensions} from "./TileDimensions";

export class TilePositionTransform {
	
	private static concealedPositions: ConcealedPositions;
	
	public static get CONCEALED() {
		if (!TilePositionTransform.concealedPositions) {
			TilePositionTransform.concealedPositions = new ConcealedPositions();
		}
		return TilePositionTransform.concealedPositions;
	}
}

class ConcealedPositions {
	public readonly NORMAL = new TilePosition(
		new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), 0 * 3.14 / 180),
		new Vector3(),
		TileDimensions.TW + TileDimensions.TGAP
	);
	public readonly OPENED = new TilePosition(
		new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -90 * 3.14 / 180),
		new Vector3(0, TileDimensions.TD, 0),
		TileDimensions.TW + TileDimensions.TGAP
	);
	public readonly TURNED_BACK = new TilePosition(
		new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), 90 * 3.14 / 180),
		new Vector3(0, 0, -TileDimensions.TH),
		TileDimensions.TW + TileDimensions.TGAP
	);
	public readonly OPENED_LEFT = new TilePosition(
		new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -90 * 3.14 / 180)
			.multiply(
				new Quaternion().setFromAxisAngle(new Vector3(0, 0, 1), 90 * 3.14 / 180)
			),
		// new Vector3(0, 0, TileDimensions.TH * .5 - TileDimensions.TW * .5),
		new Vector3(TileDimensions.TH, TileDimensions.TD, 0),
		TileDimensions.TH + TileDimensions.TGAP
	);
	public readonly OPENED_RIGHT = new TilePosition(
		new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -90 * 3.14 / 180)
			.multiply(
				new Quaternion().setFromAxisAngle(new Vector3(0, 0, 1), -90 * 3.14 / 180)
			),
		// new Vector3(0, 0, TileDimensions.TH * .5 - TileDimensions.TW * .5),
		new Vector3(0, TileDimensions.TD, -TileDimensions.TW),
		TileDimensions.TH + TileDimensions.TGAP
	);
	
	constructor() {
	
	}
}

export class TilePosition {
	constructor(public readonly rotation: Quaternion, public readonly position, public readonly xw: number) {
	
	}
}
