import {Component, EventEmitter, Input, Output} from "@angular/core";
import {OnlineGameDTO} from "../../../../net/dto/OnlineGameDTO";
import {GameState} from "../../../../game/enums/GameState";
import {GameType} from "../../../../game/enums/GameType";

@Component({
	selector: "table-card",
	template: `
       <div class="game-card" (click)="onAction()"><!--(click)="onAction('join')"-->
           <div>
               <img class="iconx"
                    src="https://www.androidfreeware.net/img2/mahjong-solitaire-classic.jpg">
           </div>
           <div class="bodyx noPointer">
               <span class="mat-card-title" style="padding: 0px 8px 0px 0px">{{table.Name}}</span>
               <div *ngIf="showGameType" class="s-row" style="display: inline-flex; justify-content: flex-start; align-items: baseline;">
                   <span class="mat-card-subtitle">Rules: </span>
                   <span class="mat-card-subtitle stateArea" style="font-size: 75%"
                   >{{gameRules}}</span>
               </div>
               <div class="s-row" style="display: inline-flex; justify-content: flex-start; align-items: baseline;">
                   <span class="mat-card-subtitle">State: </span>
                   <span class="mat-card-subtitle stateArea" style="font-size: 75%"
                         [ngClass]="getStateClass(table)"
                   >{{table.StateId | gameState}}: {{table.StateId}}</span>
               </div>
               <!--<span class="mat-card-subtitle">Short description</span>-->
               <span style="flex-grow: 100"></span>

               <div id="actions" class="hasPointer">
                   <span class="material-icons icon" matTooltip="Move time limit">timer</span>
                   <span class="icon-label">8s</span>

                   <span class="material-icons icon" matTooltip="Rounds">sync</span>
                   <span class="icon-label" matTooltip="QuickMahjongTM">QM</span>

                   <!-- <i class="material-icons icon" matTooltip="Rounds">settings</i>
						  <span class="icon-label" matTooltip="1asda22sd ad ">2</span>
		 
						  <span class="material-icons icon" matTooltip="Rounds">remove_red_eye</span>
						  <span class="icon-label" matTooltip="Rounds: 24">24</span>
		 
						  <span class="material-icons icon" matTooltip="Rounds">timer</span>
						  <span class="icon-label" matTooltip="Rounds: 24">24</span>
		  -->

                   <span style="flex: 10"></span>
                   <span class="material-icons icon"
                         style="color: #7da01f; cursor: pointer"
                         (click)="onAction()">
							  {{getTableActionIcon(table)}}</span>


                   <!--<mat-icon svgIcon="open-in-new"></mat-icon>-->
               </div>
           </div>
       </div>`,
	styleUrls: ["./table-card.component.scss"]
})
export class TableCardComponent {
	
	@Input()
	showGameType = false;
	@Input()
	public table: OnlineGameDTO;
	
	@Output()
	actionEmitter = new EventEmitter<{ action: TableCardAction, table: OnlineGameDTO }>();
	
	constructor() {
	
	}
	
	public getStateClass(table: OnlineGameDTO): string {
		switch (table.StateId) {
			case GameState.EMPTY:
				return "stateArea";
			case GameState.WAITING:
			case GameState.READY_TO_START:
				return "stateAreaWelcome";
			case GameState.PLAYING:
				return "stateAreaInGame";
			default:
				return "stateArea";
			
		}
	}
	
	public getTableAction(table: OnlineGameDTO): TableCardAction {
		if (table.StateId < GameState.PLAYING) {
			return "join";
		}
		if ((table.StateId === GameState.PLAYING || table.StateId === GameState.PAUSED) && table.IsMyGame) {
			return "join";
		}
		if (table.StateId > GameState.PLAYING) {
			return "watch";
		}
		return "noAction";
	}
	
	public getTableActionIcon(table: OnlineGameDTO): string {
		const action = this.getTableAction(table);
		switch (action) {
			case "join":
				return "arrow_forward";
			case "watch":
			case "review":
				return "remove_red_eye";
			default:
				return "";
		}
	}
	
	get gameRules() {
		return this.table?.GameTypeId ? GameType[this.table.GameTypeId] : "unknown";
	}
	
	public onAction(action?: TableCardAction) {
		this.actionEmitter.emit({action: this.getTableAction(this.table), table: this.table});
		
	}
	
}


export type TableCardAction = "join" | "watch" | "review" | "noAction";

