import {Object3D} from "three";

export class InputStreamEvent {
	
	constructor(public type: InputActionType, public payload: IInputKeyPressed | IInputMouseEvent) {
	}
	
	toString(): string {
		return `[InputStreamEvent type=${this.type}, payload=` + this.payload + "]";
	}
}

export enum InputActionType {
	Keyboard,
	Mouse,
}

export enum InputMouseEventType {
	CLICK,
	
	DRAG_START,
	DRAG_MOVE,
	DRAG_END,
	
	ROLL_OVER,
	MOVE_OVER,
	ROLL_OUT,
}

export interface IInputKeyPressed {
	keyCode: number;
}

export interface IInputMouseEvent {
	type: InputMouseEventType;
	target?: Object3D;
	point?: { x: number, y: number };
	mouseEvent?: MouseEvent;
	clickCount?: number;
}
