import {FlashUIDTO} from "./FlashUIDTO";

export class UserSettingsDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.UserSettings"
	public UserId: number;
	public LanguageId: number;
	public RatingId: number;
	public AcceptInvHk: boolean;
	public AcceptInvCo: boolean;
	public AcceptInvAm: boolean;
	public AcceptInvAs: boolean;
	public AcceptInvRcr: boolean;
	public AcceptInvEc: boolean;
	public AcceptInvTw: boolean;
	public AcceptInvWs: boolean;
	public AcceptInvJm: boolean;
	public AcceptInvSm: boolean;
	public AcceptInvCash: boolean;
	public CreateWebPage: boolean;
	public ChatColour: number;
	//public SoundVolume: number;
	//public VoiceVolume: number;
	public TimeBankSec: number;
	public IsShowEmail: boolean;
	//0 - None, 1- Old, 2 - Eon
	public AppStyle: number;
	public GameBackground: string;
	public JpLangAnimation: boolean;
	
	public AutoPassMode: number;
	
	public FlashUI: FlashUIDTO;
}
