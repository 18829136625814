import {Entity} from "ecsy";
import {InHandIndexComponent, TilePositionComponent, TileRotationComponent} from "../ecs/common/components";
import * as THREE from "three";
import {Vector3} from "three";
import {TilePositionTransform} from "../TilePositionTransform";
import {TileDimensions} from "../TileDimensions";
import {TileSetHelper} from "./TileSetHelper";
import {TileEntityHelper} from "./TileEntityHelper";
import {TileWrapper} from "../ecs/wrappers/TileWrapper";

export class WallLayoutHelper {
	
	private static startPoint = new THREE.Vector3(-170, 0, 170);
	public static sideTransforms: object = {
		0: {quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 0)},
		1: {quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 90 * 3.14 / 180)},
		2: {quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 180 * 3.14 / 180)},
		3: {quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 270 * 3.14 / 180)}
	};
	
	public static updateTilesPositions(concealedTiles: Array<Entity>, singleWallLength: number) {
		concealedTiles.forEach((tileEntity, index) => {
			this.updateTilePosition(tileEntity, singleWallLength);
		});
	}
	
	public static updateFirstTilePosition(wallTiles: Array<Entity>, singleWallLength: number): void {
		const tile = TileSetHelper.getFirstTile(wallTiles);
		if (tile) {
			this.updateTilePosition(tile, singleWallLength);
		}
	}
	
	public static updateLastTilePosition(wallTiles: Array<Entity>, singleWallLength: number): void {
		const tile = TileSetHelper.getLastTile(wallTiles);
		if (tile) {
			this.updateTilePosition(tile, singleWallLength);
		}
	}
	
	private static updateTilePosition(tileEntity: Entity, singleWallLength: number): void {
		const tileWrap = TileWrapper.wrap(tileEntity);
		const inHandIndex = tileEntity.getComponent(InHandIndexComponent).inHandIndex;
		
		const side = Math.trunc(inHandIndex / (singleWallLength * 2));
		let rowIndex = (inHandIndex + 1) % 2; // +1 so the first row will be on top and tiles will be taken in top-bottom-top-.. order
		if (TileEntityHelper.isLastTile(tileEntity) && rowIndex % 2 === 1) {
			rowIndex = 0; // force move last tile down if we removed lower tile from the end of the wall
		}
		const columnIndex = Math.trunc(inHandIndex / 2 % (singleWallLength));
		
		const tilePosAdj = tileWrap.tinyId < 2 ? TilePositionTransform.CONCEALED.TURNED_BACK : TilePositionTransform.CONCEALED.OPENED;
		const newPos = WallLayoutHelper.startPoint.clone()
			.add(tilePosAdj.position)
			.add(new Vector3(TileDimensions.TWG * columnIndex, rowIndex * TileDimensions.TDG, TileDimensions.THG))
			.applyQuaternion(this.sideTransforms[side].quaternion); // apply player side rotation
		
		const oldPos = tileEntity.getComponent(TilePositionComponent).position;
		
		if (!this.isPositionsEqual(newPos, oldPos)) {
			tileEntity.getMutableComponent(TilePositionComponent).position = newPos;
		}
		
		// TODO: add check if rotation has actually changed ?
		tileEntity.getMutableComponent(TileRotationComponent).rotation =
			this.sideTransforms[side].quaternion.clone()
				.multiply(tilePosAdj.rotation);
	}
	
	private static isPositionsEqual(p1: THREE.Vector3, p2: THREE.Vector3, delta: number = 1): boolean {
		return Math.abs(p1.x - p2.x) < delta
			&& Math.abs(p1.y - p2.y) < delta
			&& Math.abs(p1.z - p2.z) < delta;
	}
	
}

