import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { TYPES } from "../../../../../inversify/inversify.types";
import { GameEvent, GameEventsPipe, GameEventType, IDisplayPlayerInfoData } from "../../../../../game/GameEventsPipe";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GameService } from "../../../../../net/service/GameService";
import { UserStore } from "../../../../../store/UserStore";
import { GameStore } from "../../../../../store/GameStore";
import container from "../../../../../inversify/inversify.config";
import { EndGameDialogComponent, IEndGameDialogData } from "./EndGameDialogComponent";
import { IGameDialogConfig, IGameDialogSubmitData, InGameDialogComponent } from "./InGameDialogComponent";
import { GameEventsService } from "../../../../services/GameEventsService";
import { GameUIService } from "../../../../services/GameUIService";
import { AppEventsPipe } from "../../../../../game/AppEventsPipe";
import { AppEventsService } from "../../../../services/AppEventsService";
import { IMoveTimerUpdatedParams } from "../../../../../game/ecs/TimerSystem";
import { IDeactivatable } from "../../../IDeactivatable";
import { GameType } from "../../../../../game/enums/GameType";
import { AutoPassMode } from "../../../../../game/enums/AutoPassMode";
import { autorun, IReactionDisposer } from "mobx";
import { TranslateService } from "@ngx-translate/core";
import { UIActionMenu, UIActionMenuItem } from "./UIActionMenu";
import { MatMenuTrigger } from "@angular/material/menu";
import { QuickSeatService } from "../../../../services/QuickSeatService";
import { NavigationService } from "../../../../services/navigation.service";
import { ChangeDetectorRef, NgZone } from '@angular/core';

type APMode = { id: AutoPassMode; icon: "next_plan" | "health_and_safety"; toolTip: string; };
type Sounds = { enabled: boolean; iconName: string; toolTip: string; };

@Component({
	selector: "app-ingame",
	templateUrl: "./ingame.component.html",
	styleUrls: ["./ingame.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IngameComponent implements OnInit, OnDestroy, IDeactivatable {
	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
	// @ViewChild("actM") myElement: ElementRef;

	private destroy$: Subject<boolean> = new Subject<boolean>();
	private appEvents: AppEventsPipe;
	private gameEvents: GameEventsPipe;
	private gameService: GameService;
	private gameStore: GameStore;
	private userStore: UserStore;
	private navigationRequested = false;
	private reactDisposers: Array<IReactionDisposer> = [];
	private initialViewportHeight: number = window.innerHeight;
	public mainContainerHeight: string = '100vh';
	public bottomRightBoxPosition: string = '0.5rem';


	isMobileLayout: boolean = false;

	@Input()
	public actionMenu: UIActionMenu;

	private gameDialogs: Map<string, MatDialogRef<any, any>>
		= new Map<string, MatDialogRef<any, any>>();

	private disposers: IReactionDisposer[] = [];

	@Input()
	public autoPassMode: APMode;
	@Input()
	public sounds: Sounds;
	@Input()
	public playerInfos: any[];

	constructor(
		private renderer: Renderer2,
		private _snackBar: MatSnackBar,
		public dialog: MatDialog,
		public appEventsService: AppEventsService,
		public gameEventsService: GameEventsService,
		private gameUIService: GameUIService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService,
		private navigationService: NavigationService,
		private qsService: QuickSeatService,
		
	) {
		this.appEvents = container.get(TYPES.AppEventsPipe);
		this.gameEvents = container.get(TYPES.GameEventsPipe);
		this.gameService = container.get(TYPES.GameService);
		this.gameStore = container.get(TYPES.GameStore);
		this.userStore = container.get(TYPES.UserStore);

		this.disposers.push(autorun(() => {
			const apMode = this.gameStore.autoPassOption;
			this.autoPassMode = {
				id: apMode,
				icon: apMode === AutoPassMode.DEFAULT ? "health_and_safety" : "next_plan",
				toolTip: this.translate.instant("Game.AutoPass.PassAll") + ": " + (apMode === AutoPassMode.DEFAULT ? "OFF" : "ON"),
			};
		}));

		this.disposers.push(autorun(() => {
			const enabled = this.userStore.settings.sndMaster;
			this.sounds = {
				enabled,
				iconName: enabled ? "volume_up" : "volume_off",
				toolTip: this.translate.instant("Lobby.MainMenu.speakerToolTip"),
			};
		}));
	}

	// This method will be called when the window is resized


	ngOnInit(): void {
		this.gameEvents.events
			.pipe(takeUntil(this.destroy$))
			.subscribe(event => this.onGameEvent(event));
		this.gameEventsService.moveTimer$
			.pipe(takeUntil(this.destroy$))
			.subscribe(data => this.onMoveTimerUpdated(data));
		this.addReactions();
		console.log(`Initial viewport height: ${this.initialViewportHeight}`);
		//this.initialViewportHeight = window.innerHeight;
		this.initialViewportHeight = window.innerHeight;
	}


	ngOnDestroy(): void {
		try {
			this.removeReactions();
			this.destroy$.next(true);
			this.destroy$.complete();
			// remove all opened game dialogs
			try {
				this.gameDialogs.forEach(dialogRef => dialogRef.close({ dialog: dialogRef.componentInstance.dialogConfig }));
			}
			catch (e) {
				console.error("IngameComponent.ngOnDestroy: something went wrong while removing in-game dialogs" + e);
			}
			this.disposers.forEach(d => d());
		}
		catch (e) {
			console.error("IngameComponent.ngOnDestroy: " + e);
		}
	}

	@HostListener('window:resize', ['$event'])
	onWindowResize(event: Event) {
		// This should be called whenever the window is resized
		console.log('Window resize event triggered');
		this.handleResize();
	}

	@HostListener('document:focusout', ['$event'])
	onInputBlur(event: FocusEvent) {
	  // Logic to revert UI adjustments when keyboard disappears
		console.log('Input blurred, revert UI adjustments');
		this.handleResize();
	}

	private handleResize() {
		const currentViewportHeight = window.innerHeight;
		console.log(`Current viewport height: ${currentViewportHeight}`);
		// Logic to determine if the keyboard was closed based on height comparison
		if (currentViewportHeight > this.initialViewportHeight + 150) {
			// Assuming the keyboard was closed, adjust layout accordingly
			console.log(`adjustLayoutForClosedKeyboard - Current viewport height: ${currentViewportHeight}`);
			this.adjustLayoutForClosedKeyboard();
		}
		// Update the initialViewportHeight with the current height for future comparisons
		this.initialViewportHeight = currentViewportHeight;
		this.updateViewportScale(1); 
	}

	private updateViewportScale(scale: number): void {
		const viewportMetaTag = this.renderer.selectRootElement('meta[name="viewport"]', true);
		this.renderer.setAttribute(
		  viewportMetaTag,
		  'content',
		  `width=device-width, initial-scale=${scale}, maximum-scale=${scale}, user-scalable=no`
		);
	  
		// Log the updated scale value to the console
		console.log(`Viewport scale updated to: ${scale}`);
	  }

	private adjustLayoutForClosedKeyboard() {
		this.updateViewportScale(1);
	  
		// Log the updated scale value to the console
		console.log(`adjustLayoutForClosedKeyboard`);
			this.cd.detectChanges(); // Trigger change detection to apply the changes
		

		console.log('Layout adjusted for closed keyboard.');
	}

	canDeactivate(): boolean {
		if (!this.gameStore.joinedGame) {
			return true;
		}
		if (!this.navigationRequested) {
			this.doLeaveGame();
		}
		return this.navigationRequested;
	}

	private addReactions() {
		this.removeReactions();

		const disposer: IReactionDisposer = autorun(
			() => {
				console.log("IngameComponent.update player-info-bars: ");
				this.playerInfos = this.gameStore.gameUsers.players.map<IDisplayPlayerInfoData>(player => ({
					id: player.UserId,
					name: player.Name,
					realside: player.Side,
					location: player.Location,
					points: player.Points
				}));
			},
			{
				name: "IngameComponent.PlayerContOrSide_changed",
				delay: 100,
			});
		this.reactDisposers.push(disposer);
	}

	private removeReactions() {
		if (this.reactDisposers) {
			this.reactDisposers.forEach(disposer => disposer());
		}
		this.reactDisposers = [];
	}


	public openTableResultsDialog(data: IEndGameDialogData): void {

		const dialogRef = this.dialog.open(EndGameDialogComponent, {
			disableClose: true,
			data,
		});
		this.gameDialogs.set("TableResultsDialog", dialogRef);

		dialogRef.afterClosed().subscribe((result) => {
			if (this.gameDialogs.has("TableResultsDialog")) {
				this.gameDialogs.delete("TableResultsDialog");
			}
			this.processActionResult(result);
		});
	}

	processActionResult(result): void {
		console.log(`processActionResult.TableResultsDialog: ${result}`);
		switch (result) {
			case "playAgain":
				this.navigationRequested = true;
				// commander.executeCommand<SomeResult<OnlineGameDTO>>(Commands.PLAY_QUICK_AGAIN);
				// this.qsService.startSearch(this.navigationService.routerGameType);
				this.qsService.playAgain()
					.catch();
				break;
			case "exit":

				break;
			case "close":
			default:

		}
	}

	private onGameEvent(event: GameEvent): void {
		try {
			switch (event.type) {
				case GameEventType.DealEnded:
					this.openTableResultsDialog(event.data);
					break;
				case GameEventType.DealEnded_RemovePopup:
					if (this.gameDialogs.has("TableResultsDialog")) {
						this.gameDialogs.get("TableResultsDialog")?.close();
					}
					break;
				case GameEventType.Display_GameDialog:
					this.displayGamePopup(event.data);
					break;
				case GameEventType.Remove_GameDialog:
					this.removeGamePopup(event.data);
					break;
				case GameEventType.UIActionMenu_Show:
					this.actionMenu = event.data;
					this.actionMenu.items?.map(item => {
						item.label = item.labelResourceKey ? this.translate.instant(item.labelResourceKey) : item.label;
						return item;
					});
					if (this.trigger.menuOpen) {
						this.trigger.closeMenu();
					}
					this.cd.markForCheck(); // force update UI so the trigger position changes, and only after this open menu
					this.trigger.menuData = this.actionMenu.items;
					this.trigger.menu.focusFirstItem();
					this.trigger.openMenu();
					break;
				case GameEventType.UIActionMenu_Remove:
					this.trigger.closeMenu();
					this.actionMenu = null;
					break;
				case GameEventType.MoveTimer_Updated:
					console.log("IngameComponent.onGameEvent: MoveTimer_Updated");
					break;
			}
		}
		catch (e) {
			console.error(`IngameComponent.onGameEvent: event=${event?.type} -- ` + e);
		}
	}

	private doLeaveGame() {
		this.gameUIService.doLeave().pipe(takeUntil(this.destroy$)).subscribe(result => {
			this.navigationRequested = (result === "leave");
		});
	}

	public removeGamePopup(options: IGameDialogConfig): void {
		console.log("IngameComponent.removeGamePopup: " + options.id);
		if (this.gameDialogs.has(options.id)) {
			const exDialog = this.gameDialogs.get(options.id);
			this.gameDialogs.delete(options.id);
			exDialog?.close();
		}
	}

	public displayGamePopup(options: IGameDialogConfig) {
		console.log("IngameComponent.displayGamePopup: " + options?.id);
		if (this.gameDialogs.has(options.id)) {
			const exDialog = this.gameDialogs.get(options.id);
			exDialog.componentInstance?.setDialogData(options);
		}
		else {
			const dialogRef: MatDialogRef<InGameDialogComponent, IGameDialogSubmitData> = this.dialog.open(InGameDialogComponent, {
				disableClose: true,
				hasBackdrop: false,
				data: options
			});
			this.gameDialogs.set(options.id, dialogRef);
			dialogRef.afterClosed().subscribe((result) => {
				console.log("InGame dialog was closed, result=", result, ", ref=" + dialogRef);
				if (result) {
					this.removeGamePopup({ id: result.dialog.id });  // remove dialog
					this.sendGameEvent(GameEventType.GameDialog_Submit, result);
				}
			});
		}
	}

	private onMoveTimerUpdated(params: IMoveTimerUpdatedParams): void {
		if (!params) {
			console.warn("IngameComponent.onMoveTimerUpdated: empty params");
			return;
		}
		console.log("IngameComponent.onMoveTimerUpdated: secondsLeft=" + params.secondsLeft);
		if (this.userStore.settings.sndMaster && params.isMyTurn && params.secondsLeft === 1) {
			const audio = new Audio("assets/sounds/game/Beep_Timer.mp3");
			audio.play();
		}
	}

	private sendGameEvent(type: GameEventType, data?: unknown): void {
		this.gameEvents.send(type, data);
	}

	/*getMoveNowString(): string {
		return this.gameInfo.moveNowType === MoveNowType.SLOT ? "Slot" : this.gameInfo.moveNowPlayerName;
	}*/

	get hasAutoPassOption(): boolean {
		return this.gameUIService.joinedGame?.GameTypeId === GameType.WP ?? false;
	}

	onAutoPassChange() {
		this.gameStore.setAutoPassMode(this.autoPassMode.id === AutoPassMode.DEFAULT ? AutoPassMode.IGNORE_JOKER : AutoPassMode.DEFAULT);
	}

	onSortTiles() {
		this.gameEvents.send(GameEventType.UI_SortTiles);
	}

	onLeaveGame() {
		this.doLeaveGame();
	}

	onSoundToggle() {
		this.userStore.setSettings({ sndMaster: !this.userStore.settings.sndMaster });
	}

	onActionMenuItemClick(item: UIActionMenuItem, actionMenu: UIActionMenu) {
		this.gameEvents.send(GameEventType.UIActionMenu_Clicked, { menu: actionMenu, item });
	}
}
