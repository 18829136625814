import {inject, injectable} from "inversify";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {GameStore} from "../store/GameStore";
import {GameService} from "../net/service/GameService";
import {Subject} from "rxjs";
import {ChatMessageDTO} from "../net/dto/ChatMessageDTO";
import {GameMessageDTO} from "../net/dto/GameMessageDTO";
import {ChatService} from "../net/service/ChatService";
import {AppStore} from "../store/AppStore";
import {OnlineGameDTO} from "../net/dto/OnlineGameDTO";
import {getLimitedArray} from "../utils/limited-array";
import {PrivateChatMessageDTO} from "../net/dto/PrivateChatMessageDTO";
import Timeout = NodeJS.Timeout;

@injectable()
export class ChatGamController {
	
	private requestingIsActive = false;
	protected joinedChatId: number;
	public publicMessages$: Subject<ChatMessageDTO[]>;
	public privateMessages$: Subject<PrivateChatMessageDTO[]>;
	public gameMessages$: Subject<GameMessageDTO[]>;
	public readonly gameMessagesAvailable$: Subject<number>;
	
	public allPublicMessages$: Subject<ChatMessageDTO[]>;
	
	private readonly MAX_PUBLIC_MESSAGES = 10;
	private readonly publicMessagesStack: ChatMessageDTO[];
	// Accumulate game messages from joining to game chat (stat requesting gam()) till game initializes and call takeGameMessages()
	private readonly gameMessagesAcc: GameMessageDTO[];
	
	public requestType: "chat" | "game";
	public requestTimeout: Timeout;
	
	private chatId: number;
	private chatPeak = 0;
	private gamePeak: number;
	
	constructor(
		@inject(TYPES.ChatService) private readonly chatService: ChatService,
		@inject(TYPES.GameService) private readonly gameService: GameService,
		@inject(TYPES.GameStore) private readonly gameStore: GameStore,
		@inject(TYPES.UserStore) private readonly userStore: UserStore,
		@inject(TYPES.AppStore) private readonly appStore: AppStore,
	) {
		this.publicMessages$ = new Subject();
		this.privateMessages$ = new Subject();
		this.gameMessages$ = new Subject();
		this.allPublicMessages$ = new Subject();
		
		this.publicMessagesStack = getLimitedArray(this.MAX_PUBLIC_MESSAGES);
		this.gameMessagesAcc = [];
		this.gameMessagesAvailable$ = new Subject();
		
	}
	
	startRequestChat(chatId: number, chatPeak: number): void {
		console.warn(`ChatGamController.startRequestChat: ${chatId}`);
		// this.clearRequestTimeout();
		this.requestingIsActive = true;
		this.requestType = "chat"; // should be setted after clearRequestTimeout call
		this.chatId = chatId;
		this.chatPeak = chatPeak;
		this.requestMessages();
	}
	
	startRequestGame(chatId: number, chatPeak: number, gamePeak: number): void {
		console.warn(`ChatGamController.startRequestGame: ${chatId}`);
		// this.clearRequestTimeout();
		this.requestingIsActive = true;
		this.requestType = "game"; // should be setted after clearRequestTimeout call
		this.chatId = chatId;
		this.chatPeak = chatPeak;
		this.gamePeak = gamePeak;
		// empty the game messages accumulator
		this.gameMessagesAcc.length = 0;
		this.requestMessages();
	}
	
	private async getChatMessages() {
		console.log(`[getChatMessages] Start - chatId=${this.chatId}, peak=${this.chatPeak}`);
		try {
			const sr = await this.chatService.chatGetMessages(
				this.userStore.sessionKey,
				this.chatId,
				this.chatPeak);
			
			// Log the successful response
			console.log(`[getChatMessages] Success - Retrieved ${sr.Public.length} public and ${sr.Private.length} private messages`);
	
			this.proceedMessages(sr.Public, sr.Private);
		}
		catch (e) {
			// Detailed error logging
			console.warn(`[getChatMessages] Error - Unable to get chat messages for chatId=${this.chatId}: ${e.message}`, e);
		}
	
		this.requestMessages();
	
		// Log method end
		console.log(`[getChatMessages] End - chatId=${this.chatId}`);
	}
	
	async getGameMessages() {
		console.log(`ChatGamController.getGameMessages: gamePeak=${this.gamePeak}, chatId=${this.chatId}, peak=${this.chatPeak}`);
		try {
			const messages = await this.gameService.gam(
				this.userStore.sessionKey,
				this.joinedGame.RoomId,
				this.joinedGame.GameId,
				this.gamePeak,
				this.chatId,
				this.chatPeak,
			);
			this.proceedMessages(messages.PublicChat, messages.PrivateChat, messages.Game);
		}
		catch (e) {
			console.warn("ChatGamController.getGameMessages: error requesting and processing gam -- ", e);
		}
		this.requestMessages();
	}
	
	public forceReRequest(): void {
		console.log("Force Re-Request messages in mode: " + this.requestType);
		this.requestMessages();
	}
	
	private requestMessages(): void {
		this.clearRequestTimeout();
		if (this.requestingIsActive) {
			this.requestType === "game" ? this.runRequestGame() : this.runRequestChat();
		}
		else {
			console.log("CGC.requestMessages: requesting is not active.");
		}
	}
	
	private runRequestChat(): void {
		this.requestTimeout = setTimeout(() => this.getChatMessages(), 3300);
	}
	
	private runRequestGame(): void {
		this.requestTimeout = setTimeout(() => this.getGameMessages(), 1500);
	}
	
	public stopRequesting(): void {
		this.requestingIsActive = false;
		this.clearRequestTimeout();
	}
	
	private clearRequestTimeout(): void {
		if (this.requestTimeout) {
			clearTimeout(this.requestTimeout);
		}
	}
	
	private proceedMessages(publicMessages: ChatMessageDTO[], privateMessages: PrivateChatMessageDTO[], gameMessages?: GameMessageDTO[]): void {
		console.log(`ChatGamController.proceedMessages: public=${publicMessages ? publicMessages.length : -1}, private=${privateMessages ? privateMessages.length : -1}` + (gameMessages ? `, game=${gameMessages ? gameMessages.length : -1}` : ""));
		try {
			if (publicMessages && publicMessages.length > 0) { // keep last chatPeak for the next request
				this.chatPeak = publicMessages[publicMessages.length - 1].Id;
				this.publicMessagesStack.push(...publicMessages);
				this.publicMessages$.next(publicMessages);
				this.allPublicMessages$.next(this.publicMessagesStack);
			}
			if (privateMessages && privateMessages.length > 0) {
				this.privateMessages$.next(privateMessages);
			}
			if (gameMessages && gameMessages.length > 0) {
				this.gamePeak = gameMessages[gameMessages.length - 1].Id;
				this.gameMessagesAcc.push(...gameMessages);
				this.gameMessages$.next(gameMessages);
				this.gameMessagesAvailable$.next(gameMessages.length);
			}
		}
		catch (e) {
			console.error("ChatGamController.proceedMessages: ");
		}
	}
	
	public readGameMessage(): GameMessageDTO {
		return this.gameMessagesAcc.length ? this.gameMessagesAcc[0] : null;
	}
	
	public takeGameMessage(): GameMessageDTO {
		return this.gameMessagesAcc.length ? this.gameMessagesAcc.shift() : null;
	}
	
	private get joinedGame(): OnlineGameDTO {
		return this.gameStore.joinedGame;
	}
	
}

