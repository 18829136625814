import {OnlineGameDTO} from "../dto/OnlineGameDTO";
import {GameStateDTO} from "../dto/GameStateDTO";
import {PointsDescriptionDTO} from "../dto/PointsDescriptionDTO";
import {DealSnapshotDTO} from "../dto/DealSnapshotDTO";
import {GameMessagesDTO} from "../dto/GameMessagesDTO";
import {GameMessageDTO} from "../dto/GameMessageDTO";
import {TileDescrDTO} from "../dto/TileDescrDTO";
import {UserSeatDTO} from "../dto/UserSeatDTO";
import {GameSnapshotDTO} from "../dto/GameSnapshotDTO";
import {GameIndexDTO} from "../dto/GameIndexDTO";
import {GameUserPointsDTO} from "../dto/GameUserPointsDTO";
import {AutoPassMode} from "../../game/enums/AutoPassMode";
import {GameUserInfoDTO} from "../dto/GameUserInfoDTO";
import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";


export class GameService extends AbstractAMFService {
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.OnlineGame", OnlineGameDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameState", GameStateDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.DealSnapshot", DealSnapshotDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.PtsDescr", PointsDescriptionDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameMessage", GameMessageDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameMessages", GameMessagesDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameSnapshot", GameSnapshotDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TileDescr", TileDescrDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.UserSeat", UserSeatDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameSnapshot.GameIndex", GameIndexDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameUserInfo", GameUserInfoDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameUserPoints", GameUserPointsDTO);
		// Mahjong.GameServer.Service.Model.UserSeat/
	}
	
	public gam(sessionKey: string, roomId: number, gameId: number, gamePeek: number, chatId: number, chatPeek: number): Promise<GameMessagesDTO> {
		return this.send(GameServices.GAM,
			[sessionKey, roomId, gameId, gamePeek, chatId, chatPeek]);
	}
	
	public join(sessionKey: string, roomId: number, gameId: number): Promise<OnlineGameDTO> {
		return this.send(GameServices.Join,
			[sessionKey, roomId, gameId]);
	}
	
	public watch(sessionKey: string, roomId: number, gameId: number): Promise<OnlineGameDTO> {
		return this.send(GameServices.Watch,
			[sessionKey, roomId, gameId]);
	}
	
	public takeSeat(sessionKey: string, roomId: number, gameId: number): Promise<{ takenSeat: string }> {
		return this.send(GameServices.TakeSeat,
			[sessionKey, roomId, gameId]);
	}
	
	/**
	 *
	 * @param sessionKey
	 * @param roomId
	 * @param gameId
	 * @param withSettings
	 * @param rType: Unknown = 0, GameSelect = 1, JoinGame=2, AutoRefresh = 3
	 */
	public getGameState(sessionKey: string, roomId: number, gameId: number, withSettings: boolean, rType: number = 0): Promise<GameStateDTO> {
		return this.send(GameServices.GetGameState,
			[sessionKey, roomId, gameId, withSettings, rType]);
	}
	
	public getSettings(sessionKey: string, roomId: number, gameId: number): Promise<OnlineGameDTO> {
		return this.send(GameServices.GetSettings,
			[sessionKey, roomId, gameId]);
	}
	
	// GameIndexDTO
	public playAgain(sessionKey: string, roomId: number, gameId: number): Promise<GameIndexDTO> {
		return this.send(GameServices.Replay,
			[sessionKey, roomId, gameId]);
	}
	
	public leave(sessionKey: string, roomId: number, gameId: number): Promise<boolean> {
		return this.send(GameServices.Leave,
			[sessionKey, roomId, gameId]);
	}
	
	public start(sessionKey: string, roomId: number, gameId: number): Promise<boolean> {
		return this.send(GameServices.Start,
			[sessionKey, roomId, gameId]);
	}
	
	// ---
	
	public create(
		sessionKey: string, gameTypeId: number, name: string, description: string, hostSeat: boolean, forFriends: boolean,
		spectators: boolean, timeLimit: number, roundsCount: number, rated: boolean, flowers: boolean, minBelt: number,
		money: boolean, entryFee: number, declarePoints: number, showWinningHandOnly: boolean, showGameHelper: boolean,
		playTimeSec: number, waitForReconnect: boolean, enableAutoPass: boolean
	): Promise<GameIndexDTO> {
		return this.send(GameServices.Create,
			[sessionKey, gameTypeId, name, description, hostSeat, forFriends, spectators, timeLimit,
				roundsCount, rated, flowers, minBelt, money, entryFee, declarePoints, showWinningHandOnly,
				showGameHelper, playTimeSec, waitForReconnect, enableAutoPass]);
	}
	
	public create2(sessionKey: string, {
		gameTypeId, name, description, hostSeat, forFriends, spectators, timeLimit,
		roundsCount, rated, flowers, minBelt, money, entryFee, declarePoints, showWinningHandOnly,
		showGameHelper, playTimeSec, waitForReconnect, enableAutoPass
	}: ICreateGameOpts) {
		return this.create(sessionKey, gameTypeId, name, description, hostSeat, forFriends, spectators, timeLimit,
			roundsCount, rated, flowers, minBelt, money, entryFee, declarePoints, showWinningHandOnly,
			showGameHelper, playTimeSec, waitForReconnect, enableAutoPass);
	}
	
	public requestAutoSeat(sessionKey: string): Promise<boolean> {
		return this.send(GameServices.RequestAutoSeat,
			[sessionKey]);
	}
	
	
	public joinUser(sessionKey: string, roomId: number, gameId: number, userName: string): Promise<boolean> {
		return this.send(GameServices.JoinUser,
			[sessionKey, roomId, gameId, userName]);
	}
	
	public changeAutoPassMode(sessionKey: string, roomId: number, gameId: number, mode: AutoPassMode): Promise<AutoPassMode> {
		return this.send(GameServices.ChangeAutoPassMode,
			[sessionKey, roomId, gameId, mode]);
	}
	
	public getHelpTips(sessionKey: string, langId: number): Promise<Array<any>> {
		return this.send(GameServices.GetHelpTips,
			[sessionKey, langId]);
	}
	
	/**
	 * Close unfinished Siamese games
	 * @param sessionKey
	 * @param roomId
	 * @param gameId
	 * @constructor
	 */
	public ClosePausedGame(sessionKey: string, roomId: number, gameId: number): Promise<boolean> {
		return this.send(GameServices.ClosePausedGame,
			[sessionKey, roomId, gameId]);
	}
	
	// ---
	
	public makeMove(sessionKey: string, roomId: number, gameId: number, move: string, tile: number, turn: number)
		: Promise<boolean> {
		return this.send(GameServices.MakeMove,
			[sessionKey, roomId, gameId, move, tile, turn]);
	}
	
	public replaceFlowers(sessionKey: string, roomId: number, gameId: number, flowersCount: number): Promise<boolean> {
		return this.send(GameServices.ReplaceFlowers,
			[sessionKey, roomId, gameId, flowersCount]);
	}
	
	// ---
	
	public getTotalPoints(sessionKey: string, roomId: number, gameId: number): Promise<Array<GameUserPointsDTO>> {
		return this.send(GameServices.GetTotalPoints,
			[sessionKey, roomId, gameId]);
	}
	
	public dealSnapshot(sessionKey: string, roomId: number, gameId: number, dealNumber: number): Promise<DealSnapshotDTO> {
		return this.send(GameServices.DealSnapshot,
			[sessionKey, roomId, gameId, dealNumber]);
	}
	
	public gameSnapshot(sessionKey: string, roomId: number, gameId: number): Promise<GameSnapshotDTO> {
		return this.send(GameServices.GameSnapshot,
			[sessionKey, roomId, gameId]);
	}
	
}


export class GameServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.Game";
	public static readonly GAM = new ServiceDescriptor(GameServices.NS, "Gam");
	public static readonly Join = new ServiceDescriptor(GameServices.NS, "Join");
	public static readonly Watch = new ServiceDescriptor(GameServices.NS, "Watch");
	public static readonly TakeSeat = new ServiceDescriptor(GameServices.NS, "TakeSeat");
	public static readonly GetGameState = new ServiceDescriptor(GameServices.NS, "GetGameState");
	public static readonly GetSettings = new ServiceDescriptor(GameServices.NS, "GetSettings");
	public static readonly Replay = new ServiceDescriptor(GameServices.NS, "Replay");
	public static readonly Start = new ServiceDescriptor(GameServices.NS, "Start");
	public static readonly Leave = new ServiceDescriptor(GameServices.NS, "Leave");
	public static readonly Create = new ServiceDescriptor(GameServices.NS, "Create");
	public static readonly RequestAutoSeat = new ServiceDescriptor(GameServices.NS, "RequestAutoSeat");
	public static readonly JoinUser = new ServiceDescriptor(GameServices.NS, "JoinUser");
	public static readonly ChangeAutoPassMode = new ServiceDescriptor(GameServices.NS, "ChangeAutoPassMode");
	public static readonly GetHelpTips = new ServiceDescriptor(GameServices.NS, "GetHelpTips");
	public static readonly ClosePausedGame = new ServiceDescriptor(GameServices.NS, "ClosePausedGame");
	public static readonly MakeMove = new ServiceDescriptor(GameServices.NS, "MakeMove");
	public static readonly ReplaceFlowers = new ServiceDescriptor(GameServices.NS, "ReplaceFlowers");
	public static readonly GetTotalPoints = new ServiceDescriptor(GameServices.NS, "GetTotalPoints");
	public static readonly DealSnapshot = new ServiceDescriptor(GameServices.NS, "DealSnapshot");
	public static readonly GameSnapshot = new ServiceDescriptor(GameServices.NS, "GameSnapshot");
}

export interface ICreateGameOpts {
	gameTypeId: number;
	name: string;
	description: string;
	hostSeat: boolean;
	forFriends: boolean;
	spectators: boolean;
	timeLimit: number;
	roundsCount: number;
	rated: boolean;
	flowers: boolean;
	minBelt: number;
	money: boolean;
	entryFee: number;
	declarePoints: number;
	showWinningHandOnly: boolean;
	showGameHelper: boolean;
	playTimeSec: number;
	waitForReconnect: boolean;
	enableAutoPass: boolean;
}
