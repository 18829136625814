<div class="Cont">
  <div class="Cont">
    <button mat-mini-fab color="secondary" class="cone-wind-sock">
      <span class="wind-direction">{{ roundWind | slice:0:1 }}</span> <!-- Display the first letter of roundWind (E, S, W, N) -->
    </button>
    <div class="wind-caption">Wind Direction</div> <!-- Add caption below the cone icon -->
  </div>
  <div class="iBox gameInfoPopup">
    <div class="irow">
      <span class="iLabel">{{ 'Lobby.InfoTabs.TableInfo.GameName' | translate }}:</span>
      <span class="iValue">{{ gameName }}</span>
    </div>
    <div class="irow" *ngIf="minPoints !== undefined">
      <span class="iLabel" [matTooltip]="'Lobby.HOverlays.GameRules.MP_msg' | translate">{{ 'Lobby.CreateGame.MinPoints' | translate }}:</span>
      <span class="iValue">{{ minPoints }}</span>
    </div>
    <div class="irow">
      <span class="iLabel">{{ 'Game.View.DealNumber' | translate }}</span>
      <span class="iValue">{{ dealNumber }}</span>
    </div>
    <div>
      <span>Round Wind: {{ roundWind }}</span>
    </div>
  </div>
</div>