export class UserSeatDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.GameSnapshot.UserSeat"
	
	public Belt: number;
	public Gender: number;
	public Id: number;
	public Name: string;
	public Rating: number;
	public Side: string;
}
