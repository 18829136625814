export enum GameMode {
	School = 0,
	Fun = 1,
	Cash = 2,
	MyGames = 3,
}

export class GameModeHelper {
	static isValidName(value: string): boolean {
		return !isNaN(GameMode[value]);
	}
	
	static isValidId(value: number): boolean {
		return !isNaN(GameMode[GameMode[value]]);
	}
}
