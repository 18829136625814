export enum MoveType {
	UNKNOWN_MESSAGE_TYPE = -1
	, SAID = 0
	, AUTO_SAID = 1
	, MAKE_MOVE = 2
	, WRONG_MOVE = 3
	, FROM_MELDED_TO_USER_CHARLESTONE = 4
	, FROM_CONCEALED_TO_MELDED_CHARLESTONE = 5
	, FROM_USER_TO_CONCEALED_CHARLESTONE = 6
	, FROM_CONCEALED_TO_MELDED = 7
	, FROM_CONCEALED_TO_SLOT = 8
	, FROM_SLOT_TO_CONCEALED = 9
	, FROM_CONCEALED_TO_MELDED_HIDDEN = 10
	, FROM_SLOT_TO_MELDED = 11
	, FROM_SLOT_TO_DISCARDS = 12
	, FROM_WALL_TO_MELDED = 13
	, FROM_WALL_TO_CONCEALED = 14
	, READY = 15
	, DORA_INDICATOR = 16
	, URA_DORA = 17
	, NULL_ROUND = 18
	, START_SHOW_TILE = 19
	, SHOW_TILE = 20
	, SHOW_TILE_MELDED = 21
	, END_SHOW_TILE = 22
	, END_GAME = 23
	, CHANGE_SEATS = 24
	, NEW_DEAL_WIND = 25
	, NEW_DEAL = 26
	, END_DEAL = 27
	, START_GAME = 28
	, START_DEAL = 29
	, USER_ID_MOVE_NOW = 30
	, LEAVE_GAME_USER = 31
	, CHANGE_STATE_GAME_USER = 32
	, PRACTICE_PLAYER_CAN_MAKE_MOVE = 33
	, START_CHARLESTONES = 34
	, CHARLESTONE_1_RIGHT = 35
	, CHARLESTONE_2_LEFT = 36
	, CHARLESTONE_3_WAIT = 37
	, CHARLESTONE_3_OPPOSITE = 38
	, CHARLESTONES_END = 39
	, BLIND_PASS = 40
	, BLIND_PASS_REMOVE = 41
	, DEFINE_JOKER = 42
	, UNDEFINE_JOKER = 43
	, REDEEM_JOKER_START = 44
	, REDEEM_JOKER_END = 45
	, REDEEM_JOKER_EXCHANGE_MELDED_ON_SLOT = 46
	, TAKE_SEAT = 47
	, JOIN = 48
	, NEW_RATING = 49
	, REPLACE_FLOWERS = 50
	, REPLACE_FLOWER = 51
	, REPLACE_FLOWERS_SIDE = 52
	, END_TAKE_BLOCK = 54
	, WAIT_RECONNECT = 55
	, SYSTEM_PRIVATE_MESSAGE = 56
	, CHARLESTONE_2_WAIT = 57
	, CHARLESTONE_1_OPPOSITE = 58
	, CHARLESTONE_1_LEFT = 59
	, CHARLESTONE_2_OPPOSITE = 60
	, CHARLESTONE_2_RIGHT = 61
	, CHARLESTONE_3_OPPOSITE_TILES = 63
	, TEST_TOMELDED = 64
	, SHOW_JM_COUNTER = 65
	, GAME_STATE = 66
	, USER_SIDE = 67
	, DEAL_THROWN_DICE = 68
	, DEAL_TURN = 69
	, TIME_BANK = 70
	/**Indicates how mush flowers player selected to replace.*/
	, FLOWERS_COUNT = 700
	, REPLACE_FLOWERS_COUNT = 71
	, WON_USD = 72
	, WON_CHIP = 73
	, COFFEE_BREAK = 74
	, WON_BONUS_CHIP = 75
	, INC_RATING = 76
	, LOCK_TILE = 77
	, UNLOCK_TILE = 78
	, JM_DEALER_CHANGED = 79
	, CHANGE_USER_POINTS = 80
	, USER_DECLARE_DRAW = 81
	, JM_RENCHAN_CHANGED = 82
	, FROM_DEADWALL_TO_CONCEALED = 83
	, FROM_DEADWALL_TO_MELDED = 84
	
	, DEADHAND_CALL = 86
	, DEADHAND_NO = 87
	, DEADHAND_YES = 88
	
	, USER_CAN_MAKE_MOVE = 90
	
	, SHOW_BETS_COUNTER = 92
	, FROM_HAND_TO_HAND = 93
	/** Used local only to pass initial parameters from snapshot object to the game.  */
	, GAME_SNAPSHOT_OPTIONS = 400
	, GAME_SNAPSHOT_PROCESSING_END = 401
}

export function MoveType2Name(type: MoveType): string {
	const ents = Object.entries(MoveType);
	for (const [label, id] of ents) {
		if (id === type) {
			return label;
		}
	}
	return undefined;
}
