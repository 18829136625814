import { Entity } from "ecsy";
import { TileType } from "../enums/TileType";
import {
	PlayerIdComponent,
	TileDataComponent,
	TileNodesComponent,
	TileState,
	TileStoreComponent,
	TileTypeComponent
} from "../ecs/common/components";
import { TileEntityHelper } from "./TileEntityHelper";
import { ActionMoveType } from "../enums/ActionMoveType";
import { TileDataHelper } from "./TileDataHelper";
import { TileWrapper } from "../ecs/wrappers/TileWrapper";

export class TileSetHelper {

	public static getTile({ tiles, tileFullId, tileTinyId, tileType, playerId, nextNode, notTile, isLocked }: {
		tiles: Entity[],
		tileFullId?: number,
		tileTinyId?: number,
		tileType?: TileType,
		playerId?: number,
		nextNode?: Entity,
		notTile?: Entity,
		isLocked?: boolean,
	}): Entity {
		return tiles.find(tileEntity => (tileType === undefined || tileEntity.getComponent(TileTypeComponent).value === tileType)
			&& (tileFullId === undefined || tileEntity.getComponent(TileDataComponent).fullId === tileFullId)
			&& (tileTinyId === undefined || tileEntity.getComponent(TileDataComponent).tinyId === tileTinyId)
			&& (playerId === undefined || tileEntity.getComponent(PlayerIdComponent).playerId === playerId)
			&& (nextNode === undefined || tileEntity.getComponent(TileNodesComponent).next === nextNode)
			&& (notTile === undefined || tileEntity !== notTile)
			&& (isLocked === undefined || TileEntityHelper.isLocked(tileEntity) === isLocked)
		);

	}

	public static getTiles({ tiles, tileFullId, tileTinyId, tileType, playerId, nextNode, notTile }: {
		tiles: Entity[],
		tileFullId?: number,
		tileTinyId?: number,
		tileType?: TileType,
		playerId?: number,
		nextNode?: Entity,
		notTile?: Entity,
	}): Entity[] {
		return tiles.filter(tileEntity => (tileType === undefined || tileEntity.getComponent(TileTypeComponent).value === tileType)
			&& (tileFullId === undefined || tileEntity.getComponent(TileDataComponent).fullId === tileFullId)
			&& (tileTinyId === undefined || tileEntity.getComponent(TileDataComponent).tinyId === tileTinyId)
			&& (playerId === undefined || tileEntity.getComponent(PlayerIdComponent).playerId === playerId)
			&& (nextNode === undefined || tileEntity.getComponent(TileNodesComponent).next === nextNode)
			&& (notTile === undefined || tileEntity !== notTile)
		);

	}


	/**
	 * Search for the first tile in the given set
	 * @param tileSet -- array of tile entities to search through
	 * @return tile with no previous node defined or undefined otherwise
	 */
	static getFirstTile(tileSet: Array<Entity>) {
		return tileSet.find(tile => TileEntityHelper.isFirstTile(tile));
	}

	/**
	 * Search for a last tile in the given set
	 * @param tileSet -- array of tile entities to search through
	 * @return tile with no next node defined or undefined otherwise
	 */
	static getLastTile(tileSet: Array<Entity>) {
		return tileSet.find(tile => TileEntityHelper.isLastTile(tile));
	}

	public static getAvailableChows({ slotTileId, playerId, allTiles }: { slotTileId: number, playerId: number, allTiles: Entity[] }) {
		const slotTileTinyId = slotTileId % 100;
		const chowList: { type: ActionMoveType, tiles: number[] }[] = [];
		const r = { hasChow_1: false, hasChow_2: false, hasChow_3: false, chows: chowList };
		const concealedTiles = this.getPlayerConcealedTiles(playerId, allTiles);
		const l2 = this.hasTileWithTinyId(slotTileTinyId - 2, concealedTiles);
		const l1 = this.hasTileWithTinyId(slotTileTinyId - 1, concealedTiles);
		const r1 = this.hasTileWithTinyId(slotTileTinyId + 1, concealedTiles);
		const r2 = this.hasTileWithTinyId(slotTileTinyId + 2, concealedTiles);
		if (l2 && l1) {
			r.hasChow_3 = true;
			chowList.push({ type: ActionMoveType.CHOW_3, tiles: [slotTileTinyId - 2, slotTileTinyId - 1, slotTileTinyId] });
		}
		if (l1 && r1) {
			r.hasChow_2 = true;
			chowList.push({ type: ActionMoveType.CHOW_2, tiles: [slotTileTinyId - 1, slotTileTinyId, slotTileTinyId + 1] });
		}
		if (r1 && r2) {
			r.hasChow_1 = true;
			chowList.push({ type: ActionMoveType.CHOW_1, tiles: [slotTileTinyId, slotTileTinyId + 1, slotTileTinyId + 2] });
		}
		return r;
	}

	public static getPlayerConcealedTiles(playerId: number, allTiles: Array<Entity>, filter?: (tinyId: number) => boolean): Array<Entity> {
		return allTiles.filter(entity => entity.getComponent(PlayerIdComponent).playerId === playerId
			&& entity.getComponent(TileTypeComponent).value === TileType.CONCEALED
			&& (!filter || filter(entity.getComponent(TileDataComponent).tinyId))
		);
	}

	public static getPlayerMeldedTiles(playerId: number, allTiles: Array<Entity>): Array<Entity> {
		return allTiles.filter(entity => entity.getComponent(PlayerIdComponent).playerId === playerId
			&& entity.getComponent(TileTypeComponent).value === TileType.MELDED);
	}

	public static getPlayerFlowerTiles(playerId: number, allTiles: Array<Entity>): Array<Entity> {
		return allTiles.filter(entity => entity.getComponent(PlayerIdComponent).playerId === playerId
			&& entity.getComponent(TileTypeComponent).value === TileType.FLOWER);
	}

	public static retrieveConcealedTile(playerId: number, tileId: number, tiles: Array<Entity>): Entity {
		try {
			if (!playerId || !tileId || !tiles || tiles.length === 0) {
				console.error("Missing or invalid arguments in retrieveConcealedTile() function: playerId:", playerId, ", tileId:", tileId, ", tiles:", tiles);
				return null;
			}
			const tileSet = tiles.filter(tileEntity => tileEntity.getComponent(PlayerIdComponent).playerId === playerId
				&& tileEntity.getComponent(TileTypeComponent).value === TileType.CONCEALED);

			let tile = tileSet.find(tileEntity => tileEntity.getComponent(TileDataComponent).fullId === tileId);
			if (tile) {
				return tile;
			}
			console.warn("TileSetHelper.retrieveConcealedTile(): cannot find exact match with tileId=", tileId, " for playerId=", playerId);
			const tileTinyId = tileId % 100;
			console.log("trying to find tile by tinyId: " + tileTinyId);
			tile = tileSet.find(tileEntity => tileEntity.getComponent(TileDataComponent).tinyId === tileTinyId);
			if (tile) {
				return tile;
			}
			console.warn("TileSetHelper.retrieveConcealedTile(): cannot find exact match with tileId=", tileTinyId, " for playerId=", playerId);
			console.log("trying to find closed 101 tile..");
			tile = tileSet.find(tileEntity => tileEntity.getComponent(TileDataComponent).fullId === 101); // TODO: search for 201 tile if fullId = 2xx
			if (tile) {
				return tile;
			}
			console.error("retrieveConcealedTile(): Was not able to find a tile with id=", tileId);

			console.error("retrieveConcealedTile(): Available concealed tiles for player with id=", playerId, " are: ", this.getTileIds(tileSet));
		} catch (error) {
			console.error("Exception occurred in retrieveConcealedTile() function: ", error);
		}
		return null;
	}

	public static retrieveMeldedTile(playerId: number, tileId: number, tiles: Array<Entity>): Entity {
		const tileSet = tiles.filter(tileEntity => tileEntity.getComponent(PlayerIdComponent).playerId === playerId
			&& tileEntity.getComponent(TileTypeComponent).value === TileType.MELDED);

		let tile = tileSet.find(tileEntity => tileEntity.getComponent(TileDataComponent).fullId === tileId);
		if (tile) {
			return tile;
		}
		console.warn("TileSetHelper.retrieveMeldedTile(): cannot find exact match with tileId=", tileId, " for playerId=", playerId);
		console.log("trying to find tile by tinyId..");
		const tileTinyId = tileId % 100;
		tile = tileSet.find(tileEntity => tileEntity.getComponent(TileDataComponent).tinyId === tileTinyId);
		if (tile) {
			return tile;
		}
		console.warn("TileSetHelper.retrieveMeldedTile(): cannot find exact match with tileId=", tileId, " for playerId=", playerId);

		console.error("retrieveMeldedTile(): Available concealed tiles for player with id=", playerId, " are: ", this.getTileIds(tileSet));
		return null;
	}

	public static addTile({ tile, tileId, playerId, tileType, meldedGroup, afterTile, tiles }: {
		tile: Entity, playerId?: number, tileId?: number, tileType?: TileType, tiles: Array<Entity>, meldedGroup?: number, afterTile?: Entity
	}): void {
		const tileW = TileEntityHelper.wrapEntity(tile);
		console.log(`PEH.addTile: ${tileW}, afterTile?.id=${afterTile?.id}`);
		if (tileId !== undefined) {
			tileW.id = tileId;
		}
		if (tileType !== undefined) {
			tileW.type = tileType;
		}
		if (playerId !== undefined) {
			tileW.playerId = playerId;
		}
		if (meldedGroup !== undefined) {
			tileW.meldedGroup = meldedGroup;
		}
		if (afterTile === undefined || afterTile === null) { // if afterTile was not provided, search for the last tile of given TileType and Player
			afterTile = TileSetHelper.getTile({ tiles, playerId: tileW.playerId, tileType: tileW.type, nextNode: null, notTile: tile });
		}

		TileEntityHelper.linkPrevAndNextTileNodes(tile); // Unlink the tile from the old set
		if (afterTile) { // if there is a tile to insert after, link them
			console.log(`PEH.addTile: updated tile=${tileW} insert after: ${TileEntityHelper.wrapEntity(afterTile)}`);
			TileEntityHelper.insertTileAfter(tile, afterTile);
		}
		else { // this is the first tile of this TileType - clear Nodes
			console.log(`PEH.addTile: updated tile=${tileW} as first tile`);
			tileW.nodes.update(null, null);
		}
	}

	public static removeHighLightFor(playerId: number, tileType: TileType, allTiles: Array<Entity>): void {
		// console.log("» rem hi()");
		const tiles = allTiles.filter(entity => entity.getComponent(PlayerIdComponent).playerId === playerId
			&& entity.getComponent(TileTypeComponent).value === tileType
			&& entity.getComponent(TileDataComponent).state === TileState.HI);
		tiles.forEach(tileEntity => {
			// console.log("rem hi for tile with id = " + tileEntity.getComponent(TileDataComponent).fullId);
			const tileData = tileEntity.getMutableComponent(TileDataComponent);
			// we should keep 201 tile as closed (turned back) with id = 101
			// tileData.fullId -= tileData.tinyId === 1 ? 100 : 200;
			tileData.fullId = tileData.tinyId === 1
				? TileDataHelper.setState(tileData.fullId, TileState.TURNED_BACK)
				: TileDataHelper.setState(tileData.fullId, TileState.NORMAL);
		});
	}

	/**
	 * Kong Self (4 tiles in concealed) and Promoted Kong (1 tile concealed and 3 tiles (pung, same meldedGroup) in melded) list
	 * @param playerId player ID
	 * @param allTiles array of tile entities
	 * @return array of tinyIds
	 */
	public static getPlayerKongSelfList(playerId: number, allTiles: Entity[]): number[] {
		interface IMeldedTileOccurrence {
			id: number;
			count: number;
		}
		
		console.log("Starting getPlayerKongSelfList method");
		
		const playerTiles = allTiles.filter(entity => entity.getComponent(PlayerIdComponent).playerId === playerId);
		
		console.log(`Total player tiles: ${playerTiles.length}`);
	
		const concealedOccurrences = playerTiles
			.filter(entity => entity.getComponent(TileTypeComponent).value === TileType.CONCEALED)
			.map(entity => entity.getComponent(TileDataComponent).tinyId)
			.reduce((acc, currentValue) => {
				acc[currentValue] = ++acc[currentValue] || 1;
				return acc;
			}, {});
		
		console.log(`Concealed occurrences: ${JSON.stringify(concealedOccurrences)}`);
		
		const concealedKongList = Object.entries<number>(concealedOccurrences)
			.filter(([id, count]) => count > 3)
			.map(([id, count]) => +id);
	
		console.log(`Concealed Kong List: ${concealedKongList.join(", ")}`);
	
		const potentialKongs = playerTiles
			.filter(entity => entity.getComponent(TileTypeComponent).value === TileType.MELDED
				&& concealedOccurrences["" + entity.getComponent(TileDataComponent).tinyId]
			)
			.map(entity => ({
				id: entity.getComponent(TileDataComponent).tinyId
			}))
			.reduce((acc: IMeldedTileOccurrence[], currentValue) => {
				const i = acc.find(item => item.id === currentValue.id);
				if (!i) {
					acc.push({
						id: currentValue.id,
						count: 1
					} as IMeldedTileOccurrence);
				}
				else {
					i.count++;
				}
				return acc;
			}, [])
			.filter(item => item.count === 3)
			.map(t => t.id);
	
		console.log(`Potential Kongs based on Melded tiles: ${potentialKongs.join(", ")}`);
		
		const finalKongList = potentialKongs.concat(...concealedKongList);
		console.log(`Final Kong List: ${finalKongList.join(", ")}`);
	
		return finalKongList;
	}

	public static getLastMeldedForPromotedKong(playerId: number, tileId: number, allTiles: Entity[]): TileWrapper {
		console.log("getPlayerKongSelfList  Entering getLastMeldedForPromotedKong for player ID:", playerId, "and tile ID:", tileId);

		const tile = this.getPlayerMeldedTiles(playerId, allTiles)
			.reverse()
			.find(tileEntity => tileEntity.getComponent(TileDataComponent).tinyId === tileId % 100);

		console.log("getPlayerKongSelfList Found last melded tile:", tile);

		return tile ? TileWrapper.wrap(tile) : undefined;
	}

	public static hasTileWithId(tileId: number, tileSet: Entity[]): boolean {
		return tileSet.some(tileEntity => tileEntity.getComponent(TileDataComponent).fullId === tileId);
	}

	public static hasTileWithTinyId(tileTinyId: number, tileSet: Entity[]): boolean {
		return tileSet.some(tileEntity => tileEntity.getComponent(TileDataComponent).tinyId === tileTinyId);
	}

	public static getTilesCountWithId(opts: { fullTileId: number, tileSet: Entity[] }): number;
	public static getTilesCountWithId(opts: { tileTinyId: number, tileSet: Entity[] }): number;
	public static getTilesCountWithId(opts: { fullTileId?: number, tileTinyId?: number, tileSet: Entity[] }): number {
		return opts.tileSet.filter(
			tileEntity => opts.fullTileId === undefined ? tileEntity.getComponent(TileDataComponent).tinyId === opts.tileTinyId : tileEntity.getComponent(TileDataComponent).fullId === opts.fullTileId
		).length;
	}

	public static getTileIds(tiles: Entity[]): string {
		const tilesAvailable = tiles.reduce((acc: string, value: Entity) => {
			return acc + "|" + value.getComponent(TileDataComponent).fullId;
		}, "");
		return tilesAvailable;
	}

}
