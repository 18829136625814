export class UserStatisticsDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.UserStatistics"
	
	public UserId: number;
	
	public Last7dGmLost: number;
	public Last7dGmWon: number;
	public Last7dGmDraw: number;
	public LastMonthGmLost: number;
	public LastMonthGmWon: number;
	public LastMonthGmDraw: number;
	public TodayGmLost: number;
	public TodayGmWon: number;
	public TodayGmDraw: number;
	
	public TodayMoneyEarn: number;
	public Last7dMoneyEarn: number;
	public TodayChipEarn: number;
	public Last7dChipEarn: number;
}
