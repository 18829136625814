import {InputRulesSystem} from "../common/InputRulesSystem";
import {IGameDialogButtonConfig, IGameDialogConfig, IGameDialogSubmitData} from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {GameEvent, GameEventType} from "../../GameEventsPipe";
import {UIActionMenu, UIActionMenuItem} from "../../../app/ui/lobby/table/ingame/UIActionMenu";
import {InGameDialog, MenuID} from "./enumCO";
import {TileDataComponent, TileTypeComponent} from "../common/components";
import {Entity} from "ecsy";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {TileType} from "../../enums/TileType";
import {CORulesStateComponent} from "./components/CORulesStateComponent";

/**
 * Game Rules-specified Input handler system.
 */
export class InputRulesCOSystem extends InputRulesSystem {
	
	protected onTileClick(tileData: TileDataComponent, playerId: number, tileEntity: Entity, point: { x: number, y: number }): void {
		const iCanDiscard = this.gameAccessHelper.iCanDiscardTile(this.gameStorageQ);
		if (!iCanDiscard.result
			|| playerId !== this.gameStore.dealState.viewPlayerId
			|| tileEntity.getComponent(TileTypeComponent).value !== TileType.CONCEALED
			|| this.gameStorageQ.entity.getComponent(CORulesStateComponent).replaceFlowersInProgress) {
			console.log("onTileClick.CO: cannot proceed this tile");
			// this.showSnack("Nope");
			return;
		}
		console.log("InputRulesCOSystem.onTileClick: " + tileData);
		if (this.gameStorageQ.tableOptions.rulesSettings.isFlower(tileData.tinyId)) {
			this.showReplaceFlowerMenu(tileData.fullId, point);
		}
		else {
			super.onTileClick(tileData, playerId, tileEntity, point);
		}
	}
	
	protected onGameEvent(value: GameEvent) {
		super.onGameEvent(value);
		switch (value.type) {
			case GameEventType.UIActionMenu_Clicked:
				const click: { item: UIActionMenuItem, menu: UIActionMenu } = value.data;
				if (click.menu.id === MenuID.ReplaceFlower) {
					const selection: { action, tileId } = click.item.data;
					if (selection.action === "replace") {
						this.netManager.makeMove(ActionMoveType.REPLACE_FLOWER, selection.tileId, this.gameStorageQ);
					}
					else {
						this.netManager.putTile(selection.tileId, 0, this.gameStorageQ.entity);
					}
				}
				break;
		}
	}
	
	protected onGameDialogSubmit(data: IGameDialogSubmitData): void {
		const button: IGameDialogButtonConfig = data.button;
		const dialog: IGameDialogConfig = data.dialog;
		console.log(`onGameDialogSubmit: data=${data}`);
		switch (dialog.id) {
			case InGameDialog.ReplaceFlowers:
				const replaceFlowerCount: number = +button.data;
				this.netManager.replaceFlowers(replaceFlowerCount)
					.then(result => console.log("InputRulesCOSystem.onGameDialogSubmit: ReplaceFlowersDialog: " + result))
					.catch(error => console.warn("InputRulesCOSystem.onGameDialogSubmit: ReplaceFlowersDialog: " + error));
				break;
			default:
				super.onGameDialogSubmit(data);
		}
	}
	
	private showReplaceFlowerMenu(tileId: number, point: { x: number, y: number }) {
		if (point) {
			this.gameEvents.send(GameEventType.UIActionMenu_Show, {
				id: MenuID.ReplaceFlower,
				items: [
					{data: {action: "replace", tileId}, label: "Replace", labelResourceKey: "Game.Menu.replace_flower"},
					{data: {action: "discard", tileId}, label: "Discard", labelResourceKey: "Game.Menu.discard_flower"}
				],
				position: point
			} as UIActionMenu);
		}
	}
	
}

