import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../../../services/navigation.service";
import {QuickSeatService} from "../../../services/QuickSeatService";
import container from "../../../../inversify/inversify.config";
import {TYPES} from "../../../../inversify/inversify.types";
import {TournamentDTO} from "../../../../net/dto/tour/TournamentDTO";
import {TournamentListService} from "../../../../net/service/TournamentListService";

import {UserStore} from "../../../../store/UserStore";
import {NavPage} from "../../../enums/NavPage";
import {CurrencyType} from "../../../../game/enums/CurrencyType";
import {ServiceError} from "../../../../net/ServiceError";
import {Errors} from "../../../../game/enums/Errors";
import {MatDialog} from "@angular/material/dialog";
import {AccountUtil} from "../../../../utils/AccountUtil";
import {environment} from "../../../../environments/environment";
import {GameType} from "../../../../game/enums/GameType";
import {WebPages} from "../../../enums/WebPages";
import {IReactionDisposer} from "mobx";
import {TournamentState} from "../../../../game/enums/tournaments";
import {TournamentService} from "../../../../net/service/TournamentService";
import {UITourService} from "../../../services/UITourService";
import {AppEventsPipe, AppEventType} from "../../../../game/AppEventsPipe";


export interface PeriodicElement {
	name: string;
	position: number;
	weight: number;
	symbol: string;
}

@Component({
	selector: "app-tournaments",
	templateUrl: "./tournaments.component.html",
	styleUrls: ["./tournaments.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	// Need to remove view encapsulation so that the custom tooltip style defined in
	// `tooltip-custom-class-example.css` will not be scoped to this component's view.
	// encapsulation: ViewEncapsulation.None,
})
export class TournamentsComponent implements OnInit, OnDestroy {
	isDebug = environment.isDebug;
	@Input()
	tourList: TournamentDTO[] = [];
	
	displayedColumns;
	
	reactDisposers: IReactionDisposer[] = [];
	
	constructor(
		private router: Router, private route: ActivatedRoute, private navService: NavigationService, private qsService: QuickSeatService,
		public dialogService: MatDialog,
		private cd: ChangeDetectorRef,
		private uiTourService: UITourService
	) {
		this.displayedColumns = ["name", /*"registration", */"start", "players", "entryFee", "prize", "action"];
		
		
	}
	
	ngOnInit(): void {
		this.getTournaments().catch();
	}
	
	ngOnDestroy(): void {
		this.reactDisposers.forEach(disposer => disposer());
		this.reactDisposers.length = 0;
	}
	
	isTourFinished(tour: TournamentDTO): boolean {
		return tour.StateId >= TournamentState.COMPLETED;
	}
	
	get gameType(): GameType {
		return this.navService.routerGameType;
	}
	
	getCurrencyLabel(currency: CurrencyType) {
		switch (currency) {
			case CurrencyType.DragonChip:
				return "DC";
			case CurrencyType.GoldenCoin:
				return "GC";
			case CurrencyType.Usd:
				return "$";
			case CurrencyType.NoCurrency:
				return "?";
		}
	}
	
	private async getTournaments() {
		
		this.uiTourService.getOfficialList()
			.then(list => {
				this.tourList = list;
				this.cd.markForCheck();
			})
			.catch(e => console.error("TournamentsComponent.getTournaments: " + e));
		
	}
	
	public get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get tournamentService() {
		return container.get<TournamentService>(TYPES.TournamentService);
	}
	
	private get tournamentListService() {
		return container.get<TournamentListService>(TYPES.TournamentListService);
	}
	
	private displayNotEnough(currency: CurrencyType, amount: number) {
		const appEventsPipe = container.get<AppEventsPipe>(TYPES.AppEventsPipe);
		appEventsPipe?.send(AppEventType.Display_Not_Enough, {currency, amount});
	}
	
	async onReg(tour: TournamentDTO) {
		try {
			const balance = AccountUtil.verifyBalance(this.userStore.info.Account, tour.EntryFeeCurrencyId, tour.EntryFee);
			if (!balance.enough) {
				this.displayNotEnough(tour.EntryFeeCurrencyId, tour.EntryFee);
			}
			else {
				const res = await this.tournamentService.OfficialRegister(this.userStore.sessionKey, tour.RefOfficialTourId);
				tour.IsJoined = true;
				this.cd.markForCheck();
			}
		}
		catch (e) {
			console.error("TournamentsComponent.onReg: " + e);
			if (e instanceof ServiceError && e.id === Errors.NOT_ENOUGH_MONEY) {
				this.displayNotEnough(tour.EntryFeeCurrencyId, tour.EntryFee);
			}
		}
	}
	
	async onUnreg(tour: TournamentDTO) {
		try {
			const res = await this.tournamentService.OfficialUnregister(this.userStore.sessionKey, tour.RefOfficialTourId);
			tour.IsJoined = false;
			this.cd.markForCheck();
		}
		catch (e) {
			console.error("TournamentsComponent.onUnreg: " + e);
		}
	}
	
	onBack() {
		this.navService.navigate({page: NavPage.Lobby});
	}
	
	onDepositClick() {
		// window.open(`${environment.webRoot}/${WebPages.DEPOSIT}?sid=${this.userStore.sessionKey}&code=Deposit&amount=${moneyPaymentAmt}`, "_blank");
		const moneyPaymentAmt = 10;
		window.open(`${environment.webRoot}/${WebPages.DEPOSIT}?sid=${this.userStore.sessionKey}&code=Deposit&amount=${moneyPaymentAmt}`, "_blank");
	}
	
	openSchedule(tour: TournamentDTO) {
		const tourId = tour.RefOfficialTourId > 0 ? tour.RefOfficialTourId : tour.TourId;
		window.open(`${environment.webRoot}/TimeTable.aspx?tourId=${tourId}`, "_blank");
	}
}
