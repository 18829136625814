import {Side} from "../../enums/Side";
import {ActionMoveType} from "../../enums/ActionMoveType";
import * as THREE from "three";
import {Vector3} from "three";
import {ActionColors, IRulesOptions} from "../common/GameRulesAbstractSystem";
import {OnlineGameDTO} from "../../../net/dto/OnlineGameDTO";
import {dealDiceCalcCO} from "../../interfaces/TilesSortFn";
import {DiscardsMode} from "../../enums/DiscardsMode";
import {ICreateGameOpts} from "../../../net/service/GameService";
import {GameType} from "../../enums/GameType";
import {BeltType} from "../../enums/BeltType";
import {ICreateFormLimits} from "../../../app/ui/lobby/createprivate/i-create-game";
import {IRulesHelper, LocationTransforms} from "../../helpers/rules-helper";
import {RulesHelperHK} from "../hk/RulesHelperHK";

export const RulesHelperTW: IRulesHelper = {
	
	getOptions: (tableSettings: OnlineGameDTO): IRulesOptions => {
		return {
			gameType: tableSettings.GameTypeId,
			maxPlayers: 4,
			singleWallLength: tableSettings?.Flowers ? 18 : 17,
			hasDeadWall: true,
			deadWallLength: 16,
			sides: [Side.South, Side.East, Side.North, Side.West], // Sets available sides for current game rules. Should start from South, CCW
			actionsSet: [
				{id: ActionMoveType.PASS, label: "Pass", color: ActionColors.PASS},
				{id: ActionMoveType.CHOW, label: "Chow", color: ActionColors.CHOW},
				{id: ActionMoveType.PUNG, label: "Pung", color: ActionColors.PUNG},
				{id: ActionMoveType.KONG, label: "Kong", color: ActionColors.KONG},
				{id: ActionMoveType.MAHJONG, label: "Mahjong", color: ActionColors.MAHJONG},
			],
			dealDiceCalcFn: dealDiceCalcCO,
			discardsMode: DiscardsMode.COMMON,
			isFlower: tileId => (tileId % 100) > 60 && (tileId % 100) < 80,
			
			nextTileFromEnd_Enabled: true,
			nextTileFromEnd_MeldedFlower: true,
			nextTileFromEnd_Kong: true,
		};
	},
	
	getLocationTransforms: (): LocationTransforms => {
		const lc = {};
		lc[Side.South] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 0),
				euler: new Vector3(0, 0, 0),
			};
		lc[Side.West] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 90 * 3.14 / 180),
				euler: new Vector3(0, 90 * 3.14 / 180, 0),
			};
		lc[Side.North] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 180 * 3.14 / 180),
				euler: new Vector3(0, 180 * 3.14 / 180, 0),
			};
		lc[Side.East] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 270 * 3.14 / 180),
				euler: new Vector3(0, 270 * 3.14 / 180, 0),
			};
		return lc;
	},
	
	defaultGameSettings: ((): ICreateGameOpts => {
		const name = "PG - " + (new Date().getTime());
		return {
			name,
			declarePoints: 0,
			description: "",
			enableAutoPass: true,
			entryFee: 250,
			flowers: true,
			forFriends: true,
			gameTypeId: GameType.TW,
			hostSeat: false,
			minBelt: BeltType.White,
			money: false,
			playTimeSec: 3 * 60 * 60,
			rated: true,
			roundsCount: 0,
			showGameHelper: true,
			showWinningHandOnly: false,
			spectators: true,
			timeLimit: 10,
			waitForReconnect: false
		};
	})(),
	
	getCreateLimits: ({rounds}: { rounds: number }): ICreateFormLimits => {
		const entryFee = rounds === 0 ? 250 : 500 * rounds;
		return {
			name: {maxLength: 15},
			timeLimit: {min: 5, max: 3600},
			declarePoints: {values: [{data: 0, label: "0"}, {data: 6, label: "6"}]},
			entryFee: {min: entryFee, max: entryFee},
			roundsCount: [
				{data: 0, label: "QM"},
				{data: 1, label: "1"},
				{data: 2, label: "2"},
				{data: 3, label: "3"},
				{data: 4, label: "4"},
			]
		};
	},
	
	helpTips: RulesHelperHK.helpTips,
};
