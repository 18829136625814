import {ElementRef, Injectable, NgZone, OnDestroy} from "@angular/core";
import {GameWorld} from "../../game/ecs/GameWorld";
import {GameGraphics} from "../../game/GameGraphics";
import container from "../../inversify/inversify.config";
import {TYPES} from "../../inversify/inversify.types";

@Injectable({providedIn: "root"})
export class GameService implements OnDestroy {
	private canvas: HTMLCanvasElement;
	private gameWorld: GameWorld;
	private gameGraphics: GameGraphics;
	private lastTime = performance.now();
	
	private frameId: number = null;
	
	public constructor(private ngZone: NgZone) {
		console.log("GameService: Create");
		this.gameGraphics = container.get<GameGraphics>(TYPES.GameGraphics);
		this.gameWorld = container.get<GameWorld>(TYPES.GameWorld);
	}
	
	public ngOnDestroy(): void {
		console.warn("GameService: Destroy");
		this.stopAnimation();
	}
	
	public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
		// Get the reference of the canvas element from our HTML document
		this.canvas = canvas.nativeElement;
		// setTimeout(() => this.g.createRenderer(this.canvas, window.innerWidth, window.innerHeight), 3000);
		const {width, height} = this.getSize();
		this.gameGraphics.createRenderer(this.canvas, width, height);
		// this.gameGraphics.createRenderer(this.canvas, 300, 300);
		
		this.animate();
	}
	
	
	private animate(): void {
		// We have to run this outside angular zones,
		// because it could trigger heavy changeDetection cycles.
		// this.ngZone.runOutsideAngular(() => {
		if (document.readyState !== "loading") {
			this.render();
		}
		else {
			window.addEventListener("DOMContentLoaded", () => {
				this.render();
			});
		}
		
		window.addEventListener("resize", () => {
			this.resize();
		});
		
		/*			window.addEventListener("mousemove", (e) => {
						this.onMouseMove(e);
					}, false);
					window.addEventListener("mousedown", (e) => {
						this.onMouseClick(e);
					}, false);*/
		// });
	}
	
	private stopAnimation() {
		if (this.frameId != null) {
			cancelAnimationFrame(this.frameId);
		}
		// TODO: remove event listeners added on animate() ?
	}
	
	private render(): void {
		// Compute delta and elapsed time
		const time = performance.now();
		const delta = time - this.lastTime;
		
		this.gameWorld.update(delta, time);
		this.gameGraphics.render();
		
		this.frameId = requestAnimationFrame(() => {
			// console.warn("RAF");
			this.render();
		});
	}
	
	public resize(): void {
		const {width, height} = this.getSize();
		this.gameGraphics.resize(width, height);
	}
	
	/*public onMouseMove(event) {
		// calculate mouse position in normalized device coordinates
		// (-1 to +1) for both components
		this.gameGraphics.mouseMove(new THREE.Vector2(
			((event.clientX - this.canvas.offsetLeft) / this.canvas.clientWidth) * 2 - 1,
			-((event.clientY - this.canvas.offsetTop) / this.canvas.clientHeight) * 2 + 1)
		);
	}
	
	public onMouseClick(event) {
		// const rect = this.canvas.getBoundingClientRect();
		// calculate mouse position in normalized device coordinates
		// (-1 to +1) for both components
		this.gameGraphics.mouseClick(new THREE.Vector2(
			((event.clientX - this.canvas.offsetLeft) / this.canvas.clientWidth) * 2 - 1,
			-((event.clientY - this.canvas.offsetTop) / this.canvas.clientHeight) * 2 + 1)
		);
	}*/
	
	private getSize(): { width: number, height: number } {
		// const width = this.canvas.clientWidth;
		// const height = this.canvas.clientHeight;
		const width = window.innerWidth - this.canvas.offsetLeft;
		const height = window.innerHeight - this.canvas.offsetTop;
		return {width, height};
	}
}
