import {commander, ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {Commands} from "./Commands";
import {AppEventsPipe, AppEventType, IAppEventNavigateTo} from "../game/AppEventsPipe";
import {ChatService} from "../net/service/ChatService";
import {UserStore} from "../store/UserStore";
import {ChatGamController} from "../game/ChatGamController";
import {NavPage} from "../app/enums/NavPage";

export class ExitGameCommand extends ICommand {
	
	async execute(): Promise<boolean> {
		const appEvents = container.get<AppEventsPipe>(TYPES.AppEventsPipe);
		try {
			await commander.executeCommand(Commands.RELEASE_GAME);
		}
		catch (error) {
			console.warn("ReleaseGame error" + error);
		}
		
		try {
			const data: IAppEventNavigateTo = {route: NavPage.Lobby as string};
			appEvents.send(AppEventType.NavigateTo, data);
			
			const chatPeak = await this.chatService.join(this.userStore.sessionKey, 1);
			this.CGC.startRequestChat(1, chatPeak);
		}
		catch (error) {
			console.error("ExitGameCommand: error: ", error);
			return false;
		}
		return true;
	}
	
	private get chatService(): ChatService {
		return container.get<ChatService>(TYPES.ChatService);
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get CGC(): ChatGamController {
		return container.get<ChatGamController>(TYPES.ChatGamController);
	}
	
}
