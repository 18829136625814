import {ICommand} from "../commander/Commander";
import {TranslateService} from "@ngx-translate/core";
import {Locale} from "../game/enums/Locale";

export class SwitchLanguageCommand extends ICommand {
	
	/*async execute(): Promise<boolean> {
		return true;
	}*/
	private translateService: TranslateService;
	
	async execute({translateService, language = Locale.EN}: { translateService: TranslateService, language: Locale }): Promise<boolean> {
		if (!translateService) {
			console.warn("SwitchLanguageCommand.execute: translate service is required");
			return;
		}
		
		console.log("SwitchLanguageCommand.execute: " + language);
		this.translateService = translateService;
		if (language) {
			if (this.availableLocales.includes(language)) {
				this.setLanguage(language);
			}
		}
		else {
			this.setupLanguages();
		}
		return true;
	}
	
	private get availableLocales(): Locale[] {
		return [Locale.EN, Locale.FR, Locale.ES, Locale.JP, Locale.CN, Locale.RU];
	}
	
	private setupLanguages(): void {
		this.translateService.addLangs(this.availableLocales);
		this.translateService.setDefaultLang("en_US");
		
		const language = this.getDefaultLanguage(this.availableLocales);
		this.setLanguage(language);
	}
	
	private getDefaultLanguage(availableLocales: Locale[]): Locale {
		const storedLang = localStorage.getItem("language") as Locale;
		return (storedLang && availableLocales.includes(storedLang))
			? storedLang
			: availableLocales
				.find(avLocale => avLocale.split("_")[0] === this.translateService.getBrowserLang())
			?? Locale.EN;
	}
	
	private setLanguage(language: Locale) {
		console.log("SwitchLanguageCommand.setLanguage: " + language);
		this.translateService.use(language);
	}
}
