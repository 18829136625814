import {Component, Entity, Types} from "ecsy";
import {DoraType} from "../DoraType";

/*export interface IJMRulesState {
	replaceFlowersInProgress?: boolean;
}*/

export class JMRulesStateComponent extends Component<JMRulesStateComponent> {
	doras: { type: DoraType, tileId: number }[];
	betCounter: number;
	jmCounter: number;
	dealerCounter: number;
	renchanCounter: number;
	imInReadyState: boolean;
	readyCounter: number;
	
	get doraCount() {
		return this.doras.filter(dora => dora.type === DoraType.REGULAR).length;
	}
	
	get uraDoraCount() {
		return this.doras.filter(dora => dora.type === DoraType.URA_DORA).length;
	}
}

JMRulesStateComponent.schema = {
	doras: {type: Types.Array, default: []},
	betCounter: {type: Types.Number, default: 0},
	jmCounter: {type: Types.Number, default: 0},
	dealerCounter: {type: Types.Number, default: 0},
	renchanCounter: {type: Types.Number, default: 0},
	imInReadyState: {type: Types.Boolean, default: false},
	readyCounter: {type: Types.Number, default: 0},
};


export class JMRulesStateComponentWrapper {
	constructor(private entity: Entity) {
		return this;
	}
	
	static wrap(gameStorage: Entity) {
		return new JMRulesStateComponentWrapper(gameStorage);
	}
	
	private get options() {
		return this.entity.getComponent(JMRulesStateComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(JMRulesStateComponent);
	}
	
	public addDora(type: DoraType, tileId: number): void {
		this.mutableOptions.doras.push({type, tileId});
	}
	
	public clearDoras(): void {
		this.mutableOptions.doras = [];
	}
	
	public get doraCount(): number {
		return this.options.doraCount;
	}
	
	public get uraDoraCount(): number {
		return this.options.uraDoraCount;
	}
	
	public get betCounter(): number {
		return this.options.betCounter;
	}
	
	public set betCounter(value) {
		this.mutableOptions.betCounter = value;
	}
	
	public get jmCounter(): number {
		return this.options.jmCounter;
	}
	
	public set jmCounter(value) {
		this.mutableOptions.jmCounter = value;
	}
	
	public get dealerCounter(): number {
		return this.options.dealerCounter;
	}
	
	public set dealerCounter(value) {
		this.mutableOptions.dealerCounter = value;
	}
	
	public get renchanCounter(): number {
		return this.options.renchanCounter;
	}
	
	public set renchanCounter(value) {
		this.mutableOptions.renchanCounter = value;
	}
	
	public get imInReadyState(): boolean {
		return this.options.imInReadyState;
	}
	
	public set imInReadyState(value) {
		this.mutableOptions.imInReadyState = value;
	}
	
	public get readyCounter(): number {
		return this.options.readyCounter;
	}
	
	public set readyCounter(value) {
		this.mutableOptions.readyCounter = value;
	}
	
	
}
