import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Subject, Subscription, timer} from "rxjs";
import {map, take} from "rxjs/operators";
import * as moment from "moment";
// import * as moment from "moment";
// import * as h from "humanize-duration";

@Component({
	selector: "app-count-down",
	template: `{{timer|async}}`,
	styles: [``],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountDownComponent implements OnInit, OnDestroy {
	
	totalSeconds = 0;
	timer: Subject<string> = new Subject<string>();
	protected subscription: Subscription;
	
	@Input()
	get seconds(): number {
		return this.totalSeconds;
	}
	
	set seconds(value: number) {
		this.totalSeconds = value;
		this.start();
	}
	
	constructor() {
	
	}
	
	ngOnInit(): void {
	
	}
	
	ngOnDestroy(): void {
		stop();
	}
	
	start() {
		this.stop();
		this.subscription = timer(0, 1000)
			.pipe(
				take(this.totalSeconds),
				map(v => this.totalSeconds - v),
				map(v => {
					return moment().add(v, "seconds").fromNow();
				}),
			).subscribe(this.timer);
	}
	
	stop() {
		this.subscription?.unsubscribe();
		this.subscription = null;
	}
	
}
