import {Side} from "../../enums/Side";
import {ActionMoveType} from "../../enums/ActionMoveType";
import * as THREE from "three";
import {Vector3} from "three";
import {ActionColors, IRulesOptions} from "../common/GameRulesAbstractSystem";
import {OnlineGameDTO} from "../../../net/dto/OnlineGameDTO";
import {dealDiceCalcCO} from "../../interfaces/TilesSortFn";
import {DiscardsMode} from "../../enums/DiscardsMode";
import {ICreateFormLimits} from "../../../app/ui/lobby/createprivate/i-create-game";
import {ICreateGameOpts} from "../../../net/service/GameService";
import {GameType} from "../../enums/GameType";
import {BeltType} from "../../enums/BeltType";
import {IRulesHelper, LocationTransforms} from "../../helpers/rules-helper";

export const RulesHelperCO: IRulesHelper = {
	getOptions: (tableSettings: OnlineGameDTO): IRulesOptions => {
		return {
			gameType: tableSettings.GameTypeId,
			maxPlayers: 4,
			singleWallLength: tableSettings?.Flowers ? 18 : 17,
			hasDeadWall: false,
			sides: [Side.South, Side.East, Side.North, Side.West], // Sets available sides for current game rules. Should start from South, CCW
			actionsSet: [
				{id: ActionMoveType.PASS, label: "Pass", color: ActionColors.PASS},
				{id: ActionMoveType.CHOW, label: "Chow", color: ActionColors.CHOW},
				{id: ActionMoveType.PUNG, label: "Pung", color: ActionColors.PUNG},
				{id: ActionMoveType.KONG, label: "Kong", color: ActionColors.KONG},
				{id: ActionMoveType.MAHJONG, label: "Mahjong", color: ActionColors.MAHJONG},
			],
			dealDiceCalcFn: dealDiceCalcCO,
			discardsMode: DiscardsMode.SEPARATE,
			isFlower: tileId => (tileId % 100) > 60 && (tileId % 100) < 80,
			
			nextTileFromEnd_Enabled: true,
			nextTileFromEnd_MeldedFlower: true,
			nextTileFromEnd_Kong: true,
		};
	},
	
	getLocationTransforms: (): LocationTransforms => {
		const lc = {};
		lc[Side.South] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 0),
				euler: new Vector3(0, 0, 0),
			};
		lc[Side.West] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 90 * 3.14 / 180),
				euler: new Vector3(0, 90 * 3.14 / 180, 0),
			};
		lc[Side.North] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 180 * 3.14 / 180),
				euler: new Vector3(0, 180 * 3.14 / 180, 0),
			};
		lc[Side.East] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 270 * 3.14 / 180),
				euler: new Vector3(0, 270 * 3.14 / 180, 0),
			};
		return lc;
	},
	
	defaultGameSettings: ((): ICreateGameOpts => {
		return {
			name: "",
			declarePoints: 8,
			description: "",
			enableAutoPass: true,
			entryFee: 250,
			flowers: true,
			forFriends: true,
			gameTypeId: GameType.CO,
			hostSeat: false,
			minBelt: BeltType.White,
			money: false,
			playTimeSec: 3 * 60 * 60,
			rated: true,
			roundsCount: 1,
			showGameHelper: true,
			showWinningHandOnly: false,
			spectators: true,
			timeLimit: 10,
			waitForReconnect: false
		};
	})(),
	
	getCreateLimits: ({rounds}: { rounds: number }): ICreateFormLimits => {
		const entryFee = rounds === 0 ? 250 : 500 * rounds;
		
		return {
			name: {maxLength: 15},
			timeLimit: {min: 5, max: 3600},
			entryFee: {min: entryFee, max: entryFee},
			roundsCount: [
				// {data: 0, label: "QM"},
				{data: 1, label: "1"},
				{data: 2, label: "2"},
				{data: 3, label: "3"},
				{data: 4, label: "4"},
			]
		};
	},
	
	helpTips: [
		{icon: "falseMahjong", title: {key: "Lobby.HOverlays.GameRules.FM_title"}, msgShortRK: {key: "Lobby.HOverlays.GameRules.FM_msg"}},
		/*if (includeKeyboardTip) {
					tipSet[Enum.GameType.CO].push(
						{	icon: SkinLoader.getSkinItem('keyb'), title: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_title"),
							descr: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_msg"),
							descrFull: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_0")
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_space")}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pass")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "C"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.chow")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "P"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pung")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "K"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.kong")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "M"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.mahjong")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "D"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Game.Menu.discard_flower")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "R"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Game.Menu.replace_flower")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_e"), [
											{substr:"%%combination1%%", value: CoreSettings.Res.getValue("Enum.GameMove.chow") + ", " + CoreSettings.Res.getValue("Enum.GameMove.pung") + ", " + CoreSettings.Res.getValue("Enum.GameMove.kong")},
											{substr:"%%combination2%%", value: CoreSettings.Res.getValue("Enum.GameMove.mahjong")}])
								+ "\n" + CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_sp")
						});
				}*/
		{
			icon: "flower",
			title: {key: "Lobby.HOverlays.GameRules.Flowers_titile"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.Flowers_msg"}
		},
	],
};
