export enum LoginAppType {
	WWW = 1,
	DESKTOP_OLD = 2,
	DESKTOP_EON = 60,
	MOBILE_ANDROID_PHONE = 32,
	MOBILE_ANDROID_TABLET = 42,
	MOBILE_IOS_PHONE = 33,
	MOBILE_IOS_TABLET = 43,
	MOBILE_ON_DESTOP = 65,
	
	EPOCH_WEB = 71,
	EPOCH_DESKTOP = 71,
	LITE_WEB = 80,
}
