import {GameRulesAbstractSystem} from "../common/GameRulesAbstractSystem";
import {GameMessageDTO} from "../../../net/dto/GameMessageDTO";
import {IGameAction, RemoveHighLight, SortTilesAction, UpdatePlayerTilesAction} from "../common/GameAction";
import {MoveType} from "../../enums/MoveType";
import {RulesHelperCO} from "./RulesHelperCO";
import {ReplaceFlowers, ReplaceFlowersSide} from "./GameActionsCO";
import {RemoveDialog} from "../wp/GameActionsWP";
import {InGameDialog} from "./enumCO";
import {Attributes} from "ecsy";
import {CORulesStateComponent} from "./components/CORulesStateComponent";
import {DealState} from "../../enums/DealState";
import {fromWallToConcealed, parseIds} from "../common/common.commands";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {TileType} from "../../enums/TileType";

export class GameRulesCOSystem extends GameRulesAbstractSystem {
	
	init(attributes?: Attributes): void {
		super.init(attributes);
		this.getGameStorage().addComponent(CORulesStateComponent);
	}
	
	unregister(): void {
		this.getGameStorage()?.removeComponent(CORulesStateComponent);
		super.unregister();
	}
	
	// @override
	protected setupRules() {
		const joinedGame = this.gameStore.joinedGame;
		const options = RulesHelperCO.getOptions(joinedGame);
		const gs = this.getGameStorageWrapper();
		gs.tableOptions.rulesSettings = options;
		gs.tableOptions.singleWallLength = options.singleWallLength;
		gs.tableOptions.maxPlayers = options.maxPlayers;
		gs.tableOptions.sides = options.sides;
		gs.tableOptions.actionsSet = options.actionsSet;
		gs.tableOptions.hasDeadWall = options.hasDeadWall ?? false;
		gs.tableOptions.dealDiceCalcFn = options.dealDiceCalcFn;
	}
	
	// @override
	protected setupCommands() {
		const cmd = super.setupCommands();
		
		// CO Rules specific
		/* ~~~~~~~~~~ CO Replace Flowers ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ (CO) Chinese Off ~~~~~~
		* makeMove.  peekId=50  from=7  ||   userID=186     Type=END_TAKE_BLOCK (54)     Message=
		* makeMove.  peekId=51  from=6  ||   userID=348     Type=FROM_WALL_TO_CONCEALED (14)     Message=101
		* makeMove.  peekId=52  from=5  ||   userID=1     Type=USER_ID_MOVE_NOW (30)     Message=348
		* makeMove.  peekId=53  from=4  ||   userID=1     Type=START_DEAL (29)     Message=start_deal
		* makeMove.  peekId=54  from=3  ||   userID=1     Type=DEAL_THROWN_DICE (68)     Message=65
		* makeMove.  peekId=55  from=2  ||   userID=1     Type=null (69)     Message=0
		* makeMove.  peekId=56  from=1  ||   userID=1     Type=REPLACE_FLOWERS (50)     Message=start
		* makeMove.  peekId=57  from=0  ||   userID=348     Type=REPLACE_FLOWERS_SIDE (52)     Message=e
		* makeMove.  peekId=58  from=9  ||   userID=348     Type=AUTO_SAID (1)     Message=replace_flower
		* makeMove.  peekId=59  from=8  ||   userID=348     Type=REPLACE_FLOWER (51)     Message=
		* makeMove.  peekId=60  from=7  ||   userID=348     Type=FROM_CONCEALED_TO_MELDED (7)     Message=73
		* makeMove.  peekId=61  from=6  ||   userID=348     Type=FROM_WALL_TO_CONCEALED (14)     Message=101
		* makeMove.  peekId=62  from=5  ||   userID=348     Type=AUTO_SAID (1)     Message=replace_flower
		* makeMove.  peekId=63  from=4  ||   userID=348     Type=REPLACE_FLOWER (51)     Message=
		* makeMove.  peekId=64  from=3  ||   userID=348     Type=FROM_CONCEALED_TO_MELDED (7)     Message=71
		* makeMove.  peekId=65  from=2  ||   userID=348     Type=FROM_WALL_TO_CONCEALED (14)     Message=101
		* makeMove.  peekId=66  from=1  ||   userID=184     Type=REPLACE_FLOWERS_SIDE (52)     Message=s
		* makeMove.  peekId=67  from=0  ||   userID=185     Type=REPLACE_FLOWERS_SIDE (52)     Message=w
		* makeMove.  peekId=68  from=0  ||   userID=186     Type=REPLACE_FLOWERS_SIDE (52)     Message=n
		* makeMove.  peekId=69  from=1  ||   userID=1     Type=REPLACE_FLOWERS (50)     Message=end
		* makeMove.  peekId=70  from=0  ||   userID=1     Type=USER_ID_MOVE_NOW (30)     Message=348
		* makeMove.  peekId=72  from=1  ||   userID=348     Type=FROM_CONCEALED_TO_SLOT (8)     Message=11
		* makeMove.  peekId = 73  from = 0  ||   userID = 1     Type = null (69)     Message = 1
		*/
		cmd[MoveType.REPLACE_FLOWERS] = this.replaceFlowers;
		cmd[MoveType.REPLACE_FLOWERS_SIDE] = this.replaceFlowersSide;
		cmd[MoveType.REPLACE_FLOWER] = this.replaceFlower;
		
		cmd[MoveType.FROM_WALL_TO_CONCEALED] = this.fromWallToConcealed_CO;
		return cmd;
	}
	
	protected gameSnapshotOptions(gameMessage: GameMessageDTO): Array<IGameAction> {
		const actions = super.gameSnapshotOptions(gameMessage);
		if (this.gameStorageQW.dealState.state === DealState.REPLACE_FLOWERS) {
			// if we joined a game in progress and deal is in ReplaceFlowers state, we should manually start ReplaceFlowers flow
			// to disallow user to put tiles (regular put)
			// Unfortunately we dont know exact state of replacing flowers flow - whose turn to replace flowers is, thus we'll just wait for someones move or timeout
			const dto = new GameMessageDTO();
			dto.Type = MoveType.REPLACE_FLOWERS;
			dto.Message = "start";
			const dtoRFS = new GameMessageDTO();
			dtoRFS.UserId = this.gameStorageQW.myPlayerId;
			dtoRFS.Type = MoveType.REPLACE_FLOWERS_SIDE;
			dtoRFS.Message = "";
			return [...actions, ...this.replaceFlowers(dto), ...this.replaceFlowersSide(dtoRFS)];
		}
		else {
			return actions;
		}
	}
	
	private replaceFlowers(gameMessage: GameMessageDTO): Array<IGameAction> {
		const msg: "start" | "end" = gameMessage.Message === "start" ? "start" : "end";
		
		this.gameStorageQW.entity.getMutableComponent(CORulesStateComponent).replaceFlowersInProgress = msg === "start";
		const autoReplace = this.userStore.settings.autoReplaceFlowersCO;
		const actions: IGameAction[] = [
			new ReplaceFlowers(msg, autoReplace, this.gameStorageQW, this.playersQRA, this.appEvents, this.world)
		];
		
		if (msg === "end") {
			actions.push(
				// close ReplaceFlowers dialog if it was opened before
				new RemoveDialog(this.gameEvents, {id: InGameDialog.ReplaceFlowers}),
			);
		}
		return actions;
	}
	
	private replaceFlowersSide(gameMessage: GameMessageDTO): Array<IGameAction> {
		
		if (gameMessage.UserId !== this.gameStorageQW.myPlayerId) {
			// Not my turn to replace flowers: close ReplaceFlowers dialog if it was opened before
			return [
				new RemoveDialog(this.gameEvents, {id: InGameDialog.ReplaceFlowers})
			];
		}
		const autoReplace = this.userStore.settings.autoReplaceFlowersCO;
		return [new ReplaceFlowersSide({
			autoReplace,
			playerId: gameMessage.UserId,
			tileSet: this.tileSet,
			gameStorageQW: this.gameStorageQW,
			gameEventsPipe: this.gameEvents,
			netManager: this.netManager,
		})];
	}
	
	private replaceFlower(gameMessage: GameMessageDTO): Array<IGameAction> {
		// replacing flower
		return [];
	}
	
	private fromWallToConcealed_CO(gameMessage: GameMessageDTO): Array<IGameAction> {
		try {
			if (this.userStore.settings.autoReplaceFlowersCO) {
				const nm = this.netManager;
				parseIds(gameMessage.Message)
					.filter(tileId => this.gameStorageQW.tableOptions.rulesSettings.isFlower(tileId))
					.forEach(tileId => {
						console.log("GameRulesCOSystem.fromWallToConcealed_CO: auto replace: " + tileId);
						nm.makeMove(ActionMoveType.REPLACE_FLOWER, tileId, this.gameStorageQW)
							.catch();
					});
			}
		}
		catch (e) {
			console.warn("GameRulesCOSystem.fromWallToConcealed_CO: " + e);
		}
		return fromWallToConcealed.call(this, gameMessage);
	}
	
	protected startDeal(gameMessage: GameMessageDTO): Array<IGameAction> {
		// return super.startDeal(gameMessage);
		return [
			// common part
			new SortTilesAction(this.gameStore.dealState.viewPlayerId, this.playerLayoutHelper, this.tileSet, this.gameStorageQW.tableOptions.concealedSortFn),
			new UpdatePlayerTilesAction(this.getPlayerEntity(this.gameStore.dealState.viewPlayerId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet),
			// CO override
			/*new StartDealActionCO({
				gameStorage: this.gameStorageQW,
				players: this.playersQRA,
				autoReplaceFlowers: this.userStore.settings.autoReplaceFlowersCO,
				appEventsPipe: this.appEvents,
				w: this.world
			}),*/
		];
	}
	
	protected userIdMoveNow(gameMessage: GameMessageDTO): Array<IGameAction> {
		const actions = super.userIdMoveNow(gameMessage);
		if (gameMessage.UserId !== this.gameStorageQW.myPlayerId) { // remove highlight if its not my turn (can remain after flower replacement)
			actions.push(new RemoveHighLight(this.gameStorageQW.myPlayerId, this.tileSet));
		}
		return actions;
	}
	
}
