export class FlashUIDTO {
// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.FlashUI"
	public TileStyle: number;
	public TileAutoSort: boolean;
	public TileName: boolean;
	public TileComb: boolean;
	
	public HotKeys: boolean;
	public DiscardOnTable: boolean;
	public VoiceChat: boolean;
	public Sound: boolean;
	public SoundVol: number;
	public TilesColor: number;
	public VerticalLayout: boolean;
	public FacebookAuthToken: string;
	public FacebookLike: boolean;
	
	public EnableAnimation: boolean;
	public EnableProfanityFilter: boolean;
	/**
	 * depricated
	 */
	public ShowBeginnerTips: boolean;
	
	public TilesTheme: number;
}
