import {Component, Types} from "ecsy";

export interface ICORulesState {
	replaceFlowersInProgress?: boolean;
}

export class CORulesStateComponent extends Component<CORulesStateComponent> {
	replaceFlowersInProgress: boolean;
}

CORulesStateComponent.schema = {
	replaceFlowersInProgress: {type: Types.Ref, default: false},
};
