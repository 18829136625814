import {ReadyBarWrapper} from "./components/ReadyBarWrapper";
import {PlayerWrapper} from "../wrappers/PlayersArray";
import {Vector3} from "three";

export class ReadyBarHelper {
	static startPoint = {
		initial: new Vector3(-100, 0, 210),
		declared: new Vector3(0, 0, 50),
	};
	
	static updatePosition(bar: ReadyBarWrapper, player: PlayerWrapper) {
		const sp = bar.isDeclared ? this.startPoint.declared : this.startPoint.initial;
		bar.position = sp.clone().applyQuaternion(player.locationTransform.quaternion);
		bar.rotation = player.locationTransform.quaternion.clone();
		
		bar.graphicObject.position.set(bar.position.x, bar.position.y, bar.position.z);
		bar.graphicObject.setRotationFromQuaternion(bar.rotation);
	}
}
