export enum TournamentTab {
	SitnGo = 0,
	Scheduled = 1,
	InPlay = 2,
	Completed = 3,
	MyTournaments = 4,
}

export enum TournamentType {
	/* Official tournament. Contain another one-round-tournaments as its own rounds */
	Official = 0,
	/* Regular tournaments. Can contain more than 1 round (lap) */
	Simple = 10,
}

export enum TournamentMode {
	ONE_ADVANCE = 10,
	TWO_ADVANCE = 20,
}

export enum TournamentState {
	// registration not opened
	EMPTY = 10,
	// registration open
	PREPARING = 20,
	// registration closed
	READY_TO_START = 25,
	// tournament is in play
	PLAYING = 30,
	// paused
	PAUSED = 40,
	// stopped
	STOPPED = 45,
	// finished
	COMPLETED = 50,
	// archived
	STORED = 60,
}
