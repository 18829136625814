import {Component, Entity, TagComponent, Types} from "ecsy";
import {TileType} from "../../enums/TileType";
import * as THREE from "three";
import {Mesh, Object3D, PositionalAudio} from "three";
import {ActionMenuItemGraphics} from "../../graphics/ActionMenuItemGraphics";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {Side} from "../../enums/Side";
import {DealState} from "../../enums/DealState";
import {actionT} from "../ActionMenuSystem";
import {DealStage} from "../../enums/DealStage";
import {DealDiceCalcFn, TilesSortFn} from "../../interfaces/TilesSortFn";
import {InputStreamEvent} from "../../interfaces/InputStreamEvent";
import {Gender} from "../../enums/Gender";
import {ICanMakeMove} from "../../interfaces/ICanMakeMove";
import {IRulesOptions} from "./GameRulesAbstractSystem";
import {TileDataHelper} from "../../helpers/TileDataHelper";
import {LocationTransform} from "../../helpers/rules-helper";


export enum TileState {
	NORMAL = 0, TURNED_BACK = 1, HI = 2, WRONG_MJ = 3, DEFINED_JOKER = 4, R_LEFT = 5, MELD = 6, R_RIGHT = 7, LOCK = 8, MELD_SANMA = 9
}

export class TileDataComponent extends Component<TileDataComponent> {
	public fullId: number;
	
	/*It is also recommended to implement the following functions on every component class:
	  copy(src): Copy the values from the src component.
	*/
	
	toString(): string {
		return `[TileDataComponent: fullId=${this.fullId}]`;
	}
	
	get tinyId(): number {
		return TileDataHelper.getTinyId(this.fullId);
	}
	
	/*isRed5(): boolean {
		return Math.trunc((this.fullId % 10000) / 1000) > 0;
	}*/
	
	/*isWashizu(): boolean {
		return Math.trunc(this.fullId / 10000) > 0;
	}*/
	
	get state(): TileState {
		return Math.trunc((this.fullId % 1000) / 100);
	}
}

TileDataComponent.schema = {
	fullId: {type: Types.Number}
};


export class TileTypeComponent extends Component<TileTypeComponent> {
	public value: TileType;
	
}

TileTypeComponent.schema = {
	value: {type: Types.Ref, default: TileType.WALL}
};

export class PlayerIdComponent extends Component<PlayerIdComponent> {
	// 0 if not assigned to any player (wall tile)
	public playerId: number;
}

PlayerIdComponent.schema = {
	playerId: {type: Types.Number, default: 0}
};

export class InHandIndexComponent extends Component<InHandIndexComponent> {
	public inHandIndex: number;
}

InHandIndexComponent.schema = {
	inHandIndex: {type: Types.Number, default: 0}
};


export class GraphicsComponent extends Component<GraphicsComponent> {
	public obj: Object3D;
	
	constructor(props) {
		super(false);
		this.obj = props ? props.obj : null;
	}
	
	copy(source: this): this {
		this.obj = source.obj;
		// return super.copy(source);
		return this;
	}
	
	// TODO: implement reset method disposing graphic resources and assets
	reset(): void {
		this.obj = null;
	}
	
}

/*GraphicsComponent.schema = {
	obj: {type: Types.Ref}
};*/

export class TilePositionComponent extends Component<TilePositionComponent> {
	public position: THREE.Vector3;
	
	constructor(props) {
		super(false);
		this.position = props ? props.position : new THREE.Vector3();
	}
	
	reset(): void {
		this.position = new THREE.Vector3();
	}
	
	copy(source: this): this {
		this.position = source.position.clone();
		return this;
	}
	
}

/*TilePositionComponent.schema = {
	position: {type: Types.Ref, default: new THREE.Vector3()}
};*/

export class TileRotationComponent extends Component<TileRotationComponent> {
	public rotation: THREE.Quaternion;
}

TileRotationComponent.schema = {
	rotation: {type: Types.Ref, default: new THREE.Quaternion()}
};

export class TileNodesComponent extends Component<TileNodesComponent> {
	public previous: Entity;
	public next: Entity;
}

TileNodesComponent.schema = {
	previous: {type: Types.Ref, default: null},
	next: {type: Types.Ref, default: null},
};

// *************************** Action Menu
export class ActionMenuDataComponent extends Component<ActionMenuDataComponent> {
	public id: ActionMoveType;
	public label: string;
	public declarationId: ActionMoveType;
}

ActionMenuDataComponent.schema = {
	id: {type: Types.Number},
	label: {type: Types.String},
	declarationId: {type: Types.Number},
};

export class ActionMenuGuiComponent extends Component<ActionMenuGuiComponent> {
	public obj: ActionMenuItemGraphics;
}

ActionMenuGuiComponent.schema = {
	obj: {type: Types.Ref}
};


export class ActionMenuUIStateComponent extends Component<ActionMenuUIStateComponent> {
	
	public state: "normal" | "over" | "clicked";
}

ActionMenuUIStateComponent.schema = {
	obj: {type: Types.String, default: "normal"}
};

export class IsVisibleTagComponent extends TagComponent {
}

export class ActionMenuPrevEntity extends Component<ActionMenuPrevEntity> {
	public prevEntity: Entity;
}

ActionMenuPrevEntity.schema = {
	prevEntity: {type: Types.Ref}
};

export class DraggingTagComponent extends TagComponent {

}

export class InputQueryComponent extends Component<InputQueryComponent> {
	public query: InputStreamEvent[] = [];
	
}

InputQueryComponent.schema = {
	query: {type: Types.Ref, default: []},
	
};


export class PrepareToThrowTagComponent extends TagComponent {

}

export class ForceOpenedTagComponent extends TagComponent {

}

export class PlayerStorageTagComponent extends TagComponent {

}


export class LocationComponent extends Component<LocationComponent> {
	public side: string;
}

LocationComponent.schema = {
	side: {type: Types.String},
};

export class PlayerActionsComponent extends Component<PlayerActionsComponent> {
	public actions: ICanMakeMove[];
}

PlayerActionsComponent.schema = {
	actions: {type: Types.Array},
};

export class RealSideComponent extends LocationComponent {

}

export class TimeBankComponent extends Component<TimeBankComponent> {
	public available: number;
	public total: number;
}

TimeBankComponent.schema = {
	available: {type: Types.Number, default: 0},
	total: {type: Types.Number, default: 0},
};


/*
export class RealSideComponent extends Component<LocationComponent> {
	public side: string;
}

RealSideComponent.schema = {
	side: {type: Types.String},
};*/

/*export class PlayerActionsComponent extends Component<PlayerActionsComponent> {
	public locationTransform: LocationTransform;
	public meldedGroup: number;
}

PlayerActionsComponent.schema = {
	locationTransform: {type: Types.Ref},
	meldedGroup: {type: Types.Number, default: 0},
};*/

export class PlayerStoreComponent extends Component<PlayerStoreComponent> {
	public name: string;
	public gender: Gender;
	public locationTransform: LocationTransform;
	/**
	 * additional rotation for concealed tiles (for my hand), in radians
	 */
	public concealedRotationX: number;
	public meldedGroup: number;
}

PlayerStoreComponent.schema = {
	name: {type: Types.String},
	gender: {type: Types.Number, default: Gender.MALE},
	locationTransform: {type: Types.Ref},
	concealedRotationX: {type: Types.Number, default: 0},
	meldedGroup: {type: Types.Number, default: 0},
};

export class TileStoreComponent extends Component<TileStoreComponent> {
	public meldedGroup: number;
	
}

TileStoreComponent.schema = {
	meldedGroup: {type: Types.Number, default: 0},
};

export class GameStorageTagComponent extends TagComponent {

}

export class MoveNowComponent extends Component<MoveNowComponent> {
	public moveNowPlayerId: number;
	public moveNowType: "slot" | "player";
}

MoveNowComponent.schema = {
	moveNowPlayerId: {type: Types.Number, default: 0},
	moveNowType: {type: Types.String, default: ""},
};

export class TurnStateComponent extends Component<TurnStateComponent> {
	public makingMove: boolean;
	public waitingForNextMove: boolean;
	
}

TurnStateComponent.schema = {
	makingMove: {type: Types.Boolean, default: false},
	waitingForNextMove: {type: Types.Boolean, default: false},
};

export class DealStateComponent extends Component<DealStateComponent> {
	public dealNum: number;
	public dealTurn: number;
	public roundWind: Side;
	public state: DealState;
	public stage: DealStage;
	public gameSnapshotProcessing: boolean;
}

DealStateComponent.schema = {
	dealNum: {type: Types.Number, default: 1},
	dealTurn: {type: Types.Number, default: 0},
	roundWind: {type: Types.String, default: undefined},
	state: {type: Types.Number, default: DealState.NA},
	stage: {type: Types.Number, default: DealStage.NA},
	gameSnapshotProcessing: {type: Types.Boolean, default: false},
};

export class TimerComponent extends Component<TimerComponent> {
	public timestamp: number;
	public timerSeconds: number;
	public dealTurn: number;
	public active: boolean;
}

TimerComponent.schema = {
	timestamp: {type: Types.Number, default: 0},
	timerSeconds: {type: Types.Number, default: 0},
	dealTurn: {type: Types.Number, default: 0},
	active: {type: Types.Boolean, default: false},
};

export class ViewStateComponent extends Component<ViewStateComponent> {
	public viewPlayerId: number;
	public basePlayerId: number;
	public myPlayerId: number;
}

ViewStateComponent.schema = {
	viewPlayerId: {type: Types.Number, default: 0},
	basePlayerId: {type: Types.Number, default: 0},
	myPlayerId: {type: Types.Number, default: 0},
};

export class TableOptionsComponent extends Component<TableOptionsComponent> {
	public rulesOptions: IRulesOptions;
	public actionsSet: Array<actionT>;
	public maxPlayers: number;
	public singleWallLength: number;
	public hasDeadWall: boolean;
	public sides: Side[];
	public concealedSortFn?: TilesSortFn;
	public dealDiceCalcFn?: DealDiceCalcFn;
}

TableOptionsComponent.schema = {
	rulesOptions: {type: Types.Ref},
	actionsSet: {type: Types.Array},
	maxPlayers: {type: Types.Number, default: 0},
	singleWallLength: {type: Types.Number, default: 0},
	hasDeadWall: {type: Types.Boolean, default: false},
	sides: {type: Types.Array},
	concealedSortFn: {type: Types.Ref},
	dealDiceCalcFn: {type: Types.Ref},
};


export class GameMessagesComponent<T> extends Component<GameMessagesComponent<T>> {
	public pending: T[];
	public justProcessed: T[];
	public completed: T[];
}

GameMessagesComponent.schema = {
	pending: {type: Types.Array, default: []},
	justProcessed: {type: Types.Array, default: []},
	completed: {type: Types.Array, default: []},
};

export class GameActionsComponent<T> extends Component<GameActionsComponent<T>> {
	public pending: T[];
	public justProcessed: T[];
}

GameActionsComponent.schema = {
	pending: {type: Types.Array, default: []},
	justProcessed: {type: Types.Array, default: []},
};

export class DeadWallTagComponent extends TagComponent {

}


/**
 * Stores all the required props for general game flow
 */
export class GameProcessEnvComponent extends Component<GameProcessEnvComponent> {
	// if true, next FromWallTo* move should be changed to FromDeadWallTo*. Used in HK after taking flower or declaring Kong*.
	public nextTileFromEnd: boolean;
	// Contains current processing move (pung,kong_self)(during conversion to actions) declared by user. Should be set on MAKE_MOVE and cleared on DEAL_TURN and FROM_SLOT_TO_MELDED
	public makeMoveInProgress: ActionMoveType;
	
}

GameProcessEnvComponent.schema = {
	nextConcealedFromEnd: {type: Types.Boolean, default: false},
	makeMoveInProgress: {type: Types.String, default: undefined},
};

export class PlayerSoundComponent extends Component<PlayerSoundComponent> {
	public soundMesh: Mesh;
	public positionalAudio: PositionalAudio;
}

PlayerSoundComponent.schema = {
	soundMesh: {type: Types.Ref, default: null},
	positionalAudio: {type: Types.Ref, default: null},
};


export class PlaySoundComponent extends Component<PlaySoundComponent> {
	public playerId?: number;
	public soundId: string;
}

PlaySoundComponent.schema = {
	playerId: {type: Types.Number, default: 0},
	soundId: {type: Types.String, default: undefined},
};

