import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {IMoveTimerUpdatedParams} from "../../../../../../game/ecs/TimerSystem";

@Component({
	selector: "app-mini-move-timer",
	templateUrl: "./mini-move-timer.component.html",
	styleUrls: ["./mini-move-timer.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniMoveTimerComponent implements OnInit {
	@Input()
	moveTimer: IMoveTimerUpdatedParams;
	
	constructor() {
	}
	
	ngOnInit(): void {
	}
	
}
