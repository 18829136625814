import {ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnInit} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {getRulesHelper, HelpTip} from "../../../../game/helpers/rules-helper";
import {GameType} from "../../../../game/enums/GameType";

@Component({
	selector: "app-help-tips",
	templateUrl: "./help-tips.component.html",
	styleUrls: ["./help-tips.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpTipsComponent implements OnInit {
	
	imgPath = environment.liteRoot + "/assets/img/helpTips/";
	helpTips: HelpTip[];
	
	private gameRules: GameType;
	
	@Input()
	get rules(): GameType {
		return this.gameRules;
	}
	
	set rules(value: GameType) {
		this.gameRules = value;
		this.helpTips = this.gameRules ? getRulesHelper(this.gameRules)?.helpTips ?? [] : [];
		this.hasNextNav = this.helpTips.length > 1 ?? false;
		this.nextRandomTip();
	}
	
	// @Input()
	// do not set manually. depends on helpTips length
	hasNextNav: boolean;
	
	@Input()
		// do not set manually
	displayedTip: HelpTip;
	
	// @Input()
	// do not set manually
	displayedTipFullMessage: string;
	
	
	@HostBinding("class")
	hostClass = "base-box-shadowed";
	
	@HostListener("click")
	onTipClicked(): void {
	
	}
	
	constructor(private translateService: TranslateService) {
	}
	
	ngOnInit(): void {
	}
	
	nextRandomTip() {
		let nextTip: HelpTip;
		if (this.helpTips?.length > 1) {
			
			do {
				nextTip = this.helpTips[Math.trunc(Math.random() * this.helpTips.length)];
			}
			while (nextTip === this.displayedTip);
			this.displayedTip = nextTip;
		}
		else {
			this.displayedTip = this.helpTips ? this.helpTips[0] : null;
		}
		this.displayedTipFullMessage = this.displayedTip ? (this.displayedTip.msgFull ? this.getFullMessage() : this.getShortMessage()) : "";
	}
	
	onNextClick() {
		this.nextRandomTip();
	}
	
	onPrevClick() {
		this.nextRandomTip();
	}
	
	onDetailsClick() {
	
	}
	
	getShortMessage() {
		return this.displayedTip ? this.translateService.instant(this.displayedTip.msgShortRK.key, this.displayedTip.msgShortRK.params) : "";
	}
	
	getFullMessage() {
		return this.displayedTip ? this.displayedTip.msgFull.reduce((previousValue, currentValue) => {
			return previousValue + this.translateService.instant(currentValue.key, currentValue.params);
		}, "") : "";
	}
}
