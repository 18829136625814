import {
	ActionMenuDataComponent,
	GameActionsComponent,
	GameMessagesComponent,
	GameStorageTagComponent,
	GraphicsComponent,
	LocationComponent,
	PlayerStorageTagComponent,
	TileDataComponent,
	TilePositionComponent
} from "./common/components";

export const AllTilesQuery = {
	components: [TileDataComponent, GraphicsComponent, TilePositionComponent]
};
export const PlayersQuery = {
	components: [PlayerStorageTagComponent]
};
export const PlayerActionsQuery = {
	components: [PlayerStorageTagComponent]
};

export const GameStorageQuery = {
	components: [GameStorageTagComponent]
};
export const ActionMenuQuery = {
	components: [ActionMenuDataComponent]
};
export const GameMessagesChangedQuery = {
	// components: [...GameStorageQuery.components, GameMessagesComponent]
	components: [GameMessagesComponent], // we can query only one component as there is only one entity with this component (GameStorage)
	listen: {added: false, removed: false, changed: true}
};

export const GameActionsChangedQuery = {
	// components: [...GameStorageQuery.components, GameMessagesComponent]
	components: [GameActionsComponent], // we can query only one component as there is only one entity with this component (GameStorage)
	listen: {added: false, removed: false, changed: true}
};

export const playerSideChangedQuery = {
	components: [PlayerStorageTagComponent, LocationComponent],
	listen: {
		added: false,
		removed: false,
		changed: [LocationComponent]
	}
};
export const playerAddedOrRemovedQuery = {
	components: [PlayerStorageTagComponent],
	listen: {
		added: true,
		removed: true,
		changed: false, // [LocationComponent]
	}
};
