export enum GameState {
	EMPTY = 10,
	WAITING = 20,
	READY_TO_START = 25,
	PLAYING = 30,
	PAUSED = 40,
	STOPPED = 45,
	COMPLETED = 50,
	STORED = 60,
}

export class GameStateHelper {
	static isFinished(state: GameState): boolean {
		switch (state) {
			case GameState.STOPPED:
			case GameState.COMPLETED:
			case GameState.STORED:
				return true;
			default:
				return false;
		}
	}
}
