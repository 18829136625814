import {GameRulesAbstractSystem} from "../common/GameRulesAbstractSystem";
import {MoveType} from "../../enums/MoveType";
import {fromConcealedToMelded, fromWallEndToMelded, fromWallToMelded, fromWallToMeldedOrFlower, parseIds} from "../common/common.commands";
import {GameDialogType, IGameDialogButtonConfig, IGameDialogConfig} from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {IGameAction, MeldedToSlot, RemoveHighLight, SlotToConcealed, UpdatePlayerTilesAction} from "../common/GameAction";
import {
	CharlestonsStart,
	ClearDeadHand,
	ClearMarkForCharleston,
	ConcealedToMeldedCharleston,
	DeadHand,
	DefineJoker,
	DisplayDialog,
	RedeemJoker,
	RemoveDialog,
	ShowSnack,
	SlotToMeldedWP
} from "./GameActionsWP";
import {DealState} from "../../enums/DealState";
import {GraphicsComponent, PlayerIdComponent, TileDataComponent} from "../common/components";
import {WPRulesStateComponent, IWPRulesState, MarkedForCharlestonTag} from "./components.wp";
import {TileType} from "../../enums/TileType";
import {Attributes, Entity} from "ecsy";
import {GameMessageDTO} from "../../../net/dto/GameMessageDTO";
import {EntityHelper} from "../../helpers/EntityHelper";
import {RulesHelperWP} from "./RulesHelperWP";
import {DealStage} from "../../enums/DealStage";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {AutoPassMode} from "../../enums/AutoPassMode";
import {TileSetHelper} from "../../helpers/TileSetHelper";

export class GameRulesWPSystem extends GameRulesAbstractSystem {
	
	init(attributes?: Attributes): void {
		super.init(attributes);
		
		this.getGameStorage().addComponent(WPRulesStateComponent);
	}
	
	unregister(): void {
		try {
			this.getGameStorage().removeComponent(WPRulesStateComponent);
		}
		catch (e) {
			console.error("GameRulesWPSystem.unregister: " + e);
		}
		super.unregister();
	}
	
	execute(delta: number, time: number): void {
		super.execute(delta, time);
		
		/*if (this.queries.charlestonMark.added.length > 0) {
			this.queries.charlestonMark.added.forEach(entity => this.chooseForCharlestonChanged(entity, this.tileSet));
		}
		if (this.queries.charlestonMark.removed.length > 0) {
			this.queries.charlestonMark.removed.forEach(entity => this.chooseForCharlestonChanged(entity, this.tileSet));
		}*/
		
		if (RulesHelperWP.isCharlestonState(EntityHelper.getDealState(this.getGameStorage()).state)) { // TODO: do this each exec() ?
			try {
				// Do not display/update dialog after charleston ended and deal state changed to playing
				let playerId: number;
				if (this.queries.charlestonMarked.added.length > 0) {
					playerId = this.queries.charlestonMarked.added[0].getComponent(PlayerIdComponent).playerId;
				}
				if (!playerId && this.queries.charlestonMarked.removed.length > 0) {
					playerId = this.queries.charlestonMarked.removed[0].getComponent(PlayerIdComponent).playerId;
				}
				if (playerId) {
					this.gw.addAction(new UpdatePlayerTilesAction(this.getPlayerEntity(playerId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet));
					this.updateCharlestonDialog();
				}
			}
			catch (e) {
				console.error("GameRulesWPSystem.execute: ch: " + e);
			}
		}
	}
	
	// @override
	protected setupRules() {
		const joinedGame = this.gameStore.joinedGame;
		const options = RulesHelperWP.getOptions(joinedGame);
		const gs = this.getGameStorageWrapper();
		gs.tableOptions.rulesSettings = options;
		gs.tableOptions.singleWallLength = options.singleWallLength;
		gs.tableOptions.maxPlayers = options.maxPlayers;
		gs.tableOptions.sides = options.sides;
		gs.tableOptions.actionsSet = options.actionsSet;
		gs.tableOptions.concealedSortFn = options.concealedSortFn;
		gs.tableOptions.hasDeadWall = options.hasDeadWall ?? false;
		gs.tableOptions.dealDiceCalcFn = options.dealDiceCalcFn;
	}
	
	// @override
	protected setupCommands() {
		const cmd = super.setupCommands();
		
		cmd[MoveType.FROM_WALL_TO_MELDED] = fromWallToMeldedOrFlower;
		cmd[MoveType.FROM_DEADWALL_TO_MELDED] = fromWallEndToMelded;
		cmd[MoveType.FROM_CONCEALED_TO_MELDED] = fromConcealedToMelded;
		
		cmd[MoveType.DEFINE_JOKER] = this.defineJoker;
		// Charlestons
		cmd[MoveType.START_CHARLESTONES] = this.startCharlestons;
		cmd[MoveType.CHARLESTONE_1_RIGHT] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_1_OPPOSITE] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_1_LEFT] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_2_RIGHT] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_2_OPPOSITE] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_2_LEFT] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_3_OPPOSITE] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_2_WAIT] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_3_WAIT] = this.charlestonX;
		cmd[MoveType.CHARLESTONE_3_OPPOSITE_TILES] = this.charleston3OppositeTiles;
		cmd[MoveType.CHARLESTONES_END] = this.endCharlestons;
		cmd[MoveType.FROM_CONCEALED_TO_MELDED_CHARLESTONE] = this.fromConcealedToMeldedCharleston;
		cmd[MoveType.FROM_MELDED_TO_USER_CHARLESTONE] = this.fromMeldedToUserCharleston;
		cmd[MoveType.FROM_USER_TO_CONCEALED_CHARLESTONE] = this.fromUserToConcealedCharleston;
		// Redeem (exchange) joker
		/*makeMove.  peekId=226  from=5  ||   userID=1     Type=REDEEM_JOKER_START (44)     Message=63
		makeMove.  peekId=227  from=4  ||   userID=184     Type=FROM_CONCEALED_TO_SLOT (8)     Message=63
		makeMove.  peekId=228  from=3  ||   userID=185     Type=FROM_SLOT_TO_MELDED (11)     Message=63
		makeMove.  peekId=229  from=2  ||   userID=185     Type=REDEEM_JOKER_EXCHANGE_MELDED_ON_SLOT (46)     Message=463
		makeMove.  peekId=230  from=1  ||   userID=184     Type=FROM_SLOT_TO_CONCEALED (9)     Message=81
		makeMove.  peekId=231  from=0  ||   userID=1     Type=REDEEM_JOKER_END (45)     Message=463*/
		cmd[MoveType.REDEEM_JOKER_START] = this.redeemJoker_Start;
		cmd[MoveType.REDEEM_JOKER_END] = this.redeemJoker_End;
		cmd[MoveType.REDEEM_JOKER_EXCHANGE_MELDED_ON_SLOT] = this.redeemJoker_ExchangeMeldedOnSlot;
		cmd[MoveType.FROM_SLOT_TO_MELDED] = this.redeemJoker_FromSlotToMelded;
		// DeadHand
		/*	Message: id=262	 userId=182	 type=DEADHAND_CALL(86)	 message=181
			Message: id=263	 userId=181	 type=DEADHAND_NO(87)	 message=182|CombinationName	*/
		cmd[MoveType.DEADHAND_YES] = this.deadHand;
		cmd[MoveType.DEADHAND_NO] = this.deadHand;
		return cmd;
	}
	
	// ************************************************************************************************************
	// ***  Commands  *********************************************************************************************
	// ************************************************************************************************************
	protected gameSnapshotOptions(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.gameStore.setAutoPassMode(this.gameStore.joinedGame.EnableAutoPass ? AutoPassMode.IGNORE_JOKER : AutoPassMode.DEFAULT);
		return super.gameSnapshotOptions(gameMessage);
	}
	
	protected newDeal(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.gameStore.setAutoPassMode(this.gameStore.joinedGame.EnableAutoPass ? AutoPassMode.IGNORE_JOKER : AutoPassMode.DEFAULT);
		
		return [
			new ClearMarkForCharleston(this.tileSet), // add AM-specific logic before common to all rules
			new ClearDeadHand(this.playersQRA), // Remove all DeadHand tags from players
			...super.newDeal(gameMessage) // common
		];
	}
	
	private defineJoker(gameMessage: GameMessageDTO): Array<IGameAction> {
		const actions: Array<IGameAction> = [];
		// const tileId = parseInt(gameMessage.Message, 10);
		parseIds(gameMessage.Message).forEach(tileId => {
			actions.push(new DefineJoker(tileId, gameMessage.UserId, this.tileSet));
		});
		// actions.push(new RemoveHighLight(gameMessage.UserId, this.tileSet)); // TODO remove highlight after all tiles have moved or after first/each tile?
		// actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet));
		// actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet));
		return actions;
	}
	
	private startCharlestons(): Array<IGameAction> {
		this.gameStorageQW.dealState.stage = DealStage.PREPARING;
		this.setRulesState({charleston3oppositeTiles: 999}); // set high value to choose lower between you and player
		const actions: Array<IGameAction> = [];
		// actions.push(new ShowSnack(this.appEvents, {message: "Charlestons Started", duration: 3000}));
		actions.push(new CharlestonsStart());
		return actions;
	}
	
	private charlestonX(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.gameStorageQW.dealState.state = this.getDealStateByMoveType(gameMessage.Type);
		this.gameStorageQW.turnState.waitingForNextMove = false;
		
		return [
			new ClearMarkForCharleston(this.tileSet),
			new DisplayDialog(this.gameEvents, this.getCharlestonDialogConfig())
		];
	}
	
	private charleston3OppositeTiles(gameMessage: GameMessageDTO): Array<IGameAction> {
		// The lowest value between my and opposite player should be used. A high value is initially set on START_CHARLESTONES move
		if (gameMessage.UserId === this.gameStorageQW.myPlayerId) {
			// if my value is lower than  current - use it
			if (+gameMessage.Message < this.getRulesState().charleston3oppositeTiles) {
				console.log("GameRulesWPSystem.charleston3OppositeTiles: I choose " + gameMessage.Message);
				this.setRulesState({charleston3oppositeTiles: +gameMessage.Message});
			}
		}
		else {
			const myRealside = this.playersQRA.getPlayerById(this.gameStorageQW.myPlayerId).realside;
			const playerRealside = this.playersQRA.getPlayerById(gameMessage.UserId).realside;
			const mySideIndex = this.gameStorageQW.tableOptions.sides.findIndex(s => s === myRealside);
			const playerSideIndex = this.gameStorageQW.tableOptions.sides.findIndex(s => s === playerRealside);
			if (mySideIndex % 2 === playerSideIndex % 2 && +gameMessage.Message < this.getRulesState().charleston3oppositeTiles) {
				// if this is opposite player and he chooses to exchange less tiles than I
				console.log("GameRulesWPSystem.charleston3OppositeTiles: my opponent chooses " + gameMessage.Message);
				this.setRulesState({charleston3oppositeTiles: +gameMessage.Message});
			}
		}
		return [];
	}
	
	private endCharlestons(): Array<IGameAction> {
		const gs = this.gameStorageQW;
		gs.dealState.stage = DealStage.ACTIVE;
		gs.dealState.state = DealState.PLAYING;
		gs.turnState.waitingForNextMove = false;
		
		// charlestoneIsActive = false;
		// currentCharlestone = -1;
		// waitingForNextMove = false;
		// removeHilight(Store.game.participants.getBySide(Enum.Side.SOUTH).userId, 0);
		// remove hilight. there should not be any hilighted tiles after charlestone ends
		//
		// Player.meldedInCenter = false;
		// hideHint(null);
		// removeCharlestoneSelection();
		// closeCharlestonePopup();
		// autoSort(myUserID);
		const viewPlayerId = gs.viewPlayerId;
		const dialogCfg: IGameDialogConfig = {id: InGameDialogWP.Charleston};
		return [
			new ClearMarkForCharleston(this.tileSet),
			new RemoveDialog(this.gameEvents, dialogCfg),
			new RemoveHighLight(viewPlayerId, this.tileSet),
			new ShowSnack(this.appEvents, {message: "Charlestons Ended"}),
		];
	}
	
	private fromConcealedToMeldedCharleston(gameMessage: GameMessageDTO): Array<IGameAction> {
		const tileIds = parseIds(gameMessage.Message);
		
		const actions = tileIds.map<IGameAction>(tileId => new ConcealedToMeldedCharleston(tileId, gameMessage.UserId, this.tileSet));
		actions.push(
			// new ConcealedToMelded(+gameMessage.Message, gameMessage.UserId, this.tileSet),
			new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet),
			new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet),
		);
		return actions;
	}
	
	private fromMeldedToUserCharleston(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new MeldedToSlot(+gameMessage.Message, gameMessage.UserId, this.tileSet),
			new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet),
		];
	}
	
	private fromUserToConcealedCharleston(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new SlotToConcealed(+gameMessage.Message, gameMessage.UserId, this.tileSet),
			new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet),
		];
	}
	
	// Exchange Joker (from melded to slot)
	private redeemJoker_Start(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [new RedeemJoker("start", this.getGameStorage())];
	}
	
	private redeemJoker_FromSlotToMelded(gameMessage: GameMessageDTO): Array<IGameAction> {
		const tileId = +gameMessage.Message;
		return [
			new SlotToMeldedWP(tileId, gameMessage.UserId, this.tileSet, this.getGameStorage()),
			new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet)
		];
	}
	
	private redeemJoker_ExchangeMeldedOnSlot(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new MeldedToSlot(+gameMessage.Message, gameMessage.UserId, this.tileSet),
			new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet),
		];
	}
	
	private redeemJoker_End(gameMessage: GameMessageDTO): Array<IGameAction> {
		// TODO: waitingForNextMove = false; //Player should have posibility to declare mahjong after he: declared kong, then exchanged joker
		return [new RedeemJoker("end", this.getGameStorage())];
	}
	
	private deadHand(gameMessage: GameMessageDTO): Array<IGameAction> {
		// Message: id=262	 userId=182	 type=DEADHAND_CALL(86)	 message=181
		// Message: id=263	 userId=181	 type=DEADHAND_NO(87)	 message=182|CombinationName
		const [playerIdCalled, combination] = gameMessage.Message.split("|");
		const onPlayer = this.playersQRA.getPlayerById(gameMessage.UserId);
		const playerCalled = this.playersQRA.getPlayerById(+playerIdCalled);
		
		const isDead = gameMessage.Type === MoveType.DEADHAND_YES;
		const dhMessage = isDead
			? `${playerCalled.name} called ${onPlayer.name}'s Hand Dead and Won 25 Points.\nHand Is Dead!`
			: `${playerCalled.name} called ${onPlayer.name}'s Hand Dead and Lost 25 Points.\nThe Hand Is Not Dead! %%targetPlayerName%% can at least have: %%targetPlayerComb%% : ${combination}`;
		
		const dialogCfg = {
			id: InGameDialogWP.DeadHand_YN,
			type: GameDialogType.Regular,
			// title: "Dead Hand",
			message: dhMessage,
			messageResourceKey: isDead ? "Game.Message.Am.deadHandTrue" : "Game.Message.Am.deadHandFalse",
			messageResourceParams: {
				declaredPlayerName: playerCalled.name,
				targetPlayerName: onPlayer.name,
				targetPlayerComb: combination
			},
			buttons: [{id: "ok", label: "OK"} as IGameDialogButtonConfig]
		} as IGameDialogConfig;
		
		return [
			new DeadHand({isDead, playerId: +playerIdCalled, onPlayerId: gameMessage.UserId, onPlayerEntity: onPlayer.entity, combination}),
			new DisplayDialog(this.gameEvents, dialogCfg),
		];
	}
	
	// ************************************************************************************************************
	
	private updateCharlestonDialog(): void {
		const dialogCfg = this.getCharlestonDialogConfig();
		this.gw.addAction(new DisplayDialog(this.gameEvents, dialogCfg));
	}
	
	private getMarkedForCharleston(): Entity[] {
		return this.queries.charlestonMarked.results;
	}
	
	private getDealStateByMoveType(moveType: MoveType): DealState {
		switch (moveType) {
			case MoveType.CHARLESTONE_1_RIGHT:
				return DealState.CHARLESTON_1_RIGHT;
			case MoveType.CHARLESTONE_1_OPPOSITE:
				return DealState.CHARLESTON_1_OPPOSITE;
			case MoveType.CHARLESTONE_1_LEFT:
				return DealState.CHARLESTON_1_LEFT;
			
			case MoveType.CHARLESTONE_2_WAIT:
				return DealState.WAIT_FOR_CHARLESTON_2;
			case MoveType.CHARLESTONE_2_RIGHT:
				return DealState.CHARLESTON_2_RIGHT;
			case MoveType.CHARLESTONE_2_OPPOSITE:
				return DealState.CHARLESTON_2_OPPOSITE;
			case MoveType.CHARLESTONE_2_LEFT:
				return DealState.CHARLESTON_2_LEFT;
			
			case MoveType.CHARLESTONE_3_WAIT:
				return DealState.WAIT_FOR_CHARLESTON_3;
			case MoveType.CHARLESTONE_3_OPPOSITE:
				return DealState.CHARLESTON_3_OPPOSITE;
		}
		return null;
	}
	
	private getCharlestonDialogConfig(): IGameDialogConfig {
		const playerId = this.gameStore.dealState.viewPlayerId;
		const marked = this.getMarkedForCharleston();
		const meldedCount = TileSetHelper.getPlayerMeldedTiles(playerId, this.tileSet).length;
		const dialogCfg: IGameDialogConfig = {
			id: InGameDialogWP.Charleston,
			buttons: []
		} as IGameDialogConfig;
		let addPutButton = false;
		let buttons: IGameDialogButtonConfig[] = null;
		
		switch (EntityHelper.getDealState(this.getGameStorage()).state) {
			case DealState.CHARLESTON_1_RIGHT:
				dialogCfg.title = "1st Charleston - Passing Right";
				dialogCfg.titleResourceKey = "Game.Message.Am.ch1Right_sp";
				addPutButton = marked.length + meldedCount === 3;
				break;
			case DealState.CHARLESTON_1_OPPOSITE:
				dialogCfg.title = "1st Charleston - Passing Across";
				dialogCfg.titleResourceKey = "Game.Message.Am.ch1Across_sp";
				addPutButton = marked.length + meldedCount === 3;
				break;
			case DealState.CHARLESTON_1_LEFT: //
				dialogCfg.title = "1st Charleston - Passing Left";
				dialogCfg.titleResourceKey = "Game.Message.Am.ch1Left_sp";
				addPutButton = marked.length + meldedCount <= 3;
				break;
			case DealState.CHARLESTON_2_RIGHT: //
				dialogCfg.title = "2nd Charleston - Passing Right";
				dialogCfg.titleResourceKey = "Game.Message.Am.ch2Right_sp";
				addPutButton = marked.length + meldedCount <= 3;
				break;
			case DealState.CHARLESTON_2_OPPOSITE:
				dialogCfg.title = "2nd Charleston - Passing Across";
				dialogCfg.titleResourceKey = "Game.Message.Am.ch2Across_sp";
				addPutButton = marked.length + meldedCount === 3;
				break;
			case DealState.CHARLESTON_2_LEFT:
				dialogCfg.title = "2nd Charleston - Passing Left";
				dialogCfg.titleResourceKey = "Game.Message.Am.ch2Left_sp";
				addPutButton = marked.length + meldedCount === 3;
				break;
			case DealState.CHARLESTON_3_OPPOSITE:
				dialogCfg.title = "Courtesy Pass";
				dialogCfg.titleResourceKey = "Game.Message.Am.ch3Across_sp";
				const charleston3oppositeTiles = this.getRulesState().charleston3oppositeTiles;
				dialogCfg.message = `Courtesy Pass. Please select ${charleston3oppositeTiles} tiles to throw.`;
				dialogCfg.messageResourceKey = charleston3oppositeTiles === 0 ? "Game.Message.Am.ch3Across_noTiles" : "Game.Message.Am.ch3Across";
				dialogCfg.messageResourceParams = charleston3oppositeTiles === 0 ? undefined : {numOfTiles: charleston3oppositeTiles};
				addPutButton = charleston3oppositeTiles !== 0 && marked.length + meldedCount >= this.getRulesState().charleston3oppositeTiles;
				break;
			case DealState.WAIT_FOR_CHARLESTON_2:
				dialogCfg.title = "Waiting for 2nd Charleston";
				dialogCfg.titleResourceKey = "Game.Message.Am.wait4ch2_sp";
				dialogCfg.message = "Would you like to do the Charleston again?";
				dialogCfg.messageResourceKey = "Game.Message.Am.wait4ch2";
				buttons = [
					{id: "yes", label: "Yes", labelResourceKey: "UI.Basic.YES"},
					{id: "no", label: "No", labelResourceKey: "UI.Basic.NO"}
				];
				break;
			case DealState.WAIT_FOR_CHARLESTON_3:
				dialogCfg.title = "Waiting for 3rd Charleston";
				dialogCfg.titleResourceKey = "Game.Message.Am.wait4ch3_sp";
				dialogCfg.message = "How many tiles would you like to pass across as a courtesy pass?";
				dialogCfg.messageResourceKey = "Game.Message.Am.wait4ch3";
				buttons = [
					{id: "c3", label: "1", data: ActionMoveType.CHARLESTON_1},
					{id: "c3", label: "2", data: ActionMoveType.CHARLESTON_2},
					{id: "c3", label: "3", data: ActionMoveType.CHARLESTON_3},
					{id: "c3", label: "0", data: ActionMoveType.CHARLESTON_0},
				];
				break;
			// default:
		}
		if (addPutButton) {
			dialogCfg.buttons.push({id: "put_tiles", label: "Put Tiles", labelResourceKey: "Game.Message.Am.btnPutTiles"});
		}
		if (buttons) { // ask to confirm charleston 2 or chose tile count to exchange for charleston 3
			dialogCfg.buttons = buttons;
		}
		return dialogCfg;
	}
	
	private getRulesState(): IWPRulesState {
		return this.getGameStorage().getComponent(WPRulesStateComponent);
	}
	
	private setRulesState(state: IWPRulesState): void {
		const comp = this.getGameStorage().getMutableComponent(WPRulesStateComponent);
		if (state.charleston3oppositeTiles) {
			comp.charleston3oppositeTiles = state.charleston3oppositeTiles;
		}
		if (state.redeemInProgress) {
			comp.redeemInProgress = state.redeemInProgress;
		}
		if (state.redeem_position) {
			comp.redeem_position = state.redeem_position;
		}
		if (state.redeem_ClickedTile) {
			comp.redeem_ClickedTile = state.redeem_ClickedTile;
		}
	}
	
}

GameRulesWPSystem.queries.charlestonMarked = {
	components: [TileDataComponent, GraphicsComponent, MarkedForCharlestonTag],
	listen: {
		added: true,
		removed: true,
		changed: false
	}
};

export enum InGameDialogWP {
	Charleston = "dialog.Am.charleston",
	DeadHand = "dialog.Am.deadHand",
	DeadHand_YN = "dialog.Am.deadHand_yes_no",
	KongWithJokersDialog = "dialog.Am.KongWithJokersDialog",
}
