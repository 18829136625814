import {Injectable, OnDestroy} from "@angular/core";
import {LeaveGameDialogComponent} from "../ui/lobby/table/ingame/LeaveGameDialogComponent";
import {commander} from "../../commander/Commander";
import {Commands} from "../../commands/Commands";
import {MatDialog} from "@angular/material/dialog";
import {GameService} from "../../net/service/GameService";
import {GameStore} from "../../store/GameStore";
import {UserStore} from "../../store/UserStore";
import container from "../../inversify/inversify.config";
import {TYPES} from "../../inversify/inversify.types";
import {Observable} from "rxjs";
import {OnlineGameDTO} from "../../net/dto/OnlineGameDTO";

@Injectable({providedIn: "root"})
export class GameUIService implements OnDestroy {
	private gameService: GameService;
	private gameStore: GameStore;
	private userStore: UserStore;
	
	constructor(
		public dialog: MatDialog,
	) {
		this.gameService = container.get(TYPES.GameService);
		this.gameStore = container.get(TYPES.GameStore);
		this.userStore = container.get(TYPES.UserStore);
	}
	
	ngOnDestroy(): void {
	
	}
	
	get joinedGame(): OnlineGameDTO {
		return this.gameStore.joinedGame;
	}
	
	doLeave(): Observable<string> {
		console.warn("TableComponent: Leave Table");
		return this.openLeaveConfirmDialog();
	}
	
	
	public openLeaveConfirmDialog(): Observable<string> {
		const dialogRef = this.dialog.open(LeaveGameDialogComponent, {
			disableClose: true,
		});
		dialogRef.afterClosed().subscribe((result) => {
			console.log("The dialog was closed", result);
			switch (result) {
				case "leave":
					this.gameService.leave(this.userStore.sessionKey, this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId)
						.catch(e => console.warn("GameUIService.leaveGame: error:" + e));
					commander.executeCommand(Commands.EXIT_GAME)
						.catch(e => console.warn("GameUIService.leaveGame: error exiting: " + e));
					break;
			}
		});
		return dialogRef.afterClosed();
	}
}
