
   
    
    <!-- User welcome message and rating details -->


        <!-- Transaction buttons with icons -->
      <!-- Top bar with transaction buttons and balance -->


        <!-- Debug Helper - make sure this is only visible in debug mode -->
      <!-- <div *ngIf="isDebug" class="debug-helper">
            <app-debu
            g-helper matTooltip="[Dev]: Debug Helper"></app-debug-helper>
        </div> -->
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <header class="top-bar">
            <h1>&nbsp;Welcome, {{ userName }}</h1><div class="balance-display">
               <!-- <h2>Balance: {{ cashBalance | currency:'USD':'symbol-narrow':'1.2-2' }}</h2>-->
               <button mat-mini-fab (click)="switchFullscreen()" color="secondary" matTooltip="{{'Lobby.MainMenu.FullScreen'|translate}}">
                    <i class="material-icons">fullscreen</i>
                </button>
            </div>
          </header>
        <div class="top-bar2">


            <!--
            <div *ngIf="hasTournamentBox || hasJackpotBox" class="tournament-info">
                <span class="tour-line">Balance: ${{cashBalance}}</span>
                <button mat-raised-button (click)="onDepositClick()">Add Funds</button>
                <button mat-raised-button (click)="onWithdrawClick()">Withdraw</button>
                <app-debug-helper *ngIf="isDebug" matTooltip="[Dev]: Debug Helper"></app-debug-helper>
            </div>

            <div class="transaction-buttons">
                <button (click)="onBuyChipsClick()">Buy Chips</button>
                <button (click)="onDepositClick()">Add Funds</button>
                <button (click)="onWithdrawClick()">Withdraw</button>
            </div>
            -->

            

        </div>
        <div class="main-content">
            <app-chat></app-chat> 
   <div class="c">
    
    <div *ngIf="haveActiveGames" class="mygames">
        <span class="dataHeader">My Tables</span>
        <div class="my-tables-section">
          <div *ngIf="haveActiveGames" style="display: flex; flex-direction: column; justify-content:center; flex-wrap: wrap;">
            <table-card-small *ngFor="let game of myActiveGames" [table]="game" (actionEmitter)="onTableAction($event)"></table-card-small>
          </div>
        </div>
      </div>

       
     <!-- <button mat-raised-button (click)="switchMode('expert')" *ngIf="isDebug">Switch to Expert</button> -->
     
        
    <div class="c1">   
        
            <!-- Game Types -->

            <div class="container">       

                <div *ngIf="hasSchoolBox" class="box" (click)="userSelected('school')">
                    <img class="iconx"
                        src="assets/img/playBots.png" alt="Flight Academy icon" >
                    <div class="data1">
                        Flight Academy
                       <!--<p>AI Skies of Knowledge</p>-->
                    </div>
                </div>
                
                <div class="box" (click)="userSelected('fun')"
                        [ngClass]="hasFunBox?'':'box-disabled'"
                        matTooltip="{{hasFunBox?'':'Lobby.MainTabs.Games.PlaySchoolFirst'|translate}}">
                        <img class="iconx"
                            src="assets/img/playHuman.png" alt="Sky Duels icon" >
                        <div class="data1">
                            Sky Duels
                           <!--  <span>Wings of Rivalry</span>-->
                        </div>
                </div>   
        
                <div class="box" (click)="userSelected('private')">
                    <img class="iconx"
                        src="assets/img/private.png" alt="Private Sky Clash icon" >
                    <div class="data1">
                        Private Sky Clash
                        <!--<span>Wings Among Friends</span>-->
                    </div>
                </div>

                <div *ngIf="hasTournamentBox" class="box" (click)="goTournaments()">
                    <img class="iconx"
                        src="assets/img/private.png"  alt="Tournaments icon" >
                    <div class="data1">
                        <h2>Tournaments</h2>
                        <span>{{"Lite.Screen.Games.CompeteForPrizes"|translate}}</span>
                    </div>
                </div>

                <div *ngIf="hasJackpotBox" class="box" (click)="goJackpot()">
                    <img class="iconx"
                        src="assets/img/jackpot.png" alt="Jackpot icon" >
                    <div class="data">
                        <h2 style="margin-bottom: 5px;">Jackpot</h2>
                        <span>Prize: ${{jackpotInfo.Prize}}</span>
                        <!--<span>Ends: {{jackpotInfo.SecLeft}}</span>-->
                        <span>Ends In: <app-count-down [seconds]="jackpotInfo.SecLeft"></app-count-down></span>
                        <span>Top score: {{jackpotInfo.TopScore}}</span>
                        <span>Your score: {{jackpotInfo.UserScoreNeat}}</span>
                    </div>
                </div>
            </div>

        <div class="rating-box" >         
           
                <div class="bgHeader"><p class= "dataHeader"> {{'Enum.GameTypes.' + gameTypeId|translate:{NMJLCardVersion: userStore.info.NMJLCardVersion} }} Rating Details</p></div><BR>
                <p class= "data"><strong>Rating: {{ ratingType20.Rating | number:'1.0-2' }}</strong></p>
                <p class="data"><strong>Belt:&nbsp;</strong><img  [src]="getBeltImagePath(ratingType20.Belt)" alt="Belt" style="max-width: 50px; max-height: 20px;"></p>
                <p class= "data"><strong>Rank: {{ getRankDescription(ratingType20.Rank) }}</strong> </p>

                <div class="Tournaments">
                    <!--<button  >Tournaments</button>-->
                    
                </div>
                
        </div>

       
</div>
       
   <!-- <div class="return-to-game" *ngIf="myActiveGame">
        <div id="dialog" class="base-box-shadowed centered-abs">
            <span>{{'Lobby.MainTabs.Games.ReturnMsg'|translate:{gameName: myActiveGame.Name} }}</span>
            <div style="display:flex; gap: .8rem;">
                <button mat-raised-button color="primary"
                        (click)="onReturnToMyGame()">{{'Lobby.MainTabs.Games.ReturnBtn'|translate}}</button>
                <button *ngIf="isPrivateGame(myActiveGame)" mat-raised-button (click)="myActiveGame=null">
                    {{'UI.Basic.CLOSE'|translate}}
                </button>
            </div>
            <button *ngIf="isDebug" mat-raised-button (click)="myActiveGame=null">
                DBG: Close
            </button>
        </div>
    </div> -->   
        <!-- ... other content ... -->
       
</div>






