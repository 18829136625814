import {inject, injectable} from "inversify";
import {action, computed, observable, runInAction} from "mobx";
import {JoinedUserType} from "../game/enums/JoinedUserType";
import {Side} from "../game/enums/Side";
import {OnlineGameDTO} from "../net/dto/OnlineGameDTO";
import {GameUserInfoDTO} from "../net/dto/GameUserInfoDTO";
import {GameMessageDTO} from "../net/dto/GameMessageDTO";
import {AutoPassMode} from "../game/enums/AutoPassMode";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "./UserStore";
import {GameService} from "../net/service/GameService";
import {ISession} from "../inversify/inversify.config";
import {GameUserPointsDTO} from "../net/dto/GameUserPointsDTO";

@injectable()
export class GameStore {
	
	private _initMoves: Array<GameMessageDTO> = [];
	public readonly dealState: CurrentDealState = new CurrentDealState();
	// @observable
	public readonly gameUsers: GameUsers = new GameUsers();
	// public readonly points: GamePoints = new GamePoints();
	public joinedGame: OnlineGameDTO;
	
	@observable
	autoPassOption = AutoPassMode.DEFAULT;
	
	constructor(
		@inject(TYPES.SessionKey) private session: ISession,
		@inject(TYPES.UserStore) private userStore: UserStore,
		@inject(TYPES.GameService) private gameService: GameService,
	) {
	
	}
	
	public setInitMoves(moves: Array<GameMessageDTO>) {
		this._initMoves = moves;
	}
	
	public getInitMoves(): Array<GameMessageDTO> {
		return this._initMoves;
	}
	
	reset() {
		this.gameUsers.reset();
		this.dealState.reset();
		this.joinedGame = null;
		this._initMoves = [];
	}
	
	@action
	resetAutoPassMode() {
		this.autoPassOption = AutoPassMode.DEFAULT;
	}
	
	@action
	setAutoPassMode(value: AutoPassMode) {
		if (!this.joinedGame) {
			console.warn("GameStore.setAutoPassMode: cannot set autoPassMode -- there is no joined game");
			return;
		}
		this.gameService.changeAutoPassMode(this.session.sessionKey, this.joinedGame.RoomId, this.joinedGame.GameId, value)
			.then(result => {
				runInAction(() => {
					console.log("GameStore.setAutoPassMode: newMode=" + result);
					// this.autoPassOption = result.result ?? AutoPassMode.DEFAULT;
					this.autoPassOption = value ?? AutoPassMode.DEFAULT;
				});
			})
			.catch(err => {
				console.warn("TableSettings.setAutoPassMode: error setting mode=" + value);
			});
	}
	
}

export class CurrentDealState {
	
	/**
	 * All the single-directional elements (By ex.: Common Discards, some indicators, etc.) are rotated towards base player.
	 * Is set only once at the beginning of the game (startTable())
	 */
	public basePlayerId = 0;
	
	/**
	 * The player we are currently looking at. Changes when we rotating table and switching to another player's hand.
	 */
	public viewPlayerId = 0;
	
	constructor() {
	
	}
	
	public reset() {
	
	}
}

export interface IGamePlayer extends GameUserInfoDTO {
	Location?: Side;
	Points?: number;
	PointsDTO?: GameUserPointsDTO;
}

class GameUsers {
	
	@observable
	public readonly users: Array<IGamePlayer> = [];
	
	/**
	 * Returns only players (users with status==PLAYER)
	 */
	@computed
	public get players(): Array<IGamePlayer> { // reacts only on add/remove item AND changing status
		return this.users.filter(user => user.StateId === JoinedUserType.PLAYER);
	}
	
	public getPlayers(): Array<IGamePlayer> { // reacts only on add/remove item AND changing status
		return this.users.filter(user => user.StateId === JoinedUserType.PLAYER);
	}
	
	@action
	public add(gameUser: IGamePlayer): void {
		if (this.getUserById(gameUser.UserId)) { // existing user should be removed so the changes of 'users' list will be detected by MobX in @computed:players
			this.deleteUser(gameUser.UserId);
		}
		gameUser.Location ??= gameUser.Side;
		gameUser.Points ??= 0;
		// when we pass instance of GameUserInfoDTO as gameUser it doesnt have observable fields and no changes will be detected.
		// destructuring allows mobx to convert this object to a Proxy and observe all changes
		this.users.push({...gameUser});
	}
	
	@action
	public deleteUser(userId: number): boolean {
		const index = this.users.findIndex(user => user.UserId === userId);
		if (~index) {
			this.users.splice(index, 1);
			return true;
		}
		else {
			return false;
		}
	}
	
	@action
	public updateUser(userId: number, user: Partial<IGamePlayer>): boolean {
		const exUser = this.getUserById(userId);
		if (!exUser) {
			return false;
		}
		Object.assign(exUser, user);
		exUser.Location ??= exUser.Side;
		console.log(`GameUsers.update: updated = ${exUser}`);
	}
	
	public getUserById(id: number): IGamePlayer {
		return this.users.find(elem => elem.UserId === id);
	}
	
	public getPlayerByRealSide(side: Side): IGamePlayer {
		return this.users.find(elem => elem.StateId === JoinedUserType.PLAYER && elem.Side === side);
	}
	
	public reset(): void {
		this.users.length = 0;
	}
}




