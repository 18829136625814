import {GameMessagesController} from "../game/GameMessagesController";

export const TYPES = {
	LoginService: Symbol("LoginService"),
	UserService: Symbol("UserService"),
	ChatService: Symbol("ChatService"),
	GameService: Symbol("GameService"),
	GameListService: Symbol("GameListService"),
	TournamentService: Symbol("TournamentService"),
	TournamentListService: Symbol("TournamentListService"),
	JackpotService: Symbol("JackpotService"),
	OnlineUserService: Symbol("OnlineUserService"),
	GameEventsPipe: Symbol("GameEventsPipe"),
	AppEventsPipe: Symbol("AppEventsPipe"),
	
	ChatGamController: Symbol("ChatGamController"),
	GameAccessHelper: Symbol("GameAccessHelper"),
	
	GameMessagesController: Symbol("GameMessagesController"),
	
	AMFConnectionBroker: Symbol("AMFConnectionBroker"),
	GameStore: Symbol("GameStore"),
	UserStore: Symbol("UserStore"),
	AppStore: Symbol("AppStore"),
	
	SessionKey: Symbol("SessionKey"),
	
	NetGameManager: Symbol("NetGameManager: S"),
	
	GameGraphics: Symbol("GameGraphics"),
	GameWorld: Symbol("GameWorld"),
	PlayerLayoutHelper: Symbol("PlayerLayoutHelper"),
	TexturePack: Symbol("TexturePack"),
	GameController: Symbol("GameController"),
};
