import {IOnlineGame, OnlineGameDTO} from "../../net/dto/OnlineGameDTO";
import {Gender} from "../enums/Gender";
import {GameState} from "../enums/GameState";
import {GameType} from "../enums/GameType";
import {GameStateDTO} from "../../net/dto/GameStateDTO";
import {JoinedUserType} from "../enums/JoinedUserType";
import {Side} from "../enums/Side";
import {GameUserInfoDTO} from "../../net/dto/GameUserInfoDTO";
import {MoveType} from "../enums/MoveType";
import {GameMessageDTO} from "../../net/dto/GameMessageDTO";


class OnlineGameWrap {
	private PlayerIds = {
		South: 888,
		East: 2,
		North: 3,
		West: 4,
	};
	
	static createDTO() {
		return new OnlineGameDTO();
	}
	
	static wrap(dto: OnlineGameDTO) {
		return new OnlineGameWrap(dto);
	}
	
	static createAndWrap() {
		return OnlineGameWrap.wrap(OnlineGameWrap.createDTO());
	}
	
	
	constructor(public dto: OnlineGameDTO) {
	}
	
	getDTO(): OnlineGameDTO {
		return this.dto;
	}
	
	addPlayer(sideName: "East" | "North" | "West" | "South", player?: { Id?: number }): this {
		const playerId = this.dto[sideName + "Id"] = player?.Id ?? (this.PlayerIds)[sideName];
		this.dto[sideName + "Name"] = `${playerId}: Player ${sideName}`;
		this.dto[sideName + "Gender"] = Gender.MALE;
		return this;
	}
	
	setGameSettings(settings: IOnlineGame): this {
		this.dto = Object.assign(this.dto, settings);
		return this;
	}
	
}


export enum GAMES {
	HK
}


export class FakeGameHelper {
	
	private static _presetGames: { [key: number]: { settings, state } };
	private static defaultSettingsHK: IOnlineGame = {
		GameTypeId: GameType.HK,
		StateId: GameState.EMPTY,
		
	} as IOnlineGame;
	
	public static get presetGames() {
		if (!this._presetGames) {
			this._presetGames = {};
			this._presetGames[GAMES.HK] = (() => {
				const settings = OnlineGameWrap.createAndWrap()
					.setGameSettings(this.defaultSettingsHK)
					.setGameSettings({GameId: GAMES.HK, Name: "Four-Players-Game", StateId: GameState.PLAYING, IsMyGame: true})
					// .addPlayer("South", {Id: this.userStore.id})
					// .addPlayer("South", opts?.myUserId ? {Id: opts.myUserId} : undefined)
					.addPlayer("South")
					.addPlayer("East")
					.addPlayer("North")
					.addPlayer("West")
					.dto;
				const state = new GameStateDTO();
				state.Settings = settings;
				state.StateId = settings.StateId;
				state.GameUsers = [
					this.createFakeUser(state.Settings.SouthId, JoinedUserType.PLAYER, Side.South),
					this.createFakeUser(state.Settings.EastId, JoinedUserType.PLAYER, Side.East),
					this.createFakeUser(state.Settings.NorthId, JoinedUserType.PLAYER, Side.North),
					this.createFakeUser(state.Settings.WestId, JoinedUserType.PLAYER, Side.West),
				];
				return {settings, state};
			})();
		}
		return this._presetGames;
	}
	
	public static get presetGamesList() {
		return Object.values(this.presetGames);
	}
	
	public static getGameSettings(game: GAMES): OnlineGameDTO {
		return this.presetGames[game].settings;
		
	}
	
	public static getGameState(game: GAMES): GameStateDTO {
		return this.presetGames[game].state;
	}
	
	private static createFakeUser = (id: number, status: JoinedUserType, side: Side): GameUserInfoDTO => {
		const user = new GameUserInfoDTO();
		user.UserId = id;
		user.Name = "User " + user.UserId;
		user.StateId = status;
		user.Side = side;
		return user;
	};
	public static getFakeGameMessages = (userId) => {
		return [
			[],
			[
				FakeGameHelper.createFakeGameMessage({UserId: userId, Type: MoveType.FROM_WALL_TO_CONCEALED, Message: "211^212^213^214"}),
				FakeGameHelper.createFakeGameMessage({UserId: userId, Type: MoveType.END_TAKE_BLOCK, Message: "211^212^213^214"}),
				FakeGameHelper.createFakeGameMessage({UserId: userId, Type: MoveType.FROM_WALL_TO_MELDED, Message: "531"}),
			
			],
			[
				FakeGameHelper.createFakeGameMessage({UserId: userId, Type: MoveType.FROM_CONCEALED_TO_SLOT, Message: "13"}),
				FakeGameHelper.createFakeGameMessage({UserId: userId, Type: MoveType.FROM_SLOT_TO_DISCARDS, Message: "13"}),
			],
			[
				FakeGameHelper.createFakeGameMessage({UserId: userId, Type: MoveType.USER_CAN_MAKE_MOVE, Message: "pass|pung"}),
			],
			/*[
				createFakeGameMessage(3003, MoveType.USER_CAN_MAKE_MOVE, "pass|pung")
			],*/
			// []
		];
	};
	private static createFakeGameMessage = (opts: { Id?: number, UserId: number, Type: MoveType, Message: string }): GameMessageDTO => {
		opts.Id = opts.Id ?? 1;
		return Object.assign(new GameMessageDTO(), opts);
	};
	
}
