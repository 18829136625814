<mat-card>
    <mat-card-title>{{'Lobby.CreateGame.Caption'|translate}}</mat-card-title>
    <br/>
    <mat-card-content>
        <div *ngIf="createLimits">
            <form [formGroup]="createGameForm" (ngSubmit)="doSubmit()" class="create-form">

                <!--NAME-->
                <mat-form-field>
                    <mat-label>{{'Lobby.Login.Name'|translate}}</mat-label>
                    <input matInput formControlName="name">
                    <!--<span matPrefix>PG-</span>-->
                    <mat-error *ngIf="createGameForm.get('name').errors?.required">
                        {{'Lobby.CreateGame.InputErrors.Text.requiredField'|translate}}</mat-error>
                    <mat-error *ngIf="createGameForm.get('name').errors?.maxlength">
                        {{'Error.593'|translate}}</mat-error>
                </mat-form-field>

                <ng-template [ngIf]="createLimits.declarePoints">
                    <ng-container
                            *ngIf="createLimits.declarePoints.values; then dpSelection; else dpNumber;">
                        <!--If declarePoints.values is undefined a numeric stepper will be used;
                        and Select otherwise-->
                    </ng-container>
                    <ng-template #dpSelection>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'Lobby.CreateGame.MinPoints'|translate}}</mat-label>
                            <mat-select formControlName="declarePoints">
                                <mat-option *ngFor="let r of createLimits.declarePoints.values"
                                            [value]="r.data">{{r.label}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                    <ng-template #dpNumber>
                        <mat-form-field>
                            <mat-label>{{'Lobby.CreateGame.MinPoints'|translate}}</mat-label>
                            <input type="number" matInput formControlName="declarePoints"
                                   [(ngModel)]="currentValues.declarePoints"
                                   min="{{createLimits.declarePoints.min}}" max="{{createLimits.declarePoints.max}}">
                            <mat-error *ngIf="createGameForm.get('declarePoints').errors?.required">
                                {{'Lobby.CreateGame.InputErrors.Text.requiredField'|translate}}</mat-error>
                            <mat-error
                                    *ngIf="createGameForm.get('declarePoints').errors?.min || createGameForm.get('declarePoints').errors?.max">
                                Min is {{createLimits.declarePoints.min}} and max
                                is {{createLimits.declarePoints.max}}</mat-error>
                        </mat-form-field>
                    </ng-template>
                </ng-template>

                <!--ROUNDS-->
                <mat-form-field appearance="fill">
                    <mat-label>{{'Lobby.CreateGame.NumRounds'|translate}}</mat-label>
                    <mat-select formControlName="roundsCount">
                        <mat-option *ngFor="let r of createLimits.roundsCount" [value]="r.data">{{r.label}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!--TIME LIMIT-->
                <mat-form-field>
                    <mat-label>{{'Lobby.InfoTabs.TableInfo.MoveTime'|translate}}</mat-label>

                    <input type="number" matInput formControlName="timeLimit"
                           [(ngModel)]="currentValues.timeLimit"
                           min="{{createLimits.timeLimit.min}}" max="{{createLimits.timeLimit.max}}">
                    <mat-error *ngIf="createGameForm.get('timeLimit').errors?.required">
                        {{'Lobby.CreateGame.InputErrors.Text.requiredField'|translate}}</mat-error>
                    <mat-error
                            *ngIf="createGameForm.get('timeLimit').errors?.min || createGameForm.get('timeLimit').errors?.max">
                        Min is {{createLimits.timeLimit.min}} and max
                        is {{createLimits.timeLimit.max}}</mat-error>

                </mat-form-field>

                <div class="button"
                     style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center; gap: .5rem">
                    <button type="submit" mat-raised-button color="primary"
                            [disabled]="loading || !createGameForm.valid">{{'Lobby.CreateGame.Caption'|translate}}
                    </button>
                    <button type="button" mat-raised-button color="secondary" (click)="onClose()"
                            [disabled]="loading">{{'Lobby.CreateGame.Close'|translate}}
                    </button>
                </div>

            </form>
        </div>

    </mat-card-content>

</mat-card>

<div *ngIf="isDebug" style="position: fixed; display: flex; flex-direction: column; left: 1rem; bottom: 1rem;"
     class="base-box-shadowed">
    <span style="margin-bottom: .5rem;"><b>[Debug]</b> Check the values:</span>
    <span *ngFor="let row of debugSettings">{{row[0]}}: <b>{{row[1]}}</b></span>
</div>
