import {commander, ICommand} from "../commander/Commander";
import {ChatGamController} from "../game/ChatGamController";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {ChatService} from "../net/service/ChatService";
import {UserStore} from "../store/UserStore";
import {GameService} from "../net/service/GameService";
import {GameStore} from "../store/GameStore";
import {GameController} from "../game/GameController";
import {Commands} from "./Commands";

export class ReleaseGameCommand extends ICommand {
	
	async execute(): Promise<boolean> {
		try {
			const gameController = container.get<GameController>(TYPES.GameController);
			gameController.dispose();
			const cgc = container.get<ChatGamController>(TYPES.ChatGamController);
			cgc.stopRequesting();
			this.gameStore.reset();
			await commander.executeCommand(Commands.UPDATE_ONLINE_STATUS);
			return true;
		}
		catch (e) {
			return false;
		}
	}
	
	get chatService(): ChatService {
		return container.get<ChatService>(TYPES.ChatService);
	}
	
	get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
	get gameService(): GameService {
		return container.get<GameService>(TYPES.GameService);
	}
	
	get CGC(): ChatGamController {
		return container.get<ChatGamController>(TYPES.ChatGamController);
	}
	
}
