import {Side} from "../../enums/Side";
import {ActionMoveType} from "../../enums/ActionMoveType";
import * as THREE from "three";
import {Vector3} from "three";
import {ActionColors, IRulesOptions} from "../common/GameRulesAbstractSystem";
import {OnlineGameDTO} from "../../../net/dto/OnlineGameDTO";
import {dealDiceCalcHK} from "../../interfaces/TilesSortFn";
import {DiscardsMode} from "../../enums/DiscardsMode";
import {Entity} from "ecsy";
import {TileSetHelper} from "../../helpers/TileSetHelper";
import {ICreateGameOpts} from "../../../net/service/GameService";
import {GameType} from "../../enums/GameType";
import {BeltType} from "../../enums/BeltType";
import {ICreateFormLimits} from "../../../app/ui/lobby/createprivate/i-create-game";
import {IRulesHelper, LocationTransforms} from "../../helpers/rules-helper";

interface IRulesHelperJM {
	kuikaeCheck(slotTileId: number, playerId: number, allTiles: Entity[]): { hasKuikae: boolean, lockTileId?: number };
}

export const RulesHelperJM: IRulesHelper & IRulesHelperJM = {
	
	getOptions: (tableSettings: OnlineGameDTO): IRulesOptions => {
		return {
			gameType: tableSettings.GameTypeId,
			maxPlayers: 4,
			singleWallLength: 17,
			hasDeadWall: true,
			deadWallLength: 14,
			sides: [Side.South, Side.East, Side.North, Side.West], // Sets available sides for current game rules. Should start from South, CCW
			actionsSet: [
				{id: ActionMoveType.PASS, label: "Pass", color: ActionColors.PASS},
				{id: ActionMoveType.CHOW, label: "Chi", color: ActionColors.CHOW},
				{id: ActionMoveType.PUNG, label: "Pon", color: ActionColors.PUNG},
				{id: ActionMoveType.KONG, label: "Kan", color: ActionColors.KONG},
				{id: ActionMoveType.MAHJONG, label: "Ron", color: ActionColors.MAHJONG},
				{id: ActionMoveType.MAHJONG_SELF, label: "Tsumo", color: ActionColors.MAHJONG},
				{id: ActionMoveType.USER_DECLARE_DRAW, label: "Draw", color: ActionColors.USER_DECLARE_DRAW},
			],
			dealDiceCalcFn: dealDiceCalcHK,
			discardsMode: DiscardsMode.SEPARATE,
			isFlower: tileId => (tileId % 100) > 60 && (tileId % 100) < 80,
			
			nextTileFromEnd_Enabled: true,
			nextTileFromEnd_MeldedFlower: false,
			nextTileFromEnd_Kong: true,
		};
	},
	
	getLocationTransforms: (): LocationTransforms => {
		const lc = {};
		lc[Side.South] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 0),
				euler: new Vector3(0, 0, 0),
			};
		lc[Side.West] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 90 * 3.14 / 180),
				euler: new Vector3(0, 90 * 3.14 / 180, 0),
			};
		lc[Side.North] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 180 * 3.14 / 180),
				euler: new Vector3(0, 180 * 3.14 / 180, 0),
			};
		lc[Side.East] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 270 * 3.14 / 180),
				euler: new Vector3(0, 270 * 3.14 / 180, 0),
			};
		return lc;
	},
	
	/**
	 * Kuikae 「喰い替え」 is a particular restriction applied to tile calls, especially involving chii. It is commonly referred to as "swap calling".
	 * Generally speaking, when swap calling is forbidden, when a player calls another player's discard, the following discard cannot be another copy
	 * of the tile they just called, nor can it be another tile that also completes the chii that the player just called for.
	 * @return -- null if user do not have any limitations
	 */
	kuikaeCheck: (slotTileId: number, playerId: number, allTiles: Entity[]): { hasKuikae: boolean, lockTileId?: number } => {
		const slotTinyId = slotTileId % 100;
		if (!(slotTinyId > 10 && slotTinyId < 40)) {
			return null;
		}
		const playerConcealed = TileSetHelper.getPlayerConcealedTiles(playerId, allTiles);
		if (playerConcealed.length !== 4 || !TileSetHelper.hasTileWithTinyId(slotTinyId, playerConcealed)) {
			return null;
		}
		// Player has already this chow in hand
		const slotTileIndex = slotTinyId % 10;
		const sameTilesAsSlotCount = TileSetHelper.getTilesCountWithId({tileSet: playerConcealed, tileTinyId: slotTinyId});
		
		const avChows = TileSetHelper.getAvailableChows({
			slotTileId,
			playerId,
			allTiles
		});
		
		if (sameTilesAsSlotCount === 2 /* DH: 2 tile for chow & 2 tile blocked (equal to slot's tileId) */
			|| (avChows.hasChow_1 && slotTileIndex < 7 && TileSetHelper.hasTileWithTinyId(slotTinyId + 3, playerConcealed)) /* chow_1 : has tile 6 for chow 3-4-5 with 3 in slot */
			|| (avChows.hasChow_3 && slotTileIndex > 3 && TileSetHelper.hasTileWithTinyId(slotTinyId - 3, playerConcealed)) /* chow_3 : has tile 3 for chow 4-5-6 with 6 in slot */
		) {
			// Warn: Kuikae. Chow blocked.
			return {hasKuikae: true};
		}
		else {
			// Warn: lock tile, regular flow & exit
			return {hasKuikae: false, lockTileId: slotTinyId};
		}
	},
	
	defaultGameSettings: ((): ICreateGameOpts => {
		const name = "PG - " + (new Date().getTime());
		return {
			name,
			declarePoints: 1,
			description: "",
			enableAutoPass: true,
			entryFee: 250,
			flowers: false,
			forFriends: true,
			gameTypeId: GameType.RCR,
			hostSeat: false,
			minBelt: BeltType.White,
			money: false,
			playTimeSec: 3 * 60 * 60,
			rated: true,
			roundsCount: 0,
			showGameHelper: true,
			showWinningHandOnly: false,
			spectators: true,
			timeLimit: 10,
			waitForReconnect: false
		};
	})(),
	
	getCreateLimits: ({rounds}: { rounds: number }): ICreateFormLimits => {
		const entryFee = rounds === 0 ? 250 : 500 * rounds;
		
		return {
			name: {maxLength: 15},
			timeLimit: {min: 5, max: 3600},
			// declarePoints: {min: 0, max: 8},
			entryFee: {min: entryFee, max: entryFee},
			roundsCount: [
				{data: 0, label: "QM"},
				{data: 1, label: "1"},
				{data: 2, label: "2"},
				{data: 3, label: "3"},
				{data: 4, label: "4"},
			]
		};
	},
	
	// JM (Ari-Ari)
	helpTips: [
		{
			icon: "furiten",
			title: {key: "Lobby.HOverlays.GameRules.Furiten_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.Furiten_msg"}
		},
		{
			icon: "falseMahjong",
			title: {key: "Lobby.HOverlays.GameRules.FalseRon_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.FalseRon_msg"}
		},
		{
			icon: "ho_hand_r",
			title: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_msg"}
		},
		/*if (includeKeyboardTip) {
			tipSet[Enum.GameType.JM].push(
				{	icon: SkinLoader.getSkinItem('keyb'),
					title: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_title"),
					descr: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_msg"),
					descrFull: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_0")
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_space")}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pass")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "C"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.chi")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "P"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pon")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "K"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.kan")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "M"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.ron") + " / " + CoreSettings.Res.getValue("Enum.GameMove.tsumo")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "D"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.draw")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "R"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.ready")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_e"), [
							{substr:"%%combination1%%", value: CoreSettings.Res.getValue("Enum.GameMove.chi") + ", " + CoreSettings.Res.getValue("Enum.GameMove.pon") + ", " + CoreSettings.Res.getValue("Enum.GameMove.kan") + ", " + CoreSettings.Res.getValue("Enum.GameMove.ron")},
							{substr:"%%combination2%%", value: CoreSettings.Res.getValue("Enum.GameMove.tsumo") + ", " + CoreSettings.Res.getValue("Enum.GameMove.draw") + ", " + CoreSettings.Res.getValue("Enum.GameMove.ready") }])
						+ "\n" + CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_sp")
				}
			);
		}*/
		{
			icon: "ho_settings",
			title: {key: "Lobby.HOverlays.GameRules.JMSettings_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.JMSettings_msg"},
			msgFull: [{key: "Lobby.HOverlays.GameRules.JMSettings_details_AriAri"}]
		},
	],
	// JM EMA
	/*helpTips: [
		{
			icon: "furiten",
			title: {key: "Lobby.HOverlays.GameRules.Furiten_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.Furiten_msg"}
		},
		{
			icon: "falseMahjong",
			title: {key: "Lobby.HOverlays.GameRules.FalseRon_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.FalseRon_msg"}
		},
		{
			icon: "ho_hand_r",
			title: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_msg"}
		},
		/!*if (includeKeyboardTip) {
			tipSet[Enum.GameType.JM].push(
				{	icon: SkinLoader.getSkinItem('keyb'),
					title: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_title"),
					descr: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_msg"),
					descrFull: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_0")
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_space")}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pass")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "C"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.chi")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "P"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pon")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "K"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.kan")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "M"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.ron") + " / " + CoreSettings.Res.getValue("Enum.GameMove.tsumo")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "D"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.draw")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "R"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.ready")}])
						+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_e"), [
							{substr:"%%combination1%%", value: CoreSettings.Res.getValue("Enum.GameMove.chi") + ", " + CoreSettings.Res.getValue("Enum.GameMove.pon") + ", " + CoreSettings.Res.getValue("Enum.GameMove.kan") + ", " + CoreSettings.Res.getValue("Enum.GameMove.ron")},
							{substr:"%%combination2%%", value: CoreSettings.Res.getValue("Enum.GameMove.tsumo") + ", " + CoreSettings.Res.getValue("Enum.GameMove.draw") + ", " + CoreSettings.Res.getValue("Enum.GameMove.ready") }])
						+ "\n" + CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_sp")
				}
			);
		}*!/
		{
			icon: "ho_settings",
			title: {key: "Lobby.HOverlays.GameRules.JMSettings_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.JMSettings_msg"},
			msgFull: [{key: "Lobby.HOverlays.GameRules.JMSettings_details_EMA"}]
		},
	],*/
	
};
