import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";
import {JackpotInfoDTO} from "../dto/JackpotInfoDTO";
import {JackpotInfoStoreDTO} from "../dto/JackpotInfoStoreDTO";
import {JackpotRecentWinnerDTO} from "../dto/JackpotRecentWinnerDTO";
import {JackpotParticipantDTO} from "../dto/JackpotParticipantDTO";

export class JackpotService extends AbstractAMFService {
	//
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Jackpot.JackpotInfo", JackpotInfoDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Jackpot.JackpotInfoStore", JackpotInfoStoreDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Jackpot.Participant", JackpotParticipantDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Jackpot.RecentWinner", JackpotRecentWinnerDTO);
	}
	
	public getJackpotInfo(sessionKey: string): Promise<JackpotInfoDTO[]> {
		return this.send(JackpotServices.GetJackpotInfo,
			[sessionKey]);
	}
	
	public getJackpotInfoFull(sessionKey: string, gameTypeId: number, tierId: number): Promise<JackpotInfoStoreDTO> {
		return this.send(JackpotServices.GetJackpotInfoFull,
			[sessionKey, gameTypeId, tierId]);
	}
	
	public getJackpotBasicInfo(sessionKey: string, gameTypeId: number, tierId: number): Promise<JackpotInfoDTO> {
		return this.send(JackpotServices.GetJackpotBasicInfo,
			[sessionKey, gameTypeId, tierId]);
	}
	
	public getJackpotBasicInfoFull(sessionKey: string, gameTypeId: number, tierId: number): Promise<JackpotInfoStoreDTO> {
		return this.send(JackpotServices.GetJackpotBasicInfoFull,
			[sessionKey, gameTypeId, tierId]);
	}
	
	public getParticipants(sessionKey: string, gameTypeId: number, tierId: number): Promise<JackpotParticipantDTO> {
		return this.send(JackpotServices.GetParticipants,
			[sessionKey, gameTypeId, tierId]);
	}
	
	public getRecentWinners(sessionKey: string): Promise<JackpotRecentWinnerDTO[]> {
		return this.send(JackpotServices.GetRecentWinners,
			[sessionKey]);
	}
	
}


export class JackpotServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.Jackpot";
	public static readonly GetRecentWinners = new ServiceDescriptor(JackpotServices.NS, "GetRecentWinners");
	public static readonly GetParticipants = new ServiceDescriptor(JackpotServices.NS, "GetParticipants");
	public static readonly GetJackpotInfo = new ServiceDescriptor(JackpotServices.NS, "GetJackpotInfo");
	public static readonly GetJackpotInfoFull = new ServiceDescriptor(JackpotServices.NS, "GetJackpotInfoFull");
	public static readonly GetJackpotBasicInfo = new ServiceDescriptor(JackpotServices.NS, "GetJackpotBasicInfo");
	public static readonly GetJackpotBasicInfoFull = new ServiceDescriptor(JackpotServices.NS, "getJackpotBasicInfoFull");
}
