import {System} from "ecsy";
import {GameGraphics} from "../GameGraphics";
import {TileGraphics} from "../TileGraphics";
import {DeadWallTagComponent, GraphicsComponent, TileDataComponent, TileState} from "./common/components";

export class TileCharacterSystem extends System {
	private gameGraphics: GameGraphics;
	
	init(attributes) {
		this.gameGraphics = attributes.gameGraphics;
	}
	
	unregister(): void {
	
	}
	
	execute(delta: number, time: number): void {
		if (this.queries.updateCharacter.changed.length > 0) {
			this.queries.updateCharacter.changed.forEach(entity => {
				// console.log("TileCharacterSystem: character updated • ", entity.getComponent(TileDataComponent).fullId);
				const tileGraphics: TileGraphics = entity.getComponent(GraphicsComponent).obj as TileGraphics;
				const tileDataComponent = entity.getComponent(TileDataComponent);
				// as we do not display DefinedJokers, we should replace tile symbol with 81 (Joker) tile
				tileGraphics.setTileId(tileDataComponent.state === TileState.DEFINED_JOKER ? 81 : tileDataComponent.tinyId);
				tileGraphics.setIsOver(tileDataComponent.state === TileState.HI);
			});
		}
		
		// Handle newly added dead wall tiles
		if (this.queries.deadWall.added.length === 13) {  // Note: corrected = to ===
			console.log("TileCharacterSystem: Dead wall added length", this.queries.deadWall.added.length);

			this.queries.deadWall.added.forEach(tileEntity => {
				const tileGraphics: TileGraphics = tileEntity.getComponent(GraphicsComponent).obj as TileGraphics;
				const tileDataComponent = tileEntity.getComponent(TileDataComponent);
				tileGraphics.setIsDeadWall(true);
			});
		} else if (this.queries.deadWall.added.length !== 13) {  // The new else if block
			console.log("TileCharacterSystem: Dead wall added length is not 8", this.queries.deadWall.added.length);

			this.queries.deadWall.added.forEach(tileEntity => {
				const tileGraphics: TileGraphics = tileEntity.getComponent(GraphicsComponent).obj as TileGraphics;
				tileGraphics.setIsDeadWall(false);
			});
		}


		
		if (this.queries.deadWall.removed.length > 0) {
			this.queries.deadWall.removed.forEach(tileEntity => {
				const tileGraphics: TileGraphics = tileEntity.getComponent(GraphicsComponent).obj as TileGraphics;
				tileGraphics.setIsDeadWall(false);
			});
		}
	}
}

TileCharacterSystem.queries = {
	updateCharacter: {
		components: [TileDataComponent],
		listen: {
			removed: true,
			changed: true
		}
	},
	allTiles: {
		components: [TileDataComponent],
	},
	deadWall: {
		components: [TileDataComponent, DeadWallTagComponent],
		listen: {
			added: true,
			removed: true,
		}
	}
};

