
<button mat-fab (click)="doLeaveGame()" class="leave-control" color="secondary">
    <i class="material-icons">exit_to_app</i>
</button>
<div>
    <!-- ... other content ... -->
    <app-chat></app-chat>
  </div> 
<div style="display: flex" *ngIf="isDebugMode">
    <button mat-raised-button (click)="joinUser('User2')">Join User2</button>
    <button mat-raised-button (click)="joinUser('User3')">Join User3</button>
    <button mat-raised-button (click)="joinUser('User4')">Join User4</button>
    <button mat-raised-button (click)="joinUser('User5')">Join User5</button>
    <button mat-raised-button (click)="joinUser('User6')">Join User6</button>
</div>


<button *ngIf="isDebugMode" mat-raised-button color="accent" (click)="btnStartCLick()">START</button>

<div class="centered-abs" style="display: flex; flex-direction: column; gap: 1rem;">

    <div class="content">
        <div class="base-box">
            <div class="irow2">
                <h2 style="width: 100%; text-align: right;">{{'Lobby.InfoTabs.TableInfo.Caption'|translate}}</h2>
            </div>
            <div class="irow2">
                <span class="iLabel">{{'Lobby.InfoTabs.TableInfo.GameName'|translate}}:</span>
                <span class="iValue">{{gameSettings?.Name}}</span>
            </div>
            <div class="irow2" *ngIf="isMinPointsVisible">
            <span class="iLabel"
                  [matTooltip]="'Lobby.HOverlays.GameRules.MP_msg'|translate">
                {{'Lobby.CreateGame.MinPoints'|translate}}:</span>
                <span class="iValue">{{gameSettings?.DeclarePoints}}</span>
            </div>
            <div class="irow2">
                <span class="iLabel">{{'Lobby.InfoTabs.TableInfo.MoveTime'|translate}}:</span>
                <span class="iValue">{{gameSettings?.TimeLimit}}</span>
            </div>
            <div class="irow2" *ngIf="isRoundsVisible">
                <span class="iLabel">{{'Lobby.InfoTabs.TableInfo.Rounds'|translate}}:</span>
                <span class="iValue">{{gameSettings?.RoundsCount}}</span>
            </div>
        </div>
        <div class="base-box">
            <h2>{{'Lobby.InfoTabs.TableInfo.Players'|translate}}</h2>
            <mat-list role="list">
                <mat-list-item role="listitem" *ngFor="let user of users">{{user.Name}}</mat-list-item>
            </mat-list>
        </div>


    </div>
    <!--Start-->
    <div *ngIf="startGameAvailable" class="base-box" style="display:flex; flex-direction:column; gap:.5rem;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
            <span>You can start the game anytime</span>
            <button mat-raised-button color="primary" (click)="btnStartCLick()">START</button>
        </div>
    </div>
    <!--Share-->
    <div class="base-box" style="display:flex; flex-direction:column; gap:.5rem;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
            <span><b>Share link with friends</b></span>
            <button *ngIf="!copyBoxVisible" mat-mini-fab (click)="toggleCopyBox()" class="leave-control1"
                    color="secondary">
                <i class="material-icons">share</i>
            </button>
        </div>
        <div *ngIf="copyBoxVisible" style="max-width: 320px;">
            <!--<mat-form-field appearance="outline" style="width: 100%;">
              <input matInput class="truncate" [value]="gameURL" />
            </mat-form-field>-->
            <input matInput [value]="gameURL" style="border-radius: .5rem;
                                                                border-color: #cccccc;
                                                                border-style: solid;
                                                                border-width: thin;
                                                                padding: .5rem;"/>
        </div>
        <div *ngIf="copyBoxVisible" style="display: flex; gap:.5rem; justify-content: flex-end;">
            <button mat-raised-button color="secondary" matTooltip="Copy" (click)="onCopy()"><i class="material-icons">content_copy</i>
            </button>
            <button mat-raised-button color="secondary" matTooltip="Close" (click)="toggleCopyBox()"><i
                    class="material-icons">close</i></button>
        </div>

    </div>
 
 </div>