<!-- Chat Icon Image (Visible when chat is closed) -->
<img *ngIf="!showChat" 
     [ngClass]="{'highlight-icon': newMessageReceived}" 
     src="assets/img/chat.png" 
     class="chat-icon" 
     (click)="toggleChat()">

<!-- Chat Container (Visible when chat is open) -->
<div *ngIf="showChat" class="chat-container" [ngClass]="{'highlight-chat': highlightChat}">

  <!-- Close Chat Button -->
  <div class="chat-header">
   <span class="chat-header-font">Chat</span><button class="close-chat-btn" (click)="toggleChat()">&times;</button>
  </div>

  <!-- Chat Messages Container -->
  <div class="chat-messages-container" #messagesContainer *ngIf="showMessages">
    <div *ngFor="let message of chatMessages" class="chat-message">
      <strong>{{ message.UserName }}:</strong>
      {{ message.Message }}
    </div>
  </div>

  <!-- Send Message Container -->
  <div class="send-message-container">
    <input 
      [(ngModel)]="newMessage" 
      placeholder="Type to chat..." 
      (keyup.enter)="sendNewMessage()" 
      (focus)="onInputFocus()"
      (input)="resetHideMessagesTimeout()" />
    <button (click)="sendNewMessage()">Send</button>
  </div>
</div>