import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule, OnDestroy, OnInit } from "@angular/core";
import { GameStore } from "../../../../../store/GameStore";
import container from "../../../../../inversify/inversify.config";
import { TYPES } from "../../../../../inversify/inversify.types";
import { ActivatedRoute, Router } from "@angular/router";
import { GameStateDTO } from "../../../../../net/dto/GameStateDTO";
import { GameState } from "../../../../../game/enums/GameState";
import { GameMessageDTO } from "../../../../../net/dto/GameMessageDTO";
import { MoveType } from "../../../../../game/enums/MoveType";
import { ChatGamController } from "../../../../../game/ChatGamController";
import { ChatService } from '../../../../../net/service/ChatService';
import { UserStore } from "../../../../../store/UserStore";
import { GameService } from "../../../../../net/service/GameService";
import { commander } from "../../../../../commander/Commander";
import { Commands } from "../../../../../commands/Commands";
import { Subscription } from "rxjs";
import { TileDescrState } from "../../../../../game/enums/TileDescrState";
import { OnlineGameDTO } from "../../../../../net/dto/OnlineGameDTO";
import { Side } from "../../../../../game/enums/Side";
import { GameUserInfoDTO } from "../../../../../net/dto/GameUserInfoDTO";
import { JoinedUserType } from "../../../../../game/enums/JoinedUserType";
import { TileDescrDTO } from "../../../../../net/dto/TileDescrDTO";
import { ActionMoveType } from "../../../../../game/enums/ActionMoveType";
import { GameUIService } from "../../../../services/GameUIService";
import { IGameSnapshotOptions } from "../../../../../game/interfaces/IGameSnapshotOptions";
import { environment } from "../../../../../environments/environment";
import { IReactionDisposer, reaction } from "mobx";
import { GameType } from "../../../../../game/enums/GameType";
import { IDeactivatable } from "../../../IDeactivatable";
import { NavigationService } from "../../../../services/navigation.service";
import { NavPage } from "../../../../enums/NavPage";
import { QuickSeatService } from "../../../../services/QuickSeatService";
import { AppEventsPipe, AppEventType } from "../../../../../game/AppEventsPipe";
import { GameURL } from "../../../../../utils/GameURL";
import { OnlineGameHelper } from "../../../../../net/helpers/OnlineGameHelper";
import { ChatMessageDTO } from "src/net/dto/ChatMessageDTO";
import { AMFConnectionBroker } from "src/net/service/AMFConnectionBroker";



@Component({
	selector: "app-waiting",
	templateUrl: "./waiting.component.html",
	styleUrls: ["./waiting.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})


export class WaitingComponent implements OnInit, OnDestroy, IDeactivatable {

	private cgcSubscription: Subscription;
	private enteredGame = false;
	public isDebugMode: boolean = environment.isDebug;
	private reactDisposers: Array<IReactionDisposer> = [];
	public copyBoxVisible = false;
	private chatService: ChatService;
	//private chatMessages: ChatMessagesDTO[] = []; // This will hold the chat messages
	private appEvents: AppEventsPipe;
	public chatMessages: ChatMessageDTO[] = [];
	@Input()
	public gameSettings: OnlineGameDTO;
	@Input()
	public users: GameUserInfoDTO[];
	private navigationRequested = false;
	public newMessage: string = '';
	startGameAvailable = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public gameUIService: GameUIService,
		private cd: ChangeDetectorRef,
		private navigationService: NavigationService,
		private qsService: QuickSeatService,
	) {
		// 🀄️ inject GameEventsService to start handling events like adding/ updating players info
		console.log(`WaitingComponent.constructor: `);
		this.appEvents = container.get(TYPES.AppEventsPipe);
		const amfConnectionBroker = container.get<AMFConnectionBroker>(TYPES.AMFConnectionBroker);
		const appEventsPipe = container.get<AppEventsPipe>(TYPES.AppEventsPipe);

		this.chatService = new ChatService(amfConnectionBroker, appEventsPipe);


	}


	ngOnInit(): void {
		try {
			// start section will be available only for "Private Games" (name starting with "PG-")
			this.startGameAvailable = OnlineGameHelper.isPrivate(this.gameStore.joinedGame);
			this.checkGameState();
			this.addReactions();
			
			// Create a test message with all the required properties
			const testMessage: ChatMessageDTO = {
				Id: 0, // Example ID, adjust as needed
				ChatId: 1, // Example ChatId, adjust as needed
				Colour: 'black', // Example color, adjust as needed
				UserId: 183, // Example UserId, adjust as needed
				PostTime: new Date(), // Current time as an example
				UserName: 'Test User',
				Message: 'This is a test message'
			};

			// Update the chatMessages array immutably
			this.chatMessages = [...this.chatMessages, testMessage];
			console.log('Test messages after update:', this.chatMessages);

			// Manually trigger change detection for testing
			this.cd.detectChanges();
			setTimeout(() => {
				this.cd.markForCheck();
			}, 1000);

		} catch (e) {
			// Handle any errors that might have occurred during initialization
			console.error(e);
		}
	}

	ngOnDestroy(): void {
		try {
			this.stopMonitoringGameMessages();
			this.removeReactions();
		}
		catch (e) {
			console.error("WaitingComponent.ngOnDestroy: " + e);
		}
	}

	canDeactivate(): boolean {
		return !this.gameStore.joinedGame || this.navigationRequested;
	}


	private addReactions() {
		this.removeReactions();
		/** React on changing playersQ count or playersQ sides change */
		const disposer: IReactionDisposer = reaction(
			() => this.gameStore.gameUsers.players.map(player => player.UserId + "," + player.Side),
			() => {
				console.info("WaitingComponent.: users updated: ", this.gameStore.gameUsers.users);
				this.users = [...this.gameStore.gameUsers.users];
				this.cd.markForCheck();
			},
			{ name: "PlayersSystem.PlayerContOrSide_changed" });
		this.reactDisposers.push(disposer);
	}

	private removeReactions() {
		if (this.reactDisposers) {
			this.reactDisposers.forEach(disposer => disposer());
		}
		this.reactDisposers = [];
	}

	private checkGameState(): void {
		const joinedGame = this.joinedGame;

		this.gameService.getGameState(this.userStore.sessionKey, joinedGame.RoomId, joinedGame.GameId, true, 2)
			.then(result => this.processGameState(result))
			.catch(e => console.warn("WaitingComponent.checkGameState: ", e));

	}

	private processGameState(gameState: GameStateDTO): void {
		console.log("WaitingComponent.processGameState: ");
		if (!gameState.Settings) {
			console.warn("processGameState: no settings provided");
			return;
		}

		// Update joinedGame settings
		this.gameStore.joinedGame = gameState.Settings;
		this.gameSettings = this.gameStore.joinedGame;

		if (!this.enteredGame) {
			this.enteredGame = true;
			commander.executeCommand(Commands.ENTER_GAME, { gameType: this.gameStore.joinedGame.GameTypeId });
		}

		// Set or update game users / playersQ
		// Note: users have Side defined only if game is already started.
		// Not started games have users with sides in game settings and in game moves (TAKE_SEAT). The sides will be reassigned
		// during the game start also
		for (const gameUser of gameState.GameUsers) {
			console.log("WaitingComponent.processGameState: process user id=" + gameUser.UserId);
			this.gameStore.gameUsers.add(gameUser);
		}

		// const lastPeekId = gameState.LastPeak;
		// const secondsBeforeFirstMove = gameState.Settings.SecondsBeforeFirstMove;
		// const stateDescriptionId = gameState.StateDescriptionId; // read property description
		console.log("WaitingComponent.processGameState: process game state: " + gameState.StateId);
		switch (gameState.StateId) {
			case GameState.EMPTY:
			case GameState.WAITING:
			case GameState.READY_TO_START:
				this.updatePlayerSideFromGameSettings(gameState.Settings);

				if (gameState.Settings.PracticeLevel > 0 /*&& I can start, im player and game is ready to start */) {
					this.gameService.start(this.userStore.sessionKey, gameState.RoomId, gameState.GameId)
						.then(
							res => console.log("Started successfully", res),
							err => console.warn("WaitingComponent.processGameState: error starting game"));
				}
				this.startMonitoringGameMessages();
				break;

			// I can join only if its my game (I'm one of the playersQ)
			case GameState.PLAYING:
				this.enterGameInProgress();
				break;

			// Game cannot be joined
			case GameState.PAUSED:
			case GameState.STOPPED:
			case GameState.COMPLETED:
			case GameState.STORED:
				console.log("Game is ended");
				break;

		}
	}

	private startMonitoringGameMessages(): void {
		console.log("WaitingComponent.startMonitoringGameMessages: ");
		const hasStartGameMessage = this.processGameMessages(); // process already accumulated game messages
		if (!hasStartGameMessage) { // Start monitoring new game messages
			this.cgcSubscription = this.CGC.gameMessagesAvailable$.subscribe(() => this.processGameMessages());
		}
	}

	private stopMonitoringGameMessages(): void {
		console.log("WaitingComponent.stopMonitoringGameMessages: ");
		if (this.cgcSubscription) {
			this.cgcSubscription.unsubscribe();
		}
	}


	private enterStartedGame(): void {
		console.log("WaitingComponent.enterStartedGame: ");
		// TODO: check if I am a player of this game
		this.doEnterTheGame();
	}

	private enterGameInProgress(): void {
		console.log("WaitingComponent.enterGameInProgress: ");
		const initMoves: Array<GameMessageDTO> = [];
		const createMove = (playerId: number, type: MoveType, message: string, group?: number): GameMessageDTO => {
			const dto: GameMessageDTO = new GameMessageDTO();
			dto.UserId = playerId;
			dto.Type = type;
			dto.Message = message;
			if (group) {
				dto.Group = group;
			}
			return dto;
		};
		this.gameService.gameSnapshot(this.userStore.sessionKey, this.joinedGame.RoomId, this.joinedGame.GameId)
			.then(snapshot => {
				if (snapshot) {
					console.log("gameSnapshot: ", snapshot);
					console.log("gameSnapshot.P: ", snapshot.Players);
					// const dealState = snapshot.DealStateId;
					/*const dealState = this.gameStore.dealState;
					dealState.state = snapshot.DealStateId;
					dealState.dealNum = snapshot.CurrentDeal;
					dealState.dealRoundWind = snapshot.RoundWind;*/
					// dealState.betsTotalCount = snapshot.BetsTotalCount;
					// snapshot.CoffeeBreakSecLeft;
					// snapshot.DealDice;
					// snapshot.DealStateId;
					// snapshot.LastPeek;
					// snapshot.PlayTimeSecLeft;
					// snapshot.PlayedDeals;
					// snapshot.Players;
					// snapshot.PlayersTile;
					// snapshot.RcrCounterValue;
					// snapshot.RenchanCounter;


					/*const discardsCount = snapshot.PlayersTile.reduce((acc, tile) => {
						return acc + (tile.State === TileDescrState.DISCARDS ? 1 : 0);
					}, 0);*/
					const discardsCount = snapshot.PlayersTile.filter(tile => tile.State === TileDescrState.DISCARDS).length;

					const opts: IGameSnapshotOptions = {
						dealState: snapshot.DealStateId,
						currentDeal: snapshot.CurrentDeal,
						roundWind: snapshot.RoundWind as Side,
						discardsCount,
						userIdMoveNow: snapshot.UserIdMoveNow,

						betsTotalCount: snapshot.BetsTotalCount,
						rcrCounter: snapshot.RcrCounterValue,
						dealerCounter: snapshot.PlayedDeals,
						renchanCounter: snapshot.RenchanCounter,
					};
					initMoves.push(createMove(1, MoveType.GAME_SNAPSHOT_OPTIONS, JSON.stringify(opts)));
					// initMoves.push(createMove(1, MoveType.GAME_STATE, "" + snapshot.DealStateId));
					// initMoves.push(createMove(1, MoveType.START_DEAL, "" + snapshot.CurrentDeal));
					// initMoves.push(createMove(1, MoveType.NEW_DEAL_WIND, "" + snapshot.RoundWind));
					initMoves.push(createMove(1, MoveType.DEAL_THROWN_DICE, "" + snapshot.DealDice));

					let slotTile: TileDescrDTO;

					snapshot.PlayersTile.forEach(tile => {
						switch (tile.State) {
							case TileDescrState.CONCEALED:
								initMoves.push(createMove(tile.UserId, MoveType.FROM_WALL_TO_CONCEALED, "" + tile.TileId));
								break;
							case TileDescrState.MELDED:
								if (tile.TileId > 90 && tile.TileId < 95) {
									initMoves.push(createMove(tile.UserId, MoveType.READY, ""));
								}
								else {
									initMoves.push(createMove(tile.UserId, MoveType.FROM_WALL_TO_MELDED, "" + tile.TileId, tile.Group));
								}
								break;
							case TileDescrState.SLOT:
								slotTile = tile;
								initMoves.push(createMove(tile.UserId, MoveType.FROM_WALL_TO_CONCEALED, "" + tile.TileId));
								initMoves.push(createMove(tile.UserId, MoveType.FROM_CONCEALED_TO_SLOT, "" + tile.TileId));
								break;
							case TileDescrState.DISCARDS:
								initMoves.push(createMove(tile.UserId, MoveType.FROM_WALL_TO_CONCEALED, "" + tile.TileId));
								initMoves.push(createMove(tile.UserId, MoveType.FROM_CONCEALED_TO_SLOT, "" + tile.TileId));
								initMoves.push(createMove(tile.UserId, MoveType.FROM_SLOT_TO_DISCARDS, "" + tile.TileId));
								break;
							case TileDescrState.DORA_INDICATOR:
								initMoves.push(createMove(tile.UserId, MoveType.DORA_INDICATOR, "" + tile.TileId));
								break;
						}
					});

					const myUserId = this.userStore.id;
					if (snapshot.UserIdMoveNow !== 0) { // It's a player's move. Should throw a tile
						initMoves.push(createMove(myUserId, MoveType.USER_ID_MOVE_NOW, "" + myUserId));
					}
					else { // It's a slot tile
						if (slotTile && slotTile.UserId !== myUserId) { // TODO:  && I'm player in this game
							// there is a tile in slot and it is not mine
							// initMoves.push(createMove(myUserId, MoveType.USER_CAN_MAKE_MOVE, "pass"));
							// As there is no information about  available actions for user we can only allow him to pass. But single pass option is
							// skipped and not displayed to user, thus we have to force makeMove(pass)
							this.gameService.makeMove(this.userStore.sessionKey, this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId,
								ActionMoveType.PASS, 0, 0)
								.catch(e => console.warn("WaitingComponent.processing snapshot: cannot make pass"));
						}
					}
					// dealState.playerIdMoveNow = this.gameStore.tablePlayers.getPlayerByRealside(snapshot.SideMoveNow).id;
					initMoves.push(createMove(1, MoveType.GAME_SNAPSHOT_PROCESSING_END, JSON.stringify(opts)));

					this.gameStore.setInitMoves(initMoves);
					this.doEnterTheGame();
				}
			}
			)
			.catch(e => console.error("WaitingComponent.: error processing game snapshot" + e));
	}

	/**
	 * Process game messages until START_GAME is reached.
	 * @param messagesCount optional
	 * @returns true if a START_GAME message has been meet, false otherwise
	 */
	private processGameMessages(messagesCount?: number): boolean {
		console.log("WaitingComponent.processGameMessages: " + messagesCount);
		let gameMessage = this.CGC.readGameMessage(); // get game message to proceed
		while (gameMessage) {
			const isStartGameMessage: boolean = this.processGameMessage(gameMessage);
			if (isStartGameMessage) {
				this.enterStartedGame();
				return true;
			}
			this.CGC.takeGameMessage(); // remove this message from stack
			gameMessage = this.CGC.readGameMessage(); // get next message
		}
		return false;
	}

	/**
	 * Process game message and return true if it is START_GAME message
	 * @param gameMessage - a game message
	 * @returns true if START_GAME message was meet, false otherwise
	 */
	private processGameMessage(gameMessage: GameMessageDTO): boolean {
		console.log("WaitingComponent.processGameMessage: " + gameMessage);
		switch (gameMessage.Type) {
			case MoveType.JOIN: // User joined the game
				// Message: id=9	 userId=200687	 type=JOIN(48)	 message=200687|goalkicker16|3|False
				// Id | Name | Status | ?
				const [userId, userName, userStatus] = gameMessage.Message.split("|");
				const joinedUser = new GameUserInfoDTO();
				joinedUser.UserId = +userId;
				joinedUser.Name = userName;
				joinedUser.StateId = +userStatus;
				this.gameStore.gameUsers.add(joinedUser);
				break;
			case MoveType.TAKE_SEAT: // User took a seat
				// Message: id=10	 userId=200687	 type=TAKE_SEAT(47)	 message=s|90|20|US|0||117622||
				/* side
					 + "|" + (byte)user.Rating.GetBelt(Settings.GameType)
					 + "|" + user.Profile.Gender
					 + "|" + user.Profile.Country
					 + "|" + user.Profile.GuildId
					 + "|" + user.Profile.GuildName);
					 + "|" + Chips
					 + "|" + fbId
				 + "|" + mmId*/
				const side = gameMessage.Message.split("|")[0];
				this.gameStore.gameUsers.updateUser(gameMessage.UserId, { Side: side as Side, StateId: JoinedUserType.PLAYER });
				break;
			case MoveType.CHANGE_STATE_GAME_USER:
				this.gameStore.gameUsers.updateUser(gameMessage.UserId, { StateId: gameMessage.Message as unknown as JoinedUserType });
				break;
			case MoveType.LEAVE_GAME_USER:
				this.gameStore.gameUsers.deleteUser(gameMessage.UserId);
				break;
			case MoveType.GAME_STATE:
				this.joinedGame.StateId = +gameMessage.Message;
				break;
			case MoveType.START_GAME:
				return true;
				break;
		}
		return false;
	}

	private processStartGame(): void {
		console.log("WaitingComponent.processStartGame: ");
		this.doEnterTheGame();
	}

	private doEnterTheGame(): void {
		this.navigateToTheGame();
	}

	private navigateToTheGame() {
		this.navigationRequested = true;
		this.qsService.stopSearch(); // stop quick seat if it is running
		commander.executeCommand(Commands.RUN_GAME)
			.then(() => {
				this.navigationService.navigate({ page: NavPage.TableInGame, extras: { relativeTo: this.route.parent } });
			})
			.catch(err => console.warn("WaitingComponent.navigate: ", err));
	}

	private get joinedGame() {
		return this.gameStore.joinedGame;
	}

	private updatePlayerSideFromGameSettings(settings: OnlineGameDTO): void {
		const splayers = {};
		splayers[Side.South] = settings.SouthId;
		splayers[Side.East] = settings.EastId;
		splayers[Side.North] = settings.NorthId;
		splayers[Side.West] = settings.WestId;

		Object.entries(splayers).forEach((ent: [Side, number]) => {
			if (ent[1]) {
				const player = this.gameStore.gameUsers.getUserById(ent[1]);
				this.gameStore.gameUsers.updateUser(player.UserId, { Side: ent[0] });
			}
		});
	}

	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}

	private get gameService(): GameService {
		return container.get<GameService>(TYPES.GameService);
	}

	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}

	private get CGC(): ChatGamController {
		return container.get<ChatGamController>(TYPES.ChatGamController);
	}

	public doLeaveGame() {
		this.gameUIService.doLeave().subscribe(result => {
			if (result === "leave") {
				this.qsService.stopSearch(); // if user manually leave game: stop quick seat if it is in progress
			}
			this.navigationRequested = (result === "leave");
		});
	}

	isMinPointsVisible(): boolean {
		return this.gameSettings && this.gameSettings.GameTypeId !== GameType.WP && this.gameSettings.GameTypeId !== GameType.AS;
	}

	isRoundsVisible(): boolean {
		return this.gameSettings && this.gameSettings.RoundsCount !== 0;
	}

	joinUser(user: string) {
		this.gameService.joinUser(this.userStore.sessionKey, this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId, user)
			.catch(e => console.warn(`WaitingComponent.joinUser: unable to join user: ${user}, error: `, e));
	}

	btnStartCLick() {
		this.gameService.start(this.userStore.sessionKey, this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId)
			.then(
				res => console.log("start successful: ", res),
				err => console.warn("start error: ", err)
			);
	}

	get gameURL(): string {
		const urlParams = GameURL.encode({
			gameId: this.gameSettings.GameId,
			roomId: this.gameSettings.RoomId,
			gameTypeId: this.gameSettings.GameTypeId
		});
		return `${environment.liteRoot}/#/game/${urlParams}`;
	}

	toggleCopyBox() {
		this.copyBoxVisible = !this.copyBoxVisible;
	}

	onCopy() {
		try {
			if (navigator.clipboard) {
				navigator.clipboard.writeText(this.gameURL)
					.then(value => this.appEvents.send(AppEventType.ShowSnack, { message: "Link has been copied to clipboard" }));
			}
			else {
				throw new Error("clipboard is not available");
			}
		}
		catch (e) {
			console.warn("WaitingComponent.onCopy: " + e);
		}
	}
}