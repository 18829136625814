import * as THREE from "three";
import {DoubleSide, Mesh, MeshLambertMaterial, Object3D, PlaneGeometry, Vector2, Vector3} from "three";
import {TileGraphics} from "./TileGraphics";
import {inject, injectable} from "inversify";
import {Side} from "./enums/Side";
import OrbitControls from "three-orbitcontrols";
import {TYPES} from "../inversify/inversify.types";
import {TexturePack} from "./graphics/TexturePack";
import {Subject} from "rxjs";
import {PointerController} from "./PointerController";

export interface IKeyboardEvent {
	type: "down" | "up";
	key: string;
	code: string;
}

export interface IPointerEvent {
	event: MouseEvent;
	type: string;
}

@injectable()
export class GameGraphics {
	
	public canvas: HTMLCanvasElement;
	public renderer: THREE.WebGLRenderer;
	public camera: THREE.PerspectiveCamera;
	public scene: THREE.Scene;
	private light: THREE.AmbientLight;
	private dragon: THREE.Mesh;
	private orbitControls;
	private raycaster = new THREE.Raycaster();
	private mouse = new THREE.Vector2();
	
	public mouse$: Subject<{ type: string, data?: any }> = new Subject<{ type: string, data?: any }>();
	public keyboard$: Subject<IKeyboardEvent> = new Subject<IKeyboardEvent>();
	public pointerController: PointerController;
	
	constructor(
		@inject(TYPES.TexturePack) private tp: TexturePack,
	) {
		console.log("GameGraphics: Create");
		this.pointerController = new PointerController();
		this.createScene();
		this.addKeyboardListeners();
	}
	
	public viewSide(side: Side) {
		const axisDist = 700;
		switch (side) {
			case Side.South:
				this.camera.position.x = 0;
				this.camera.position.z = axisDist;
				break;
			case Side.East:
				this.camera.position.x = axisDist;
				this.camera.position.z = 0;
				break;
			case Side.North:
				this.camera.position.x = 0;
				this.camera.position.z = -axisDist;
				break;
			case Side.West:
				this.camera.position.x = -axisDist;
				this.camera.position.z = 0;
				break;
		}
		this.camera.lookAt(new THREE.Vector3(0, -50, 0));
		if (this.orbitControls) {
			this.orbitControls.update();
		}
	}
	
	public createScene(): void {
		// create the scene
		this.scene = new THREE.Scene();
		// this.scene.background = new THREE.Color(0xCCDDDD);
		// this.scene.fog = new THREE.FogExp2(0xcccccc, 0.002);
		
		this.camera = new THREE.PerspectiveCamera(24, 2 /*width / height*/, 0.1, 10000);
		this.camera.position.x = 0;
		this.camera.position.y = 700;
		this.camera.position.z = 700;
		this.camera.lookAt(new THREE.Vector3(0, -50, 0));
		this.scene.add(this.camera);
		
		// soft white light
		this.light = new THREE.AmbientLight(0xffffff, 0.4);
		this.scene.add(this.light);
		
		const directionalLight = new THREE.DirectionalLight(0xffffff, 0.725);
		directionalLight.position.x = 0;
		directionalLight.position.y = 1;
		directionalLight.position.z = .5;
		directionalLight.position.normalize();
		this.scene.add(directionalLight);
		
		/*const pointLight = new THREE.PointLight(0xffffff, 1);
		pointLight.position.y = 200;
		pointLight.position.z = 0;
		this.scene.add(pointLight);*/
		
		// pointLight.add(new THREE.Mesh(new THREE.SphereBufferGeometry(4, 8, 8), new THREE.MeshBasicMaterial({color: 0xffffff})));
		
		// --------------
		// const dragonMaterial = new THREE.MpointereshBasicMaterial({color: 0x00ff00});
		// const dragGeometry = this.tp.skullModel
		// this.dragon = new THREE.Mesh(geometry, material);
		// this.scene.add(this.dragon/);
	}
	
	private createOrbitControls() {
		// controls
		const controls = new OrbitControls(this.camera, this.renderer.domElement);
		
		//controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)
		
		controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
		controls.dampingFactor = 0.05;
		controls.screenSpacePanning = false;
		controls.minDistance = 100;
		controls.maxDistance = 10000;
		// controls.maxPolarAngle = Math.PI / 2;
		
		this.orbitControls = controls;
	}
	
	public createRenderer(canvas: HTMLCanvasElement, width, height) {
		this.canvas = canvas;
		this.renderer = new THREE.WebGLRenderer({
			canvas: this.canvas,
			alpha: true, // transparent background
			antialias: true, // smooth edges
			preserveDrawingBuffer: true, // enabled to make screenshots for bug report
		});
		console.log(`GameGraphics.createRenderer: ${width} x ${height}`);
		
		const dpi = window.devicePixelRatio;
		const rpi = this.renderer.getPixelRatio();
		if (dpi !== rpi) {
			console.log(`GameGraphics.createRenderer: pixel ratio: device=${dpi}, renderer=${rpi}. Set renderer to ${dpi}`);
			this.renderer.setPixelRatio(dpi);
		}
		console.log("GameGraphics.createRenderer: getMaxAnisotropy = " + this.renderer.capabilities.getMaxAnisotropy());
		
		// renderer.setSize( window.innerWidth, window.innerHeight );
		this.renderer.setSize(width, height);
		
		// need to force update aspect ratio and projection matrix
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
		
		
		// this.createOrbitControls();
		this.pointerController.setup(this.scene, this.camera, this.canvas);
	}
	
	public render(): void {
		
		/*// update the picking ray with the camera and mouse position
		this.raycaster.setFromCamera(this.mouse, this.camera);
		
		// calculate objects intersecting the picking ray
		const intersects = this.raycaster.intersectObjects(this.scene.children);
		
		for (let i = 0; i < intersects.length; i++) {
			intersects[i].object.material.color.set(0xff0000);
		}*/
		
		if (this.renderer) {
			this.renderer.render(this.scene, this.camera);
		}
	}
	
	public resize(width, height): void {
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
		
		if (this.renderer) {
			this.renderer.setSize(width, height);
		}
	}
	
	/*
	public mouseMove(vector2: THREE.Vector2) {
		this.mouse = vector2;
	}
	
	public mouseClick(vector2: THREE.Vector2) {
		
		this.mouse = vector2;
		
		const mouseVector = vector2;
		// update the picking ray with the camera and mouse position
		this.raycaster.setFromCamera(mouseVector, this.camera);
		
		// calculate objects intersecting the picking ray
		// TODO: can be optimized passing a list of object able to interact (times, actions, buttons, ..)
		const intersects: Intersection[] = this.raycaster.intersectObjects(this.scene.children, true)
			.filter(({object}) => object.visible);
		if (intersects.length > 0) {
			const i = intersects.length === 1
				? intersects[0]
				: intersects.reduce((previousValue, currentValue) => currentValue.distance < previousValue.distance ? currentValue : previousValue);
			
			if ((i.object as Mesh).parent instanceof TileGraphics) {
				const tg: TileGraphics = (i.object as Mesh).parent as TileGraphics;
				console.log("Tile clicked: ", tg);
				this.mouse$.next({type: "tileClick", data: tg});
			} else if (i.object instanceof ActionMenuItemGraphics) {
				console.log("Action Menu clicked: playerId=", i.object.playerId, ": action=", i.object.action);
				this.mouse$.next({type: "actionMenuClick", data: i.object});
			}
		}
		/!*for (const i of intersects) {
			if (!i.object.visible) {
				continue;
			}
			if ((i.object as Mesh).parent instanceof TileGraphics) {
				const tg: TileGraphics = (i.object as Mesh).parent as TileGraphics;
				console.log("Tile clicked: ", tg);
				this.mouse$.next({type: "tileClick", data: tg});
				break;
			}
			if (i.object instanceof ActionMenuItemGraphics) {
				console.log("Action Menu clicked: playerId=", i.object.playerId, ": action=", i.object.action);
				this.mouse$.next({type: "actionMenuClick", data: i.object});
			}
		}*!/
		
	}
	*/
	public createEmptyTile(): TileGraphics {
		const tileG = new TileGraphics(this.tp);
		// const tileG = TileGraphicsFactory.createTileGraphics();
		this.scene.add(tileG);
		return tileG;
	}
	
	public createEmptyPlane(): Mesh {
		const plane = new Mesh(new PlaneGeometry(800, 64, 1, 1),
			new MeshLambertMaterial({side: DoubleSide}));
		plane.geometry.translate(0, 16, 0);
		plane.visible = false;
		this.scene.add(plane);
		return plane;
	}
	
	public addActionMenuItem(ami): void {
		this.scene.add(ami);
		return ami;
	}
	
	public addReadyBar(): Mesh {
		// const rb = this.tp.readyObj.clone(true);
		const rg = (this.tp.readyObj as Mesh).geometry.clone().scale(0.7, 0.7, 0.7);
		const rb = new Mesh(rg, new MeshLambertMaterial({color: 0xEEEEEE}));
		rb.name = "ReadyBarGraphics";
		this.scene.add(rb);
		return rb as Mesh;
	}
	
	public removeFromScene(object: Object3D) {
		this.scene.remove(object);
	}
	
	private addKeyboardListeners() {
		window.addEventListener("keyup", (event) => {
			const key = event.key;
			const code = event.code;
			
			// As the user releases the Ctrl key, the key is no longer active,
			// so event.ctrlKey is false.
			/*if (key === "Control") {
				alert("Control key was released");
			}*/
			const type = "up";
			this.keyboard$.next({type, key, code});
		}, false);
	}
	
	public convertVector3dTo2dScreen(v: Vector3): Vector2 {
		const rs = new Vector2();
		this.renderer.getSize(rs); // https://github.com/mrdoob/three.js/issues/7995
		return this.convertVector3dTo2dScreenX(v, this.camera, rs);
	}
	
	public convertVector3dTo2dScreenX(v: Vector3, camera, canvasSize: Vector2): Vector2 {
		const vector = v.project(camera);
		return new Vector2((vector.x + 1) / 2 * canvasSize.x, -(vector.y - 1) / 2 * canvasSize.y);
	}
	
}

