<mat-card-header><b>Settings</b></mat-card-header>
<form class="example-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" ngNativeValidate>
    <mat-slide-toggle formControlName="autoReplaceFlowers">Auto Replace Flowers</mat-slide-toggle>
    <div>
        <button mat-raised-button type="submit">Save Settings</button>
        &nbsp;&nbsp;
        <button mat-raised-button type="button" (click)="onClose()">Close</button>
    </div>

</form>
