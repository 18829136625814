import { Attributes, Entity, System } from "ecsy";
import { PlayerLayoutHelper } from "../../helpers/PlayerLayoutHelper";
import { GameGraphics } from "../../GameGraphics";
import { NetGameManager } from "../../NetGameManager";
import { PointerController } from "../../PointerController";
import { Mesh, Object3D, Vector3 } from "three";
import {
	DraggingTagComponent,
	InputQueryComponent,
	PlayerIdComponent,
	TileDataComponent,
	TileNodesComponent,
	TilePositionComponent,
	TileState,
	TileTypeComponent
} from "./components";
import { TileGraphics } from "../../TileGraphics";
import { TileEntityHelper } from "../../helpers/TileEntityHelper";
import { TileType } from "../../enums/TileType";
import { TileDimensions } from "../../TileDimensions";
import { IDisposable } from "../../IDisposable";
import { GameEvent, GameEventsPipe, GameEventType } from "../../GameEventsPipe";
import { Subject } from "rxjs";
import { GameWorld } from "../GameWorld";
import { ConcealedToSlotPrepare, ConcealedToSlotPrepareCancel, SortTilesAction, UpdatePlayerTilesAction, UpdateSlotTile } from "./GameAction";
import { GameStore } from "../../../store/GameStore";
import { takeUntil } from "rxjs/operators";
import { IExtSystem } from "../IExtSystem";
import { IGameDialogButtonConfig, IGameDialogConfig, IGameDialogSubmitData } from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import { GameAccessHelper } from "../../helpers/GameAccessHelper";
import { AppEventsPipe, AppEventType, SnackData, SnackMessage, SnackResourceMessage } from "../../AppEventsPipe";
import { ActionMenuQuery, AllTilesQuery, GameStorageQuery, PlayersQuery } from "../queries";
import { ActionMoveType } from "../../enums/ActionMoveType";
import { GameStorageWrapper } from "../wrappers/GameStorageWrapper";
import { UserStore } from "../../../store/UserStore";
import { PlayersArray } from "../wrappers/PlayersArray";
import { IInputMouseEvent, InputActionType, InputMouseEventType } from "../../interfaces/InputStreamEvent";
import { environment } from "../../../environments/environment";

/**
 * Game Rules-specified Input handler system.
 */
export abstract class InputRulesSystem extends System implements IDisposable, IExtSystem {
	private plh: PlayerLayoutHelper;
	private gameGraphics: GameGraphics;
	protected netManager: NetGameManager;
	protected gameWorld: GameWorld;

	private pointer: PointerController;
	// Is used in drag process and keeps ref to currently dragged graphic object
	private currentOver;
	// Is used in tile drag process to catch mouse intersections
	private dragPlaneMesh: Mesh;

	protected appEvents: AppEventsPipe;
	protected gameEvents: GameEventsPipe;
	protected gameStore: GameStore;
	protected userStore: UserStore;
	protected gameAccessHelper: GameAccessHelper;

	private destroy$ = new Subject<boolean>();

	public init(attributes?: Attributes): void {
		this.plh = attributes.plh;
		this.gameGraphics = attributes.gameGraphics;
		this.netManager = attributes.netGameManager;
		this.gameWorld = attributes.gameWorld;
		this.appEvents = attributes.appEvents;
		this.gameEvents = attributes.gameEvents;
		this.gameStore = attributes.gameStore;
		this.userStore = attributes.userStore;
		this.gameAccessHelper = attributes.gameAccessHelper;

		this.pointer = this.gameGraphics.pointerController;

		this.dragPlaneMesh = this.gameGraphics.createEmptyPlane();

		this.gameEvents.events
			.pipe(takeUntil(this.destroy$))
			.subscribe(value => this.onGameEvent(value));
	}

	public unregister(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public dispose(): void {
		this.unregister();
	}

	// *************************************************************************************************************************
	protected onGameEvent(value: GameEvent) {
		console.log("InputRulesSystem.onGameEvent: " + value.type + ": " + GameEventType[value.type]);
		try {
			switch (value.type) {
				case GameEventType.UI_SortTiles:
					this.gameWorld.addAction(new SortTilesAction(this.gameStore.dealState.viewPlayerId, this.plh, this.allTilesQ, this.gameStorageQ.tableOptions.concealedSortFn));
					this.gameWorld.addAction(new UpdatePlayerTilesAction(
						this.getPlayerEntity(this.gameStore.dealState.viewPlayerId),
						TileType.CONCEALED,
						this.plh,
						this.allTilesQ
					));
					break;
				case GameEventType.GameDialog_Submit:
					if (value.data) {
						const submittedData: IGameDialogSubmitData = value.data;
						this.onGameDialogSubmit(submittedData);
					}
					break;
			}
		}
		catch (e) {
			console.error("InputRulesSystem.onGameEvent: error: " + e);
		}
	}

	protected onGameDialogSubmit(data: IGameDialogSubmitData): void {
		const button: IGameDialogButtonConfig = data.button;
		const dialog: IGameDialogConfig = data.dialog;
		if (environment.isDebug) {
			console.log(`onGameDialogSubmit: dialog=${dialog?.id}:${dialog?.title}, button=${button?.data}:${button?.label}`);
		}

		// const viewPlayerId = this.gameStore.dealState.viewPlayerId;
		switch (dialog.id) {
			case "MultipleChoiceDialog":
				switch (button.id) {
					case ActionMoveType.CHOW:
						// data: {tileId: slotTileW.tinyId, action: actionType},
						const { tileId: chowTileId, action } = button.data as { tileId: number, action: ActionMoveType };
						this.netManager.makeActionMove({
							action,
							tileId: chowTileId,
							gameStorageW: this.gameStorageQ,
							playersArr: this.playersQRA
						});
						break;
					case ActionMoveType.KONG_SELF:
						const kongSelfTileId = button.data as number;
						this.netManager.makeMove(ActionMoveType.KONG_SELF, kongSelfTileId, this.gameStorageQ);
						break;
				}
		}
	}

	private isTileGraphics(object: Object3D): boolean {
		return object && object.name === "TileGraphics";
	}

	private onTileOverMove(tileGraphics: TileGraphics, point?: { x: number, y: number }): void {
		if (point) {
			this.appEvents.send(AppEventType.MoveTooltip, { point });
		}
	}

	

	protected onTileClick(tileData: TileDataComponent, playerId: number, tileEntity: Entity, point: { x: number, y: number }): void {
		try {
			console.log("onTileClick: tileData:", tileData);
			console.log("onTileClick: playerId:", playerId);
			console.log("onTileClick: tileEntity:", tileEntity);
			console.log("onTileClick: point:", point);

			console.log(`onTileClick: Initial state of this:`, this);
			console.log(`onTileClick: playerId=${playerId} tile=${tileData.fullId}`);
			const iCanDiscard = this.gameAccessHelper.iCanDiscardTile(this.gameStorageQ);
			console.log("onTileClick: iCanDiscard:", iCanDiscard);
			if (!iCanDiscard.result) {
				console.log(`onTileClick: You cannot throw tile -- ${iCanDiscard.error}:${iCanDiscard.errorMessage}`);
				return;
			}
			if (playerId !== this.gameStore.dealState.viewPlayerId) {
				console.log("onTileClick: This is not your tile");
				return;
			}
			if (tileEntity.getComponent(TileTypeComponent).value !== TileType.CONCEALED) {
				console.log("onTileClick: This is not a concealed tile");
				return;
			}

			const tiles = this.allTilesQ;
			console.log("onTileClick: tiles:", tiles);

			this.gameWorld.addActions([
				new ConcealedToSlotPrepare(tileEntity, tiles),
				new UpdateSlotTile(tiles, this.gameStorageQ.tableOptions.rulesSettings.discardsMode, this.playersQRA)
			]);
			console.log("onTileClick: Actions added to gameWorld");

			console.log("onTileClick: Calling netManager.putTile with tileFullId:", tileData.fullId, "playerId:", playerId, "gameStorage:", this.getGameStorage());


			this.netManager.putTile(tileData.fullId, playerId, this.getGameStorage())
				.then(success => {
					console.log("onTileClick: netManager.putTile success:", success);
					if (!success) {
						this.gameWorld.addAction(new ConcealedToSlotPrepareCancel(tiles));
						this.gameWorld.addAction(new UpdateSlotTile(tiles, this.gameStorageQ.tableOptions.rulesSettings.discardsMode, this.playersQRA));
						this.gameWorld.addAction(new UpdatePlayerTilesAction(this.getPlayerEntity(playerId), TileType.CONCEALED, this.plh, tiles));
						console.log("onTileClick: Actions added to gameWorld on unsuccessful putTile");
					}
				})
				.catch(error => {
					console.error('onTileClick: Error occurred in netManager.putTile:', error);
					throw error;
				});

			console.log(`onTileClick: Final state of this:`, this);
		} catch (error) {
			console.error('onTileClick: Error in onTileClick: ', error);
		}
	}

	protected onTileMultiClick(tileData: TileDataComponent, playerId: number, tileEntity: Entity): void {
	}

	private onTileOver(tileGraphics: TileGraphics, point?: { x: number, y: number }): void {
		this.currentOver = tileGraphics.tileMesh;
		this.currentOver.currentHex = this.currentOver.material.emissive.getHex();
		this.currentOver.material.emissive.setHex(0x660000);

		/*// Remove tile tooltips as requested by Slava
		const tileEntity = TileEntityHelper.getTileEntityByTileGraphics(tileGraphics, this.allTilesQ);
		if (tileEntity) {
			const tileId = tileEntity.getComponent(TileDataComponent).fullId;
			if (point) {
				this.appEvents.send(AppEventType.ShowTooltip, {message: `Tile: ${tileId}`, point});
			}
		}*/
	}

	private onTileOut(): void {
		if (this.currentOver) {
			this.currentOver.material.emissive.setHex(this.currentOver.currentHex);
			this.currentOver = null;
			/* // Remove tile tooltips as requested by Slava
			this.appEvents.send(AppEventType.HideTooltip);*/
		}
	}

	private onTileDragStart(tileGraphics: TileGraphics): void {
		const lcsTileEntity = TileEntityHelper.getTileEntityByTileGraphics(tileGraphics, this.allTilesQ);
		if (
			lcsTileEntity.getComponent(TileTypeComponent).value === TileType.CONCEALED
			&& lcsTileEntity.getComponent(TileDataComponent).state !== TileState.WRONG_MJ
		) {
			console.log("Drag start -- e.id=" + lcsTileEntity.id + ", tile=", lcsTileEntity);
			// this.onTileDragStart(tileEntity);

			// orient drag plane same as dragging tile
			// (dragPlaneMesh.geometry as PlaneGeometry) = me.target.position.clone();
			this.dragPlaneMesh.position.copy(tileGraphics.position.clone()).y = 0;
			this.dragPlaneMesh.rotation.copy(tileGraphics.rotation.clone());
			// this.dragPlaneMesh.visible = false;

			//
			lcsTileEntity.addComponent(DraggingTagComponent);
		}
	}

	private onTileDragMove(allTiles: Entity[], event: MouseEvent): void {
		const tileEntity = this.draggingTilesQ[0];
		if (!tileEntity) {
			return;
		}
		const p: Vector3 = this.pointer.getIntersectionPoint(this.dragPlaneMesh, event); // TODO: get rid of this.pointer ref
		if (!p) {
			return;
		}
		const playerId = tileEntity.getComponent(PlayerIdComponent).playerId;
		const lastTile: Entity = allTiles.find(
			te => te.getComponent(TileNodesComponent).next === null
				&& te.getComponent(TileTypeComponent).value === TileType.CONCEALED
				&& te.getComponent(PlayerIdComponent).playerId === playerId
		);
		if (!lastTile) {
			return;
		}

		let firstTile = null;
		let tile = lastTile;
		while (tile) {
			const x = tile.getComponent(TilePositionComponent).position.x;
			if (p.x > x + TileDimensions.TW * 0.5 && tile !== tileEntity) {
				break;
			}
			if (!tile.getComponent(TileNodesComponent).previous) {
				firstTile = tile;
			}
			tile = tile.getComponent(TileNodesComponent).previous;

		}

		console.log(`LongClickMove: drag tile ${tileEntity.id} after ${tile?.id} or before ${firstTile?.id}`);

		const afterTile = tile;
		const tileEntityNodes = tileEntity.getComponent(TileNodesComponent);

		if (afterTile) {
			if (tileEntity !== afterTile
				&& (!tileEntityNodes.previous || tileEntityNodes.previous !== afterTile)) {
				console.log(`LongClickMove: drag tile after`);
				TileEntityHelper.linkPrevAndNextTileNodes(tileEntity);
				TileEntityHelper.insertTileAfter(tileEntity, afterTile);
				this.plh.updatePlayerTilesPositions(this.getPlayerEntity(playerId), allTiles, TileType.CONCEALED);
			}
		}
		else {
			if (tileEntity !== firstTile) {
				console.log(`LongClickMove: drag tile before`);
				TileEntityHelper.linkPrevAndNextTileNodes(tileEntity);
				TileEntityHelper.insertTileBefore(tileEntity, firstTile);
				this.plh.updatePlayerTilesPositions(this.getPlayerEntity(playerId), allTiles, TileType.CONCEALED);
			}
		}

		const tilePos = tileEntity.getMutableComponent(TilePositionComponent).position;
		tilePos.set(p.x - TileDimensions.TW / 2, tilePos.y, tilePos.z);
	}

	private cancelDrag() {
		console.log(`Drag cancel. There are ${this.draggingTilesQ.length} dragging tiles`);
		const lceTileEntity = this.draggingTilesQ[0];

		if (!lceTileEntity) {
			return;
		}
		console.log("Drag cancel -- e.id=" + lceTileEntity.id + ", tile=", lceTileEntity);
		const allTiles = this.allTilesQ;
		lceTileEntity.removeComponent(DraggingTagComponent);
		const playerId1 = lceTileEntity.getComponent(PlayerIdComponent).playerId;
		this.plh.updatePlayerTilesPositions(this.getPlayerEntity(playerId1), allTiles, TileType.CONCEALED);
	}

	protected processMouseInputAction(input: IInputMouseEvent): void {
		try {
			const allTiles = this.allTilesQ;
			switch (input.type) {
				case InputMouseEventType.CLICK:
					if (input.target && this.isTileGraphics(input.target)) {
						const cTileData = TileEntityHelper.getTileDataByTileGraphics(input.target as TileGraphics, this.allTilesQ);
						if (cTileData) {
							if (input.clickCount === 1) {
								this.onTileClick(cTileData.tileData, cTileData.playerId, cTileData.tileEntity, input.point);
							}
							else {
								this.onTileMultiClick(cTileData.tileData, cTileData.playerId, cTileData.tileEntity);
							}
						}
						else {
							console.warn("processMouseInputAction.Tile: cannot find corresponding entity");
						}
					}
					break;
				/*case InputMouseEventType.MultiClick:
					if (this.isTileGraphics(input.target)) {
						const cTileData = TileEntityHelper.getTileDataByTileGraphics(input.target as TileGraphics, this.allTilesQ);
						this.onTileMultiClick(cTileData.tileData, cTileData.playerId, cTileData.tileEntity);
						return true;
					}
					break;*/
				case InputMouseEventType.ROLL_OVER:
					if (this.isTileGraphics(input.target)) {
						this.onTileOver(input.target as TileGraphics, input.point);
					}
					break;
				/*Turned off tooltips as requested by S on 2020/12/03
				case InputMouseEventType.MOVE_OVER:
					if (this.isTileGraphics(input.target)) {
						this.onTileOverMove(input.target as TileGraphics, input.point);
					}
					break;
				 */
				case InputMouseEventType.ROLL_OUT:
					this.onTileOut();
					break;
				case InputMouseEventType.DRAG_START:
					if (this.isTileGraphics(input.target)) {
						this.onTileDragStart(input.target as TileGraphics);
					}
					break;
				case InputMouseEventType.DRAG_MOVE:
					this.onTileDragMove(allTiles, input.mouseEvent);
					break;
				case InputMouseEventType.DRAG_END:
					console.log("DRAG_END. Stop dragging.");
					this.cancelDrag();
					break;
			}
		}
		catch (e) {
			console.error("InputRulesSystem.processMouseInputAction: error: " + e);
		}
	}

	/*private processKeyboardInputAction(input: IInputKeyPressed): boolean {
		switch (input.keyCode) {
			case KeyCode.KEY_A:
				
				break;
		}
		return false;
	}*/

	execute(delta: number, time: number): void {
		if (this.queries.inputQuery.results.length > 0) {
			try {
				const iq = this.queries.inputQuery.results[0];
				const iqQuery = iq.getComponent(InputQueryComponent).query;

				if (iqQuery.length > 0) {
					iqQuery.forEach(action => {

						if (action.type === InputActionType.Mouse) {
							this.processMouseInputAction(action.payload as IInputMouseEvent);
						}
						/*else if (action.type === InputActionType.Keyboard) {
							this.processKeyboardInputAction(action.payload as IInputKeyPressed);
						}*/
					});
				}
			}
			catch (e) {
				console.error("InputRulesSystem.execute: error processing input query: " + e);
			}
		}

		/**
		 * Cancel drag if tile is changing its origin (type - wall / concealed / melded / ..)
		 */
		if (this.queries.tilesChangedType.changed.length > 0) {
			try {
				const draggingTileEntity = this.draggingTilesQ[0];
				if (draggingTileEntity) {
					this.queries.tilesChangedType.changed.forEach(changedTile => {
						console.log("changedTile -- " + changedTile.getComponent(TileDataComponent).fullId);
						if (changedTile === draggingTileEntity) {
							console.log("tile type of dragged tile has changed");
							this.cancelDrag();
						}
					});
				}
			}
			catch (e) {
				console.error("InputRulesSystem.execute: error processing tilesChangedType: " + e);
			}
		}
	}

	// *************************************************************************************************************************
	protected showSnack(message: SnackMessage): void;
	protected showSnack(message: SnackResourceMessage): void;
	protected showSnack(message: SnackData): void {
		this.appEvents.send(AppEventType.ShowSnack, { message });
	}

	// *************************************************************************************************************************
	protected get playersQ(): Entity[] {
		return this.queries.players.results;
	}

	protected get playersQRA() {
		return new PlayersArray().setPlayerEntities(this.playersQ);
	}

	protected get allTilesQ(): Entity[] {
		return this.queries.tiles.results;
	}

	protected get actionMenuQ(): Entity[] {
		return this.queries.actionMenuItems.results;
	}

	protected get draggingTilesQ(): Entity[] {
		return this.queries.draggingTiles.results;
	}

	protected getPlayerEntity(playerId: number): Entity {
		return this.playersQ.find(playerEntity => playerEntity.getComponent(PlayerIdComponent).playerId === playerId);
	}

	protected getGameStorage(): Entity {
		return this.queries.gameStorage.results[0];
	}

	protected get gameStorageQ(): GameStorageWrapper {
		return new GameStorageWrapper(this.getGameStorage());
	}
}

InputRulesSystem.queries = {
	tiles: AllTilesQuery,
	players: PlayersQuery,
	gameStorage: GameStorageQuery,
	actionMenuItems: ActionMenuQuery,

	draggingTiles: {
		components: [DraggingTagComponent]
	},
	tilesChangedType: {
		components: [TileDataComponent, TileTypeComponent],
		listen: {
			added: false,
			removed: false,
			changed: true
		}
	},
	inputQuery: {
		components: [InputQueryComponent]
	},

};
