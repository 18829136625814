import {InputRulesSystem} from "../common/InputRulesSystem";

/**
 * Game Rules-specified Input handler system.
 */
export class InputRulesHKSystem extends InputRulesSystem {
	
	/*protected onGameDialogSubmit(data: IGameDialogSubmitData): void {
		
		const button: IGameDialogButtonConfig = data.button;
		const dialog: IGameDialogConfig = data.dialog;
		// console.log(`onGameDialogSubmit: button=${button}, dialog=${dialog}`);
		console.log(`onGameDialogSubmit: data=${data}`);
		// const viewPlayerId = this.gameStore.dealState.viewPlayerId;
		switch (dialog.id) {
			case "MultipleChoiceDialog":
				switch (button.id) {
					case ActionMoveType.CHOW:
						// data: {tileId: slotTileW.tinyId, action: actionType},
						const {tileId: chowTileId, action} = button.data as { tileId: number, action: ActionMoveType };
						this.netManager.makeMove(action, chowTileId, this.getGameStorage());
						break;
					case ActionMoveType.KONG_SELF:
						const {tileId: kongSelfTileId} = button.data as { tileId: number };
						this.netManager.makeMove(ActionMoveType.KONG_SELF, kongSelfTileId, this.getGameStorage());
						break;
				}
		}
	}*/
}
