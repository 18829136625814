import {GuildDTO} from "./GuildDTO";
import {Side} from "../../game/enums/Side";

export class GameUserInfoDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.GameUserInfo"
	
	public UserId: number;
	public Name: string;
	public Belt: number;
	public Gender: number;
	public StateId: number;
	public Side: Side;
	public IsInGame: boolean;
	public IsOnline: boolean;
	public Guild: GuildDTO;
	public Rating: number;
	public Country: string;
	public TimeBankSec: number = -1; //-1 used as not_setted value
	public Chips: number;
	public FacebookUid: string;
	public MatchmoveUid: string;
	public IsHiddenBot: boolean;
}
