// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.TournamentTakeSeatInfo"

import {TournamentDTO} from "./TournamentDTO";
import {OnlineGameDTO} from "../OnlineGameDTO";

export class TournamentTakeSeatInfoDTO {
	public Game: OnlineGameDTO;
	public Tournament: TournamentDTO;
	public Official: TournamentDTO;
}
