import {Entity, World} from "ecsy";
import {
	GraphicsComponent,
	InHandIndexComponent,
	PlayerIdComponent,
	TileDataComponent,
	TileNodesComponent,
	TilePositionComponent,
	TileRotationComponent,
	TileStoreComponent,
	TileTypeComponent
} from "../ecs/common/components";
import {TileType} from "../enums/TileType";
import {GameGraphics} from "../GameGraphics";
import {TileSetHelper} from "./TileSetHelper";
import {TileEntityHelper} from "./TileEntityHelper";
import {TileWrapper} from "../ecs/wrappers/TileWrapper";

export class WallTilesHelper {
	
	public static generateTileSet(tilesCount: number, hasDeadWall: boolean, world: World, g: GameGraphics): Entity[] {
		console.log(`Create tile set: tilesCount=${tilesCount}`);
		const tilesSet: Entity[] = [];
		let prev: Entity = null;
		
		for (let i = 0; i < tilesCount; i++) {
			const tileEntity = world.createEntity()
				.addComponent(TileDataComponent)
				.addComponent(TileTypeComponent, {value: TileType.WALL})
				.addComponent(PlayerIdComponent)
				.addComponent(InHandIndexComponent, {inHandIndex: i})
				.addComponent(GraphicsComponent, {obj: g.createEmptyTile()})
				.addComponent(TilePositionComponent)
				.addComponent(TileRotationComponent)
				.addComponent(TileNodesComponent, {previous: prev})
				.addComponent(TileStoreComponent, {meldedGroup: 0})
			;
			tilesSet.push(tileEntity);
			if (prev) {
				prev.getMutableComponent(TileNodesComponent).next = tileEntity;
			}
			prev = tileEntity;
		}
		return tilesSet;
	}
	
	public static removeTileSet(tiles: Entity[], world: World, g: GameGraphics): boolean {
		console.log("removeTileSet", arguments);
		for (const tileEntity of tiles) {
			const obj = tileEntity.getComponent(GraphicsComponent).obj;
			if (obj.parent) {
				obj.parent.remove(obj);
			}
			tileEntity.remove();
		}
		return true;
	}
	
	public static setBreakPoint(wallTiles: Entity[], tileIndex: number, wallLength: number, hasDeadWall: boolean): void {
		if (wallTiles.length < wallLength) {
			console.warn("WallTilesHelper.setBreakPoint: break point can be set only while wall is full");
			return;
		}
		const breakIndex = (tileIndex - tileIndex % 2) % wallLength; // break index should be always even
		if (breakIndex === 0) { // no changes needed
			return;
		}
		// connect current last and first tiles
		const firstTile = TileSetHelper.getFirstTile(wallTiles);
		const lastTile = TileSetHelper.getLastTile(wallTiles);
		TileEntityHelper.linkTilesNodes(lastTile, firstTile);
		// break tiles at new position
		/* NOTE: to set break point on partially distributed wall newFirstTile ? newLastTile calculating can be complex
		 as they can be among already distributed tiles. firstTile and lastTile indexes should also be shifted this case. */
		const newFirstTile = WallTilesHelper.getTileByInHandIndex(wallTiles, breakIndex);
		const newLastTile = WallTilesHelper.getTileByInHandIndex(wallTiles, breakIndex - 1);
		TileEntityHelper.breakLinkedTilesNodes(newLastTile, newFirstTile);
		
		const assignNextIndex = (thisTile: Entity, index: number) => {
			if (thisTile) {
				assignNextIndex(TileEntityHelper.getTileNodes(thisTile)?.next, index + 1);
			}
			return;
		};
		assignNextIndex(newFirstTile, 0);
		
		if (hasDeadWall) {
			this.updateDeadWall(wallTiles);
		}
	}
	
	public static getTileAtIndex(allTiles: Entity[], singleWallLength: number, index: number, fromEnd: boolean = false): Entity {
		const wallTiles = this.getWallTiles(allTiles);
		const tile = TileWrapper.wrap(TileSetHelper.getLastTile(wallTiles));
		return this.getTileByInHandIndex(wallTiles, /*tile.inHandIndex*/   singleWallLength * 8 - index);
	}
	
	public static getTile(allTiles: Array<Entity>): Entity {
		const wallTiles = this.getWallTiles(allTiles);
		if (wallTiles.length === 0) {
			console.error("No more tiles in wall remained!");
			return null;
		}
		return TileSetHelper.getFirstTile(wallTiles);
	}
	
	/**
	 * Returns first available tile from end of wall
	 * @param allTiles
	 */
	public static getTileFromEnd(allTiles: Array<Entity>): Entity {
		const wallTiles = this.getWallTiles(allTiles);
		if (wallTiles.length === 0) {
			console.error("No more tiles in wall remained!");
			return null;
		}
		return TileSetHelper.getLastTile(wallTiles);
	}
	
	public static getWallTiles(alTiles: Array<Entity>): Array<Entity> {
		return alTiles.filter(tile => tile.getComponent(TileTypeComponent).value === TileType.WALL);
	}
	
	private static getTileByInHandIndex(wallTiles: Entity[], inHandIndex: number): Entity {
		return wallTiles.find(tile => tile.getComponent(InHandIndexComponent).inHandIndex === inHandIndex);
	}
	
	public static updateDeadWall(wallTiles: Entity[], tilesCount: number = 14) {
		let tileEntity = TileSetHelper.getLastTile(wallTiles);
		if (tileEntity) {
			let currentIndex = 0;
			while (currentIndex < tilesCount && !TileEntityHelper.isFirstTile(tileEntity)) {
				TileEntityHelper.markAsDeadWall(tileEntity);
				// tileEntity = TileWrapper.wrap(tileEntity).nodes.previous;
				tileEntity = TileEntityHelper.getTileNodes(tileEntity).previous;
				currentIndex++;
			}
		}
	}
	
}
