import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AppEventsService} from "../../services/AppEventsService";
import {AppEventType} from "../../../game/AppEventsPipe";

@Component({
	selector: "app-lobby",
	templateUrl: "./lobby.component.html",
	styleUrls: ["./lobby.component.scss"]
})
export class LobbyComponent implements OnInit {
	
	constructor(private router: Router, public appEventsService: AppEventsService) {
	}
	
	ngOnInit(): void {
	}
	
	onBugRepClick() {
		this.appEventsService.send(AppEventType.BugReport_Show);
	}
	
	onLogoutClick() {
		this.appEventsService.send(AppEventType.Logout);
	}
	
	onSettingsClick() {
		this.appEventsService.send(AppEventType.Settings_Show);
	}
}
