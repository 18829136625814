import {Entity, System} from "ecsy";
import {
	LocationComponent,
	PlayerActionsComponent,
	PlayerIdComponent,
	PlayerStorageTagComponent,
	PlayerStoreComponent,
	RealSideComponent,
	TimeBankComponent,
	ViewStateComponent
} from "./common/components";
import {IExtSystem} from "./IExtSystem";
import {RulesHelperHK} from "./hk/RulesHelperHK";
import {GameStore} from "../../store/GameStore";
import {IReactionDisposer, reaction} from "mobx";
import {Side} from "../enums/Side";
import {PlayerEntityHelper} from "../helpers/PlayerEntityHelper";
import {GameGraphics} from "../GameGraphics";
import {GameStorageQuery} from "./queries";
import {GameStorageWrapper} from "./wrappers/GameStorageWrapper";
import {GameEventsPipe} from "../GameEventsPipe";
import {PlayersArray} from "./wrappers/PlayersArray";
import {ActionMoveType} from "../enums/ActionMoveType";
import {Gender} from "../enums/Gender";

export class PlayersSystem extends System implements IExtSystem {
	private gameEvents: GameEventsPipe;
	private gameStore: GameStore;
	private gameGraphics: GameGraphics;
	
	private readonly actions: GameRulesActions[] = [];
	private reactDisposers: Array<IReactionDisposer> = [];
	
	init(attributes) {
		this.gameStore = attributes.gameStore;
		this.gameGraphics = attributes.gameGraphics;
		this.gameEvents = attributes.gameEvents;
		this.setup();
	}
	
	setup() {
		this.addReactions();
	}
	
	unregister(): this {
		try {
			this.stop();
			this.actions.length = 0;
			this.removeReactions();
			this.disposePlayerEntities();
		}
		catch (e) {
			console.error("PlayersSystem.unregister: " + e);
		}
		return this;
	}
	
	execute(delta: number, time: number): void {
		if (this.actions.length > 0) {
			try {
				while (this.actions.length > 0) {
					switch (this.actions.shift()) {
						case GameRulesActions.UPDATE_PLAYERS:
							this.updatePlayerEntities();
							break;
						case GameRulesActions.ROTATE_PLAYERS:
							const basePlayerId: number = this.gameStore.dealState.basePlayerId;
							const players = this.queries.players.results;
							this.rotatePlayers(basePlayerId, players, this.gameStorageQ.tableOptions.sides);
							break;
						case GameRulesActions.REMOVE_PLAYERS:
							this.disposePlayerEntities();
							break;
					}
				}
			}
			catch (e) {
				console.error("PlayersSystem.execute: actions: " + e);
			}
		}
		
		if (this.queries.viewSettings.changed.length > 0) {
			try {
				const viewPlayerId = this.queries.viewSettings.changed[0].getComponent(ViewStateComponent).viewPlayerId;
				const player = PlayerEntityHelper.getPlayer(viewPlayerId, this.players);
				this.gameGraphics.viewSide(player.location);
			}
			catch (e) {
				console.error("PlayersSystem.execute: viewSettings.changed: " + e);
			}
		}
	}
	
	// *************************************************************************************************************************
	private addReactions() {
		this.removeReactions();
		/** React on changing playersQ count or playersQ sides change */
		const disposer: IReactionDisposer = reaction(
			() => this.gameStore.gameUsers.players.map(player => player.UserId + "," + player.Side),
			(v) => {
				console.info("PlayersSystem.playerSidesChanged: all players=" + (this.gameStore.gameUsers.players.length === this.gameStorageQ.tableOptions.maxPlayers));
				if (this.gameStore.gameUsers.players.length === this.gameStorageQ.tableOptions.maxPlayers) { // TODO: check for hidden bots not counting as playersQ
					this.onPlayersSideChanged();
					// this.onPlayersCountChanged();
				}
				else { // table not full. at least one more player is required
					this.onPlayersCountChanged();
				}
			},
			{
				name: "PlayersSystem.PlayerContOrSide_changed",
				// delay: 1000, // shouldn't add delay as players should be settled when game messages start to convert
			});
		this.reactDisposers.push(disposer);
		
		
	}
	
	private removeReactions() {
		if (this.reactDisposers) {
			this.reactDisposers.forEach(disposer => disposer());
		}
		this.reactDisposers = [];
	}
	
	protected createPlayerEntity(playerId: number, name: string, gender: Gender): Entity {
		return this.world.createEntity("Player" + playerId)
			.addComponent(PlayerStorageTagComponent) // Tag this entity as Player
			.addComponent(PlayerIdComponent, {playerId})
			.addComponent(LocationComponent)
			.addComponent(RealSideComponent)
			.addComponent(PlayerStoreComponent, {name, gender})
			.addComponent(PlayerActionsComponent)
			.addComponent(TimeBankComponent)
			;
	}
	
	public removePlayerEntities(): void {
		// TODO : or just call entity.remove() ?
		this.actions.push(GameRulesActions.REMOVE_PLAYERS);
	}
	
	private disposePlayerEntities(): void {
		// make a copy of players array because the length of the players array changes dynamically
		[...this.players].forEach(player => player.remove());
	}
	
	private onPlayersCountChanged(): void {
		console.log("PlayersSystem.onPlayersCountChanged");
		if (!~this.actions.indexOf(GameRulesActions.UPDATE_PLAYERS)) {
			this.actions.push(GameRulesActions.UPDATE_PLAYERS);
		}
	}
	
	private onPlayersSideChanged(): void {
		console.log("PlayersSystem.onPlayersSideChanged");
		if (!~this.actions.indexOf(GameRulesActions.ROTATE_PLAYERS)) {
			this.actions.push(GameRulesActions.ROTATE_PLAYERS);
		}
	}
	
	public rotatePlayers(playerIdToSeatAtSouth: number, playerEntities: Entity[], sides: Side[]): boolean {
		console.log(`PlayersSystem.rotatePlayersX: ${playerIdToSeatAtSouth} sides[${sides.length}]: ${sides}`);
		
		const ptsas = this.gameStore.gameUsers.getUserById(playerIdToSeatAtSouth);
		if (ptsas/* && ptsas.Location !== Side.South*/) {
			const oldUsers = this.gameStore.gameUsers.players.map(player => ({id: player.UserId, side: player.Side}));
			const ptsasLocationIndex = sides.indexOf(ptsas.Side);
			const sidesCount = sides.length;
			this.gameStore.gameUsers.players.forEach(player => {
				const originLoc = oldUsers.find(p => p.id === player.UserId).side;
				const locIndex = sides.indexOf(originLoc);
				const newLoc = sides[(-ptsasLocationIndex + locIndex + sidesCount) % sidesCount];
				this.gameStore.gameUsers.updateUser(player.UserId, {Location: newLoc});
			});
			
			this.updatePlayerEntities();
			console.log("PlayersSystem.rotatePlayersX: complete");
			return true;
		}
		else {
			console.log("PlayersSystem.rotatePlayersX: not needed");
			return false;
		}
	}
	
	protected updatePlayerEntities(): void {
		console.log("PlayersSystem.updatePlayerEntities: ");
		const players = this.queries.players.results;
		const lc = RulesHelperHK.getLocationTransforms();
		const users: ITablePlayer[] = this.gameStore.gameUsers.getPlayers().map(player =>
			({
				id: player.UserId,
				name: player.Name,
				gender: player.Gender,
				realside: player.Side,
				location: player.Location,
				locationTransform: lc[player.Location]
			}));
		
		// find and remove all player entities of missing players (who left the game or changes status and is not in players list)
		const missingPlayers = this.playersQRA
			.filter(player => !users.find(user => user.id === player.id));
		if (missingPlayers.length) {
			console.log(`PlayersSystem.updatePlayerEntities: remove ${missingPlayers.length} missing player(s)`);
			missingPlayers.forEach(player => this.playersQRA.removePlayerById(player.id));
		}
		
		//
		users.forEach(tablePlayer => {
			const playerEntity = players.find(player => player.getComponent(PlayerIdComponent).playerId === tablePlayer.id)
				?? this.createPlayerEntity(tablePlayer.id, tablePlayer.name, tablePlayer.gender);
			
			const pLoc = playerEntity.getComponent(LocationComponent).side;
			if (pLoc !== tablePlayer.location) {
				playerEntity.getMutableComponent(LocationComponent).side = tablePlayer.location;
			}
			const pRealSide = playerEntity.getComponent(RealSideComponent).side;
			if (pRealSide !== tablePlayer.realside) {
				playerEntity.getMutableComponent(RealSideComponent).side = tablePlayer.realside;
			}
			playerEntity.getMutableComponent(PlayerStoreComponent).locationTransform = lc[tablePlayer.location];
			// set additional rotation for south concealed tiles
			playerEntity.getMutableComponent(PlayerStoreComponent).concealedRotationX = tablePlayer.id === this.gameStorageQ.myPlayerId ? 45 * 3.14 / 180 : 0;
			console.info(`PlayersSystem.updatePlayers: ${tablePlayer.name}:${tablePlayer.id} loc: ${tablePlayer.location} real: ${tablePlayer.realside}`);
		});
		console.log("update complete");
	}
	
	private get players(): Entity[] {
		return this.queries.players.results;
	}
	
	// Players query results array
	public get playersQRA() {
		return new PlayersArray().setPlayerEntities(this.players);
	}
	
	private get gameStorageQ(): GameStorageWrapper {
		const gameStorage = this.queries.gameStorage.results[0];
		return gameStorage ? new GameStorageWrapper(gameStorage) : null;
	}
}

PlayersSystem.queries = {
	players: {
		components: [PlayerStorageTagComponent],
		listen: {
			added: true,
			removed: true,
			changed: false,
		}
	},
	viewSettings: {
		components: [ViewStateComponent],
		listen: {
			added: false,
			removed: false,
			changed: true,
		}
	},
	gameStorage: GameStorageQuery,
};

enum GameRulesActions {
	ROTATE_PLAYERS,
	UPDATE_PLAYERS,
	REMOVE_PLAYERS,
}

interface ITablePlayer {
	id: number;
	name: string;
	gender: Gender;
	location: Side;
	realside: Side;
	avMovesStr?: string;
	avMoves?: Array<ActionMoveType>;
	locationTransform?;
	concealedRotationX?: number;
}
