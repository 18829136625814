function getArrayWithLimitedLength(length) {
	const array = new Array();
	
	array.push = function() {
		const argLen = arguments.length;
		if (this.length + argLen >= length) {
			let i = this.length + argLen - length;
			while (i-- > 0) {
				this.shift();
			}
		}
		return Array.prototype.push.apply(this, arguments);
	};
	
	return array;
	
}

export const getLimitedArray = getArrayWithLimitedLength;

