import {IGameAction} from "../common/GameAction";
import {GameEventsPipe, GameEventType} from "../../GameEventsPipe";
import {Entity, World} from "ecsy";
import {GameStorageWrapper} from "../wrappers/GameStorageWrapper";
import {GameDialogType, IGameDialogButtonConfig, IGameDialogConfig} from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {InGameDialog} from "./enumCO";
import {TileSetHelper} from "../../helpers/TileSetHelper";
import {NetGameManager} from "../../NetGameManager";
import {AppEventsPipe, AppEventType} from "../../AppEventsPipe";
import {Side} from "../../enums/Side";
import {PlayersArray} from "../wrappers/PlayersArray";
import {SoundsHelper} from "../../helpers/SoundsHelper";
import {Sound} from "../../enums/Sound";

export class ReplaceFlowersSide implements IGameAction {
	constructor(private params: {
		playerId: number,
		tileSet: Entity[],
		gameStorageQW: GameStorageWrapper,
		gameEventsPipe: GameEventsPipe,
		autoReplace: boolean,
		netManager: NetGameManager,
	}) {
	}
	
	execute() {
		const autoReplace = this.params.autoReplace ?? false;
		const isFlowerFn = this.params.gameStorageQW.tableOptions.rulesSettings.isFlower;
		const flowers = TileSetHelper.getPlayerConcealedTiles(this.params.playerId, this.params.tileSet, isFlowerFn);
		console.log(`ReplaceFlowersSide.execute: ${this.params.playerId}, autoReplace=${this.params.autoReplace}, flowers=${flowers}`);
		if (autoReplace) {
			if (flowers.length) {
				// const nm = container.get<NetGameManager>(TYPES.NetGameManager);
				const nm = this.params.netManager;
				nm.replaceFlowers(flowers.length)
					.catch();
			}
		}
		else {
			
			const buttons: IGameDialogButtonConfig[] = flowers
				.map<IGameDialogButtonConfig>((_, index) => ({
					label: "" + (index + 1),
					data: (index + 1)
				}));
			buttons.push({data: 0, label: "No Flowers", labelResourceKey: "Game.Dialog.ReplaceFlowers.btnNoFlowers"});
			
			const dialogCfg: IGameDialogConfig = {
				id: InGameDialog.ReplaceFlowers,
				type: GameDialogType.Regular,
				title: "Flower Replacement",
				titleResourceKey: "Game.Dialog.ReplaceFlowers.Caption",
				message: "How many flowers would you like to replace?",
				messageResourceKey: "Game.Dialog.ReplaceFlowers.Text",
				buttons,
			};
			// this.displayGameDialog(dialogCfg);
			// this.sendGameEvent(GameEventType.Display_GameDialog, value);
			this.params.gameEventsPipe.send(GameEventType.Display_GameDialog, dialogCfg);
		}
	}
}

export class ReplaceFlowers implements IGameAction {
	
	constructor(
		private type: "start" | "end",
		private autoReplaceFlowersOn: boolean,
		private gameStorage: GameStorageWrapper,
		private players: PlayersArray,
		private appEventsPipe: AppEventsPipe,
		private w: World,
	) {
	}
	
	execute() {
		if (this.type === "start") {
			// if (!this.autoReplaceFlowersOn) {
			this.appEventsPipe.send(AppEventType.ShowSnack, {
				rsKey: "Game.Message.FlowersReplacingStarted",
				fallbackMessage: "Replace Flowers Started",
				duration: 3000
			});
			// }
		}
		else {
			const imEastOnStart = this.players.getPlayerById(this.gameStorage.myPlayerId)?.realside === Side.East;
			// if (!this.autoReplaceFlowersOn) {
			if (imEastOnStart) {
				SoundsHelper.playSound(this.w, Sound.GAME.TIMER);
				this.appEventsPipe.send(AppEventType.ShowSnack, {
					message: "Flower Replacement finished. Discard a tile to start the game",
					duration: 6000
				});
			}
			else {
				this.appEventsPipe.send(AppEventType.ShowSnack, {
					rsKey: "Game.Message.DealStarted",
					fallbackMessage: "Deal Started",
					duration: 3000
				});
			}
			/*this.appEventsPipe.send(AppEventType.ShowSnack, imEastOnStart
				? {message: "Flower Replacement finished. Discard a tile to start the game", duration: 6000}
				: {rsKey: "Game.Message.DealStarted", fallbackMessage: "Deal Started", duration: 3000});*/
			
		}
	}
}

/*export class StartDealActionCO implements IGameAction {
	constructor(private params: {
		autoReplaceFlowers: boolean,
		gameStorage: GameStorageWrapper,
		players: PlayersArray,
		appEventsPipe: AppEventsPipe,
		w: World,
	}) {
	}
	
	execute() { // same as default, just no start sound for east
		
		const imEastOnStart = this.params.players.getPlayerById(this.params.gameStorage.myPlayerId)?.realside === Side.East;
		/!*	this.params.appEventsPipe.send(AppEventType.ShowSnack, imEastOnStart
			? {message: "Deal Started. Discard a tile to start the game", duration: 6000}
			: {rsKey: "Game.Message.DealStarted", fallbackMessage: "Deal Started", duration: 3000});
			*!/
		if (!this.params.autoReplaceFlowers) {
			this.params.appEventsPipe.send(AppEventType.ShowSnack, {
				rsKey: "Game.Message.DealStarted",
				fallbackMessage: "Deal Started",
				duration: 3000
			});
		}
	}
}*/
