import {commander, ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {LoginService} from "../net/service/LoginService";
import {UserStore} from "../store/UserStore";
import {Commands} from "./Commands";
import {ChatService} from "../net/service/ChatService";
import {GameStore} from "../store/GameStore";
import {GameService} from "../net/service/GameService";
import {ChatGamController} from "../game/ChatGamController";
import {AppEventsPipe, AppEventType, IAppEventNavigateTo} from "../game/AppEventsPipe";
import {NavPage} from "../app/enums/NavPage";

export class LogoutCommand extends ICommand {
	
	async execute(params?: any): Promise<any> {
		
		// TODO : clear all resources, quit table, stop GAM & Chats..
		console.log("Logout.. started");
		let successCount = 0;
		try {
			console.log("LogoutCommand.execute: stopping the chat..");
			this.CGC.stopRequesting();
			
			console.log("LogoutCommand.execute: exiting game..");
			// await commander.executeCommand(Commands.EXIT_GAME);
			await commander.executeCommand(Commands.RELEASE_GAME);
			
			if (this.userStore.isAuthenticated()) {
				console.log("LogoutCommand.execute: logging out..");
				const sessionKey = this.userStore.sessionKey;
				this.userStore.logout();
				await this.loginService.logout(sessionKey);
			}
			successCount++;
		}
		catch (e) {
			console.warn("LogoutCommand.execute: smth went wrong while logging out");
		}
		
		// navigating
		try {
			this.appEvents.send(AppEventType.NavigateTo, {route: NavPage.Root} as IAppEventNavigateTo);
			successCount++;
		}
		catch (e) {
			console.warn("LogoutCommand.execute: error navigating");
		}
		
		console.log("LogoutCommand.execute: logout complete.");
		return successCount === 2;
	}
	
	
	private get appEvents(): AppEventsPipe {
		return container.get<AppEventsPipe>(TYPES.AppEventsPipe);
	}
	
	private get chatService(): ChatService {
		return container.get<ChatService>(TYPES.ChatService);
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
	private get gameService(): GameService {
		return container.get<GameService>(TYPES.GameService);
	}
	
	private get loginService(): LoginService {
		return container.get<LoginService>(TYPES.LoginService);
	}
	
	private get CGC(): ChatGamController {
		return container.get<ChatGamController>(TYPES.ChatGamController);
	}
}
