import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TYPES} from "../../../../inversify/inversify.types";
import container from "../../../../inversify/inversify.config";
import {commander} from "../../../../commander/Commander";
import {Commands} from "../../../../commands/Commands";
import {OnlineGameDTO} from "../../../../net/dto/OnlineGameDTO";
import {GameListService} from "../../../../net/service/GameListService";
import {UserStore} from "../../../../store/UserStore";
import {GameMode} from "../../../../game/enums/GameMode";
import {GameType} from "../../../../game/enums/GameType";
import {AppEventsPipe, AppEventType} from "../../../../game/AppEventsPipe";
import {NavigationService} from "../../../services/navigation.service";
import {NavPage} from "../../../enums/NavPage";
import {TableCardAction} from "../games/table-card.component";
import {environment} from "../../../../environments/environment";
import {GameState} from "../../../../game/enums/GameState";

@Component({
	selector: "app-welcome",
	templateUrl: "./games-expert.component.html",
	styleUrls: ["./games-expert.component.scss"]
})
export class GamesExpertComponent implements OnInit {
	
	public gamesList: Array<OnlineGameDTO>;
	public myGames: Array<OnlineGameDTO>;
	
	public selectedGameMode;
	public avGameModes = [
		{mode: GameMode.School, label: "School", icon: "rowing"},
		{mode: GameMode.Fun, label: "Fun", icon: "rowing"}
	];
	public selectedGameRules;
	public avGameRules = [
		{id: GameType.HK, label: "HK", icon: "rowing"},
		{id: GameType.WP, label: "WP", icon: "rowing"},
		{id: GameType.CO, label: "CO", icon: "rowing"},
		{id: GameType.RCR, label: "JM", icon: "rowing"},
		// {id: GameType.RCR, label: "RCR", icon: "rowing"},
		// {id: GameType.EMA, label: "EMA", icon: "rowing"},
		{id: GameType.EC, label: "EC", icon: "rowing"},
		{id: GameType.TW, label: "TW", icon: "rowing"},
		{id: GameType.WP, label: "WP", icon: "rowing"}, // Add WP rules
	];
	
	private appEvents: AppEventsPipe;
	public userName: string;
	
	isDebug = environment.isDebug;
	
	constructor(private router: Router, private route: ActivatedRoute, private navService: NavigationService) {
	}
	
	ngOnInit(): void {
		const gt = this.navService.routerRules;
		
		this.selectedGameMode = this.avGameModes[0];
		this.selectedGameRules = this.avGameRules[gt === "hk" ? 0 : 1];
		this.loadGames();
		this.updateMyActiveGames();
		this.appEvents = container.get(TYPES.AppEventsPipe);
		this.userName = this.userStore.info.Profile.UserName;
	}
	
	get notMyGames() {
		return this.gamesList?.filter(game => !game.IsMyGame);
	}
	
	get iHaveGames(): boolean {
		// return (this.myGames || []).length !== 0;
		return this.myGames?.length > 0;
	}
	
	private loadGames(): void {
		this.gameListService.getGames(this.userStore.sessionKey, this.selectedGameMode.mode, this.selectedGameRules.id, 0)
			.then(games => this.gamesList = games)
			.catch(err => console.warn("GamesExpertComponent.: " + err));
	}
	
	private async updateMyActiveGames() {
		try {
			const mg = await this.gameListService.getMyGames(this.userStore.sessionKey);
			this.myGames = mg.filter(game => game.StateId === GameState.PLAYING);
		}
		catch (e) {
			console.error("GamesExpertComponent.updateMyActiveGames: " + e);
		}
	}
	
	doJoinGame(game: OnlineGameDTO): void {
		const gameParams = {gameId: game.GameId, roomId: game.RoomId};
		// TODO: join game and then proceed to table
		commander.executeCommand(Commands.JOIN_GAME, gameParams)
			.then((success) => {
					this.navService.navigate({page: NavPage.Table});
				},
				err => {
					console.error("GamesExpertComponent.doJoinGame: error: " + err, err);
				});
		
		
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameListService(): GameListService {
		return container.get<GameListService>(TYPES.GameListService);
	}
	
	changeMode(value: any) {
		this.selectedGameMode = value;
		this.loadGames();
	}
	
	changeRules(value: any) {
		this.selectedGameRules = value;
		this.loadGames();
	}
	
	onTableAction({action, table}: { action: TableCardAction; table: OnlineGameDTO }) {
		switch (action) {
			case "join":
				this.doJoinGame(table);
				break;
			default:
				this.appEvents.send(AppEventType.ShowSnack, {message: "Action: " + action});
		}
	}
	
	switchMode(mode: "normal" | "expert") {
		this.navService.navigate({page: mode === "expert" ? NavPage.GamesExpert : NavPage.Games});
	}
}
