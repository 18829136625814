<div class="engine-wrapper">
    <app-engine></app-engine>
</div>
<div class="ui-wrapper-router">
    <router-outlet></router-outlet>

</div>

<div class="G0" *ngIf="!g1visible"></div>
<div class="G1" *ngIf="g1visible"></div>
<div class="G-Fade"></div>
 <div class="Gradient"></div> 


<span class="no-select"
      style="position: absolute; bottom:.3rem; right:1rem; font-size:.7rem; line-height: 1; color: #7f7979;"
>{{version}}</span>
