<div style="position: relative;" [ngClass]="jpData.bannerType===10 ? 'blue' : 'brown'">

    <div style="position: absolute; width: 100%; padding: 1rem">
        <span id="title" [innerHTML]="jpData?.title"></span>
        <br>
        <span id="msg" [innerHTML]="jpData?.message"></span>
    </div>

    <div id="bottom-line">
        <span style="width: 100%; text-align: center; vertical-align: middle;  font-size: 1rem; color: #eeeeee; user-select: none;"
              [innerHTML]="jpData?.bottomLine"></span>
    </div>

    <button style="position: absolute; top:2px; right: 2px;" (click)="onClick()" mat-icon-button color="primary">
        <mat-icon>highlight_off</mat-icon>
    </button>

    <img style="object-fit: cover;"
         src="assets/img/JackPotMsg{{jpData?.bannerType}}.png">

</div>

