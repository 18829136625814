import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: "app-bug-report-dialog",
	template: `
       <h1 mat-dialog-title>Any thoughts or suggestions?</h1>
       <div mat-dialog-content>
           Your opinion is important for us
           <mat-form-field class="example-full-width">
               <mat-label>Leave us a comment</mat-label>
               <textarea #message matInput placeholder=""></textarea>
           </mat-form-field>
       </div>
       <div mat-dialog-actions>
           <button mat-raised-button color="primary" (click)="send(message.value)">Send</button>
           <button mat-raised-button mat-dialog-close cdk-focus-start>Close</button>
       </div>
	`,
	styles: [`.example-full-width {
       width: 100%;
   }

   .mat-dialog-content {
       width: 100%;
       margin: 0;
   }

   .mat-dialog-actions {
       justify-content: flex-end;
   }`
	]
})
export class BugReportDialogComponent {
	
	constructor(
		public dialogRef: MatDialogRef<BugReportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
	}
	
	send(message) {
		this.dialogRef.close({action: "send", message});
	}
	
}
