import {ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {loglite} from "../utils/logger-ite/LoggerLite";
import * as JSZip from "jszip";
import {AppStore} from "../store/AppStore";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../environments/environment";
import {BugReportDialogComponent} from "../app/ui/dialogs/BugReportDialog";
import {GameStore} from "../store/GameStore";
import {GameType} from "../game/enums/GameType";
import {saveAs} from "file-saver";

// import {saveAs} from "file-saver";

export class BugReportCommand extends ICommand {
	
	async execute({displayDialog = false, dialogSrv}: { displayDialog: boolean, dialogSrv?: MatDialog }): Promise<boolean> {
		try {
			const user = `${this.userStore.id}_${this.userStore.info?.Profile.UserName}`;
			const strVN = `v${this.appStore.version}_${this.userStore.id}_${this.userStore.info?.Profile.UserName}`;
			let info = `v${this.appStore.version}
	user: ${this.userStore.id} ${this.userStore.info?.Profile.UserName}
			`;
			const gameString = this.gameStore.joinedGame ? this.gameStore.joinedGame.GameId + "_" + this.gameStore.joinedGame.Name : "";
			if (this.gameStore.joinedGame) {
				info += `\nGame\n-------------------
	name: ${this.gameStore.joinedGame.Name}
	id: ${this.gameStore.joinedGame.GameId}
	rules: ${this.gameStore.joinedGame.GameTypeId} ${GameType[this.gameStore.joinedGame.GameTypeId]}
	state: ${this.gameStore.joinedGame.StateId}
	rounds: ${this.gameStore.joinedGame.RoundsCount}
	min.belt: ${this.gameStore.joinedGame.MinBelt}
	showWinHandOnly: ${this.gameStore.joinedGame.ShowWinHandOnly}
	------------------------`;
			}
			const log = loglite.getMessages().join("\n");
			// const canvas = await html2canvas(document.body); // whole page - Game + UI
			const canvas = document.getElementById("renderCanvas") as HTMLCanvasElement; // Game canvas only
			// document.body.appendChild(canvas);
			const png = canvas.toDataURL("image/png");
			// var fullQuality = canvas.toDataURL('image/jpeg', 1.0);
			// // data:image/jpeg;base64,/9j/4AAQSkZJRgABAQ...9oADAMBAAIRAxEAPwD/AD/6AP/Z"
			// var mediumQuality = canvas.toDataURL('image/jpeg', 0.5);
			// var lowQuality = canvas.toDataURL('image/jpeg', 0.1);
			
			const datetimeString = (new Date()).toISOString().replace(/[^0-9T]/g, "").replace("T", "-").substr(0, 15);
			
			if (displayDialog) {
				const dialogRef = dialogSrv.open(BugReportDialogComponent, {
					disableClose: true,
				});
				dialogRef.afterClosed().subscribe(async (result) => {
					let message: string;
					if (result?.action === "send") {
						message = result.message ?? "no-message-provided";
						const fName = `LT_FD_${user}_${gameString}_${datetimeString}`;
						const zipFile = await this.createArchive(fName, message + "\n\n" + info, log, png);
						if (environment.isDebug) {
							// see FileSaver.js
							// saveAs(zipFile, `${datetimeString}-trace.zip`);
							saveAs(zipFile, `${fName}-trace.zip`);
						}
						else {
							const res = await this.upload(fName, zipFile); // Lite version, Feedback Dialog
						}
					}
					else {
						return; // Close
					}
				});
			}
			else {
				console.log("BugReportCommand.execute: dialog was not displayed");
			}
			return true;
		}
		catch (e) {
			console.warn("BugReportCommand.execute: -- " + e);
		}
		return false;
	}
	
	private async createArchive(fileName: string, message: string, log: string, image: string) {
		const zip = new JSZip();
		zip.file(fileName + ".info", message);
		zip.file(fileName + ".txt", log);
		
		// const img = zip.folder("images");
		// img.file(..
		
		// In order to get JSZip to correctly save dataURL to valid png file need to cut starting string and add options object
		zip.file(`${fileName}.png`, image.substr("data:image/png;base64,".length), {base64: true});
		const content: Blob = await zip.generateAsync({
			type: "blob",
			compression: "DEFLATE",
			compressionOptions: {
				level: 9
			}
		});
		return content;
	}
	
	private async upload(fileName: string, file: Blob) {
		console.log("BugReportCommand.upload: " + fileName);
		const traceHandler = "Handlers/FlexTraceHandler.ashx";
		const url = environment.webRoot + "/" + traceHandler;
		const formData = new FormData();
		formData.append("file", file, fileName + ".zip");
		return fetch(url, {
			method: "POST",
			// headers: {
			// "Content-Type": file.type
			// "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM"
			// "Content-Type": "multipart/form-data"
			// },
			body: formData,
		});
	}
	
	private get appStore(): AppStore {
		return container.get<AppStore>(TYPES.AppStore);
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
}
