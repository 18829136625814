import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {GameService} from "../game/game.service";

@Component({
	selector: "app-engine",
	templateUrl: "./engine.component.html",
	providers: [GameService]
})
export class EngineComponent implements OnInit, OnDestroy {
	
	@ViewChild("rendererCanvas", {static: true})
	public rendererCanvas: ElementRef<HTMLCanvasElement>;
	
	public constructor(private engServ: GameService/*EngineService*/) {
	}
	
	public ngOnInit(): void {
		this.engServ.createScene(this.rendererCanvas);
		
		/*const tp = container.get<TexturePack>(TYPES.TexturePack);
		tp.loadTextTexture() // wait for textures to load and then proceed
			.then(() => {
				this.engServ.setGamePlayers();
				this.engServ.setRules(GameTypeTag.HK);
				this.engServ.setGameMessages();
			});*/
	}
	
	public ngOnDestroy(): void {
	
	}
	
}
