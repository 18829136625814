import {ICreateGameOpts} from "../../net/service/GameService";
import {OnlineGameDTO} from "../../net/dto/OnlineGameDTO";
import {IRulesOptions} from "../ecs/common/GameRulesAbstractSystem";
import {ICreateFormLimits} from "../../app/ui/lobby/createprivate/i-create-game";
import * as THREE from "three";
import {Vector3} from "three";
import {GameType} from "../enums/GameType";
import {RulesHelperHK} from "../ecs/hk/RulesHelperHK";
import {RulesHelperJM} from "../ecs/jm/RulesHelperJM";
import {RulesHelperTW} from "../ecs/tw/RulesHelperTW";
import {RulesHelperEC} from "../ecs/ec/RulesHelperEC";
import {RulesHelperCO} from "../ecs/co/RulesHelperCO";
import {RulesHelperWP } from "../ecs/wp/RulesHelperWP";
import {RulesHelperEMA} from "../ecs/jm/RulesHelperEMA";

export const getRulesHelper = (rules: GameType): IRulesHelper => {
	switch (rules) {
		case GameType.HK:
			return RulesHelperHK;
		case GameType.CO:
			return RulesHelperCO;
		case GameType.WP:
			return RulesHelperWP;
		case GameType.EC:
			return RulesHelperEC;
		case GameType.RCR:
			return RulesHelperJM;
		case GameType.EMA:
			return RulesHelperEMA;
		case GameType.TW:
			return RulesHelperTW;
	}
};

export interface IRulesHelper {
	helpTips: HelpTip[];
	defaultGameSettings: ICreateGameOpts;
	
	getOptions(tableSettings: OnlineGameDTO): IRulesOptions;
	
	getLocationTransforms(): LocationTransforms;
	
	getCreateLimits({rounds}: { rounds: number }): ICreateFormLimits;
	
}

export type LocationTransform = { quaternion: THREE.Quaternion, euler: Vector3 };
export type LocationTransforms = {
	[key: string]: LocationTransform
};
export type HelpTip = {
	icon: string,
	title: ResourceMsg,
	msgShortRK: ResourceMsg,
	msgFull?: ResourceMsg[],
};
export type ResourceMsg = { key: string, params?: object };
