import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: "app-leave-game-dialog",
	template: `
       <h1 mat-dialog-title>Confirmation</h1>
       <div mat-dialog-content>
           Are you sure you want to leave the game?
       </div>
       <div mat-dialog-actions>
           <button mat-raised-button mat-dialog-close="leave">Leave</button>
           <button mat-raised-button color="primary" mat-dialog-close cdk-focus-start>No, Stay in Game</button>
       </div>
	`
})
export class LeaveGameDialogComponent {
	
	constructor(
		public dialogRef: MatDialogRef<LeaveGameDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
	}
	
}
