import {Injectable, OnDestroy} from "@angular/core";
import {ReplaySubject, Subject} from "rxjs";
import container from "../../inversify/inversify.config";
import {TYPES} from "../../inversify/inversify.types";
import {filter, map, takeUntil, tap} from "rxjs/operators";
import {GameEventsPipe, GameEventType} from "../../game/GameEventsPipe";
import {IMoveNowUpdatedParams} from "../../game/interfaces/IMoveNowUpdatedParams";
import {IMoveTimerUpdatedParams} from "../../game/ecs/TimerSystem";
import {UserStore} from "../../store/UserStore";

@Injectable({providedIn: "root"})
export class GameEventsService implements OnDestroy {
	
	private eventsPipe: GameEventsPipe;
	private destroy$: Subject<boolean> = new Subject<boolean>();
	
	public moveTimer$: ReplaySubject<IMoveTimerUpdatedParams> = new ReplaySubject<IMoveTimerUpdatedParams>(1);
	public moveNow$: ReplaySubject<IMoveNowUpdatedParams> = new ReplaySubject<IMoveNowUpdatedParams>(1);
	
	constructor() {
		this.eventsPipe = container.get<GameEventsPipe>(TYPES.GameEventsPipe);
		
		this.eventsPipe.events.pipe(
			filter(value => value.type === GameEventType.MoveTimer_Updated),
			map(value => value.data),
			takeUntil(this.destroy$)
		).subscribe(this.moveTimer$);
		this.eventsPipe.events.pipe(
			filter(value => value.type === GameEventType.MoveNow_Updated),
			map(value => value.data),
			takeUntil(this.destroy$)
		).subscribe(this.moveNow$);
		
		this.eventsPipe.events.pipe(
			filter(value => value.type === GameEventType.Game_Ended),
			tap(() => {
				console.log("GameEventsService.Played-a-game: set flag");
				const userStore = container.get<UserStore>(TYPES.UserStore);
				userStore?.setSettings({playedAGame: true});
			}),
			takeUntil(this.destroy$)
		).subscribe();
	}
	
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
	
}

