export class PointsDescriptionDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.PtsDescr"
	
	public Concealed: string;
	/*<PntsDescr>
			<BfrTblCmns><Cmns><Id>10</Id><Pnt>1</Pnt><Descr>Season of Own Wind</Descr></Cmns></BfrTblCmns>
			<BfrTblTotal>2</BfrTblTotal>
			<TblPnts><ToEast>0</ToEast><ToSouth>2</ToSouth><ToWest>4</ToWest><ToNorth>2</ToNorth></TblPnts>
			<Additional></Additional>
			<Total>8</Total>
		</PntsDescr>*/
	public Descr: string;
	public Melded: string;
	public Side: string;
	public UserId: number;
	public IsWinner: boolean;
	public Place: number;
}
