import {Commands} from "../commands/Commands";
import {CurrencyType} from "../game/enums/CurrencyType";
import {MatDialog} from "@angular/material/dialog";

class Commander {
	
	commandsMap = new Map();
	
	constructor() {
	
	}
	
	registerCommand(commandName: Commands, commandClass: typeof ICommand): void {
		this.commandsMap.set(commandName, commandClass);
	}
	
	unregisterCommand(commandName: Commands): void {
		this.commandsMap.delete(commandName);
	}
	
	executeCommand<T>(commandName: Commands.NOT_ENOUGH, params: { dialogService: MatDialog, currency: CurrencyType, amount: number }): Promise<T>;
	executeCommand<T>(commandName: Commands): Promise<T>;
	executeCommand<T>(commandName: Commands, params): Promise<T>;
	executeCommand<T, U>(commandName: Commands, params?: U): Promise<T>;
	executeCommand<T, U>(commandName: Commands, params?: U): Promise<T> {
		const commandClass = this.commandsMap.get(commandName);
		if (commandClass) {
			const cmd = new commandClass();
			return cmd.execute(params);
		}
		return Promise.reject("no command exist: " + commandName);
	}
}

export const commander = new Commander();


export abstract class ICommand {
	constructor() {
	
	}
	
	// public abstract async execute<T>(params?: any): Promise<T>;
	public abstract async execute(params?: any): Promise<any>;
	
}
