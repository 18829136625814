import {IDisposable} from "./IDisposable";
import * as THREE from "three";
import {Object3D, Scene, Vector2, Vector3} from "three";
import {fromEvent, merge, Observable, of, race, Subject} from "rxjs";
import {
	buffer,
	debounceTime,
	filter,
	map,
	publish,
	refCount,
	skipWhile,
	switchMap,
	switchMapTo,
	take,
	takeUntil,
	throttleTime
} from "rxjs/operators";
import {IInputMouseEvent, InputMouseEventType} from "./interfaces/InputStreamEvent";

// type EvObj = [event: MouseEvent, object: Object3D];

export class PointerController implements IDisposable {
	
	private dispose$ = new Subject();
	public mouseStream$: Observable<IInputMouseEvent>;
	
	hoveredObject;
	private raycaster = new THREE.Raycaster();
	
	private scene: Scene;
	private camera;
	private canvas;
	
	constructor() {
	}
	
	public setup(scene: Scene, camera, canvas) {
		this.scene = scene;
		this.camera = camera;
		this.canvas = canvas;
		this.createStreams();
	}
	
	public dispose(): void {
		this.dispose$.next();
		this.dispose$.complete();
	}
	
	private createStreams(): void {
		const mouseDown$ = fromEvent<MouseEvent>(document, "mousedown").pipe(
			filter(event => event.button === 0), // filter only left mouse button
			takeUntil(this.dispose$),
			publish(), // never-completes stream, so use publish and not share
			refCount(),
		);
		const mouseUp$ = fromEvent<MouseEvent>(document, "mouseup").pipe(
			filter(event => event.button === 0), // filter only left mouse button
			takeUntil(this.dispose$),
			publish(),
			refCount(),
		);
		const mouseMove$ = fromEvent<MouseEvent>(document, "mousemove").pipe(
			filter(event => event.button === 0), // filter only left mouse button
			takeUntil(this.dispose$),
			publish(),
			refCount(),
		);
		
		/* Source observable for all other mouse observables (click, drag, hover).
		 * After mouseDown it switches to fastest of mouseUp (click flow) or mouseMove (drag flow).
		 * Add move threshold of 4px to avoid drag event after a really small mouse movement (even 1px move
		 *  between mouseDown and mouseUp can prevent 'click' event and starts 'drag' flow.
		 * */
		const clickSource$ = mouseDown$.pipe(
			switchMap(event => race(
				mouseUp$,
				mouseMove$.pipe(skipWhile(moveEvent => this.getDistance(moveEvent, event) < 4), take(1)),
			)),
		);
		
		/* Drag Flow
		* Do not start drag if there is no object3d under the mouse
		* */
		const dragStart$ = clickSource$.pipe(
			filter(event => event.type === "mousemove"),
			map<MouseEvent, [MouseEvent, Object3D]>(event => ([event, this.getOverObjectByMouse(event)])),
			filter(tuple => !!tuple[1]), // skip unnecessary drag events if no object3d present
			switchMap(tuple => of({
				type: InputMouseEventType.DRAG_START,
				mouseEvent: tuple[0],
				target: tuple[1],
			}))
		);
		
		const dragMove$ = dragStart$.pipe(
			switchMapTo(mouseMove$.pipe(
				throttleTime(50),
				map<MouseEvent, IInputMouseEvent>(event => ({
					type: InputMouseEventType.DRAG_MOVE,
					mouseEvent: event
				})),
				takeUntil(mouseUp$)
			)),
		);
		
		const dragStop$ = dragStart$.pipe(
			switchMapTo(mouseUp$.pipe(
				map<MouseEvent, IInputMouseEvent>(event => ({
					type: InputMouseEventType.DRAG_END,
					mouseEvent: event
				})),
				take(1)
			)),
		);
		
		/* Click Flow */
		const click$ = clickSource$.pipe(
			filter(event => event.type === "mouseup"),
		);
		
		const multiClick = click$.pipe(
			buffer(click$.pipe(debounceTime(200))),
			map<MouseEvent[], IInputMouseEvent>(arr => ({
				type: InputMouseEventType.CLICK,
				clickCount: arr.length,
				mouseEvent: arr[0],
				point: {x: arr[0].clientX, y: arr[0].clientY},
				target: this.getOverObjectByMouse(arr[0])
			})),
		);
		
		/* Hover Flow */
		const hover$ = mouseMove$.pipe(
			// debounceTime(40),
			map<MouseEvent, IInputMouseEvent>(event => {
				const over = this.getOverObjectByMouse(event);
				if (this.hoveredObject) { // a previous hovered object exists
					if (!over || this.hoveredObject !== over) { // mouse moved out or rolledOver another object
						// TODO: if Hovered != over -- would be better to throw 2 events: out & new over
						const hovObject = this.hoveredObject;
						this.hoveredObject = null;
						return {
							type: InputMouseEventType.ROLL_OUT,
							target: hovObject,
							mouseEvent: event,
							// point: {x: event.clientX, y: event.clientY}
						};
					}
					else { // move over already hovered object
						return {
							type: InputMouseEventType.MOVE_OVER,
							target: over,
							mouseEvent: event,
							point: {x: event.clientX, y: event.clientY}
						};
					}
				}
				else { // there is no previous hovered object
					if (over) { // mouse is over an object
						this.hoveredObject = over;
						return {
							type: InputMouseEventType.ROLL_OVER,
							target: over,
							mouseEvent: event,
							point: {x: event.clientX, y: event.clientY}
						};
					}
					else {
						return undefined;
					}
				}
			}),
			filter(event => event !== undefined),
			// publish(), // multicast (publish or share) should be used if hover$ will have more than 1 subscriber as it uses local variables
			// refCount(),
		);
		
		this.mouseStream$ = merge(multiClick, hover$, dragStart$, dragMove$, dragStop$).pipe(
			publish(),
			refCount(),
		);
		
		/*if (environment.isDebug) {
			this.mouseStream$.subscribe(
				event => console.info(` stream$ : ${InputMouseEventType[event?.type]}`, event),
				error => console.error,
				() => console.info(` stream$ -- COMPLETE`)
			);
		}*/
		
	}
	
	/*onWindowResize() {
		
		var aspect = window.innerWidth / window.innerHeight;
		
		camera.left = - frustumSize * aspect / 2;
		camera.right = frustumSize * aspect / 2;
		camera.top = frustumSize / 2;
		camera.bottom = - frustumSize / 2;
		
		camera.updateProjectionMatrix();
		
		renderer.setSize( window.innerWidth, window.innerHeight );
		
	}*/
	
	private get3DbyMouse(event: MouseEvent): Vector2 {
		return new Vector2(
			((event.clientX - this.canvas.offsetLeft) / this.canvas.clientWidth) * 2 - 1,
			-((event.clientY - this.canvas.offsetTop) / this.canvas.clientHeight) * 2 + 1);
	}
	
	private getOverObjectByMouse(event: MouseEvent): Object3D {
		// find intersections
		try {
			this.raycaster.setFromCamera(this.get3DbyMouse(event), this.camera);
			const intersects = this.raycaster.intersectObjects(this.scene.children, true);
			if (intersects.length === 0) {
				return null;
			}
			return intersects.find(i => {
				return i.object.visible;
			})?.object ?? null;
		}
		catch (e) {
			console.warn("PointerController.getOverObjectByMouse: -- " + e);
		}
	}
	
	public getIntersectionPoint(object: Object3D, event: MouseEvent): Vector3 {
		this.raycaster.setFromCamera(this.get3DbyMouse(event), this.camera);
		const intersects = this.raycaster.intersectObject(object, false);
		return intersects.length === 0 ? null : intersects[0].point;
	}
	
	/**
	 * Calculates distance between to mouse points
	 * @param mEvent1 -- MouseEvent
	 * @param mEvent2 -- MouseEvent
	 */
	private getDistance(mEvent1, mEvent2): number {
		return Math.sqrt(Math.pow(mEvent2.x - mEvent1.x, 2) + Math.pow(mEvent2.y - mEvent1.y, 2));
	}
}
