import {Component, Inject, OnInit} from "@angular/core";
import {GameService} from "../../../../../net/service/GameService";
import {GameStore} from "../../../../../store/GameStore";
import {UserStore} from "../../../../../store/UserStore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import container from "../../../../../inversify/inversify.config";
import {TYPES} from "../../../../../inversify/inversify.types";
import {DealSnapshotDTO} from "../../../../../net/dto/DealSnapshotDTO";
import {DealState} from "../../../../../game/enums/DealState";
import {OnlineGameDTO} from "../../../../../net/dto/OnlineGameDTO";
import {DealStage} from "../../../../../game/enums/DealStage";
import {GameStorageWrapper} from "../../../../../game/ecs/wrappers/GameStorageWrapper";
import {PlayersArray} from "../../../../../game/ecs/wrappers/PlayersArray";
import {Side} from "../../../../../game/enums/Side";
import {ICombination, PointsDescriptionModel} from "../../../../../net/dto/PointsDescriptionModel";

@Component({
	selector: "app-eng-game-dialog",
	template: `
       <h1 mat-dialog-title style="text-align: center;">{{dialogTitle|translate}}</h1>
       <h2 mat-dialog-title class="tableName">{{tableResults?.gameName}}</h2>
       <div mat-dialog-content *ngIf="loading">
           <list-content-loader></list-content-loader>

       </div>
       <div mat-dialog-content *ngIf="!loading" style="overflow: hidden;">
           <!--<span>Game Name: {{tableResults?.gameName}}</span>
           <div>
               <span>Deal Results:</span>
               <span>Place: {{tableResults?.dealResults.place}}, isWinner: {{tableResults?.dealResults.isWinner}}</span>
           </div>
           <div *ngIf="tableResults?.gameEnded">
               <span>Game Results:</span>
               <span>Place: {{tableResults?.gameResults.Place}}, isWinner: {{tableResults?.gameResults.IsWinner}}
                   , Points: {{tableResults?.gameResults.Points}}, Rating: {{tableResults?.gameResults.Rating}}</span>
           </div>-->

           <div style="display: flex; flex-direction: row; align-items: stretch; padding-top: 2rem; padding-bottom: .2rem;">
               <app-player-game-results *ngFor="let player of tableResults?.playerResults" [player]="player"></app-player-game-results>
           </div>

       </div>
       <div mat-dialog-actions style="justify-content: center;">
           <!--<button mat-raised-button color="primary" mat-dialog-close="playAgain" *ngIf="deal.hasPlayAgain">Play Again</button>
           <button mat-raised-button mat-dialog-close="exit">Exit</button>-->
           <button *ngIf="tableResults?.gameEnded" mat-raised-button mat-dialog-close="playAgain">Play Again</button>
           <button mat-raised-button mat-dialog-close="close">Close</button>
       </div>
	`,
	styleUrls: ["EndGameDialogComponent.scss"],
})
export class EndGameDialogComponent implements OnInit {
	private gameService: GameService;
	private gameStore: GameStore;
	private userStore: UserStore;
	
	public loading = false;
	public tableResults: TableResults;
	
	constructor(
		public dialogRef: MatDialogRef<EndGameDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public inData: IEndGameDialogData,
	) {
		this.gameService = container.get(TYPES.GameService);
		this.gameStore = container.get(TYPES.GameStore);
		this.userStore = container.get(TYPES.UserStore);
	}
	
	ngOnInit(): void {
		if (!this.gameStore.joinedGame) {
			console.warn("NoGameJoined!");
			return;
		}
		this.loading = true;
		this.gameService
			.dealSnapshot(this.userStore.sessionKey, this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId, this.inData.dealNum)
			.then(dealSnapshot => this.processDealSnapshot(dealSnapshot))
			.catch(err => this.dialogRef.close())
			.catch(err => console.warn("EndGameDialogComponent.: "))
			
			.finally(() => this.loading = false);
	}
	
	private processDealSnapshot(dealSnapshot: DealSnapshotDTO): void {
		console.log("Got: DealSnapshot: ", dealSnapshot);
		const dealSnapshotModel = new DealSnapshotModel(dealSnapshot);
		this.tableResults = new TableResults(dealSnapshotModel, this.inData.storage, this.inData.players, this.gameStore.joinedGame);
	}
	
	public get dialogTitle(): string {
		if (!this.tableResults) {
			return "";
		}
		if (this.tableResults.gameEnded) {
			// return "Game Ended: " + this.tableResults.gameName;
			return "Game.Dialog.EndGame.GameIsFinished";
		}
		if (this.tableResults.dealEnded) {
			return "Game.Dialog.EndGame.DealIsFinished";
		}
		// return "Deal is in progress!";
		return "Game.Dialog.EndGame.DealInProgress";
	}
}

export interface IEndGameDialogData {
	dealNum: number;
	storage: GameStorageWrapper;
	players: PlayersArray;
}

class DealSnapshotModel implements IDealSnapshotModel {
	private _pointsDescription: PointsDescriptionModel[];
	
	constructor(public readonly dto: DealSnapshotDTO) {
	
	}
	
	public get stateId() {
		return this.dto.StateId;
	}
	
	public get slotTileId() {
		return this.dto.Slot;
	}
	
	public get roundWind(): Side {
		return this.dto.RoundWind as Side;
	}
	
	public get coffeeBreak() {
		return this.dto.CoffeeBreak;
	}
	
	public get pointsDescription() {
		if (!this._pointsDescription) {
			this._pointsDescription = this.dto.PtsDescr.map(pd => new PointsDescriptionModel(pd));
		}
		return this._pointsDescription;
	}
	
	public get totalPoints() {
		return this.dto.TotalPoints;
	}
}

interface IDealSnapshotModel {
	stateId: DealState;
	slotTileId: number;
	roundWind: Side;
	coffeeBreak: boolean;
	pointsDescription: PointsDescriptionModel[];
}

export interface IPlayerResults {
	id: number;
	name: string;
	wind: Side;
	combinations: ICombination[];
	// pointsChange: number;
	dealPoints: number;
	totalPoints: number;
}

export class TableResults {
	
	private _playerResults: IPlayerResults[];
	
	constructor(
		private dealSnapshot: DealSnapshotModel, private gameStorage: GameStorageWrapper,
		private players: PlayersArray, private gameSettings: OnlineGameDTO
	) {
	
	}
	
	public get gameName() {
		return this.gameSettings.Name;
	}
	
	public get playerResults() {
		if (!this._playerResults) {
			this._playerResults = this.players.map<IPlayerResults>(player => {
					const pd = this.dealSnapshot.pointsDescription.find(pl => pl.userId === player.id);
					const tp = this.dealSnapshot.totalPoints.find(pl => pl.UserId === player.id);
					return {
						id: player.id,
						name: player.name,
						wind: player.realside,
						dealPoints: pd.pointsInfo.total,
						totalPoints: tp.Points,
						combinations: pd.pointsInfo.beforeTable.concat(pd.pointsInfo.additional, pd.pointsInfo.descriptive),
					};
				}
			);
		}
		return this._playerResults;
	}
	
	public get dealEnded(): boolean {
		switch (this.dealSnapshot.stateId) {
			case DealState.MAHJONG:
			case DealState.MAHJONG_SELF:
			case DealState.DEAD_WALL:
				return true;
			default:
				return false;
		}
	}
	
	public get gameEnded(): boolean {
		return this.gameStorage.dealState.stage === DealStage.GAME_ENDED;
	}
	
	public get myDealResults() {
		return this.dealSnapshot.pointsDescription.find(dto => dto.userId === this.gameStorage.viewPlayerId);
	}
	
	public get myGameResults() {
		return this.dealSnapshot.totalPoints.find(dto => dto.UserId === this.gameStorage.viewPlayerId);
	}
	
	get hasPlayAgain(): boolean {
		return this.dealSnapshot.stateId > DealState.PLAYING
			&& this.gameSettings.TourId === 0
			&& this.gameSettings.PracticeLevel === 0;
	}
	
}
