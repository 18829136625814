import {LoginAppType} from "../../game/enums/LoginAppType";
import {LoginDTO} from "../dto/LoginDTO";
import {ILoginService} from "./ILoginService";
import {LoginService} from "./LoginService";


export class FakeLoginService extends LoginService implements ILoginService {
	
	public async login(name: string, password: string, appType: LoginAppType): Promise<LoginDTO> {
		const dto: LoginDTO = new LoginDTO();
		dto.UserId = 888;
		dto.SessionKey = "-= Fake Session Key =-";
		return dto;
	}
	
	/*public getInfo(sessionKey: string): Promise<ServiceResult<UserInfoStoreDTO>> {
		return new Promise<ServiceResult<UserInfoStoreDTO>>((resolve, reject) => {
			const dto: UserInfoStoreDTO = new UserInfoStoreDTO();
			// dto.UserId = 888;
			// dto.SessionKey = "-= Fake Session Key =-";
			resolve(new ServiceResult(dto));
		});
	}*/
}
