export function connect(method1: string, endpoint: string, buffer: ArrayBufferLike) {
	
	return new Promise<ArrayBufferLike>((resolve, reject) => {
		const oReq = new XMLHttpRequest();
		oReq.onload = () => resolve(oReq.response);
		oReq.onerror = () => reject("Error: " + oReq.statusText + ": " + oReq.response);
		oReq.onabort = () => reject("Aborted:" + oReq.statusText);
		oReq.open(method1, endpoint, true);
		// oReq.setRequestHeader("Content-Type", "application/x-amf; charset=UTF-8");
		oReq.setRequestHeader("Content-Type", "application/x-amf");
		// oReq.setRequestHeader("Content-Type", "multipart/form-data");
		// oReq.setRequestHeader("X-Requested-With", "XMLHttpRequest");
		//  oReq.setRequestHeader("Accept",
		//  	"text/xml, application/xml, application/xhtml+xml, text/html;q=0.9, text/plain;q=0.8, text/css, image/png, image/jpeg, image/gif;q=0.8, application/x-shockwave-flash, video/mp4;q=0.9, flv-application/octet-stream;q=0.8, video/x-flv;q=0.7, audio/mp4, application/futuresplash, *!/!*;q=0.5, application/x-mpegURL");
		// oReq.setRequestHeader("x-flash-version", "31,0,0,122");
		// oReq.overrideMimeType("application/x-amf; charset=UTF-8");
		// oReq.overrideMimeType("text/plain; charset=x-user-defined");
		// oReq.overrideMimeType("application/x-amf; charset=iso-8859-1");
		// oReq.responseType = "arraybuffer";
		oReq.responseType = "arraybuffer";
		oReq.send(buffer);
	});
}

/*

login_xml() {
	// const serResult = this.generatePacket("Mahjong.GameServer.Web.FluorineService.Authentication.Login", ["xxx", "xxx", 2]);
	const serResult = this.generatePacket("Mahjong.GameServer.Web.FluorineService.Authentication.Login", ["xx", "xx", 2]);
	// const serResult = this.generatePacket("echo", "aaaa");
	// const bb = require("buffer/").Buffer;
	//const w = window;
	//const g = global;
	const bb = Buffer;
	let buff = bb.from(serResult);
	const abuff = new Uint8Array(serResult).buffer;
	
	
	/!*let sHex = buff.toString("hex");
	console.log("sHex=", sHex);
	console.log("sHex1=", sHex);
	sHex += "00";
	console.log("sHex2=", sHex);
	buff = Buffer.from(sHex, "hex");
	console.log("buff=", buff);*!/
	
	const uint8 = new Uint8Array(buff);
	
	// POST /Gateway.aspx HTTP/1.1
	// Accept: text/xml, application/xml, application/xhtml+xml, text/html;q=0.9, text/plain;q=0.8, text/css, image/png, image/jpeg, image/gif;q=0.8, application/x-shockwave-flash, video/mp4;q=0.9, flv-application/octet-stream;q=0.8, video/x-flv;q=0.7, audio/mp4, application/futuresplash, *!/!*;q=0.5, application/x-mpegURL
	// x-flash-version: 31,0,0,122
	// Content-type: application/x-amf
	// User-Agent: Shockwave Flash
	// Host: game.mahjongtime.com
	// Content-Length: 244
	// Pragma: no-cache
	// Cookie: __utmz=174554046.1588278849.1.1.utmccn=(direct)|utmcsr=(direct)|utmcmd=(none); regInfo=referrer=&ip=1xx.1x.5x.1xx; __utma=174554046.90052910.1588278849.1588278849.1588278849.1; mt_lang=eng
	
	
	const oReq = new XMLHttpRequest();
	oReq.onload = reqListener;
	oReq.onerror = reqError;
	
	let ep = this.endpoint;
	ep = "https://cors-anywhere.herokuapp.com/game.mahjongtime.com/Gateway.aspx";
	
	oReq.open("post", ep, true);
	// oReq.setRequestHeader("Content-Type", "application/x-amf; charset=UTF-8");
	oReq.setRequestHeader("Content-Type", "application/x-amf");
	// oReq.setRequestHeader("Content-Type", "multipart/form-data");
	// oReq.setRequestHeader("X-Requested-With", "XMLHttpRequest");
	// oReq.setRequestHeader("Accept", "text/xml, application/xml, application/xhtml+xml, text/html;q=0.9, text/plain;q=0.8, text/css, image/png, image/jpeg, image/gif;q=0.8, application/x-shockwave-flash, video/mp4;q=0.9, flv-application/octet-stream;q=0.8, video/x-flv;q=0.7, audio/mp4, application/futuresplash, *!/!*;q=0.5, application/x-mpegURL");
	// oReq.setRequestHeader("x-flash-version", "31,0,0,122");
	// oReq.overrideMimeType("application/x-amf; charset=UTF-8");
	// oReq.overrideMimeType("text/plain; charset=x-user-defined");
	// oReq.overrideMimeType("application/x-amf; charset=iso-8859-1");
	// oReq.responseType = "arraybuffer";
	oReq.responseType = "arraybuffer";
	// oReq.send(new Uint8Array(buff));
	console.log("SENDING..");
	
	oReq.send(abuff); //99
	
	function reqListener() {
		console.log("GOT RESULT");
		const data = oReq.response;
		console.log(data);
		const uint8arr = new Uint8Array(oReq.response);
		console.log(uint8arr);
		const abuf = uint8arr.buffer;
		

		try {
			let message, body, deserializer = new amf.Deserializer(new Uint8Array(oReq.response));
			message = deserializer.readMessage();
			console.log(message);
		} catch (e) {
			console.warn("error parsing message");
		}
		
		// if (xhr.obj.status >= 200 && xhr.obj.status <= 300
		// 	&& xhr.obj.responseType == "arraybuffer"
		console.log("d=", oReq.getResponseHeader("Content-type").indexOf("application/x-amf") > -1);
	};
	
	function reqError(err) {
		console.log("Fetch Error :-S", err);
	}
	
}*/
