import {Pipe, PipeTransform} from "@angular/core";
import {GameState} from "../../game/enums/GameState";

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: "gameState"})
export class GameStatePipe implements PipeTransform {
	transform(value: GameState): string {
		return GameState[value];
		
	}
}

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: "gameState111"})
export class GameStatePipe111 implements PipeTransform {
	transform(value: number, exponent?: number): number {
		return Math.pow(value, isNaN(exponent) ? 1 : exponent);
	}
}