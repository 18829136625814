import {GameMessageDTO} from "./GameMessageDTO";
import {ChatMessageDTO} from "./ChatMessageDTO";
import {PrivateChatMessageDTO} from "./PrivateChatMessageDTO";

export class GameMessagesDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.GameMessages"
	
	public Game: Array<GameMessageDTO>;
	public PublicChat: Array<ChatMessageDTO>;
	public PrivateChat: Array<PrivateChatMessageDTO>;
	
}
