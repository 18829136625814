import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../../../../services/navigation.service";
import {QuickSeatService} from "../../../../services/QuickSeatService";
import {commander} from "../../../../../commander/Commander";
import {Commands} from "../../../../../commands/Commands";
import {NavPage} from "../../../../enums/NavPage";
import {GameType, GameTypeHelper} from "../../../../../game/enums/GameType";
import {GameURL} from "../../../../../utils/GameURL";

@Component({
	selector: "app-join-game-id",
	templateUrl: "./join-game-id.component.html",
	styleUrls: ["./join-game-id.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinGameIdComponent implements OnInit, OnDestroy {
	public joinStatus = "";
	
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private navigationService: NavigationService,
		private qsService: QuickSeatService
	) {
		// 🀄️ inject GameEventsService to start handling events like adding/ updating players info
		console.log("JoinGameIdComponent.constructor: ");
		this.route.snapshot.queryParamMap.get("gameId");
	}
	
	ngOnInit(): void {
		try {
			const gameOpts = this.route.snapshot.paramMap.get("gameId");
			if (!gameOpts) {
				throw new Error("no game opts provided");
			}
			const gameParams = GameURL.decode(gameOpts);
			if (!gameParams) {
				throw new Error("cannot read game params");
			}
			if (!GameTypeHelper.isValidId(gameParams.gameTypeId)) {
				throw new Error("not a valid game type id");
			}
			
			this.joinGame(gameParams.gameId, gameParams.roomId)
				.then(value => {
					if (!value) {
						throw new Error("Was not able to join");
					}
					return value;
				})
				.catch(reason => {
					console.warn("JoinGameIdComponent.: cannot join game -- " + reason);
					this.navigationService.navigate({page: NavPage.Games, gameType: gameParams.gameTypeId});
				});
		}
		catch (e) {
			console.error("JoinGameIdComponent.ngOnInit: " + e);
			this.navigationService.navigate({page: NavPage.Wizard});
		}
	}
	
	ngOnDestroy(): void {
		try {
		}
		catch (e) {
		}
	}
	
	
	private async joinGame(gameId: number, roomId: number): Promise<boolean> {
		try {
			this.joinStatus = "Joining..";
			// const gameParams = {gameId: game.GameId, roomId: game.RoomId};
			const gameParams = {gameId, roomId};
			this.joinStatus = "Joining.. " + gameId;
			// TODO: join game and then proceed to table
			await commander.executeCommand(Commands.JOIN_GAME, gameParams);
			this.joinStatus = "Joined: OK";
			await this.navigationService.navigate({page: NavPage.Table, gameType: GameType.WP}); // it was HK here, changed to WP
			return true;
		}
		catch (e) {
			console.error("JoinGameIdComponent.joinGame: " + e);
			this.joinStatus = "Joining error: " + e;
			return false;
		}
	}
	
}
