import {Injectable, NgZone, OnDestroy} from "@angular/core";
import {AppEvent, AppEventsPipe, AppEventType} from "../../game/AppEventsPipe";
import {Observable, Subject} from "rxjs";
import container from "../../inversify/inversify.config";
import {TYPES} from "../../inversify/inversify.types";
import {filter, map, takeUntil} from "rxjs/operators";
import {commander} from "../../commander/Commander";
import {Commands} from "../../commands/Commands";
import {IServiceError, ServiceErrors} from "../../net/ServiceError";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Locale} from "../../game/enums/Locale";
import {NavigationService} from "./navigation.service";
import {NavPage} from "../enums/NavPage";
import {Errors} from "../../game/enums/Errors";
import {UserService} from "../../net/service/UserService";
import {UserStore} from "../../store/UserStore";
import {MatDialog} from "@angular/material/dialog";
import {CurrencyType} from "../../game/enums/CurrencyType";
import {DialogService} from "./DialogService";

@Injectable({providedIn: "root"})
export class AppEventsService implements OnDestroy {
	
	private appEventsPipe: AppEventsPipe;
	private destroy$: Subject<boolean> = new Subject<boolean>();
	
	tooltip$: Observable<{ message: string; point: { x: number, y: number } }>;
	tooltipPos$: Observable<{ point: { x: number, y: number } }>;
	
	constructor(
		private router: Router,
		private snackBar: MatSnackBar,
		private zone: NgZone,
		private translate: TranslateService,
		private navigationService: NavigationService,
		public dialog: MatDialog,
		public dialogService: DialogService
	) {
		this.appEventsPipe = container.get<AppEventsPipe>(TYPES.AppEventsPipe);
		this.appEventsPipe.events
			.pipe(takeUntil(this.destroy$))
			.subscribe((event) => this.onAppEvent(event));
		this.tooltip$ = this.appEventsPipe.events
			.pipe(
				filter(event => event.type === AppEventType.ShowTooltip || event.type === AppEventType.HideTooltip),
				map(event => event.data),
				takeUntil(this.destroy$)
			);
		this.tooltipPos$ = this.appEventsPipe.events
			.pipe(
				filter(event => event.type === AppEventType.MoveTooltip),
				map(event => event.data),
				takeUntil(this.destroy$)
			);
	}
	
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
	
	public send(type: AppEventType.ShowSnack, data?: { rsKey: string, fallbackMessage?: string, duration?: number }): void;
	public send(type: AppEventType.ShowSnack, data?: { message: string, duration?: number }): void;
	public send(type: AppEventType.Display_Not_Enough, data: { currency: CurrencyType, amount: number }): void;
	public send(type: AppEventType, data?: unknown);
	public send(type: AppEventType, data?: unknown) {
		this.appEventsPipe.send(type, data);
	}
	
	private onAppEvent(event: AppEvent): void {
		try {
			switch (event.type) {
				case AppEventType.NavigateTo:
					this.navigationService.navigate({page: event.data.route as NavPage});
					break;
				case AppEventType.ShowSnack:
					this.openSnackBar(event.data);
					break;
				case AppEventType.ShowMessage:
					this.showMessage(event.data);
					break;
				case AppEventType.ServiceError:
					this.onServiceError(event.data);
					break;
				case AppEventType.Logout:
					commander.executeCommand(Commands.LOGUOT);
					break;
				case AppEventType.SwitchLanguage:
					const language = event.data?.language as Locale;
					commander.executeCommand(Commands.SWITCH_LANGUAGE, {translateService: this.translate, language});
					break;
				case AppEventType.SwitchFullscreen:
					const htmlElem = document.body;
					if (!document.fullscreenElement) {
						htmlElem.requestFullscreen().catch(err => {
							console.warn(`AppEventsService.SwitchFullScreen: Error enabling full-screen mode: ${err.message} (${err.name})`);
						});
					}
					else {
						document.exitFullscreen().catch(err => {
							console.warn("AppEventsService.SwitchFullScreen: Error exiting fullscreen mode: " + err?.message);
						});
					}
					break;
				case AppEventType.BugReport_Show:
					commander.executeCommand(Commands.BUG_REPORT, {displayDialog: true, dialogSrv: this.dialog});
					break;
				case AppEventType.Settings_Show:
					commander.executeCommand(Commands.SETTINGS, {displayDialog: true, dialogSrv: this.dialog});
					break;
				
				case AppEventType.Display_Not_Enough:
					commander.executeCommand(Commands.NOT_ENOUGH, {...event.data, dialogService: this.dialog})
						.catch();
					break;
			}
		}
		catch (e) {
			console.error("AppEventsService.onAppEvent: " + e);
		}
	}
	
	private openSnackBar(data: { message: string, duration?: number }, action?: string);
	private openSnackBar(data: { rsKey: string, fallbackMessage?: string, duration?: number }, action?: string);
	private openSnackBar(data: { message: string, rsKey: string, fallbackMessage?: string, duration?: number }, action?: string) {
		console.log("AppEventsService.openSnackBar: " + data.message);
		// use zone to open snack for correct positioning
		// see more: https://github.com/angular/components/issues/9875#issuecomment-375545786
		// this.zone.run(() => {
		
		this.snackBar.open(data.rsKey ? this.translate.instant(data.rsKey) ?? data.fallbackMessage ?? "Message not defined" : data.message,
			action,
			{
				duration: data?.duration ?? 2000,
				// verticalPosition: "bottom",
				// horizontalPosition: "center"
			});
	}
	
	private showMessage(data: { message: string });
	private showMessage(data: { rsKey: string, fallbackMessage?: string });
	private showMessage(data: { message: string, rsKey: string, fallbackMessage?: string }) {
		console.log("AppEventsService.showMessage: " + data.message);
		// use zone to open snack for correct positioning
		// see more: https://github.com/angular/components/issues/9875#issuecomment-375545786
		// this.zone.run(() => {
		
		const msg = data.rsKey ? this.translate.instant(data.rsKey) ?? data.fallbackMessage ?? "Message not defined" : data.message;
		this.dialogService.displayMessage(msg);
	}
	
	private onServiceError(error: IServiceError) {
		console.warn(`AppEventsService.onServiceError  • id: ${error.id}  • message: ${error.message}  • at: ${error.target.uri}`);
		switch (error.id) {
			case ServiceErrors.CONNECTION:
				break;
			case ServiceErrors.ERROR_PROCESSING:
				break;
			case ServiceErrors.UNKNOWN_ERROR:
				break;
			// -----------------------------------------
			case Errors.SESSION_EXPIRED:
				this.showError(error.id);
				this.userStore.logout();
				commander.executeCommand(Commands.LOGUOT).catch();
				break;
			case Errors.TIME_FOR_THE_GAME_HAS_RUN_OUT:
			case Errors.ACCESS_TO_GAME_DENIED:
				commander.executeCommand(Commands.EXIT_GAME).catch();
				break;
			// -----------------------------------------
			case 1101:
				this.showError(error.id);
				break;
			case Errors.NOT_ENOUGH_MONEY:
				// commander.executeCommand(Commands.NOT_ENOUGH, {currency: CurrencyType.Usd, amount: 100}).catch();
				break;
			// -----------------------------------------
			default:
				this.showError(error.id);
		}
	}
	
	private showError(errorId: number) {
		try {
			this.openSnackBar({rsKey: "Error." + errorId, duration: 4000});
		}
		catch (e) {
			console.warn("AppEventsService.showError: " + errorId + " -- " + e);
		}
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get userService(): UserService {
		return container.get<UserService>(TYPES.UserService);
	}
}
