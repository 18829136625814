import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GameStore } from "../../store/GameStore";
import { UserStore } from "../../store/UserStore";
import container from "../../inversify/inversify.config";
import { TYPES } from "../../inversify/inversify.types";
import { EMPTY, from, of, ReplaySubject, Subject } from "rxjs";
import { GameType, GameTypeHelper } from "../../game/enums/GameType";
import { catchError, delay, filter, map, repeat, switchMap, takeUntil, tap } from "rxjs/operators";
import { GameListService } from "../../net/service/GameListService";
import { GameMode } from "../../game/enums/GameMode";
import { OnlineGameDTO } from "../../net/dto/OnlineGameDTO";
import { GameState, GameStateHelper } from "../../game/enums/GameState";
import { commander } from "../../commander/Commander";
import { Commands } from "../../commands/Commands";
import { NavPage } from "../enums/NavPage";
import { NavigationService } from "./navigation.service";
import { environment } from "../../environments/environment";
import { BeltType } from "../../game/enums/BeltType";
import { OnlineGameHelper } from "../../net/helpers/OnlineGameHelper";
import { GameService } from "../../net/service/GameService";
import { CurrencyType } from "../../game/enums/CurrencyType";
import { AppEventsPipe, AppEventType } from "../../game/AppEventsPipe";


export enum QuickSeatActivityMessageType {
	ACTION,
	GOT_GAMES,
	FILTERED_GAMES,
	BEST_GAME,
	WAIT
}

export interface IQuickSeatActivityMessage {
	id: QuickSeatActivityMessageType;
	data: any;
}

@Injectable({ providedIn: "root" })
export class QuickSeatService implements OnDestroy {
	private static REQUEST_INTERVAL_MS = environment.isDebug ? 6000 : 20000;
	private gameListService: GameListService;
	private gameStore: GameStore;
	private userStore: UserStore;

	private searchGameType: GameType;
	private starter$ = new Subject<string>();
	private destroy$: Subject<boolean> = new Subject<boolean>();

	// true if there is active search in progress
	public isActive = false;
	// stream only not null game matching all requirements
	public stream$: ReplaySubject<OnlineGameDTO> = new ReplaySubject<OnlineGameDTO>(1);
	/**
	 * Will notify about search progress and steps including the game found
	 */
	public activityStream$: ReplaySubject<IQuickSeatActivityMessage> = new ReplaySubject<IQuickSeatActivityMessage>(1);

	constructor(
		public dialog: MatDialog,
		private navigationService: NavigationService
	) {
		this.gameListService = container.get(TYPES.GameListService);
		this.gameStore = container.get(TYPES.GameStore);
		this.userStore = container.get(TYPES.UserStore);

		/**
		 *  Post to activity stream
		 */
		const postActivity = (type: QuickSeatActivityMessageType, data?: any) => {
			this.activityStream$.next({ id: type, data });
		};
		/**
		 * filter game by its settings:
		 * Exclude:
		 *  - currently joined game
		 *  - already started games
		 *  - cash games
		 *  - tournament games
		 *  - high entry fee (1000+)
		 *  - practice games (name contains '*Practice*')
		 *  - AS games (returned in AM list)
		 *  - SM games (returned in JM list)
		 *  - CO: not "Berlin" games (#LT-87)
		 * Include:
		 *  - HK, AM: QuickMahjong games only
		 *  - CO, RCR, EMA, TW: 1-Round games only
		 */
		const gameFilter = (game: OnlineGameDTO): boolean => {
			if (!game) {
				return;
			}
			const userBelt: BeltType = this.userStore.info.Rating.Ratings.find(belt => belt.Type === game.GameTypeId)?.Belt ?? 0;
			if (game.StateId >= GameState.PLAYING || game.IsMoney || game.TourId !== 0 // skip playing, money, tournament games
				|| game.EntryFee > 1000 // skip high entry fee
				|| this.gameStore.joinedGame?.GameId === game.GameId // exclude joined game if its not null
				|| game.Name.includes("Practice") // skip '*Practice*' games
				|| game.GameTypeId === GameType.AS || game.GameTypeId === GameType.SM
				|| game.MinBelt > userBelt
				|| (game.GameTypeId === GameType.CO && game.Name !== "Berlin") // #LT-87
			) {
				// console.log("QuickSeatService.gameFilter: doesnt fit: ", game);
				return false;
			}
			console.log("QuickSeatService.gameFilter: joinedGame.playersToStart=" + OnlineGameHelper.playersToStart(this.gameStore.joinedGame));
			switch (game.GameTypeId) {
				case GameType.HK:
				case GameType.WP:
					return game.RoundsCount === 0; // we cannot count on joinedGame as its settings wont update after player joined and waiting for start
					break;
				case GameType.CO:
				case GameType.RCR:
				case GameType.EMA:
				case GameType.TW:
					return game.RoundsCount === 1; // we cannot count on joinedGame as its settings wont update after player joined and waiting for start
					break;
			}
			return true;
		};
		/**
		 * Search for a game require less player possible with lowest entry fee possible
		 */
		const gameSelector = (games: OnlineGameDTO[]): OnlineGameDTO => {
			return games
				// Map games to {playersToStart, game}
				?.map(game => ({ playersToStart: OnlineGameHelper.playersToStart(game), game }))
				// sort by playerToStart lower first, entry fee lower first
				.sort((a, b) => {
					if (a.playersToStart === b.playersToStart) {
						return a.game.EntryFee - b.game.EntryFee;
					}
					else {
						return a.playersToStart - b.playersToStart;
					}
				})[0]?.game ?? null;
		};

		/**
		 * Gets games list of given type (rules), filter them and notify required streams. Pause. Repeat.
		 */
		const gameRequest = (gt: GameType) => {
			return of(1)
				.pipe(
					// tap(games => console.info("~~~~~~~~~~~~~~~~~~~ ", games)),
					tap(() => console.log("QuickSeatService.gameRequest: start " + gt)),
					switchMap(() => from(this.gameListService.getGames(this.userStore.sessionKey, GameMode.Fun, gt, 0))),
					catchError(e => {
						console.warn("QuickSeatService.gameRequest: error -- " + e);
						return of([] as OnlineGameDTO[]);
					}),
					// tap(games => console.info("got list " + games?.length + ":", games)),
					tap(games => postActivity(QuickSeatActivityMessageType.GOT_GAMES, games)),
					map(games => games.filter(gv => gameFilter(gv))), // filter games by settings and players
					// tap(games => console.info("fit by settings " + games?.length + ":", games)),
					tap(games => postActivity(QuickSeatActivityMessageType.FILTERED_GAMES, games)),
					map(gamesP2S => gameSelector(gamesP2S)),
					tap(game => postActivity(QuickSeatActivityMessageType.BEST_GAME, game)),
					tap(game => {
						if (game) {
							console.log("QuickSeatService. has a game: ", game);
							this.stream$.next(game);
						}
						else {
							console.log("QuickSeatService. no game: ", game);
						}
					}),
					tap(() => postActivity(QuickSeatActivityMessageType.WAIT, 6000)),
					delay(QuickSeatService.REQUEST_INTERVAL_MS),
					// tap(game => console.info("after delay " + game)),
					repeat(Infinity),
					takeUntil(this.destroy$)
				);
		};

		// trigger to start/stop searching a game
		this.starter$.pipe(
			// tap(msg => console.info("starter$: " + msg)),
			tap(msg => this.isActive = msg === "start"),
			tap(msg => postActivity(QuickSeatActivityMessageType.ACTION, msg)),
			switchMap(msg => msg === "start" ? gameRequest(this.searchGameType) : EMPTY),
			takeUntil(this.destroy$)
		).subscribe();
		/*.subscribe(
				(next) => console.info("starter$: SUBSCR: ", next),
				(error) => console.info("starter$: error", error),
				() => console.info("starter$: complete")
			);*/
		/*this.activityStream$.pipe(takeUntil(this.destroy$))
		.subscribe(
			(next) => console.info("activityStream$: MSG: ", next),
			(error) => console.info("activityStream$: ERROR", error),
			() => console.info("activityStream$: COMPLETE")
		);*/
		this.activityStream$.pipe(filter(msg => msg.id === QuickSeatActivityMessageType.BEST_GAME && msg.data))
			.subscribe(({ data: game }: { id, data: OnlineGameDTO }) => {
				// console.info(`### joined game = ${this.gameStore.joinedGame}, gameUsers.players.length = ${this.gameStore.gameUsers.players.length}`);
				// console.info(`### game = `, game);

				// join another game if my game is finished (play again used)
				if (!this.gameStore.joinedGame || GameStateHelper.isFinished(this.gameStore.joinedGame.StateId) || this.gameStore.gameUsers.players.length < OnlineGameHelper.playersCount(game)) {
					(async () => {
						try {
							console.log("QuickSeatService.joining..: " + game.GameTypeId + " " + game.GameId);
							await commander.executeCommand(Commands.EXIT_GAME);
							await commander.executeCommand(Commands.JOIN_GAME, { gameId: game.GameId, roomId: game.RoomId });
							await this.navigationService.navigate({ page: NavPage.Table });
						}
						catch (e) {
							console.warn("QuickSeatService.: " + e);
						}
					})();
				}
				else {
					console.log("Not allowed to join a game . " + (this.gameStore.joinedGame ? ", jg.State=" + this.gameStore.joinedGame.StateId + ", plCount=" + this.gameStore.gameUsers.players.length + ", g.plCount=" + OnlineGameHelper.playersCount(game) : "no game joined"));
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	/**
	 * Start searching a game. If search is already running, previous search will be discarded and a new one started.
	 * You can check isActive property to check whenever search is running
	 * @param gameType -- game rules to search
	 */
	startSearch(gameType: GameType) {
		console.log("QuickSeatService.startSearch: " + gameType);
		this.searchGameType = gameType;
		this.starter$.next("start");
	}

	/**
	 * Stops active search if is running (and discards current request)
	 */
	stopSearch() {
		this.starter$.next("stop");
	}

	/**
	 * Search for a practice game and join it.
	 * Note: HK and AM only!
	 * @param gameType
	 */
	practice(gameType: GameType) {
		console.log("QuickSeatService .practice: " + gameType);
		(async () => {
			try {
				let games: OnlineGameDTO[] = [];

				games = await this.gameListService.getGames(this.userStore.sessionKey, GameMode.School, gameType, 0);

				console.log("Games:", games);
				// exclude AS games from the list
				games = games.filter(game => game.GameTypeId === gameType);
				// Print each game's details after filtering
				games.forEach((game, index) => {
					console.log(`QuickSeatService Filtered game ${index}: StateId=${game.StateId}, GameTypeId=${game.GameTypeId}, isPractice=${OnlineGameHelper.isPractice(game)}`);
				});
				console.log("QuickSeatService Filtered games:", games);

				const practiceGame = games.filter(game => game.StateId < GameState.PLAYING)
					.find(game => OnlineGameHelper.isPractice(game));

				console.log("QuickSeatService Practice game:", practiceGame);

				if (practiceGame) {
					try {
						await commander.executeCommand(Commands.EXIT_GAME);
						await commander.executeCommand(Commands.JOIN_GAME, { gameId: practiceGame.GameId, roomId: practiceGame.RoomId });
						await this.navigationService.navigate({ page: NavPage.Table });
					}
					catch (e) {
						console.warn("QuickSeatService: " + e);
					}
				}
				else {
					console.log(`QuickSeatService QuickSeatService.joinQuickGame: no practice game available for ${gameType}`);
				}
			}
			catch (e) {
				console.warn("QuickSeatService QuickSeatService.joinQuickGame: " + e);
			}
		})();
	}

	async playAgain() {
		try {
			const game: OnlineGameDTO = this.gameStore.joinedGame;
			if (!game) {
				console.warn("QuickSeatService.playAgain: no joinedGameDetected. Exit.");
				return;
			}

			console.log(`QuickSeatService.playAgain: type=${game.GameTypeId}, name=${game.Name}, money=${game.IsMoney}`);
			// in case user player a PrivateGame, we'll recreate same game using PlayAgain method
			// so the others will be able to join same game
			if (OnlineGameHelper.isPractice(game)) {
				this.practice(game.GameTypeId);
			}
			else if (OnlineGameHelper.isPrivate(game) || game.IsMoney) {
				const gs: GameService = container.get<GameService>(TYPES.GameService);
				const g = await gs.playAgain(this.userStore.sessionKey, game.RoomId, game.GameId);
				console.log("QuickSeatService.playAgain: got a game:", g);
				if (this.gameStore.joinedGame) {
					// await commander.executeCommand(Commands.EXIT_GAME);
					await commander.executeCommand(Commands.RELEASE_GAME);
				}
				await commander.executeCommand(Commands.JOIN_GAME, { gameId: g.GameId, roomId: g.RoomId });
				await this.navigationService.navigate({ page: NavPage.Table });
			}
			// otherwise run quick search for the next game
			else {
				if (this.gameStore.joinedGame) {
					console.log("QuickSeatService.playAgain: exit current game and start searching for a new game");
					await commander.executeCommand(Commands.EXIT_GAME);
				}
				else {
					console.log("QuickSeatService.playAgain: no current game. just start search for a new game");
				}
				this.startSearch(game.GameTypeId);
			}
		}
		catch (e) {
			console.warn("QuickSeatService.playAgain: smth went wrong -- " + e);
			commander.executeCommand(Commands.EXIT_GAME)
				.catch();
		}
	}

	/**
	 * Join TW cash game (jackpot) named 'Ventiane'
	 */
	async joinCash(gameTypeId: GameType) {
		if (!GameTypeHelper.hasJackpot(gameTypeId)) {
			console.log(`QuickSeatService.joinCash: ${gameTypeId} does not support jackpot`);
			return;
		}
		try {
			const gamesRaw = await this.gameListService.getGames(this.userStore.sessionKey, GameMode.Cash, gameTypeId);
			const games = gamesRaw.filter(game => game.GameTypeId === gameTypeId && (game.Name === "Vientiane" || game.Name === "Dhaka" || game.Name === "European") && OnlineGameHelper.playersCount(game) < 4)
				.sort(sortOn.playersCountDesc);
			console.log(`QuickSeatService.joinCash: games: ${games.length} from ${gamesRaw.length}`);
			if (games.length > 0) {
				const game = games[0];
				const ub = this.userStore.getBalance(CurrencyType.Usd);
				// verify if user has enough money
				if (game.EntryFee > this.userStore.getBalance(CurrencyType.Usd)) {
					this.displayNotEnough(CurrencyType.Usd, game.EntryFee);
				}
				else {
					const result = await commander.executeCommand(Commands.JOIN_GAME, { gameId: game.GameId, roomId: game.RoomId });
					if (result) {
						await this.navigationService.navigate({ page: NavPage.Table });
					}
					else {
						console.log("QuickSeatService.joinCash: join game returned false");
					}
				}
			}
			else {
				console.warn("QuickSeatService.joinCash: cannot find cash game to join");
			}
		}
		catch (e) {
			console.warn("QuickSeatService.joinCash: failed -- " + e);
		}
	}

	private displayNotEnough(currency: CurrencyType, amount: number) {
		const appEventsPipe = container.get<AppEventsPipe>(TYPES.AppEventsPipe);
		appEventsPipe?.send(AppEventType.Display_Not_Enough, { currency, amount });
	}
}


const sortOn = {
	playersCountDesc: (a, b) => {
		if (a.playersToStart === b.playersToStart) {
			return 0;
		}
		else {
			return a.playersToStart - b.playersToStart;
		}
	}
};
