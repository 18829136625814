import {commander, ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {GameService} from "../net/service/GameService";
import {GameStore} from "../store/GameStore";
import {GameController} from "../game/GameController";
import {GameType} from "../game/enums/GameType";
import {Commands} from "./Commands";

export class EnterGameCommand extends ICommand {
	
	async execute(params: { gameId: number, roomId: number, gameType: GameType }): Promise<boolean> {
		const joinedGameType = params.gameType;
		const gameController = container.get<GameController>(TYPES.GameController);
		gameController.prepareTable(joinedGameType);
		commander.executeCommand(Commands.UPDATE_ONLINE_STATUS); // no need to await
		return true;
	}
	
	get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
	get gameService(): GameService {
		return container.get<GameService>(TYPES.GameService);
	}
	
}
