export enum Errors {
	ACCESS_DENIED = 1,
	ACCESS_TO_GAME_DENIED = 1301,
	SESSION_EXPIRED = 1100,
	TIME_FOR_THE_GAME_HAS_RUN_OUT = 999,
	NOT_ENOUGH_MONEY = 1703,
	NOT_ENOUGH_MONEY_GAME = 1315,
}


export const ErrorHelper = {
	notEnoughMoney: (errorId) => errorId === Errors.NOT_ENOUGH_MONEY || errorId === Errors.NOT_ENOUGH_MONEY_GAME,
};

/*
export function Error2Name(type: Errors): string {
	const ents = Object.entries(Errors);
	for (const [label, id] of ents) {
		if (id === type) {
			return label;
		}
	}
	return undefined;
}
*/


// Error.1    = Access Denied!
// Error.1100 = Your session has expired. Please login again.
// Error.1101 = Invalid Login: Check user name and password
// Error.1102 = Not in user role
// Error.1103 = Your account has been suspended
// Error.1104 = Your IP has been suspended
// Error.1105 = This player is already in your friends list
// Error.1106 = This player is not in your friends list
// Error.1107 = Your account is deleted
// Error.1110 = This player is already in your ignore list
// Error.1115 = This email is already in use
// Error.1117 = This player is already in your ignore list
// Error.1118 = This payer is not in your ignore list
// Error.1119 = We are sorry, but you cannot add this user to your Friends List because this user has placed you on their Ignore List
// Error.1120 = Due to US sanctions against Crimea you can not use this website.
// Error.1122 = There is a limit of 100 friends you can add to your Friends List. We are sorry, you have already reached this limit. To add new friends:- revise the current friends list; - delete from the friends list the playersQ you are not friends with anymore; - add new friends
// Error.1123 = There is a limit of 50 playersQ you can add to your Ignore List. We are sorry, you have already reached this limit. To add new playersQ in your Ignore List: - revise the current Ignore List; - delete from the Ignore List the playersQ you don't want to ignore any more; - add a new player to the Ignore List
// Error.1125 = Membership already exist
// Error.1201 = You have not joined the chat
// Error.1204 = The message can't be delivered because the recipient is on your Ignore List, you have to remove the recipient from the Ignore List before sending them a message.
// Error.1205 = The message can't be delivered because the recipient has placed you on their Ignore List.
// Error.1300 = Game finished or does not exist
// Error.1301 = Access to game denied
// Error.1302 = This Game Name is already in use
// Error.1303 = This game already started (Bonus)
// Error.1304 = This game already started
// Error.1305 = Only the game creator can seat playersQ
// Error.1306 = Incorrect seat
// Error.1307 = There are no playersQ in this game
// Error.1309 = The minimum belt must be less than or equal your belt
// Error.1310 = This game is accessible only to friends of the table creator
// Error.1311 = You are not a player
// Error.1312 = This player is already seated
// Error.1313 = This seat is already taken
// Error.1314 = You do not have enough Golden Coins
// Error.1315 = You don't have enough money in your account
// Error.1316 = You don't have enough Dragon Chips
// Error.1317 = Game doesn't allow spectators
// Error.1318 = Players can't be spectators
// Error.1320 = The Site CANNOT offer fee-based tournaments with prizes to residents of the following states: Arkansas, Arizona, Connecticut, Delaware, Florida, Iowa, Illinois, Louisiana, Maryland, Montana, Nebraska, South Carolina, South Dakota, Tennessee, and Vermont. VOID WHERE PROHIBITED OR RESTRICTED BY LAW. Countries that are not permitted to play games for cash include: China
// Error.1321 = All seats are already taken
// Error.1324 = You cannot leave this tournament because it has already started.
// Error.1325 = We are sorry, the game can only be started with four playersQ
// Error.1326 = The Coffee break was called by another player.
// Error.1327 = We are sorry, the deal has already started. Coffee Break can be used only between deals.
// Error.1328 = Open Wall can't be used if the deal is still in play.
// Error.1329 = You don't have enough game items.
// Error.1331 = Unfortunately, your account is temporarily or permanently suspended from entering MCR Marathon games.
// Error.1332 = Due to upcoming server maintenance, joining games is currently impossible. Please try again later and thank you for your patience!
// Error.1333 = Unfortunately, your account is temporarily or permanently suspended from entering Riichi Marathon games.
// Error.1334 = Unfortunately, your account is temporarily or permanently suspended from entering NMJL Marathon games.
// Error.1335 = Your game version is outdated. To switch from Old to EON, please go to My Account - General Settings – Application style and click Save, then re-login.
// Error.1401 = This game is not archived
// Error.1509 = This is not a tournament game
// Error.1510 = You are already a tournament player
// Error.1511 = You can not join this game
// Error.1515 = Tournament not found
// Error.1516 = This is not an official tournament
// Error.1517 = Incorrect official tournament
// Error.1518 = Incorrect tour type
// Error.1519 = Incorrect number of winners
// Error.1520 = Incorrect distribution percents
// Error.1521 = Incorrect number of tables
// Error.1522 = We are Sorry. You can't access this table as it is reserved for Russian Mahjong Marathon
// Error.1703 = You don't have enough money in your account
// Error.1801 = You can not sit at this table
// Error.1802 = You are not an official tournament player.
// Error.1901 = Guild not found
// Error.1902 = Guild is not active
// Error.1903 = Only the guild leader can drop member
// Error.1904 = You are not the leader of this guild
// Error.1905 = This player joined another guild
// Error.1906 = This player is already a member
// Error.1907 = You are already a member
// Error.1908 = You did not receive an invitation to this guild
// Error.1909 = User is not joined to this guild
// Error.1910 = Maximum icon size is 2kb
// Error.1911 = Maximum logo size is 40kb
// Error.1912 = Maximum number of guild members was reached
// Error.1913 = Join Request Not Found
// Error.1914 = Already Sent a Join Request
// Error.1915 = This Guild Already Exists
// Error.1916 = Selected player can’t join your guild
// Error.1917 = You can’t join this guild
// Error.1918 = Please refrain from using profane language in your guild name
// Error.1919 = Please check again the selected icon and logo images
// Error.227  = Archived games are available to members only
// Error.507  = You can't declare draw.
// Error.514  = You are not the game creator
// Error.515  = Not enough playersQ to start the game
// Error.518  = It is not your turn to move
// Error.519  = You don't have this tile
// Error.520  = Invalid move
// Error.521  = The system has already moved for you
// Error.522  = You do not have the required tile to exchange the Joker.
// Error.523  = You cannot use the last tile of the wall for Kong self
// Error.524  = The Joker can be exchanged only at your turn
// Error.531  = Incorrect prize fund
// Error.532  = Incorrect time limit. The minimum time limit is 5 seconds
// Error.533  = Incorrect number of rounds
// Error.534  = You have no credits
// Error.535  = Incorrect Game Name
// Error.536  = We are sorry, to create your own table you have to be a VIP or Premium member.
// Error.537  = We are sorry, the minimum belt requirement to create a game is white belt with one green stripe
// Error.539  = Incorrect minimum points to declare Mahjong
// Error.540  = Incorrect Game type
// Error.541  = You can't declare Mahjong self
// Error.542  = The robot can't join any games with entry fees
// Error.549  = Minimum points for MCR is 8
// Error.550  = Please place open tile
// Error.551  = You have to say pass
// Error.552  = Invalid self move
// Error.553  = You don't have Kong self
// Error.554  = You can't declare Mahjong
// Error.555  = You can't declare Kong self
// Error.556  = Incorrect tile
// Error.558  = Incorrect play time. The minimum play time is 15 minutes
// Error.559  = You can place only tiles drawn from the wall
// Error.560  = You can't become Ready because you have exposed tiles
// Error.561  = Minimum hans for RCR is 1
// Error.563  = You don't have Mahjong or your hand doesn't have minimum required points to declare Mahjong. Please make another move.
// Error.564  = You can say only pass on a discarded joker
// Error.565  = You can't create an entry fee game because you do not have money on your account
// Error.566  = You have attempted to join a table with two user accounts from the same computer. This is strictly prohibited according to our terms and conditions. Your account is now monitored by our anti-collusion team. If you attempt this again your accounts will be suspended.
// Error.567  = You can't declare draw
// Error.568  = You can't place an open tile
// Error.569  = You can't declare Chow. Please make another move
// Error.570  = You can't declare Pung. Please make another move
// Error.571  = You can't declare Kong. Please make another move
// Error.572  = You can't declare Kong self. You received seabed tile
// Error.573  = You can't declare Chow on riverbed tile
// Error.574  = You can't declare Pung on riverbed tile
// Error.575  = You can't declare Kong on riverbed tile
// Error.576  = You can't declare Mahjong self. You must discard a tile
// Error.577  = Jokers cannot be passed during Charleston. Please select another tile
// Error.578  = Flowers replacement has finished
// Error.579  = You don't have a defined Joker
// Error.580  = You can't declare Mahjong with undefined Jokers in the hand
// Error.585  = Tournament games cannot be replayed
// Error.586  = Minimum points for Zung Jung is 5
// Error.587  = You can't declare Riichi
// Error.589  = Incorrect game description
// Error.590  = Your belt is lower than the minimum required to join the table
// Error.591  = You cannot claim the last tile of the wall for Chow, Pung or Kong
// Error.592  = Incorrect belt type
// Error.593  = The Game Name cannot be longer than 15 characters
// Error.594  = Incorrect entry fee
// Error.595  = Time Bank feature is active
// Error.596  = Time Bank can be used only once per deal
// Error.597  = This game name or description contains profane words
// Error.598  = You aren't a participant
// Error.599  = One of the  playersQ declared 4 Kongs. ﻿No further Kongs may be declared.
// Error.604  = Invalid First Name
// Error.605  = Invalid Last Name
// Error.606  = Invalid E-Mail
// Error.607  = Invalid City
// Error.608  = Invalid Province
// Error.609  = Invalid ZIP code
// Error.617  = These features are only accessible to Premium and VIP Members.
// Error.618  = The Old Password is incorrect. Please verify the password and try again.
// Error.619  = New Password is incorrect
// Error.623  = These features are accessible only to VIP Yearly members
// Error.624  = You are a Free Member, the full set of features is available to VIP Members
// Error.625  = You are a Premium Member, the full set of features is available to VIP Members
// Error.626  = You are a Premium Member. The Save Avatar function is available to VIP Members only
// Error.627  = You are a Free Member. The Save Avatar function is available to VIP Members only
// Error.628  = You are a Cash Member. The Save Avatar function is available to VIP Members only
// Error.629  = You are a Cash Member, the full set of features is available to VIP Members
// Error.631  = Please fill all the required fields
// Error.632  = Incorrect country
// Error.652  = Email is missing
// Error.653  = A change in the Multiple Tables rule has established the policy that playersQ can no longer play for the same Jackpot at two different tables at the same time. Playing multiple tables for different Jackpot levels and/or styles is still permitted.
// Error.703  = The tournament has already started. You will be automatically redirected to table at which you are playing
// Error.711  = The game limit has been reached
// Error.800  = Please submit a valid amount to withdraw
// Error.803  = Invalid parameters
// Error.900  = You have sent a word in the chat that is in our profanity filter. We strongly believe that it is inappropriate for playersQ on our website to use inappropriate language. Please read our policy on player behavior
// Error.901  = You have sent a word in the chat that is in our profanity filter. We strongly believe that this is inappropriate for playersQ on our website and this is a first warning for using profanity or offensive language. Please read our policy on player behavior
// Error.902  = You have sent a word in the chat that is in our profanity filter. We strongly believe that this is inappropriate for playersQ on our website and your account has been suspended for 3 hours.  Please read our policy on player behavior
// Error.903  = You have sent a word in the chat that is in our profanity filter. We strongly believe that this is inappropriate for playersQ on our website and your account has been suspended for 3 hours.  Please read our policy on player behavior
// Error.904  = You have sent a word in the chat that is in our profanity filter. We strongly believe that this is inappropriate for playersQ on our website and this is a second warning for using profanity or offensive language. Please read our policy on player behavior
// Error.905  = You have sent a word in the chat that is in our profanity filter. We strongly believe that this is inappropriate for playersQ on our website and your account has been banned for 24 hours.  Please read our policy on player behavior
// Error.906  = You have sent a word in the chat that is in our profanity filter. We strongly believe that this is inappropriate for playersQ on our website and this is a third (final) warning for using profanity or offensive language. Next time your account will be terminated without notice. Please read our policy on player behavior
// Error.999  = The time for the game has run out
