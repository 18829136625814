export enum Commands {
	BUG_REPORT,
	NAVIGATE_TO,
	PLAY_QUICK_AGAIN,
	SWITCH_LANGUAGE,
	REGISTER_COMMANDS,
	GET_INFO,
	LOGGED_IN,
	LOGUOT,
	JOIN_GAME,
	ENTER_GAME,
	RUN_GAME,
	EXIT_GAME,
	RELEASE_GAME,
	UPDATE_PLAYER_POINTS,
	UPDATE_ONLINE_STATUS,
	NOT_ENOUGH,
	SETTINGS,
}
