import {ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {GameStore} from "../store/GameStore";
import {GameService} from "../net/service/GameService";

export class UpdatePlayerPointsCommand extends ICommand {
	
	async execute() {
		try {
			const sid = this.userStore.sessionKey;
			const joinedGame = this.gameStore.joinedGame;
			const points = await this.gameService.getTotalPoints(sid, joinedGame.RoomId, joinedGame.GameId);
			// this.gameStore.points.setPoints(points);
			points.forEach(pts => {
				this.gameStore.gameUsers.updateUser(pts.UserId, {Points: pts.Points});
			});
		}
		catch (e) {
			console.error("UpdatePlayerPointsCommand.execute: " + e);
		}
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
	private get gameService(): GameService {
		return container.get<GameService>(TYPES.GameService);
	}
	
}
