import {IGameAction} from "../common/GameAction";
import {GameMessageDTO} from "../../../net/dto/GameMessageDTO";
import {JMRulesStateComponentWrapper} from "./components/JMRulesStateComponent";
import {DoraType} from "./DoraType";
import {WallTilesHelper} from "../../helpers/WallTilesHelper";
import {Entity} from "ecsy";
import {TileEntityHelper} from "../../helpers/TileEntityHelper";
import {ReadyBarWrapper} from "./components/ReadyBarWrapper";
import {PlayerWrapper} from "../wrappers/PlayersArray";
import {TileSetHelper} from "../../helpers/TileSetHelper";


export class DoraIndicatorAction implements IGameAction {
	private readonly tileId: number;
	private readonly gameMessage: GameMessageDTO;
	private readonly rulesState: JMRulesStateComponentWrapper;
	private singleWallLength: number;
	private readonly allTiles: Entity[];
	
	constructor({gameMessage, rulesState, singleWallLength, allTiles}: { gameMessage: GameMessageDTO, rulesState: JMRulesStateComponentWrapper, singleWallLength: number, allTiles: Entity[] }) {
		this.gameMessage = gameMessage;
		this.rulesState = rulesState;
		this.singleWallLength = singleWallLength;
		this.allTiles = allTiles;
		this.tileId = +gameMessage.Message;
	}
	
	execute() {
		this.rulesState.addDora(DoraType.REGULAR, +this.gameMessage.Message);
		const tile = WallTilesHelper.getTileAtIndex(this.allTiles, this.singleWallLength, this.rulesState.doraCount * 2 + 4);
		TileEntityHelper.changeId(tile, this.tileId);
	}
}

export class UraDoraIndicatorAction implements IGameAction {
	private readonly tileIds: number[];
	private readonly rulesState: JMRulesStateComponentWrapper;
	private readonly singleWallLength: number;
	private readonly allTiles: Entity[];
	
	constructor({tileIds, rulesState, singleWallLength, allTiles}: { tileIds: number[], rulesState: JMRulesStateComponentWrapper, singleWallLength: number, allTiles: Entity[] }) {
		this.tileIds = tileIds;
		this.rulesState = rulesState;
		this.singleWallLength = singleWallLength;
		this.allTiles = allTiles;
	}
	
	execute() {
		this.tileIds.forEach(uraDoraId => {
			this.rulesState.addDora(DoraType.URA_DORA, uraDoraId);
			const tile = WallTilesHelper.getTileAtIndex(this.allTiles, this.singleWallLength, this.rulesState.uraDoraCount * 2 + 3);
			TileEntityHelper.changeId(tile, uraDoraId);
		});
	}
}


export class ReadyAction implements IGameAction {
	private readonly readyBar: ReadyBarWrapper;
	private readonly player: PlayerWrapper;
	
	constructor({readyBar, player}: { readyBar: ReadyBarWrapper, player: PlayerWrapper }) {
		this.readyBar = readyBar;
		this.player = player;
	}
	
	execute() {
		this.readyBar.isDeclared = true;
	}
}

export class LockTilesAction implements IGameAction {
	constructor(private params: { allTiles: Entity[], player: PlayerWrapper, tileTinyIds: number[] }) {
	}
	
	execute() {
		console.log("LockTilesAction.execute: players=" + this.params.player.id + " , " + this.params.tileTinyIds.join("|"));
		const playerConcealed = TileSetHelper.getPlayerConcealedTiles(this.params.player.id, this.params.allTiles);
		this.params.tileTinyIds.forEach(tileId => {
			const te = TileSetHelper.getTile({tiles: playerConcealed, tileTinyId: tileId, isLocked: false});
			if (te) {
				TileEntityHelper.lockTile(te);
				console.log(`LockTilesAction.lock: tile=${tileId}.. ok`);
			}
			else {
				console.log(`LockTilesAction.lock: tile=${tileId}.. failed`);
				console.log("LockTilesAction.av-concealed: " + TileSetHelper.getTileIds(playerConcealed));
			}
		});
	}
}

export class UnlockTilesAction implements IGameAction {
	constructor(private params: { player: PlayerWrapper, tileTinyIds: number[], allTiles: Entity[] }) {
	}
	
	execute() { // for the case of kuikae this will be the last concealed tile, so no sense to search by id
		console.log("UnlockTilesAction.execute: player=" + this.params.player.id + " , " + this.params.tileTinyIds.join("|"));
		const playerConcealed = TileSetHelper.getPlayerConcealedTiles(this.params.player.id, this.params.allTiles);
		this.params.tileTinyIds.forEach(tileId => {
			const te = TileSetHelper.getTile({tiles: playerConcealed, tileTinyId: tileId, isLocked: true});
			if (te) {
				TileEntityHelper.unlockTile(te);
				console.log(`UnlockTilesAction.unlock: tile=${tileId}.. ok`);
			}
			else {
				console.log(`UnlockTilesAction.unlock: tile=${tileId}.. failed`);
				console.log("UnlockTilesAction.av-concealed: " + TileSetHelper.getTileIds(playerConcealed));
			}
		});
	}
}
