import {TileDescrState} from "../../game/enums/TileDescrState";

export class TileDescrDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.TileDescr"
	
	public UserId: number;
	public TileId: number;
	public State: TileDescrState;
	public Group: number;
}
