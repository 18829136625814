import {Subject} from "rxjs";
import {injectable} from "inversify";
import {Side} from "./enums/Side";

@injectable()
export class GameEventsPipe {
	public events: Subject<GameEvent> = new Subject<GameEvent>();
	
	public send<T>(type: GameEventType, data?: T): void {
		this.events.next(new GameEvent(type, data));
	}
}


export class GameEvent {
	constructor(public type: GameEventType, public data?: any) {
	
	}
}

export enum GameEventType {
	MoveNow_Updated,
	Display_PlayerInfo,
	DealStarted,
	DealEnded,
	DealEnded_RemovePopup,
	GameError,
	UI_SortTiles,
	Display_GameDialog,
	Remove_GameDialog,
	GameDialog_Submit,
	// pass to game engine
	Action_EndGame,
	MakeMove_Started,
	MakeMove_Succeed,
	MakeMove_Failed,
	MoveTimer_Updated,
	UIActionMenu_Show,
	UIActionMenu_Clicked,
	UIActionMenu_Remove,
	JMCountersUpdated,
	Game_Ended,
}

export interface IDisplayPlayerInfoData {
	id: number;
	name: string;
	location: Side;
	realside: Side;
	points: number;
}
