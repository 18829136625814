import {GuildDTO} from "./GuildDTO";

export class UserProfileDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.UserProfile";
	
	public UserId: number;
	public UserName: string;
	public FirstName: string;
	public LastName: string;
	public GenderId: number;
	public Email: string;
	public IsValidEmail: boolean;
	public IsShowEmail: boolean;
	public Address: string;
	public City: string;
	public PartnerId: number;
	public Province: string;
	public StateId: number;
	public Country: string;
	public ZipCode: string;
	public RegDate: Date;
	public FacebookUid: string;
	public MatchmoveUid: string;
	public Guild: GuildDTO;
	
	public EmailState: boolean;
	public LastDateEmailVerified: Date;
	
	public DateEmailStateChanged: Date;
}
