import consola from "consola";
import {environment} from "../../environments/environment";


export default class MyReporter {
	private messagesToKeep = environment.isDebug ? 6666 : 3333;
	private messagesStack: any[] = [];
	
	constructor(/*{ stream } = {}*/) {
		// this.stream = stream || process.stdout
	}
	
	log(logObj) {
		const newLength = this.messagesStack.unshift(logObj);
		if (newLength > this.messagesToKeep) {
			this.messagesStack.pop();
		}
	}
	
	private formatLog(o): string {
		try {
			return JSON.stringify(o);
		}
		catch (e) {
			return "JSON error";
		}
	}
	
	public getMessagesFormatted(): string[] {
		// this.messagesStack.reduce((acc, current) => acc + "\n" + this.formatLog(current), "");
		return this.messagesStack.map(log => this.formatLog(log));
	}
}

export class LoggerLite {
	
	private consoleInfoFn;
	private logCollector: MyReporter;
	
	constructor() {
		// console["__info_bak"] = console.info;
		this.consoleInfoFn = console.info;
		this.logCollector = this.addLogCollector();
	}
	
	// d
	fatal(message: any, ...args: any[]): void {
		this.logger.fatal(message, args);
	}
	
	error(message: any, ...args: any[]): void {
		this.logger.error(message, args);
		
	}
	
	warn(message: any, ...args: any[]): void {
		this.logger.warn(message, args);
	}
	
	log(message: any, ...args: any[]): void {
		this.logger.log(message, args);
	}
	
	info(message: any, ...args: any[]): void {
		this.logger.info(message, args);
	}
	
	debug(message: any, ...args: any[]): void {
		this.logger.debug(message, args);
	}
	
	/*trace(message: any, ...args: any[]): void {
		
	}*/
	
	private get logger() {
		return consola;
	}
	
	private addLogCollector(): MyReporter {
		const mr = new MyReporter();
		environment.production ? this.logger.setReporters([mr]) : this.logger.addReporter(mr);
		return mr;
	}
	
	public wrapConsole(): void {
		// this.logger.wrapConsole();
		// this.logger.wrapStd();
		this.logger.wrapAll();
	}
	
	
	public getMessages(): string[] {
		return this.logCollector.getMessagesFormatted();
	}
	
}


export const loglite = new LoggerLite();


