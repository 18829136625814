import {MoveType, MoveType2Name} from "../../game/enums/MoveType";

export class GameMessageDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.GameMessage"
	
	public Id: number;
	public GameId: number;
	public UserId: number;
	// public Type: number;
	public Type: MoveType;
	public Message: string;
	public PostTime: Date;
	
	/** Optional. Used only in GameSnapshot for Melded tiles */
	public Group: number;
	
	public toString() {
		return `[GameMessageDTO {id=${this.Id}, gameId=${this.GameId}, userId=${this.UserId}, type=${this.Type}: ${MoveType2Name(this.Type)}, message=${this.Message}, time=${this.PostTime}}]`;
		// return `[GameMessageDTO -- id: ${this.Id} • gameId: ${this.GameId} • userId: ${this.UserId} • type: ${this.Type} - ${MoveType2Name(this.Type)} • message: ${this.Message} • time: ${this.PostTime}]`;
	}
}
