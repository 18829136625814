import {Entity} from "ecsy";
import {DealStateComponent, TurnStateComponent, ViewStateComponent} from "../ecs/common/components";
import {DealState} from "../enums/DealState";
import {Side} from "../enums/Side";

export class EntityHelper {
	
	public static setViewState(gameStorage: Entity, state: IViewState): void {
		const ds = gameStorage.getMutableComponent(ViewStateComponent);
		if (state.basePlayerId) {
			ds.basePlayerId = state.basePlayerId;
		}
		if (state.viewPlayerId) {
			ds.viewPlayerId = state.viewPlayerId;
		}
	}
	
	public static getViewState(gameStorage: Entity): IViewState {
		return gameStorage.getComponent(ViewStateComponent);
	}
	
	public static setDealState(gameStorage: Entity, state: IDealState): void {
		const ds = gameStorage.getMutableComponent(DealStateComponent);
		if (state.dealNum !== undefined) {
			ds.dealNum = state.dealNum;
		}
		if (state.dealTurn !== undefined) {
			ds.dealTurn = state.dealTurn;
		}
		if (state.dealRoundWind !== undefined) {
			ds.roundWind = state.dealRoundWind;
		}
		if (state.state !== undefined) {
			ds.state = state.state;
		}
	}
	
	public static getDealState(gameStorage: Entity): IDealState {
		const ts = gameStorage.getComponent(DealStateComponent);
		return {
			dealNum: ts.dealNum,
			dealTurn: ts.dealTurn,
			dealRoundWind: ts.roundWind,
			state: ts.state,
		};
	}
	
	public static setTurnState(
		gameStorage: Entity,
		{makingMove, waitingForNextMove}: { makingMove?: boolean, waitingForNextMove?: boolean }
	): void {
		const ds = gameStorage.getMutableComponent(TurnStateComponent);
		
		if (makingMove !== undefined) {
			console.log("EntityHelper.setTurnState: makingMove=" + makingMove);
			ds.makingMove = makingMove;
		}
		if (waitingForNextMove !== undefined) {
			console.log("EntityHelper.setTurnState: waitingForNextMove=" + waitingForNextMove);
			ds.waitingForNextMove = waitingForNextMove;
		}
	}
	
	public static getTurnSate(gameStorage: Entity): ITurnState {
		const ts = gameStorage.getComponent(TurnStateComponent);
		return {makingMove: ts.makingMove, waitingForNextMove: ts.waitingForNextMove};
	}
	
}

type IMoveNow = {
	playerId: number, type: "slot" | "player"
};

interface ITurnState {
	makingMove: boolean;
	waitingForNextMove: boolean;
}

interface IViewState {
	viewPlayerId: number;
	basePlayerId: number;
}

interface IDealState {
	dealNum?: number;
	dealTurn?: number;
	state?: DealState;
	dealRoundWind?: Side;
}
