import {commander, ICommand} from "../commander/Commander";
import {Commands} from "./Commands";
import {UserInfoStoreDTO} from "../net/dto/UserInfoStoreDTO";
import {ChatGamController} from "../game/ChatGamController";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {ChatService} from "../net/service/ChatService";
import {UserStore} from "../store/UserStore";
import {TexturePack} from "../game/graphics/TexturePack";

export class LoggedInCommand extends ICommand {
	
	async execute(params?: any): Promise<boolean> {
		console.log("LoggedInCommand.execute: start");
		let successCount = 0;
		try {
			const info = await commander.executeCommand<UserInfoStoreDTO>(Commands.GET_INFO);
			console.log("LoggedInCommand: got my info:", info);
			const chatId = 1;
			// Join default public chat
			const peak = await this.chatService.join(this.userStore.sessionKey, chatId);
			// Start requesting chat.cgam() to get new messages
			this.CGC.startRequestChat(chatId, peak);
			successCount++;
		}
		catch (err) {
			console.warn("Chat err:", err);
		}
		try {
			const tp = container.get<TexturePack>(TYPES.TexturePack);
			console.log(`LoggedInCommand.execute: load start`);
			await tp.loadTextTexture(); // wait for textures to load and then proceed
			console.log(`LoggedInCommand.execute: load complete`);
			successCount++;
		}
		catch (e) {
			console.warn("LoggedInCommand.execute: error loading textures");
		}
		commander.executeCommand(Commands.UPDATE_ONLINE_STATUS); // no need to await
		console.log("LoggedInCommand: done");
		return successCount === 2;
	}
	
	private get chatService(): ChatService {
		return container.get<ChatService>(TYPES.ChatService);
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get CGC(): ChatGamController {
		return container.get<ChatGamController>(TYPES.ChatGamController);
	}
	
}
