<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Wright Patterson Mah Jongg - Beta Release</title>
  <style>
    body {
      font-family: 'Arial', sans-serif;
      overflow: auto;
      margin: 0;
      padding: 0;
      background-image: url('https://mahjongtime.com/uploads/bg_planes.jpg');
      background-size: cover;
      background-attachment: fixed;
    }
    .container {
      max-width: 960px;
      margin: 20px auto;
      background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }
    header, footer {
      background-color: #ec933e;
      color: white;
      padding: 1em;
      text-align: center;
      border-radius: 6px;  /* Rounded corners */
    }
    section {
      padding: 1em;
      color: black;
      border-bottom: 1px solid #ddd;
    }
    .play-now-button {
      background-color: #208ab4;  /* Background color */
      color: white;  /* Text color */
      padding: 15px;  /* Padding */
      text-align: center;  /* Center align text */
      margin: 1em auto;  /* Margins */
      display: inline-block;  /* Display type */
      width: 200px;  /* Width */
      text-decoration: none;  /* No text decoration */
      font-weight: bold;  /* Bold font */
      border: none;  /* Explicitly remove the border */
      outline: none;  /* Remove outline */
      border-radius: 4px;  /* Rounded corners */
      transition: background-color 0.3s ease;  /* Transition effect */
    }

    .play-now-button:hover {
    background-color: #005a70;  /* Darker shade on hover */
    }
    .feedback-link, .official-site-link {
      color: #007bff;
      text-decoration: underline;
    }
    .important-notice {
      background-color: #ffe0b2;
      border: 2px solid #ff9800;
      padding: 1em;
      margin: 1em 0;
      border-radius: 8px;
      font-weight: bold;
    }

    .highlighted-link {
     color: #ff4500;
    font-weight: bold;
    text-decoration: underline;
  }
    /* Media query for devices with a max width of 960px */
    @media screen and (max-width: 960px) {
      .container {
        margin: 20px 10px;
      }
    }

    /* Media query for portrait orientation */
    @media screen and (orientation: portrait) {
      /* Styles for portrait orientation */
      .container {
        width: 100%; /* Full width in portrait mode */
        padding: 10px; /* Adjust padding */
        margin: 10px auto; /* Adjust margin */
      }
      /* Additional styles for portrait mode can be added here */
    }

    /* Media query for landscape orientation */
    @media screen and (orientation: landscape) {
      /* Styles for landscape orientation */
      .container {
        max-width: 1200px; /* Wider width in landscape mode */
        padding: 20px; /* Adjust padding */
        margin: 20px auto; /* Adjust margin */
      }
      /* Additional styles for landscape mode can be added here */
    }
  </style>
</head>
<body>
  <div class="container">
    <header>
      <h1>Wright Patterson Mah Jongg - Beta Release</h1>
    </header>

    <section>
      <h2>About the Game</h2>
      <p>
        Wright Patterson Mah Jongg is a unique variation of the traditional Mah Jongg game, offering a diverse array of strategies and hands to play. Perfect for both novice and experienced players!
      </p>
      <h2>How to Provide Feedback</h2>
      <p>Your input is invaluable to us! Here are the ways you can offer your feedback:</p>
      <ul>
        <li>Comment Below on Blogger: We encourage you to leave your thoughts, suggestions, and questions in the comments section of our <a href="https://wpmahjongg.blogspot.com/" target="_blank" >Blogger page</a>.</li>
        <li>Facebook Group: Join our <a href="https://www.facebook.com/groups/3113000165510306" target="_blank">Facebook Group</a> to share your thoughts and connect with other players.</li>
        
      </ul>
      
        <div class="important-notice">
    <strong>Game Book Required:</strong> 
    The game book for Wright Patterson Mah Jongg is necessary to fully engage in the game. 
    You can purchase the official game book on the 
    <a href="https://wrightpattersonosc.org/mah-jongg.html" target="_blank" class="official-site-link">
      Wright-Patterson Mah Jongg Website
    </a>. 
    Without the book, you won't be able to fully enjoy the rich set of hands that this version offers.
  </div>
     
  <div *ngFor="let info of TYPE_INFO" (click)="selectGameType(info.gameType)" class="rulesInfoImg">
        
    <button class="play-now-button" [attr.data-game-type]="info.gameType">Play Now</button>
        
    
    </div>
    </section>
  
    <section>
      <h2>Wanna Try? Here's a Quick Start Guide!</h2>
      <p>If you don't have the Wright Patterson Mah Jongg rules book but still want to give it a try, or if you're familiar with other Mah Jongg styles like Hong Kong, Taiwanese, MCR, or Riichi, here are some quick tips and easy hand combinations you can aim for.</p>
      <h3>For Concealed Hands:</h3>
      <ul>
        <li><strong>#16 Chow Pungs Concealed:</strong> You can go for chows and Pungs or only Chows or only Pungs. However, this hand must be concealed and can only be completed if you draw the last tile from the wall.</li>
        <li><strong>#15 Sequence 1 - 9 and two pungs and a pair:</strong> This involves a concealed hand sequence of 1-9 and two pungs and a pair between numbers 2-8. The sequence cannot be made of melded Chows.</li>
        <li><strong>#68 Concealed Pungs:</strong> Aim for four concealed pungs of any tiles. No Kong or self-Kong allowed.</li>
      </ul>
      <h3>Note:</h3>
      <p>If you go for pairs, you can either opt for one suit or one suit with corresponding dragons.</p>
      <h3>For Exposed Hands (For those familiar with Asian Mahjong):</h3>
      <ul>
        <li><strong>#70 Terminals and Honors Pungs:</strong> This one is straightforward if you're used to Asian Mahjong rules.</li>
        <li><strong>#51 Three Different Suits:</strong> Gather three different pungs in each suit and a pung or pair in Dragons and Winds. No terminals allowed.</li>
        <li><strong>#49 Same Number Pungs:</strong> Get pungs of the same number and complement it with a pung and a pair of either dragons and winds or winds and dragons.</li>
      </ul>
      <div *ngFor="let info of TYPE_INFO" (click)="selectGameType(info.gameType)" class="rulesInfoImg">
        
        <button class="play-now-button" [attr.data-game-type]="info.gameType">Play Now</button>
            
        </div>
    </section>

    
    <footer>
      <p>By using this site, you agree to our <a href="https://mahjongtime.com/terms.html" target="_blank">Terms and Conditions</a>.</p>
    </footer>
  </div>
</body>
</html>