import { Component, HostBinding, HostListener, Input } from "@angular/core";
import { IPlayerResults } from "../../lobby/table/ingame/EndGameDialogComponent";

type winds = "s" | "n" | "e" | "w";

@Component({
	selector: "app-player-game-results",
	templateUrl: "player-game-results.component.html",
	styleUrls: ["player-game-results.component.scss"],
})

export class PlayerGameResultsComponent {
	private _wind: winds = "s";
	private _player: IPlayerResults; // = {} as IPlayerResults;

	@HostBinding("class.compact")
	@Input()
	public cColor = true;

	@HostListener("click")
	onClick(): void {
		this.cColor = !this.cColor;
	}

	@Input()
	public get wind(): winds {
		return this._wind;
	}

	public set wind(value: winds) {
		this._wind = value;
	}

	@Input()
	public get player(): IPlayerResults {
		return this._player;
	}

	public set player(value) {
		this._player = value; // ?? {} as IPlayerResults;
		this.wind = this.player.wind;
	}

	// Returns the points label with doubles and points calculation
	public get pointsLabel(): string {
		// If pointsChange is 1500 or more, display the points directly without doubles
		if (this.pointsChange >= 1500) {
			return `${this.pointsChange} points`;
		}

		// Handle the case for positive points below 1500
		if (this.pointsChange > 0) {
			const correctedPoints = this.pointsChange; 
			const doubles = Math.floor(correctedPoints / 100); // Calculate doubles
			const points = correctedPoints % 100; // Calculate remaining points
			return `${doubles} Dbls and ${points} points`;
		}

		// Calculate doubles and points for non-positive values or values below 1500
		const doubles = Math.floor(this.pointsChange / 100); // Calculate doubles
		const points = this.pointsChange % 100; // Calculate remaining points

		return `${doubles} Dbls and ${points} points`;
	}

	// Getter for pointsChange
	public get pointsChange(): number {
		return this.player?.dealPoints || 0;
	}
}