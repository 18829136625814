import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import container from "../../../../inversify/inversify.config";
import {TYPES} from "../../../../inversify/inversify.types";
import {UserStore} from "../../../../store/UserStore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: "app-settings-dialog",
	templateUrl: "./settings-dialog.component.html",
	styleUrls: ["./settings-dialog.component.scss"]
})
export class SettingsDialogComponent implements OnInit {
	
	formGroup: FormGroup;
	
	constructor(
		public dialogRef: MatDialogRef<SettingsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		formBuilder: FormBuilder,
	) {
		
		const settings = this.userStore.settings;
		this.formGroup = formBuilder.group({
			autoReplaceFlowers: settings.autoReplaceFlowersCO,
			acceptTerms: [""]
			// acceptTerms: ["", Validators.requiredTrue]
		});
	}
	
	ngOnInit(): void {
	}
	
	onSubmit() {
		console.log("SettingsDialogComponent.onSubmit: ", this.formGroup.controls.autoReplaceFlowers.value);
		this.userStore.setSettings({autoReplaceFlowersCO: this.formGroup.controls.autoReplaceFlowers.value});
		// alert(JSON.stringify(this.formGroup.value, null, 2));
		
		this.dialogRef.close();
	}
	
	private get userStore() {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	onClose() {
		this.dialogRef?.close();
	}
}
