// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.PrivateChatMessage"
export class PrivateChatMessageDTO {
	public Id: number; // 	= -1;
	public FromUserId: number; // = -1;
	public FromName: string;
	public ToUserId: number; // 	= -1;
	public ToName: string;
	public Message: string;
	public Colour: string;
	public PostTime: Date;
	
	toString(): string {
		return "[PrivateChatMessageDTO: Id=" + this.Id + " FromUserId=" + this.FromUserId + " FromName=" + this.FromName + " ToUserId=" + this.ToUserId + " ToName=" + this.ToName + " Message=" + this.Message + " Colour=" + this.Colour + " PostTime=" + this.PostTime + "]";
	}
	
}
