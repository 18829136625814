export enum Language {
	ENGLISH = 10,
	CN_TRAD = 20,
	CN_SIMPLIFIED = 30,
	SPANISH = 40,
	FRENCH = 50,
	RUSSIAN = 70,
	JAPANESE = 80,
	KOREAN = 100
}
