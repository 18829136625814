import {ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {AppEventsPipe, AppEventType, IAppEventNavigateTo} from "../game/AppEventsPipe";
import {NavPage} from "../app/enums/NavPage";

export class NavigateToCommand extends ICommand {
	
	async execute(params: { page: NavPage }): Promise<boolean> {
		try {
			const appEvents = container.get<AppEventsPipe>(TYPES.AppEventsPipe);
			const data: IAppEventNavigateTo = {route: params.page};
			appEvents.send(AppEventType.NavigateTo, data);
			return true;
		}
		catch (e) {
			console.error("NavigateToCommand.execute: " + e);
			return false;
		}
	}
	
}
