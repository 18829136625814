import * as THREE from "three";
import {BufferAttribute, BufferGeometry, Euler, Geometry, Mesh, MeshLambertMaterial, Object3D, PlaneGeometry, Vector3} from "three";
import {TileDimensions} from "./TileDimensions";
import {ITextureRegionData, TexturePack} from "./graphics/TexturePack";
import {DecalGeometry} from "three/examples/jsm/geometries/DecalGeometry";

export class TileGraphics extends Object3D {
	
	public readonly tileMesh: Tile;
	private readonly tileSymbol: ITileSymbol;
	private char: THREE.Mesh;
	
	constructor(private tp: TexturePack) {
		super();
		this.name = "TileGraphics";
		
		this.tileMesh = new Tile(this.tp);
		this.add(this.tileMesh);
		
		// this.tileSymbol = new TileSymbolMesh(this.tileMesh, this.tp);
		this.tileSymbol = new TileSymbolDecal(this.tileMesh, this.tp); // produce more triangles than regular TileSymbolMesh
		this.add(this.tileSymbol);
		
		// renderOrder required if tileSymbol is a close standing mesh to avoid disappearing on certain angles. Not required if decal is used
		// this.tileMesh.renderOrder = 90; // renderOrder for tile symbol should be greater than for tile itself. Otherwise will appear drawing artifacts
		// this.tileSymbol.renderOrder = 100; // renderOrder for tile symbol should be greater than for tile itself. Otherwise will appear drawing artifacts
		
		this.setTileId(1);
	}
	
	public setIsOver(state: boolean) {
		(this.tileMesh.material as MeshLambertMaterial).color.setHex(state ? 0xCCFFCC : 0xffffff);
		(this.tileMesh.material as MeshLambertMaterial).needsUpdate = true;
	}
	
	public setIsDeadWall(state: boolean) {
		(this.tileMesh.material as MeshLambertMaterial).color.setHex(state ? 0x999999 : 0xffffff);
		(this.tileMesh.material as MeshLambertMaterial).needsUpdate = true;
	}
	
	public setTileId(id: number) {
		// Numbers
		/*if (this.char) {
			this.remove(this.char);
		}
		this.char = TileCharacterGenerator.createLabel(id, 10, 0, 0, 20, undefined);
		this.char.rotateX(180 * 3.14 / 180);
		this.char.position.x = 10;
		this.char.position.y = 15;
		this.char.position.z = -TileDimensions.TD - 2.5;
		this.add(this.char);*/
		
		// Graphic
		this.tileSymbol.setSymbol(id);
	}
	
	public dispose() {
		// TODO: implement dispose
	}
}

interface ITileSymbol extends Mesh {
	setSymbol(id: number);
}

abstract class TileSymbolAbstract extends Mesh implements ITileSymbol {
	// Store original geometry as we modify each tile geometry uvs
	protected static geometryModel: Geometry | BufferGeometry;
	
	public constructor(protected tileMesh: Mesh, protected tp: TexturePack) {
		super();
		this.name = "TileCharacter";
		this.createMesh();
	}
	
	protected abstract createMesh();
	
	protected abstract updateUVs(geometry: Geometry | BufferGeometry, region: ITextureRegionData, textureSize: number);
	
	public setSymbol(tileId: number) {
		if (tileId === 1) {
			this.visible = false; //  make visible = false if there is no symbol to display to avoid unnecessary triangle draws.
			return;
		}
		// in case of: tileId != 1
		const region = (this.tp.tileAtlasRegions["bam" + tileId] ?? this.tp.tileAtlasRegions["bcs" + tileId]);
		if (!region) {
			console.log(`TileSymbol.setSymbol: there is no region for id=${tileId}`);
			this.visible = false;
			return;
		}
		
		// region for tile symbol has been found
		if (!this.visible) {
			this.visible = true;
		}
		const {width: textureSize, height: textureSize2} = this.tileTextureSize;
		
		// scale region to get rid of padding on character symbols. Even more important when decals are used as decal geometry covers less than 100% of tile surface
		const scaledRegion = {...region};
		scaledRegion.x += region.width * 0.05;
		scaledRegion.y += region.height * 0.05;
		scaledRegion.width *= 0.9;
		scaledRegion.height *= 0.9;
		
		this.updateUVs(this.geometry, scaledRegion, textureSize);
	}
	
	private get tileTextureSize(): { width: number, height: number } {
		const {width, height} = this.tp.tileAtlasTexture.image;
		return {width, height};
	}
	
}

class TileSymbolMesh extends TileSymbolAbstract {
	
	protected createMesh() {
		if (!TileSymbolMesh.geometryModel) {
			TileSymbolMesh.geometryModel = new PlaneGeometry(TileDimensions.TW, TileDimensions.TH, 1, 1);
		}
		const texture = this.tp.tileAtlasTexture;
		texture.anisotropy = 2; // renderer.capabilities.getMaxAnisotropy
		// texture.generateMipmaps = false; // default
		// texture.minFilter = THREE.LinearFilter; // add sharpness
		
		this.geometry = TileSymbolMesh.geometryModel.clone();
		this.material = new MeshLambertMaterial({
			map: texture,
			transparent: true,
			// side: THREE.DoubleSide,
			// opacity: 0.5,
			// depthTest: true,
			// depthWrite: false,
		});
		this.position.x = TileDimensions.TW / 2;
		this.position.y = TileDimensions.TH / 2;
		this.position.z = 1;
	}
	
	protected updateUVs(geometry: Geometry | BufferGeometry, region: ITextureRegionData, textureSize: number) {
		Tile.updateGeometryUVsByRegion(geometry as Geometry, TileSymbolMesh.geometryModel as Geometry, region, textureSize);
	}
}

class TileSymbolDecal extends TileSymbolAbstract {
	
	protected createMesh() {
		const pos = new Vector3(TileDimensions.TW / 2, TileDimensions.TH / 2);
		const size = new THREE.Vector3(TileDimensions.TW * .94, TileDimensions.TH * .94, 4); // .86 is max value to keep as min tri count as possible
		
		if (!TileSymbolDecal.geometryModel) {
			TileSymbolDecal.geometryModel = new DecalGeometry(this.tileMesh, pos, new Euler(0, 0, 0), size);
		}
		const decalGeometry = TileSymbolDecal.geometryModel.clone();
		const texture = this.tp.tileAtlasTexture;
		
		texture.anisotropy = 2; // renderer.capabilities.getMaxAnisotropy
		// texture.generateMipmaps = false; // default: true
		// texture.minFilter = THREE.LinearFilter; // add sharpness
		
		const decalMaterial = new THREE.MeshPhongMaterial({
			map: texture,
			// color: 0x336699,
			transparent: true,
			// wireframe: true,
			// depthTest: true,
			// depthWrite: false,
			polygonOffset: true,
			polygonOffsetFactor: -2,
		});
		this.geometry = decalGeometry;
		this.material = decalMaterial;
	}
	
	protected updateUVs(geometry: Geometry | BufferGeometry, region: ITextureRegionData, textureSize: number) {
		Tile.updateBufferGeometryUVsByRegion(geometry as BufferGeometry, TileSymbolDecal.geometryModel as BufferGeometry, region, textureSize);
	}
}

class Tile extends Mesh {
	private static sharedMaterial;
	private static geometryModel: BufferGeometry;
	
	constructor(private tp: TexturePack) {
		super();
		this.name = "TileMesh";
		if (!Tile.geometryModel) {
			Tile.geometryModel = this.createTileGeometry() as BufferGeometry;
			Tile.geometryModel.computeBoundingBox();  // calculate only once to use later in .boundingBox.getSize()
		}
		
		this.geometry = Tile.geometryModel.clone();
		// this.material = this.getOrCreateTileMaterial();
		this.material = this.createTileMaterial();
		// this.geometry.translate(TileDimensions.TW / 2, TileDimensions.TH / 2, -TileDimensions.TD / 2);
		const bbSize = this.geometry.boundingBox.getSize(new Vector3());
		this.geometry.translate(bbSize.x / 2, bbSize.y / 2, -bbSize.z / 2);
		this.setTileTheme(0);
	}
	
	static updateBufferGeometryUVsByRegion(geometry: BufferGeometry, referenceGeometry: BufferGeometry, region: ITextureRegionData, textureSize: number): void {
		const {array: uvs, count, itemSize} = geometry.getAttribute("uv");
		const {array: referenceUvs} = referenceGeometry.getAttribute("uv");
		const itemsCount = count * itemSize;
		const {x, y, width, height} = region;
		
		const uvsNew = new Float32Array(itemsCount);
		let uvx = 0;
		let uvy = 0;
		for (let i = 0; i < itemsCount; i += itemSize) {
			uvx = referenceUvs[i];
			uvy = referenceUvs[i + 1];
			uvx *= width / textureSize;
			uvy *= height / textureSize;
			uvx += (x) / textureSize;
			uvy += (textureSize - height - y) / textureSize;
			uvsNew[i] = uvx;
			uvsNew[i + 1] = uvy;
		}
		const ba = new BufferAttribute(uvsNew, itemSize);
		ba.needsUpdate = true;
		geometry.setAttribute("uv", ba);
	}
	
	/**
	 *
	 * @param geometry -- modifying geometry
	 * @param referenceGeometry -- used as source of original UVs
	 * @param region -- uv region
	 * @param textureSize -- texture size (width of square texture)
	 */
	static updateGeometryUVsByRegion(geometry: Geometry, referenceGeometry: Geometry, region: ITextureRegionData, textureSize): void {
		const {x, y, width, height} = region;
		
		geometry.faceVertexUvs.forEach((layer, layerIndex) => {
			layer.forEach((face, faceIndex) => {
				face.forEach((uv, uvIndex) => {
					uv.x = referenceGeometry.faceVertexUvs[layerIndex][faceIndex][uvIndex].x;
					uv.y = referenceGeometry.faceVertexUvs[layerIndex][faceIndex][uvIndex].y;
					uv.x *= width / textureSize;
					uv.y *= height / textureSize;
					uv.x += (x) / textureSize;
					uv.y += (textureSize - height - y) / textureSize;
				});
			});
		});
		
		geometry.uvsNeedUpdate = true;
	}
	
	static updateGeometryUVs(x, y, w, h, g, textureSize): void {
		for (const layer of g.faceVertexUvs) {
			for (const face of layer) {
				for (const uv/*: Vector2*/ of face) {
					uv.x *= w / textureSize;
					uv.y *= h / textureSize;
					uv.x += (x) / textureSize;
					uv.y += (textureSize - h - y) / textureSize;
				}
			}
		}
		
	}
	
	private setTileTheme(themeId: number = 0) {
		const charRegionData = this.tp.tileAtlasRegions["tileBack_" + "0"];
		const {width: textureSize, height: textureSize2} = this.tileTextureSize;
		Tile.updateBufferGeometryUVsByRegion(this.geometry as BufferGeometry, Tile.geometryModel, charRegionData, textureSize);
		/*const geometry = new THREE.BoxGeometry(TileDimensions.TW, TileDimensions.TH, TileDimensions.TD);
		geometry.translate(TileDimensions.TW / 2, TileDimensions.TH / 2, -TileDimensions.TD / 2);
		const material = new THREE.MeshLambertMaterial({color: 0x336699});
		this.mesh = new THREE.Mesh(geometry, material);*/
		// (this.mesh.geometry as any).uvsNeedUpdate = true;
	}
	
	private setTileSymbol(id: number) {
	
	}
	
	private createTileGeometry() {
		return this.tileModel.geometry.clone();
	}
	
	private createTileMaterial() {
		const material = new MeshLambertMaterial({
			map: this.tileTexture,
			transparent: true,
			// wireframe: true
		});
		return material;
	}
	
	private getOrCreateTileMaterial() {
		if (!Tile.sharedMaterial) {
			const material = new MeshLambertMaterial({
				map: this.tileTexture,
				transparent: true
			});
			// material.side = THREE.DoubleSide;
			// material.map.encoding = THREE.sRGBEncoding;
			// material.map.anisotropy = 16;
			// material.map.flipY = false;
			// material.needsUpdate = true;
			Tile.sharedMaterial = material;
		}
		return Tile.sharedMaterial;
	}
	
	private get tileModel() {
		return this.tp.tileObj.children[0] as Mesh;
	}
	
	private get tileTexture() {
		return this.tp.tileAtlasTexture;
	}
	
	private get tileTextureSize(): { width: number, height: number } {
		const {width, height} = this.tp.tileAtlasTexture.image;
		return {width, height};
	}
	
	
}
