import {ICommand} from "../commander/Commander";
import {ChatGamController} from "../game/ChatGamController";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {ChatService} from "../net/service/ChatService";
import {UserStore} from "../store/UserStore";
import {GameService} from "../net/service/GameService";
import {GameStore} from "../store/GameStore";
import {AppEventsPipe} from "../game/AppEventsPipe";
import {TournamentService} from "../net/service/TournamentService";
import {OnlineGameDTO} from "../net/dto/OnlineGameDTO";

export class JoinGameCommand extends ICommand {
	
	// async execute(params?: any): Promise<any> {
	async execute(params: { gameId: number, roomId: number }): Promise<boolean> {
		console.log("JoinGameCommand.execute: gameId=" + params.gameId);
		if (!params || !params.gameId || !params.roomId) {
			console.warn("JoinGameCommand.execute: verify gameId and roomId: ", params);
			return;
		}
		try {
			
			const gameSettings = await this.gameService.getSettings(this.userStore.sessionKey, params.roomId, params.gameId);
			let joinedGame: OnlineGameDTO;
			if (!gameSettings) {
				console.warn("JoinGameCommand.execute: no game settings for gameId=" + params.gameId);
				throw new Error("No game settings for gameId=" + params.gameId);
			}
			
			if (gameSettings.TourId === 0) {
				// try {
				joinedGame = await this.gameService.join(this.userStore.sessionKey, params.roomId, params.gameId);
				/*}
				catch (e) {
					if (e instanceof ServiceError && ErrorHelper.notEnoughMoney(e.id)) {
						this.displayNotEnough(CurrencyType.Usd, gameSettings.EntryFee);
						return false;
					}
					else {
						throw e;
					}
				}*/
			}
			else {
				// const ts = await this.tourService.GetSettings(this.userStore.sessionKey,params.roomId, gameSettings.TourId);
				// try {
				const t = await this.tourService.takeSeat(this.userStore.sessionKey, gameSettings.RoomId, gameSettings.TourId, gameSettings.GameId);
				joinedGame = t.Game;
				/*}
				catch (e) {
					if (e instanceof ServiceError && ErrorHelper.notEnoughMoney(e.id)) {
						this.displayNotEnough(CurrencyType.Usd, gameSettings.EntryFee);
						return false;
					}
					else {
						throw e;
					}
				}*/
				// t.Official
				// t.Tournament
			}
			
			if (!joinedGame) {
				throw new Error("Joined a null game");
			}
			this.gameStore.joinedGame = joinedGame;
			
			// TODO: join chat & ChatGamController
			const chatId = joinedGame.PlayerChatId; // TODO: Player or Spectator
			// Join default public chat
			const peak = await this.chatService.join(this.userStore.sessionKey, chatId);
			// Start requesting chat.cgam() to get new messages
			this.CGC.startRequestGame(chatId, peak, joinedGame.LastPeak);
			
			return true;
		}
		catch (e) {
			console.log("JoinGameCommand.execute: " + e.message);
			console.warn("JoinGameCommand.execute: ", e);
		}
		return false;
	}
	
	/*private displayNotEnough(currency: CurrencyType, amount: number) {
		const appEventsPipe = container.get<AppEventsPipe>(TYPES.AppEventsPipe);
		appEventsPipe?.send(AppEventType.Display_Not_Enough, {currency, amount});
	}*/
	
	private get chatService(): ChatService {
		return container.get<ChatService>(TYPES.ChatService);
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
	private get gameService(): GameService {
		return container.get<GameService>(TYPES.GameService);
	}
	
	private get tourService(): TournamentService {
		return container.get<TournamentService>(TYPES.TournamentService);
	}
	
	private get CGC(): ChatGamController {
		return container.get<ChatGamController>(TYPES.ChatGamController);
	}
	
	private get appEvents(): AppEventsPipe {
		return container.get<AppEventsPipe>(TYPES.AppEventsPipe);
	}
	
}
