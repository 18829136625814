import {ServiceDescriptor} from "./ServiceDescriptor";

export interface IServiceError {
	id: number;
	message: string;
	target: ServiceDescriptor;
	requestData: any;
}

export class ServiceError extends Error implements IServiceError {
	constructor(
		public readonly id: number = 0,
		public readonly message: string = null,
		public readonly target: ServiceDescriptor,
		public readonly requestData: any
	) {
		super();
	}
	
	toString(): string {
		return `ServiceError -- id: ${this.id}, message: ${this.message}, target: ${this.target}, requestedData: ${this.requestData}`;
	}
}

export enum ServiceErrors {
	UNKNOWN_ERROR = -1,
	CONNECTION = -2,
	ERROR_PROCESSING = -3,
}

