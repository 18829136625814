import {UserInfoStoreDTO} from "../dto/UserInfoStoreDTO";
import {UserRatingDTO} from "../dto/UserRatingDTO";
import {TypeRatingDTO} from "../dto/TypeRatingDTO";
import {UserProfileDTO} from "../dto/UserProfileDTO";
import {GuildDTO} from "../dto/GuildDTO";
import {RewardPointsDTO} from "../dto/RewardPointsDTO";
import {RewardPointsBonusDTO} from "../dto/RewardPointsBonusDTO";
import {UserAccountDTO} from "../dto/UserAccountDTO";
import {UserStatisticsDTO} from "../dto/UserStatisticsDTO";
import {UserSettingsDTO} from "../dto/UserSettingsDTO";
import {FlashUIDTO} from "../dto/FlashUIDTO";
import {AwardDTO} from "../dto/AwardDTO";
import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";
import {TournamentSeatsDTO} from "../dto/tour/TournamentSeatsDTO";

export class UserService extends AbstractAMFService {
	
	protected registerClassAliases() {
		
		this.registerClassAlias("Mahjong.GameServer.Service.Model.UserInfoStore", UserInfoStoreDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.UserRating", UserRatingDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TypeRating", TypeRatingDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.UserProfile", UserProfileDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Guild", GuildDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Award", AwardDTO);
		
		this.registerClassAlias("Mahjong.GameServer.Service.Model.UserAccount", UserAccountDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.RewardPoints", RewardPointsDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.RewardPointsBonus", RewardPointsBonusDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TournamentSeats", TournamentSeatsDTO);
		
		this.registerClassAlias("Mahjong.GameServer.Service.Model.UserStatistics", UserStatisticsDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.UserSettings", UserSettingsDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.FlashUI", FlashUIDTO);
	}
	
	public getInfo(sessionKey: string): Promise<UserInfoStoreDTO> {
		return this.send(UserServices.GetInfo,
			[sessionKey]);
	}
}

export class UserServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.User";
	public static readonly GetInfo = new ServiceDescriptor(UserServices.NS, "GetInfo");
}
