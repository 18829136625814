import {UserRatingDTO} from "./UserRatingDTO";
import {UserProfileDTO} from "./UserProfileDTO";
import {UserAccountDTO} from "./UserAccountDTO";
import {UserStatisticsDTO} from "./UserStatisticsDTO";
import {UserSettingsDTO} from "./UserSettingsDTO";

export class UserInfoStoreDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.UserInfoStore";
	public UserId: number;
	public Profile: UserProfileDTO;
	public Account: UserAccountDTO;
	public Rating: UserRatingDTO;
	public Settings: UserSettingsDTO;
	public Statistics: UserStatisticsDTO;
	public IsFriend: boolean;
	public IsShowWebPage: boolean;
	public Mood: string;
	public Crown: number;
	public GoldenTiles: {}; // Object; //List<GoldenTile> // TODO: DTOs
	public Awards: {}; // Object; //List<Award> // TODO: DTOs
	public Status: string;
	public PS: string;
	public UsedAir: boolean;
	public LastLoginApp: number;
	public NMJLCardVersion: number;
	public ShowAgreement: boolean;
}
