import {UserInfoStoreDTO} from "../dto/UserInfoStoreDTO";
import {UserService} from "./UserService";
import {UserSettingsDTO} from "../dto/UserSettingsDTO";
import {FlashUIDTO} from "../dto/FlashUIDTO";

const generateFakeUser = () => {
	const dto = new UserInfoStoreDTO();
	// dto.UserId = 3001;
	dto.Settings = {
		FlashUI: {
			TileAutoSort: true
		} as FlashUIDTO
	} as UserSettingsDTO;
	return dto;
};

export class FakeUserService extends UserService {
	
	public getInfo(sessionKey: string): Promise<UserInfoStoreDTO> {
		return Promise.resolve(generateFakeUser());
	}
	
}

