import {Injectable, OnDestroy} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GameStore} from "../../store/GameStore";
import {UserStore} from "../../store/UserStore";
import container from "../../inversify/inversify.config";
import {TYPES} from "../../inversify/inversify.types";
import {Subject} from "rxjs";
import {NavigationService} from "./navigation.service";
import {IGameDialogConfig, InGameDialogComponent} from "../ui/lobby/table/ingame/InGameDialogComponent";
import {ComponentType} from "@angular/cdk/overlay";
import {takeUntil} from "rxjs/operators";

@Injectable({providedIn: "root"})
export class DialogService implements OnDestroy {
	
	private gameStore: GameStore;
	private userStore: UserStore;
	
	private destroy$: Subject<boolean> = new Subject<boolean>();
	private gameDialogs: Map<string, MatDialogRef<any, any>>
		= new Map<string, MatDialogRef<any, any>>();
	
	constructor(
		public dialog: MatDialog,
		private navigationService: NavigationService
	) {
		this.gameStore = container.get(TYPES.GameStore);
		this.userStore = container.get(TYPES.UserStore);
		
	}
	
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
	
	public displayMessage(message: string/*, opts?: { modal?: boolean }*/) {
		this.displayDialog({options: {id: "Random", message, buttons: [{data: "CLOSE", label: "Close"}]}});
	}
	
	public displayDialog(props: { options: IGameDialogConfig }): MatDialogRef<any, any>;
	public displayDialog<T>(props: { component: ComponentType<T>, options: any, panelClass?: string }): MatDialogRef<T, any>;
	public displayDialog<T>({component, options, panelClass}: { component?: ComponentType<T>, options: any, panelClass?: string }): MatDialogRef<any, any> {
		console.log("DialogService.displayDialog: " + options?.id);
		const dId = options?.id ?? "Dialog_" + Date.now();
		if (this.gameDialogs.has(dId)) {
			const exDialog = this.gameDialogs.get(dId);
			exDialog.componentInstance?.setDialogData(options);
			return exDialog;
		}
		else {
			let dialogRef: MatDialogRef<T | InGameDialogComponent>;
			if (component) {
				const cfg: { disableClose: boolean, hasBackdrop: boolean, data: any, panelClass?: string } = {
					disableClose: true,
					hasBackdrop: false,
					data: options,
				};
				if (panelClass) {
					cfg.panelClass = panelClass;
				}
				dialogRef = this.dialog.open(component, cfg);
			}
			else {
				// dialogRef: MatDialogRef<InGameDialogComponent, IGameDialogSubmitData> = this.dialog.open(InGameDialogComponent, {
				dialogRef = this.dialog.open(InGameDialogComponent, {
					disableClose: true,
					hasBackdrop: false,
					data: options
				});
			}
			
			this.gameDialogs.set(dId, dialogRef);
			dialogRef.afterClosed()
				.pipe(takeUntil(this.destroy$))
				.subscribe((result) => {
					console.log(`The dialog [${dId}] was closed, result=`, result, ", ref=" + dialogRef);
					this.removeGamePopup({id: dId});  // remove dialog
				});
			return dialogRef;
		}
	}
	
	public removeGamePopup(options: IGameDialogConfig): void {
		const dId = options.id ?? "R";
		console.log("DialogService.removeGamePopup: " + options.id);
		if (this.gameDialogs.has(options.id)) {
			const exDialog = this.gameDialogs.get(options.id);
			this.gameDialogs.delete(options.id);
			exDialog?.close();
		}
	}
	
}
