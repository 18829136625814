<div style="display: flex; gap: 3rem;">
    <button mat-raised-button (click)="onBack()">‹ Back</button>
    <app-debug-helper *ngIf="isDebug" matTooltip="[Dev]: Debug Helper"></app-debug-helper>
</div>

<br>
<!--
<div class="tour-list">
    <div class="tour-line2" *ngFor="let tour of tourList">
        <b id="name">{{tour.Name}}</b>
        &lt;!&ndash;    <span>tour start: {{tour.StartDate}}</span>&ndash;&gt;
        &lt;!&ndash;        <span> » RegOpen: <b>{{tour.RegStartDate|date:"short"}}</b></span>&ndash;&gt;
        <div id="start">
            <span>• Start: </span>
            <b>{{tour.StartDate|date:"short"}}</b>
        </div>
        <span id="entryFee"> • EntryFee: <b>{{getCurrencyLabel(tour.EntryFeeCurrencyId)}} {{tour.EntryFee}}</b></span>
        &lt;!&ndash;    <span> • Currency: <b>{{tour.EntryFeeCurrencyId}}</b></span>&ndash;&gt;
        &lt;!&ndash;        <span> • IsMy: <b>{{tour.IsJoined}}</b></span>&ndash;&gt;
        <div id="btn">
            <button mat-stroked-button color="primary"
                    *ngIf="!tour.IsJoined && tour.RegStartSecLeft===0 && tour.EntryFeeCurrencyId===3"
                    (click)="onReg(tour)">
                Register
            </button>
            <button mat-stroked-button color="warn" *ngIf="tour.IsJoined" (click)="onUnreg(tour)">UnRegister</button>
        </div>
    </div>

</div>-->
<br>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="tourList">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Tournament</th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="registration">
            <th mat-header-cell *matHeaderCellDef>Registration</th>
            <td mat-cell *matCellDef="let tour">
                {{tour.RegStartDate|date:"short"}}
                <div class="cd-start" *ngIf="tour?.RegStartSecLeft > 0">
                    ›
                    <app-count-down [seconds]="tour.RegStartSecLeft"></app-count-down>
                </div>
            </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef>Start</th>
            <td mat-cell *matCellDef="let tour">
                <div style="display: flex; gap:0.5rem; align-items: center;">
                    <mat-icon *ngIf="!isTourFinished(tour)" class="cd-start" style="cursor: pointer;"
                              (click)="openSchedule(tour)"
                              matTooltip="Show tournament schedule">
                        calendar_today
                    </mat-icon>
                    <div>
                        {{tour.StartDate|date:"short"}}
                        <div class="cd-start" *ngIf="tour?.StartSecLeft > 0">
                            ›
                            <app-count-down [seconds]="tour.StartSecLeft"></app-count-down>
                        </div>

                    </div>

                </div>
            </td>
        </ng-container>

        <!-- Weight Column -->
        <!--<ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef>Entry Fee</th>
            <td mat-cell *matCellDef="let element">
                <div style=" display: flex;flex-direction: column;"><span>W</span>
                    <span>{{element.weight}}</span>
                </div>
            </td>
        </ng-container>-->

        <!-- Symbol Column -->
        <ng-container matColumnDef="players">
            <th mat-header-cell *matHeaderCellDef style="text-align:center">Players</th>
            <td mat-cell *matCellDef="let tour"
                style="text-align:center">
                {{tour.PlayersCount}}</td>
        </ng-container>

        <ng-container matColumnDef="entryFee">
            <th mat-header-cell *matHeaderCellDef style="text-align:center">Entry Fee</th>
            <td mat-cell *matCellDef="let tour"
                style="text-align:center">
                {{getCurrencyLabel(tour.EntryFeeCurrencyId)}} {{tour.EntryFee}}</td>
        </ng-container>

        <ng-container matColumnDef="prize">
            <th mat-header-cell *matHeaderCellDef style="text-align:center">Prize Fund</th>
            <td mat-cell *matCellDef="let tour"
                style="text-align:center"
                [mtxTooltip]="tpl"
                mtxTooltipPosition="below">{{tour.PrizeHeading}}
                <ng-template #tpl>
                    <div [innerHTML]="tour.PrizeDescr"
                         class="tt-style"></div>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="text-align:center"></th>
            <td mat-cell *matCellDef="let tour" style="text-align:center">
                <div id="btn">
                    <button mat-stroked-button color="primary"
                            *ngIf="!tour.IsJoined && tour.RegStartSecLeft===0"
                            (click)="onReg(tour)">
                        Register
                    </button>
                    <button mat-stroked-button color="warn" *ngIf="tour.IsJoined" (click)="onUnreg(tour)">UnRegister
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>
<br>
<a href="https://mahjongtime.com/mahjong-international-mcr-ranking-list.aspx?gameType={{gameType}}" target="_blank">Tournament
    Ranking</a>
<!--
<mat-list role="list" style="display:flex; flex-direction:column; overflow: auto">
    <mat-list-item role="listitem" class="tour-line" *ngFor="let tour of tourList">
        <b>{{tour.Name}}</b>
        &lt;!&ndash;    <span>tour start: {{tour.StartDate}}</span>&ndash;&gt;
        &lt;!&ndash;        <span> » RegOpen: <b>{{tour.RegStartDate|date:"short"}}</b></span>&ndash;&gt;
        &lt;!&ndash;        <span style="background-color: #03dcff"> • Start: <b>{{tour.StartDate|date:"short"}}</b></span>&ndash;&gt;
        <span> • EntryFee: <b>{{getCurrencyLabel(tour.EntryFeeCurrencyId)}} {{tour.EntryFee}}</b></span>
        &lt;!&ndash;    <span> • Currency: <b>{{tour.EntryFeeCurrencyId}}</b></span>&ndash;&gt;
        &lt;!&ndash;        <span> • IsMy: <b>{{tour.IsJoined}}</b></span>&ndash;&gt;
        <button mat-raised-button>sd</button>
        &lt;!&ndash;        <button mat-stroked-button color="primary" *ngIf="!tour.IsJoined && tour.RegStartSecLeft===0"&ndash;&gt;
        &lt;!&ndash;                (click)="onReg(tour)">&ndash;&gt;
        &lt;!&ndash;            Register&ndash;&gt;
        &lt;!&ndash;        </button>&ndash;&gt;
        &lt;!&ndash;        <button mat-stroked-button color="warn" *ngIf="tour.IsJoined" (click)="onUnreg(tour)">UnRegister</button>&ndash;&gt;
    </mat-list-item>

</mat-list>
-->


