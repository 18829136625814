import {GuildDTO} from "./GuildDTO";
import {GameType} from "../../game/enums/GameType";

export class JackpotRecentWinnerDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.Jackpot.RecentWinner"
	
	public UserId: number;
	public Name: string;
	public GameTypeId: GameType;
	public Amount: number;
	public Guild: GuildDTO;
	
}

