import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";
import {TournamentDTO} from "../dto/tour/TournamentDTO";
import {TournamentGameDTO} from "../dto/tour/TournamentGameDTO";
import {TournamentSeatsDTO} from "../dto/tour/TournamentSeatsDTO";
import {TournamentTakeSeatInfoDTO} from "../dto/tour/TournamentTakeSeatInfoDTO";
import {TournamentWinnerDTO} from "../dto/tour/TournamentWinnerDTO";
import {TourPlayerScoreResultsDTO} from "../dto/tour/TourPlayerScoreResultsDTO";
import {TourScoreResultsDTO} from "../dto/tour/TourScoreResultsDTO";
import {TGamesInfoStoreDTO} from "../dto/tour/TGamesInfoStoreDTO";


export class TournamentService extends AbstractAMFService {
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Tournament", TournamentDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TournamentGame", TournamentGameDTO);
		// this.registerClassAlias("Mahjong.GameServer.Service.Model.Tournaments", TournamentListDTO); // looks like it is not used anymore
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TournamentSeats", TournamentSeatsDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TournamentTakeSeatInfo", TournamentTakeSeatInfoDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TWinner", TournamentWinnerDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TourPlayerScoreResults", TourPlayerScoreResultsDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TourScoreResults", TourScoreResultsDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TGamesInfoStore", TGamesInfoStoreDTO);
	}
	
	public leave(sessionKey: string, roomId: number, tourId: number, gameId: number): Promise<void> {
		return this.send(TournamentServices.leave, [sessionKey, roomId, tourId, gameId]);
	}
	
	public takeSeat(sessionKey: string, roomId: number, tourId: number, gameId: number): Promise<TournamentTakeSeatInfoDTO> {
		return this.send(TournamentServices.takeSeat, [sessionKey, roomId, tourId, gameId]);
	}
	
	public getGames(sessionKey: string, roomId: number, tourId: number, tourRound: number): Promise<TGamesInfoStoreDTO> {
		return this.send(TournamentServices.getGames, [sessionKey, roomId, tourId, tourRound]);
	}
	
	public GetSettings(sessionKey: string, roomId: number, tourId: number): Promise<TournamentDTO> {
		return this.send(TournamentServices.getSettings, [sessionKey, roomId, tourId]);
	}
	
	public GetOfficialSettings(sessionKey: string, officialTourId: number): Promise<TournamentDTO> {
		return this.send(TournamentServices.getOfficialSettings, [sessionKey, officialTourId]);
	}
	
	public OfficialRegister(sessionKey: string, officialTourId: number): Promise<ITournamentData> {
		return this.send(TournamentServices.officialRegister, [sessionKey, officialTourId]);
	}
	
	public OfficialUnregister(sessionKey: string, officialTourId: number): Promise<ITournamentData> {
		return this.send(TournamentServices.officialUnregister, [sessionKey, officialTourId]);
	}
	
}

export class TournamentServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.Tournament";
	public static readonly leave = new ServiceDescriptor(TournamentServices.NS, "Leave");
	public static readonly takeSeat = new ServiceDescriptor(TournamentServices.NS, "TakeSeat");
	public static readonly getGames = new ServiceDescriptor(TournamentServices.NS, "GetGames");
	public static readonly getSettings = new ServiceDescriptor(TournamentServices.NS, "GetSettings");
	public static readonly getOfficialSettings = new ServiceDescriptor(TournamentServices.NS, "GetOfficialSettings");
	public static readonly officialRegister = new ServiceDescriptor(TournamentServices.NS, "OfficialRegister");
	public static readonly officialUnregister = new ServiceDescriptor(TournamentServices.NS, "OfficialUnregister");
}

interface ITournamentData {
	roomId?: number;
	tourId?: number;
	tourName?: string;
}
