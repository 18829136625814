import {Entity} from "ecsy";
import {
	ForceOpenedTagComponent,
	PlayerIdComponent,
	PlayerStoreComponent,
	TileDataComponent,
	TileNodesComponent,
	TilePositionComponent,
	TileRotationComponent,
	TileState,
	TileTypeComponent
} from "../ecs/common/components";
import {TileType} from "../enums/TileType";
import * as THREE from "three";
import {Quaternion, Vector3} from "three";
import {TilePosition, TilePositionTransform} from "../TilePositionTransform";
import {inject, injectable} from "inversify";
import {TileDimensions} from "../TileDimensions";
import {TYPES} from "../../inversify/inversify.types";
import {GameStore} from "../../store/GameStore";
import {MarkedForCharlestonTag} from "../ecs/wp/components.wp";
import {TilesSortFn} from "../interfaces/TilesSortFn";
import {TileSetHelper} from "./TileSetHelper";

@injectable()
export class PlayerLayoutHelper {
	
	constructor(@inject(TYPES.GameStore) private gameStore: GameStore) {
	}
	
	private startPoint = new THREE.Vector3(-160, 0, 260);
	
	public updatePlayerTilesPositions(player: Entity, allTiles: Array<Entity>, tileType?: TileType) {
		const playerId = player.getComponent(PlayerIdComponent).playerId;
		switch (tileType) {
			case TileType.CONCEALED:
				this.updatePlayerConcealedPositions(player,
					TileSetHelper.getPlayerConcealedTiles(playerId, allTiles)
				);
				break;
			case TileType.MELDED:
				this.updatePlayerMeldedPositions(player,
					TileSetHelper.getPlayerMeldedTiles(playerId, allTiles)
				);
				break;
			case TileType.FLOWER:
				this.updatePlayerFlowerPositions(player,
					TileSetHelper.getPlayerFlowerTiles(playerId, allTiles)
				);
				break;
			default: // undefined
			// all
		}
	}
	
	/**
	 *
	 * @param concealedTiles -- sorted
	 */
	private updatePlayerConcealedPositions(player: Entity, concealedTiles: Array<Entity>) {
		if (concealedTiles.length === 0) {
			return;
		}
		let xPos = 0;
		const playerId: number = player.getComponent(PlayerIdComponent).playerId;
		const firstTile: Entity = concealedTiles.find(tileEntity => tileEntity.getComponent(TileNodesComponent).previous === null);
		let maxIter = 50;
		let tile = firstTile;
		while (tile && maxIter-- > 0) {
			xPos = this.alignTile(tile, player, new Vector3(xPos, 0, 0), this.getConcealedTileAdjByState);
			tile = tile.getComponent(TileNodesComponent).next;
		}
		if (maxIter === 0) {
			console.warn("error updating concealed tiles. max iter reached");
		}
	}
	
	private alignTile(tileEntity: Entity, player: Entity, pos: Vector3, adjByState: (state: TileState, forceOpened?: boolean) => TilePosition): number {
		const playerId = player.getComponent(PlayerIdComponent).playerId;
		const lc = player.getComponent(PlayerStoreComponent).locationTransform;
		
		
		const tileData = tileEntity.getComponent(TileDataComponent);
		const isForceOpened = tileEntity.hasComponent(ForceOpenedTagComponent);
		const tilePosAdj = adjByState(tileData.state, isForceOpened);
		
		const currentPosition = tileEntity.getComponent(TilePositionComponent).position;
		const newPosition = pos.clone();
		if (tileEntity.hasComponent(MarkedForCharlestonTag)) { 
			newPosition.z -= 10;
		}

		if (tileEntity.hasComponent(MarkedForCharlestonTag)) { 
			newPosition.z -= 10;
		}
		newPosition
			.add(this.startPoint) // apply player side position
			.add(tilePosAdj.position); // add tile position adjustments
		/*if (concealedRotationX > 0 && tileEntity.getComponent(TileTypeComponent).value === TileType.CONCEALED) {
			newPosition.applyAxisAngle(new Vector3(0, 1, 0), concealedRotationX);
		}*/
		newPosition
			// .applyQuaternion(this.playerTransformsQ[playerId].quaternion); // apply player side rotation
			.applyQuaternion(lc.quaternion); // apply player side rotation
		
		
		if (!this.isPositionsEqual(currentPosition, newPosition)) {
			tileEntity.getMutableComponent(TilePositionComponent).position = newPosition;
		}
		
		// TODO: add check if rotation has actually changed ?
		const rot = lc.quaternion.clone();
		// add rotation for concealed tiles (if required)
		const concealedRotationX = player.getComponent(PlayerStoreComponent).concealedRotationX;
		if (concealedRotationX > 0 && tileEntity.getComponent(TileTypeComponent).value === TileType.CONCEALED) {
			rot.multiply(new Quaternion()).setFromAxisAngle(new Vector3(1, 0, 0), concealedRotationX);
		}
		//
		rot.multiply(tilePosAdj.rotation);
		tileEntity.getMutableComponent(TileRotationComponent).rotation = rot;
		
		return pos.x + tilePosAdj.xw;
	}
	
	/**
	 * @param player - player
	 * @param meldedTiles - sorted
	 */
	private updatePlayerMeldedPositions(player: Entity, meldedTiles: Array<Entity>) {
		if (meldedTiles.length === 0) {
			return;
		}
		let xPos = 0;
		const playerId: number = player.getComponent(PlayerIdComponent).playerId;
		// calc start point for melded tiles
		for (const tileEntity of meldedTiles) {
			const tileData = tileEntity.getComponent(TileDataComponent);
			const tilePosAdj = this.getMeldedTileAdjByState(tileData.state);
			xPos += tilePosAdj.xw;
		}
		xPos = 420 - xPos;
		
		// start placing tiles
		const firstTile: Entity = meldedTiles.find(tileEntity => tileEntity.getComponent(TileNodesComponent).previous === null);
		let maxIter = 50;
		let tile = firstTile;
		while (tile && maxIter-- > 0) {
			xPos = this.alignTile(tile, player, new Vector3(xPos, 0, 0), this.getMeldedTileAdjByState);
			tile = tile.getComponent(TileNodesComponent).next;
		}
		if (maxIter === 0) {
			console.warn("error updating concealed tiles. max iter reached");
		}
	}
	
	/**
	 *
	 * @param flowerTiles -- sorted
	 */
	private updatePlayerFlowerPositions(player: Entity, flowerTiles: Array<Entity>) {
		if (flowerTiles.length === 0) {
			return;
		}
		let xPos = 0;
		const playerId: number = player.getComponent(PlayerIdComponent).playerId;
		const firstTile: Entity = flowerTiles.find(tileEntity => tileEntity.getComponent(TileNodesComponent).previous === null);
		let maxIter = 50;
		let tile = firstTile;
		while (tile && maxIter-- > 0) {
			xPos = this.alignTile(tile, player, new Vector3(xPos, 0, -TileDimensions.THG), this.getConcealedTileAdjByState);
			tile = tile.getComponent(TileNodesComponent).next;
		}
		if (maxIter === 0) {
			console.warn("error updating flower tiles. max iter reached");
		}
	}
	
	private isPositionsEqual(p1: THREE.Vector3, p2: THREE.Vector3, delta: number = 1): boolean {
		return Math.abs(p1.x - p2.x) < delta
			&& Math.abs(p1.y - p2.y) < delta
			&& Math.abs(p1.z - p2.z) < delta;
	}
	
	private getConcealedTileAdjByState(state: TileState, forceOpened: boolean = false): TilePosition {
		if (forceOpened) {
			return TilePositionTransform.CONCEALED.OPENED;
		}
		switch (state) {
			case TileState.TURNED_BACK:
				return TilePositionTransform.CONCEALED.NORMAL;
				break;
			case TileState.WRONG_MJ:
				return TilePositionTransform.CONCEALED.OPENED;
				break;
			/*case TileState.DEFINED_JOKER:
				console.warn("Define joker is not used anymore. Was used for concealed and melded tiles before.");
				return TilePositionTransform.CONCEALED.OPENED;
				break;*/
			case TileState.NORMAL:
			case TileState.HI:
			default:
				return TilePositionTransform.CONCEALED.OPENED;
		}
	}
	
	private getMeldedTileAdjByState(state: TileState, forceOpened: boolean = false) {
		if (forceOpened) {
			return TilePositionTransform.CONCEALED.OPENED;
		}
		switch (state) {
			case TileState.TURNED_BACK:
				return TilePositionTransform.CONCEALED.TURNED_BACK;
				break;
			case TileState.NORMAL:
			case TileState.HI:
				return TilePositionTransform.CONCEALED.OPENED;
				break;
			case TileState.WRONG_MJ:
				return TilePositionTransform.CONCEALED.OPENED;
				break;
			case TileState.DEFINED_JOKER:
				// console.warn("Define joker is not used anymore. Was used for concealed and melded tiles before.");
				return TilePositionTransform.CONCEALED.OPENED;
				break;
			case TileState.R_LEFT:
				return TilePositionTransform.CONCEALED.OPENED_LEFT;
				break;
			case TileState.MELD:
				return TilePositionTransform.CONCEALED.OPENED_LEFT;
				break;
			case TileState.R_RIGHT:
				return TilePositionTransform.CONCEALED.OPENED_RIGHT;
				break;
			case TileState.MELD_SANMA:
			default:
				return TilePositionTransform.CONCEALED.NORMAL;
		}
	}
	
	/**
	 * Sort player's concealed tiles
	 * @param playerId - Player id
	 * @param allTiles - All tiles
	 * @param [compareFn] - [optional] Custom sorting function (used to determine the order of the elements)
	 */
	public sortPlayerTiles(playerId: number, allTiles: Array<Entity>, compareFn?: TilesSortFn) {
		let concealedTiles = TileSetHelper.getPlayerConcealedTiles(playerId, allTiles);
		concealedTiles = concealedTiles.sort((a, b) => {
			const aTinyId = a.getComponent(TileDataComponent).tinyId;
			const bTinyId = b.getComponent(TileDataComponent).tinyId;
			return compareFn ? compareFn(aTinyId, bTinyId) : aTinyId - bTinyId;
		});
		
		const cLen = concealedTiles.length;
		if (cLen === 0) {
			return;
		}
		else {
			let previous: Entity = concealedTiles[0];
			previous.getMutableComponent(TileNodesComponent).reset();
			if (cLen > 1) {
				concealedTiles[cLen - 1].getMutableComponent(TileNodesComponent).next = null;
				for (let i = 1; i < cLen; i++) {
					const tile = concealedTiles[i];
					previous.getMutableComponent(TileNodesComponent).next = tile;
					tile.getMutableComponent(TileNodesComponent).previous = previous;
					previous = tile;
				}
			}
		}
	}
	
}
