import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-jackpot-message",
	templateUrl: "./jackpot-message.component.html",
	styleUrls: ["./jackpot-message.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JackpotMessageComponent implements OnInit {
	
	@Input()
	jpData: JackpotMessageData;
	
	constructor(
		public dialogRef: MatDialogRef<JackpotMessageComponent>,
		@Inject(MAT_DIALOG_DATA) data: JackpotMessageData,
		private translate: TranslateService) {
		this.setDialogData(data);
	}
	
	ngOnInit(): void {
	}
	
	htmlDecode(input) {
		const doc = new DOMParser().parseFromString(input, "text/html");
		return doc.documentElement.textContent;
	}
	
	public setDialogData(data: JackpotMessageData): void {
		this.jpData = data;
		this.jpData.title = this.htmlDecode(this.jpData.title);
		this.jpData.message = this.htmlDecode(this.jpData.message);
		console.log("JackpotMessageComponent.setDialogData: ", data);
	}
	
	onClick() {
		this.dialogRef.close();
	}
}

export type JackpotMessageData = {
	// jackpot#$#<jp_banner_type>@$@<jp_event_type>@$@<jp_title>@$@<jp_message>@$@<jp_bottom>
	// 10 - blue; 20 - brown
	bannerType: number;
	// not used. default=0, 10 (first jackpot winning), 20 (first cash game winning),  30 ...
	eventType: number;
	title: string;
	message: string;
	bottomLine: string;
};
