import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {TYPES} from "../../inversify/inversify.types";
import {UserStore} from "../../store/UserStore";
import container from "../../inversify/inversify.config";
import {NavigationService} from "../services/navigation.service";
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class AuthChildGuard implements CanActivateChild {
	private userStore: UserStore;
	
	constructor(public router: Router, private navService: NavigationService) {
		this.userStore = container.get<UserStore>(TYPES.UserStore);
	}
	
	/**
	 *
	 * @param next -- the next route that will be activated if the guard is allowing access,
	 * @param state -- the next router state if the guard is allowing access.
	 */
	canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
		if (environment.isDebug) {
			console.log(`AuthChildGuard: CanActivate: ${next.toString()}, state=${state.toString()}`);
			console.log("AuthChildGuard.canActivate: next=", next);
			console.log("AuthChildGuard.canActivate: state=", state);
		}
		
		if (!this.userStore.isAuthenticated()) {
			console.warn("AuthChildGuard.canActivateChild: can't activate: " + next?.url);
			return this.router.createUrlTree(["./login"], {queryParams: {ret: state.url}});
		}
		return true;
	}
}
