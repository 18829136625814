export class RewardPointsBonusDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.RewardPointsBonus";
	
	public BonusId: number;
	public PointsAmt: number;
	
	public UsdAmt: number;
	public UsdBonusPc: number;
	public UsdBonusAmt: number;
	
	public CoinAmt: number;
	public CoinBonusPc: number;
	public CoinBonusAmt: number;
}
