import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {IDeactivatable} from "../ui/IDeactivatable";

@Injectable({
	providedIn: "root",
})
export class LeaveGameGuard implements CanDeactivate<any> {
	
	canDeactivate(
		component: IDeactivatable,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const canDeactivate = component.canDeactivate();
		if (!canDeactivate) {
			console.warn("LeaveGameGuard.canDeactivate: cannot leave " + currentState.url + " for " + nextState?.url);
		}
		return canDeactivate;
	}
	
}
