import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {TYPES} from "../../inversify/inversify.types";
import {UserStore} from "../../store/UserStore";
import container from "../../inversify/inversify.config";
import {NavigationService} from "../services/navigation.service";
import {GameStore} from "../../store/GameStore";

@Injectable({
	providedIn: "root",
})
export class CanActivateChildGameGuard implements CanActivateChild {
	private userStore: UserStore;
	
	constructor(public router: Router, private navService: NavigationService) {
		this.userStore = container.get<UserStore>(TYPES.UserStore);
	}
	
	/**
	 *
	 * @param next -- the next route that will be activated if the guard is allowing access,
	 * @param state -- the next router state if the guard is allowing access.
	 */
	canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		return !!this.gameStore.joinedGame ? true : this.router.parseUrl("/lobby");
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
}
