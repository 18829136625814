import {ChatGamController} from "./ChatGamController";
import {ActionMoveType} from "./enums/ActionMoveType";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {GameEventsPipe, GameEventType} from "./GameEventsPipe";
import {GameAccessHelper} from "./helpers/GameAccessHelper";
import {GameStore} from "../store/GameStore";
import {inject, injectable} from "inversify";

import {GameGraphics} from "./GameGraphics";
import {GameService} from "../net/service/GameService";
import {Entity} from "ecsy";
import {GameStorageWrapper} from "./ecs/wrappers/GameStorageWrapper";
import {PlayersArray} from "./ecs/wrappers/PlayersArray";

@injectable()
export class NetGameManager {
	constructor(
		@inject(TYPES.GameAccessHelper) private gameAccessHelper: GameAccessHelper,
		@inject(TYPES.GameGraphics) private gameGraphics: GameGraphics,
		@inject(TYPES.GameService) private gameService: GameService,
		@inject(TYPES.UserStore) private userStore: UserStore,
		@inject(TYPES.GameStore) private gameStore: GameStore,
		@inject(TYPES.ChatGamController) private cgc: ChatGamController,
		@inject(TYPES.GameEventsPipe) private gameEvents: GameEventsPipe,
	) {
	
	}
	
	public async putTile(tileId: number, playerId: number, gameStorage: Entity): Promise<boolean> {
		try {
			console.log(`NetGameManager.putTile: START: tileId=${tileId}, playerId=${playerId}`);
			
			let success = false;
			const gameStorageW = GameStorageWrapper.wrapEntity(gameStorage);
	
			console.log('NetGameManager.putTile: calling iCanDiscardTile...');
			const accessResponse = this.gameAccessHelper.iCanDiscardTile(gameStorageW);
	
			
			
			if (accessResponse.result) {
				const move = {move: ActionMoveType.PUT_TILE, tileId, dealTurn: gameStorageW.dealState.dealTurn} as IMakeMove;
				
				
				this.sendAppEvent(GameEventType.MakeMove_Started, move);
				
				success = await this.makeMove2(move);
	
				if (success) {
					this.cgc.forceReRequest();
					this.sendAppEvent(GameEventType.MakeMove_Succeed, move);
					
				}
				else {
					this.sendAppEvent(GameEventType.MakeMove_Failed, move);				
				}
			}
			else {
				console.warn(`NetGameManager.putTile: Cannot put - ${accessResponse.errorMessage}`);
			}
	
			console.log(`NetGameManager.putTile: END: tileId=${tileId}, playerId=${playerId}, success=${success}`);
			return success;
		} catch (error) {
			console.error(`NetGameManager.putTile: Error - ${error.message}`);
			// You could also throw the error again so the caller knows something went wrong.
			throw error;
		}
	}
	
	public async putTiles(tileIds: number[], playerId: number, gameStorageW: GameStorageWrapper): Promise<boolean> {
		console.log(`NetGameManager.putTiles: playerId=${playerId} tile=${tileIds}`);
		// const currentPlayerId = this.gameStore.dealState.viewPlayerId; // this.userStore.id
		
		let ok = true;
		// if (playerId === currentPlayerId && this.gameAccessHelper.iCanThrowTile(gameStorageW.entity)) {
		try {
			const dealTurn = gameStorageW.dealState.dealTurn;
			const requests = tileIds.map(id => this.makeMove2({move: ActionMoveType.PUT_TILE, tileId: id, dealTurn}));
			// make move start
			gameStorageW.turnState.makingMove = true;
			const allRes = await Promise.all(requests);
			ok = !allRes.find(res => res === false);
			if (ok) {
				gameStorageW.turnState.waitingForNextMove = true;
				// TODO: clear player actions?
			}
		}
		catch (error) {
			console.error("putTiles(): error -- ", error);
		}
		// make move end
		gameStorageW.turnState.makingMove = false;
		return false;
	}
	
	private async __makeAction({action, tileId, gameStorageW, playersArr}: {
		action: ActionMoveType, tileId?: number, gameStorageW: GameStorageWrapper, playersArr: PlayersArray
	}): Promise<boolean> {
		let success = false;
		const accessResponse = this.gameAccessHelper.iCanMakeDeclaration(gameStorageW, playersArr);
		
		if (accessResponse.result) {
			const move = {move: action, tileId: (tileId ?? 0), dealTurn: gameStorageW.dealState.dealTurn} as IMakeMove;
			this.sendAppEvent(GameEventType.MakeMove_Started, move);
			success = await this.makeMove2(move);
			if (success) {
				this.cgc.forceReRequest();
				this.sendAppEvent(GameEventType.MakeMove_Succeed, move);
			}
			else {
				this.sendAppEvent(GameEventType.MakeMove_Failed, move);
			}
		}
		else {
			console.warn("NetGameManager.__makeAction: " + accessResponse.errorMessage);
		}
		return success;
	}
	
	public makeActionMove({action, tileId, gameStorageW, playersArr}: { action: ActionMoveType, tileId?: number, gameStorageW: GameStorageWrapper, playersArr: PlayersArray }): Promise<boolean> {
		console.log(`NetGameManager.makeActionMove: action=${action}`);
		return this.__makeAction({action, tileId, gameStorageW, playersArr});
	}
	
	/* AM: charleston moves do not have available actions */
	public async makeDeclaration(declaration: ActionMoveType, gameStorageW: GameStorageWrapper): Promise<boolean> {
		console.log(`NetGameManager.makeDeclaration: ${declaration}`);
		if (this.gameAccessHelper.iCanDeclareCharleston(gameStorageW.entity)) {
			const move = {move: declaration, tileId: 0, dealTurn: gameStorageW.dealState.dealTurn} as IMakeMove;
			this.sendAppEvent(GameEventType.MakeMove_Started, move);
			const success = await this.makeMove2(move);
			if (success) {
				this.cgc.forceReRequest();
				this.sendAppEvent(GameEventType.MakeMove_Succeed, move);
			}
			else {
				this.sendAppEvent(GameEventType.MakeMove_Failed, move);
			}
			return success;
		}
		else {
			console.warn(`NetGameManager.makeDeclaration: you cannot declare declaration`);
		}
		return false;
	}
	
	/* AM: charleston moves do not have available actions */
	public async declareDeadHand(toPlayerId: number, gameStorageW: GameStorageWrapper): Promise<boolean> {
		console.log(`NetGameManager.declareDeadHand -- toPlayerId=${toPlayerId}`);
		// TODO: check if declaration is allowed at this moment
		// if (this.gameAccessHelper.iCanDeclareCharleston(gameStorageW.entity)) {
		const move = {move: ActionMoveType.DEAD_HAND, tileId: toPlayerId, dealTurn: gameStorageW.dealState.dealTurn} as IMakeMove;
		this.sendAppEvent(GameEventType.MakeMove_Started, move);
		const success = await this.makeMove2(move);
		if (success) {
			this.cgc.forceReRequest();
			this.sendAppEvent(GameEventType.MakeMove_Succeed, move);
		}
		else {
			this.sendAppEvent(GameEventType.MakeMove_Failed, move);
		}
		return success;
	}
	
	/*private onKeyboardEvent(event: IKeyboardEvent): void {
		console.log(`onKey: type=${event.type}, key=${event.key}, code=${event.code}`);
		const cmd = this.commands.get(event.key);
		if (cmd) {
			cmd();
		}
	}*/
	
	/*public async makeMove(move: string, tileId: number = 0, gameStorage: Entity): Promise<IMakeMoveResult> {
		console.log(`NetGameManager.makeMove: move=${move} tile=${tileId}`);
		EntityHelper.setTurnState(gameStorage, {makingMove: true});
		const {success} = await this.gameService.makeMove(this.userStore.sessionKey,
			this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId, move, tileId, EntityHelper.getDealState(gameStorage).dealTurn);
		EntityHelper.setTurnState(gameStorage, {makingMove: false});
		const m = {move, tileId, dealTurn: EntityHelper.getDealState(gameStorage).dealTurn} as IMakeMove;
		
		this.gameEvents.send(success ? GameEventType.MakeMove_Succeed : GameEventType.MakeMove_Failed, m);
		return {success, move: m} as IMakeMoveResult;
	}*/
	
	public async makeMove(action: ActionMoveType, tileId: number, gameStorageW: GameStorageWrapper): Promise<boolean> {
		const move = {move: action, tileId, dealTurn: gameStorageW.dealState.dealTurn} as IMakeMove;
		// this.sendAppEvent(GameEventType.MakeMove_Started, move);
		gameStorageW.turnState.makingMove = true;
		const success = await this.makeMove2(move);
		if (success) {
			this.cgc.forceReRequest();
			// this.sendAppEvent(GameEventType.MakeMove_Succeed, move);
		}
		else {
			// this.sendAppEvent(GameEventType.MakeMove_Failed, move);
		}
		gameStorageW.turnState.makingMove = false;
		return success;
	}
	
	// Returns true or false. No exceptions.
	private async makeMove2(move: IMakeMove): Promise<boolean> {
		console.log(`NetGameManager.makeMove2: move=`, move);
		return this.gameService.makeMove(
			this.userStore.sessionKey,
			this.gameStore.joinedGame.RoomId,
			this.gameStore.joinedGame.GameId,
			move.move,
			move.tileId,
			move.dealTurn,
		).then(
			result => true,
			error => false
		);
	}
	
	private sendAppEvent(type: GameEventType, data?: unknown): void {
		this.gameEvents.send(type, data);
	}
	
	public replaceFlowers(flowersCount: number) {
		return this.gameService.replaceFlowers(this.userStore.sessionKey, this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId, flowersCount);
	}
}

export interface IMakeMoveResult {
	success: boolean;
	move: IMakeMove;
}

export interface IMakeMove {
	move: string;
	tileId: number;
	dealTurn: number;
}

