// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Core.Core.TourScoreResults"
export class TourScoreResultsDTO {
	public UserId: number;
	public UserName: string;
	public Points: number;
	public Top10TourPoints: number;
	public Top10GamePoints: number;
	public AverageTourPoints: number;
	public Rating: number;
	public GamesPlayed: number;
	public Place: number;
	
}
