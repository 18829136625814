import {Subject} from "rxjs";
import {injectable} from "inversify";

@injectable()
export class AppEventsPipe {
	public events: Subject<AppEvent> = new Subject<AppEvent>();
	
	public send(type: AppEventType.ShowSnack, data?: SnackMessage): void;
	public send(type: AppEventType.ShowSnack, data?: SnackResourceMessage): void;
	// public send(type: AppEventType.ShowSnack, data?: SnackData): void;
	public send(type: AppEventType, data?: any): void;
	public send(type: AppEventType, data?: any): void {
		this.events.next(new AppEvent(type, data));
	}
}

export class AppEvent {
	constructor(public type: AppEventType, public data?: any) {
		
	}
}

export enum AppEventType {
	SwitchFullscreen,
	ServiceError,
	NavigateTo,
	ShowSnack,
	ShowMessage,
	ShowTooltip,
	MoveTooltip,
	HideTooltip,
	Logout,
	SwitchLanguage,
	BugReport_Show,
	Settings_Show,
	Display_Not_Enough,
}

export interface IAppEventNavigateTo {
	route: string;
	params?: any;
}

export type SnackMessage = {
	message: string;
	duration?: number;
	
};
export type SnackResourceMessage = {
	rsKey: string;
	fallbackMessage: string;
	duration?: number;
};
export type SnackData = (SnackMessage & { rsKey?: never, fallbackMessage?: never; }) | (SnackResourceMessage & { message?: never });


/*export type SnackData2 = AllXOR<[SnackMessage, SnackResourceMessage]>;
export type SnackData3 = XOR<SnackMessage, SnackResourceMessage> & { duration?: number };
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = (T | U) extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
type AllXOR<T extends any[]> =
	T extends [infer Only] ? Only :
		T extends [infer A, infer B, ...infer Rest] ? AllXOR<[XOR<A, B>, ...Rest]> :
			never;*/

// { message: string, duration?: number, fallbackMessage?: never }
// | { rsKey: string, fallbackMessage?: string, duration?: number };
