import {Injectable} from "@angular/core";
import {Router, UrlTree} from "@angular/router";
import {NavPage} from "../enums/NavPage";
import {GameType} from "../../game/enums/GameType";

@Injectable({
	providedIn: "root"
})
export class NavigationService {
	
	public navigationAllowed = false;
	
	constructor(private router: Router) {
	}
	
	get routerRules(): string {
		return this.router.routerState.root.firstChild.snapshot.paramMap.get("gameType");
	}
	
	get routerGameType(): GameType {
		return GameType[this.routerRules] ?? null;
	}
	
	/**
	 *
	 * @param page
	 * @param extras -- game params
	 */
	public async navigate({page, extras}: { page: NavPage.EnterGame, extras: string });
	/**
	 * Navigame default
	 * @param page
	 * @param gameType
	 * @param queryParams
	 * @param extras
	 */
	public async navigate({page, gameType, queryParams, extras}: { page: NavPage, gameType?: GameType, queryParams?: object, extras?: any });
	public async navigate({page, gameType, queryParams, extras}: { page: NavPage, gameType?: GameType, queryParams?: object, extras?: any }) {
		console.log(`NavigationService.navigate: page=${page}, gameType=${gameType}, query=${queryParams}`);
		const rulesName = GameType[gameType] ?? this.gt ?? "NoRules";
		console.log("NavigationService.navigate: rulesName=" + rulesName);
		this.navigationAllowed = true;
		try {
			switch (page) {
				case NavPage.Root:
					await this.router.navigate([NavPage.Root]);
					break;
				case NavPage.Login:
					await this.router.navigate([NavPage.Login]);
					break;
				case NavPage.Table:
					await this.router.navigate([rulesName, NavPage.Table]);
					break;
				case NavPage.TableInGame:
					await this.router.navigate([NavPage.TableInGame], {...extras});
					break;
				case NavPage.Games:
					await this.router.navigate([rulesName, NavPage.Lobby, NavPage.Games], {queryParams});
					break;
				case NavPage.GamesExpert:
					await this.router.navigate([rulesName, NavPage.Lobby, NavPage.GamesExpert], {queryParams});
					break;
				case NavPage.CreatePrivate:
					await this.router.navigate([rulesName, NavPage.Lobby, NavPage.CreatePrivate], {queryParams});
					break;
				case NavPage.Tournaments:
					await this.router.navigate([rulesName, NavPage.Lobby, NavPage.Tournaments], {queryParams});
					break;
				case NavPage.Lobby:
					await this.router.navigate([rulesName, NavPage.Lobby], {queryParams});
					break;
				case NavPage.EnterGame:
					await this.router.navigate([NavPage.EnterGame, extras], {queryParams});
					break;
				case NavPage.Wizard:
					// default:
					await this.router.navigate([NavPage.Wizard]);
					break;
			}
		}
		catch (e) {
			console.warn("NavigationService.navigate: ", e);
		}
		this.navigationAllowed = false;
	}
	
	public async navigateByUrl(url: string | UrlTree) {
		this.router.navigateByUrl(url)
			.then(
				() => true,
				() => false
			);
	}
	
	private get gt() {
		return this.router.routerState.root.firstChild.snapshot.paramMap.get("gameType");
	}
	
}

