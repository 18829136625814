import {FileLoader, Object3D, Texture, TextureLoader} from "three";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {injectable} from "inversify";
import {environment} from "../../environments/environment";


@injectable()
export class TexturePack {
	
	public fontAtlasTexture: Texture;
	public fontAtlasRegions: { [regionName: string]: ITextureRegionData };
	public tileAtlasTexture: Texture;
	public tileAtlasRegions: { [regionName: string]: ITextureRegionData };
	public tileObj: Object3D;
	public readyObj: Object3D;
	public arrowObj: Object3D;
	
	private path = environment.texturesPath;
	private readonly fontAtlasFile = "FontAtlas.json";
	private readonly tilesAtlasFile = "TileAtlas.json";
	private readonly tileModelFile = "GameTile-2F-HK-Body.obj";
	private readonly arrowModelFile = "arrow.obj";
	private readonly readyModelFile = "Ready-bar.obj";
	
	public async loadTextTexture(): Promise<boolean> {
		console.log(`TexturePack.loadTextTexture: ${this.path} - ${this.fontAtlasFile}`);
		try {
			const {atlas: fontAtlas, texture: fontTexture} = await new TextureAtlasLoader().load(this.path, this.fontAtlasFile);
			this.fontAtlasRegions = fontAtlas.items;
			this.fontAtlasTexture = fontTexture;
			
			const {atlas: tilesAtlas, texture: tilesTexture} = await new TextureAtlasLoader().load(this.path, this.tilesAtlasFile);
			this.tileAtlasRegions = tilesAtlas.items;
			this.tileAtlasTexture = tilesTexture;
			
			console.log(`TexturePack.loadTextTexture: load tile obj: ${this.tileModelFile}`);
			this.tileObj = await ObjLoader.loadObj(this.path + this.tileModelFile);
			this.arrowObj = await ObjLoader.loadObj(this.path + this.arrowModelFile);
			this.readyObj = await ObjLoader.loadObj(this.path + this.readyModelFile);
			this.readyObj = this.readyObj.children[0];
		}
		catch (err) {
			console.error(`TexturePack.loadTextTexture: error=`, err);
			return false;
		}
		return true;
	}
}


export interface ITextureRegionData {
	x: number;
	y: number;
	width: number;
	height: number;
}

type IJsonAtlas = {
	file: string;
	items: { [char: string]: ITextureRegionData };
};

class TextureAtlasLoader {
	async load(path: string, jsonFile: string): Promise<{ atlas: IJsonAtlas, texture: Texture }> {
		const atlas = await this.loadJson(path + jsonFile);
		const texture = await this.loadTexture(path + atlas.file);
		return {atlas, texture};
	}
	
	loadJson(jsonPath: string): Promise<IJsonAtlas> {
		return new Promise((resolve, reject) => {
			const loader = new FileLoader();
			loader.load(jsonPath,
				(data) => { // onLoad callback
					resolve(JSON.parse(data as string)[0]);
				},
				(xhr) => { // onProgress callback
					// console.log((xhr.loaded / xhr.total * 100) + "% loaded");
				},
				(err) => { // onError callback
					reject("Error loading json file: " + err);
				}
			);
		});
	}
	
	loadTexture(path: string): Promise<Texture> {
		return new Promise((resolve, reject) => {
			new TextureLoader().load(path,
				(texture) => { // onLoad callback
					resolve(texture);
				},
				(xhr) => { // onProgress callback
					// console.log((xhr.loaded / xhr.total * 100) + "% loaded");
				},
				(err) => { // onError callback
					reject(err);
				});
		});
	}
}

class ObjLoader {
	static loadObj(path: string): Promise<Object3D> {
		return new Promise((resolve, reject) => {
			new OBJLoader().load(path,
				(object) => { // onLoad callback
					resolve(object);
				},
				(xhr) => { // onProgress callback
					// console.log((xhr.loaded / xhr.total * 100) + "% loaded");
				},
				(err) => { // onError callback
					reject("Error loading object file: " + err);
				}
			);
		});
	}
	
}
