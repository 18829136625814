import {Entity} from "ecsy";
import {
	ActionMenuDataComponent,
	ActionMenuGuiComponent,
	ActionMenuUIStateComponent,
	IsVisibleTagComponent,
	PlayerIdComponent
} from "../common/components";
import {ActionMenuItemGraphics} from "../../graphics/ActionMenuItemGraphics";
import {ActionMoveType} from "../../enums/ActionMoveType";

export class ActionMenuWrapper {
	
	constructor(public entity: Entity) {
	
	}
	
	public dispose(): void {
		this.entity.removeAllComponents();
		this.entity.remove();
	}
	
	public get id(): ActionMoveType {
		return this.entity.getComponent(ActionMenuDataComponent).id;
	}
	
	public set id(id) {
		this.entity.getMutableComponent(ActionMenuDataComponent).id = id;
	}
	
	public get label(): string {
		return this.entity.getComponent(ActionMenuDataComponent).label;
	}
	
	public set label(label) {
		this.entity.getMutableComponent(ActionMenuDataComponent).label = label;
	}
	
	public get declarationId(): ActionMoveType {
		return this.entity.getComponent(ActionMenuDataComponent).declarationId;
	}
	
	public set declarationId(possibleActions) {
		this.entity.getMutableComponent(ActionMenuDataComponent).declarationId = possibleActions;
	}
	
	public get playerId(): number {
		return this.entity.getComponent(PlayerIdComponent).playerId;
	}
	
	public set playerId(playerId) {
		this.entity.getMutableComponent(PlayerIdComponent).playerId = playerId;
	}
	
	public get graphics(): ActionMenuItemGraphics {
		return this.entity.getComponent(ActionMenuGuiComponent).obj;
	}
	
	public set graphics(obj) {
		this.entity.getMutableComponent(ActionMenuGuiComponent).obj = obj;
	}
	
	// **
	
	
	public get uiState(): "normal" | "over" | "clicked" {
		return this.entity.getComponent(ActionMenuUIStateComponent).state;
	}
	
	public set uiState(value) {
		this.entity.getMutableComponent(ActionMenuUIStateComponent).state = value;
	}
	
	public get isVisible(): boolean {
		return this.entity.hasComponent(IsVisibleTagComponent);
	}
	
	public set isVisible(value) {
		value ? this.entity.addComponent(IsVisibleTagComponent) : this.entity.removeComponent(IsVisibleTagComponent);
	}
	
	public toString(): string {
		return `[ActionMenuWrapper: {id=${this.id}, label=${this.label}, declarationId=${this.declarationId}, playerId=${this.playerId}, isVisible=${this.isVisible}, graphics=${this.graphics}]`;
	}
}
