import {Entity, World} from "ecsy";
import {TileType} from "../../enums/TileType";
import {WallTilesHelper} from "../../helpers/WallTilesHelper";
import {WallLayoutHelper} from "../../helpers/WallLayoutHelper";
import {DiscardsLayoutHelper} from "../../helpers/DiscardsLayoutHelper";
import {DiscardsTilesHelper} from "../../helpers/DiscardsTilesHelper";
import {PlayerLayoutHelper} from "../../helpers/PlayerLayoutHelper";
import {GameEventsPipe, GameEventType} from "../../GameEventsPipe";
import {TileEntityHelper} from "../../helpers/TileEntityHelper";
import {
	PlayerActionsComponent,
	PlayerIdComponent,
	PlayerStoreComponent,
	TileDataComponent,
	TileState,
	TileTypeComponent
} from "./components";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {GameStorageWrapper, IDealState} from "../wrappers/GameStorageWrapper";
import {IEndGameDialogData} from "../../../app/ui/lobby/table/ingame/EndGameDialogComponent";
import {PlayersArray} from "../wrappers/PlayersArray";
import {MoveType} from "../../enums/MoveType";
import {GameMessageDTO} from "../../../net/dto/GameMessageDTO";
import {TilesSortFn} from "../../interfaces/TilesSortFn";
import {TileWrapper} from "../wrappers/TileWrapper";
import {ICanMakeMove} from "../../interfaces/ICanMakeMove";
import {DiscardsMode} from "../../enums/DiscardsMode";
import {TileSetHelper} from "../../helpers/TileSetHelper";
import {TileDataHelper} from "../../helpers/TileDataHelper";
import {IGameDialogConfig} from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {IRulesOptions} from "./GameRulesAbstractSystem";
import {Side} from "../../enums/Side";
import {commander} from "../../../commander/Commander";
import {Commands} from "../../../commands/Commands";
import {GameStore} from "../../../store/GameStore";
import {AppEventsPipe, AppEventType} from "../../AppEventsPipe";
import {SoundsHelper} from "../../helpers/SoundsHelper";
import {Sound} from "../../enums/Sound";

export interface IGameAction {
	execute();
}

export class WallToConcealedAction implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}

	execute() {
		try {
			console.log('WallToConcealedAction: execute start. tileId:', this.tileId, 'playerId:', this.playerId, 'tileSet:', this.tileSet);
			const tileEntity = WallTilesHelper.getTile(this.tileSet);
			console.log('WallToConcealedAction: got tile entity:', tileEntity);

			if (tileEntity) {
				TileSetHelper.addTile({
					tile: tileEntity,
					playerId: this.playerId,
					tileId: this.tileId,
					tileType: TileType.CONCEALED,
					tiles: this.tileSet
				});
				console.log('WallToConcealedAction: tile successfully added');
			} else {
				console.error('WallToConcealedAction: no tileEntity found');
			}

			console.log('WallToConcealedAction: execute end.');
		} catch (error) {
			console.error('WallToConcealedAction: an error occurred in execute:', error);
		}
	}
}

export class WallEndToConcealedAction implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		const tileEntity = WallTilesHelper.getTileFromEnd(WallTilesHelper.getWallTiles(this.tileSet));
		TileEntityHelper.markAsDeadWall(tileEntity, false);
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: this.tileId,
				tileType: TileType.CONCEALED,
				tiles: this.tileSet
			});
		}
	}
}

export class WallToMeldedAction implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private meldedGroup: number) {
	}
	
	execute() {
		const tileEntity = WallTilesHelper.getTile(this.tileSet);
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: this.tileId,
				tileType: TileType.MELDED,
				tiles: this.tileSet,
				meldedGroup: this.meldedGroup
			});
		}
	}
}

export class WallEndToMeldedAction implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private meldedGroup: number) {
	}
	
	execute() {
		const tileEntity = WallTilesHelper.getTileFromEnd(WallTilesHelper.getWallTiles(this.tileSet));
		
		if (tileEntity) {
			TileEntityHelper.markAsDeadWall(tileEntity, false);
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: this.tileId,
				tileType: TileType.MELDED,
				tiles: this.tileSet,
				meldedGroup: this.meldedGroup
			});
		}
	}
}

export class WallToFlowerAction implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private meldedGroup: number) {
	}
	
	execute() {
		const tileEntity = WallTilesHelper.getTile(this.tileSet);
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: this.tileId,
				tileType: TileType.FLOWER,
				tiles: this.tileSet,
				meldedGroup: this.meldedGroup
			});
		}
	}
}

export class WallEndToFlowerAction implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private meldedGroup: number) {
	}
	
	execute() {
		const tileEntity = WallTilesHelper.getTileFromEnd(WallTilesHelper.getWallTiles(this.tileSet));
		TileEntityHelper.markAsDeadWall(tileEntity, false);
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: this.tileId,
				tileType: TileType.FLOWER,
				tiles: this.tileSet,
				meldedGroup: this.meldedGroup
			});
		}
	}
}

export class ConcealedToMelded implements IGameAction {
	/**
	 * Moves tile from concealed to melded. Has 'closed' option to keep 2xx tile closed after moving
	 * @param tileId
	 * @param playerId
	 * @param tileSet
	 * @param meldedGroup
	 * @param closed -- boolean. Whether the melded tile should be treated as closed tile (closed kong). The way the highlight (2xx) will be removed depend on this parameter.
	 */
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private meldedGroup: number, private closed: boolean = false) {
	}
	
	execute() {
		/*
		* After removing highlight from  closed tile it should remain closed - and closed state should be set manually.
		* By ex.: in kong self: FC2M_HIDDEN: 151^251^151^151 -- 251 should be converted to 151 and not 51
		* */
		const tileEntity = TileSetHelper.retrieveConcealedTile(this.playerId, this.tileId, this.tileSet);
		let tileIdNoHi = this.tileId;
		if (TileDataHelper.getTileState(this.tileId) === TileState.HI) {
			tileIdNoHi = TileDataHelper.removeHighlightState(this.tileId);
			if (this.closed) {
				tileIdNoHi = TileDataHelper.setState(tileIdNoHi, TileState.TURNED_BACK);
			}
		}
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				// tileId: TileDataHelper.removeHighlightState(this.tileId),
				tileId: tileIdNoHi,
				// tileId: TileDataHelper.getTileState(this.tileId)===TileState.HI
				// 	? (this.closed ? this.tileId - 100 : TileDataHelper.removeHighlightState(this.tileId)),
				tileType: TileType.MELDED,
				tiles: this.tileSet,
				meldedGroup: this.meldedGroup
			});
		}
	}
}

export class ConcealedToFlower implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private meldedGroup: number) {
	}
	
	execute() {
		const tileEntity = TileSetHelper.retrieveConcealedTile(this.playerId, this.tileId, this.tileSet);
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				tileId: TileDataHelper.removeHighlightState(this.tileId),
				tileType: TileType.FLOWER,
				tiles: this.tileSet,
				meldedGroup: this.meldedGroup
			});
		}
	}
}

/**
 * Move tile from player's concealed tiles to discard's slot.
 * If there is a tile already in slot (moved by ConcealedToSlotPrepare action) - just remove prepare flag.
 */
export class ConcealedToSlot implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
		//console.log('ConcealedToSlot: constructed with tileId=', this.tileId, ', playerId=', this.playerId, ', tileSet=', this.tileSet);
	}
	
	execute() {
		console.log('ConcealedToSlot: starting execution...');
		const slotTile = DiscardsTilesHelper.getSlotTile(this.tileSet);
		if (slotTile) {
			console.log("ConcealedToSlot: there is a tile in slot", slotTile);
			if (TileEntityHelper.getFullId(slotTile) === this.tileId) { // a required tile is already in slot. just remove prepare to throw flag
				console.log('ConcealedToSlot: required tile is already in slot, removing prepare to throw flag');
				TileEntityHelper.removePrepareToThrow(slotTile);
			}
			else { // there is a slot tile, but with different id. this tile should be returned to player before actual processing of C2S move
				console.warn(`ConcealedToSlot.execute: there is a different tile in slot.\n	slot: ${TileWrapper.wrap(slotTile)}\n	tile: id=${this.tileId} player=${this.playerId}`);
				new ConcealedToSlotPrepareCancel(this.tileSet).execute();
			}
		}
		else { // regular flow if there is no prepared (or any other) tile in slot
			console.log('ConcealedToSlot: no tile in slot, starting regular flow...');
			const tileEntity = TileSetHelper.retrieveConcealedTile(this.playerId, this.tileId, this.tileSet);
			if (tileEntity) {
				console.log('ConcealedToSlot: retrieved tile from concealed tiles, adding it to the slot', tileEntity);
				DiscardsTilesHelper.addTile(tileEntity, this.tileId, TileType.SLOT, this.tileSet);
			} else {
				console.warn('ConcealedToSlot: could not find the tile in the concealed tiles');
			}
		}
		console.log('ConcealedToSlot: execution finished');
	}
}

/**
 * Move a tile from player's concealed set to slot and mark the tile as prepared to throw. Used to react instantly on user's tile click.
 * - ConcealedToSlot() will be called on server move;
 * - ConcealedToSlotPrepareCancel() is used to move back prepared tile to player's hand
 */
export class ConcealedToSlotPrepare implements IGameAction {
	constructor(private tileEntity: Entity, private tileSet: Entity[]) {
	}
	
	execute() {
		try {
			if (this.tileEntity) {
				console.log("ConcealedToSlotPrepare: tileEntity is defined. Preparing to throw...");
				TileEntityHelper.setPrepareToThrow(this.tileEntity);
				const tileId = this.tileEntity.getComponent(TileDataComponent).fullId;
				console.log(`ConcealedToSlotPrepare: tileId=${tileId}. Adding tile to slot...`);
				DiscardsTilesHelper.addTile(this.tileEntity, tileId, TileType.SLOT, this.tileSet);
				console.log("ConcealedToSlotPrepare: tile successfully added to slot");
			} else {
				console.error("ConcealedToSlotPrepare: tileEntity is not defined");
			}
	
			console.log('ConcealedToSlotPrepare: Entire incoming tileSet: ', this.tileSet);
	
		} catch (error) {
			console.error("ConcealedToSlotPrepare: Error in execute: ", error);
		}
	}
}

/**
 * Used to move back prepared tile from slot to player's hand.
 */
export class ConcealedToSlotPrepareCancel implements IGameAction {
	constructor(private tileSet: Entity[]) {
	}
	
	execute() {
		const slotTile = DiscardsTilesHelper.getSlotTile(this.tileSet);
		if (slotTile) {
			TileEntityHelper.removePrepareToThrow(slotTile);
			TileSetHelper.addTile({
				tile: slotTile,
				tileType: TileType.CONCEALED,
				tiles: this.tileSet
			});
		}
		else {
			console.warn("ConcealedToSlotPrepareCancel: there is no tile in slot to move back");
		}
	}
}

export class SlotToDiscards implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		const tileEntity = DiscardsTilesHelper.getSlotTile(this.tileSet);
		if (tileEntity) {
			DiscardsTilesHelper.addTile(tileEntity, this.tileId, TileType.DISCARD, this.tileSet);
		}
	}
}

export class SlotToMelded implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private meldedGroup: number) {
	}
	
	execute() {
		const tileEntity = DiscardsTilesHelper.getSlotTile(this.tileSet);
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: this.tileId,
				tileType: TileType.MELDED,
				tiles: this.tileSet,
				meldedGroup: this.meldedGroup
			});
		}
		else {
			console.warn("SlotToMelded.execute: cannot find slot tile: " + this.tileId);
		}
	}
}

export class SlotToMeldedPromoted implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		const tileEntity = DiscardsTilesHelper.getSlotTile(this.tileSet);
		const anchorTile = TileSetHelper.getLastMeldedForPromotedKong(this.playerId, this.tileId, this.tileSet);
		if (tileEntity && anchorTile) {
			const tileState = TileDataHelper.getTileState(this.tileId);
			// TODO: 9xx tile should be placed in the second row of melds (above 5xx, 6xx or 7xx)
			const tileIdNoState = (tileState === TileState.HI || tileState === TileState.MELD_SANMA)
				? TileDataHelper.clearTileState(this.tileId)
				: this.tileId;
			
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: tileIdNoState,
				tileType: TileType.MELDED,
				tiles: this.tileSet,
				
				meldedGroup: anchorTile.meldedGroup,
				afterTile: anchorTile.entity,
			});
		}
		else {
			console.warn(`SlotToMeldedPromoted.execute: both tile and anchor required: tile=${tileEntity}, anchor=${anchorTile}`);
		}
	}
}


export class SlotToConcealed implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		const tileEntity = DiscardsTilesHelper.getSlotTile(this.tileSet);
		if (tileEntity) {
			TileSetHelper.addTile({
				tile: tileEntity,
				playerId: this.playerId,
				tileId: this.tileId,
				tileType: TileType.CONCEALED,
				tiles: this.tileSet
			});
		}
	}
}

export class MeldedToSlot implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		const tileEntity = TileSetHelper.retrieveMeldedTile(this.playerId, this.tileId, this.tileSet);
		if (tileEntity) {
			DiscardsTilesHelper.addTile(tileEntity, this.tileId, TileType.SLOT, this.tileSet);
		}
	}
}


export class UpdatePlayerTilesAction implements IGameAction {
	constructor(private player: Entity, private tilesType: TileType, private plh: PlayerLayoutHelper, private tileSet: Entity[]) {
	}
	
	execute() {
		this.plh.updatePlayerTilesPositions(this.player, this.tileSet, this.tilesType);
	}
}

export class UpdateWallTiles implements IGameAction {
	constructor(private tileSet: Entity[], private singleWallLength: number, private tilesSubSetType: TileSubSetType = TileSubSetType.ALL_TILES) {
	}
	
	execute() {
		switch (this.tilesSubSetType) {
			case TileSubSetType.LAST_TILE_ONLY:
				WallLayoutHelper.updateLastTilePosition(WallTilesHelper.getWallTiles(this.tileSet), this.singleWallLength);
				break;
			case TileSubSetType.FIRST_TILE_ONLY:
				WallLayoutHelper.updateFirstTilePosition(WallTilesHelper.getWallTiles(this.tileSet), this.singleWallLength);
				break;
			default:
				WallLayoutHelper.updateTilesPositions(WallTilesHelper.getWallTiles(this.tileSet), this.singleWallLength);
		}
	}
}

export class UpdateDeadWall implements IGameAction {
	constructor(private tileSet: Entity[], private gameStorage: GameStorageWrapper) {
	}
	
	execute() {
		WallTilesHelper.updateDeadWall(WallTilesHelper.getWallTiles(this.tileSet), this.gameStorage.tableOptions.rulesSettings.deadWallLength);
	}
}


export class UpdateDiscardsTiles implements IGameAction {
	/**
	 * Updates discard tiles transform
	 * @param tileSet -- all tiles
	 * @param discardsMode -- Common or Separate
	 * @param playerId -- Required for Separate mode only
	 * @param players -- Required for Separate mode only
	 */
	constructor(private tileSet: Entity[], private discardsMode: DiscardsMode, private playerId?: number, private players?: PlayersArray) {
	}
	
	execute() {
		this.discardsMode === DiscardsMode.COMMON
			? DiscardsLayoutHelper.updateTilesPositions_Common(this.tileSet)
			: DiscardsLayoutHelper.updateTilesPositions_Separate(this.tileSet, this.playerId, this.players);
	}
}

export class UpdateSlotTile implements IGameAction {
	constructor(private tileSet: Entity[], private discardsMode: DiscardsMode, private players?: PlayersArray) {
	}
	
	execute() {
		try {
			console.log("UpdateSlotTile: Initial state of this:", this);

			console.log(`UpdateSlotTile: discardsMode=${this.discardsMode}`);
			if (this.discardsMode === DiscardsMode.COMMON) {
				console.log("UpdateSlotTile: Updating slot tile position in COMMON mode...");
				DiscardsLayoutHelper.updateSlotTilePosition_Common(this.tileSet);
			} else {
				console.log("UpdateSlotTile: Updating slot tile position in SEPARATE mode...");
				DiscardsLayoutHelper.updateSlotTilePosition_Separate(this.tileSet, this.players);
			}

			console.log("UpdateSlotTile: Final state of this:", this);
		} catch (error) {
			console.error("UpdateSlotTile: Error in execute: ", error);
		}
	}
}

export class RemoveHighLight implements IGameAction {
	constructor(private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		TileSetHelper.removeHighLightFor(this.playerId, TileType.CONCEALED, this.tileSet);
	}
}

export class UserCanMakeMove implements IGameAction {
	constructor(private pe: Entity, private moves: ICanMakeMove[]) {
	}
	
	execute() {
		this.pe.getMutableComponent(PlayerActionsComponent).actions = this.moves;
	}
}

export class UpdateDisplayedMoveNow implements IGameAction {
	/**
	 * @param playerId -- take a note that 1 is used by System
	 * @param gameStore -- GameStore
	 */
	constructor(private type: "slot" | "player", private playerId: number, private gameStorage: GameStorageWrapper) {
	}
	
	execute() {
		// this.gameStorage.
		// EntityHelper.setMoveNow(this.gameStorage, {playerId: this.playerId, type: "player"});
	}
}

export class MakeMove implements IGameAction {
	constructor(private playerId: number, private move: ActionMoveType, private playerEntity: Entity) {
	}
	
	execute() {
		this.playerEntity.getMutableComponent(PlayerStoreComponent).meldedGroup++;
	}
}

export class UpdateMoveNow implements IGameAction {
	constructor(private playerId: number, private gameStorage: GameStorageWrapper) {
	}
	
	execute() {
		// TODO: UpdateMoveNow action
		// actual values are set in commands. Here can be set displayed values
		// EntityHelper.setMoveNow(this.gameStorage, {playerId: this.playerId, type: "player"});
	}
}

export class DealTurnAction implements IGameAction {
	constructor(private dealTurn: number, private gameStorage: GameStorageWrapper) {
	}
	
	execute() {
		this.gameStorage.dealState.dealTurn = this.dealTurn;
		this.gameStorage.turnState.waitingForNextMove = false;
	}
}


export class StartDealAction implements IGameAction {
	constructor(private params: {
		gameStorage: GameStorageWrapper,
		players: PlayersArray,
		appEventsPipe: AppEventsPipe,
		w: World,
	}) {
	}
	
	execute() {
		const imEastOnStart = this.params.players.getPlayerById(this.params.gameStorage.myPlayerId)?.realside === Side.East;
		if (imEastOnStart) {
			SoundsHelper.playSound(this.params.w, Sound.GAME.TIMER);
			this.params.appEventsPipe.send(AppEventType.ShowSnack, {
				message: "Deal Started. Discard a tile to start the game",
				duration: 6000
			});
		}
		else {
			this.params.appEventsPipe.send(AppEventType.ShowSnack, {
				rsKey: "Game.Message.DealStarted",
				fallbackMessage: "Deal Started",
				duration: 3000
			});
		}
		
		
	}
}

export class EndDealAction implements IGameAction {
	constructor(private gameEvents: GameEventsPipe, private gameStorage: GameStorageWrapper, private players: PlayersArray) {
	}
	
	execute() {
		this.gameEvents.send(GameEventType.DealEnded, {
			dealNum: this.gameStorage.dealState.dealNum,
			storage: this.gameStorage,
			players: this.players,
		} as IEndGameDialogData);
	}
}

/*export class SetTimerAction implements IGameAction {
	constructor(private moveType: MoveType, private gameStorage: GameStorageWrapper, private players: PlayersArray) {
	}
	
	execute() {
		let seconds = 0;
		switch (this.moveType) {
			case MoveType.FROM_SLOT_TO_MELDED:
				seconds = 13;
				break;
		}
		this.gameStorage.timer.setTimer(seconds, new Date().getTime(), this.gameStorage.dealState.dealTurn);
	}
}*/
export class GameMoveAction implements IGameAction {
	constructor(public gameMessage: GameMessageDTO, private gameStorage: GameStorageWrapper) {
	}
	
	execute() {
		/*let seconds = 0;
		switch (this.moveType) {
			case MoveType.FROM_SLOT_TO_MELDED:
				seconds = 13;
				break;
		}
		this.gameStorage.timer.setTimer(seconds, new Date().getTime(), this.gameStorage.dealState.dealTurn);*/
	}
}

export class ActivateTimerAction implements IGameAction {
	constructor(private moveType: MoveType, private gameStorage: GameStorageWrapper) {
	}
	
	execute() {
		/*let seconds = 0;
		switch (this.moveType) {
			case MoveType.FROM_SLOT_TO_MELDED:
				seconds = 13;
				break;
		}
		this.gameStorage.timer.setTimer(seconds, new Date().getTime(), this.gameStorage.dealState.dealTurn);*/
	}
}

export class DeactivateTimerAction implements IGameAction {
	constructor(private moveType: MoveType, private gameStorage: GameStorageWrapper) {
	}
	
	execute() {
		/*let seconds = 0;
		switch (this.moveType) {
			case MoveType.FROM_SLOT_TO_MELDED:
				seconds = 13;
				break;
		}
		this.gameStorage.timer.setTimer(seconds, new Date().getTime(), this.gameStorage.dealState.dealTurn);*/
	}
}

/*export class EndGameAction implements IGameAction {
	constructor(private gameEvents: GameEventsPipe) {
	}
	
	execute() {
		this.gameEvents.send(GameEventType.);
	}
}*/

export class ForcedEndDealAction implements IGameAction {
	constructor(private gameEvents: GameEventsPipe) {
	}
	
	execute() {
		this.gameEvents.send(GameEventType.DealEnded);
	}
}

export class SortTilesAction implements IGameAction {
	constructor(private playerId: number, private plh: PlayerLayoutHelper, private tileSet: Entity[], private sortFn?: TilesSortFn) {
	}
	
	execute() {
		this.plh.sortPlayerTiles(this.playerId, this.tileSet, this.sortFn);
	}
}


export class ShowTilesConcealed implements IGameAction {
	constructor(private playerId: number, private showTileIds: number[], private tileSet: Entity[]) {
	}
	
	execute() {
		const playerCTiles = this.tileSet.filter(tileEntity => tileEntity.getComponent(PlayerIdComponent).playerId === this.playerId
			&& tileEntity.getComponent(TileTypeComponent).value === TileType.CONCEALED);
		
		this.showTileIds
			.forEach(tileId => {
				// trying to get tile bu fullId, then tinyId, closed tile with tinyId=1, and any still not opened tile otherwise
				const te = TileEntityHelper.getTileEntityNotForceOpened({tileId, tileSet: playerCTiles})
					?? TileEntityHelper.getTileEntityNotForceOpened({tileTinyId: tileId % 100, tileSet: playerCTiles})
					?? TileEntityHelper.getTileEntityNotForceOpened({tileTinyId: 1, tileSet: playerCTiles})
					?? TileEntityHelper.getTileEntityNotForceOpened({tileSet: playerCTiles});
				if (te) {
					TileEntityHelper.changeId(te, tileId);
					TileEntityHelper.setForceOpened(te);
				}
				else {
					console.warn(`ShowTilesConcealed: Cannot find tile ${tileId} at player ${this.playerId} concealed tiles. Available only: ${TileSetHelper.getTileIds(playerCTiles)}`);
				}
			});
	}
}

export class ShowTilesExposed implements IGameAction {
	constructor(private playerId: number, private showTileIds: number[], private tileSet: Entity[]) {
	}
	
	execute() {
		const playerMFTiles = this.tileSet
			.filter(tileEntity => tileEntity.getComponent(PlayerIdComponent).playerId === this.playerId
				&& (tileEntity.getComponent(TileTypeComponent).value === TileType.MELDED || tileEntity.getComponent(TileTypeComponent).value === TileType.FLOWER)
			);
		
		this.showTileIds
			.forEach(tileId => {
				const tileEntity = TileEntityHelper.getTileEntityNotForceOpened({tileId, tileSet: playerMFTiles});
				if (tileEntity) {
					TileEntityHelper.setForceOpened(tileEntity);
				}
				else {
					console.warn(`ShowTilesConcealed: Cannot find tile ${tileId} at player ${this.playerId} concealed tiles. Available only: ${TileSetHelper.getTileIds(playerMFTiles)}`);
				}
			});
	}
}

export class RemoveMultipleChoiceDialog implements IGameAction {
	constructor(private gameEvents: GameEventsPipe) {
	}
	
	execute() {
		this.gameEvents.send<IGameDialogConfig>(GameEventType.Remove_GameDialog, {id: "MultipleChoiceDialog"});
	}
}

export class UpdateDealState implements IGameAction {
	constructor(private state: Partial<IDealState>, private gameStorageQW: GameStorageWrapper) {
	
	}
	
	execute() {
		this.gameStorageQW.dealState.update(this.state);
	}
}

export class UpdatePlayersPointsAction implements IGameAction {
	constructor() {
	
	}
	
	execute() {
		commander.executeCommand(Commands.UPDATE_PLAYER_POINTS);
	}
}

export class ChangePlayerPointsAction implements IGameAction {
	constructor(private playerId: number, private difference: number, private gameStore: GameStore) {
	
	}
	
	execute() {
		const player = this.gameStore.gameUsers.getUserById(this.playerId);
		this.gameStore.gameUsers.updateUser(this.playerId, {Points: player.Points + this.difference});
	}
}


export class NewDealAction implements IGameAction {
	constructor(private opts: { tiles: Entity[], gameStorageQW: GameStorageWrapper, gameEvents: GameEventsPipe }) {
	
	}
	
	execute() {
		this.opts.tiles
			.map(tileEntity => TileWrapper.wrap(tileEntity))
			.reduce((prev, curr, index, array) => {
				curr.id = 1;
				curr.playerId = 0;
				curr.type = TileType.WALL;
				curr.meldedGroup = 0;
				curr.inHandIndex = index;
				if (prev) {
					// TileEntityHelper.linkTilesNodes()
					prev.nodes.next = curr.entity;
					curr.nodes.previous = prev.entity;
				}
				else {
					curr.nodes.previous = null;
				}
				curr.nodes.next = null;
				TileEntityHelper.markAsDeadWall(curr.entity, false);
				TileEntityHelper.removeForceOpened(curr.entity);
				TileEntityHelper.removePrepareToThrow(curr.entity);
				return curr;
			}, null);
		
		this.opts.gameEvents.send(GameEventType.DealEnded_RemovePopup);
	}
}

export class DealThrownDice implements IGameAction {
	constructor(private opts: { dices: string, tiles: Entity[], tableOptions: IRulesOptions, players: PlayersArray }) {
	
	}
	
	execute() {
		const dealDiceCalcFn = this.opts.tableOptions.dealDiceCalcFn;
		const realEastPlayer = this.opts.players.getPlayerByRealside(Side.East);
		
		WallTilesHelper.setBreakPoint(
			WallTilesHelper.getWallTiles(this.opts.tiles),
			dealDiceCalcFn(this.opts.dices, this.opts.tableOptions.singleWallLength, realEastPlayer.location),
			this.opts.tableOptions.singleWallLength * 8,
			this.opts.tableOptions.hasDeadWall,
		);
	}
}


export class SystemPrivateMessage implements IGameAction {
	constructor(private opts: { gameMessage: GameMessageDTO, gameStorageQW: GameStorageWrapper, appEvents: AppEventsPipe }) {
	}
	
	execute() {
		if (!this.opts || this.opts.gameMessage.UserId !== this.opts.gameStorageQW.myPlayerId) {
			return;
		}
		switch (this.opts.gameMessage.Message) {
			case "Kick":
				// Game.Message.YouWereKicked
				this.opts.appEvents.send(AppEventType.ShowMessage, {rsKey: "Game.Message.YouWereKicked"});
				// this.gameService.leave(this.userStore.sessionKey, this.gameStore.joinedGame.RoomId, this.gameStore.joinedGame.GameId)
				// 	.catch(e => console.warn("GameUIService.leaveGame: error:" + e));
				commander.executeCommand(Commands.EXIT_GAME)
					.catch(e => console.error("SystemPrivateMessage.: " + e));
				break;
			case "KickWarning":
				
				break;
		}
	}
}


export class EmptyAction implements IGameAction {
	constructor() {
	
	}
	
	execute() {
	
	}
}


export enum TileSubSetType {
	ALL_TILES = "all_tiles",
	LAST_TILE_ONLY = "only_end_tiles",
	FIRST_TILE_ONLY = "only_live_tiles",
}
