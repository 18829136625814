import {ChatMessagesDTO} from "../dto/ChatMessagesDTO";
import {ChatService} from "./ChatService";

export class FakeChatService extends ChatService {
	
	public async join(sessionKey: string, chatId: number): Promise<number> {
		return 0;
	}
	
	public async chatGetMessages(sessionKey: string, chatId: number, peak: number): Promise<ChatMessagesDTO> {
		const dto = new ChatMessagesDTO();
		dto.Public = [];
		dto.Private = [];
		return dto;
	}
	
}

