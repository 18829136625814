import {inject, injectable} from "inversify";
import {action, observable} from "mobx";
import {UserInfoStoreDTO} from "../net/dto/UserInfoStoreDTO";
import {TYPES} from "../inversify/inversify.types";
import {ISession} from "../inversify/inversify.config";
import {CurrencyType} from "../game/enums/CurrencyType";
import {computedFn} from "mobx-utils";
import {AccountUtil} from "../utils/AccountUtil";

/**
 * Currently logged user data
 */
@injectable()
export class UserStore {
	// @observable
	private _id = 0;
	private _sessionKey: string;
	@observable
	public info: UserInfoStoreDTO = new UserInfoStoreDTO();
	
	
	@observable
	localSettings = {};
	
	constructor(@inject(TYPES.SessionKey) private session: ISession) {
	}
	
	getBalance = computedFn((currency: CurrencyType): number => {
		return AccountUtil.getBalance(this.info.Account, currency);
	});
	
	isAuthenticated() {
		return this.sessionKey ?? false;
	}
	
	/**/
	
	/*public getBalance(currency: CurrencyType): number {
		return 11;//AccountUtil.getBalance(this.info.Account, currency);
	}*/
	
	get settings(): LocalSettings {
		const toBoolean = (value, defaultValue) => {
			return value ? (value === "true") : defaultValue;
		};
		
		const s = {
			autoReplaceFlowersCO: toBoolean(localStorage.getItem("settings.autoReplaceFlowersCO"), true),
			sndMaster: toBoolean(localStorage.getItem("settings.sndMaster"), true),
			playedAGame: toBoolean(localStorage.getItem("settings.playedAGame"), false),
		};
		
		return Object.assign(this.localSettings, s);
		
	}
	
	// *** Computed *******************************************************
	// @computed
	public get id(): number {
		return this._id;
	}
	
	public get sessionKey(): string {
		return this._sessionKey;
	}
	
	// *** Actions *******************************************************
	@action
	setUserData(userId, sessionKey) {
		this._id = userId;
		this._sessionKey = sessionKey;
		this.session.sessionKey = sessionKey;
	}
	
	@action
	setUserInfo(dto: UserInfoStoreDTO) {
		this.info = dto;
		this._id = dto.UserId; // _id is 0 initially if user is logged in as a guest
	}
	
	@action
	logout() {
		this._id = 0;
		this._sessionKey = null;
		this.session.sessionKey = null;
	}
	
	@action
	setSettings(params: Partial<LocalSettings>) {
		if (!params) {
			return;
		}
		Object.entries(params).forEach(([key, value]) => {
			localStorage.setItem("settings." + key, "" + value);
		});
	}
}

type LocalSettings = {
	autoReplaceFlowersCO: boolean;
	sndMaster: boolean;
	playedAGame: boolean;
};
