// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.Tournament"
import {TournamentWinnerDTO} from "./TournamentWinnerDTO";
import {CurrencyType} from "../../../game/enums/CurrencyType";

export class TournamentDTO {
	public CurrentRound: number;
	public DeclarePoints: number;
	public Distribution: string;
	public EnableAutoPass: boolean;
	public EntryFee: number;
	public EntryFeeCurrencyId: CurrencyType;
	public FinishDate: Date;
	public Flowers: boolean;
	public GameRoundsCount: number;
	public GameTypeId: number;
	public IsJoined: boolean;
	public IsMoney: boolean;
	public IsRated: boolean;
	public MinBelt: number;
	public Name: string;
	public PlayersCount: number;
	public PlayTimeSec: number;
	public PrizeCurrencyId: CurrencyType;
	public PrizeDescr: string;
	public PrizeFund: number;
	public PrizeHeading: string;
	public RefOfficialTourId: number;
	public RefOfficialTourRound: number;
	public RegEndDate: Date;
	public RegStartDate: Date;
	public RegStartSecLeft: number;
	public RoomId: number;
	public Spectators: boolean;
	public StartDate: Date;
	public StartSecLeft: number;
	public StateId: number;
	public TablesCount: number;
	public TimeLimit: number;
	public TotalPlayers: number;
	public TourId: number;
	public TourModeId: number;
	public TourRoundsCount: number;
	public TourTypeId: number;
	public WaitForReconnect: boolean;
	public Winners: Array<TournamentWinnerDTO>;
	/**Not a marathon, RUMarathon, RCRMarathon, AMMarathon*/
	public MarathonType: number;
	
	// local
	
	
	public imPlayer: boolean;
	public canJoin: boolean;
	public canWatch: boolean;
	
	public responceTime: Date;
	
}
