import {OnlineGameDTO} from "./OnlineGameDTO";
import {GameUserInfoDTO} from "./GameUserInfoDTO";

export class GameStateDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.GameState"
	
	public GameUsers: Array<GameUserInfoDTO>;
	public StateId: number;
	/**
	 * GetGameState - added new property StateDescriptionId that have following values:
	 * 0 = None, StartedAfterFirstMove = 31
	 * This property is sent when calling GetGameState and Join methods.
	 * So, if StateId == 30 && StateDescriptionId < 31 Then need to show animation with tiles distribution
	 * But, if StateId == 30 && StateDescriptionId == 31 Then animation should NOT be shown
	 */
	public StateDescriptionId: number;
	public LastPeak: number;
	public RoomId: number;
	public GameId: number;
	public Settings: OnlineGameDTO;
}
