import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {GameStore} from "../../../../store/GameStore";
import container from "../../../../inversify/inversify.config";
import {TYPES} from "../../../../inversify/inversify.types";
import {GameWorld} from "../../../../game/ecs/GameWorld";
import {commander} from "../../../../commander/Commander";
import {Commands} from "../../../../commands/Commands";
import {UserStore} from "../../../../store/UserStore";
import {GameService} from "../../../../net/service/GameService";
import {environment} from "../../../../environments/environment";
import {GameEventsService} from "../../../services/GameEventsService";
import {AppEventsService} from "../../../services/AppEventsService";
import {AppEventType} from "../../../../game/AppEventsPipe";
import {IJMIndicators} from "../../../../game/ecs/jm/IJMIndicators";
import {GameEventsPipe, GameEventType} from "../../../../game/GameEventsPipe";
import {filter, map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {AppStore} from "../../../../store/AppStore";
import {NavigationService} from "../../../services/navigation.service";
import {GameType} from "../../../../game/enums/GameType";
import {JackpotService} from "../../../../net/service/JackpotService";
import {EntryFeeTier} from "../../../../game/enums/EntryFeeTier";
import {JackpotInfoDTO} from "../../../../net/dto/JackpotInfoDTO";
import {JackpotParticipantDTO} from "../../../../net/dto/JackpotParticipantDTO";

@Component({
	selector: "app-table",
	templateUrl: "./table.component.html",
	styleUrls: ["./table.component.scss"],
	providers: [GameEventsService]
})
export class TableComponent implements OnInit, OnDestroy {
	private gameService: GameService;
	public gameStore: GameStore;
	private userStore: UserStore;
	private appStore: AppStore;
	public gameWorld: GameWorld;
	public gameEvents: GameEventsPipe;
	private destroy$: Subject<boolean> = new Subject<boolean>();
	isDebug = environment.isDebug;
	
	/**
	 * Contains indicators data for JM (Japanese Modern) rules. Is not set for other game types
	 */
	@Input()
	public jmIndicators: IJMIndicators;
	@Input()
	public jackpotInfo: { info: JackpotInfoDTO, participants: JackpotParticipantDTO[] };
	showJackpotInfo = false;
	
	constructor(
		public appEventsService: AppEventsService,
		public gameEventsService: GameEventsService, // keep this to create service at this moment, so it can start handle game events
		private navigationService: NavigationService,
	) {
		this.gameService = container.get(TYPES.GameService);
		this.gameStore = container.get(TYPES.GameStore);
		this.userStore = container.get(TYPES.UserStore);
		this.appStore = container.get(TYPES.AppStore);
		this.gameEvents = container.get(TYPES.GameEventsPipe);
	}
	
	ngOnInit(): void {
		console.log("TableComponent - init");
		this.gameWorld = container.get<GameWorld>(TYPES.GameWorld);
		this.gameWorld.world.stop();
		this.gameEvents.events.pipe(
			filter(value => value.type === GameEventType.JMCountersUpdated),
			map(value => value.data),
			takeUntil(this.destroy$),
		).subscribe(value => this.jmIndicators = value);
		
		this.showJackpotInfo = this.navigationService.routerGameType === GameType.TW;
		if (this.showJackpotInfo) {
			(async () => {
				const info = await this.jackpotService.getJackpotInfoFull(this.userStore.sessionKey, GameType.TW, EntryFeeTier.BRONZE);
				console.log("TableComponent.got info: ", info);
				this.jackpotInfo = {
					info: info.getInfo(GameType.TW, EntryFeeTier.BRONZE),
					participants: info.Participants
				};
			})();
			
		}
	}
	
	ngOnDestroy(): void {
		try {
			this.destroy$.next(true);
			this.destroy$.complete();
			console.log("TableComponent.onDestroy - reset game");
			this.resetGame();
		}
		catch (e) {
			console.warn("TableComponent.ngOnDestroy: Error -- " + e);
		}
	}
	
	private resetGame(): void {
		console.log("TableComponent.resetGame - start");
		// commander.executeCommand(Commands.EXIT_GAME)
		commander.executeCommand(Commands.RELEASE_GAME)
			.then(success => console.log("TableComponent.resetGame - released"));
		console.log("TableComponent.resetGame - end");
	}
	
	onBugRepClick() {
		this.appEventsService.send(AppEventType.BugReport_Show);
	}
	
	switchFullscreen() {
		this.appEventsService.send(AppEventType.SwitchFullscreen);
	}
	
	private get jackpotService(): JackpotService {
		return container.get<JackpotService>(TYPES.JackpotService);
	}
}
