import {DealState} from "./DealState";
import {GameState} from "./GameState";

export enum DealStage {
	NA,
	PREPARING,
	ACTIVE,
	DEAL_ENDED,
	GAME_ENDED
}

export const getDealStageByDealState = (state: DealState): DealStage => {
	switch (state) {
		case DealState.NA:
			return DealStage.NA;
		
		case DealState.FORCED_FINISH:
		// return DealStage.DEAL_IS_NOT_ACTIVE;
		case DealState.DEAD_HAND:
		case DealState.DEAD_WALL:
		case DealState.MAHJONG:
		case DealState.MAHJONG_SELF:
			return DealStage.DEAL_ENDED;
		
		case DealState.REPLACE_FLOWERS: // CO|MCR Replace flowers before deal start
		case DealState.CHARLESTON_1_LEFT:
		case DealState.CHARLESTON_1_OPPOSITE:
		case DealState.CHARLESTON_1_RIGHT:
		case DealState.WAIT_FOR_CHARLESTON_2:
		case DealState.CHARLESTON_2_LEFT:
		case DealState.CHARLESTON_2_OPPOSITE:
		case DealState.CHARLESTON_2_RIGHT:
		case DealState.WAIT_FOR_CHARLESTON_3:
		case DealState.CHARLESTON_3_OPPOSITE:
			return DealStage.PREPARING;
		
		case DealState.PLAYING:
		default:
			return DealStage.ACTIVE;
	}
};

export const getDealStageByGameState = (state: GameState): DealStage => {
	switch (state) {
		case GameState.PLAYING:
			return DealStage.ACTIVE;
		case GameState.PAUSED:
			return DealStage.DEAL_ENDED;
		case GameState.STOPPED:
		case GameState.COMPLETED:
			return DealStage.GAME_ENDED;
		default:
			return DealStage.NA;
	}
};
