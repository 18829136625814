import {RulesHelperJM} from "./RulesHelperJM";

export const RulesHelperEMA: typeof RulesHelperJM = (() => {
	const jmHelper = {...RulesHelperJM};
	jmHelper.helpTips = [
		{
			icon: "furiten",
			title: {key: "Lobby.HOverlays.GameRules.Furiten_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.Furiten_msg"}
		},
		{
			icon: "falseMahjong",
			title: {key: "Lobby.HOverlays.GameRules.FalseRon_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.FalseRon_msg"}
		},
		{
			icon: "ho_hand_r",
			title: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_msg"}
		},
		
		{
			icon: "ho_settings",
			title: {key: "Lobby.HOverlays.GameRules.JMSettings_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.JMSettings_msg"},
			msgFull: [{key: "Lobby.HOverlays.GameRules.JMSettings_details_EMA"}]
		},
	];
	return jmHelper;
})();

/*

helpTips: (() => ([
	{
		icon: "furiten",
		title: {key: "Lobby.HOverlays.GameRules.Furiten_title"},
		msgShortRK: {key: "Lobby.HOverlays.GameRules.Furiten_msg"}
	},
	{
		icon: "falseMahjong",
		title: {key: "Lobby.HOverlays.GameRules.FalseRon_title"},
		msgShortRK: {key: "Lobby.HOverlays.GameRules.FalseRon_msg"}
	},
	{
		icon: "ho_hand_r",
		title: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_title"},
		msgShortRK: {key: "Lobby.HOverlays.GameRules.DeclareRiichi_msg"}
	},
	/!*if (includeKeyboardTip) {
		tipSet[Enum.GameType.JM].push(
			{	icon: SkinLoader.getSkinItem('keyb'),
				title: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_title"),
				descr: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_msg"),
				descrFull: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_0")
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_space")}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pass")}])
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "C"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.chi")}])
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "P"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pon")}])
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "K"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.kan")}])
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "M"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.ron") + " / " + CoreSettings.Res.getValue("Enum.GameMove.tsumo")}])
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "D"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.draw")}])
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "R"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.ready")}])
					+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_e"), [
						{substr:"%%combination1%%", value: CoreSettings.Res.getValue("Enum.GameMove.chi") + ", " + CoreSettings.Res.getValue("Enum.GameMove.pon") + ", " + CoreSettings.Res.getValue("Enum.GameMove.kan") + ", " + CoreSettings.Res.getValue("Enum.GameMove.ron")},
						{substr:"%%combination2%%", value: CoreSettings.Res.getValue("Enum.GameMove.tsumo") + ", " + CoreSettings.Res.getValue("Enum.GameMove.draw") + ", " + CoreSettings.Res.getValue("Enum.GameMove.ready") }])
					+ "\n" + CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_sp")
			}
		);
	}*!/
	{
		icon: "ho_settings",
		title: {key: "Lobby.HOverlays.GameRules.JMSettings_title"},
		msgShortRK: {key: "Lobby.HOverlays.GameRules.JMSettings_msg"},
		msgFull: [{key: "Lobby.HOverlays.GameRules.JMSettings_details_AriAri"}]
	},
]))(),*/
