import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {GameWorld} from "../../../../../game/ecs/GameWorld";
import {GameMessageDTO} from "../../../../../net/dto/GameMessageDTO";
import {MoveType} from "../../../../../game/enums/MoveType";
import {PlayersSystem} from "../../../../../game/ecs/PlayersSystem";
import {Side} from "../../../../../game/enums/Side";
import {GameEventsPipe, GameEventType} from "../../../../../game/GameEventsPipe";
import container from "../../../../../inversify/inversify.config";
import {TYPES} from "../../../../../inversify/inversify.types";
import {Subject} from "rxjs";
import {AppEventsPipe, AppEventType} from "../../../../../game/AppEventsPipe";
import {AppEventsService} from "../../../../services/AppEventsService";
import {ChatGamController} from "../../../../../game/ChatGamController";
import {ChatService} from "../../../../../net/service/ChatService";
import {UserStore} from "../../../../../store/UserStore";

@Component({
	selector: "app-debug-helper",
	templateUrl: "./debug-helper.component.html",
	styleUrls: ["./debug-helper.component.scss"]
})
export class DebugHelperComponent implements OnInit, OnDestroy {
	
	private destroy$: Subject<boolean> = new Subject<boolean>();
	private appEvents: AppEventsPipe;
	private gameEvents: GameEventsPipe;
	
	public gw: GameWorld;
	public players: { id: number, name: string, realside: Side }[];
	public gameMovesList: { id: MoveType; label: string }[];
	private movesList = [
		MoveType.FROM_WALL_TO_CONCEALED,
		MoveType.FROM_WALL_TO_MELDED,
		MoveType.FROM_DEADWALL_TO_CONCEALED,
		MoveType.FROM_DEADWALL_TO_MELDED,
		MoveType.MAKE_MOVE,
		MoveType.FROM_CONCEALED_TO_MELDED,
		MoveType.FROM_CONCEALED_TO_MELDED_HIDDEN,
		MoveType.FROM_CONCEALED_TO_SLOT,
		MoveType.FROM_SLOT_TO_MELDED,
		MoveType.FROM_SLOT_TO_DISCARDS,
		MoveType.NEW_DEAL,
		MoveType.USER_CAN_MAKE_MOVE,
		// JM Rules
		MoveType.DORA_INDICATOR,
		MoveType.URA_DORA,
		MoveType.READY,
		MoveType.SHOW_BETS_COUNTER,
		MoveType.SHOW_JM_COUNTER,
		MoveType.JM_DEALER_CHANGED,
		MoveType.JM_RENCHAN_CHANGED,
		
		MoveType.USER_SIDE,
		MoveType.SYSTEM_PRIVATE_MESSAGE,
	
	];
	opened = false;
	
	@Input("gameWorld")
	set gameWorld(value: GameWorld) {
		this.gw = value;
		// this.gameWorld.gameStorage
	}
	
	constructor(
		public appEventsService: AppEventsService,
	) {
		this.gameMovesList = this.movesList.map(moveId => ({id: moveId, label: MoveType[moveId]}));
		
		this.appEvents = container.get(TYPES.AppEventsPipe);
		this.gameEvents = container.get(TYPES.GameEventsPipe);
	}
	
	ngOnInit(): void {
		console.log("DebugHelperComponent.ngOnInit: 	");
	}
	
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
		
	}
	
	private updatePlayers(): void {
		try {
			this.players = this.gw.world.getSystem(PlayersSystem).playersQRA.map(pl => ({
				id: pl.id,
				name: pl.name,
				realside: pl.realside
			}));
		}
		catch (e) {
			console.error("DebugHelperComponent.updatedPlayers: error");
		}
	}
	
	toggleOpened() {
		this.opened = !this.opened;
		if (this.opened) {
			this.updatePlayers();
		}
	}
	
	breakWall(value: string) {
		this.addGameMessages(
			this.createGameMessage(186, MoveType.DEAL_THROWN_DICE, value)
			// this.gameWorld.gameActionsSystem.
		);
	}
	
	wall2concealed(id: string) {
		this.addGameMessages(
			this.createGameMessage(186, MoveType.FROM_WALL_TO_CONCEALED, id)
		);
	}
	
	dwall2concealed(id: string) {
		this.addGameMessages(
			this.createGameMessage(186, MoveType.FROM_DEADWALL_TO_CONCEALED, id)
		);
	}
	
	private addActions(...args) {
		this.gw.addActions(args);
	}
	
	private addGameMessages(...args) {
		this.gw.addGameMessages(args);
	}
	
	private createGameMessage(userId: number, type: MoveType, message: string): GameMessageDTO {
		const gm = new GameMessageDTO();
		gm.UserId = userId;
		gm.Type = type;
		gm.Message = message;
		return gm;
	}
	
	execMove(playerId: number, moveId: number, message: string) {
		this.addGameMessages(
			this.createGameMessage(playerId, moveId, message)
		);
	}
	
	private sendGameEvent(type: GameEventType, data?: unknown): void {
		this.gameEvents.send(type, data);
	}
	
	onEndDealClick() {
		this.sendGameEvent(GameEventType.Action_EndGame);
		this.toggleOpened();
	}
	
	onLeaveClick() {
		this.appEventsService.send(AppEventType.Logout);
	}
	
	
	public get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	// X section
	
	
	requestBalance() {
		
		container.get<ChatGamController>(TYPES.ChatGamController).privateMessages$.next([{
			Id: 0,
			FromUserId: 1,
			FromName: "System",
			ToUserId: this.userStore.id,
			ToName: "MyName",
			Message: `sysnotify#$#CHIP.BONUS#$#xxx@$@`,
			Colour: "",
			PostTime: new Date()
		}]);
	}
	
	invite(tourId: string, gameId: string, roomId: string) {
		container.get<ChatService>(TYPES.ChatService).postPrivateMessage(this.userStore.sessionKey, this.userStore.id, `tournr#$#${roomId}@$@${tourId}@$@${gameId}`);
	}
	
	sysAlr(message: string) {
		// container.get<ChatService>(TYPES.ChatService).postPrivateMessage(this.userStore.sessionKey, this.userStore.id, "sysAlr#$#Sys Alr Msg@$@SysAlrUrl@$@SysAlrCmd");
		// container.get<ChatService>(TYPES.ChatService).postPrivateMessage(this.userStore.sessionKey, this.userStore.id, "sysInf#$#Mahjong Marathons Started Today!@$@http://mahjongtime.com:80/uploads/marathone21_message.jpg@$@");
		container.get<ChatService>(TYPES.ChatService).postPrivateMessage(this.userStore.sessionKey, this.userStore.id, message);
	}
	
	sysInf(message: string) {
		// container.get<ChatService>(TYPES.ChatService).postPrivateMessage(this.userStore.sessionKey, this.userStore.id, "sysInf#$#Sys Inf Msg@$@SysInfUrl@$@SysInfCmd");
		container.get<ChatService>(TYPES.ChatService).postPrivateMessage(this.userStore.sessionKey, this.userStore.id, message);
	}
	
	sendSysMessage(message: string) {
		console.log("DebugHelperComponent.sendSysMessage: message");
		container.get<ChatService>(TYPES.ChatService).postPrivateMessage(this.userStore.sessionKey, this.userStore.id, message);
	}
}
