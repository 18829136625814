import {JackpotParticipantDTO} from "./JackpotParticipantDTO";
import {JackpotRecentWinnerDTO} from "./JackpotRecentWinnerDTO";
import {JackpotInfoDTO} from "./JackpotInfoDTO";
import {EntryFeeTier} from "../../game/enums/EntryFeeTier";
import {GameType} from "../../game/enums/GameType";

export class JackpotInfoStoreDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.Jackpot.JackpotInfoStore"
	
	public JackpotInfo: JackpotInfoDTO[];
	public Participants: JackpotParticipantDTO[];
	public RecentWinner: JackpotRecentWinnerDTO[];
	
	public getInfo(gameTypeId: GameType, tier: EntryFeeTier) {
		if (this.JackpotInfo) {
			return this.JackpotInfo.find(info => info.GameTypeId === gameTypeId && info.TierId === tier);
		}
		return null;
	}
}

