import {
	ActivateTimerAction,
	ConcealedToFlower,
	ConcealedToMelded,
	ConcealedToSlot,
	DealThrownDice,
	EndDealAction,
	IGameAction,
	MakeMove,
	RemoveHighLight,
	RemoveMultipleChoiceDialog,
	ShowTilesConcealed,
	ShowTilesExposed,
	SlotToDiscards,
	SlotToMelded,
	SlotToMeldedPromoted,
	TileSubSetType,
	UpdateDeadWall,
	UpdateDiscardsTiles,
	UpdateMoveNow,
	UpdatePlayersPointsAction,
	UpdatePlayerTilesAction,
	UpdateSlotTile,
	UpdateWallTiles,
	UserCanMakeMove,
	WallEndToConcealedAction,
	WallEndToFlowerAction,
	WallEndToMeldedAction,
	WallToConcealedAction,
	WallToFlowerAction,
	WallToMeldedAction
} from "./GameAction";
import { TileType } from "../../enums/TileType";
import { ActionMoveType } from "../../enums/ActionMoveType";
import { GameMessageDTO } from "../../../net/dto/GameMessageDTO";
import { Entity } from "ecsy";
import { PlayerStoreComponent } from "./components";
import { PlayerEntityHelper } from "../../helpers/PlayerEntityHelper";
import { DealStage, getDealStageByGameState } from "../../enums/DealStage";
import { GameState } from "../../enums/GameState";
import { Side } from "../../enums/Side";
import { TileDataHelper } from "../../helpers/TileDataHelper";
import { GameEventType } from "../../GameEventsPipe";

export function dealThrownDice(gameMessage: GameMessageDTO): Array<IGameAction> {
	const actions = [
		new DealThrownDice({
			dices: gameMessage.Message,
			tableOptions: this.gameStorageQW.tableOptions,
			tiles: this.tileSet,
			players: this.playersQRA
		}),
		new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.singleWallLength)
	];
	if (this.gameStorageQW.tableOptions.hasDeadWall) {
		return [new UpdateDeadWall(this.tileSet, this.gameStorageQW)];
	}
	return actions;
}

export function dealWind(gameMessage: GameMessageDTO): Array<IGameAction> {
	this.gameStorageQW.dealState.roundWind = gameMessage.Message as Side;
	return [];
}

export function fromWallToConcealed(gameMessage: GameMessageDTO): Array<IGameAction> {
	try {
		console.log('fromWallToConcealed: start. gameMessage:', gameMessage);

		// remove all available actions
		this.clearAllPlayerActions();
		console.log('fromWallToConcealed: All player actions cleared.');

		this.gameStorageQW.turnState.waitingForNextMove = false;
		console.log('fromWallToConcealed: turnState.waitingForNextMove set to false.');

		let hasTilesFromEnd = false;
		const actions: Array<IGameAction> = [];
		parseIds(gameMessage.Message).forEach(tileId => {
			console.log('fromWallToConcealed: processing tileId:', tileId);

			if (this.gameStorageQW.gameProcessEnv.nextTileFromEnd) {
				console.log('fromWallToConcealed: nextTileFromEnd is true.');
				this.gameStorageQW.gameProcessEnv.nextTileFromEnd = false;
				hasTilesFromEnd = true;

				const action = new WallEndToConcealedAction(tileId, gameMessage.UserId, this.tileSet);
				actions.push(action);
				console.log('fromWallToConcealed: pushed WallEndToConcealedAction to actions:', action);
			}
			else {
				const action = new WallToConcealedAction(tileId, gameMessage.UserId, this.tileSet);
				actions.push(action);
				console.log('fromWallToConcealed: pushed WallToConcealedAction to actions:', action);
			}
		});

		const updatePlayerTilesAction = new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet);
		actions.push(updatePlayerTilesAction);
		console.log('fromWallToConcealed: pushed UpdatePlayerTilesAction to actions:', updatePlayerTilesAction);

		const updateMoveNowAction = new UpdateMoveNow(gameMessage.UserId, this.gameStorageQW);
		actions.push(updateMoveNowAction);
		console.log('fromWallToConcealed: pushed UpdateMoveNow to actions:', updateMoveNowAction);

		if (hasTilesFromEnd) {
			console.log('fromWallToConcealed: hasTilesFromEnd is true.');

			const updateWallTilesAction = new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.singleWallLength, TileSubSetType.LAST_TILE_ONLY);
			actions.push(updateWallTilesAction);
			console.log('fromWallToConcealed: pushed UpdateWallTiles to actions:', updateWallTilesAction);

			if (this.gameStorageQW.tableOptions.hasDeadWall) {
				console.log('fromWallToConcealed: hasDeadWall is true.');

				const updateDeadWallAction = new UpdateDeadWall(this.tileSet, this.gameStorageQW);
				actions.push(updateDeadWallAction);
				console.log('fromWallToConcealed: pushed UpdateDeadWall to actions:', updateDeadWallAction);
			}
		}

		console.log('fromWallToConcealed: end. actions:', actions);
		return actions;
	} catch (error) {
		console.error('fromWallToConcealed: an error occurred:', error);
		return [];
	}
}

export function fromWallEndToConcealed(gameMessage: GameMessageDTO): Array<IGameAction> {
	this.clearAllPlayerActions(); // remove all available actions
	this.gameStorageQW.turnState.waitingForNextMove = false;

	const actions: Array<IGameAction> = [];
	parseIds(gameMessage.Message)
		.forEach(tileId => {
			actions.push(new WallEndToConcealedAction(tileId, gameMessage.UserId, this.tileSet));
		});
	actions.push(new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.singleWallLength, TileSubSetType.LAST_TILE_ONLY));
	if (this.gameStorageQW.tableOptions.hasDeadWall) {
		 actions.push(new UpdateDeadWall(this.tileSet, this.gameStorageQW)); 
	}

	actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet));
	actions.push(new UpdateMoveNow(gameMessage.UserId, this.gameStorageQW));
	return actions;
}

export function fromWallToMelded(gameMessage: GameMessageDTO): Array<IGameAction> {
	const actions: Array<IGameAction> = [];
	const playerId = gameMessage.UserId;
	// use gameMessage group if it is available (is provided only in case this move created during Game Snapshot processing, and each Melded tile
	// has Group index)
	const meldedGroup = gameMessage.Group ?? this.getPlayerEntity(playerId).getComponent(PlayerStoreComponent).meldedGroup;

	parseIds(gameMessage.Message).forEach(tileId => {
		actions.push(new WallToMeldedAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
	});

	actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet));
	return actions;
}

export function fromWallEndToMelded(gameMessage: GameMessageDTO): Array<IGameAction> {
	const actions: Array<IGameAction> = [];
	const playerId = gameMessage.UserId;
	// use gameMessage group if it is available (is provided only in case this move created during Game Snapshot processing, and each Melded tile
	// has Group index)
	const meldedGroup = gameMessage.Group ?? this.getPlayerEntity(playerId).getComponent(PlayerStoreComponent).meldedGroup;

	parseIds(gameMessage.Message).forEach(tileId => {
		actions.push(new WallEndToMeldedAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
	});

	actions.push(new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.singleWallLength, TileSubSetType.LAST_TILE_ONLY));
	if (this.gameStorageQW.tableOptions.hasDeadWall) {
		actions.push(new UpdateDeadWall(this.tileSet, this.gameStorageQW));
	}
	actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet));
	return actions;
}

export function fromWallToMeldedOrFlower(gameMessage: GameMessageDTO): Array<IGameAction> {
	const actions: Array<IGameAction> = [];
	let hasMelded = false;
	let hasFlowers = false;
	let hasTilesFromEnd = false;
	const playerId = gameMessage.UserId;
	// use gameMessage group if it is available (is provided only in case this move created during Game Snapshot processing, and each Melded tile
	// has Group index)
	const meldedGroup = gameMessage.Group ?? this.getPlayerEntity(playerId).getComponent(PlayerStoreComponent).meldedGroup;
	const isFlower = this.gameStorageQW.tableOptions.rulesSettings.isFlower;

	parseIds(gameMessage.Message).forEach(tileId => {
		if (isFlower(tileId)) {
			hasFlowers = true;
			if (this.gameStorageQW.gameProcessEnv.nextTileFromEnd) {
				actions.push(new WallEndToFlowerAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
				hasTilesFromEnd = true;
			}
			else {
				actions.push(new WallToFlowerAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
			}
			if (this.gameStorageQW.tableOptions.rulesSettings.nextTileFromEnd_Enabled) {
				this.gameStorageQW.gameProcessEnv.nextTileFromEnd = this.gameStorageQW.tableOptions.rulesSettings.nextTileFromEnd_MeldedFlower;
			}
		}
		else {
			hasMelded = true;
			if (this.gameStorageQW.gameProcessEnv.nextTileFromEnd) {
				hasTilesFromEnd = true;
				actions.push(new WallEndToMeldedAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
				this.gameStorageQW.gameProcessEnv.nextTileFromEnd = false;
			}
			else {
				actions.push(new WallToMeldedAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
			}
		}
	});

	if (hasFlowers) {
		actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.FLOWER, this.playerLayoutHelper, this.tileSet));
	}
	if (hasMelded) {
		actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet));
	}
	if (hasTilesFromEnd) {
		actions.push(new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.singleWallLength, TileSubSetType.LAST_TILE_ONLY));
		if (this.gameStorageQW.tableOptions.hasDeadWall) {
			 actions.push(new UpdateDeadWall(this.tileSet, this.gameStorageQW));			
		}
	}

	return actions;
}

export function fromWallEndToMeldedOrFlower(gameMessage: GameMessageDTO): Array<IGameAction> {
	const actions: Array<IGameAction> = [];
	let hasMelded = false;
	let hasFlowers = false;
	const playerId = gameMessage.UserId;
	console.log('fromWallEndToMeldedOrFlower fromWallEndToMeldedOrFlower');
	// use gameMessage group if it is available (is provided only in case this move created during Game Snapshot processing, and each Melded tile
	// has Group index)
	const meldedGroup = gameMessage.Group ?? this.getPlayerEntity(playerId).getComponent(PlayerStoreComponent).meldedGroup;
	const isFlower = this.gameStorageQW.tableOptions.rulesSettings.isFlower;
	console.log('fromWallEndToMeldedOrFlower fromWallEndToMeldedOrFlower', isFlower);
	parseIds(gameMessage.Message).forEach(tileId => {
		// TODO: For this rules only
		if (isFlower(tileId)) {
			hasFlowers = true;
			actions.push(new WallEndToFlowerAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
			console.log('fromWallEndToMeldedOrFlower WallEndToFlowerAction');
		}
		else {
			hasMelded = true;
			actions.push(new WallEndToMeldedAction(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
			console.log('fromWallEndToMeldedOrFlower WallEndToMeldedAction');
		}

	});

	// update (verify) position of last tile (to avoid levitating). // TODO: maybee listen to TileNodes change for Wall tiles?
	actions.push(new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.singleWallLength, TileSubSetType.LAST_TILE_ONLY));
	if (this.gameStorageQW.tableOptions.hasDeadWall) {
		// TODO TEST actions.push(new UpdateDeadWall(this.tileSet, this.gameStorageQW));
	}

	if (hasFlowers) {
		actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.FLOWER, this.playerLayoutHelper, this.tileSet));
	}
	if (hasMelded) {
		actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet));
	}
	return actions;
}

export function fromConcealedToSlot(gameMessage: GameMessageDTO): Array<IGameAction> {
	try {
		console.log('fromConcealedToSlot: romConcealedToSlot called with:', gameMessage);

		PlayerEntityHelper.setActions(this.getPlayerEntity(gameMessage.UserId), []);
		console.log('fromConcealedToSlot: Player actions cleared');

		const actions: Array<IGameAction> = [];
		const tileId = parseInt(gameMessage.Message, 10);
		console.log('fromConcealedToSlot: Parsed tileId:', tileId);

		actions.push(
			new ConcealedToSlot(tileId, gameMessage.UserId, this.tileSet),
			new RemoveHighLight(gameMessage.UserId, this.tileSet),
			new UpdateSlotTile(this.tileSet, this.gameStorageQW.tableOptions.rulesSettings.discardsMode, this.playersQRA) // Move tile to slot position
		);
		console.log('fromConcealedToSlot: Actions prepared:', actions);

		/*if (this.userStore.info.Settings.FlashUI.TileAutoSort) {
			actions.push(new SortTilesAction(gameMessage.UserId, this.playerLayoutHelper, this.tileSet, this.gameStorageQW.tableOptions.concealedSortFn));
		}*/

		actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet));
		console.log('fromConcealedToSlot: Final actions:', actions);
		return actions;
	} catch (error) {
		console.error(`fromConcealedToSlot: Error in fromConcealedToSlot: ${error.message}, Stack: ${error.stack}`);
		// Add additional error handling logic if necessary
		throw error;
	}
}

export function fromConcealedToMelded(gameMessage: GameMessageDTO): Array<IGameAction> {
	const actions: Array<IGameAction> = [];
	// const tileId = parseInt(gameMessage.Message, 10);
	const playerId = gameMessage.UserId;
	const playerEntity = this.getPlayerEntity(playerId);
	const meldedGroup = playerEntity.getComponent(PlayerStoreComponent).meldedGroup;

	parseIds(gameMessage.Message).forEach(tileId => {
		actions.push(new ConcealedToMelded(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
	});
	actions.push(
		new RemoveHighLight(gameMessage.UserId, this.tileSet),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet)
	);
	return actions;
}

/**
 *
 * @param gameMessage
 * @param closed -- whether the tile belongs to a closed combination (like kong_self_concealed (from_concealed_to_melded_hidden))
 */
export function fromConcealedToMeldedOrFlower(gameMessage: GameMessageDTO, closed: boolean = false): Array<IGameAction> {
	const actions: Array<IGameAction> = [];
	let hasMelded = false;
	let hasFlowers = false;
	const playerId = gameMessage.UserId;
	const playerEntity = this.getPlayerEntity(playerId);
	const meldedGroup = playerEntity.getComponent(PlayerStoreComponent).meldedGroup;

	parseIds(gameMessage.Message).forEach(tileId => {
		const isFlower = this.gameStorageQW.tableOptions.rulesSettings.isFlower;
		if (isFlower(tileId)) {
			hasFlowers = true;
			actions.push(new ConcealedToFlower(tileId, gameMessage.UserId, this.tileSet, meldedGroup));
			if (this.gameStorageQW.tableOptions.rulesSettings.nextTileFromEnd_Enabled && this.gameStorageQW.tableOptions.rulesSettings.nextTileFromEnd_MeldedFlower) {
				this.gameStorageQW.gameProcessEnv.nextTileFromEnd = true;
			}
		}
		else {
			hasMelded = true;
			actions.push(new ConcealedToMelded(tileId, gameMessage.UserId, this.tileSet, meldedGroup, closed));
		}

	});
	actions.push(
		new RemoveHighLight(gameMessage.UserId, this.tileSet),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet)
	);
	if (hasFlowers) {
		actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.FLOWER, this.playerLayoutHelper, this.tileSet));
	}
	if (hasMelded) {
		actions.push(new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet));
	}
	return actions;
}

export function fromConcealedToMeldedHidden(gameMessage: GameMessageDTO): Array<IGameAction> {
	// kong_self_concealed -- all tiles should be hidden (turned back)
	// can be 151^151^151^151  or 151^251^151^151
	return fromConcealedToMeldedOrFlower.call(this, gameMessage, true);
}

export function fromSlotToDiscards(gameMessage: GameMessageDTO): Array<IGameAction> {
	const actions: Array<IGameAction> = [];
	const tileId = parseInt(gameMessage.Message, 10);
	actions.push(new SlotToDiscards(tileId, gameMessage.UserId, this.tileSet));
	// + play sound

	actions.push(new UpdateDiscardsTiles(this.tileSet, this.gameStorageQW.tableOptions.rulesSettings.discardsMode, gameMessage.UserId, this.playersQRA));
	// actions.push(new UpdateDiscardsTiles(this.tileSet));
	// post actions
	// actions.push( SORT TILES IF SORT IS ON)  //only after all w2c executed
	return actions;
}

export function fromSlotToMelded(gameMessage: GameMessageDTO): Array<IGameAction> {
	const tileId = parseInt(gameMessage.Message, 10);
	const playerId = gameMessage.UserId;
	const playerEntity = this.getPlayerEntity(playerId);
	const meldedGroup = playerEntity.getComponent(PlayerStoreComponent).meldedGroup;
	const isPromotedKong = this.gameStorageQW.gameProcessEnv.makeMoveInProgress === ActionMoveType.KONG_SELF_PROMOTED;
	this.gameStorageQW.gameProcessEnv.makeMoveInProgress = undefined;

	return [
		isPromotedKong ? new SlotToMeldedPromoted(tileId, gameMessage.UserId, this.tileSet) : new SlotToMelded(tileId, gameMessage.UserId, this.tileSet, meldedGroup),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet),
		new ActivateTimerAction(gameMessage.Type, this.gameStorageQW),
	];
}


export function endTakeBlock(gameMessage: GameMessageDTO): Array<IGameAction> {
	return [
		new RemoveHighLight(gameMessage.UserId, this.tileSet),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet)
	];
}

export function userCanMakeMove(gameMessage: GameMessageDTO): Array<IGameAction> {
	// TODO: JM rules: override userCanMakeMove -- do not convert mahjong_self -> mahjong
	// disable game actions for user until UCMM action proceed (when game actions will be setted)
	// this.gameStore.tablePlayers.clearAvailableActions(gameMessage.UserId);
	// const pe = this.getPlayerEntity(gameMessage.UserId).getMutableComponent(PlayerActionsComponent).actions = [];
	PlayerEntityHelper.setActions(this.getPlayerEntity(gameMessage.UserId), []);

	const moves = gameMessage.Message.split("|") as ActionMoveType[];
	const avMoves = this.avActionsMap(moves); // convert and filter
	const pe1 = this.getPlayerEntity(gameMessage.UserId);
	return [
		// remove dialog, as after KongSelf user gets FC2M and UCMM (but not DEAL_TURN)
		new RemoveMultipleChoiceDialog(this.gameEvents), // remove dialog if user didn't chose anything.
		new UserCanMakeMove(pe1, avMoves)
	];
}


export function makeMove(gameMessage: GameMessageDTO): Array<IGameAction> {
	// server sends MAKE_MOVE when all of the players have made their moves already, thus we can remove all actions
	// remove all available actions
	this.clearAllPlayerActions();
	this.gameStorageQW.gameProcessEnv.makeMoveInProgress = gameMessage.Message;

	const player: Entity = this.getPlayerEntity(gameMessage.UserId);
	const actions = [
		new MakeMove(gameMessage.UserId, gameMessage.Message as ActionMoveType, player),
		new UpdateMoveNow(gameMessage.UserId, this.gameStorageQW),
	];

	if (this.gameStorageQW.tableOptions.rulesSettings.nextTileFromEnd_Enabled && this.gameStorageQW.tableOptions.rulesSettings.nextTileFromEnd_Kong) {
		// HK rules require to take next tile from end of the wall
		// additionally: set NextTileFromEndOfWall if user declared any type of kong
		switch (gameMessage.Message) {
			case ActionMoveType.KONG:
			case ActionMoveType.KONG_SELF:
			case ActionMoveType.KONG_SELF_CONCEALED:
			case ActionMoveType.KONG_SELF_PROMOTED:
				this.gameStorageQW.gameProcessEnv.nextTileFromEnd = true;
		}
	}
	return actions;
}

// Show Tiles
export function startShowTile(gameMessage: GameMessageDTO): Array<IGameAction> {
	return [];
}

export function showTileConcealed(gameMessage: GameMessageDTO): Array<IGameAction> {
	return [
		new ShowTilesConcealed(gameMessage.UserId, parseIds(gameMessage.Message), this.tileSet),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet),
	];
}

export function showTileMelded(gameMessage: GameMessageDTO): Array<IGameAction> {
	return [
		new ShowTilesExposed(gameMessage.UserId, parseIds(gameMessage.Message), this.tileSet),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.MELDED, this.playerLayoutHelper, this.tileSet),
		new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.FLOWER, this.playerLayoutHelper, this.tileSet),
	];
}

export function endShowTile(gameMessage: GameMessageDTO): Array<IGameAction> {
	return [];
}

// End Deal and Game
export function gameState(gameMessage: GameMessageDTO): Array<IGameAction> {
	const states = parseIds(gameMessage.Message); // Multiple states in one message allowed (40^45). We need only the last one.
	const state: GameState = states.pop();
	this.gameStore.joinedGame.StateId = state;
	this.gameStorageQW.dealState.stage = getDealStageByGameState(state);
	return [];
}

export function endDeal(gameMessage: GameMessageDTO): Array<IGameAction> {
	this.gameStorageQW.dealState.stage = DealStage.DEAL_ENDED;
	return [
		new EndDealAction(this.gameEvents, this.gameStorageQW, this.playersQRA),
		new UpdatePlayersPointsAction()
	];
}

export function endGame(gameMessage: GameMessageDTO): Array<IGameAction> {
	this.gameStorageQW.dealState.stage = DealStage.GAME_ENDED;
	this.sendGameEvent(GameEventType.Game_Ended);
	return [];
}

export function newRating(gameMessage: GameMessageDTO): Array<IGameAction> {
	return [];
}

export function parseIds(ids: string): Array<number> {
	return ids.split("^").map(s => parseInt(s, 10));
}

export function toTinyIDs(ids: number[]): Array<number> {
	return ids.map(tileId => TileDataHelper.getTinyId(tileId));
}
