import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./ui/login/login.component";
import {WelcomeWizComponent} from "./ui/welcome-wiz/welcome-wiz.component";
import {IngameComponent} from "./ui/lobby/table/ingame/ingame.component";
import {GamesComponent} from "./ui/lobby/games/games.component";
import {LobbyComponent} from "./ui/lobby/lobby.component";
import {WaitingComponent} from "./ui/lobby/table/waiting/waiting.component";
import {TableComponent} from "./ui/lobby/table/table.component";
import {AuthChildGuard} from "./guards/AuthChildGuard";
import {GamesExpertComponent} from "./ui/lobby/gamesexpert/games-expert.component";
import {GameTypeChildGuard} from "./guards/GameTypeChildGuard";
import {LeaveGameGuard} from "./guards/DeactivatableGuard";
import {CanActivateChildGameGuard} from "./guards/CanActivateChildGameGuard";
import {NavigationGuard} from "./guards/NavigationGuard";
import {JoinGameIdComponent} from "./ui/lobby/table/join-game-id/join-game-id.component";
import {AuthGuard} from "./guards/AuthGuard";
import {CreatePrivateComponent} from "./ui/lobby/createprivate/create-private.component";
import {TournamentsComponent} from "./ui/lobby/tournaments/tournaments.component";

const routes: Routes = [
	{path: "login", component: LoginComponent, canDeactivate: [/*NavigationGuard*/]},
	{
		path: ":gameType",
		canActivateChild: [GameTypeChildGuard, AuthChildGuard],
		children: [
			{path: "", redirectTo: "lobby", pathMatch: "full"},
			{
				path: "lobby",
				component: LobbyComponent,
				// canDeactivate: [DeactivatableGuard],
				// canActivateChild: [GameTypeChildGuard, AuthChildGuard],
				children: [
					{path: "", redirectTo: "games", pathMatch: "full"},
					{path: "games", component: GamesComponent, canDeactivate: [NavigationGuard]},
					{path: "gamesExpert", component: GamesExpertComponent, canDeactivate: [NavigationGuard]},
					{path: "create-private", component: CreatePrivateComponent, canDeactivate: [NavigationGuard]},
					{path: "tournaments", component: TournamentsComponent, canDeactivate: [NavigationGuard]},
				]
			},
			{
				path: "table",
				component: TableComponent,
				canActivateChild: [CanActivateChildGameGuard],
				children: [
					{path: "", redirectTo: "waiting", pathMatch: "full"},
					{path: "waiting", component: WaitingComponent, canDeactivate: [LeaveGameGuard, NavigationGuard]},
					{path: "ingame", component: IngameComponent, canDeactivate: [LeaveGameGuard, NavigationGuard]},
					{path: ":gameId", component: JoinGameIdComponent},
				]
			},
		
		],
	},
	{path: "game/:gameId", component: JoinGameIdComponent, pathMatch: "full", canActivate: [AuthGuard]},
	{path: "**", component: WelcomeWizComponent/*, canActivate: [AuthGuard]*/} /* Enable AuthGuard to move Game Rules selection after login screen */
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true, relativeLinkResolution: "legacy"})],
	exports: [RouterModule]
})

export class AppRoutingModule {
}
