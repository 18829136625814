enum Game {
	START_GAME = "game/StartGame.mp3",
	TIMER = "game/Beep_Timer.mp3",
	TIMER_TB = "game/Beep_TB.mp3",
	PASS = "game/Pass.mp3",
	TILE = "game/Tile.mp3",
	ACTION_MENU = "game/TileClick2.mp3",
	TILE_SLOT = "game/TileSlot.mp3",
	WIN = "game/Win.mp3",
	WRONG_MAHJONG = "game/WrongMahjong.mp3",
}

export class Sound {
	public static GAME: typeof Game = Game;
}


