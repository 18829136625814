import {GameRulesAbstractSystem} from "../common/GameRulesAbstractSystem";
import {RulesHelperHK} from "./RulesHelperHK";

export class GameRulesHKSystem extends GameRulesAbstractSystem {
	
	// @override
	protected setupRules() {
		const joinedGame = this.gameStore.joinedGame;
		const options = RulesHelperHK.getOptions(joinedGame);
		const gs = this.getGameStorageWrapper();
		gs.tableOptions.rulesSettings = options;
		gs.tableOptions.singleWallLength = options.singleWallLength;
		gs.tableOptions.maxPlayers = options.maxPlayers;
		gs.tableOptions.sides = options.sides;
		gs.tableOptions.actionsSet = options.actionsSet;
		gs.tableOptions.hasDeadWall = options.hasDeadWall ?? false;
		gs.tableOptions.dealDiceCalcFn = options.dealDiceCalcFn;
	}
	
}
