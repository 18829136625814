import {Side} from "../../enums/Side";
import {ActionMoveType} from "../../enums/ActionMoveType";
import * as THREE from "three";
import {Vector3} from "three";
import {DealState} from "../../enums/DealState";
import {ActionColors, IRulesOptions} from "../common/GameRulesAbstractSystem";
import {OnlineGameDTO} from "../../../net/dto/OnlineGameDTO";
import {dealDiceCalcWP} from "../../interfaces/TilesSortFn";
import {DiscardsMode} from "../../enums/DiscardsMode";
import {ICreateGameOpts} from "../../../net/service/GameService";
import {GameType} from "../../enums/GameType";
import {BeltType} from "../../enums/BeltType";
import {ICreateFormLimits} from "../../../app/ui/lobby/createprivate/i-create-game";
import {IRulesHelper, LocationTransforms} from "../../helpers/rules-helper";

interface IRulesHelperWP {
	isCharlestonState(stateId: DealState): boolean;
}

export const RulesHelperWP: IRulesHelper & IRulesHelperWP = {

	getOptions: (tableSettings: OnlineGameDTO): IRulesOptions => {
		return {
			gameType: tableSettings.GameTypeId,
			maxPlayers: 4,
			singleWallLength: 18,
			hasDeadWall: true,
			deadWallLength: 13,
			sides: [Side.South, Side.East, Side.North, Side.West], // Sets available sides for current game rules. Should start from South, CCW
			actionsSet: [
				{ id: ActionMoveType.PASS, label: "Pass", color: ActionColors.PASS },
				{id: ActionMoveType.CHOW, label: "Chow", color: ActionColors.CHOW},
				{ id: ActionMoveType.PUNG, label: "Pung", color: ActionColors.PUNG },				
				{id: ActionMoveType.KONG, label: "Kong", color: ActionColors.KONG},				
				{id: ActionMoveType.MAHJONG, label: "Mah Jongg", color: ActionColors.MAHJONG},
			],
			concealedSortFn: (a: number, b: number) => {
				/**
				 * Reassign some IDs to change sorting order
				 * @param id - original tile tinyID
				 * @return - new tile id
				 */
				const adjustId = (id: number): number => {
					switch (id) {
						// sorting winds in order N-E-W-S
						case 54:
							return 51;
						case 51:
							return 52;
						case 53:
							return 53;
						case 52:
							return 54;
						// flowers should be at the start of hand.
						case 61:
							return 2;
						case 62:
							return 3;
						case 63:
							return 4;
						case 64:
							return 5;
						case 65:
							return 6;
						case 66:
							return 7;
						case 67:
							return 8;
						case 68:
							return 9;
						default:
							return id;
					}
				};
				return adjustId(a) - adjustId(b);
			},
			dealDiceCalcFn: dealDiceCalcWP,
			discardsMode: DiscardsMode.COMMON,
			
			isFlower: tileId => (tileId % 100) > 60 && (tileId % 100) < 80, 
			
			nextTileFromEnd_Enabled: true,
			nextTileFromEnd_MeldedFlower: true,
			nextTileFromEnd_Kong: true,
		};
	},
	isCharlestonState: (stateId: DealState): boolean => {
		switch (stateId) {
			case DealState.CHARLESTON_1_RIGHT:
			case DealState.CHARLESTON_1_OPPOSITE:
			case DealState.CHARLESTON_1_LEFT:
			case DealState.WAIT_FOR_CHARLESTON_2:
			case DealState.CHARLESTON_2_RIGHT:
			case DealState.CHARLESTON_2_OPPOSITE:
			case DealState.CHARLESTON_2_LEFT:
			case DealState.WAIT_FOR_CHARLESTON_3:
			case DealState.CHARLESTON_3_OPPOSITE:
				return true;
		}
		return false;
	},
	getLocationTransforms: (): LocationTransforms => {
		const lc = {};
		lc[Side.South] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 0),
				euler: new Vector3(0, 0, 0),
			};
		lc[Side.West] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 90 * 3.14 / 180),
				euler: new Vector3(0, 90 * 3.14 / 180, 0),
			};
		lc[Side.North] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 180 * 3.14 / 180),
				euler: new Vector3(0, 180 * 3.14 / 180, 0),
			};
		lc[Side.East] =
			{
				quaternion: new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), 270 * 3.14 / 180),
				euler: new Vector3(0, 270 * 3.14 / 180, 0),
			};
		return lc;
	},
	defaultGameSettings: ((): ICreateGameOpts => {
		const name = "PG - " + (new Date().getTime());
		return {
			name,
			declarePoints: 0,
			description: "",
			enableAutoPass: true,
			entryFee: 250,
			flowers: true,
			forFriends: true,
			gameTypeId: GameType.WP,
			hostSeat: false,
			minBelt: BeltType.White,
			money: false,
			playTimeSec: 3 * 60 * 60,
			rated: false,
			roundsCount: 0,
			showGameHelper: true,
			showWinningHandOnly: false,
			spectators: true,
			timeLimit: 10,
			waitForReconnect: false
		};
	})(),
	
	getCreateLimits({rounds}: { rounds: number }): ICreateFormLimits {
		const entryFee = rounds === 0 ? 250 : 500 * rounds;
		
		return {
			name: {maxLength: 15},
			timeLimit: {min: 5, max: 3600},
			// declarePoints: {min: 0, max: 8},
			entryFee: {min: entryFee, max: entryFee},
			roundsCount: [
				{data: 0, label: "One Game"},
				{data: 1, label: "Four Games"},
			]
		};
	},
	
	helpTips: [
		{icon: "ho_Joker", title: {key: "Lobby.HOverlays.GameRules.Joker_titile"}, msgShortRK: {key: "Lobby.HOverlays.GameRules.Joker_msg"}},
		{
			icon: "ho_StealJoker",
			title: {key: "Lobby.HOverlays.GameRules.StealJoker_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.StealJoker_msg"}
		},
		{
			icon: "ho_DeadHand",
			title: {key: "Lobby.HOverlays.GameRules.DeadHand_title"},
			msgShortRK: {key: "Lobby.HOverlays.GameRules.DeadHand_msg"}
		},
		{icon: "joker", title: {key: "Game.AutoPass.IgnoreJoker"}, msgShortRK: {key: "Lobby.HOverlays.GameRules.IgnoreJoker_msg"}},
		/*if (includeKeyboardTip) {
					tipSet[Enum.GameType.WP].push(
						{	icon: SkinLoader.getSkinItem('keyb'),
							title: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_title"),
							descr: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_msg"),
							descrFull: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_0")
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_space")}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pass")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "P"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.pung")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "K"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.kong")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "Q"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.quint")}])
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_key_for"), [{substr:"%%key%%", value: "M"}, {substr:"%%combination%%", value: CoreSettings.Res.getValue("Enum.GameMove.mahjong_am")}])
								+ "\n" + CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_j")
								+ "\n" + StringUtil.replace2(CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_e"), [
											{substr:"%%combination1%%", value: CoreSettings.Res.getValue("Enum.GameMove.pung") + ", " + CoreSettings.Res.getValue("Enum.GameMove.kong") + ", " + CoreSettings.Res.getValue("Enum.GameMove.quint")},
											{substr:"%%combination2%%", value: CoreSettings.Res.getValue("Enum.GameMove.mahjong_am")}])
								+ "\n" + CoreSettings.Res.getValue("Lobby.HOverlays.GameRules.Keyb_detail_ak_sp")
						}
					);
				}*/
	],
	
};




