import {ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {OnlineUserService} from "../net/service/OnlineUserService";
import {GameStore} from "../store/GameStore";
import {CurrencyType} from "../game/enums/CurrencyType";
import {MatDialog} from "@angular/material/dialog";
import {NotEnoughData, NotEnoughDialogComponent} from "../app/ui/dialogs/not-enough-dialog.component";
import {environment} from "../environments/environment";
import {WebPages} from "../app/enums/WebPages";

export class NotEnoughCommand extends ICommand {
	async execute({dialogService, currency, amount}: { dialogService: MatDialog, currency: CurrencyType, amount: number }): Promise<boolean> {
		try {
			const accBalance = this.userStore.getBalance(currency);
			const neData: NotEnoughData = {
				currency,
				fullAmount: amount,
				availableAmount: accBalance,
				requiredAmount: Math.ceil(amount - accBalance)
			};
			const dialogRef = dialogService.open(NotEnoughDialogComponent, {disableClose: true, data: neData});
			dialogRef.afterClosed().subscribe(value => {
				if (value) {
					switch (currency) {
						case CurrencyType.Usd:
							const moneyPaymentAmt = Math.ceil(neData.requiredAmount);
							window.open(`${environment.webRoot}/${WebPages.DEPOSIT}?sid=${this.userStore.sessionKey}&code=Deposit&amount=${moneyPaymentAmt}`, "_blank");
							break;
						case CurrencyType.DragonChip:
							const chipsPaymentAmt = Math.ceil(neData.requiredAmount / 1000);
							window.open(`${environment.webRoot}/${WebPages.BUY_CHIPS_AND_COINS}?sid=${this.userStore.sessionKey}&code=Chips&amount=${chipsPaymentAmt}`, "_blank");
							break;
						case CurrencyType.GoldenCoin:
							const coinsPaymentAmt = Math.ceil(neData.requiredAmount / 100);
							window.open(`${environment.webRoot}/${WebPages.BUY_CHIPS_AND_COINS}?sid=${this.userStore.sessionKey}&code=Coins&amount=${coinsPaymentAmt}`, "_blank");
							break;
						default:
							console.warn("NotEnoughCommand.: unknown currency: " + currency);
					}
				}
				else {
					// just close()
				}
			});
		}
		catch (e) {
			console.warn("UpdateOnlineStatusCommand.execute: " + e);
		}
		return true;
	}
	
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
	private get onlineUserService(): OnlineUserService {
		return container.get<OnlineUserService>(TYPES.OnlineUserService);
	}
	
}
