import {ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {UserService} from "../net/service/UserService";
import {UserInfoStoreDTO} from "../net/dto/UserInfoStoreDTO";

export class GetInfoCommand extends ICommand {
	
	async execute(params?: any): Promise<UserInfoStoreDTO | false> {
		const sid = this.userStore.sessionKey;
		const info = await this.userService.getInfo(sid);
		
		this.userStore.setUserInfo(info);
		return info;
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get userService(): UserService {
		return container.get<UserService>(TYPES.UserService);
	}
}
