import {Entity} from "ecsy";
import {TileDataComponent, TileTypeComponent} from "../ecs/common/components";
import {TileType} from "../enums/TileType";
import {TileEntityHelper} from "./TileEntityHelper";
import {TileSetHelper} from "./TileSetHelper";

export class DiscardsTilesHelper {
	
	public static getDiscardTiles(alTiles: Array<Entity>): Array<Entity> {
		return alTiles.filter(tile => tile.getComponent(TileTypeComponent).value === TileType.DISCARD);
	}
	
	public static getSlotTile(allTiles: Array<Entity>): Entity | null {
		// Ensure 'allTiles' array is defined and log the result
		console.log("'allTiles' is defined:", !!allTiles);
	
		// If 'allTiles' is defined, log its length
		if (allTiles) {
			console.log("'allTiles' length:", allTiles.length);
		} else {
			console.error("Error: 'allTiles' array is not defined.");
			return null;
		}
	
		// Find the slot tile
		const slotTile = allTiles.find(tile => tile.getComponent(TileTypeComponent).value === TileType.SLOT);
	
		// Check if the slot tile was found and log the result
		if(slotTile) {
			console.log("Slot tile found: ", slotTile);
		} else {
			console.warn("No slot tile was found in the provided tiles.");
		}
	
		return slotTile;
	}
	
	public static addTile(tile: Entity, tileId: number, tileType: TileType, tiles: Array<Entity>): void {
		try {
			const tileW = TileEntityHelper.wrapEntity(tile);
			console.log(`DTH.addTile: ${tileW} with new id=${tileId} and type=${tileType}`);
			
			tileW.id = tileId;
			tileW.type = tileType;
	
			TileEntityHelper.linkPrevAndNextTileNodes(tile); // Unlink the tile from the old set
	
			// search for the last discard/slot tile to add new tile after
			const afterTile = TileSetHelper.getTile({tiles, tileType: tileW.type, nextNode: null, notTile: tile});
	
			// insert new tile after last tile or make new tile the first tile clearing the tile nodes
			if(afterTile){
				TileEntityHelper.insertTileAfter(tile, afterTile);
				console.log(`DTH.addTile: Inserted tile after tile with id ${afterTile.getComponent(TileDataComponent).fullId}`);
			} else {
				tileW.nodes.update(null, null);
				console.log("DTH.addTile: No afterTile found, updating tile nodes to null");
			}
		} catch (error) {
			console.error(`DTH.addTile: Error while adding tile with id ${tileId} and type ${tileType}:`, error);
		}
	}
		
}
