import {inject, injectable} from "inversify";
import {TYPES} from "../inversify/inversify.types";
import {GameGraphics} from "./GameGraphics";
import {UserStore} from "../store/UserStore";
import {GameStore} from "../store/GameStore";
import {GameWorld} from "./ecs/GameWorld";
import {Side} from "./enums/Side";
import {ChatGamController} from "./ChatGamController";
import {GameMessagesController} from "./GameMessagesController";
import {GameMessageDTO} from "../net/dto/GameMessageDTO";
import {GameType} from "./enums/GameType";

@injectable()
export class GameController {
	protected viewPlayerId: number;
	
	constructor(
		@inject(TYPES.GameStore) private readonly gameStore: GameStore,
		@inject(TYPES.UserStore) private readonly userStore: UserStore,
		@inject(TYPES.GameGraphics) private readonly gameGraphics: GameGraphics,
		@inject(TYPES.GameWorld) private readonly gameWorld: GameWorld,
		@inject(TYPES.ChatGamController) private readonly cgc: ChatGamController,
		@inject(TYPES.GameMessagesController) private readonly gameMessagesCtrl: GameMessagesController,
	) {
		console.log(`GameController.constructor: `);
	}
	
	/**
	 * Create all systems
	 * @param gameRules
	 */
	public prepareTable(gameRules: GameType) {
		console.log(`GameController.prepareTable: `);
		const playerId = this.userStore.id;
		this.gameStore.dealState.basePlayerId = playerId;
		this.gameStore.dealState.viewPlayerId = playerId;
		
		this.gameWorld.prepare(gameRules, playerId);
		this.gameWorld.world.play();
	}
	
	/**
	 * Resets all systems. Keep current rules.
	 */
	public reset() {
		this.gameWorld.reset();
		this.gameStore.reset();
	}
	
	/**
	 * remove all systems and dispose any resources.
	 */
	public dispose(): void {
		if (this.gameMessagesCtrl) {
			this.gameMessagesCtrl.dispose();
		}
		this.gameWorld.dispose();
		this.gameStore.reset();
	}
	
	// *************************************************************************************************************************
	public startTable(): void {
		console.log(`GameController.startTable: `);
		
		// set me as view side if I am among playersQ of the game, choose South player otherwise
		const playerId = this.gameStore.gameUsers.getUserById(this.userStore.id)?.UserId ?? this.gameStore.gameUsers.getPlayerByRealSide(Side.South).UserId;
		this.gameStore.dealState.basePlayerId = playerId;
		this.gameStore.dealState.viewPlayerId = playerId;
		//
		this.setViewPlayerId(this.gameStore.dealState.viewPlayerId);
		this.gameGraphics.viewSide(Side.South);
		
		const initMoves = this.gameStore.getInitMoves() ?? [];
		if (initMoves.length === 0) {
			this.startGame();
		}
		else {
			this.processMovesSilent(initMoves);
			this.startGame();
		}
	}
	
	private startGame() {
		console.log(`GameController.startGame: `);
		this.gameMessagesCtrl.setup(this.cgc, this.gameWorld);
	}
	
	private processMovesSilent(moves: Array<GameMessageDTO>): void {
		console.log(`GameController.processMovesSilent: `);
		this.gameWorld.addGameMessages(moves);
	}
	
	public setViewPlayerId(playerId: number): void {
		this.gameWorld.setViewPlayerId(playerId);
		// TODO: P
		/*const player = this.gameStore.tablePlayers.getPlayerById(playerId);
		if (player) {
			this.viewPlayerId = playerId;
			this.gameGraphics.viewSide(player.location);
		}
		else {
			console.warn("GameController.setViewPlayerId: there is no player with id=" + playerId);
		}*/
	}
	
	
}

