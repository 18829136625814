import {GameType} from "../game/enums/GameType";

type GameURLParams = { gameId: number, roomId: number, gameTypeId: GameType };

export class GameURL {
	static encode({gameId, roomId, gameTypeId}: GameURLParams) {
		const obj = {g: gameId, r: roomId, gt: gameTypeId};
		const objS = JSON.stringify(obj);
		const b64S = btoa(objS);
		return b64S;
	}

	/**
	 * Convert a base64 string to a GameURLParams object
	 * @param v base64-encoded string
	 * @returns object containing gameId, roomId, gameTypeId
	 */
	static decode(v: string): GameURLParams {
		const b64S = atob(v);
		const obj = JSON.parse(b64S);
		const obj2: GameURLParams = {gameId: obj.g, roomId: obj.r, gameTypeId: obj.gt};
		return obj2;
	}
}
