import {ChatGamController} from "./ChatGamController";
import {GameMessageDTO} from "../net/dto/GameMessageDTO";
import {Subscription} from "rxjs";
import {IDisposable} from "./IDisposable";
import {injectable} from "inversify";
import {GameWorld} from "./ecs/GameWorld";

type ProcessMode = "auto" | "step-by-step";

@injectable()
export class GameMessagesController implements IDisposable {
	
	private cgcSubscription: Subscription;
	private mode: ProcessMode;
	private cgc: ChatGamController;
	private gw: GameWorld;
	
	constructor() {
	
	}
	
	setup(cgc: ChatGamController, gw: GameWorld, mode: ProcessMode = "auto") {
		this.mode = mode;
		this.cgc = cgc;
		this.gw = gw;
		
		this.processGameMessages(); // first, need to proceed all already accumulated game messages
		this.cgcSubscription = this.cgc.gameMessagesAvailable$.subscribe(() => {
			this.processGameMessages();
		}); // subscribe for the game messages updates
	}
	
	/**
	 * Unsubscribe all subscriptions and release all resources
	 */
	public dispose(): void {
		if (this.cgcSubscription) {
			this.cgcSubscription.unsubscribe();
		}
		this.cgc = null;
		this.gw = null;
	}
	
	public processGameMessages(): boolean {
		let gameMessage = this.cgc.takeGameMessage();
		while (gameMessage) {
			// const gm = new GameMessageModel(gameMessage);
			this.processGameMessage(gameMessage);
			gameMessage = this.cgc.takeGameMessage();
		}
		return true;
	}
	
	processGameMessage(gameMessage: GameMessageDTO): void {
		console.log(`GameMessagesController.processGameMessage: ${gameMessage.Id}: ${gameMessage}`);
		this.gw.addGameMessages([gameMessage]);
	}
	
}
