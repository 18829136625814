import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {GameType} from "../../game/enums/GameType";

@Injectable({
	providedIn: "root",
})
export class GameTypeChildGuard implements CanActivateChild {
	
	constructor(public router: Router) {
	}
	
	/**
	 *
	 * @param next -- the next route that will be activated if the guard is allowing access,
	 * @param state -- the next router state if the guard is allowing access.
	 */
	canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		const gameTypeName = next?.pathFromRoot[1]?.params?.gameType?.toUpperCase();
		if (gameTypeName && !isNaN(+GameType[gameTypeName])) {
			console.log("GameTypeChildGuard.canActivateChild: gameTypeName=" + gameTypeName);
			return true;
		}
		else {
			console.log("GameTypeChildGuard.canActivateChild: Can't activate: " + gameTypeName);
			return this.router.parseUrl("");
		}
	}
	
}
