import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {NavigationService} from "../services/navigation.service";

@Injectable({
	providedIn: "root",
})
export class NavigationGuard implements CanDeactivate<any> {
	constructor(private navigationService: NavigationService) {
	}
	
	canDeactivate(
		component: any,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const allowed = this.navigationService.navigationAllowed;
		if (!allowed) {
			console.warn("DeactivatableGuard.canDeactivate: cannot leave " + currentState.url + " for " + nextState?.url);
		}
		return allowed;
	}
	
}
