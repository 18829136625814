import {Attributes, System} from "ecsy";
import {IExtSystem} from "../IExtSystem";
import {IGameAction} from "./GameAction";
import {TileDataComponent} from "./components";
import {GameStorageQuery, PlayersQuery} from "../queries";
import {GameStorageWrapper} from "../wrappers/GameStorageWrapper";

export class GameActionsSystem extends System implements IExtSystem {
	private newActionsCollector: Array<IGameAction> = [];
	
	init(attributes?: Attributes): void {
	
	}
	
	execute(delta: number, time: number): void {
		if (!this.gameStorageQW.gameActions.justProcessed.isEmpty) {
			this.gameStorageQW.gameActions.justProcessed.clear();
		}
		
		if (!this.gameStorageQW.gameActions.pending.isEmpty) {
			try {
				const action: IGameAction = this.gameStorageQW.gameActions.pending.getFirst();
				console.log("GameActionsSystem.exec: " + (action.constructor.name));
				action.execute();
				this.gameStorageQW.gameActions.justProcessed.add(action);
			}
			catch (error) {
				console.error("GameActionsSystem.execute() -- error exec action: ", error);
			}
		}
		else if (this.newActionsCollector.length === 0) {
			console.log("GameActionsSystem: no actions. stop processing.");
			this.stop();
		}
		
		if (this.newActionsCollector.length > 0) {
			this.gameStorageQW.gameActions.pending.add(...this.newActionsCollector);
			this.newActionsCollector = [];
		}
	}
	
	unregister(): void {
		this.newActionsCollector = [];
	}
	
	
	addAction(action: IGameAction) {
		this.newActionsCollector.push(action);
		if (!this.enabled) {
			this.play();
		}
	}
	
	addActions(actions: Array<IGameAction>) {
		this.newActionsCollector = this.newActionsCollector.concat(actions);
		if (!this.enabled) {
			this.play();
		}
	}
	
	protected get gameStorageQW(): GameStorageWrapper {
		const gameStorage = this.queries.gameStorage.results[0];
		return gameStorage ? new GameStorageWrapper(gameStorage) : null;
	}
	
}

GameActionsSystem.queries = {
	tiles: {components: [TileDataComponent]},
	players: PlayersQuery,
	gameStorage: GameStorageQuery,
};
