export enum ActionMoveType {
	
	PUT_TILE = "put_tile",
	PASS = "pass",
	CHOW = "chow",
	CHOW_1 = "chow1",
	CHOW_2 = "chow2",
	CHOW_3 = "chow3",
	PUNG = "pung",
	KONG = "kong",
	// Used to declare kong of WhiteDragons in AM games.
	KONG_1 = "kong1",
	KONG_SELF = "kong_self",
	KONG_SELF_CONCEALED = "kong_self_concealed",
	KONG_SELF_PROMOTED = "kong_self_prom",
	QIUNT = "quint",
	SEXTET = "sextet",
	MAHJONG = "mahjong",
	MAHJONG_SELF = "mahjong_self",
	DEFINE_JOKER = "define_joker",
	UNDEFINE_JOKER = "undefine_joker",
	REDEEM_JOKER = "redeem_joker",
	READY = "ready",
	
	REPLACE_FLOWER = "replace_flower",
	// Sanma version. Replace North Wind as Flower in CO
	REPLACE_WIND = "replace_wind",
	USER_DECLARE_DRAW = "user_declare_draw",
	DEAD_HAND = "dead_hand",
	SEND_TILE = "send_tile",
	
	CHARLESTON_3 = "charlestone_3",
	CHARLESTON_0 = "charlestone_0",
	CHARLESTON_1 = "charlestone_1",
	CHARLESTON_2 = "charlestone_2",
	
}
