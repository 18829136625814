import {Attributes, Entity, System} from "ecsy";
import {GameStore} from "../../../store/GameStore";
import {UserStore} from "../../../store/UserStore";
import {GameEvent, GameEventsPipe, GameEventType} from "../../GameEventsPipe";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {
	ChangePlayerPointsAction,
	DealTurnAction,
	EmptyAction,
	EndDealAction,
	GameMoveAction,
	IGameAction,
	NewDealAction,
	RemoveHighLight,
	RemoveMultipleChoiceDialog,
	SlotToConcealed,
	SortTilesAction,
	StartDealAction,
	SystemPrivateMessage,
	TileSubSetType,
	UpdateDeadWall,
	UpdateDealState,
	UpdateDisplayedMoveNow,
	UpdatePlayersPointsAction,
	UpdatePlayerTilesAction,
	UpdateWallTiles,
	UserCanMakeMove
} from "./GameAction";
import {GameWorld} from "../GameWorld";
import {GraphicsComponent, PlayerIdComponent, TileDataComponent, TilePositionComponent} from "./components";
import {MoveType, MoveType2Name} from "../../enums/MoveType";
import {
	dealThrownDice,
	dealWind,
	endDeal,
	endGame,
	endShowTile,
	endTakeBlock,
	fromConcealedToMeldedHidden,
	fromConcealedToMeldedOrFlower,
	fromConcealedToSlot,
	fromSlotToDiscards,
	fromSlotToMelded,
	fromWallEndToConcealed,
	fromWallEndToMeldedOrFlower,
	fromWallToConcealed,
	fromWallToMeldedOrFlower,
	gameState,
	makeMove,
	newRating,
	showTileConcealed,
	showTileMelded,
	startShowTile,
	userCanMakeMove
} from "./common.commands";
import {GameMessageDTO} from "../../../net/dto/GameMessageDTO";
import {WallTilesHelper} from "../../helpers/WallTilesHelper";
import {PlayerLayoutHelper} from "../../helpers/PlayerLayoutHelper";
import {GameGraphics} from "../../GameGraphics";
import {IExtSystem} from "../IExtSystem";
import {AppEventsPipe, AppEventType} from "../../AppEventsPipe";
import {TileType} from "../../enums/TileType";
import {GameStorageQuery, PlayersQuery} from "../queries";
import {GameStorageWrapper} from "../wrappers/GameStorageWrapper";
import {Side} from "../../enums/Side";
import {actionT} from "../ActionMenuSystem";
import {DealStage, getDealStageByDealState} from "../../enums/DealStage";
import {PlayersArray} from "../wrappers/PlayersArray";
import {IMakeMove, NetGameManager} from "../../NetGameManager";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {IGameSnapshotOptions} from "../../interfaces/IGameSnapshotOptions";
import {MoveNowType} from "../../enums/MoveNowType";
import {IMoveNowUpdatedParams} from "../../interfaces/IMoveNowUpdatedParams";
import {DealDiceCalcFn, TilesSortFn} from "../../interfaces/TilesSortFn";
import {ICanMakeMove} from "../../interfaces/ICanMakeMove";
import {DiscardsMode} from "../../enums/DiscardsMode";
import {environment} from "../../../environments/environment";
import {GameType} from "../../enums/GameType";

export abstract class GameRulesAbstractSystem extends System implements IExtSystem {
	
	protected playerLayoutHelper: PlayerLayoutHelper;
	protected gameGraphics: GameGraphics;
	protected gw: GameWorld;
	
	private gameWorld: GameWorld;
	protected gameStore: GameStore;
	protected userStore: UserStore;
	protected gameEvents: GameEventsPipe;
	protected appEvents: AppEventsPipe;
	protected netManager: NetGameManager;
	private destroy$ = new Subject<boolean>();
	
	private commands = {};
	public tileSet: Entity[];
	
	private newMessagesCollector: GameMessageDTO[] = [];
	
	protected static parseIds(ids: string): Array<number> {
		return ids.split("^").map(s => parseInt(s, 10));
	}
	
	// System implementation
	init(attributes?: Attributes): void {
		this.playerLayoutHelper = attributes.plh;
		this.gw = attributes.gameWorld;
		this.gameGraphics = attributes.gameGraphics;
		
		this.gameWorld = attributes.gameWorld;
		this.gameStore = attributes.gameStore;
		this.userStore = attributes.userStore;
		this.appEvents = attributes.appEvents;
		this.gameEvents = attributes.gameEvents;
		this.netManager = attributes.netManager;
		this.gameEvents.events
			.pipe(takeUntil(this.destroy$))
			.subscribe(value => this.onGameEvent(value));
		try {
			this.commands = this.setupCommands();
			this.setupRules();
			this.createTileSet();
		}
		catch (e) {
			console.error("GameRulesAbstractSystem.init: " + e);
		}
	}
	
	unregister(): void {
		try {
			this.destroy$.next();
			this.destroy$.complete();
			this.removeTileSet();
		}
		catch (e) {
			console.error("GameRulesAbstractSystem.unregister: " + e);
		}
	}
	
	execute(delta: number, time: number): void {
		if (!this.gameStorageQW.gameMessages.justProcessed.isEmpty) {
			try {
				this.gameStorageQW.gameMessages.completed.add(...this.gameStorageQW.gameMessages.justProcessed.getAll());
				this.gameStorageQW.gameMessages.justProcessed.clear();
			}
			catch (e) {
				console.error("GameRulesAbstractSystem.execute: gameMessages.justProcessed: " + e);
			}
		}
		
		if (!this.gameStorageQW.gameMessages.pending.isEmpty) {
			try {
				const toProcess = this.gameStorageQW.gameMessages.pending.getAll();
				this.gameStorageQW.gameMessages.pending.clear();
				
				let newActions: Array<IGameAction> = [];
				toProcess.forEach(gm => {
					
					newActions = newActions.concat(this.convertToActions(gm));
					this.verifyMoveNow(gm);
				});
				this.gw.addActions(newActions);
				
				this.gameStorageQW.gameMessages.justProcessed.set(toProcess);
			}
			catch (e) {
				console.error("GameRulesAbstractSystem.execute: gameMessages.pending: " + e);
			}
		}
		
		// after pending game messages have been parsed
		// move new collected game messages to pending stack
		if (this.newMessagesCollector.length > 0) {
			this.gameStorageQW.gameMessages.pending.add(...this.newMessagesCollector);
			this.newMessagesCollector = [];
		}
		
		/*// convert all available game messages to actions and add them
		if (this.gameStorageQW.gameMessages.hasPending) {
			let newActions: Array<IGameAction> = [];
			this.gameStorageQW.gameMessages.getPending().forEach(gm => {
				newActions = newActions.concat(this.convertToActions(gm));
				this.checkMoveTimer(gm.Type, gm.UserId);
			});
			this.gw.addActions(newActions);
			this.gameStorageQW.gameMessages.clearPending();
		}
		
		// after pending game messages have been parsed
		// move new collected game messages to pending stack
		if (this.newMessagesCollector.length > 0) {
			this.gameStorageQW.gameMessages.addPending(this.newMessagesCollector);
			this.newMessagesCollector = [];
		}*/
	}
	
	private onGameEvent(value: GameEvent) {
		if (environment.isDebug) {
			console.log("GameRulesAbstractSystem.onGameEvent: " + value?.type + ": " + GameEventType[value?.type]);
		}
		try {
			switch (value.type) {
				case GameEventType.Action_EndGame:
					this.gw.addAction(new EndDealAction(this.gameEvents, this.gameStorageQW, this.playersQRA));
					break;
				case GameEventType.MakeMove_Started:
					this.onMakeMoveStarted(value.data);
					break;
				case GameEventType.MakeMove_Succeed:
					this.onMakeMoveResult(true, value.data);
					break;
				case GameEventType.MakeMove_Failed:
					this.onMakeMoveResult(false, value.data);
					break;
			}
		}
		catch (e) {
			console.error("GameRulesAbstractSystem.onGameEvent: " + e);
		}
	}
	
	protected onMakeMoveStarted(move: IMakeMove): void {
		this.gameStorageQW.turnState.makingMove = true;
	}
	
	protected onMakeMoveResult(success: boolean, move: IMakeMove): void {
		console.log("GameRulesAbstractSystem.onMakeMoveResult: " + success + ", move=", move);
		this.gameStorageQW.turnState.makingMove = false;
		if (success) {
			// make sure to not process the results of the previous move.
			if (move.dealTurn === this.gameStorageQW.dealState.dealTurn) {
				if (move.move === ActionMoveType.PASS) {
					this.gameStorageQW.turnState.waitingForNextMove = true;
				}
				else {
				}
				this.playersQRA.getPlayerById(this.gameStorageQW.myPlayerId).actions = []; // clear available actions for the player.
				// In case the player has another available moves
				// we will get another UserCanMakeMove move from server and display a new set of available actions
				// *
				// we should not remove available actions for ALL players at this point, because some of them can still make move (in archive mode)
				// it depends on server which move will be accepted. we can know this from MAKE_MOVE move later.
			}
		}
		else {
			this.gameStorageQW.turnState.waitingForNextMove = false;
		}
	}
	
	protected abstract setupRules();
	
	protected setupCommands() {
		const cmd = {};
		/*
		11: [GameMessageDTO {id=11, gameId=377767, userId=1, type=28: START_GAME, message=begin, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		12: [GameMessageDTO {id=12, gameId=377767, userId=1, type=66: GAME_STATE, message=30, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		13: [GameMessageDTO {id=13, gameId=377767, userId=1, type=28: START_GAME, message=end, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		14: [GameMessageDTO {id=14, gameId=377767, userId=1, type=26: NEW_DEAL, message=1, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		15: [GameMessageDTO {id=15, gameId=377767, userId=181, type=67: USER_SIDE, message=n, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		16: [GameMessageDTO {id=16, gameId=377767, userId=182, type=67: USER_SIDE, message=e, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		17: [GameMessageDTO {id=17, gameId=377767, userId=183, type=67: USER_SIDE, message=s, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		18: [GameMessageDTO {id=18, gameId=377767, userId=186, type=67: USER_SIDE, message=w, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		19: [GameMessageDTO {id=19, gameId=377767, userId=1, type=68: DEAL_THROWN_DICE, message=661, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		20: [GameMessageDTO {id=20, gameId=377767, userId=1, type=25: NEW_DEAL_WIND, message=e, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		21: [GameMessageDTO {id=21, gameId=377767, userId=182, type=14: FROM_WALL_TO_CONCEALED, message=101^101^101^101, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		22: [GameMessageDTO {id=22, gameId=377767, userId=182, type=54: END_TAKE_BLOCK, message=, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		     ...
		53: [GameMessageDTO {id=53, gameId=377767, userId=182, type=14: FROM_WALL_TO_CONCEALED, message=101, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		55: [GameMessageDTO {id=55, gameId=377767, userId=181, type=70: TIME_BANK, message=0|30, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		56: [GameMessageDTO {id=56, gameId=377767, userId=182, type=70: TIME_BANK, message=0|30, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		57: [GameMessageDTO {id=57, gameId=377767, userId=183, type=70: TIME_BANK, message=0|30, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		58: [GameMessageDTO {id=58, gameId=377767, userId=186, type=70: TIME_BANK, message=0|0, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		59: [GameMessageDTO {id=59, gameId=377767, userId=1, type=30: USER_ID_MOVE_NOW, message=182, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		60: [GameMessageDTO {id=60, gameId=377767, userId=1, type=29: START_DEAL, message=start_deal, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		61: [GameMessageDTO {id=61, gameId=377767, userId=1, type=69: DEAL_TURN, message=0, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}]
		*/
		// cmd[MoveType.START_GAME] =; // begin
		// cmd[MoveType.GAME_STATE] =;
		// cmd[MoveType.START_GAME] =; // end
		cmd[MoveType.NEW_DEAL] = this.newDeal; //
		cmd[MoveType.USER_SIDE] = this.userSide; // GameMessageDTO {id=16, gameId=377767, userId=182, type=67: USER_SIDE, message=e, time=Mon Nov 30 2020 18:42:11 GMT-0500 (Eastern Standard Time)}
		cmd[MoveType.DEAL_THROWN_DICE] = dealThrownDice;
		cmd[MoveType.NEW_DEAL_WIND] = dealWind;
		cmd[MoveType.START_DEAL] = this.startDeal;
		cmd[MoveType.CHANGE_USER_POINTS] = this.changeUserPoints;
		
		cmd[MoveType.GAME_SNAPSHOT_OPTIONS] = this.gameSnapshotOptions;
		cmd[MoveType.GAME_SNAPSHOT_PROCESSING_END] = this.gameSnapshotProcessingEnd;
		cmd[MoveType.TIME_BANK] = this.timeBank;
		
		cmd[MoveType.MAKE_MOVE] = makeMove;
		cmd[MoveType.FROM_WALL_TO_CONCEALED] = fromWallToConcealed;
		cmd[MoveType.FROM_WALL_TO_MELDED] = fromWallToMeldedOrFlower;
		cmd[MoveType.FROM_CONCEALED_TO_MELDED] = fromConcealedToMeldedOrFlower;
		cmd[MoveType.FROM_CONCEALED_TO_MELDED_HIDDEN] = fromConcealedToMeldedHidden;
		
		// cmd[MoveType.FROM_WALL_TO_CONCEALED] = fromWallToConcealed;
		cmd[MoveType.FROM_DEADWALL_TO_CONCEALED] = fromWallEndToConcealed;
		// cmd[MoveType.FROM_WALL_TO_MELDED] = fromWallToMeldedOrFlower;
		cmd[MoveType.FROM_DEADWALL_TO_MELDED] = fromWallEndToMeldedOrFlower;
		cmd[MoveType.FROM_CONCEALED_TO_SLOT] = fromConcealedToSlot;
		cmd[MoveType.FROM_SLOT_TO_DISCARDS] = fromSlotToDiscards;
		cmd[MoveType.END_TAKE_BLOCK] = endTakeBlock;
		cmd[MoveType.USER_CAN_MAKE_MOVE] = userCanMakeMove;
		// cmd[MoveType.MAKE_MOVE] = makeMove;
		cmd[MoveType.DEAL_TURN] = this.dealTurn;
		cmd[MoveType.USER_ID_MOVE_NOW] = this.userIdMoveNow;
		// cmd[MoveType.GAME_STATE] = gameState;
		// cmd[MoveType.FROM_CONCEALED_TO_MELDED] = fromConcealedToMelded;
		// cmd[MoveType.FROM_CONCEALED_TO_MELDED] = fromConcealedToMeldedOrFlower_AndNextTile;
		cmd[MoveType.FROM_SLOT_TO_MELDED] = fromSlotToMelded;
		cmd[MoveType.FROM_SLOT_TO_CONCEALED] = this.fromSlotToConcealed;
		
		cmd[MoveType.START_SHOW_TILE] = startShowTile;
		cmd[MoveType.SHOW_TILE] = showTileConcealed;
		cmd[MoveType.SHOW_TILE_MELDED] = showTileMelded;
		cmd[MoveType.END_SHOW_TILE] = endShowTile;
		/*End Deal:
		Message: id=1064	 userId=1	 type=END_SHOW_TILE(22)	 message=
		Message: id=1065	 userId=1	 type=END_DEAL(27)	 message=end_deal
		Message: id=1066	 userId=1	 type=GAME_STATE(66)	 message=40*/
		/*End Game 1:
		Message: id=378	 userId=1	 type=END_SHOW_TILE(22)	 message=
		Message: id=379	 userId=1	 type=END_DEAL(27)	 message=end_deal
		Message: id=380	 userId=1	 type=GAME_STATE(66)	 message=40
		Message: id=381	 userId=305998	 type=WON_CHIP(73)	 message=0.00|20.00
		Message: id=382	 userId=418260	 type=WON_CHIP(73)	 message=0.00|20.00
		Message: id=383	 userId=930185	 type=WON_CHIP(73)	 message=1600.00|20.00
		Message: id=384	 userId=935573	 type=WON_CHIP(73)	 message=0.00|5.00
		Message: id=385	 userId=1	 type=GAME_STATE(66)	 message=50
		Message: id=386	 userId=935573	 type=NEW_RATING(49)	 message=6.01
		Message: id=387	 userId=305998	 type=INC_RATING(76)	 message=27.77
		Message: id=388	 userId=305998	 type=NEW_RATING(49)	 message=55.66
		Message: id=389	 userId=930185	 type=INC_RATING(76)	 message=15.52
		Message: id=390	 userId=930185	 type=NEW_RATING(49)	 message=30.79
		Message: id=391	 userId=418260	 type=INC_RATING(76)	 message=43.27
		Message: id=392	 userId=418260	 type=NEW_RATING(49)	 message=86.72
		Message: id=393	 userId=1	 type=END_GAME(23)	 message=end_game*/
		/*End Game 2:
		Message: id=1177	 userId=1	 type=END_SHOW_TILE(22)	 message=^
		Message: id=1178	 userId=1	 type=GAME_STATE(66)	 message=40
		Message: id=1179	 userId=1	 type=END_DEAL(27)	 message=end_deal
		Message: id=1180	 userId=354996	 type=WON_CHIP(73)	 message=1900.00|5.00
		Message: id=1181	 userId=605125	 type=WON_CHIP(73)	 message=0.00|0.00
		Message: id=1182	 userId=865854	 type=WON_CHIP(73)	 message=0.00|5.00
		Message: id=1183	 userId=932962	 type=WON_CHIP(73)	 message=0.00|0.00
		Message: id=1184	 userId=1	 type=GAME_STATE(66)	 message=50
		Message: id=1185	 userId=865854	 type=NEW_RATING(49)	 message=158.25
		Message: id=1186	 userId=354996	 type=NEW_RATING(49)	 message=121.33
		Message: id=1187	 userId=932962	 type=NEW_RATING(49)	 message=62.84
		Message: id=1188	 userId=605125	 type=NEW_RATING(49)	 message=81.36
		Message: id=1189	 userId=1	 type=END_GAME(23)	 message=end_game*/
		cmd[MoveType.END_DEAL] = endDeal;
		cmd[MoveType.GAME_STATE] = gameState;
		// case MoveTypes.ID.INC_RATING:		actionIncRating(gameMessageModel);		break;
		// case MoveTypes.ID.NEW_RATING:		actionNewRating(gameMessageModel);		break;
		cmd[MoveType.NEW_RATING] = newRating;
		// case MoveTypes.ID.WON_CHIP:		actionWonChip(gameMessageModel);		break;
		// case MoveTypes.ID.WON_USD:		actionWonUSD(gameMessageModel);		break;
		// case MoveTypes.ID.WON_BONUS_CHIP:		actionWonChipBonus(gameMessageModel);		break;
		
		cmd[MoveType.END_GAME] = endGame;
		
		cmd[MoveType.SYSTEM_PRIVATE_MESSAGE] = this.systemPrivateMessage;
		//processMove.  peekId=377  from=1  ||   userID=186     Type=SYSTEM_PRIVATE_MESSAGE (56)     Message=KickWarning
		//processMove.  peekId=480  from=4  ||   userID=186     Type=SYSTEM_PRIVATE_MESSAGE (56)     Message=Kick
		
		return cmd;
	}
	
	private convertToActions(gameMessage: GameMessageDTO): Array<IGameAction> {
		console.log("GameRulesAbstractSystem.convertToActions: " + gameMessage);
		const actions = [new GameMoveAction(gameMessage, this.gameStorageQW)];
		if (this.commands.hasOwnProperty(gameMessage.Type)) {
			try {
				actions.push(...(this.commands[gameMessage.Type]).call(this, gameMessage));
			}
			catch (error) {
				console.error("GameRulesAbstractSystem.convertToActions: Error converting game message: " + gameMessage);
			}
		}
		else {
			console.warn(`GameRulesAbstractSystem.convertToActions: Can't convert to actions -- Unknown game message type: ${gameMessage.Type} - ${MoveType2Name(gameMessage.Type)}`);
		}
		return actions;
	}
	
	/** Update moveNowType and moveNowPlayerId values depending on game message processed */
	private verifyMoveNow(gameMessage: GameMessageDTO): void {
		let moveNowType: MoveNowType;
		let moveNowPlayerId: number;
		
		switch (gameMessage.Type) {
			case MoveType.FROM_WALL_TO_CONCEALED:
			case MoveType.FROM_DEADWALL_TO_CONCEALED:
			// case MoveType.FROM_SLOT_TO_MELDED: // see MAKE_MOVE
			case MoveType.FROM_SLOT_TO_CONCEALED: // user received tile during Redeem_Joker. Also REDEEM_JOKER_END can be used to update moveNow
			case MoveType.MAKE_MOVE: // One of user made a declaration (Chow/*) and now it is his turn
				moveNowType = MoveNowType.PLAYER;
				moveNowPlayerId = gameMessage.UserId;
				break;
			case MoveType.FROM_CONCEALED_TO_SLOT:
				moveNowType = MoveNowType.SLOT;
				moveNowPlayerId = gameMessage.UserId;
				break;
			case MoveType.USER_ID_MOVE_NOW:
				moveNowType = MoveNowType.PLAYER;
				moveNowPlayerId = +gameMessage.Message;
				break;
			case MoveType.USER_CAN_MAKE_MOVE:
				if (gameMessage.Message === ActionMoveType.PUT_TILE) {
					this.gameStorageQW.moveNowState.moveNowType = MoveNowType.PLAYER;
					this.gameStorageQW.moveNowState.moveNowPlayerId = gameMessage.UserId;
				}
				break;
		}
		if (moveNowType) {
			console.log(`GameRulesAbstractSystem.checkMoveNow: set type=${moveNowType} and playerId=${moveNowPlayerId} on ${gameMessage.Type}:` + MoveNowType[gameMessage.Type]);
			this.updateMoveNow(moveNowType, moveNowPlayerId);
		}
	}
	
	private updateMoveNow(moveNowType: MoveNowType, moveNowPlayerId: number) {
		console.log(`GameRulesAbstractSystem.updateMoveNow: ${moveNowType}, ${moveNowPlayerId}`);
		// updated store values
		this.gameStorageQW.moveNowState.moveNowType = moveNowType;
		this.gameStorageQW.moveNowState.moveNowPlayerId = moveNowPlayerId;
		// notify (by ex. game info ui)
		this.sendGameEvent(GameEventType.MoveNow_Updated, {
			moveNowType,
			moveNowPlayerId,
			moveNowPlayerName: (moveNowPlayerId ? this.playersQRA.getPlayerById(moveNowPlayerId).name : undefined)
		} as IMoveNowUpdatedParams);
	}
	
	public addGameMessages(gameMessages: Array<GameMessageDTO>) {
		console.log("GameRulesAbstractSystem.addGameMessages: " + gameMessages?.length);
		this.newMessagesCollector.push(...gameMessages);
	}
	
	public createTileSet(): void {
		const tableOptions = this.gameStorageQW.tableOptions;
		this.tileSet = WallTilesHelper.generateTileSet(tableOptions.singleWallLength * 8, tableOptions.hasDeadWall, this.gw.world, this.gameGraphics);
		this.gw.addAction(new UpdateWallTiles(this.tileSet, tableOptions.singleWallLength));
	}
	
	public removeTileSet() {
		WallTilesHelper.removeTileSet(this.tileSet, this.gw.world, this.gameGraphics);
		this.tileSet = [];
	}
	
	/**
	 * Creates a new array of actions to display and declare. Some actions like kong and kong_self have single action to display (kong)
	 * but different to declare (kong vs kong_self)
	 * Converts display MJS -> MJ, declare move remains unchanged (note: JM* games should have unchanged moves to display)
	 * @param moves Array of possible actions received from server
	 * @return new array with displayActionId and declareActionId values
	 */
	protected avActionsMap(moves: ActionMoveType[]): ICanMakeMove[] {
		const avMoves: ICanMakeMove[] = [];
		const hasMove = (key: ActionMoveType, array: ICanMakeMove[]) => {
			return ~array.findIndex(move => move.declare === key);
		};
		
		if (moves.length === 1 && moves[0] === ActionMoveType.PASS) {
			moves.shift();
		}
		if (moves.length === 1 && moves[0] === ActionMoveType.PUT_TILE) {
			moves.shift();
		}
		
		moves.forEach(move => {
			switch (move) {
				case ActionMoveType.CHOW_1:
				case ActionMoveType.CHOW_2:
				case ActionMoveType.CHOW_3:
					// NOTE: looks like server send only chow1, even if user has chow2 or chow3 or even multiple chows
					if (!hasMove(ActionMoveType.CHOW, avMoves)) {
						avMoves.push({display: ActionMoveType.CHOW, declare: ActionMoveType.CHOW});
					}
					break;
				case ActionMoveType.KONG:
				case ActionMoveType.KONG_SELF:
					if (!hasMove(ActionMoveType.KONG, avMoves)) {
						avMoves.push({display: ActionMoveType.KONG, declare: move});
					}
					break;
				case ActionMoveType.MAHJONG_SELF: // !! All rules besides JM* display MJ instead of MJS
					avMoves.push({display: ActionMoveType.MAHJONG, declare: move});
					break;
				default:
					avMoves.push({display: move, declare: move});
			}
		});
		return avMoves;
	}
	
	protected sendGameEvent(type: GameEventType, data?): void {
		this.gameEvents.send(type, data);
	}
	
	protected sendAppEvent(type: AppEventType, data?): void {
		this.appEvents.send(type, data);
	}
	
	protected newDeal(gameMessage: GameMessageDTO) {
		this.gameStorageQW.dealState.dealNum = +gameMessage.Message;
		
		const actions: Array<IGameAction> = this.playersQRA.map(player =>
			new UserCanMakeMove(player.entity, [])
		);
		actions.push(
			new NewDealAction({tiles: this.tileSet, gameStorageQW: this.gameStorageQW, gameEvents: this.gameEvents}),
			new EmptyAction(),
			new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.singleWallLength, TileSubSetType.ALL_TILES),
			new EmptyAction()
		);
		return actions;
	}
	
	private userSide(gameMessage: GameMessageDTO) {
		const pl = this.gameStore.gameUsers.getUserById(gameMessage.UserId);
		console.info(`GameRulesAbstractSystem.userSide: ${pl.Name}:${gameMessage.UserId}  change seat from '${pl.Side}' to ${gameMessage.Message}`);
		this.gameStore.gameUsers.updateUser(pl.UserId, {Side: gameMessage.Message as Side});
		return [];
	}
	
	protected startDeal(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new SortTilesAction(this.gameStore.dealState.viewPlayerId, this.playerLayoutHelper, this.tileSet, this.gameStorageQW.tableOptions.concealedSortFn),
			new UpdatePlayerTilesAction(this.getPlayerEntity(this.gameStore.dealState.viewPlayerId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet),
			new StartDealAction({gameStorage: this.gameStorageQW, players: this.playersQRA, appEventsPipe: this.appEvents, w: this.world}),
		];
	}
	
	/** Riichi declaration should decrease the users points by 1000.
	 * When points are changing during the game there is a chat message �change_user_points� (code = 80) is sending.
	 * It sends also difference in points to add or subtract from the current user�s points (number 1000 to add or -1000 to subtract)
	 */
	private changeUserPoints(gameMessage: GameMessageDTO) {
		return [
			new ChangePlayerPointsAction(gameMessage.UserId, +gameMessage.Message, this.gameStore)
		];
	}
	
	protected gameSnapshotOptions(gameMessage: GameMessageDTO): Array<IGameAction> {
		const options = JSON.parse(gameMessage.Message) as IGameSnapshotOptions;
		const dealStage = getDealStageByDealState(options.dealState);
		console.log(`GameRulesAbstractSystem.gameSnapshotOptions: dealStage=${DealStage[dealStage]}, options=${gameMessage.Message}`);
		this.gameStorageQW.dealState.update({
			gameSnapshotProcessing: true,
			state: options.dealState,
			stage: dealStage,
			dealNum: options.currentDeal,
			dealTurn: 1,
			roundWind: options.roundWind,
		});
		if (this.gameStorageQW.tableOptions.hasDeadWall) {
			return [new UpdateDeadWall(this.tileSet, this.gameStorageQW)];
		}
		return [];
	}
	
	private gameSnapshotProcessingEnd(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.gameStorageQW.dealState.gameSnapshotProcessing = false;
		
		// set gameSnapshotProcessing param after all other action have been executed
		return [
			new UpdateDealState({gameSnapshotProcessing: false}, this.gameStorageQW),
			new UpdatePlayersPointsAction()
		];
	}
	
	private timeBank(gameMessage: GameMessageDTO): Array<IGameAction> {
		/* TimeBank Log.
		* Not sure what is 16/30.
		* Does 16 is max he can spend on this turn?
		* 1|29 - user actually user 1 sec from 30.
		Message: id=2614	 userId=938327	 type=TIME_BANK(70)	 message=1|29
		Message: id=2616	 userId=938327	 type=FROM_CONCEALED_TO_SLOT(8)	 message=42
		Message: id=2617	 userId=1	 type=DEAL_TURN(69)	 message=54
		...
		Message: id=2613	 userId=938327	 type=TIME_BANK(70)	 message=16|30
		10/27/2020 ► 00:27:30.046 ► [DEBUG] ► net.novowebsoft.game.mahjong.view.components.game.InGameAvs
				► onTimerHandler(false): secondsPassed > tbAvailableValue  |  8/7 t=29
		*/
		const [remains, total] = gameMessage.Message.split("|");
		this.playersQRA.getPlayerById(gameMessage.UserId).timeBank.available = +remains;
		this.playersQRA.getPlayerById(gameMessage.UserId).timeBank.total = +total;
		return [];
	}
	
	// **************************************************************************************************************************
	// *** Command Handlers *****************************************************************************************************
	// **************************************************************************************************************************
	private dealTurn(gameMessage: GameMessageDTO): Array<IGameAction> {
		// remove all available actions
		this.clearAllPlayerActions();
		
		this.gameStorageQW.turnState.waitingForNextMove = false;
		this.gameStorageQW.gameProcessEnv.makeMoveInProgress = undefined;
		
		return [
			new RemoveMultipleChoiceDialog(this.gameEvents), // remove dialog if user didn't chose anything
			new DealTurnAction(parseInt(gameMessage.Message, 10), this.gameStorageQW)
		];
	}
	
	protected userIdMoveNow(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new UpdateDisplayedMoveNow("player", +gameMessage.Message, this.gameStorageQW)
		];
	}
	
	protected fromSlotToConcealed(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new RemoveHighLight(gameMessage.UserId, this.tileSet),
			new SlotToConcealed(+gameMessage.Message, gameMessage.UserId, this.tileSet),
			new UpdatePlayerTilesAction(this.getPlayerEntity(gameMessage.UserId), TileType.CONCEALED, this.playerLayoutHelper, this.tileSet),
		];
	}
	
	protected systemPrivateMessage(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new SystemPrivateMessage({gameMessage, gameStorageQW: this.gameStorageQW, appEvents: this.appEvents}),
		];
	}
	
	// **************************************************************************************************************************
	private clearAllPlayerActions(): void {
		this.playersQRA.forEach(playerW => {
			playerW.actions = [];
		});
	}
	
	// **************************************************************************************************************************
	protected get players(): Entity[] {
		return this.queries.players.results;
	}
	
	protected getPlayerEntity(playerId: number): Entity {
		return this.players.find(playerEntity => playerEntity.getComponent(PlayerIdComponent).playerId === playerId);
	}
	
	protected get playersQRA(): PlayersArray {
		return new PlayersArray().setPlayerEntities(this.players);
	}
	
	protected getGameStorage(): Entity {
		return this.queries.gameStorage.results[0];
	}
	
	protected getGameStorageWrapper(): GameStorageWrapper {
		return new GameStorageWrapper(this.queries.gameStorage.results[0]);
	}
	
	public get gameStorageQW(): GameStorageWrapper {
		const gameStorage = this.queries.gameStorage.results[0];
		return gameStorage ? new GameStorageWrapper(gameStorage) : null;
	}
	
}


GameRulesAbstractSystem.queries = {
	entities: {
		components: [TileDataComponent, GraphicsComponent, TilePositionComponent]
	},
	
	players: PlayersQuery,
	gameStorage: GameStorageQuery,
};

enum GameRulesActions {
	UPDATE_PLAYERS,
	REMOVE_PLAYERS,
}

export interface IRulesOptions {
	gameType: GameType;
	actionsSet: Array<actionT>;
	maxPlayers: number;
	singleWallLength: number;
	hasDeadWall: boolean;
	deadWallLength?: number;
	sides: Side[];
	concealedSortFn?: TilesSortFn;
	dealDiceCalcFn: DealDiceCalcFn;
	discardsMode: DiscardsMode;
	isFlower: (tileId) => boolean;
	
	nextTileFromEnd_Enabled: boolean; // next tile should be taken from end of the wall
	nextTileFromEnd_MeldedFlower: boolean; // next tile from end after a flower goes to melded
	nextTileFromEnd_Kong: boolean; // next tile from end after kong declaration (kong replacement tile)
}

export enum ActionColors {
	PASS = 0xe8e8e8,
	CHOW = 0xaaff31,
	PUNG = 0x0468ff,
	KONG = 0xd053ff,
	QIUNT = 0xff53ff,
	SEXTET = 0xdefc08,
	MAHJONG = 0xff1818,
	READY = 0xff53ff,
	USER_DECLARE_DRAW = 0xdefc08,
}





