export class RewardPointsDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.RewardPoints";
	
	public Amount: number;
	public Crown: number;
	public NextCrownPts: number;
	public TotalBonusCoins: number;
	public TotalBonusUsd: number;
	public CurrentMonthAmt: number;
	public NextStepAmt: number;
	public NextStepBonusCoins: number;
	public NextStepBonusUsd: number;
}
