import {ResponseResultDTO} from "../dto/ResponseResultDTO";
import {LoginDTO} from "../dto/LoginDTO";
import {ErrorResultDTO} from "../dto/ErrorResultDTO";
import {LoginAppType} from "../../game/enums/LoginAppType";
import {ILoginService} from "./ILoginService";
import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";

export class LoginService extends AbstractAMFService implements ILoginService {
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.LoginModel", LoginDTO);
		
		// TODO: not a right place for registering these DTOs
		this.registerClassAlias("Mahjong.GameServer.Web.FluorineService.Error", ErrorResultDTO);
		this.registerClassAlias("Mahjong.GameServer.Web.FluorineService.ResponseResult", ResponseResultDTO);
	}
	
	public login(name: string, password: string, appType: LoginAppType): Promise<LoginDTO> {
		return this.send(LoginServices.LOGIN, [name, password, appType]);
	}
	
	public logout(sessionKey: string): Promise<boolean> {
		return this.send(LoginServices.LOGOUT, [sessionKey]);
	}
	
}

export class LoginServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.Authentication";
	public static readonly LOGIN = new ServiceDescriptor(LoginServices.NS, "Login");
	public static readonly LOGOUT = new ServiceDescriptor(LoginServices.NS, "Logout");
}
