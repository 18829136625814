export enum BeltType {
	White = 10,
	WhiteOneGreenStripe = 20,
	WhiteTwoGreenStripes = 30,
	Green = 40,
	GreenOneBrownStripe = 50,
	GreenTwoBrownStripes = 60,
	Brown = 70,
	BrownOneBlackStripe = 80,
	BrownTwoBlackStripes = 90,
	Black = 100,
}
