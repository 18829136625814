import {Side} from "../enums/Side";

export type TilesSortFn = (a: number, b: number) => number;


export type DealDiceCalcFn = (dices: string, singleWallLength: number, eastPlayerIsAtLocation: Side) => number;

/**
 * Calculates wall break point for the following game rules: HK, WS
 * @param dices
 * @param singleWallLength
 * @param eastPlayerIsAtLocation
 */
export const dealDiceCalcHK: DealDiceCalcFn = (dices: string, singleWallLength: number, eastPlayerIsAtLocation: Side = Side.East) => {
	if (dices.match(/^\d{3}$/) === null) { // verify dices string to contain correct number of digits
		console.warn(".dealDiceCalcHK: dices string does not match the template");
		return 0;
	}
	
	let sws = 0;
	switch (eastPlayerIsAtLocation) {
		case Side.South:
			sws = 1;
			break;
		case Side.East:
			sws = 2;
			break;
		case Side.North:
			sws = 3;
			break;
		case Side.West:
			sws = 0;
			break;
	}
	
	const dealDices: string[] = dices.split("");
	const dicesSum: number = (+dealDices[0] + (+dealDices[1]) + (+dealDices[2]));
	sws = (sws + dicesSum - 1) % 4 + 1;
	const spRaw = singleWallLength * 2 * sws - dicesSum * 2;
	const spFinal = (spRaw < 0) ? (singleWallLength * 8 - spRaw) : (spRaw);
	
	return spFinal;
};

/**
 * Calculates wall break point for the following game rules: CO, EC, EMA, RCR, SM, TW
 * @param dices
 * @param singleWallLength
 * @param eastPlayerIsAtLocation
 */
export const dealDiceCalcCO: DealDiceCalcFn = (dices: string, singleWallLength: number, eastPlayerIsAtLocation: Side = Side.East) => {
	if (dices.match(/^\d{4}$/) === null) { // verify dices string to contain correct number of digits
		console.warn(".dealDiceCalcCO: dices string does not match the template");
		return 0;
	}
	
	let sws = 0;
	switch (eastPlayerIsAtLocation) {
		case Side.South:
			sws = 1;
			break;
		case Side.East:
			sws = 2;
			break;
		case Side.North:
			sws = 3;
			break;
		case Side.West:
			sws = 0;
			break;
	}
	
	const dealDices: string[] = dices.split("");
	const dicesSum: number = +dealDices[0] + (+dealDices[1]);
	const dicesSum2: number = +dealDices[2] + (+dealDices[3]);
	sws = (sws + dicesSum - 1) % 4 + 1;
	const spRaw = singleWallLength * 2 * sws - dicesSum * 2 - dicesSum2 * 2;
	const spFinal = (spRaw < 0) ? (singleWallLength * 8 - spRaw) : (spRaw);
	
	return spFinal;
};

/**
 * Calculates wall break point for the following game rules: AM, AS
 * @param dices
 * @param singleWallLength
 * @param eastPlayerIsAtLocation
 */
export const dealDiceCalcWP: DealDiceCalcFn = (dices: string, singleWallLength: number, eastPlayerIsAtLocation: Side = Side.East) => {
	if (dices.match(/^\d{2}$/) === null) { // verify dices string to contain correct number of digits
		console.warn(".dealDiceCalcWP: dices string does not match the template");
		return 0;
	}
	
	let sws = 0;
	switch (eastPlayerIsAtLocation) {
		case Side.South:
			sws = 1;
			break;
		case Side.East:
			sws = 2;
			break;
		case Side.North:
			sws = 3;
			break;
		case Side.West:
			sws = 0;
			break;
	}
	
	const dealDices: string[] = dices.split("");
	const dicesSum: number = +dealDices[0] + (+dealDices[1]);
	sws += 1;
	const spRaw = singleWallLength * 2 * sws - dicesSum * 2;
	const spFinal = (spRaw < 0) ? (singleWallLength * 8 - spRaw) : (spRaw);
	
	return spFinal;
};