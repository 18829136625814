import {RewardPointsDTO} from "./RewardPointsDTO";
import {RewardPointsBonusDTO} from "./RewardPointsBonusDTO";
import {TournamentSeatsDTO} from "./tour/TournamentSeatsDTO";


export class UserAccountDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.UserAccount";
	
	// public CrownPts: number;
	public IsCash: boolean;
	public Maw: number;
	public MemEndDate: Date;
	public MemId: number;
	public MemStartDate: Date;
	public MoneyAmt: number;
	public UserId: number;
	public Coins: number;
	public Chips: number;
	public ChipsReloadSec: number;
	public ChipsReloadCount: number;
	public PlayedGames: number;
	public PlayedGamesWithoutEmail: number;
	
	public RewardPts: RewardPointsDTO;
	public RewardPtsBonus: RewardPointsBonusDTO;
	public TournamentSeats: TournamentSeatsDTO;
}
