import {InputRulesSystem} from "../common/InputRulesSystem";
import {IGameDialogButtonConfig, IGameDialogConfig, IGameDialogSubmitData} from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {
	GraphicsComponent,
	PlayerIdComponent,
	PlayerStoreComponent,
	TileDataComponent,
	TilePositionComponent,
	TileState,
	TileStoreComponent,
	TileTypeComponent
} from "../common/components";
import {TileEntityHelper} from "../../helpers/TileEntityHelper";
import {Entity} from "ecsy";
import {PlayerEntityHelper} from "../../helpers/PlayerEntityHelper";
import {WPRulesStateComponent, HandIsDeadTag, IWPRulesState, MarkedForCharlestonTag} from "./components.wp";
import {InGameDialogWP} from "./GameRulesWPSystem";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {DealState} from "../../enums/DealState";
import {TileType} from "../../enums/TileType";
import {TileDataHelper} from "../../helpers/TileDataHelper";
import {DisplayDialog} from "./GameActionsWP";
import {PlayersQuery} from "../queries";
import {EntityHelper} from "../../helpers/EntityHelper";
import {RulesHelperWP} from "./RulesHelperWP";
import {TileSetHelper} from "../../helpers/TileSetHelper";
import {DealStage} from "../../enums/DealStage";

/**
 * Game Rules-specified Input handler system.
 */
export class InputRulesWPSystem extends InputRulesSystem {
	
	protected onTileClick(tileData: TileDataComponent, playerId: number, tileEntity: Entity, point): void {
		const dealState = EntityHelper.getDealState(this.getGameStorage()).state;
		if (RulesHelperWP.isCharlestonState(dealState)) {
			console.log(`WP.onTileClick: charleston in progress -- tile ${tileData.fullId}`);
			if (tileEntity.getComponent(TileDataComponent).tinyId === 81) {
				console.log("InputRulesWPSystem.onTileClick: its a Joker. It cannot be used in charlestons");
				// this.showSnack("Error.577");
				return;
			}
			if (!tileEntity.hasComponent(MarkedForCharlestonTag)) {
				const tileSet = this.allTilesQ;
				const meldedCount = TileSetHelper.getPlayerMeldedTiles(playerId, tileSet).length;
				const choseCount = this.markedForCharlestonQ.length;
				if (meldedCount + choseCount >=
					(EntityHelper.getDealState(this.getGameStorage()).state === DealState.CHARLESTON_3_OPPOSITE
						? this.getRulesState().charleston3oppositeTiles
						: 3)) {
					console.log(`You cannot select more tiles. You selected ${choseCount} and you have ${meldedCount} melds`);
					return;
				}
			}
			TileEntityHelper.toggleMarkForCharleston(tileEntity);
		}
		else {
			const tileTinyId = tileEntity.getComponent(TileDataComponent).tinyId;
			console.log('InputRulesWPSystem.onTileClick: tileTinyId:', tileTinyId);
			
			const tileState = tileEntity.getComponent(TileDataComponent).state;
			console.log('InputRulesWPSystem.onTileClick: tileState:', tileState);
			
			const tileType = tileEntity.getComponent(TileTypeComponent).value;
			console.log('InputRulesWPSystem.onTileClick: tileType:', tileType);
			
			const tileMeldedGroup = tileEntity.getComponent(TileStoreComponent).meldedGroup;
			console.log('InputRulesWPSystem.onTileClick: tileMeldedGroup:', tileMeldedGroup);
			
			const playerEntity = PlayerEntityHelper.getPlayerEntity(playerId, this.playersQ);
			
			const playerMeldedGroup = playerEntity.getComponent(PlayerStoreComponent).meldedGroup;
			console.log('InputRulesWPSystem.onTileClick: playerMeldedGroup:', playerMeldedGroup);
			
			const playerHandIsDead = playerEntity.hasComponent(HandIsDeadTag);
			console.log('InputRulesWPSystem.onTileClick: playerHandIsDead:', playerHandIsDead);
			
			const isFlower = this.gameStorageQ.tableOptions.rulesSettings.isFlower;
			console.log('InputRulesWPSystem.onTileClick: isFlower:', isFlower);
			if (
				tileType === TileType.MELDED && tileState === TileState.DEFINED_JOKER
				&& (!playerHandIsDead || tileMeldedGroup < playerMeldedGroup)
				&& this.gameAccessHelper.iCanDiscardTile(this.gameStorageQ).result
			) {
				if (this.allTilesQ.find(cjTileEntity => cjTileEntity.getComponent(PlayerIdComponent).playerId === this.gameStorageQ.myPlayerId
					&& cjTileEntity.getComponent(TileTypeComponent).value === TileType.CONCEALED
					&& (cjTileEntity.getComponent(TileDataComponent).tinyId === tileTinyId
						|| (isFlower(tileTinyId) && isFlower(cjTileEntity.getComponent(TileDataComponent).tinyId))
					)
				)) {
					this.setRulesState({redeem_ClickedTile: tileEntity});
					this.netManager.makeMove(ActionMoveType.REDEEM_JOKER, TileDataHelper.setState(tileTinyId, TileState.DEFINED_JOKER), this.gameStorageQ);
				}
				else {
					console.warn("InputRulesWPSystem.onTileClick: there is no matching tile for joker exchange");
				}
			}
			else {
				console.log('InputRulesWPSystem.onTileClick: tileData: ', tileData);
				console.log('InputRulesWPSystem.onTileClick: playerId: ', playerId);
				console.log('InputRulesWPSystem.onTileClick: tileEntity: ', tileEntity);
				console.log('InputRulesWPSystem.onTileClick: point: ', point);
				if (tileData && playerId && tileEntity && point) {
					console.log('InputRulesWPSystem.onTileClick: Calling super.onTileClick method with tileData, playerId, tileEntity, point');
					super.onTileClick(tileData, playerId, tileEntity, point);
				} else {
					console.error('InputRulesWPSystem.onTileClick: Not all parameters are defined, not calling super.onTileClick');
				}
			}
		}
	}
	
	protected onTileMultiClick(tileData: TileDataComponent, playerId: number, tileEntity: Entity): void {
		if (playerId !== this.gameStore.dealState.viewPlayerId
			&& tileEntity.getComponent(TileTypeComponent).value === TileType.MELDED
			&& this.gameStorageQ.dealState.stage === DealStage.ACTIVE) {
			console.log("InputRulesWPSystem.onTileMultiClick: ");
			const playerName = this.gameStore.gameUsers.getUserById(playerId)?.Name;
			const dialogCfg: IGameDialogConfig = {
				id: InGameDialogWP.DeadHand,
				title: "Dead Hand Confirmation",
				titleResourceKey: "Game.Message.Wp.deadHandConfirm_title",
				message: `Are you sure ${playerName}'s hand is dead?`,
				messageResourceKey: "Game.Message.Wp.deadHandConfirm_message",
				messageResourceParams: {targetPlayerName: playerName},
				buttons: [
					{id: "deadHand", label: "Hand is Dead!", labelResourceKey: "Game.Message.Wp.deadHandConfirm_submit", data: playerId},
					{id: "cancel", label: "Cancel", labelResourceKey: "UI.Basic.CANCEL", data: playerId}
				]
			} as IGameDialogConfig;
			this.gameWorld.addAction(new DisplayDialog(this.gameEvents, dialogCfg));
		}
	}
	
	
	protected onGameDialogSubmit(data: IGameDialogSubmitData): void {
		const button: IGameDialogButtonConfig = data.button;
		const dialog: IGameDialogConfig = data.dialog;
		// console.log(`onGameDialogSubmit: button=${button}, dialog=${dialog}`);
		console.log(`onGameDialogSubmit: data=${data}`);
		const viewPlayerId = this.gameStore.dealState.viewPlayerId;
		switch (dialog.id) {
			case InGameDialogWP.Charleston:
				switch (button.id) {
					case "put_tiles":
						const marked = this.markedForCharlestonQ;
						// if (marked.length > 0) {
						const tileIDs = marked.map(tileEntity => tileEntity.getComponent(TileDataComponent).fullId);
						const dealState = EntityHelper.getDealState(this.getGameStorage()).state;
						if (tileIDs.length < 3
							&& (dealState === DealState.CHARLESTON_1_LEFT || dealState === DealState.CHARLESTON_2_RIGHT)) {
							while (tileIDs.length < 3) {
								tileIDs.push(90);
							}
						}
						this.netManager.putTiles(tileIDs, viewPlayerId, this.gameStorageQ)
							.then(success => {
								if (!success) {
									// TODO: open dialog again?
								}
							});
						/*marked.forEach(tileEntity => {
							const tileId = tileEntity.getComponent(TileDataComponent).fullId;
							this.netManager.putTile(tileId, playerId);
						});*/
						// }
						break;
					case "yes":
						this.netManager.makeDeclaration(ActionMoveType.CHARLESTON_3, this.gameStorageQ);
						break;
					case "no":
						this.netManager.makeDeclaration(ActionMoveType.CHARLESTON_0, this.gameStorageQ);
						break;
					case "c3":
						this.netManager.makeDeclaration(button.data as ActionMoveType, this.gameStorageQ);
						break;
					default:
						console.warn("Unknown submit action for charleston: ", button);
				}
				break;
			case InGameDialogWP.DeadHand:
				if (button.id === "deadHand") {
					// this.netManager.makeMove(ActionMoveType.DEAD_HAND, button.data as number, this.getGameStorage());
					this.netManager.declareDeadHand(button.data as number, this.gameStorageQ);
				}
				break;
			default:
				super.onGameDialogSubmit(data);
		}
	}
	
	private setRulesState(state: IWPRulesState): void {
		const comp = this.getGameStorage().getMutableComponent(WPRulesStateComponent);
		if (state.charleston3oppositeTiles) {
			comp.charleston3oppositeTiles = state.charleston3oppositeTiles;
		}
		if (state.redeemInProgress) {
			comp.redeemInProgress = state.redeemInProgress;
		}
		if (state.redeem_position) {
			comp.redeem_position = state.redeem_position;
		}
		if (state.redeem_ClickedTile) {
			comp.redeem_ClickedTile = state.redeem_ClickedTile;
		}
	}
	
	public get markedForCharlestonQ(): Entity[] {
		return this.queries.charlestonMarked.results;
	}
	
	private getRulesState(): IWPRulesState {
		return this.getGameStorage().getComponent(WPRulesStateComponent);
	}
	
}

InputRulesWPSystem.queries.charlestonMarked = {
	components: [TileDataComponent, GraphicsComponent, TilePositionComponent, MarkedForCharlestonTag]
};
InputRulesWPSystem.queries.players = PlayersQuery;