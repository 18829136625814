import { Component, Entity, TagComponent, Types } from "ecsy";

export class MarkedForCharlestonTag extends TagComponent {
}

export class HandIsDeadTag extends TagComponent {
}

export interface IWPRulesState {
	charleston3oppositeTiles?: number;
	redeemInProgress?: boolean;
	/**
	 * Keep reference to the clicked tile to insert new tile into this position later.
	 */
	redeem_ClickedTile?: Entity;
	redeem_position?: { prev: Entity, next: Entity };
}

export class WPRulesStateComponent extends Component<WPRulesStateComponent> implements IWPRulesState {
	charleston3oppositeTiles: number;
	redeemInProgress: boolean;
	/**
	 * Keep reference to the clicked tile to insert new tile into this position later.
	 */
	redeem_ClickedTile: Entity;
	
	redeem_position: { prev: Entity, next: Entity };
}

WPRulesStateComponent.schema = {
	charleston3oppositeTiles: { type: Types.Ref, default: 0 },
	redeemInProgress: { type: Types.Ref, default: false },
	redeem_ClickedTile: { type: Types.Ref },
	redeem_position: { type: Types.Ref },
};
