<meta name="viewport" content="width=device-width, initial-scale=1">
<mat-card>
    <mat-card-title>{{'Lobby.Login.Auth'|translate}}</mat-card-title>
    <mat-card-content>
        <mat-tab-group dynamicHeight>
            <mat-tab label="{{'Lobby.Login.SignIn'|translate}}">
                <div>
                    <form [formGroup]="signInForm" (ngSubmit)="doLogin()">
                        <mat-form-field style="display: block">
                            <mat-label>{{'Lobby.Login.Name'|translate}}</mat-label>
                            <input matInput formControlName="name">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{'Lobby.Login.Pass'|translate}}</mat-label>
                            <input type="password" matInput placeholder="{{'Lobby.Login.Pass'|translate}}"
                                   formControlName="password">
                        </mat-form-field>
                        <div class="button"
                             style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center; gap: .5rem">
                            <button type="button"
                                    *ngIf="guestLoginAllowed" mat-flat-button
                                    tabindex="-1"
                                    [disabled]="loading"
                                    (click)="doGuestLogin()">{{'Lobby.Login.SignInGuest'|translate|uppercase}}
                            </button>
                            <button type="submit" mat-raised-button color="primary"
                                    [disabled]="loading || !signInForm.valid">{{'Lobby.Login.Enter'|translate|uppercase}}
                            </button>
                        </div>

                        
                    </form>
                </div>
            </mat-tab>
            <mat-tab label="{{'Lobby.Login.SignUp'|translate}}">
                <form [formGroup]="signUpForm" (ngSubmit)="doSignUp()">
                    <mat-form-field>
                        <mat-label>{{'Lobby.Login.Name'|translate}}</mat-label>
                        <input matInput formControlName="name">
                        <mat-error
                                *ngIf="signUpForm.get('name').hasError('required')">{{'Lobby.Cashier.ConvertPage.Errors.RequiredField'|translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Lobby.Login.Pass'|translate}}</mat-label>
                        <input type="password" matInput placeholder="{{'Lobby.Login.Pass'|translate}}"
                               formControlName="password">
                        <mat-error
                                *ngIf="signUpForm.get('password').errors?.required">{{'Lobby.Cashier.ConvertPage.Errors.RequiredField'|translate}}</mat-error>
                        <mat-error
                                *ngIf="signUpForm.get('password').errors?.minlength || signUpForm.get('password').errors?.maxlength">{{'Lobby.MyAccount.Password.notValid'|translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'Lobby.InfoTabs.MyAcc.Email'|translate}}</mat-label>
                        <input matInput placeholder="{{'Lobby.InfoTabs.MyAcc.Email'|translate}}"
                               formControlName="email">
                        <mat-error
                                *ngIf="signUpForm.get('email').hasError('required')">{{'Lobby.Cashier.ConvertPage.Errors.RequiredField'|translate}}</mat-error>
                    </mat-form-field>
                    <div class="button"
                         style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center; gap: .5rem">
                        <button type="submit" mat-raised-button color="primary"
                                [disabled]="loading || !signUpForm.valid">{{'Lobby.Login.SignUp'|translate|uppercase}}
                        </button>
                    </div>

                </form>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>

    <div style="position: absolute; top:0rem; right:0rem;">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Language">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let country of availableCountries" (click)="langMenuClose(country)">
                <span class="flag-icon flag-icon-{{country.alpha2}}" style="transform: scale(1)"></span>

                <span>{{country.locale}}</span>
            </button>
        </mat-menu>
    </div>
</mat-card>


<div class="icons-container">
    <button mat-fab (click)="switchFullscreen()" color="secondary"
            matTooltip="{{'Lobby.MainMenu.FullScreen'|translate}}">
        <i class="material-icons">fullscreen</i>
    </button>
</div>
