import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

export interface IGameDialogSubmitData {
	button: IGameDialogButtonConfig;
	dialog: IGameDialogConfig;
}

export enum GameDialogType {
	Regular
}

export interface IGameDialogButtonConfig {
	id?: unknown;
	label: string;
	labelResourceKey?: string;
	data?: unknown;
}

export interface IGameDialogConfig {
	id: string;
	type?: GameDialogType;
	title?: string;
	titleResourceKey?: string;
	message?: string;
	messageResourceKey?: string;
	messageResourceParams?: {};
	buttons?: IGameDialogButtonConfig[];
}

@Component({
	selector: "app-in-game-dialog",
	template: `
       <div class="dialog">
           <p class="title no-pointer" [innerHTML]="title"></p>
           <p class="message no-pointer" [innerHTML]="message"></p>
           <div id="actions">
               <button mat-raised-button *ngFor="let btn of buttons" (click)="submit(btn)" tabindex="-1">
                   <!--<span role="img" aria-label="Fox">🦊</span>-->
                   {{getButtonLabel(btn)}}
               </button>
           </div>
       </div>
	`,
	styleUrls: ["./InGameDialogComponent.scss"]
})

export class InGameDialogComponent implements OnInit {
	
	public buttons: IGameDialogButtonConfig[];
	public dialogConfig: IGameDialogConfig;
	
	constructor(
		public dialogRef: MatDialogRef<InGameDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: IGameDialogConfig,
		private translate: TranslateService
	) {
		this.setDialogData(data);
	}
	
	ngOnInit(): void {
	
	}
	
	public setDialogData(data: IGameDialogConfig): void {
		this.dialogConfig = data;
		this.buttons = data.buttons;
	}
	
	public submit(button: IGameDialogButtonConfig): void {
		this.dialogRef.close({button, dialog: this.dialogConfig} as IGameDialogSubmitData);
	}
	
	public get title(): string {
		return this.dialogConfig.titleResourceKey
			? this.translate.instant(this.dialogConfig.titleResourceKey)
			: this.dialogConfig.title;
	}
	
	public get message(): string {
		return this.dialogConfig.messageResourceKey
			? this.translate.instant(this.dialogConfig.messageResourceKey, this.dialogConfig.messageResourceParams)
			: this.dialogConfig.message;
	}
	
	public getButtonLabel(btn: IGameDialogButtonConfig): string {
		return btn.labelResourceKey
			? this.translate.instant(btn.labelResourceKey)
			: btn.label;
	}
	
}

