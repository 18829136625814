import {commander, ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {GameStore} from "../store/GameStore";
import {GameMode} from "../game/enums/GameMode";
import {OnlineGameDTO} from "../net/dto/OnlineGameDTO";
import {Commands} from "./Commands";
import {SomeResult} from "../shared/SomeResult";

export class PlayQuickAgainCommand extends ICommand {
	
	async execute(): Promise<SomeResult<OnlineGameDTO>> {
		throw new Error("Command not implemented");
		try {
			const gameType = this.gameStore.joinedGame?.GameTypeId;
			const gameMode = ~this.gameStore.joinedGame?.Name.indexOf("Practice") ? GameMode.School : GameMode.Fun;
			console.log(`PlayQuickAgainCommand.execute: type=${gameType}, mode=${gameMode}, name=${this.gameStore.joinedGame?.Name}`);
			/*if (GameTypeHelper.isValidId(gameType) && GameModeHelper.isValidId(gameMode)) {
				const qRes = await commander.executeCommand<{ result?: OnlineGameDTO, error?: string }, { gameType: GameType, gameMode: GameMode }>(Commands.GET_QUICK_GAME, {
					gameMode,
					gameType
				});
				if (qRes.result) {
					await commander.executeCommand(Commands.RELEASE_GAME);
					const joinRes = await commander.executeCommand<boolean>(Commands.JOIN_GAME, {
						gameId: qRes.result.GameId,
						roomId: qRes.result.RoomId
					});
					if (joinRes) {
						// JOINED
						await commander.executeCommand<boolean, { page: NavPage }>(Commands.NAVIGATE_TO, {page: NavPage.Table});
						return {result: qRes.result};
					}
					else {
						// error joining
					}
				}
				else {
					// cannot fing quick game
				}
			}
			else {
				// no game type id or game mode
			}
			*/
		}
		catch (e) {
			// return {error: {message: "Error occurred", details: e}};
		}
		console.warn("PlayQuickAgainCommand.execute: Something went wrong");
		await commander.executeCommand(Commands.EXIT_GAME);
		return {error: {message: "Something went wrong"}};
	}
	
	// *************************************************************************************************************************
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
}
