import {GameType} from "../../game/enums/GameType";
import {EntryFeeTier} from "../../game/enums/EntryFeeTier";

export class JackpotInfoDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.Jackpot.JackpotInfo"
	
	public GameTypeId: GameType;
	public TierId: EntryFeeTier;
	public Prize: number;
	public SecLeft: number;
	public TopScore: number;
	public UserScore: number;
	
	
	get UserScoreNeat(): number {
		return this.UserScore > 0 ? this.UserScore : 0;
	}
	
	get ImParticipant() {
		return this.UserScore > 0;
	}
	
}

