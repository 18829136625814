<button mat-mini-fab color="secondary" (click)="toggleOpened()" *ngIf="!opened">
    <mat-icon color="accent">support</mat-icon>
</button>
<div *ngIf="opened" style="background-color: #eeeeee; border-radius: .6rem; padding:1rem; max-width: 50vw;">
    <div style="position: relative; display: flex; align-items: flex-start">
        <mat-tab-group style="flex: 1">
            <mat-tab label="X">
                <button mat-raised-button (click)="requestBalance()">Send sysNotify: request balance</button>
                <div style="display: flex; flex-direction: row !important; gap: 1rem;">
                    <div style="border: #666666 1px solid; padding: 0.5rem; display: flex; flex-direction: column;">
                        <mat-form-field><input matInput placeholder="message" #sysMsg
                                               value="sysInf#$#Mahjong Marathons Started Today!@$@http://mahjongtime.com:80/uploads/marathone21_message.jpg@$@">
                        </mat-form-field>
                        <button mat-raised-button (click)="sysAlr(sysMsg.value)">sysAlr</button>
                        <button mat-raised-button (click)="sysInf(sysMsg.value)">sysInf</button>
                        <button mat-raised-button
                                (click)="sendSysMessage('jackpot#$#10@$@20@$@Congratulations on winning the <font color=\'#F1C719\'><b>Bronze TW</b></font> Jackpot!@$@Your <font color=\'#F1C719\'><b>TW</b></font> skill has earned you <font color=\'#F1C719\'><b>100%</b></font> of the Jackpot pool!@$@Good luck at the next Jackpot tables!')">
                            jackpot Blue
                        </button>
                        <button mat-raised-button
                                (click)="sendSysMessage('jackpot#$#20@$@20@$@Congratulations on winning the <font color=\'#F1C719\'><b>Bronze TW</b></font> Jackpot!@$@Your <font color=\'#F1C719\'><b>TW</b></font> skill has earned you <font color=\'#F1C719\'><b>100%</b></font> of the Jackpot pool!@$@Good luck at the next Jackpot tables!')">
                            jackpot Br
                        </button>
                    </div>
                    <div style="border: #666666 1px solid; padding: 0.5rem; display: flex; flex-direction: column;">
                        <!--                    <input matInput placeholder="message" #nrMsg>-->
                        <mat-form-field><input matInput placeholder="tour room" #nrRoomId></mat-form-field>
                        <mat-form-field><input matInput placeholder="tour id" #nrTourId></mat-form-field>
                        <mat-form-field><input matInput placeholder="game id" #nrGameId></mat-form-field>
                        <button mat-raised-button (click)="invite(nrTourId.value, nrGameId.value, nrRoomId.value)">New
                            Round
                            Invite
                        </button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Game">
                <mat-form-field>
                    <input matInput #breakIndex placeholder="break index">
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="breakWall(breakIndex.value)">Break!</button>

                <div style="display: flex; padding: 1rem;">
                    <mat-form-field appearance="fill">
                        <mat-label>Player</mat-label>
                        <mat-select #player>
                            <mat-option *ngFor="let pl of players" [value]="pl.id">
                                {{pl.name}}:{{pl.id}}:{{pl.realside}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Move</mat-label>
                        <!--                        <mat-select [(ngModel)]="selectedValue">-->
                        <mat-select #moveType>
                            <mat-option *ngFor="let move of gameMovesList" [value]="move.id">
                                {{move.label}}
                            </mat-option>
                        </mat-select>
                        <!--<mat-hint>{{moveType.value}}</mat-hint>-->
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Message</mat-label>
                        <input matInput #message placeholder="message" value="22">
                    </mat-form-field>
                    <button mat-raised-button color="primary"
                            (click)="execMove(player.value, moveType.value, message.value)">
                        Exec
                    </button>
                </div>

                <button mat-raised-button (click)="onEndDealClick()">EndDealPP</button>
                <button mat-raised-button (click)="onLeaveClick()">Logout</button>
            </mat-tab>


            <mat-tab label="Second">
                <mat-accordion>
                    <mat-expansion-panel hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                This is the expansion title
                            </mat-panel-title>
                            <mat-panel-description>
                                This is a summary of the content
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p>This is the primary content of the panel.</p>
                    </mat-expansion-panel>
                    <mat-expansion-panel><!--
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">-->
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Self aware panel
                            </mat-panel-title>
                            <mat-panel-description>
                                <!--Currently I am {{panelOpenState ? "open' : 'closed'}}-->
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p>I'm visible because I am open</p>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-tab>
        </mat-tab-group>
        <button mat-mini-fab color="primary" (click)="toggleOpened()">
            <!--style="position: absolute; top:.5rem; right:.5rem;">-->
            <mat-icon>clear</mat-icon>

        </button>


    </div>


</div>
