import {inject, injectable} from "inversify";
import {TYPES} from "../../inversify/inversify.types";
import {UserStore} from "../../store/UserStore";
import {GameStore} from "../../store/GameStore";
import {Entity} from "ecsy";
import {EntityHelper} from "./EntityHelper";
import {GameStorageWrapper} from "../ecs/wrappers/GameStorageWrapper";
import {DealState} from "../enums/DealState";
import {PlayersArray} from "../ecs/wrappers/PlayersArray";
import {DealStage} from "../enums/DealStage";

@injectable()
export class GameAccessHelper {
	
	constructor(
		@inject(TYPES.UserStore) private userStore: UserStore,
		@inject(TYPES.GameStore) private gameStore: GameStore,
	) {
	}
	
	get dealIsActive(): boolean {
		return true;
	}
	
	private itsMyTurn(gameStorage: GameStorageWrapper): IAccessResponse {
		try {
			console.log('itsMyTurn: gameStorage:', gameStorage);
			if (gameStorage.dealState.state > DealState.PLAYING) {
				console.warn('itsMyTurn: dealState.state > DealState.PLAYING');
				return {result: false, error: AccessError.DEAL_NOT_ACTIVE, errorMessage: AccessError[AccessError.DEAL_NOT_ACTIVE]};
			}
			if (gameStorage.dealState.stage !== DealStage.PREPARING && gameStorage.dealState.stage !== DealStage.ACTIVE) {
				console.warn('itsMyTurn: dealState.stage is neither DealStage.PREPARING nor DealStage.ACTIVE');
				return {result: false, error: AccessError.DEAL_IS_NOT_ACTIVE, errorMessage: AccessError[AccessError.DEAL_IS_NOT_ACTIVE]};
			}
			if (gameStorage.turnState.makingMove) {
				console.warn('itsMyTurn: turnState.makingMove is true');
				return {result: false, error: AccessError.MAKE_MOVE_IN_PROGRESS, errorMessage: AccessError[AccessError.MAKE_MOVE_IN_PROGRESS]};
			}
			if (gameStorage.turnState.waitingForNextMove) {
				console.warn('itsMyTurn: turnState.waitingForNextMove is true');
				return {result: false, error: AccessError.WAITING_FOR_NEXT_MOVE, errorMessage: AccessError[AccessError.WAITING_FOR_NEXT_MOVE]};
			}
			console.log('itsMyTurn: It\'s your turn');
			return {result: true};
		} catch (error) {
			console.error('itsMyTurn: Error in itsMyTurn: ', error);
			return {result: false, error: AccessError.UNKNOWN_ERROR, errorMessage: 'Unknown error: ' + error.message};
		}
	}
	
	iCanDiscardTile(gameStorage: GameStorageWrapper/*, myPlayerId: number*/): IAccessResponse {
		try {
			console.log('iCanDiscardTile: gameStorage:', gameStorage);
			const itsMyTurn = this.itsMyTurn(gameStorage);
			if (!itsMyTurn.result) {
				console.warn('iCanDiscardTile: It\'s not your turn');
				return itsMyTurn;
			}
	
			if (gameStorage.moveNowState.moveNowType === "slot") {
				console.warn('iCanDiscardTile: There is a tile in the slot');
				return {result: false, error: AccessError.THERE_IS_A_TILE_IN_SLOT, errorMessage: AccessError[AccessError.THERE_IS_A_TILE_IN_SLOT]};
			}
			if (gameStorage.moveNowState.moveNowType === "player" && gameStorage.moveNowState.moveNowPlayerId !== gameStorage.viewPlayerId) {
				console.warn('iCanDiscardTile: It\'s not your move');
				return {result: false, error: AccessError.NOT_YOUR_MOVE, errorMessage: AccessError[AccessError.NOT_YOUR_MOVE]};
			}
			console.log('iCanDiscardTile: You can discard the tile');
			return {result: true};
		} catch (error) {
			console.error('iCanDiscardTile: Error in iCanDiscardTile: ', error);
			return {result: false, error: AccessError.UNKNOWN_ERROR, errorMessage: 'Unknown error: ' + error.message};
		}
	}
	
	iCanMakeDeclaration(gameStorage: GameStorageWrapper, players: PlayersArray): IAccessResponse {
		const itsMyTurn = this.itsMyTurn(gameStorage);
		if (!itsMyTurn.result) {
			return itsMyTurn;
		}
		
		if (gameStorage.moveNowState.moveNowType === "slot") { // there is a tile in slot
			if (gameStorage.moveNowState.moveNowPlayerId === gameStorage.viewPlayerId) { // this is viewPlayer's tile
				return {
					result: false,
					error: AccessError.CANT_DECLARE_ON_YOUR_TILE,
					errorMessage: AccessError[AccessError.CANT_DECLARE_ON_YOUR_TILE]
				};
			}
			else { // tile in slot doesnt belong to viewPlayer. Verifying if player has available actions to declare
				const viewPlayer = players.getPlayerById(gameStorage.viewPlayerId);
				if (viewPlayer.actions.length === 0) {
					return {result: false, error: AccessError.NO_AVAILABLE_ACTIONS, errorMessage: AccessError[AccessError.NO_AVAILABLE_ACTIONS]};
				}
			}
		}
		if (gameStorage.moveNowState.moveNowType === "player" && gameStorage.moveNowState.moveNowPlayerId !== gameStorage.viewPlayerId) {
			return {result: false, error: AccessError.NOT_YOUR_MOVE, errorMessage: AccessError[AccessError.NOT_YOUR_MOVE]};
		}
		return {result: true};
	}
	
	iCanThrowTile(gameStorage: Entity): boolean {
		const ds = EntityHelper.getTurnSate(gameStorage);
		return this.dealIsActive
			&& !ds.makingMove
			&& !ds.waitingForNextMove;
	}
	
	iCanDeclareMove(pe: Entity, gameStorage: Entity): boolean {
		const ds = EntityHelper.getTurnSate(gameStorage);
		return this.dealIsActive
			&& !ds.makingMove
			&& !ds.waitingForNextMove;
	}
	
	iCanDeclareCharleston(gameStorage: Entity): boolean {
		const ds = EntityHelper.getTurnSate(gameStorage);
		console.log(`GameAccessHelper.iCanDeclareCharleston: {dealIsActive: ${this.dealIsActive}, makingMove: ${ds.makingMove}, waitingForNextMove: ${ds.waitingForNextMove}`);
		return this.dealIsActive
			&& !ds.makingMove
			&& !ds.waitingForNextMove;
	}
	
	get userId(): number {
		// return this.userStore.id
		return this.gameStore.dealState.viewPlayerId;
	}
}

// BaseGameRules.convertToCommands: [GameMessageDTO -- id: 104 • gameId: 375197 • userId: 186 • type: 90 - USER_CAN_MAKE_MOVE • message: kong_self|put_tile • time: Tue Aug 18 2020 16:32:32 GMT-0400 (Eastern Daylight Time)]

interface IAccessResponse {
	result: boolean;
	error?: AccessError;
	errorMessage?: string;
}


export enum AccessError {
	NO_AVAILABLE_ACTIONS,
	CANT_DECLARE_ON_YOUR_TILE,
	THERE_IS_A_TILE_IN_SLOT,
	NOT_YOUR_MOVE,
	WAITING_FOR_NEXT_MOVE,
	MAKE_MOVE_IN_PROGRESS,
	DEAL_NOT_ACTIVE,
	DEAL_IS_NOT_ACTIVE,
	UNKNOWN_ERROR
}
