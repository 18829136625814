import {Container} from "inversify";
import {TYPES} from "./inversify.types";
import {GameGraphics} from "../game/GameGraphics";
import {UserStore} from "../store/UserStore";
import {TexturePack} from "../game/graphics/TexturePack";
import {GameStore} from "../store/GameStore";
import {AMFConnectionBroker} from "../net/service/AMFConnectionBroker";
import {GameWorld} from "../game/ecs/GameWorld";
import {LoginService} from "../net/service/LoginService";
import {PlayerLayoutHelper} from "../game/helpers/PlayerLayoutHelper";
import {GameController} from "../game/GameController";
import {environment} from "../environments/environment";
import {FakeLoginService} from "../net/service/FakeLoginService";
import {ILoginService} from "../net/service/ILoginService";
import {UserService} from "../net/service/UserService";
import {ChatService} from "../net/service/ChatService";
import {GameService} from "../net/service/GameService";
import {OnlineUserService} from "../net/service/OnlineUserService";
import {GameListService} from "../net/service/GameListService";
import {ChatGamController} from "../game/ChatGamController";
import {AppStore} from "../store/AppStore";
import {FakeGameService} from "../net/service/FakeGameService";
import {FakeUserService} from "../net/service/FakeUserService";
import {FakeChatService} from "../net/service/FakeChatService";
import {GameMessagesController} from "../game/GameMessagesController";
import {GameAccessHelper} from "../game/helpers/GameAccessHelper";
import {FakeGameListService} from "../net/service/FakeGameListService";
import {GameEventsPipe} from "../game/GameEventsPipe";
import {AppEventsPipe} from "../game/AppEventsPipe";
import {NetGameManager} from "../game/NetGameManager";
import {TournamentService} from "../net/service/TournamentService";
import {TournamentListService} from "../net/service/TournamentListService";
import {JackpotService} from "../net/service/JackpotService";

// import "reflect-metadata";

/**
 * @injectable classes should use constructor @inject() @named() instead of using container.get() in code
 * to avoid circular dependencies.
 * WARNING in Circular dependency detected:
 * src\game\inversifyX\inversify.config.ts -> src\game\rules\BGR.ts -> src\game\inversifyX\inversify.config.ts
 */
const container = new Container();

container.bind<ISession>(TYPES.SessionKey).toConstantValue({sessionKey: undefined});

container.bind<PlayerLayoutHelper>(TYPES.PlayerLayoutHelper).to(PlayerLayoutHelper).inSingletonScope();

// Singleton instances (live until container.unbind)
container.bind<TexturePack>(TYPES.TexturePack).to(TexturePack).inSingletonScope();
container.bind<GameGraphics>(TYPES.GameGraphics).to(GameGraphics).inSingletonScope();
container.bind<GameWorld>(TYPES.GameWorld).to(GameWorld).inSingletonScope();

// Singletons: Stores
container.bind<GameStore>(TYPES.GameStore).to(GameStore).inSingletonScope();
container.bind<UserStore>(TYPES.UserStore).to(UserStore).inSingletonScope();
container.bind<AppStore>(TYPES.AppStore).to(AppStore).inSingletonScope();

// Singletons: Services
container.bind<AMFConnectionBroker>(TYPES.AMFConnectionBroker).to(AMFConnectionBroker).inSingletonScope();
container.bind<OnlineUserService>(TYPES.OnlineUserService).to(OnlineUserService).inSingletonScope();
container.bind<TournamentService>(TYPES.TournamentService).to(TournamentService).inSingletonScope();
container.bind<TournamentListService>(TYPES.TournamentListService).to(TournamentListService).inSingletonScope();
container.bind<JackpotService>(TYPES.JackpotService).to(JackpotService).inSingletonScope();

if (environment.fakeServices) {
	container.bind<ILoginService>(TYPES.LoginService).to(FakeLoginService).inSingletonScope();
	container.bind<GameService>(TYPES.GameService).to(FakeGameService).inSingletonScope();
	container.bind<GameListService>(TYPES.GameListService).to(FakeGameListService).inSingletonScope();
	container.bind<UserService>(TYPES.UserService).to(FakeUserService).inSingletonScope();
	container.bind<ChatService>(TYPES.ChatService).to(FakeChatService).inSingletonScope();
}
else {
	container.bind<ILoginService>(TYPES.LoginService).to(LoginService).inSingletonScope();
	container.bind<GameService>(TYPES.GameService).to(GameService).inSingletonScope();
	container.bind<GameListService>(TYPES.GameListService).to(GameListService).inSingletonScope();
	container.bind<UserService>(TYPES.UserService).to(UserService).inSingletonScope();
	container.bind<ChatService>(TYPES.ChatService).to(ChatService).inSingletonScope();
}

container.bind<ChatGamController>(TYPES.ChatGamController).to(ChatGamController).inSingletonScope();
container.bind<GameController>(TYPES.GameController).to(GameController).inSingletonScope();
container.bind<GameAccessHelper>(TYPES.GameAccessHelper).to(GameAccessHelper).inSingletonScope();
container.bind<GameEventsPipe>(TYPES.GameEventsPipe).to(GameEventsPipe).inSingletonScope();
container.bind<AppEventsPipe>(TYPES.AppEventsPipe).to(AppEventsPipe).inSingletonScope();
container.bind<NetGameManager>(TYPES.NetGameManager).to(NetGameManager).inSingletonScope();

// New instances are created on request

/** inTransientScope: new instances on request */
container.bind<GameMessagesController>(TYPES.GameMessagesController).to(GameMessagesController).inTransientScope();

export default container;

export interface ISession {
	sessionKey: string;
}
