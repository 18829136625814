<!--<div class="game-card" (click)="onTipClicked()">-->
<div class="icon-area">
    <img class="iconx" src="{{imgPath}}{{displayedTip?.icon}}.png">
</div>
<div class="content-area" *ngIf="displayedTip">
    <span class="mat-card-title">{{displayedTip.title.key|translate:displayedTip.title.params}}</span>
    <!--<span class="mat-card-subtitle msg-short">{{displayedTip.msgShortRK.key|translate:displayedTip.msgShortRK.params}}</span>-->

    <span id="full-message" class="mat-card-subtitle msg-full">{{displayedTipFullMessage}}</span>
    <div id="btns" *ngIf="hasNextNav">
        <!--        <button mat-raised-button color="secondary" (click)="onPrevClick()">&lt; Previous</button>-->

        <button mat-raised-button color="secondary" (click)="onNextClick()">Next Tip &gt;</button>
        <!--<button mat-raised-button color="secondary" (click)="onDetailsClick()" *ngIf="displayedTip.msgFull">Show
            Details
        </button>-->
    </div>
</div>
<!--</div>-->
