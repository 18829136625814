import {IGameDialogConfig} from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {AppEventsPipe, AppEventType, SnackData} from "../../AppEventsPipe";
import {GameEventsPipe, GameEventType} from "../../GameEventsPipe";
import {IGameAction} from "../common/GameAction";
import {Entity} from "ecsy";
import {WPRulesStateComponent, HandIsDeadTag, IWPRulesState, MarkedForCharlestonTag} from "./components.wp";
import {TileType} from "../../enums/TileType";
import {TileEntityHelper} from "../../helpers/TileEntityHelper";
import {DiscardsTilesHelper} from "../../helpers/DiscardsTilesHelper";
import {PlayerIdComponent, TileDataComponent, TileTypeComponent} from "../common/components";
import {TileWrapper} from "../wrappers/TileWrapper";
import {PlayersArray} from "../wrappers/PlayersArray";
import {TileSetHelper} from "../../helpers/TileSetHelper";

export class CharlestonsStart implements IGameAction {
	constructor() {
	}
	
	execute() {
	
	}
}

export class ShowSnack implements IGameAction {
	
	constructor(private appEventsPipe: AppEventsPipe, private snackData: SnackData) {
	}
	
	execute() {
		this.appEventsPipe.send(AppEventType.ShowSnack, this.snackData);
	}
}

export class ExecCallback implements IGameAction {
	constructor(private callback) {
	}
	
	execute() {
		this.callback();
	}
}

export class DisplayDialog implements IGameAction {
	constructor(private gameEventsPipe: GameEventsPipe, private dialogCfg: IGameDialogConfig) {
	}
	
	execute() {
		this.gameEventsPipe.send(GameEventType.Display_GameDialog, this.dialogCfg);
	}
}

export class RemoveDialog implements IGameAction {
	constructor(private gameEventsPipe: GameEventsPipe, private dialogCfg: IGameDialogConfig) {
	}
	
	execute() {
		this.gameEventsPipe.send(GameEventType.Remove_GameDialog, this.dialogCfg);
	}
}

export class DefineJoker implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		const tileEntity: Entity = TileSetHelper.retrieveConcealedTile(this.playerId, 81, this.tileSet);
		if (tileEntity) {
			// const tileFullId = tileEntity.getComponent(TileDataComponent).fullId;
			TileEntityHelper.changeId(tileEntity, this.tileId);
		}
	}
}

export class ConcealedToMeldedCharleston implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[]) {
	}
	
	execute() {
		// filter marked for
		const markedTilesSet = this.tileSet.filter(entity => entity.hasComponent(MarkedForCharlestonTag));
		// trying to search required tile in marked set (for my player)
		let markedTileEntity = TileSetHelper.retrieveConcealedTile(this.playerId, this.tileId, markedTilesSet);
		if (!markedTileEntity) { // if no tile found in marked set, try regular search (for closed hands)
			markedTileEntity = TileSetHelper.retrieveConcealedTile(this.playerId, this.tileId, this.tileSet);
		}
		if (markedTileEntity) {
			TileSetHelper.addTile({
				tile: markedTileEntity,
				// playerId: this.playerId, // remains the same player
				tileId: this.tileId,
				tileType: TileType.MELDED,
				tiles: this.tileSet
			});
		}
	}
}

export class ClearMarkForCharleston implements IGameAction {
	constructor(private tileSet: Entity[]) {
	}
	
	execute() {
		this.tileSet.forEach(tileEntity => {
			if (tileEntity.hasComponent(MarkedForCharlestonTag)) {
				tileEntity.removeComponent(MarkedForCharlestonTag);
			}
		});
	}
}

export class RedeemJoker implements IGameAction {
	constructor(private type: "start" | "end", private gameStorage: Entity) {
	}
	
	execute() {
		const dealState: IWPRulesState = this.gameStorage.getMutableComponent(WPRulesStateComponent);
		dealState.redeemInProgress = (this.type === "start");
		if (this.type === "end") {
			dealState.redeem_ClickedTile = null;
		}
	}
}


export class SlotToMeldedWP implements IGameAction {
	constructor(private tileId: number, private playerId: number, private tileSet: Entity[], private gameStorage: Entity) {
	}
	
	private getRulesState(): IWPRulesState {
		return this.gameStorage.getComponent(WPRulesStateComponent);
	}
	
	execute() {
		// if redeem joker is NOT in progress - just a regular Slot2Melded
		if (this.getRulesState().redeemInProgress === false) {
			const slotTileEntity = DiscardsTilesHelper.getSlotTile(this.tileSet);
			if (slotTileEntity) {
				TileSetHelper.addTile({
					tile: slotTileEntity,
					playerId: this.playerId,
					tileId: this.tileId,
					tileType: TileType.MELDED,
					tiles: this.tileSet
				});
			}
			else {
				console.error("SlotToMeldedWP: there is no tile in slot!");
			}
		}
		// redeem joker is in progress
		else {
			const slotTileEntity = DiscardsTilesHelper.getSlotTile(this.tileSet);
			if (slotTileEntity) {
				let afterTileWrap: TileWrapper;
				let afterTile = this.getRulesState().redeem_ClickedTile;
				
				if (afterTile) { // if it is an auto redeem or another player redeems joker - there wont be a clicked tile
					afterTileWrap = TileEntityHelper.wrapEntity(afterTile);
					if (afterTileWrap.id !== this.tileId || afterTileWrap.playerId !== this.playerId) { // make sure clicked tile has required tileId and playerId
						// in case we picked tile X at player Y, but system redeems tile X at player Z
						console.log("SlotToMeldedWP: Redeem: there is a redeemClickedTile, but tileId or playerId do not match. " + `tileId ${afterTileWrap.id}!==${this.tileId} or playerId ${afterTileWrap.playerId}!==${this.playerId}`);
						afterTile = null;
						afterTileWrap = null;
					}
				}
				
				if (!afterTile) {
					console.log("SlotToMeldedWP: Redeem: No afterTile. Search by tileId");
					afterTile = this.tileSet.find(tileEntity => tileEntity.getComponent(TileTypeComponent).value === TileType.MELDED
						&& tileEntity.getComponent(PlayerIdComponent).playerId === this.playerId
						&& tileEntity.getComponent(TileDataComponent).fullId === this.tileId
					);
				}
				if (!afterTile) {
					console.warn("SlotToMeldedWP: Redeem: No afterTile. Add as last tile");
				}
				TileSetHelper.addTile({
					tile: slotTileEntity,
					playerId: this.playerId,
					tileId: this.tileId,
					tileType: TileType.MELDED,
					tiles: this.tileSet,
					afterTile
				});
			}
			else {
				console.error("SlotToMeldedWP: Redeem: there is no tile in slot!");
			}
		}
	}
}

export class DeadHand implements IGameAction {
	constructor(private params: {
		isDead: boolean,
		playerId: number,
		onPlayerId: number,
		onPlayerEntity: Entity,
		combination?: string,
	}) {
	}
	
	execute() {
		if (this.params.isDead) {
			this.params.onPlayerEntity.addComponent(HandIsDeadTag);
		}
	}
}

export class ClearDeadHand implements IGameAction {
	constructor(private players: PlayersArray) {
	}
	
	execute() {
		this.players.forEach(player => {
			if (player.entity.hasComponent(HandIsDeadTag)) {
				player.entity.removeComponent(HandIsDeadTag);
			}
		});
	}
}



