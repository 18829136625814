import {Entity} from "ecsy";
import {
	LocationComponent,
	PlayerActionsComponent,
	PlayerIdComponent,
	PlayerStoreComponent,
	RealSideComponent,
	TimeBankComponent
} from "../common/components";
import {Side} from "../../enums/Side";
import {IDisposable} from "../../IDisposable";
import {ICanMakeMove} from "../../interfaces/ICanMakeMove";
import {LocationTransform} from "../../helpers/rules-helper";

export class PlayerWrapper implements IDisposable {
	public readonly timeBank: TimeBank;
	
	constructor(public entity: Entity) {
		this.timeBank = new TimeBank(entity);
	}
	
	static wrap(entity: Entity): PlayerWrapper {
		return new PlayerWrapper(entity);
	}
	
	public dispose(): void {
		if (this.entity) {
			this.entity.remove();
			this.entity = null;
		}
	}
	
	public get id(): number {
		return this.entity.getComponent(PlayerIdComponent).playerId;
	}
	
	public get location(): Side {
		return this.entity.getComponent(LocationComponent).side as Side;
	}
	
	public set location(side) {
		this.entity.getMutableComponent(LocationComponent).side = side;
	}
	
	public get realside(): Side {
		return this.entity.getComponent(RealSideComponent).side as Side;
	}
	
	public set realside(side) {
		this.entity.getMutableComponent(RealSideComponent).side = side;
	}
	
	public get locationTransform(): LocationTransform {
		return this.entity.getComponent(PlayerStoreComponent).locationTransform;
	}
	
	public set locationTransform(side) {
		this.entity.getMutableComponent(PlayerStoreComponent).locationTransform = side;
	}
	
	public get concealedRotationX(): number {
		return this.entity.getComponent(PlayerStoreComponent).concealedRotationX;
	}
	
	public set concealedRotationX(value) {
		this.entity.getMutableComponent(PlayerStoreComponent).concealedRotationX = value;
	}
	
	public get name(): string {
		return this.entity.getComponent(PlayerStoreComponent).name;
	}
	
	public set name(value) {
		this.entity.getMutableComponent(PlayerStoreComponent).name = value;
	}
	
	public get actions(): ICanMakeMove[] {
		return this.entity.getComponent(PlayerActionsComponent).actions;
	}
	
	public set actions(actions) {
		this.entity.getMutableComponent(PlayerActionsComponent).actions = actions;
	}
	
}


class TimeBank {
	constructor(private entity: Entity) {
	}
	
	private get options() {
		return this.entity.getComponent(TimeBankComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(TimeBankComponent);
	}
	
	/*public update(dealOptions: { state?: number, dealNum?: number, dealTurn?: number, roundWind?: Side }): void {
		if (Object.values(dealOptions).length > 0) {
			const mc = this.mutableOptions;
			Object.entries(dealOptions)
				.forEach(([key, val]) => mc[key] = val);
		}
	}*/
	
	/**
	 * Remaining amount of seconds user has for current deal
	 */
	public get available(): number {
		return this.options.available;
	}
	
	public set available(value) {
		this.mutableOptions.available = value;
	}
	
	/**
	 * Total (initial) amount of seconds user had for current deal
	 */
	public get total(): number {
		return this.options.total;
	}
	
	public set total(value) {
		this.mutableOptions.total = value;
	}
}

export class PlayersArray extends Array<PlayerWrapper> {
	
	public setPlayerEntities(players: Entity[]) {
		this.length = 0;
		this.push(...players.map(player => new PlayerWrapper(player)));
		return this;
	}
	
	public setPlayers(players: PlayerWrapper[]) {
		this.push(...players);
		return this;
	}
	
	public getPlayerById(id: number): PlayerWrapper {
		return this.find(player => player.id === id);
	}
	
	public getPlayerByRealside(side: Side): PlayerWrapper {
		return this.find(player => player.realside === side);
	}
	
	public removePlayerById(id: number): boolean {
		const index = this.findIndex(player => player.id === id);
		if (~index) {
			this.splice(index, 1)[0].dispose();
			return true;
		}
	}
}
