export enum GameType {
	HK = 10, CO = 20, AM = 30, AS, RCR = 40, EC = 50, WS = 60, TW = 70, EMA = 80, SM = 90, WP=100
}

export class GameTypeHelper {
	static isValidName(value: string): boolean {
		return !isNaN(GameType[value]);
	}
	
	static isValidId(value: number): boolean {
		return !isNaN(GameType[GameType[value]]);
	}
	
	static hasJackpot(value: number) {
		switch (value) {
			case GameType.HK:
			case GameType.WP:
			case GameType.RCR:
			case GameType.EMA:
			case GameType.TW:
				return true;
			default:
				return false;
		}
	}
}
