export enum AutoPassMode {
	
	DEFAULT = 0,
	IGNORE_CHOW = 1,
	IGNORE_PUNG = 2,
	IGNORE_KONG = 4,
	IGNORE_QUINT = 8,
	IGNORE_SEXTET = 16,
	IGNORE_JOKER = 512,
	CONCEALED_HAND = 4096
}
