import {Entity} from "ecsy";
import {PlayerActionsComponent, PlayerIdComponent} from "../ecs/common/components";
import {PlayerWrapper} from "../ecs/wrappers/PlayersArray";
import {ICanMakeMove} from "../interfaces/ICanMakeMove";

export class PlayerEntityHelper {
	
	
	// Player Entities
	public static getPlayerEntity(playerId: number, players: Entity[]): Entity {
		return players.find(playerEntity => playerEntity.getComponent(PlayerIdComponent).playerId === playerId);
	}
	
	public static getPlayer(playerId: number, players: Entity[]): PlayerWrapper {
		const entity = players.find(playerEntity => playerEntity.getComponent(PlayerIdComponent).playerId === playerId);
		return entity ? new PlayerWrapper(entity) : null;
	}
	
	public static wrapPlayerEntity(player: Entity): PlayerWrapper {
		return new PlayerWrapper(player);
	}
	
	public static getActions(playerEntity: Entity): ICanMakeMove[] {
		return playerEntity.getComponent(PlayerActionsComponent).actions;
	}
	
	public static setActions(playerEntity: Entity, actions: ICanMakeMove[]): void {
		playerEntity.getMutableComponent(PlayerActionsComponent).actions = actions;
	}
	
	
}


