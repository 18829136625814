export class GameUserPointsDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.GameUserPoints"
	
	public UserId: number;
	public UserName: string;
	public Points: number;
	public Rating: number;
	
	public IsWinner: boolean;
	public Place: number;
}
