import {GameListService} from "./GameListService";
import {OnlineGameDTO} from "../dto/OnlineGameDTO";
import {FakeGameHelper} from "../../game/helpers/FakeGameHelper";

export class FakeGameListService extends GameListService {
	
	getGames(sessionKey: string, scope: number, gameTypeId: number, roomId: number, isAutoRefresh: boolean = false): Promise<Array<OnlineGameDTO>> {
		/*const createGameDTO = (gameType: GameType, settings?: IOnlineGame): OnlineGameDTO => {
			const defaultDTO = Object.assign(new OnlineGameDTO(), {GameTypeId: gameTypeId}) as OnlineGameDTO;
			return settings ? Object.assign(defaultDTO, settings) : defaultDTO;
		};
		
		return Promise.resolve(new ServiceResult(
			[201, 202].map(gameId => createGameDTO(gameTypeId, {GameId: gameId, Name: "Game " + gameId}) as OnlineGameDTO)
		));*/
		
		/*const fpg = OnlineGameWrap
			.createAndWrap()
			.setGameSettings(FakeGameHelper.getGameSettings(GAMES.HK))
			.setGameSettings({Name: "Four-Player-Game", StateId: GameState.PLAYING, IsMyGame: true})
			// .addPlayer("South", {Id: this.userStore.id})
			.addPlayer("South", {Id: 888})
			.addPlayer("East")
			.addPlayer("North")
			.addPlayer("West")
			.dto;*/
		
		return Promise.resolve(FakeGameHelper.presetGamesList.map(pg => pg.settings));
	}
	
}

