// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.TournamentGame"
export class TournamentGameDTO {
	public RoomId: number;
	public GameId: number;
	public GameName: string;
	public GameStateId: number;
	public PlayersCount: number;
	public IsMyGame: boolean;
	
	public TourId: number;
	
}
