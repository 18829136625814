import {PointsDescriptionDTO} from "./PointsDescriptionDTO";
import {Side} from "../../game/enums/Side";
import {parse} from "fast-xml-parser";

export class PointsDescriptionModel {
	
	private _pointsInfo: IPoints;
	
	constructor(public readonly dto: PointsDescriptionDTO) {
	
	}
	
	public get userId(): number {
		return this.dto.UserId;
	}
	
	public get isWinner(): boolean {
		return this.dto.IsWinner;
	}
	
	public get place(): number {
		return this.dto.Place;
	}
	
	public get side(): Side {
		return this.dto.Side as Side;
	}
	
	public get concealed(): string {
		return this.dto.Concealed;
	}
	
	public get melded(): string {
		return this.dto.Melded;
	}
	
	public get pointsInfo() {
		if (!this._pointsInfo) {
			this._pointsInfo = this.parseHandDetails(this.dto.Descr);
		}
		return this._pointsInfo;
	}
	
	private parseHandDetails(details: string): IPoints {
		/*<PntsDescr>
			<BfrTblCmns><Cmns><Id>10</Id><Pnt>1</Pnt><Descr>Season of Own Wind</Descr></Cmns></BfrTblCmns>
			<BfrTblTotal>2</BfrTblTotal>
			<TblPnts><ToEast>0</ToEast><ToSouth>2</ToSouth><ToWest>4</ToWest><ToNorth>2</ToNorth></TblPnts>
			<Additional></Additional>
			<Total>8</Total>
		</PntsDescr>*/
		const res: IPointsServer = parse(details).PntsDescr;
		
		const convertCombination = (combination: ICombinationServer): ICombination => ({
			id: combination.Id ?? 0,
			points: combination.Pnt ?? 0,
			description: combination.Descr ?? "",
		});
		
		const convertCombDescription = (combinations: ICombinationServer | ICombinationServer[]) => {
			if (!combinations) {
				return [];
			}
			if (Array.isArray(combinations)) {
				return combinations.map<ICombination>((combination: ICombinationServer) => convertCombination(combination));
			}
			else {
				return [convertCombination(combinations)];
			}
		};
		
		const hd: IPoints = {
			beforeTableTotal: res.BfrTblTotal,
			total: res.Total,
			pointsMoves: res.TblPnts,
			beforeTable: convertCombDescription(res.BfrTblCmns?.Cmns),
			additional: convertCombDescription(res.Additional?.Cmns),
			descriptive: convertCombDescription(res.Descriptive?.Cmns),
		};
		return hd;
	}
	
}

interface IPoints {
	readonly beforeTableTotal: number;
	readonly total: number;
	pointsMoves: PointsMove;
	beforeTable: ICombination[];
	additional: ICombination[];
	descriptive: ICombination[];
}

export interface ICombination {
	id: number;
	points: number;
	description: string;
}

type PointsMove = { ToEast: number, ToNorth: number, ToWest: number, ToSouth: number };

/** Server: Points details */
interface IPointsServer {
	BfrTblTotal: number;
	Total: number;
	BfrTblCmns: { Cmns: ICombinationServer | ICombinationServer[] };
	Additional: { Cmns: ICombinationServer | ICombinationServer[] };
	Descriptive?: { Cmns: ICombinationServer | ICombinationServer[] };
	TblPnts: PointsMove;
}

/** Server: Combination description */
interface ICombinationServer {
	Id: number;
	Pnt: number;
	Descr: string;
}
