import {Subscription} from "rxjs";
import {Entity, System} from "ecsy";
import {GameGraphics} from "../GameGraphics";
import {InputQueryComponent} from "./common/components";
import {IExtSystem} from "./IExtSystem";
import {map} from "rxjs/operators";
import {InputActionType, InputStreamEvent} from "../interfaces/InputStreamEvent";

/**
 * Handle Mouse / Touch / Keyboard inputs and push them into actions stack.
 * On execute() all items from the stack will be moved to InputQueryComponent query and stack will be flushed.
 * (!) Actions query in InputQueryComponent is flushed each iteration (execute call), thus,
 * only systems executed after InputSystem will be able to handle actions from query in InputQueryComponent.
 * (!) Use unregister() on system remove to unsubscribe from input streams
 */
export class InputSystem extends System implements IExtSystem {
	private gameGraphics: GameGraphics;
	private actions: InputStreamEvent[] = [];
	private subscriptions: Subscription[] = [];
	private inputQuery: Entity;
	
	init(attributes) {
		try {
			this.gameGraphics = attributes.gameGraphics;
			// this.actions = [];
			this.setup();
			this.inputQuery = this.world.createEntity("inputQuery")
				.addComponent(InputQueryComponent);
		}
		catch (e) {
			console.error("InputSystem.init: " + e);
		}
	}
	
	setup() {
		const pointer = this.gameGraphics.pointerController;
		const subscription = pointer.mouseStream$.pipe(
			map(item => {
				if (item.target && (item.target.name === "TileMesh" || (item.target.name === "TileCharacter"))) {
					item.target = item.target.parent;
				}
				return item;
			}),
			map(event => (new InputStreamEvent(InputActionType.Mouse, event)))
		).subscribe(value => this.actions.push(value));
		this.subscriptions.push(subscription);
	}
	
	unregister() {
		try {
			this.subscriptions.forEach(s => s.unsubscribe());
			this.subscriptions = [];
			
			if (this.inputQuery) {
				this.inputQuery.remove(); // remove entity and all of its components
			}
		}
		catch (e) {
			console.error("InputSystem.unregister: " + e);
		}
	}
	
	execute(delta: number, time: number): void {
		const inputQuery = this.queries.inputQuery.results[0];
		
		if (this.actions.length === 0) {
			const q = inputQuery.getComponent(InputQueryComponent).query;
			if (q.length > 0) {
				inputQuery.getMutableComponent(InputQueryComponent).query = [];
			}
		}
		else {
			try {
				const iqQuery = [];
				// add all of actions into inputQuery (input query)
				this.actions.forEach(action => iqQuery.push(action));
				inputQuery.getMutableComponent(InputQueryComponent).query = iqQuery;
				this.actions = [];
			}
			catch (e) {
				console.error("InputSystem.execute: actions.else:" + e);
			}
		}
	}
}

InputSystem.queries = {
	inputQuery: {
		components: [InputQueryComponent]
	}
};
