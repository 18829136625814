import {GameRulesAbstractSystem} from "../common/GameRulesAbstractSystem";
import {GameMessageDTO} from "../../../net/dto/GameMessageDTO";
import {IGameAction, UpdateWallTiles} from "../common/GameAction";
import {MoveType} from "../../enums/MoveType";
import {RulesHelperJM} from "./RulesHelperJM";
import {Attributes, Entity} from "ecsy";
import {JMRulesStateComponent, JMRulesStateComponentWrapper} from "./components/JMRulesStateComponent";
import {DoraIndicatorAction, LockTilesAction, ReadyAction, UnlockTilesAction, UraDoraIndicatorAction} from "./GameActionsJM";
import {fromConcealedToSlot, makeMove, parseIds, toTinyIDs} from "../common/common.commands";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {ICanMakeMove} from "../../interfaces/ICanMakeMove";
import {IJMIndicators} from "./IJMIndicators";
import {GameEventType} from "../../GameEventsPipe";
import {IGameSnapshotOptions} from "../../interfaces/IGameSnapshotOptions";
import {ReadyBarTagComponent} from "./components/ReadyBarTagComponent";
import {ReadyBarDeclaredTagComponent} from "./components/ReadyBarDeclaredTagComponent";
import {ReadyBarWrapper} from "./components/ReadyBarWrapper";
import {playerAddedOrRemovedQuery, playerSideChangedQuery} from "../queries";
import {PlayerWrapper} from "../wrappers/PlayersArray";
import {PlayerIdComponent} from "../common/components";
import {ReadyBarHelper} from "./ReadyBarHelper";
import {PlayerKuikaeComponent} from "./components/PlayerKuikaeComponent";
import {TileEntityHelper} from "../../helpers/TileEntityHelper";

export class GameRulesJMSystem extends GameRulesAbstractSystem {
	private rulesState: JMRulesStateComponentWrapper;
	
	init(attributes?: Attributes): void {
		super.init(attributes);
		this.getGameStorage().addComponent(JMRulesStateComponent);
		this.rulesState = JMRulesStateComponentWrapper.wrap(this.gameStorageQW.entity);
	}
	
	unregister(): void {
		try {
			this.getGameStorage()?.removeComponent(JMRulesStateComponent);
			this.rulesState = null;
			this.disposeAllReadyBars();
		}
		catch (e) {
			console.warn("GameRulesJMSystem.unregister: -- " + e);
		}
		super.unregister();
	}
	
	// @override
	protected setupRules() {
		const joinedGame = this.gameStore.joinedGame;
		const options = RulesHelperJM.getOptions(joinedGame);
		const gs = this.getGameStorageWrapper();
		gs.tableOptions.rulesSettings = options;
		gs.tableOptions.singleWallLength = options.singleWallLength;
		gs.tableOptions.maxPlayers = options.maxPlayers;
		gs.tableOptions.sides = options.sides;
		gs.tableOptions.actionsSet = options.actionsSet;
		gs.tableOptions.hasDeadWall = options.hasDeadWall ?? false;
		gs.tableOptions.dealDiceCalcFn = options.dealDiceCalcFn;
	}
	
	// @override
	protected setupCommands() {
		const cmd = super.setupCommands();
		cmd[MoveType.DORA_INDICATOR] = this.doraIndicator;
		cmd[MoveType.URA_DORA] = this.uraDora;
		cmd[MoveType.READY] = this.ready;
		cmd[MoveType.SHOW_BETS_COUNTER] = this.betsCounter;
		cmd[MoveType.SHOW_JM_COUNTER] = this.jmCounter;
		cmd[MoveType.JM_DEALER_CHANGED] = this.dealerChanged;
		cmd[MoveType.JM_RENCHAN_CHANGED] = this.renchanChanged;
		
		cmd[MoveType.LOCK_TILE] = this.lockTile;
		cmd[MoveType.UNLOCK_TILE] = this.unlockTile;
		//
		// cmd[MoveType.USER_CAN_MAKE_MOVE] = this.userCanMakeMove; // check overridden avActionsMap method
		// cmd[MoveType.GAME_SNAPSHOT_OPTIONS] = this.gameSnapshotOptions;
		cmd[MoveType.MAKE_MOVE] = this.makeMove;
		cmd[MoveType.FROM_CONCEALED_TO_SLOT] = this.fromConcealedToSlot;
		return cmd;
	}
	
	private doraIndicator(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new DoraIndicatorAction({
				gameMessage,
				rulesState: this.rulesState,
				singleWallLength: this.gameStorageQW.tableOptions.singleWallLength,
				allTiles: this.tileSet
			}),
			new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.rulesSettings.singleWallLength)
		];
	}
	
	private uraDora(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [
			new UraDoraIndicatorAction({
				tileIds: parseIds(gameMessage.Message),
				rulesState: this.rulesState,
				singleWallLength: this.gameStorageQW.tableOptions.singleWallLength,
				allTiles: this.tileSet
			}),
			new UpdateWallTiles(this.tileSet, this.gameStorageQW.tableOptions.rulesSettings.singleWallLength)
		];
	}
	
	private ready(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.rulesState.readyCounter++;
		if (gameMessage.UserId === this.gameStorageQW.myPlayerId) {
			this.rulesState.imInReadyState = true;
		}
		const player: PlayerWrapper = this.playersQRA.getPlayerById(gameMessage.UserId);
		const readyBar: ReadyBarWrapper = this.getReadyBar(player.id);
		return [
			new ReadyAction({readyBar, player})
		];
	}
	
	// Ready counter
	private betsCounter(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.rulesState.betCounter = +gameMessage.Message;
		this.gameEvents.send(GameEventType.JMCountersUpdated, this.getJmCounters());
		return [];
	}
	
	private jmCounter(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.rulesState.jmCounter = +gameMessage.Message;
		this.gameEvents.send(GameEventType.JMCountersUpdated, this.getJmCounters());
		return [];
	}
	
	private dealerChanged(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.rulesState.dealerCounter = +gameMessage.Message;
		this.gameEvents.send(GameEventType.JMCountersUpdated, this.getJmCounters());
		return [];
	}
	
	private renchanChanged(gameMessage: GameMessageDTO): Array<IGameAction> {
		this.rulesState.renchanCounter = +gameMessage.Message;
		this.gameEvents.send(GameEventType.JMCountersUpdated, this.getJmCounters());
		return [];
	}
	
	// Lock tiles (Kuikae)
	private lockTile(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [new LockTilesAction({
			tileTinyIds: toTinyIDs(parseIds(gameMessage.Message)),
			player: this.playersQRA.getPlayerById(gameMessage.UserId),
			allTiles: this.tileSet
		})];
	}
	
	private unlockTile(gameMessage: GameMessageDTO): Array<IGameAction> {
		return [new UnlockTilesAction({
			tileTinyIds: toTinyIDs(parseIds(gameMessage.Message)),
			player: this.playersQRA.getPlayerById(gameMessage.UserId),
			allTiles: this.tileSet
		})];
	}
	
	
	/*
	* Extension: If player making CHOW and he had Kuikae while declarig, we have to lock the remaining tile
	* */
	private makeMove(gameMessage: GameMessageDTO): IGameAction[] {
		switch (gameMessage.Message) {
			case ActionMoveType.CHOW:
			case ActionMoveType.CHOW_1:
			case ActionMoveType.CHOW_2:
			case ActionMoveType.CHOW_3:
				const player = this.playersQRA.getPlayerById(gameMessage.UserId);
				if (player.entity.hasComponent(PlayerKuikaeComponent)) {
					// looks like player had kuikae while declaring this chow. We need to lock tile with given id AFTER disposing chow (to lock the remaining tile)
					const tileId2lock = player.entity.getComponent(PlayerKuikaeComponent).lockTileTinyId;
					return makeMove.call(this, gameMessage) // default behaviour
						.push(new LockTilesAction({tileTinyIds: [tileId2lock], player, allTiles: this.tileSet})); // + lock tile
				}
				break;
			default:
				return makeMove.call(this, gameMessage);
		}
	}
	
	/*
	* Extension: If player has Kuikae component attached then he had locked tile we need to unlock for him after he discards this tile
	* */
	private fromConcealedToSlot(gameMessage: GameMessageDTO): IGameAction[] {
		const player = this.playersQRA.getPlayerById(gameMessage.UserId);
		if (player.entity.hasComponent(PlayerKuikaeComponent)) {
			const lockedTileTinyId = player.entity.getComponent(PlayerKuikaeComponent).lockTileTinyId;
			player.entity.removeComponent(PlayerKuikaeComponent); // remove as we dont need it anymore
			// looks like player had kuikae and now discarding tile. We should unlock the tile with given id (simply remove locks from his tiles)
			return fromConcealedToSlot.call(this, gameMessage)
				.push(new UnlockTilesAction({player, tileTinyIds: [lockedTileTinyId], allTiles: this.tileSet}));
		}
		return fromConcealedToSlot.call(this, gameMessage);
	}
	
	// -------------------------------------------------------------------------------------------------------------------------
	private getJmCounters(): IJMIndicators {
		return {
			jmCounter: this.rulesState.jmCounter,
			betCounter: this.rulesState.betCounter,
			renchanCounter: this.rulesState.renchanCounter,
			dealerCounter: this.rulesState.dealerCounter,
		};
	}
	
	/**
	 * Override. Set JM-specific options.
	 */
	protected gameSnapshotOptions(gameMessage: GameMessageDTO): Array<IGameAction> {
		const options = JSON.parse(gameMessage.Message) as IGameSnapshotOptions;
		this.rulesState.betCounter = options.betsTotalCount;
		this.rulesState.jmCounter = options.rcrCounter;
		this.rulesState.dealerCounter = options.dealerCounter;
		this.rulesState.renchanCounter = options.renchanCounter;
		this.gameEvents.send(GameEventType.JMCountersUpdated, this.getJmCounters());
		return super.gameSnapshotOptions(gameMessage);
	}
	
	protected newDeal(gameMessage: GameMessageDTO): Array<IGameAction> {
		try {
			this.rulesState.clearDoras();
			this.rulesState.readyCounter = 0;
			this.rulesState.imInReadyState = false;
			
			this.playersQRA.forEach(player => {
				const rb = this.getReadyBar(player.id);
				if (rb) {
					rb.isDeclared = false;
				}
				if (player.entity.hasComponent(PlayerKuikaeComponent)) {
					player.entity.removeComponent(PlayerKuikaeComponent);
				}
			});
			
			this.tileSet.forEach(tile => {
				if (TileEntityHelper.isLocked(tile)) {
					TileEntityHelper.unlockTile(tile);
				}
			});
			
			// this.rulesState.betCounter = ;
			// this.rulesState.jmCounter = options.rcrCounter;
			// this.rulesState.dealerCounter = options.dealerCounter;
			// this.rulesState.renchanCounter = options.renchanCounter;
			this.gameEvents.send(GameEventType.JMCountersUpdated, this.getJmCounters());
		}
		catch (e) {
			console.warn("GameRulesJMSystem.newDeal: -- " + e);
		}
		return super.newDeal(gameMessage);
	}
	
	// Override default. Do not filter MahjongSelf move as JM rules have both MJ and MJS with corresponding (different) declarations
	protected avActionsMap(moves: ActionMoveType[]): ICanMakeMove[] {
		const avMoves: ICanMakeMove[] = [];
		const hasMove = (key: ActionMoveType, array: ICanMakeMove[]) => {
			return ~array.findIndex(move => move.declare === key);
		};
		
		if (moves.length === 1 && moves[0] === ActionMoveType.PASS) {
			moves.shift();
		}
		if (moves.length === 1 && moves[0] === ActionMoveType.PUT_TILE) {
			moves.shift();
		}
		
		moves.forEach(move => {
			switch (move) {
				case ActionMoveType.CHOW_1:
				case ActionMoveType.CHOW_2:
				case ActionMoveType.CHOW_3:
					// NOTE: looks like server send only chow1, even if user has chow2 or chow3 or even multiple chows
					if (!hasMove(ActionMoveType.CHOW, avMoves)) {
						avMoves.push({display: ActionMoveType.CHOW, declare: ActionMoveType.CHOW});
					}
					break;
				case ActionMoveType.KONG:
				case ActionMoveType.KONG_SELF:
					if (!hasMove(ActionMoveType.KONG, avMoves)) {
						avMoves.push({display: ActionMoveType.KONG, declare: move});
					}
					break;
				// case ActionMoveType.MAHJONG_SELF: // !! All rules besides JM* display MJ instead of MJS
				// 	avMoves.push({display: ActionMoveType.MAHJONG, declare: move});
				// 	break;
				default:
					avMoves.push({display: move, declare: move});
			}
		});
		return avMoves;
	}
	
	// -------------------------------------------------------------------------------------------------------------------------
	private createReadyBar(player: Entity): void {
		try {
			const pew = PlayerWrapper.wrap(player);
			console.log(`GameRulesJMSystem.createReadyBar: player: ${pew.id} ${pew.name} at loc: ${pew.location}`);
			const rb = this.gameGraphics.addReadyBar();
			ReadyBarWrapper.create(this.world, rb, pew);
		}
		catch (e) {
			console.warn("GameRulesJMSystem.createReadyBar: " + e);
		}
	}
	
	private disposeReadyBar(playerId: number): void {
		try {
			const rb = this.getReadyBar(playerId);
			console.log("GameRulesJMSystem.disposeReadyBar: for player = " + rb?.playerId);
			if (rb) {
				this.gameGraphics.removeFromScene(rb.graphicObject);
				rb.entity.remove();
			}
		}
		catch (e) {
			console.warn("GameRulesJMSystem.disposeReadyBar: " + e);
		}
	}
	
	private disposeAllReadyBars() {
		[...this.queries.readyBars.results].forEach(bar => {
			const rbg = ReadyBarWrapper.wrap(bar)?.graphicObject;
			if (rbg) {
				this.gameGraphics.removeFromScene(rbg);
			}
			bar.remove();
		});
	}
	
	private getReadyBar(playerId: number): ReadyBarWrapper {
		return ReadyBarWrapper.wrap(this.queries.readyBars.results.find(bar => {
			return playerId === bar.getComponent(PlayerIdComponent).playerId;
		}));
	}
	
	private updateReadyBar(bar: Entity) {
		const barW = ReadyBarWrapper.wrap(bar);
		const pw = this.playersQRA.getPlayerById(barW.playerId);
		console.log(`GameRulesJMSystem.updateReadyBar: player=${pw?.name}`);
		if (barW && pw) {
			ReadyBarHelper.updatePosition(barW, pw);
		}
	}
	
	// -------------------------------------------------------------------------------------------------------------------------
	execute(delta: number, time: number): void {
		super.execute(delta, time);
		try {
			if (this.queries.playerAddedOrRemoved.added.length > 0) {
				this.queries.playerAddedOrRemoved.added.forEach(player => {
					this.createReadyBar(player);
				});
			}
			if (this.queries.playerAddedOrRemoved.removed.length > 0) {
				this.queries.playerAddedOrRemoved.removed.forEach(player => {
					console.log("GameRulesJMSystem.player removed: ", player);
					if (player.hasRemovedComponent(PlayerIdComponent)) {
						const pc = player.getRemovedComponent(PlayerIdComponent);
						this.disposeReadyBar(pc.playerId);
					}
				});
			}
			if (this.queries.readyBars.added.length > 0) {
				this.queries.readyBars.added.forEach(bar => {
					this.updateReadyBar(bar);
				});
			}
			if (this.queries.readyBarsDeclared.added.length > 0) {
				this.queries.readyBarsDeclared.added.forEach(bar => {
					this.updateReadyBar(bar);
				});
			}
			if (this.queries.readyBarsDeclared.removed.length > 0) {
				this.queries.readyBarsDeclared.removed.forEach(bar => {
					// if (bar.hasRemovedComponent(ReadyBarTagComponent)) {
					// 	return;
					// } // exit if readyBar was removed
					this.updateReadyBar(bar);
				});
			}
			
			if (this.queries.playerSideChanged.changed.length > 0) {
				try {
					console.log("GameRulesJMSystem.execute: playerSideChanged -- ");
					this.queries.playerSideChanged.changed.forEach(player => {
						const bar = this.getReadyBar(player.getComponent(PlayerIdComponent).playerId);
						this.updateReadyBar(bar.entity);
					});
				}
				catch (e) {
					console.error("GameRulesJMSystem.execute: playerSideChanged.changed: " + e);
				}
			}
			
		}
		catch (e) {
			console.error("GameRulesJMSystem.execute: -- " + e);
		}
	}
}

GameRulesJMSystem.queries.playerAddedOrRemoved = playerAddedOrRemovedQuery;

GameRulesJMSystem.queries.readyBars = {
	components: [ReadyBarTagComponent],
	listen: {
		added: true,
		removed: false,
		changed: false
	}
};
GameRulesJMSystem.queries.readyBarsDeclared = {
	components: [ReadyBarTagComponent, ReadyBarDeclaredTagComponent],
	listen: {
		added: true,
		removed: true,
		changed: false
	}
};
GameRulesJMSystem.queries.playerSideChanged = playerSideChangedQuery;
