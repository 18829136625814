import {Entity, System} from "ecsy";
import {GameGraphics} from "../GameGraphics";
import {
	ActionMenuDataComponent,
	ActionMenuGuiComponent,
	ActionMenuPrevEntity,
	ActionMenuUIStateComponent,
	InputQueryComponent,
	IsVisibleTagComponent,
	PlayerActionsComponent,
	PlayerIdComponent,
	PlayerStoreComponent,
	TurnStateComponent
} from "./common/components";
import {IExtSystem} from "./IExtSystem";
import {ActionMoveType} from "../enums/ActionMoveType";
import {ActionMenuItemGraphics} from "../graphics/ActionMenuItemGraphics";
import {TexturePack} from "../graphics/TexturePack";
import {ActionMenuQuery, AllTilesQuery, GameMessagesChangedQuery, GameStorageQuery, playerSideChangedQuery, PlayersQuery} from "./queries";
import {GameStorageWrapper} from "./wrappers/GameStorageWrapper";
import {ActionMenuEntityHelper} from "./ActionMenuEntityHelper";
import {ActionMenuWrapper} from "./wrappers/ActioMenuWrapper";
import {NetGameManager} from "../NetGameManager";
import {Euler, Object3D} from "three";
import {PlayersArray} from "./wrappers/PlayersArray";
import {AppEventsPipe, AppEventType} from "../AppEventsPipe";
import {GameAccessHelper} from "../helpers/GameAccessHelper";
import {DiscardsTilesHelper} from "../helpers/DiscardsTilesHelper";
import {TileEntityHelper} from "../helpers/TileEntityHelper";
import {GameDialogType, IGameDialogButtonConfig, IGameDialogConfig} from "../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {GameEventsPipe, GameEventType} from "../GameEventsPipe";
import {IInputMouseEvent, InputActionType, InputMouseEventType} from "../interfaces/InputStreamEvent";
import {ICanMakeMove} from "../interfaces/ICanMakeMove";
import {TileSetHelper} from "../helpers/TileSetHelper";

export type actionT = {
	id: ActionMoveType;
	label: string;
	color?: number;
};

export class ActionMenuSystem extends System implements IExtSystem {
	private gameGraphics: GameGraphics;
	private texturePack: TexturePack;
	protected netManager: NetGameManager;
	private appEvents: AppEventsPipe;
	private gameEvents: GameEventsPipe;
	private gameAccessHelper: GameAccessHelper;
	
	private actions: Array<actionT>;
	private actionEntities: Array<Entity> = [];
	
	init(attributes) {
		this.gameGraphics = attributes.gameGraphics;
		this.texturePack = attributes.texturePack;
		this.netManager = attributes.netGameManager;
		this.appEvents = attributes.appEvents;
		this.gameEvents = attributes.gameEvents;
		this.gameAccessHelper = attributes.gameAccessHelper;
	}
	
	unregister(): void {
		try {
			this.removeActions();
		}
		catch (e) {
			console.error("ActionMenuSystem.unregister: " + e);
		}
	}
	
	private updateMenuItemPosition(entity: Entity) {
		
		const graphicObj = entity.getComponent(ActionMenuGuiComponent).obj;
		const prevAm = entity.getComponent(ActionMenuPrevEntity).prevEntity;
		let x = 0;
		if (prevAm) {
			const prevGraphics = prevAm.getComponent(ActionMenuGuiComponent).obj;
			x = prevGraphics.x + prevGraphics.width / 2 + graphicObj.width / 2 + 6;
		}
		graphicObj.x = x; // store x pos in the item, because of position.x will be modified during transform/rotate
		graphicObj.position.x = x - 200;
		graphicObj.position.y = 35;
		graphicObj.position.z = 165;
		graphicObj.setRotationFromEuler(new Euler(0, 0, 0));
		/**/
		const playerId = entity.getComponent(PlayerIdComponent).playerId;
		const transform = this.getPlayerTransformById(playerId);
		graphicObj.position.applyQuaternion(transform.quaternion); // rotate positions
		graphicObj.applyQuaternion(transform.quaternion); // rotate menu items itself
		graphicObj.rotateX(-90 * 3.14 / 180); // TODO: for debug only: rotate face up
		graphicObj.visible = false; // initially not visible
		
	}
	
	execute(delta: number, time: number): void {
		this.execInput();
		this.execMouseInteraction();
		
		// Create Actions when new player appears
		if (this.queries.playerActions.added.length > 0) {
			try {
				this.queries.playerActions.added.forEach(entity => {
					const playerId = entity.getComponent(PlayerIdComponent).playerId;
					// Create only actions for playing player, as we dont need action menu for other players (can be used while reviewing an archived game)
					// also check update actions, as they are also filtered by playerId
					if (playerId === this.gameStorageQ.myPlayerId) {
						this.createActions(this.gameStorage.tableOptions.actionsSet, playerId);
					}
				});
			}
			catch (e) {
				console.error("ActionMenuSystem.execute: playerActions.added: " + e);
			}
		}
		
		// Update Actions set on available actions change
		if (this.queries.playerActions.changed.length > 0) {
			try {
				this.queries.playerActions.changed.forEach(entity => {
					const playerId = entity.getComponent(PlayerIdComponent).playerId;
					// Update only actions for playing player, as we didnt create actions for other players
					if (playerId === this.gameStorageQ.myPlayerId) {
						const actions = entity.getComponent(PlayerActionsComponent).actions;
						this.setAvailableMoves(playerId, actions);
					}
				});
			}
			catch (e) {
				console.error("ActionMenuSystem.execute: playerActions.changed: " + e);
			}
		}
		
		if (this.queries.gameStorage.changed.length > 0) {
			this.execStorageTurnChange();
		}
		
		if (this.queries.positioning.added.length > 0) {
			try {
				// the items in query will be in inserting order (pass > mahjong) (all AM of all players!)
				this.queries.positioning.added.forEach(entity => this.updateMenuItemPosition(entity));
			}
			catch (e) {
				console.error("ActionMenuSystem.execute: positioning.added: " + e);
			}
		}
		
		// Update visibility of actions
		if (this.queries.visibility.added.length > 0) {
			try {
				this.queries.visibility.added.forEach(entity => {
					entity.getComponent(ActionMenuGuiComponent).obj.visible = true;
				});
			}
			catch (e) {
				console.error("ActionMenuSystem.execute: visibility.added: " + e);
			}
		}
		
		if (this.queries.visibility.removed.length > 0) {
			try {
				this.queries.visibility.removed.forEach(entity => {
					// if (entity.hasRemovedComponent(IsVisibleTag)) {
					entity.getComponent(ActionMenuGuiComponent).obj.visible = false;
					// }
				});
			}
			catch (e) {
				console.error("ActionMenuSystem.execute: visibility.removed: " + e);
			}
		}
		
		if (this.queries.playerSideChanged.changed.length > 0) {
			try {
				console.log("ActionMenuSystem.execute: playerSideChanged -- update menu positions");
				this.queries.playerSideChanged.changed.forEach(player => {
					this.actionMenuQ
						.filter(am => am.getComponent(PlayerIdComponent).playerId === player.getComponent(PlayerIdComponent).playerId)
						.forEach(am => this.updateMenuItemPosition(am));
				});
			}
			catch (e) {
				console.error("ActionMenuSystem.execute: playerSideChanged.changed: " + e);
			}
		}
	}
	
	// *************************************************************************************************************************
	private execInput(): void {
		if (this.queries.inputQuery.results.length > 0) {
			try {
				const iq = this.queries.inputQuery.results[0];
				const iqQuery = iq.getComponent(InputQueryComponent).query;
				
				if (iqQuery.length > 0) {
					iqQuery.forEach(action => {
						if (action.type === InputActionType.Mouse) {
							const wasProcessed = this.processMouseInputAction(action.payload as IInputMouseEvent);
						}
						/*else if (action.type === InputActionType.Keyboard) {
							this.processKeyboardInputAction(action.payload as IInputKeyPressed);
						}*/
					});
				}
			}
			catch (e) {
				console.error("ActionMenuSystem.execInput: " + e);
			}
		}
	}
	
	private execMouseInteraction(): void {
		if (this.queries.mouseInteraction.changed.length > 0) {
			try {
				this.queries.mouseInteraction.changed.forEach(amEntity => {
						const amw = ActionMenuEntityHelper.wrapActionMenuEntity(amEntity);
						switch (amw.uiState) {
							case "over":
								amw.graphics.setSafeScaleAnimated(1.1);
								break;
							case "clicked":
								amw.graphics.setSafeScaleAnimated(0.88);
								break;
							case "normal":
							default:
								amw.graphics.setSafeScaleAnimated(1);
						}
					}
				);
			}
			catch (e) {
				console.error("ActionMenuSystem.execMouseInteraction: " + e);
			}
		}
		
	}
	
	private execStorageTurnChange(): void {
		try {
			const makingMove = this.gameStorageQ.turnState.makingMove;
			const waitingForNextMove = this.gameStorageQ.turnState.waitingForNextMove;
			// in case if user is making move or made a move - hide menu
			const playerId = this.gameStorageQ.myPlayerId;
			const player = this.playersQRA.getPlayerById(playerId);
			if (makingMove || waitingForNextMove) {
				this.setAvailableMoves(playerId, []);
			}
			else {
				this.setAvailableMoves(playerId, player.actions);
			}
		}
		catch (e) {
			console.error("ActionMenuSystem.execStorageTurnChange: " + e);
		}
	}
	
	// *************************************************************************************************************************
	private createActions(actions: Array<actionT>, playerId: number) {
		this.actions = actions;
		let entity: Entity = null;
		for (const action of actions) {
			entity = this.createMenuActionEntity(action, playerId, entity);
			this.actionEntities.push(entity);
		}
	}
	
	private removeActions() {
		try {
			for (const actionEntity of this.actionEntities) {
				const graphics = actionEntity.getComponent(ActionMenuGuiComponent).obj;
				this.gameGraphics.removeFromScene(graphics);
				actionEntity.remove();
			}
		}
		catch (error) {
			console.error("ActionMenuSystem.removeActions: ", error);
		}
		this.actionEntities = [];
	}
	
	private setAvailableMoves(playerId: number, moves: Array<ICanMakeMove>) {
		console.log(`ActionMenuSystem.setAvailableMoves(${playerId}, ${moves})`);
		const playerActions = this.actionEntities.filter(entity => entity.getComponent(PlayerIdComponent).playerId === playerId);
		playerActions.forEach(entity => {
			const isVisible = entity.hasComponent(IsVisibleTagComponent);
			const actionId = entity.getComponent(ActionMenuDataComponent).id;
			const avMove = moves.find(move => move.display === actionId);
			if (avMove) {
				if (!isVisible) {
					entity.getMutableComponent(ActionMenuDataComponent).declarationId = avMove.declare;
					entity.addComponent(IsVisibleTagComponent);
				}
			}
			else {
				if (isVisible) {
					entity.removeComponent(IsVisibleTagComponent);
				}
			}
		});
	}
	
	private createMenuActionEntity(action: actionT, playerId: number, prevEntity: Entity = null): Entity {
		const actionMenuItemGraphics = new ActionMenuItemGraphics({action, playerId, tp: this.texturePack});
		this.gameGraphics.addActionMenuItem(actionMenuItemGraphics);
		
		const actionEntity = this.world.createEntity("ActionMenu")
			.addComponent(PlayerIdComponent, {playerId})
			.addComponent(ActionMenuDataComponent, {id: action.id, label: action.label})
			.addComponent(ActionMenuGuiComponent, {obj: actionMenuItemGraphics})
			.addComponent(ActionMenuPrevEntity, {prevEntity})
			.addComponent(ActionMenuUIStateComponent)
		;
		// actionEntity.addComponent(IsVisibleTag); // added when item should be shown
		return actionEntity;
	}
	
	// *************************************************************************************************************************
	private getPlayerTransformById(playerId: number) {
		return this.getPlayerEntity(playerId).getComponent(PlayerStoreComponent).locationTransform;
	}
	
	private get players(): Entity[] {
		return this.queries.players.results;
	}
	
	private getPlayerEntity(playerId: number): Entity {
		return this.players.find(playerEntity => playerEntity.getComponent(PlayerIdComponent).playerId === playerId);
	}
	
	private get gameStorage(): GameStorageWrapper {
		const gameStorage = this.queries.gameStorage.results[0];
		return gameStorage ? new GameStorageWrapper(gameStorage) : null;
	}
	
	private getActionMenuW(g: ActionMenuItemGraphics): ActionMenuWrapper {
		const amEntity = ActionMenuEntityHelper.getActionMenuEntityByGraphics(g as ActionMenuItemGraphics, this.actionMenuQ);
		if (amEntity) {
			const amWrapper = ActionMenuEntityHelper.wrapActionMenuEntity(amEntity);
			return amWrapper;
		}
		else {
			console.warn("ActionMenuSystem.getActionMenuW:  cannot find corresponding entity");
			return null;
		}
	}
	
	// *************************************************************************************************************************
	private isActionMenuGraphics(object: Object3D): boolean {
		return object && object instanceof ActionMenuItemGraphics;
	}
	
	private processMouseInputAction(input: IInputMouseEvent): boolean {
		switch (input.type) {
			case InputMouseEventType.CLICK:
				if (this.isActionMenuGraphics(input.target)) {
					this.onActionMenuClick(this.getActionMenuW(input.target as ActionMenuItemGraphics));
				}
				break;
			case InputMouseEventType.ROLL_OVER:
				if (this.isActionMenuGraphics(input.target)) {
					this.onActionMenuOver(this.getActionMenuW(input.target as ActionMenuItemGraphics));
				}
				break;
			case InputMouseEventType.ROLL_OUT:
				if (this.isActionMenuGraphics(input.target)) {
					this.onActionMenuOut(this.getActionMenuW(input.target as ActionMenuItemGraphics));
				}
				break;
		}
		return false;
	}
	
	private onActionMenuClick(actionMenuItem: ActionMenuWrapper): void {
		console.log(`onActionMenuClick: ${actionMenuItem}`);
		if (!actionMenuItem) {
			return;
		}
		actionMenuItem.uiState = "clicked";
		setTimeout(() => {
			actionMenuItem.uiState = "normal";
		}, 1000); // TODO: what is user clicked and left game immediately?
		
		const iCanDeclare = this.gameAccessHelper.iCanMakeDeclaration(this.gameStorageQ, this.playersQRA); // TODO: add ActionMoveType check inside
		if (!iCanDeclare.result) {
			console.log(`You cannot declare: ${iCanDeclare.errorMessage}:${iCanDeclare.error}`);
			this.showSnack(`You cannot declare: ${iCanDeclare.errorMessage}:${iCanDeclare.error}`);
			return;
		}
		
		switch (actionMenuItem.id) {
			case ActionMoveType.CHOW:
				this.onActionMenuClick_Chow(actionMenuItem);
				break;
			case ActionMoveType.KONG:
				this.onActionMenuClick_Kong(actionMenuItem);
				break;
			case ActionMoveType.MAHJONG:
				// declarationId will hold Mahjong or MahjongSelf move id to declare
				// this.netManager.makeActionMove(actionMenuItem.playerId, actionMenuItem.declarationId,
				// this.getPlayerEntity(actionMenuItem.playerId), this.getGameStorage());
				this.netManager.makeActionMove({
					action: actionMenuItem.declarationId,
					gameStorageW: this.gameStorageQ,
					playersArr: this.playersQRA
				});
				break;
			default:
				this.netManager.makeActionMove({action: actionMenuItem.id, gameStorageW: this.gameStorageQ, playersArr: this.playersQRA});
		}
		// this.sendGameEvent(GameEventType.MakeMove_Succeed); // TODO: failed case
	}
	
	
	protected onActionMenuClick_Chow(actionMenuItem: ActionMenuWrapper): void {
		const slotTile = DiscardsTilesHelper.getSlotTile(this.allTilesQ);
		if (slotTile) {
			const slotTileW = TileEntityHelper.wrapEntity(slotTile);
			// declare action
			const chowList = TileSetHelper.getAvailableChows({
				slotTileId: slotTileW.tinyId,
				playerId: actionMenuItem.playerId,
				allTiles: this.allTilesQ
			}).chows;
			
			switch (chowList.length) {
				case 0:
					console.warn("ActionMenuSystem.onActionMenuClick_Chow: User doesnt have a chow");
					break;
				case 1:
					this.netManager.makeActionMove({
						action: chowList[0].type,
						tileId: slotTileW.tinyId,
						gameStorageW: this.gameStorageQ,
						playersArr: this.playersQRA
					});
					break;
				default:
					const buttons: IGameDialogButtonConfig[] = chowList
						.map<IGameDialogButtonConfig>(chow => ({
							id: ActionMoveType.CHOW,
							label: chow.tiles.map(id => id % 10).join("-"),
							data: {tileId: slotTileW.tinyId, action: chow.type}
						}));
					const dialogCfg: IGameDialogConfig = {
						id: "MultipleChoiceDialog",
						type: GameDialogType.Regular,
						title: "Multiple Choice Chow",
						titleResourceKey: "Game.Dialog.ChooseChow.Caption",
						message: "",
						buttons,
					};
					this.displayGameDialog(dialogCfg);
			}
			
		}
		else {
			console.warn(`onActionMenuClick.${actionMenuItem.id}: there is no slot tile`);
		}
	}
	
	protected onActionMenuClick_Kong(actionMenuItem: ActionMenuWrapper): void {
		// there are only 2 actions for kong: Kong & Kong_Self
		if (actionMenuItem.declarationId === ActionMoveType.KONG_SELF) {
			// get list of kong selfs
			// const concealedTiles = TileSetHelper.getPlayerConcealedTiles(action.playerId, this.allTilesQ);
			const kongSelfTilesList = TileSetHelper.getPlayerKongSelfList(actionMenuItem.playerId, this.allTilesQ);
			if (kongSelfTilesList.length === 0) {
				console.warn("There is no kong self in player hand");
			}
			else if (kongSelfTilesList.length === 1) {
				// this.netManager.makeMove(ActionMoveType.KONG_SELF, kongSelfTilesList[0], this.getGameStorage());
				this.netManager.makeActionMove({
					action: ActionMoveType.KONG_SELF,
					tileId: kongSelfTilesList[0],
					gameStorageW: this.gameStorageQ,
					playersArr: this.playersQRA
				});
			}
			else { // player has more than one kong self
				const buttons: IGameDialogButtonConfig[] = kongSelfTilesList
					.map<IGameDialogButtonConfig>(tileId => ({id: ActionMoveType.KONG_SELF, label: "Kong: " + tileId, data: tileId}));
				
				const dialogCfg: IGameDialogConfig = {
					id: "MultipleChoiceDialog", type: GameDialogType.Regular, title: "Multiple Choice Kong Self",
					message: "",
					buttons,
				};
				this.displayGameDialog(dialogCfg);
			}
		}
		else { // Regular Kong
			const slotTileK = DiscardsTilesHelper.getSlotTile(this.allTilesQ);
			if (slotTileK) {
				const slotTileW = TileEntityHelper.wrapEntity(slotTileK);
				// this.netManager.makeMove(ActionMoveType.KONG, slotTileW.tinyId, this.getGameStorage());
				this.netManager.makeActionMove({
					action: ActionMoveType.KONG,
					tileId: slotTileW.tinyId,
					gameStorageW: this.gameStorageQ,
					playersArr: this.playersQRA
				});
			}
			else {
				console.warn(`onActionMenuClick.${actionMenuItem.id}: there is no slot tile`);
			}
		}
	}
	
	private onActionMenuOver(actionMenuItem: ActionMenuWrapper): void {
		if (!actionMenuItem) {
			return;
		}
		actionMenuItem.uiState = "over";
	}
	
	private onActionMenuOut(actionMenuItem: ActionMenuWrapper): void {
		if (!actionMenuItem) {
			return;
		}
		// actionMenuItem.graphics.geometry.scale(1, 1, 1);
		// actionMenuItem.graphics.translateZ(-10);
		// actionMenuItem.isOver = false;
		actionMenuItem.uiState = "normal";
	}
	
	// *************************************************************************************************************************
	protected displayGameDialog(value: IGameDialogConfig): void {
		this.sendGameEvent(GameEventType.Display_GameDialog, value);
	}
	
	protected showSnack(message: string): void {
		this.appEvents.send(AppEventType.ShowSnack, {message});
	}
	
	protected sendGameEvent(type: GameEventType, data?): void {
		this.gameEvents.send(type, data);
	}
	
	// *************************************************************************************************************************
	
	protected get playersQ(): Entity[] {
		return this.queries.players.results;
	}
	
	protected get playersQRA() {
		return new PlayersArray().setPlayerEntities(this.playersQ);
	}
	
	protected get allTilesQ(): Entity[] {
		return this.queries.tiles.results;
	}
	
	protected get actionMenuQ(): Entity[] {
		return this.queries.actionMenuItems.results;
	}
	
	protected get actionMenuQW(): ActionMenuWrapper[] {
		return this.queries.actionMenuItems.results.map(amEntity => new ActionMenuWrapper(amEntity));
	}
	
	protected getGameStorage(): Entity {
		return this.queries.gameStorage.results[0];
	}
	
	protected get gameStorageQ(): GameStorageWrapper {
		return new GameStorageWrapper(this.queries.gameStorage.results[0]);
	}
	
}

ActionMenuSystem.queries = {
	mouseInteraction: {
		components: [ActionMenuUIStateComponent], listen: {
			added: false,
			removed: false,
			changed: true,
		}
	},
	positioning: {
		components: [ActionMenuDataComponent], listen: {
			added: true,
			removed: false,
			changed: false
		}
	},
	visibility: {
		components: [ActionMenuDataComponent, IsVisibleTagComponent], listen: {
			added: true,
			removed: true,
			changed: false
		}
	},
	playerActions: {
		components: [PlayerActionsComponent], listen: {
			added: true,
			removed: false,
			changed: true
		}
	},
	inputQuery: {
		components: [InputQueryComponent]
	},
	tiles: AllTilesQuery,
	players: PlayersQuery,
	gameStorage: {
		...GameStorageQuery, listen: {
			added: false,
			removed: false,
			changed: [TurnStateComponent]
		}
	},
	actionMenuItems: ActionMenuQuery,
	playerSideChanged: playerSideChangedQuery,
	
	gameMessagesChanged: GameMessagesChangedQuery,
};

