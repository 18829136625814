import {ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {OnlineUserService} from "../net/service/OnlineUserService";
import {OnlineUserStatus} from "../game/enums/OnlineUserStatus";
import {GameState} from "../game/enums/GameState";
import {GameStore} from "../store/GameStore";

export class UpdateOnlineStatusCommand extends ICommand {
	
	async execute(): Promise<boolean> {
		return; // turn off temporary as requested by Slava to keep desktop users away from PrivateGames (join via player info/ online players)
		try {
			await this.onlineUserService.updateOnlineStatus(this.userStore.sessionKey, this.myStatus);
		}
		catch (e) {
			console.warn("UpdateOnlineStatusCommand.execute: " + e);
		}
		return true;
	}
	
	private get myStatus() {
		const joinedGame = this.gameStore?.joinedGame;
		if (joinedGame) {
			const myStatus: OnlineUserStatus = joinedGame.IsMyGame
				? (joinedGame.StateId < GameState.PLAYING ? OnlineUserStatus.WAITING : OnlineUserStatus.PLAYING) : OnlineUserStatus.SPECTATING;
			return myStatus + "#" + `${joinedGame.RoomId}#${joinedGame.GameId}#${joinedGame.Name}`;
		}
		else {
			return OnlineUserStatus.FREE + "###";
		}
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
	private get onlineUserService(): OnlineUserService {
		return container.get<OnlineUserService>(TYPES.OnlineUserService);
	}
	
}
