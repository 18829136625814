<router-outlet></router-outlet>

<button mat-mini-fab (click)="onBugRepClick()" class="bug-rep-icon" matTooltip="Leave us a comment">
    <i class="material-icons">rate_review</i>
</button>

<button mat-mini-fab (click)="onSettingsClick()" class="icon-regular" matTooltip="Settings" color="secondary">
    <i class="material-icons">settings</i>
</button>

<button mat-fab color="secondary" (click)="onLogoutClick()" class="log-out">
    <mat-icon matTooltip="Logout">exit_to_app</mat-icon>
</button>
