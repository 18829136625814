import {ICommand} from "../commander/Commander";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {UserStore} from "../store/UserStore";
import {AppStore} from "../store/AppStore";
import {MatDialog} from "@angular/material/dialog";
import {GameStore} from "../store/GameStore";
import {SettingsDialogComponent} from "../app/ui/dialogs/settings-dialog/settings-dialog.component";

// import {saveAs} from "file-saver";

export class SettingsCommand extends ICommand {
	
	async execute({displayDialog = false, dialogSrv}: { displayDialog: boolean, dialogSrv?: MatDialog }): Promise<boolean> {
		try {
			
			if (displayDialog) {
				const dialogRef = dialogSrv.open(SettingsDialogComponent, {
					disableClose: true,
				});
				dialogRef.afterClosed().subscribe(async (result) => {
				
				});
			}
			else {
				console.log("SettingsCommand.execute: dialog was not displayed");
			}
			return true;
		}
		catch (e) {
			console.warn("SettingsCommand.execute: -- " + e);
		}
		return false;
	}
	
	private get appStore(): AppStore {
		return container.get<AppStore>(TYPES.AppStore);
	}
	
	private get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get gameStore(): GameStore {
		return container.get<GameStore>(TYPES.GameStore);
	}
	
}
