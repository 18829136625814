<div style="display: flex; gap: 2rem;">
    <h2 style="color: #ababab">{{ 'Lobby.MyAccount.GeneralPage.WelcomeUser' | translate:{userName: userName} }}</h2>
    <button mat-raised-button (click)="switchMode('normal')" *ngIf="isDebug">Switch to Normal</button>
</div>
<div class="box">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                             #group="matButtonToggleGroup"
                             (change)="changeMode(group.value)"
                             [value]="selectedGameMode"
    >
        <mat-button-toggle *ngFor="let mode of avGameModes" [value]="mode">
            <mat-icon color="accent">{{mode.icon}}</mat-icon>
            {{mode.label}}
        </mat-button-toggle>

        <!--<mat-button-toggle value="italic" align="center">
            <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                <mat-icon color="accent">rowing</mat-icon>
                <div>Italic</div>
            </div>
        </mat-button-toggle>-->
    </mat-button-toggle-group>

    <mat-button-toggle-group name="fontStyle1" aria-label="Font Style"
                             #group1="matButtonToggleGroup"
                             (change)="changeRules(group1.value)"
                             [value]="selectedGameRules"
    >

        <mat-button-toggle *ngFor="let rules of avGameRules" [value]="rules">
            <mat-icon color="accent">{{rules.icon}}</mat-icon>
            {{rules.label}}
        </mat-button-toggle>

        <!--<mat-button-toggle value="italic" align="center">
            <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                <mat-icon color="accent">rowing</mat-icon>
                <div>Italic</div>
            </div>
        </mat-button-toggle>-->
    </mat-button-toggle-group>
</div>

<mat-card *ngIf="iHaveGames">
    <h2 align="center">Your Games</h2>
</mat-card>
<div *ngIf="iHaveGames" style="display: flex; flex-direction: row; justify-content:center; flex-wrap: wrap;">
    <table-card *ngFor="let game of myGames" [table]="game" showGameType="true"
                (actionEmitter)="onTableAction($event)"></table-card>
</div>

<mat-card>
    <h2 align="center">{{iHaveGames ? 'More Games' : 'Games'}}</h2>
</mat-card>
<div style="display: flex; flex-direction: row; justify-content:center; flex-wrap: wrap;">
    <table-card *ngFor="let game of notMyGames" [table]="game" (actionEmitter)="onTableAction($event)"></table-card>
</div>

<!--<mat-list>
    <mat-list-item *ngFor="let message of chatMessages$ | async">
        {{message.UserName}}: {{message.Message}}
    </mat-list-item>
</mat-list>-->

<!--<mat-nav-list #shoes2 multiple="false">
    <mat-list-item *ngFor="let game of gamesList">

        <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="space-around start">
                {{game.Name}}: {{game.StateId}}
                &lt;!&ndash;<p matLine>phone: {{game.phone}}  | email: {{game.email}}</p>&ndash;&gt;
                <button type="button" mat-icon-button (click)="doJoinGame(game)"
                        matTooltip="Join">
                    <mat-icon color="accent">rowing</mat-icon>
                </button>
            </div>


        </div>
    </mat-list-item>
</mat-nav-list>-->
<!--
<div style="display: flex; flex-direction: row; justify-content:center; flex-wrap: wrap;">
    <table-card *ngFor="let game of myGames" [table]="game" (actionEmitter)="onTableAction($event)"></table-card>
</div>-->

<!--
<div>
    <div class="game-card">
        <div style="border-radius: 1em; background-color: #ffffff;">

            <img class="iconx" src="https://icons.iconarchive.com/icons/jommans/mahjong/64/Desktop-Dice-icon.png">
        </div>

    </div>
    <div class="game-card">
        <div>
            <img class="iconx" src="https://placekitten.com/400/200">
        </div>
        <div>
            <span>Title</span>
            <span>message</span>
            <div id="actions">
                <span>icons</span>
                <span>button</span>
            </div>
        </div>
    </div>
</div>
-->
