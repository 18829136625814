export enum DealState {
	NA,
	REPLACE_FLOWERS = 10, // Co flower replacement at start of deal
	CHARLESTON_1_RIGHT = 11,
	CHARLESTON_1_OPPOSITE = 12,
	CHARLESTON_1_LEFT = 13,
	WAIT_FOR_CHARLESTON_2 = 14,
	CHARLESTON_2_LEFT = 15,
	CHARLESTON_2_OPPOSITE = 16,
	CHARLESTON_2_RIGHT = 17,
	WAIT_FOR_CHARLESTON_3 = 18,
	CHARLESTON_3_OPPOSITE = 19,
	PLAYING = 20,
	DEAD_HAND = 21,
	FORCED_FINISH = 25,
	DEAD_WALL = 30,
	WRONG_MAHJONG = 40,
	WRONG_MAHJONG_SELF = 50,
	MAHJONG = 60,
	MAHJONG_SELF = 70,
}
