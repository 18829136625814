import {System} from "ecsy";
import {IExtSystem} from "./IExtSystem";
import {GraphicsComponent, TileDataComponent, TilePositionComponent, TileRotationComponent} from "./common/components";
import {gsap} from "gsap";

export class TileAnimSystem extends System implements IExtSystem {
	
	unregister(): void {
	
	}
	
	execute(delta: number, time: number): void {
		if (this.queries.entities.changed.length > 0) {
			try {
				this.queries.entities.changed.forEach(entity => {
					const newPos = entity.getComponent(TilePositionComponent).position;
					const tileObject = entity.getComponent(GraphicsComponent).obj;
					const meshPos = tileObject.position;
					
					const dist = meshPos.distanceTo(newPos);
					if (dist > 10) { // move with animation if distance in 10+
						const duration = dist / 1500 + 0.1;
						
						gsap.to(tileObject.position, {duration, x: newPos.x, y: newPos.y, z: newPos.z, ease: "expo.out", overwrite: true});
						// const t = gsap.to(tileObject.rotation, {duration: 40, y: Math.PI * 2, repeat: -1, ease: "none"});
					}
					else if (dist > 1) { // move tile without animation if distance is [1..10]
						if (gsap.isTweening(tileObject.position)) {
							gsap.killTweensOf(tileObject.position);
						}
						meshPos.x = newPos.x;
						meshPos.y = newPos.y;
						meshPos.z = newPos.z;
					}
					tileObject.setRotationFromQuaternion(entity.getComponent(TileRotationComponent).rotation);
				});
			}
			catch (e) {
				console.error("TileAnimSystem.execute: entities.changed: " + e);
			}
		}
	}
}

TileAnimSystem.queries = {
	entities: {
		components: [TileDataComponent, GraphicsComponent, TilePositionComponent], listen: {
			added: false,
			removed: false,
			changed: [TilePositionComponent]
		}
	}
};
