import * as THREE from "three";
import {Geometry, Mesh, MeshLambertMaterial, MeshLambertMaterialParameters} from "three";
import {TexturePack} from "./TexturePack";
import {actionT} from "../ecs/ActionMenuSystem";
import {gsap} from "gsap";

export class ActionMenuItemGraphics extends Mesh {
	
	// used during positioning
	public x = 0;
	
	private textureSize = 1024; // TODO: remove hardcoded value
	// total width of all characters
	private _width = 0;
	// characters scale factor
	private scaleGr = 0.25;
	
	public action: actionT;
	public playerId: number;
	private tp: TexturePack;
	private _safeScale = 1;
	
	constructor({action, playerId, tp}: { action: actionT, playerId: number, tp: TexturePack }) {
		super();
		this.action = action;
		this.playerId = playerId;
		this.tp = tp;
		
		const upCaseLabel = (action.label ?? "").toUpperCase();
		this.createLabel(upCaseLabel);
		(this as Mesh).translateZ(350);
	}
	
	public get width(): number {
		return this._width;
	}
	
	public get safeScale(): number {
		return this._safeScale;
	}
	
	public set safeScale(value: number) {
		if (this.geometry) {
			const sv = 1 / this._safeScale * value;
			this.geometry.scale(sv, sv, sv);
			this.geometry.translate(0, 0, sv * 15 - 15);
			this._safeScale = value;
			this.renderOrder = sv > 1 ? 999 : 998;
		}
	}
	
	public setSafeScaleAnimated(value: number) {
		gsap.to(this, {duration: 0.2, safeScale: value, ease: "back.out(2)", overwrite: true});
	}
	
	private createLabel(label: string) {
		
		const geo = new Geometry();
		this._width = label.split("").reduce((acc, letter) => {
			const charRegionData = this.tp.fontAtlasRegions[letter];
			if (!charRegionData) {
				console.warn("There is no data exists for character: " + letter);
				return acc;
			}
			const charGeom = this.createCharGeometry(charRegionData.x, charRegionData.y, charRegionData.width, charRegionData.height);
			charGeom.translate(acc + charRegionData.width / 2 * this.scaleGr, 0, 0);
			geo.merge(charGeom);
			return acc + charRegionData.width * this.scaleGr;
		}, 0);
		
		this.geometry = geo;
		
		geo.translate(this._width * -.5, 0, 0);
		
		const parameters = {map: this.tp.fontAtlasTexture} as MeshLambertMaterialParameters;
		parameters.color = this.action.color ?? 0xCCCCCC;
		this.material = new MeshLambertMaterial(parameters);
		
		this.material.transparent = true;
		// this.material.depthTest = true;
		// this.material.depthWrite = true;
		this.renderOrder = 998; // renderOrder is used to fix transparency artefacts
		// this.material.needsUpdate = true;
	}
	
	private createCharGeometry(x, y, w, h): Geometry {
		const g = new THREE.PlaneGeometry(w * this.scaleGr, h * this.scaleGr, 1, 1);
		for (const layer of g.faceVertexUvs) {
			for (const face of layer) {
				for (const uv/*: Vector2*/ of face) {
					uv.x *= w / this.textureSize;
					uv.y *= h / this.textureSize;
					uv.x += (x) / this.textureSize;
					uv.y += (this.textureSize - h - y) / this.textureSize;
				}
			}
		}
		return g;
	}
	
	public dispose() {
	
	}
	
}
