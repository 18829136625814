import {OnlineGameDTO} from "../dto/OnlineGameDTO";
import {GameStreamingInfoDTO} from "../dto/GameStreamingInfoDTO";
import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";
import {GameMode} from "../../game/enums/GameMode";
import {GameType} from "../../game/enums/GameType";


export class GameListService extends AbstractAMFService {
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.GameStreamingInfo", GameStreamingInfoDTO);
	}
	
	public getGames(sessionKey: string, scope: GameMode, gameTypeId: GameType, roomId: number = 0, isAutoRefresh: boolean = false)
		: Promise<Array<OnlineGameDTO>> {
		return this.send(GameListServices.GetGames,
			[sessionKey, scope, gameTypeId, roomId, isAutoRefresh]);
	}
	
	public getWaitingGames(sessionKey: string, scope: GameMode, gameTypeId: GameType, roomId: number): Promise<Array<OnlineGameDTO>> {
		return this.send(GameListServices.GetWaitingGames,
			[sessionKey, scope, gameTypeId, roomId]);
	}
	
	public getMyGames(sessionKey: string)
		: Promise<Array<OnlineGameDTO>> {
		return this.send(GameListServices.GetMyGames,
			[sessionKey]);
	}
	
	public getGameForStreaming(onlyGameDetails: boolean = false): Promise<GameStreamingInfoDTO> {
		return this.send(GameListServices.GetGameForStreaming,
			[]);
	}
	
	public getGameForStreamingDebug(): Promise<GameStreamingInfoDTO> {
		return this.send(GameListServices.GetGameForStreamingDebug,
			[]);
	}
	
}

export class GameListServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.GameList";
	public static readonly GetGames = new ServiceDescriptor(GameListServices.NS, "GetGames");
	public static readonly GetWaitingGames = new ServiceDescriptor(GameListServices.NS, "GetWaitingGames");
	public static readonly GetMyGames = new ServiceDescriptor(GameListServices.NS, "GetMyGames");
	public static readonly GetTotalPoints = new ServiceDescriptor(GameListServices.NS, "GetTotalPoints");
	public static readonly GetGameForStreaming = new ServiceDescriptor(GameListServices.NS, "GetGameForStreaming");
	public static readonly GetGameForStreamingDebug = new ServiceDescriptor(GameListServices.NS, "GetGameForStreamingDebug");
}
