import {Component, EventEmitter, Input, Output} from "@angular/core";
import {animate, keyframes, query, stagger, state, style, transition, trigger} from "@angular/animations";

export type FabAction = { id: any, icon: string, label?: string, data?: any };

@Component({
	// tslint:disable-next-line:component-selector
	selector: "fab-menu",
	template: `
       <div id="fab-dismiss" class="hasMouse"
            *ngIf="fabTogglerState==='active'"
            (click)="onToggleFab()">
       </div>
       <div class="hasMouse"
            [ngClass]="(direction==='down')?'fab-container-down':'fab-container-up'"
       >
           <button mat-fab
                   [@fabToggler]="{value: fabTogglerState}"
                   (click)="onToggleFab()"
                   color="secondary" class="fab-toggler">
               <i class="material-icons">settings</i>
           </button>
           <div [@speedDialStagger]="buttons.length">
               <button *ngFor="let btn of buttons"
                       matTooltip="{{btn.label}}"
                       mat-mini-fab
                       class="fab-secondary"
                       color="secondary"
                       (click)="onButtonClick(btn)">
                   <i class="material-icons">{{btn.icon}}</i>
               </button>
           </div>
       </div>`,
	styleUrls: ["fab-menu.component.scss"],
	animations: [
		trigger("fabToggler", [
			state("inactive", style({
				transform: "rotate(0deg)"
			})),
			state("active", style({
				transform: "rotate(30deg)"
			})),
			transition("* <=> *", animate("200ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
		]),
		trigger("speedDialStagger", [
			transition("* => *", [
				
				query(":enter", style({opacity: 0}), {optional: true}),
				
				query(":enter", stagger("40ms",
					[
						animate("200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
							keyframes(
								[
									style({opacity: 0, transform: "translateY(10px)"}),
									style({opacity: 1, transform: "translateY(0)"}),
								]
							)
						)
					]
				), {optional: true}),
				
				query(":leave",
					animate("200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
						keyframes([
							style({opacity: 1}),
							style({opacity: 0}),
						])
					), {optional: true}
				)
			
			])
		])
	],
})
export class FabMenuComponent {
	
	private fabButtons: FabAction[] = [
		// {id: 0, icon: "share"},
		// {id: 1, icon: "sort"},
		// {id: 3, icon: "exit_to_app"},
		// {id: 4, icon: "lightbulb_outline"},
		{id: 6, icon: "lock"}
	];
	buttons = [];
	fabTogglerState = "inactive";
	
	
	@Input()
	public direction: "up" | "down";
	
	@Input("actions")
	public set setActions(actions: FabAction[]) {
		this.fabButtons = actions;
	}
	
	@Output()
	action = new EventEmitter<FabAction>();
	
	constructor() {
	
	}
	
	showItems() {
		this.fabTogglerState = "active";
		this.buttons = this.fabButtons;
	}
	
	hideItems() {
		this.fabTogglerState = "inactive";
		this.buttons = [];
	}
	
	onToggleFab() {
		this.buttons.length ? this.hideItems() : this.showItems();
	}
	
	onButtonClick(a: FabAction) {
		this.hideItems();
		this.action.emit(a);
	}
}

