import {OnlineGameDTO} from "../dto/OnlineGameDTO";
import {GameStateDTO} from "../dto/GameStateDTO";
import {GameService} from "./GameService";
import {GameMessagesDTO} from "../dto/GameMessagesDTO";
import {GameSnapshotDTO} from "../dto/GameSnapshotDTO";
import {TileDescrDTO} from "../dto/TileDescrDTO";
import {TileDescrState} from "../../game/enums/TileDescrState";
import {FakeGameHelper, GAMES} from "../../game/helpers/FakeGameHelper";
import {GameIndexDTO} from "../dto/GameIndexDTO";

/*
const fakeGameSettings = () => {
	const dto = new OnlineGameDTO();
	dto.GameId = 88;
	dto.RoomId = 55;
	dto.StateId = GameState.PLAYING;
	return dto;
};
const fakeGameState = () => {
	const dto = new GameStateDTO();
	dto.GameUsers = [
		createFakeUser(3001, JoinedUserType.PLAYER, Side.South),
		createFakeUser(3002, JoinedUserType.PLAYER, Side.East),
		createFakeUser(3003, JoinedUserType.PLAYER, Side.North),
		createFakeUser(3004, JoinedUserType.PLAYER, Side.West),
	];
	dto.Settings = fakeGameSettings();
	dto.StateId = dto.Settings.StateId;
	return dto;
};
const createFakeUser = (id: number, status: JoinedUserType, side: Side): GameUserInfoDTO => {
	const user = new GameUserInfoDTO();
	user.UserId = id;
	user.Name = "User " + user.UserId;
	user.StateId = status;
	user.Side = side;
	return user;
};
*/

/*
const getFakeGameMessages = (userId) => {
	return [
		[],
		[
			createFakeGameMessage(userId, MoveType.FROM_WALL_TO_CONCEALED, "211^212^213^214"),
			createFakeGameMessage(userId, MoveType.END_TAKE_BLOCK, ""),
			createFakeGameMessage(userId, MoveType.FROM_WALL_TO_MELDED, "531"),
		
		],
		[
			createFakeGameMessage(userId, MoveType.FROM_CONCEALED_TO_SLOT, "13"),
			createFakeGameMessage(userId, MoveType.FROM_SLOT_TO_DISCARDS, "13"),
		],
		[
			createFakeGameMessage(userId, MoveType.USER_CAN_MAKE_MOVE, "pass|pung"),
		],
		/!*[
			createFakeGameMessage(3003, MoveType.USER_CAN_MAKE_MOVE, "pass|pung")
		],*!/
		//[]
	]
		;
};
const createFakeGameMessage = (userId: number, type: MoveType, message: string): GameMessageDTO => {
	const dto = new GameMessageDTO();
	dto.Id = fakeGamePeek++;
	dto.GameId = 123;
	dto.UserId = userId;
	// dto.PostTime = ;
	dto.Type = type;
	dto.Message = message;
	return dto;
};
*/

let gamReturnBatch: number;
let fakeGamePeek = 0;
/*const gamFakeMessages = getFakeGameMessages(3001)
	.concat([[createFakeGameMessage(3003, MoveType.USER_CAN_MAKE_MOVE, "pass|chow1|pung")]]);*/

const gamFakeMessages = FakeGameHelper.getFakeGameMessages(FakeGameHelper.getGameSettings(GAMES.HK).SouthId);

export class FakeGameService extends GameService {
	// private NS = "Mahjong.GameServer.Web.FluorineService.Game.";
	
	public async gam(sessionKey: string, roomId: number, gameId: number, gamePeek: number, chatId: number, chatPeek: number): Promise<GameMessagesDTO> {
		console.log(`GAM: gamReturnBatch=${gamReturnBatch} len=${gamFakeMessages.length}`);
		const dto = new GameMessagesDTO();
		dto.PublicChat = [];
		dto.PrivateChat = [];
		dto.Game = gamReturnBatch < gamFakeMessages.length ? gamFakeMessages[gamReturnBatch] : [];
		gamReturnBatch++;
		return dto;
	}
	
	public async join(sessionKey: string, roomId: number, gameId: number): Promise<OnlineGameDTO> {
		gamReturnBatch = 0;
		return FakeGameHelper.getGameSettings(gameId);
	}
	
	public async getGameState(sessionKey: string, roomId: number, gameId: number, withSettings: boolean, rType: number = 0): Promise<GameStateDTO> {
		return FakeGameHelper.getGameState(gameId);
	}
	
	public async getSettings(sessionKey: string, roomId: number, gameId: number): Promise<OnlineGameDTO> {
		return FakeGameHelper.getGameSettings(gameId);
	}
	
	public async playAgain(sessionKey: string, roomId: number, gameId: number): Promise<GameIndexDTO> {
		const fakeGame = FakeGameHelper.getGameSettings(gameId);
		return {GameId: fakeGame.GameId, RoomId: fakeGame.RoomId};
	}
	
	public async leave(sessionKey: string, roomId: number, gameId: number): Promise<boolean> {
		return true;
	}
	
	public async gameSnapshot(sessionKey: string, roomId: number, gameId: number): Promise<GameSnapshotDTO> {
		const gameSnapshot = new GameSnapshotDTO();
		const createTile = (userId: number, tileId: number): TileDescrDTO => {
			const dto = new TileDescrDTO();
			dto.UserId = userId;
			dto.TileId = tileId;
			dto.State = TileDescrState.MELDED;
			return dto;
		};
		gameSnapshot.PlayersTile = [];
		// gameSnapshot.PlayersTile = [createTile(3001, 41)];
		return gameSnapshot;
	}
	
}

