import {Entity} from "ecsy";
import {
	InHandIndexComponent,
	PlayerIdComponent,
	TileDataComponent,
	TileNodesComponent,
	TileStoreComponent,
	TileTypeComponent
} from "../common/components";
import {TileType} from "../../enums/TileType";

export class TileWrapper {
	private _nodes: Nodes;
	
	constructor(public entity: Entity) {
	}
	
	public static wrap(entity): TileWrapper {
		return entity ? new TileWrapper(entity) : undefined;
	}
	
	public get nodes(): Nodes {
		if (!this._nodes) {
			this._nodes = new Nodes(this.entity);
		}
		return this._nodes;
	}
	
	public get id(): number {
		return this.entity.getComponent(TileDataComponent).fullId;
	}
	
	public set id(id) {
		this.entity.getMutableComponent(TileDataComponent).fullId = id;
	}
	
	public get tinyId(): number {
		return this.entity.getComponent(TileDataComponent).tinyId;
	}
	
	public get type(): TileType {
		return this.entity.getComponent(TileTypeComponent).value;
	}
	
	public set type(value) {
		this.entity.getMutableComponent(TileTypeComponent).value = value;
	}
	
	public get playerId(): number {
		return this.entity.getComponent(PlayerIdComponent).playerId;
	}
	
	public set playerId(value) {
		this.entity.getMutableComponent(PlayerIdComponent).playerId = value;
	}
	
	public get meldedGroup(): number {
		return this.entity.getComponent(TileStoreComponent).meldedGroup;
	}
	
	public set meldedGroup(value) {
		this.entity.getMutableComponent(TileStoreComponent).meldedGroup = value;
	}
	
	public get inHandIndex(): number {
		return this.entity.getComponent(InHandIndexComponent).inHandIndex;
	}
	
	public set inHandIndex(value) {
		this.entity.getMutableComponent(InHandIndexComponent).inHandIndex = value;
	}
	
	public toString() {
		return `TileWrapper {entityId=${this.entity.id}, id=${this.id}, tinyId=${this.tinyId}, type=${TileType[this.type]}, playerId=${this.playerId}, meldedGroup=${this.meldedGroup}, nodes=${this.nodes}}`;
	}
}


class Nodes {
	constructor(private entity: Entity) {
	}
	
	private get options() {
		return this.entity.getComponent(TileNodesComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(TileNodesComponent);
	}
	
	public update(previous?: Entity, next?: Entity): void {
		const mOpts = this.mutableOptions;
		if (previous !== undefined) {
			mOpts.previous = previous;
		}
		if (next !== undefined) {
			mOpts.next = next;
		}
	}
	
	public get next(): Entity {
		return this.options.next;
	}
	
	public set next(value) {
		this.mutableOptions.next = value;
	}
	
	public get previous(): Entity {
		return this.options.previous;
	}
	
	public set previous(value) {
		this.mutableOptions.previous = value;
	}
	
	public toString() {
		return `{previousEntityId=${this.previous?.id}, nextEntityId=${this.next?.id}}`;
	}
}
