import {Injectable} from "@angular/core";
import {TournamentCategory} from "../../game/enums/TournamentCategory";
import {CurrencyType} from "../../game/enums/CurrencyType";
import {TournamentDTO} from "../../net/dto/tour/TournamentDTO";
import container from "../../inversify/inversify.config";
import {TournamentListService} from "../../net/service/TournamentListService";
import {TYPES} from "../../inversify/inversify.types";
import {NavigationService} from "./navigation.service";
import {UserStore} from "../../store/UserStore";

@Injectable({
	providedIn: "root"
})
export class UITourService {
	
	constructor(private navService: NavigationService) {
	}
	
	
	async getOfficialList(): Promise<TournamentDTO[]> {
		try {
			const tourList = await this.tournamentListService.getPlaying(this.userStore.sessionKey, TournamentCategory.Scheduled);
			const filteredList = tourList?.filter(tour => tour.GameTypeId === this.navService.routerGameType
				&& tour.EntryFeeCurrencyId === CurrencyType.Usd
				&& tour.RegStartSecLeft === 0 // exclude tours with registration in future
			);
			return filteredList ?? [];
		}
		catch (e) {
			console.warn("GamesComponent.: error getting tour list");
		}
		return [];
	}
	
	/*async OfficialUnregister(RefOfficialTourId: number) {
		const res = await this.tournamentService.OfficialRegister(this.userStore.sessionKey, tour.RefOfficialTourId);
	}*/
	
	public get userStore(): UserStore {
		return container.get<UserStore>(TYPES.UserStore);
	}
	
	private get tournamentListService() {
		return container.get<TournamentListService>(TYPES.TournamentListService);
	}
	
	private get tournamentService() {
		return container.get<UITourService>(TYPES.TournamentService);
	}
	
}
