import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, Renderer2} from "@angular/core";
import {Router} from "@angular/router";
import {LoginService} from "../../../net/service/LoginService";
import container from "../../../inversify/inversify.config";
import {TYPES} from "../../../inversify/inversify.types";
import {LoginAppType} from "../../../game/enums/LoginAppType";
import {ILoginService} from "../../../net/service/ILoginService";
import {UserStore} from "../../../store/UserStore";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {commander} from "../../../commander/Commander";
import {Commands} from "../../../commands/Commands";
import {UserService} from "../../../net/service/UserService";
import {AppStore} from "../../../store/AppStore";
import {Locale} from "../../../game/enums/Locale";
import {AppEventsPipe, AppEventType, IAppEventNavigateTo} from "../../../game/AppEventsPipe";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import * as md5 from "md5";
import {v4 as uuidv4, validate as uuidValidate} from "uuid";
import {Language} from "../../../game/enums/Language";
import {AppEventsService} from "../../services/AppEventsService";
import {ServiceError} from "../../../net/ServiceError";
import {TranslateService} from "@ngx-translate/core";
import {NavPage} from "../../enums/NavPage";
import {NavigationService} from "../../services/navigation.service";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
	
	private userStore: UserStore;
	private appStore: AppStore;
	public loading = false;
	public guestLoginAllowed = true;
	private initialViewportHeight: number = window.innerHeight;
	
	availableCountries: ICountry[] =
		[
			{locale: Locale.EN, alpha2: "us"} as ICountry,
			{locale: Locale.FR, alpha2: "fr"} as ICountry,
			{locale: Locale.ES, alpha2: "es"} as ICountry,
			{locale: Locale.JP, alpha2: "jp"} as ICountry,
			{locale: Locale.CN, alpha2: "cn"} as ICountry,
			{locale: Locale.RU, alpha2: "ru"} as ICountry,
		];
	
	signInForm: FormGroup = new FormGroup({
		name: new FormControl("", [Validators.required]),
		password: new FormControl("", [Validators.required]),
	});
	signUpForm: FormGroup = new FormGroup({
		name: new FormControl("", [Validators.required]),
		email: new FormControl("", [Validators.required, Validators.email]),
		password: new FormControl("", [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
	});

	isMobileLayout: boolean = false;

	constructor(
		private router: Router, private _snackBar: MatSnackBar, private http: HttpClient,
		public appEventsService: AppEventsService,
		private translate: TranslateService,
		private renderer: Renderer2,
		private changeDetectorRef: ChangeDetectorRef,
		private navigationService: NavigationService
	) {
		this.userStore = container.get<UserStore>(TYPES.UserStore);
		this.appStore = container.get<AppStore>(TYPES.AppStore);
		const {name, password}: { name: string, password: string; } = this.loadCredentials();
		this.signInForm.get("name").setValue(name);
		this.signInForm.get("password").setValue(password);
	}
	
	ngOnInit(): void {
	
	}
	
	async doLogin() {
		console.log("LoginComponent.doLogin: ");
		this.loading = true;
		const username = this.signInForm.get("name").value;
		const password = this.signInForm.get("password").value;
		try {
			this.saveCredentials(username, password);
			const result = await this.loginService.login(username, password, LoginAppType.LITE_WEB);
			await this.onLoginSuccessful(result.UserId, result.SessionKey);
		}
		catch (e) {
			this.loading = false;
			if (e instanceof ServiceError && (e as ServiceError).id > 0) {
				const errorMessage = this.translate.instant("Error." + e.id);
				// this.openSnackBar(`[${e.id}] ${errorMessage}`, "");
			}
			else {
				this.openSnackBar("Cannot login", "");
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	onWindowResize(event: Event) {
		// This should be called whenever the window is resized
		console.log('Window resize event triggered');
		this.handleResize();
	}

	private handleResize() {
		const currentViewportHeight = window.innerHeight;
		console.log(`Current viewport height: ${currentViewportHeight}`);
		// Logic to determine if the keyboard was closed based on height comparison

		// Update the initialViewportHeight with the current height for future comparisons
		this.initialViewportHeight = currentViewportHeight;
		this.updateViewportScale(1);
	}

	private updateViewportScale(scale: number): void {
		const viewportMetaTag = this.renderer.selectRootElement('meta[name="viewport"]', true);
		this.renderer.setAttribute(
			viewportMetaTag,
			'content',
			`width=device-width, initial-scale=${scale}, maximum-scale=${scale}, user-scalable=no`
		);

		// Log the updated scale value to the console
		console.log(`Viewport scale updated to: ${scale}`);
	}
	
	async doGuestLogin(): Promise<void> {
		console.log("LoginComponent.doGuestLogin: ");
		this.loading = true;
		try {
			// retrieve existing valid uid from localStorage or create a new one
			let storedUid = localStorage.getItem("uid") ?? "";
			if (!uuidValidate(storedUid)) {
				storedUid = uuidv4();
			}
			const guestLoginResult = await this.loginAsGuest(storedUid);
			if (guestLoginResult.successful) {
				localStorage.setItem("uid", storedUid);
				// pass 0 instead of userId as guestLoginResult.userId is a string (uid passed in LoginAsGuest)
				await this.onLoginSuccessful(0, guestLoginResult.result.sessionId);
				return; // exit . do not set loading to false
			}
			else {
				console.warn("LoginComponent.doGuestLogin: error: ", guestLoginResult.error);
				this.openSnackBar(guestLoginResult.error);
			}
		}
		catch (e) {
			console.error("LoginComponent.login as guest error: " + e);
			this.openSnackBar("Something went wrong");
		}
		this.loading = false;
	}
	
	async doSignUp(): Promise<void> {
		console.log("LoginComponent.doSignUp: ");
		this.loading = true;
		const form = this.signUpForm;
		const username = form.get("name").value;
		const password = form.get("password").value;
		const email = form.get("email").value;
		try {
			const registerRes = await this.registerUser({name: username, password, email});
			if (registerRes.successful) {
				this.saveCredentials(username, password);
				await this.onLoginSuccessful(0, registerRes.result.sessionId);
				return; // exit . do not set loading to false
			}
			else {
				this.loading = false;
				this.openSnackBar(registerRes.error);
				// throw registerRes.error;
			}
		}
		catch (e) {
			console.error("LoginComponent.doSignUp: something went wrong: " + e);
			this.openSnackBar("Something went wrong");
		}
		this.loading = false;
	}
	
	async onLoginSuccessful(userId: number, sessionKey: string): Promise<void> {
		this.userStore.setUserData(userId, sessionKey);
		console.log("LoginComponent.onLoginSuccessful: start");
		const cmdRes = await commander.executeCommand(Commands.LOGGED_IN);
		
		const ar = this.router.routerState.root;
		const returnPath = ar.snapshot.queryParams.ret;
		console.log("LoginComponent.onLoginSuccessful: returnPath=" + returnPath);
		
		if (returnPath) {
			// this.router.navigateByUrl(returnPath);
			this.navigationService.navigateByUrl(returnPath);
		}
		else {
			this.appEventsService.send(AppEventType.NavigateTo, {route: NavPage.Lobby} as IAppEventNavigateTo);
		}
	}
	
	private async loginAsGuest(uid: string): Promise<IQuickLoginResult<IUserResponse>> {
		const gender = "female";
		const qString = "uid=" + uid + "&gender=" + gender;
		try {
			const res = await this.requestQuickLogin<IUserResponse>(qString);
			if (!res.successful) {
				console.warn("LoginComponent.loginAsGuest: error -- " + res.error);
				return {successful: false, error: res.error};
			}
			return res;
		}
		catch (e) {
			console.warn("LoginComponent.error signing in as guest: ", e);
			return {successful: false, error: "Something went wrong: " + e};
		}
	}
	
	private async registerUser(
		{name, password, gender = "female", email, language = Language.ENGLISH, partnerId}:
			{ name: string, password: string, gender?: string, email: string, language?: Language, partnerId?: number }
	): Promise<IQuickLoginResult<IUserResponse>> {
		
		partnerId ??= this.getPartnerIdFromCookies() ?? 1;
		const qString = "username=" + name + "&password=" + password + "&gender=" + gender + "&email=" + email + "&lang=" + language + "&partnerId=" + partnerId;
		try {
			const res = await this.requestQuickLogin<IUserResponse>(qString);
			if (!res.successful) {
				console.warn("LoginComponent.registerUser: error -- " + res.error);
				return {successful: false, error: res.error};
			}
			
			/**
			 * Regular flow.
			 * Problem: Server do not return Siamese games (gametype=31) right after registering user. Relogin needed to get AS games.
			 */
			const sessionId = res.result.sessionId;
			this.userStore.setUserData(0, sessionId);
			const info = await this.userService.getInfo(sessionId);
			await this.onLoginSuccessful(info.UserId, sessionId);
			
			// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
			
			/**
			 * Regular flow.
			 * Problem: Server do not return Siamese games (gametype=31) right after registering user. Relogin needed to get AS games.
			 */
			/*userProxy.sessionKey = data.sessionId;
				userProxy.getInfo(userProxy.sessionKey);*/
			/**
			 * Workaround: additional login after a new user registation
			 */
			/*SLog.debug(this, "registerUserSuccess(): call logout and delayedLogin in 1 sec");
			userProxy.logout(userProxy.sessionKey);
			var r:LoginLVO = result.requestData as LoginLVO;
			TweenLite.killDelayedCallsTo(delayedLogin);
			TweenLite.delayedCall(1, delayedLogin, [r]);*/
			/**
			 * end workaround
			 */
			
			
			/**
			 * Workaround: additional login after a new user registation
			 */
			/*SLog.debug(this, "delayedLogin(): Login user");
			doLogin(r.name, r.password, false);*/
			/**
			 * end workaround
			 */
			return res;
		}
		catch (e) {
			console.warn("LoginComponent.registerUser: error -- ", e);
			return {successful: false, error: "Unknown error at registerUser()"};
		}
	}
	
	private async requestQuickLogin<T extends IQuickLoginResponse>(qString: string): Promise<IQuickLoginResult<T>> {
		try {
			const hash = md5((qString + "qwerty123").toUpperCase());
			const url = environment.webRoot + `/QuickLogin/Mobile.aspx?${qString}&sig=${hash}`;
			const res = await this.http.get<T>(url, {responseType: "json"}).toPromise();
			if (res.isError) {
				// {"isError":true,"userId":null,"sessionId":"","error":"Invalid E-Mail"}
				throw new Error(res.error);
			}
			return {successful: true, result: res};
		}
		catch (e) {
			return {successful: false, error: e};
		}
	}
	
	openSnackBar(message: string, action: string = "") {
		this._snackBar.open(message, action, {
			duration: 2000,
		});
	}
	
	saveCredentials(name: string, password: string): void {
		localStorage.setItem("name", name);
		if (environment.isDebug) {
			localStorage.setItem("password", password);
		}
	}
	
	loadCredentials(): { name: string, password: string } {
		const name = localStorage.getItem("name") ?? "";
		const password = localStorage.getItem("password") ?? "";
		return {name, password};
	}
	
	langMenuClose(country: ICountry) {
		console.log("LoginComponent.langMenuClose: " + country);
		this.sendAppEvent(AppEventType.SwitchLanguage, {language: country.locale});
	}
	
	sendAppEvent(type: AppEventType, data: any) {
		const appEventsPipe = container.get<AppEventsPipe>(TYPES.AppEventsPipe);
		appEventsPipe.send(type, data);
	}
	
	get loginService(): ILoginService {
		return container.get<LoginService>(TYPES.LoginService);
	}
	
	get userService(): UserService {
		return container.get<UserService>(TYPES.UserService);
	}
	
	switchFullscreen() {
		this.appEventsService.send(AppEventType.SwitchFullscreen);
	}
	
	private getPartnerIdFromCookies() {
		const pid = document.cookie
			.split("; ")
			.find(row => row.startsWith("partnerId"))
			?.split("=")[1];
		return pid ? +pid : undefined;
	}
}

export interface ICountry {
	locale: string;
	alpha2: string;
}


interface IQuickLoginResponse {
	isError: boolean;
	error: string;
}

interface IUserResponse extends IQuickLoginResponse {
	sessionId: string;
}

interface IQuickLoginResult<T> {
	successful: boolean;
	error?: string;
	result?: T;
}
