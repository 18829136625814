import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TYPES } from "../../../../inversify/inversify.types";
import container from "../../../../inversify/inversify.config";
import { UserStore } from "../../../../store/UserStore";
import { NavigationService } from "../../../services/navigation.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AppStore } from "../../../../store/AppStore";
import { GameService, ICreateGameOpts } from "../../../../net/service/GameService";
import { RulesHelperHK } from "../../../../game/ecs/hk/RulesHelperHK";
import { ICreateFormLimits } from "./i-create-game";
import { AppEventsService } from "../../../services/AppEventsService";
import { AppEventType } from "../../../../game/AppEventsPipe";
import { NavPage } from "../../../enums/NavPage";
import { GameURL } from "../../../../utils/GameURL";
import { GameType } from "../../../../game/enums/GameType";
import { RulesHelperJM } from "../../../../game/ecs/jm/RulesHelperJM";
import { RulesHelperTW } from "../../../../game/ecs/tw/RulesHelperTW";
import { RulesHelperEC } from "../../../../game/ecs/ec/RulesHelperEC";
import { RulesHelperCO } from "../../../../game/ecs/co/RulesHelperCO";
import { RulesHelperWP } from "../../../../game/ecs/wp/RulesHelperWP";
import { environment } from "../../../../environments/environment";
import { BeltType } from "../../../../game/enums/BeltType";
import { Subject } from "rxjs";

@Component({
	selector: "app-create-private",
	templateUrl: "./create-private.component.html",
	styleUrls: ["./create-private.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePrivateComponent implements OnInit, OnDestroy {
	private readonly gameType: GameType;

	private userStore: UserStore;
	private appStore: AppStore;
	public loading = false;

	createGameForm: FormGroup = new FormGroup({
		name: new FormControl(""),
		roundsCount: new FormControl(""),
		timeLimit: new FormControl(""),
		declarePoints: new FormControl(""),
		forFriends: new FormControl(""),
	});
	isDebug = environment.isDebug;
	private destroy$: Subject<boolean> = new Subject<boolean>();

	@Input()
	currentValues: ICreateGameOpts;
	@Input()
	createLimits: ICreateFormLimits;

	constructor(
		public appEventsService: AppEventsService,
		private translate: TranslateService,
		private navigationService: NavigationService
	) {
		this.userStore = container.get<UserStore>(TYPES.UserStore);
		this.appStore = container.get<AppStore>(TYPES.AppStore);

		this.gameType = navigationService.routerGameType;
		this.currentValues = this.getDefaultGameSettings(this.gameType);
		// + generate random name for the game
		this.currentValues.name = "PG-" + (Math.random().toString(32).replace(/[^a-z]+/g, "").substr(0, 10));
	}

	ngOnInit(): void {
		this.updateFormLimits();
		this.setFormValues(this.currentValues);

		this.createGameForm.controls.name.valueChanges.subscribe(value => this.currentValues.name = value);
		this.createGameForm.controls.declarePoints.valueChanges.subscribe(value => this.currentValues.declarePoints = value);
		this.createGameForm.controls.forFriends.valueChanges.subscribe(value => this.currentValues.forFriends = value);
		this.createGameForm.controls.roundsCount.valueChanges.subscribe(value => this.currentValues.roundsCount = value);
		this.createGameForm.controls.roundsCount.valueChanges.subscribe(() => this.onRoundCountChanged());
		this.createGameForm.controls.timeLimit.valueChanges.subscribe(value => this.currentValues.timeLimit = value);
	}

	ngOnDestroy(): void {
		try {
			this.destroy$.next(true);
			this.destroy$.complete();
		}
		catch (e) {
			console.warn("CreatePrivateComponent.ngOnDestroy: " + e);
		}
	}

	doSubmit() {
		this.loading = true;
		const cv = ({ ...this.currentValues });
		// check if the name starts with 'PG-' prefix and fix it otherwise.
		if (cv.name.substring(0, 3) !== "PG-") {
			cv.name = ("PG-" + cv.name).substr(0, 15);
		}
		this.gameService.create2(this.userStore.sessionKey, cv)
			.then(value => {
				const gtId = this.navigationService.routerGameType;
				console.log(`CreatePrivateComponent.game created: id=${value.GameId} room=${value.RoomId} of rules=${gtId}`);
				const game = GameURL.encode({ roomId: value.RoomId, gameId: value.GameId, gameTypeId: gtId });
				this.navigationService.navigate({ page: NavPage.EnterGame, extras: game });
			})
			.catch(err => {
				this.appEventsService.send(AppEventType.ShowSnack, { message: err });
				this.loading = false;

			});
	}

	onClose() {
		this.navigationService.navigate({ page: NavPage.Games });
	}

	private setFormValues(values) {
		Object.keys(values).forEach(key => {
			try {
				this.createGameForm.get(key).setValue(values[key]);
			}
			catch (e) {
				console.warn(`CreatePrivateComponent.no form field: ${key} -- ` + e);
			}
		});
	}

	private updateFormLimits() {
		this.createLimits = this.getCreateGameLimits(this.gameType, { rounds: this.currentValues.roundsCount });
		this.createLimits.roundsCount = [
			{ data: 0, label: "One Game" },
			// add more rounds as needed
		];
		Object.keys(this.createLimits).forEach(key => {
			this.addValidators(key, this.createLimits);
		});
	}

	private addValidators(field: string, settings): void {
		const v = [Validators.required];
		if (settings[field]?.min) {
			v.push(Validators.min(settings[field].min));
		}
		if (settings[field]?.max) {
			v.push(Validators.max(settings[field].max));
		}
		if (settings[field]?.maxLength) {
			v.push(Validators.maxLength(settings[field].maxLength));
		}
		this.createGameForm.get(field)?.setValidators(v);
	}

	private verifyGameSettings() {
		const values = this.currentValues;
		const limits = this.createLimits;
		let hasChanges = false;
		Object.keys(this.createLimits).forEach(field => {
			if (limits[field]?.min && values[field] < limits[field].min) {
				values[field] = limits[field].min;
				hasChanges = true;
			}
			if (limits[field]?.max && values[field] > limits[field].max) {
				values[field] = limits[field].max;
				hasChanges = true;
			}
		});
		if (hasChanges) {
			this.currentValues = { ...this.currentValues }; // force update input property if there are any changes
		}
	}

	private onRoundCountChanged() {
		try {
			this.updateFormLimits();
			this.verifyGameSettings();
		}
		catch (e) {
			console.error("CreatePrivateComponent.onRoundCountChanged: " + e);
		}
	}

	private getDefaultGameSettings(gameType: GameType) {
		switch (gameType) {
			case GameType.HK:
				return RulesHelperHK.defaultGameSettings;
			case GameType.CO:
				return RulesHelperCO.defaultGameSettings;
			case GameType.WP:
				return RulesHelperWP.defaultGameSettings;
			case GameType.EC:
				return RulesHelperEC.defaultGameSettings;
			case GameType.RCR:
			case GameType.EMA:
			case GameType.SM:
			case GameType.WS:
				return RulesHelperJM.defaultGameSettings;
			case GameType.TW:
				return RulesHelperTW.defaultGameSettings;
		}
	}

	private getCreateGameLimits(gameType: GameType, opts: { rounds: number }) {
		switch (gameType) {
			case GameType.HK:
				return RulesHelperHK.getCreateLimits(opts);
			case GameType.CO:
				return RulesHelperCO.getCreateLimits(opts);
			case GameType.WP:
				return RulesHelperWP.getCreateLimits(opts);
			case GameType.EC:
				return RulesHelperEC.getCreateLimits(opts);
			case GameType.RCR:
			case GameType.EMA:
			case GameType.SM:
			case GameType.WS:
				return RulesHelperJM.getCreateLimits(opts);
			case GameType.TW:
				return RulesHelperTW.getCreateLimits(opts);
		}
	}

	private get gameService(): GameService {
		return container.get<GameService>(TYPES.GameService);
	}

	// for debug only
	get debugSettings() {
		const getValue = (field, value) => {
			switch (field) {
				case "gameTypeId":
					return value + ": " + ` (${GameType[value]})`;
				case "minBelt":
					return value + ` (${BeltType[value]})`;
				case "roundsCount":
					return value === 0 ? value + " (QM)" : value;
				default:
					return value;
			}
		};
		return Object.entries(this.currentValues).map(value => [value[0], getValue(value[0], value[1])]);
	}
}
