import {CurrencyType} from "../game/enums/CurrencyType";
import {UserAccountDTO} from "../net/dto/UserAccountDTO";


export const AccountUtil = {
	getBalance: (account: UserAccountDTO, currency: CurrencyType): number => {
		switch (currency) {
			case CurrencyType.Usd:
				return account.MoneyAmt;
			case CurrencyType.GoldenCoin:
				return account.Coins;
			case CurrencyType.DragonChip:
			case CurrencyType.NoCurrency:
			default:
				return account.Chips;
		}
	},
	verifyBalance: (account: UserAccountDTO, currency: CurrencyType, amount: number): { enough: boolean, amountRequired: number } => {
		const accountBalance = AccountUtil.getBalance(account, currency);
		return {enough: accountBalance >= amount, amountRequired: amount - accountBalance};
	},
	buyPackages: ((): BuyPackage[] => ([
		{
			type: "Chips", items: [
				{amount: 2000, price: 2},
				{amount: 5000, price: 5},
				{amount: 10000, price: 10},
				{amount: 50000, price: 50},
				{amount: 150000, price: 150},
				{amount: 250000, price: 250},
			]
		},
		{
			type: "Coins", items: [
				{amount: 200, price: 2},
				{amount: 500, price: 5},
				{amount: 1000, price: 10},
				{amount: 5000, price: 50},
				{amount: 10000, price: 100}]
		},
	]))()
};

// enum BuyPackageType {Chips = "Chips", Coins= };
type BuyPackageType = "Chips" | "Coins";
type BuyPackage = { type: BuyPackageType, items: BuyPackageItem[] };
type BuyPackageItem = { amount: number, price: number };

/*
export const getBalance = (account: UserAccountDTO, currency: CurrencyType): number => {
	switch (currency) {
		case CurrencyType.Usd:
			return account.MoneyAmt;
		case CurrencyType.GoldenCoin:
			return account.Coins;
		case CurrencyType.DragonChip:
		case CurrencyType.NoCurrency:
		default:
			return account.Chips;
	}
};

export const verifyBalance = (account: UserAccountDTO, currency: CurrencyType, amount: number): { enough: boolean, amountRequired: number } => {
	const accountBalance = getBalance(account, currency);
	return {enough: accountBalance >= amount, amountRequired: amount - accountBalance};
};*/
