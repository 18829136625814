import {InputRulesSystem} from "../common/InputRulesSystem";
import {Mesh, MeshLambertMaterial, Object3D} from "three";
import {ReadyBarWrapper} from "./components/ReadyBarWrapper";
import {IInputMouseEvent, InputMouseEventType} from "../../interfaces/InputStreamEvent";
import {ReadyBarTagComponent} from "./components/ReadyBarTagComponent";
import {ActionMoveType} from "../../enums/ActionMoveType";
import {TileDataComponent} from "../common/components";
import {Entity} from "ecsy";
import {TileEntityHelper} from "../../helpers/TileEntityHelper";

/**
 * Game Rules-specified Input handler system.
 */
export class InputRulesJMSystem extends InputRulesSystem {
	
	dispose(): void {
		super.dispose();
	}
	
	private declareReady() {
		this.netManager.makeActionMove({
			action: ActionMoveType.READY,
			tileId: 91,
			gameStorageW: this.gameStorageQ,
			playersArr: this.playersQRA
		})
			.catch(e => console.warn("InputRulesJMSystem.: error declaring ready: -- " + e));
	}
	
	
	//
	private isReadyBarGraphics(object: Object3D): boolean {
		return object && object.name === "ReadyBarGraphics";
	}
	
	private getReadyBarByGraphic(gObject: Object3D): ReadyBarWrapper {
		return ReadyBarWrapper.wrap(
			this.queries.readyBars.results.find(bar => ReadyBarWrapper.wrap(bar).graphicObject === gObject)
		);
	}
	
	protected onTileClick(tileData: TileDataComponent, playerId: number, tileEntity: Entity, point: { x: number; y: number }): void {
		if (TileEntityHelper.isLocked(tileEntity)) {
			console.log("InputRulesJMSystem.onTileClick: This tile is locked, choose another one");
			this.showSnack({message: "This tile is locked, choose another one"});
		}
		else {
			super.onTileClick(tileData, playerId, tileEntity, point);
		}
	}
	
	private setIsOver(bar: Mesh, state: boolean) {
		try {
			(bar.material as MeshLambertMaterial).color.setHex(state ? 0xCCFFCC : 0xffffff);
			(bar.material as MeshLambertMaterial).needsUpdate = true;
		}
		catch (e) {
			console.warn("InputRulesJMSystem.setIsOver: -- " + e);
		}
	}
	
	/*
	* Extension: added click handler for ReadyBars
	* */
	protected processMouseInputAction(input: IInputMouseEvent): void {
		super.processMouseInputAction(input);
		try {
			switch (input.type) {
				case InputMouseEventType.ROLL_OVER:
					if (input.target && this.isReadyBarGraphics(input.target)) {
						const bar = this.getReadyBarByGraphic(input.target);
						if (bar?.playerId === this.gameStorageQ.myPlayerId) {
							this.setIsOver(input.target as Mesh, true);
							document.body.style.cursor = "pointer";
						}
					}
					break;
				case InputMouseEventType.ROLL_OUT:
					if (input.target && this.isReadyBarGraphics(input.target)) {
						this.setIsOver(input.target as Mesh, false);
						document.body.style.cursor = "default";
					}
					break;
				case InputMouseEventType.CLICK:
					try {
						if (input.target && this.isReadyBarGraphics(input.target)) {
							const bar = this.getReadyBarByGraphic(input.target);
							if (bar?.playerId === this.gameStorageQ.myPlayerId) {
								this.declareReady();
							}
							else {
								console.warn("processMouseInputAction.ReadyBar: " + (bar ? `not my bar: ${bar.playerId}` : "cannot find corresponding entity"));
							}
						}
					}
					catch (e) {
						console.warn(`InputRulesCOSystem.processMouseInputAction: ${input.type} -- ` + e);
					}
					break;
			}
		}
		catch (e) {
			console.warn("InputRulesJMSystem.processMouseInputAction: -- " + e);
		}
	}
	
}

InputRulesJMSystem.queries.readyBars = {
	components: [ReadyBarTagComponent],
	listen: {
		added: true,
		removed: true,
		changed: false
	}
};

