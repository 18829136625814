import {Entity} from "ecsy";
import {
	DeadWallTagComponent,
	ForceOpenedTagComponent,
	GraphicsComponent,
	PlayerIdComponent,
	PrepareToThrowTagComponent,
	TileDataComponent,
	TileNodesComponent,
	TileTypeComponent
} from "../ecs/common/components";
import {TileType} from "../enums/TileType";
import {TileGraphics} from "../TileGraphics";
import {MarkedForCharlestonTag} from "../ecs/wp/components.wp";
import {TileWrapper} from "../ecs/wrappers/TileWrapper";
import {LockedTileTagComponent} from "../ecs/jm/components/LockedTileTagComponent";

export class TileEntityHelper {
	
	public static changeId(tile: Entity, fullId: number) {
		const tileData = tile.getComponent(TileDataComponent);
		if (tileData.fullId === fullId) {
			return;
		}
		tile.getMutableComponent(TileDataComponent).fullId = fullId;
		
		// const tileGraphics = tile.getComponent(GraphicsComponent).obj;
		// tileGraphics.setTileId(tile.getComponent(TileDataComponent).tinyId);
	}
	
	public static setPlayerId(tile: Entity, playerId: number) {
		const comp = tile.getComponent(PlayerIdComponent);
		if (comp.playerId === playerId) {
			return;
		}
		tile.getMutableComponent(PlayerIdComponent).playerId = playerId;
	}
	
	public static setTileType(tile: Entity, type: TileType) {
		const comp = tile.getComponent(TileTypeComponent);
		if (comp.value === type) {
			return;
		}
		tile.getMutableComponent(TileTypeComponent).value = type;
	}
	
	public static getTileDataByTileGraphics(tileG: TileGraphics, tileSet: Array<Entity>): { tileData: TileDataComponent, playerId: number, tileEntity: Entity } {
		const tileEntity = tileSet.find(te => {
			return tileG === te.getComponent(GraphicsComponent).obj;
		});
		if (tileEntity) {
			return {
				tileData: tileEntity.getComponent(TileDataComponent),
				playerId: tileEntity.getComponent(PlayerIdComponent).playerId,
				tileEntity
			};
		}
	}
	
	public static getTileEntityByTileGraphics(tileG: TileGraphics, tileSet: Array<Entity>): Entity {
		const tileEntity = tileSet.find(te => {
			return tileG === te.getComponent(GraphicsComponent).obj;
		});
		return tileEntity;
	}
	
	
	public static getTileEntityNotForceOpened({tileSet}: { tileSet: Array<Entity> }): Entity;
	public static getTileEntityNotForceOpened({tileId, tileSet}: { tileId: number, tileSet: Array<Entity> }): Entity;
	public static getTileEntityNotForceOpened({tileTinyId, tileSet}: { tileTinyId: number, tileSet: Array<Entity> }): Entity;
	public static getTileEntityNotForceOpened({tileId, tileTinyId, tileSet}: { tileId?: number, tileTinyId?: number, tileSet: Array<Entity> }): Entity {
		return tileSet.find(te => {
			return !te.hasComponent(ForceOpenedTagComponent)
				&& (!tileId || te.getComponent(TileDataComponent).fullId === tileId)
				&& (!tileTinyId || te.getComponent(TileDataComponent).tinyId === tileTinyId);
		});
	}
	
	public static linkPrevAndNextTileNodes(tileEntity: Entity): Entity {
		const nodes = tileEntity.getMutableComponent(TileNodesComponent);
		if (nodes) {
			if (nodes.previous) { // set previous' .next to current .next
				nodes.previous.getMutableComponent(TileNodesComponent).next = nodes.next;
			}
			if (nodes.next) {// set next's .previous to current .previous
				nodes.next.getMutableComponent(TileNodesComponent).previous = nodes.previous;
			}
			nodes.previous = null;
			nodes.next = null;
		}
		return tileEntity;
	}
	
	public static insertTileAfter(tile: Entity, afterTile: Entity) {
		if (!tile || !afterTile || afterTile === tile) {
			console.warn("TEH.insertTileAfter: both tile and afterTile are required and should not be the same. tile.id=" + tile.id + ", afterTile.id=" + afterTile.id);
			return;
		}
		
		if (afterTile.getComponent(TileNodesComponent).next === tile) {
			console.warn(`TEH.insertTileAfter: tile is in its place already`);
			return;
		}
		
		const afterTileNodes = afterTile.getMutableComponent(TileNodesComponent);
		const nextTile = afterTileNodes.next;
		if (nextTile) {
			console.log("TEH.insertTileAfter: tile  =" + TileEntityHelper.wrapEntity(tile));
			console.log("TEH.insertTileAfter: after =" + TileEntityHelper.wrapEntity(afterTile));
			console.log("TEH.insertTileAfter: next  =" + TileEntityHelper.wrapEntity(nextTile));
			const nextTileNodes = nextTile.getMutableComponent(TileNodesComponent);
			nextTileNodes.previous = tile;
		}
		else {
			console.log("TEH.insertTileAfter: tile  =" + TileEntityHelper.wrapEntity(tile));
			console.log("TEH.insertTileAfter: after =" + TileEntityHelper.wrapEntity(afterTile));
		}
		const tileNodes = tile.getMutableComponent(TileNodesComponent);
		tileNodes.previous = afterTile;
		tileNodes.next = afterTileNodes.next;
		afterTileNodes.next = tile;
		
	}
	
	public static insertTileBefore(tile: Entity, beforeTile: Entity) {
		if (!tile || !beforeTile) {
			return;
		}
		
		const tileNodes = tile.getMutableComponent(TileNodesComponent);
		const beforeTileNodes = beforeTile.getMutableComponent(TileNodesComponent);
		
		// Skip if beforeTile is same as tile OR tile is already placed before beforeTile
		if (tile === beforeTile || tileNodes.next === beforeTile) {
			return;
		}
		
		const prevTile = beforeTileNodes.previous;
		if (prevTile) {
			const nextTileNodes = prevTile.getMutableComponent(TileNodesComponent);
			nextTileNodes.next = tile;
		}
		
		tileNodes.previous = prevTile;
		tileNodes.next = beforeTile;
		beforeTileNodes.previous = tile;
		
	}
	
	public static toggleMarkForCharleston(tileEntity: Entity) {
		if (tileEntity.hasComponent(MarkedForCharlestonTag)) {
			tileEntity.removeComponent(MarkedForCharlestonTag);
		}
		else {
			tileEntity.addComponent(MarkedForCharlestonTag);
		}
	}
	
	public static setPrepareToThrow(tileEntity: Entity) {
		if (!tileEntity.hasComponent(PrepareToThrowTagComponent)) {
			tileEntity.addComponent(PrepareToThrowTagComponent);
		}
	}
	
	public static removePrepareToThrow(tileEntity: Entity) {
		if (tileEntity.hasComponent(PrepareToThrowTagComponent)) {
			tileEntity.removeComponent(PrepareToThrowTagComponent);
		}
	}
	
	public static setForceOpened(tileEntity: Entity) {
		if (!tileEntity.hasComponent(ForceOpenedTagComponent)) {
			tileEntity.addComponent(ForceOpenedTagComponent);
		}
	}
	
	public static removeForceOpened(tileEntity: Entity) {
		if (tileEntity.hasComponent(ForceOpenedTagComponent)) {
			tileEntity.removeComponent(ForceOpenedTagComponent);
		}
	}
	
	public static wrapEntity(tile: Entity): TileWrapper {
		return new TileWrapper(tile);
	}
	
	static linkTilesNodes(prevTile: Entity, nextTile: Entity) {
		const prevTileNodes = prevTile.getMutableComponent(TileNodesComponent);
		const nextTileNodes = nextTile.getMutableComponent(TileNodesComponent);
		prevTileNodes.next = nextTile;
		nextTileNodes.previous = prevTile;
	}
	
	static breakLinkedTilesNodes(prevTile: Entity, nextTile: Entity) {
		const prevTileNodes = prevTile.getMutableComponent(TileNodesComponent);
		const nextTileNodes = nextTile.getMutableComponent(TileNodesComponent);
		prevTileNodes.next = null;
		nextTileNodes.previous = null;
	}
	
	static isFirstTile(tile: Entity) {
		return !tile.getComponent(TileNodesComponent).previous;
	}
	
	static isLastTile(tile: Entity) {
		return !tile.getComponent(TileNodesComponent).next;
	}
	
	static getFullId(tile: Entity) {
		return tile.getComponent(TileDataComponent).fullId;
	}
	
	static getTinyId(tile: Entity) {
		return tile.getComponent(TileDataComponent).tinyId;
	}
	
	static markAsDeadWall(tile: Entity, isDead: boolean = true) {
		if (isDead && !tile.hasComponent(DeadWallTagComponent)) {
			tile.addComponent(DeadWallTagComponent);
			return true;
		}
		if (!isDead && tile.hasComponent(DeadWallTagComponent)) {
			tile.removeComponent(DeadWallTagComponent);
			return true;
		}
		return false;
	}
	
	static getTileNodes(tile: Entity) {
		return tile.getComponent(TileNodesComponent);
	}
	
	
	public static isLocked(tileEntity: Entity) {
		return tileEntity.hasComponent(LockedTileTagComponent);
	}
	
	public static lockTile(tileEntity: Entity) {
		if (!this.isLocked(tileEntity)) {
			tileEntity.addComponent(LockedTileTagComponent);
		}
	}
	
	public static unlockTile(tileEntity: Entity) {
		if (this.isLocked(tileEntity)) {
			tileEntity.removeComponent(LockedTileTagComponent);
		}
	}
}
