import {GuildDTO} from "./GuildDTO";

export class OnlineUserDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.OnlineUser"
	
	public UserId: number;
	public UserName: string;
	public UserRating: number;
	public UserBelt: number;
	public DefRatingId: number;
	public IsCash: boolean;
	public Country: string;
	public State: string;
	public Membership: string;
	public PS: string;
	
	public Guild: GuildDTO;
	
	public Mood: string;
	
}
