// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.TGamesInfoStore"

import {TournamentGameDTO} from "./TournamentGameDTO";

export class TGamesInfoStoreDTO {
	public StartSecLeft: number;
	public StateId: number;
	// List of TournamentGameDTO
	public Games: Array<TournamentGameDTO>;
	
	
}
