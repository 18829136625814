import {ErrorHandler, Injectable} from "@angular/core";

@Injectable()
export class AppErrorHandler implements ErrorHandler {
	
	handleError(error) {
		console.error("AppErrorHandler.handleError: " + error);
		if (error.stack) {
			console.error("AppErrorHandler.handleError.stack: " + error.stack);
		}
	}
	
}
