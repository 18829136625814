import {commander, ICommand} from "../commander/Commander";
import {Commands} from "./Commands";
import {LoggedInCommand} from "./LoggedInCommand";
import {GetInfoCommand} from "./GetInfoCommand";
import {LogoutCommand} from "./LogoutCommand";
import {JoinGameCommand} from "./JoinGameCommand";
import {EnterGameCommand} from "./EnterGameCommand";
import {ExitGameCommand} from "./ExitGameCommand";
import {RunGameCommand} from "./RunGameCommand";
import {ReleaseGameCommand} from "./ReleaseGameCommand";
import {SwitchLanguageCommand} from "./SwitchLanguageCommand";
import {PlayQuickAgainCommand} from "./PlayQuickAgainCommand";
import {NavigateToCommand} from "./NavigateToCommand";
import {BugReportCommand} from "./BugReportCommand";
import {UpdatePlayerPointsCommand} from "./UpdatePlayerPointsCommand";
import {UpdateOnlineStatusCommand} from "./UpdateOnlineStatusCommand";
import {NotEnoughCommand} from "./NotEnoughCommand";
import {SettingsCommand} from "./SettingsCommand";

export class RegisterCommands extends ICommand {
	
	async execute(params?: any): Promise<any> {
		commander.registerCommand(Commands.NAVIGATE_TO, NavigateToCommand);
		commander.registerCommand(Commands.LOGGED_IN, LoggedInCommand);
		commander.registerCommand(Commands.GET_INFO, GetInfoCommand);
		commander.registerCommand(Commands.LOGUOT, LogoutCommand);
		commander.registerCommand(Commands.JOIN_GAME, JoinGameCommand);
		commander.registerCommand(Commands.ENTER_GAME, EnterGameCommand);
		commander.registerCommand(Commands.RUN_GAME, RunGameCommand);
		commander.registerCommand(Commands.EXIT_GAME, ExitGameCommand);
		commander.registerCommand(Commands.RELEASE_GAME, ReleaseGameCommand);
		commander.registerCommand(Commands.SWITCH_LANGUAGE, SwitchLanguageCommand);
		commander.registerCommand(Commands.PLAY_QUICK_AGAIN, PlayQuickAgainCommand);
		commander.registerCommand(Commands.BUG_REPORT, BugReportCommand);
		commander.registerCommand(Commands.UPDATE_PLAYER_POINTS, UpdatePlayerPointsCommand);
		commander.registerCommand(Commands.UPDATE_ONLINE_STATUS, UpdateOnlineStatusCommand);
		commander.registerCommand(Commands.NOT_ENOUGH, NotEnoughCommand);
		commander.registerCommand(Commands.SETTINGS, SettingsCommand);
		return Promise.resolve({});
	}
	
}
