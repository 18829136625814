import {ActionMenuItemGraphics} from "../graphics/ActionMenuItemGraphics";
import {Entity} from "ecsy";
import {ActionMenuGuiComponent} from "./common/components";
import {ActionMenuWrapper} from "./wrappers/ActioMenuWrapper";

export class ActionMenuEntityHelper {
	
	public static getActionMenuEntityByGraphics(graphics: ActionMenuItemGraphics, actionMenu: Entity[]): Entity {
		return actionMenu.find(entity => entity.getComponent(ActionMenuGuiComponent).obj === graphics);
	}
	
	public static wrapActionMenuEntity(entity: Entity): ActionMenuWrapper {
		return new ActionMenuWrapper(entity);
	}
}
