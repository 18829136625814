<div class="results-box disable-selection" [ngClass]="'wind-'+wind">
    <div class="windContainer">
        <svg class="windBg " xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 61.93 53.16">
            <defs>
                <radialGradient id="radial-gradient1x-{{wind}}" cx="26.14" cy="17.28" r="35.95"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" id="c1"/>
                    <stop offset="1" id="c2"/>
                </radialGradient>
            </defs>
            <path id="p"
                  style="fill: url(#radial-gradient1x-{{wind}})"
                  d="M41.46,0H23.57A14.14,14.14,0,0,0,11.33,7.07L2.39,22.56C-.14,26.93-1,29.71,1.51,34.08l7.2,12A14.16,14.16,0,0,0,21,53.16H38.84a14.15,14.15,0,0,0,12.25-7.07L60,30.6a14.12,14.12,0,0,0,0-14.14l-7.2-12C50.31.08,46.51,0,41.46,0Z"
            />
        </svg>
        <svg class="windFg" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 57.07 52.29">
            <defs>
                <radialGradient id="radial-gradient22" cx="28.53" cy="26.14" fx="14.175302019553648" r="27.36"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fefcfd"/>
                    <stop offset="1" stop-color="#ecf0f3"/>
                </radialGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M19.59,52.29A10.71,10.71,0,0,1,10.36,47L1.42,31.47a10.71,10.71,0,0,1,0-10.65L10.36,5.33A10.69,10.69,0,0,1,19.59,0H37.48A10.66,10.66,0,0,1,46.7,5.33l8.95,15.49a10.71,10.71,0,0,1,0,10.65L46.7,47a10.68,10.68,0,0,1-9.22,5.33Z"
                          style="fill:url(#radial-gradient22)"/>
                </g>
            </g>
        </svg>

        <svg *ngIf="wind==='s'" name="s" class="wind" id="Layer_1" data-name="Layer 1"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 28.57 34.55"
             matTooltip="{{wind|uppercase}}">
            <path d="M24.5,18.11l.74-1.05-1.42-.43-1.11,1.48-1.17.12L22,14.59h4.44L23.7,13l-1.3.3.06-.3,4.45-3.64L22.09,2.5l-.18,1.42-2-1.61,1.55,3.52-1.42,8-3.15.68-1.23-.19.92.93L19.93,15l-.61,3.64L17.84,19l-1.92-2.78h-.18v-.12l-1,1.17-.06.06,1.54,2.23a64,64,0,0,0-6,2.53l-5-.25L8,24.21l2.22,6.42,1.66.19-.92-7.22a26.28,26.28,0,0,1,3-1.05v-.06a22,22,0,0,1,3.95-.74L18.76,23v.13l-4.69-.25L14,24.71l4.44.06v.93h-.06l-.06.55L13.89,26l-.25,1.79L18,27.67l-.49,3.15.24.06,2-1.79.37-1.36,4.87-.18.43-1.36H20.24v-.31h.06l.19-1.11h5.86l-.12-1.54-5.43-.06,1.11-1.48a22,22,0,0,1,4.5.74h.06c.62.12,1.22.26,1.79.43a16.11,16.11,0,0,1-3.33,10.42c-1.56.66-3.56.42-6-.74q4,3.7,7.34,2.9a42.62,42.62,0,0,0,5.86-13.38A9.91,9.91,0,0,0,24.5,18.11Zm-1.3-11,.74.19s.07.33.31.86a2.92,2.92,0,0,0,.25.5l-1.85,1.54ZM19.93,21.93l-.24-.24v-.07h.43Z"
                  transform="translate(-5.11 -1.75)"/>
        </svg>
        <svg *ngIf="wind==='e'" name="e" class="wind" id="Layer_1" data-name="Layer 1"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 26.96 33.27"
             matTooltip="{{wind|uppercase}}">
            <path d="M21.09,21.43l2.22.06,4.44-8.39a7.38,7.38,0,0,0-3.26-2.28,32.69,32.69,0,0,0-3.95-.13l.12-1.35L25,9.15a7.52,7.52,0,0,1-3.09-3.27Q20.66,8.77,21,4.4a8.64,8.64,0,0,0-3.09-2.65c-1,.78-1.4,1.44-1.23,2l1.79,1.36-.12,2.59L11.16,9.89l7.16-.37-.13,1.3a32,32,0,0,0-4.93.74l-2.84-.87,1.79,2.41,1,8.51,1.85.19a14.56,14.56,0,0,1-6,5.68L5.11,29.2l5.43.74,6.91-8.51-.37-.06h.74L17.88,32q-3.45-2.1-3.64-.92a5.21,5.21,0,0,1,3,4.38l2.4.8V21.43h.5l5.36,9.38A8.16,8.16,0,0,1,30,29.94a20.78,20.78,0,0,1,3.7.37A36.09,36.09,0,0,1,30,28.83,20.15,20.15,0,0,1,21.09,21.43ZM18,20.38h-2.9l-.13-2.59,3-.37ZM18,16l-3,.93-.74-3.71,4-.92Zm2.46-3.76a9.62,9.62,0,0,1,4.51.61l-1,2.41-3.7.37Zm-.24,4.93,3-.68L22,20.26H20.17Z"
                  transform="translate(-5.19 -2.31)"/>
        </svg>
        <svg *ngIf="wind==='n'" name="n" class="wind" id="Layer_1" data-name="Layer 1"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 22 31.24"
             matTooltip="{{wind|uppercase}}">
            <polygon points="2.49 22.96 5.38 20.88 0.81 16.83 0.69 16.89 2.66 21.98 2.49 22.96"/>
            <path d="M28,29.38q-.93,3.53-2.95-1.33l-.46-3.24a2.68,2.68,0,0,0-.06-.52l2.55-.87A1.72,1.72,0,0,0,27.76,21q-.41-1.62-2.43-.93l-.69,1.56-.35.52a5.94,5.94,0,0,1-.06-.87.5.5,0,0,1,0-.34h-.11c0-1.12.11-2.16.23-3.13.38-3.51,1.39-6.13,3-7.86.24-.23.47-.45.7-.64L23.71,3.76,22.73,8,21.17,7c-.08.07.13.65.63,1.73.12.31.25.66.41,1V10l.17,13.42q.75,7.86,1.85,8.9,8.79,4.29,6.48-1C29.44,29.71,28.53,29.07,28,29.38Z"
                  transform="translate(-9.19 -3.76)"/>
            <path d="M11.68,10.64l3,2L15,22.33v.23l.17,5.67-2.6,2.08-3.36-1.1,3.3,4.45,2.66-3.18L15.21,35l.52-5.26,5-5.9v-.12L16,27.53l1.39-11.86a4.77,4.77,0,0,1,3.41-4.22V11.1L14.11,6.94Z"
                  transform="translate(-9.19 -3.76)"/>
        </svg>
        <svg *ngIf="wind==='w'" name="w" class="wind" id="Layer_1" data-name="Layer 1"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 31.53 27.32"
             matTooltip="{{wind|uppercase}}">
            <polygon points="11.41 4.57 22.95 2.65 17.83 0 11.17 2.53 7.53 2.53 11.04 4.57 11.41 4.57"/>
            <path d="M29.74,16.44a5.06,5.06,0,0,0-1.23-.25c-2.88-.21-4.28-.39-4.19-.56,1-1.31,1.21-2.07.55-2.28L21.6,11.13l-3,.06c2.34,1.15,3.47,2.31,3.39,3.46l-.55,1.6a54.69,54.69,0,0,0-5.74.87l-3.4-1.92,1.18,2.47c-1.65.41-3.4.91-5.25,1.48l-5.3-2.28,3.51,5.06a16.64,16.64,0,0,0,2.66,9.44q4.8,3.51,10.85.31a4.9,4.9,0,0,1,6.42.49,2.74,2.74,0,0,0,2.41-.68c.33-.37.66-.76,1-1.17a20.33,20.33,0,0,0,4.69-9.94A6.29,6.29,0,0,0,29.74,16.44ZM13.33,31.31v-.13l.06.13Zm.13,0-.07-.13.13.13Zm.18-.07-.12-.06h.12Zm16.1-4.44a17.58,17.58,0,0,1-3.64,3.7l-2.59-.92c-.86-.08-1.64-.13-2.34-.13a3.18,3.18,0,0,0-1.24.31l.74-.12L19.19,30c-.41.12-.63.23-.67.31l-.93.25a14.67,14.67,0,0,1-3.83.61L12.65,31l.25.12L12,30.94c-.29-.09-.54-.17-.74-.25Q8,27.6,8.77,21.19l3-1.05,2.28-.5s0-.08.12-.12a9.27,9.27,0,0,1,.19,6.73l-.86,1.42c2.18-1.24,3.37-4,3.57-8.27.13-.37.23-.5.31-.37a8.63,8.63,0,0,1,2.9-.56c.87,0,1.18.82.93,2.59a6.89,6.89,0,0,0,.43,4.14c-1.73,1.48-3.08,2.69-4.07,3.64l6-2.78.68-.06a15.07,15.07,0,0,0,3.21-.43c.58-.45.78-.93.62-1.42s-.64-.7-1.54-.62a9.21,9.21,0,0,0-3.21.62,13.1,13.1,0,0,1,.49-6.23,18.34,18.34,0,0,1,5.92,1.85,8.26,8.26,0,0,1,2,1.23A9.32,9.32,0,0,1,29.74,26.8Z"
                  transform="translate(-2.91 -5.89)"/>
        </svg>
    </div>
    <div class="content">
        <div class="player truncate">{{player?.name}}
            <!--<p class="trunc">--><!--DragonGirl888 DragonGirl888 DragonGirl888-->
        </div>
        <div id="wave">
            <svg xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 90 13.6">
                <defs>
                    <linearGradient id="linear-gradient-w1-{{wind}}" x1="45" x2="45" y2="12.99"
                                    gradientUnits="userSpaceOnUse">
                        <stop id="w1c1" offset="0" stop-color="#000000" stop-opacity=".08"/>
                        <stop id="w1c2" offset="1" stop-color="#ff0000" stop-opacity=".08"/>
                    </linearGradient>
                </defs>
                <path d="M90,0C80.29,0,40.79,9.71,23.31,9.71S0,7.12,0,7.12A90.81,90.81,0,0,0,27.84,13C42.09,13.6,75.76,5.18,90,4.53Z"
                      style="fill:url(#linear-gradient-w1-{{wind}})"/>
                <path d="M90,7.12c-7.77,0-27.19,5.18-34.32,6.48,11,0,24.61-3.24,34.32-2.59Z"
                      style="fill:url(#linear-gradient-w1-{{wind}})"/>
            </svg>
        </div>
        <div id="combinations">
            <div 
              class="combRow" 
              *ngFor="let cmb of player?.combinations" 
              [matTooltip]="cmb.description">
              
              <!-- Display description and yellow chip emoji if "Winner Payout" is in the description -->
              <span id="name" class="truncate">
                {{ cmb.description }}
                <ng-container *ngIf="cmb.description.includes('Winner Payout')"> 💰 </ng-container> <!-- Emoji for yellow chip -->
              </span>
              
              <!-- Conditionally render points or hide if "Winner Payout" is in description -->
              <span id="points" *ngIf="!cmb.description.includes('Winner Payout')">
                {{ (cmb.points >= 100 && cmb.points < 1500 && cmb.points % 100 === 0)
                  ? ((cmb.points / 100) + ' Dbl' + (cmb.points >= 200 ? 's' : ''))
                  : cmb.points }}
              </span>
            </div>
          </div>
        <div id="wave2">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 53 6.95">
                <defs>
                    <linearGradient id="linear-gradient-90" x1="52.94" y1="1.09" x2="52.94" y2="1.13"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#ff0000" stop-opacity=".08"/>
                        <stop offset="1" stop-color="#000000" stop-opacity=".08"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-91" x1="26.44" y1="0" x2="26.44" y2="6.95"
                                    xlink:href="#linear-gradient-90"/>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M52.88,1.13l.12,0Z" style="fill:url(#linear-gradient-90)"/>
                        <path d="M29.69,3.68C22.38,3.68,11.22,2,0,0V4.21c9.89,1.66,19.21,3,25.16,2.7A91.15,91.15,0,0,0,52.88,1.13C52,1.48,45.79,3.68,29.69,3.68Z"
                              style="fill:url(#linear-gradient-91)"/>
                    </g>
                </g>
            </svg>
        </div>

        <div id="ptsBlock">
            <span [ngClass]="pointsChange > 0 ? 'pointsInc' : 'pointsDec'">{{pointsLabel}}</span>
            <!--<span id="pointsLabel">{{'Game.EndGame.Points'|translate|uppercase}}</span>-->
        </div>
        <p class="points">{{player?.totalPoints}}</p>
    </div>
    <div class="content-xs">
        <div class="playerContainer">
            <div class="player truncate">{{player?.name}}
                <!--<p class="trunc">--><!--DragonGirl888 DragonGirl888 DragonGirl888-->
            </div>
            <p class="points">{{player?.totalPoints}}</p>
        </div>

    </div>


</div>