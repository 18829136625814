import {
	GraphicsComponent,
	PlayerIdComponent,
	TableOptionsComponent,
	TilePositionComponent,
	TileRotationComponent
} from "../../common/components";
import {Entity, World} from "ecsy";
import {ReadyBarTagComponent} from "./ReadyBarTagComponent";
import {Object3D, Quaternion, Vector3} from "three";
import {PlayerWrapper} from "../../wrappers/PlayersArray";
import {ReadyBarDeclaredTagComponent} from "./ReadyBarDeclaredTagComponent";

export class ReadyBarWrapper {
	constructor(public entity: Entity) {
	}
	
	static wrap(entity) {
		return entity ? new ReadyBarWrapper(entity) : undefined;
	}
	
	static create(world: World, graphics: Object3D, player: PlayerWrapper) {
		return world.createEntity("ReadyBar")
			.addComponent(ReadyBarTagComponent)
			.addComponent(GraphicsComponent, {obj: graphics})
			.addComponent(TilePositionComponent)
			.addComponent(TileRotationComponent)
			.addComponent(PlayerIdComponent, {playerId: player.id});
	}
	
	private get options() {
		return this.entity.getComponent(TableOptionsComponent);
	}
	
	private get mutableOptions() {
		return this.entity.getMutableComponent(TableOptionsComponent);
	}
	
	public get graphicObject(): Object3D {
		return this.entity.getComponent(GraphicsComponent).obj;
	}
	
	public get playerId(): number {
		return this.entity.getComponent(PlayerIdComponent).playerId;
	}
	
	
	public get isDeclared(): boolean {
		return this.entity.hasComponent(ReadyBarDeclaredTagComponent);
	}
	
	public set isDeclared(value) {
		if (value === false && this.isDeclared) {
			this.entity.removeComponent(ReadyBarDeclaredTagComponent);
		}
		if (value === true) {
			this.entity.addComponent(ReadyBarDeclaredTagComponent);
		}
	}
	
	public get position(): Vector3 {
		return this.entity.getComponent(TilePositionComponent).position;
	}
	
	public set position(position) {
		this.entity.getMutableComponent(TilePositionComponent).position = position;
	}
	
	public get rotation(): Quaternion {
		return this.entity.getComponent(TileRotationComponent).rotation;
	}
	
	public set rotation(value) {
		this.entity.getMutableComponent(TileRotationComponent).rotation = value;
	}
	
	
}
