import {Component, HostBinding, Input, OnInit} from "@angular/core";
import {IDisplayPlayerInfoData} from "../../../../../../game/GameEventsPipe";
import {IMoveNowUpdatedParams} from "../../../../../../game/interfaces/IMoveNowUpdatedParams";

@Component({
	selector: "app-player-info-bar",
	template: `
       <span class="lbl">{{playerInfo?.name}} • {{playerInfo.realside.toString().toUpperCase()}} • {{playerInfo.points}}</span>
	`,
	styleUrls: ["./player-info-bar.component.scss"],
})
export class PlayerInfoBarComponent implements OnInit {
	@Input()
	playerInfo: IDisplayPlayerInfoData;
	
	@Input()
	moveNow: IMoveNowUpdatedParams;
	
	@HostBinding("class") get classes(): string {
		return "side-" + this.playerInfo?.location + " " + "realside-" + this.playerInfo?.realside;
	}
	
	// @HostBinding("class.realside") get realsideClass(): string {
	// 	return ;
	// }
	
	@HostBinding("class.selected") get selected() {
		return this.playerInfo.id === this.moveNow?.moveNowPlayerId;
	}
	
	constructor() {
	}
	
	ngOnInit(): void {
	}
	
}
