export class ServiceDescriptor {
	constructor(public ns: string, public name: string) {
	}
	
	public get uri(): string {
		return this.ns + "." + this.name;
	}
	
	public toString(): string {
		return this.uri;
	}
}