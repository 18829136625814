import {ActionMenuSystem} from "../ActionMenuSystem";
import {ActionMenuWrapper} from "../wrappers/ActioMenuWrapper";
import {DiscardsTilesHelper} from "../../helpers/DiscardsTilesHelper";
import {TileWrapper} from "../wrappers/TileWrapper";
import {PlayerKuikaeComponent} from "./components/PlayerKuikaeComponent";
import {RulesHelperJM} from "./RulesHelperJM";

export class ActionMenuJMSystem extends ActionMenuSystem {
	
	/*	As user click on Chow we check for Kuikae (see kuikaeCheck()).
		We can:
			- warn player and cancel the chow to avoid hand block
			- lock tile and allow to declare chow
			- regular chow declaration
		If there is a tile to lock, we attach to player PlayerKuikaeComponent with locking tileId
		On next MAKE_MOVE (it should be any CHOW*) we check the PlayerKuikaeComponent presence and if so we add LockTileAction to the regular flow (thus we lock the tile after disposing chow)
		On next FROM_CONCEALED_TO_SLOT, if player has PlayerKuikaeComponent we remove it and add UnlockTilesAction after the regular flow (thus we unlock right after user discards second tile)
	*/
	protected onActionMenuClick_Chow(actionMenuItem: ActionMenuWrapper): void {
		try {
			const slotTileW = TileWrapper.wrap(DiscardsTilesHelper.getSlotTile(this.allTilesQ));
			const kuikae = RulesHelperJM.kuikaeCheck(slotTileW.id, actionMenuItem.playerId, this.allTilesQ);
			if (!kuikae) {
				super.onActionMenuClick_Chow(actionMenuItem);
			}
			else if (kuikae.hasKuikae) {
				// WARN & BLOCK chow declaration
				console.log("ActionMenuJMSystem.onActionMenuClick_Chow: has Kuikae");
				this.showSnack("Kuikae - Chow blocked"); // "Game.Message.Kuikae"
			}
			else {
				// LOCK TILES: we should lock tile (by tileId) and allow to discard remained tile
				// WARN: if user re-joins the game - this tile won't be locked, as server reports it as regular
				console.log("ActionMenuJMSystem.onActionMenuClick_Chow: has Kuikae with lock tile: " + slotTileW.tinyId);
				this.playersQRA.getPlayerById(actionMenuItem.playerId)
					.entity.addComponent(PlayerKuikaeComponent, {lockTileTinyId: slotTileW.tinyId});
				super.onActionMenuClick_Chow(actionMenuItem);
			}
		}
		catch (e) {
			console.warn("ActionMenuJMSystem.onActionMenuClick_Chow: -- " + e);
		}
	}
	
}
