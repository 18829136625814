import {System} from "ecsy";
import {IExtSystem} from "./IExtSystem";
import {GameActionsChangedQuery, GameMessagesChangedQuery, GameStorageQuery, PlayersQuery} from "./queries";
import {PlayersArray} from "./wrappers/PlayersArray";
import {GameStorageWrapper} from "./wrappers/GameStorageWrapper";
import {GameMessageDTO} from "../../net/dto/GameMessageDTO";
import {GameMoveAction} from "./common/GameAction";
import {MoveType} from "../enums/MoveType";
import {GameEventsPipe, GameEventType} from "../GameEventsPipe";
import {GameStore} from "../../store/GameStore";
import {IGameSnapshotOptions} from "../interfaces/IGameSnapshotOptions";
import Timeout = NodeJS.Timeout;

export interface IMoveTimerUpdatedParams {
	secondsLeft: number;
	isMyTurn: boolean;
}

export class TimerSystem extends System implements IExtSystem {
	private gameEvents: GameEventsPipe;
	private gameStore: GameStore;
	
	private moveTimer: MoveTimer;
	private discardsCount: number;
	private moveTimeLimit: number;
	
	init(attributes) {
		this.gameEvents = attributes.gameEvents;
		this.gameStore = attributes.gameStore;
		
		this.moveTimer = new MoveTimer({
			onUpdated: (event => {
				const secondsLeft = event.secondsRemained;
				this.gameEvents.send(GameEventType.MoveTimer_Updated, {
					secondsLeft,
					isMyTurn: this.isMyTurn,
				} as IMoveTimerUpdatedParams);
				console.log(`TimerSystem.onTimerUpdate: [` + (secondsLeft === 1 ? this.isMyTurn : "no") + `] ${secondsLeft}`);
			})
		});
		this.discardsCount = 0;
		this.moveTimeLimit = this.gameStore.joinedGame?.TimeLimit ?? 0;
	}
	
	unregister(): void {
		this.gameEvents = null;
		this.moveTimer.stop();
		this.moveTimer = null;
	}
	
	execute(delta: number, time: number): void {
		// GameStorage.GameMessagesComponent changed: pending messages length
		if (this.queries.gameMessagesChanged.results.length > 0) {
			if (!this.gameStorageQW.gameMessages.justProcessed.isEmpty) {
				this.gameStorageQW.gameMessages.justProcessed.getAll()
					.forEach(message => this.processGameMessage(message));
			}
		}
		
		if (this.gameActionsQR.length > 0 && !this.gameStorageQW.gameActions.justProcessed.isEmpty) {
			const pending = this.gameStorageQW.gameActions.justProcessed.getAll();
			pending.filter(action => action instanceof GameMoveAction)
				.forEach((action: GameMoveAction) => this.processGameAction(action));
		}
	}
	
	dispose(): void {
		this.unregister();
	}
	
	/* ~ ~ ~ */
	private get isMyTurn(): boolean {
		return !this.gameStorageQW.turnState.waitingForNextMove
			&& (
				(this.gameStorageQW.moveNowState.moveNowType === "slot"
					&& this.gameStorageQW.moveNowState.moveNowPlayerId !== this.gameStorageQW.myPlayerId
					&& this.playersQRA.getPlayerById(this.gameStorageQW.myPlayerId).actions.length > 0
				)
				|| (this.gameStorageQW.moveNowState.moveNowType === "player" && this.gameStorageQW.moveNowState.moveNowPlayerId === this.gameStorageQW.myPlayerId)
			);
	}
	
	private get secondsPerMove(): { passed: number, total: number } {
		const gsTimer = this.gameStorageQW.timer;
		const currentTimestamp = new Date().getTime();
		return {passed: Math.trunc((currentTimestamp - gsTimer.timestamp) / 1000), total: gsTimer.timerSeconds};
	}
	
	/* ~ ~ ~ */
	private processGameMessage(gameMessage: GameMessageDTO): void {
		let seconds: number;
		switch (gameMessage.Type) {
			case MoveType.GAME_SNAPSHOT_OPTIONS:
				const options = JSON.parse(gameMessage.Message) as IGameSnapshotOptions;
				this.discardsCount = options.discardsCount;
				if (options.userIdMoveNow === 0) { // slot
					seconds = this.moveTimeLimit;
				}
				else if (options.userIdMoveNow === this.gameStorageQW.myPlayerId) { // player move, my move
					seconds = this.discardsCount === 0 ? (this.gameStore.joinedGame.PracticeLevel > 0 ? 3600 : 60) : this.moveTimeLimit;
				}
				break;
			case MoveType.FROM_SLOT_TO_DISCARDS:
				this.discardsCount++;
				break;
			
			case MoveType.START_DEAL:
				seconds = 60;
				break;
			case MoveType.CHARLESTONE_1_LEFT:
			case MoveType.CHARLESTONE_1_OPPOSITE:
			case MoveType.CHARLESTONE_1_RIGHT:
			case MoveType.CHARLESTONE_2_LEFT:
			case MoveType.CHARLESTONE_2_OPPOSITE:
			case MoveType.CHARLESTONE_2_RIGHT:
			case MoveType.CHARLESTONE_2_WAIT:
			case MoveType.CHARLESTONE_3_WAIT:
			case MoveType.CHARLESTONE_3_OPPOSITE:
				seconds = 60;
				break;
			case MoveType.CHARLESTONES_END:
				seconds = this.moveTimeLimit * 4;
				break;
			case MoveType.DEADHAND_CALL:
				seconds = this.moveTimeLimit;
				break;
			case MoveType.READY: // JM
			case MoveType.DEFINE_JOKER: // AM
			case MoveType.UNDEFINE_JOKER: // AM
			
			case MoveType.FROM_WALL_TO_CONCEALED:
			case MoveType.FROM_DEADWALL_TO_CONCEALED:
			case MoveType.FROM_SLOT_TO_MELDED:
				// case MoveType.FROM_SLOT_TO_CONCEALED: // ???
				seconds = this.discardsCount > 1 ? this.moveTimeLimit : this.moveTimeLimit * 2;
				break;
			case MoveType.END_DEAL:
				// stop timer
				seconds = 0;
				break;
		}
		if (seconds !== undefined) {
			console.log("TimerSystem.processGameMessage: Set Timer:" + gameMessage.Type + ":" + MoveType[gameMessage.Type]);
			this.gameStorageQW.timer.setTimer(seconds, new Date().getTime(), this.gameStorageQW.dealState.dealTurn);
			if (seconds === 0) {
				this.moveTimer.stop();
			}
		}
	}
	
	private processGameAction(action: GameMoveAction): void {
		switch (action.gameMessage.Type) {
			
			case MoveType.FROM_CONCEALED_TO_SLOT:
			case MoveType.FROM_SLOT_TO_MELDED:
			case MoveType.FROM_WALL_TO_CONCEALED:
			case MoveType.FROM_DEADWALL_TO_CONCEALED:
				console.log(`TimerSystem.ShowTimer: ${action.gameMessage.Id}:${MoveType[action.gameMessage.Type]} - ${this.secondsPerMove.passed} / ${this.secondsPerMove.total}`);
				// TODO: do not start timer if deal is finished
				this.moveTimer.start(this.secondsPerMove.total, this.secondsPerMove.passed);
				break;
		}
	}
	
	/* ~ ~ ~ */
	private get playersQRA() {
		return new PlayersArray().setPlayerEntities(this.queries.players.results);
	}
	
	private get gameStorageQW(): GameStorageWrapper {
		const gameStorage = this.queries.gameStorage.results[0];
		return gameStorage ? new GameStorageWrapper(gameStorage) : null;
	}
	
	private get gameActionsQR() {
		return this.queries.gameActionsChanged.results;
	}
	
}

TimerSystem.queries = {
	players: PlayersQuery,
	gameStorage: GameStorageQuery,
	gameMessagesChanged: GameMessagesChangedQuery,
	gameActionsChanged: GameActionsChangedQuery,
};

interface ITimerEvent {
	secondsRemained: number;
	seconds?: number;
}

type ITimerUpdated = (event: ITimerEvent) => void;

class MoveTimer {
	private readonly onUpdated: ITimerUpdated;
	private time: number;
	
	
	private sRemained: number;
	private int1s: Timeout;
	
	constructor({onUpdated}: { onUpdated: (event: ITimerEvent) => void }) {
		this.onUpdated = onUpdated;
	}
	
	/*
	private tween: gsap.core.Tween;
	start(seconds, passed) {
		// gsap.ticker.
		if (this.tween) {
			this.tween.kill();
		}
		this.tween = gsap.fromTo(this, {time: 5}, {time: 0, duration: 5, onUpdate: args => this.onUpdated({current: this.time})});
	}*/
	
	public start(total, passed): void {
		this.clear();
		this.sRemained = total - passed;
		if (this.sRemained > 0) {
			this.run();
		}
	}
	
	public stop(): void {
		this.clear();
	}
	
	private run(): void {
		this.clear();
		if (this.sRemained-- > 0) {
			this.int1s = setTimeout(() => this.run(), 1000);
			this.onUpdated({secondsRemained: this.sRemained});
		}
	}
	
	private clear() {
		if (this.int1s) {
			clearTimeout(this.int1s);
			// this.int1s.unref();
			this.int1s = null;
		}
		
	}
	
}
