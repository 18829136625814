import { Component, OnInit, OnDestroy,Renderer2, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UserStore } from '../../../../store/UserStore';
import { TYPES } from '../../../../inversify/inversify.types';
import container from '../../../../inversify/inversify.config';
import { ChatService } from '../../../../net/service/ChatService';
import { ChatMessageDTO } from 'src/net/dto/ChatMessageDTO';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  public newMessage: string = '';
  public chatMessages: ChatMessageDTO[] = [];
  private chatService: ChatService;
  private userStore: UserStore;
  private lastMessageId: number = 0;
  private isNewMessageSoundPlayed = false;
  public highlightChat: boolean = false;
  private highlightTimeout: any;
  public showMessages: boolean = true;
  private hideMessagesTimeout: any;
  newMessageReceived = false;
  showChat = true;

  @ViewChild('messagesContainer') private messagesContainer: ElementRef<HTMLElement>;

  constructor(private cd: ChangeDetectorRef, private renderer: Renderer2,) {
    this.chatService = container.get<ChatService>(TYPES.ChatService);
    this.userStore = container.get<UserStore>(TYPES.UserStore);
  }

  ngOnInit(): void {
    this.loadChatMessages();
    this.showChat = !this.isMobileDevice();
    this.refreshChat();
  }

  ngAfterViewInit(): void {
    // Additional logic after view init if necessary
  }

  ngOnDestroy(): void {
    // Cleanup if needed
  }

  private isMobileDevice(): boolean {
    // Check if the device is a mobile device based on window width or user agent
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) || window.innerWidth <= 768;
  }

  public onNewMessageReceived(): void {
    this.newMessageReceived = true;
    this.playNewMessageSound();
    // Optionally, reset the highlight after a certain period
    setTimeout(() => this.newMessageReceived = false, 5000);
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    }, 0);
  }

  private refreshChat(): void {
    // Call the method to load new messages
    this.loadChatMessages();
    // Set a timeout or interval as needed to refresh
    setTimeout(() => {
      this.refreshChat();
    }, 1000); // Refresh every 5 seconds, adjust the time as needed
  }

  public async loadChatMessages(): Promise<void> {
    try {
      const chatId = 1; // Adjust as needed
      const sessionKey = this.userStore.sessionKey;
  
      // Attempt to fetch chat messages
      const chatMessagesDTO = await this.chatService.chatGetMessages(sessionKey, chatId, 1);
  
      // Validate the response
      if (!chatMessagesDTO || !chatMessagesDTO.Public || !Array.isArray(chatMessagesDTO.Public)) {
        console.error('Invalid chatMessagesDTO', chatMessagesDTO);
        return;
      }
  
      const newMessages = chatMessagesDTO.Public.filter(msg => msg.Id > this.lastMessageId);
    
      if (newMessages.length > 0) {
        this.lastMessageId = Math.max(...newMessages.map(msg => msg.Id));
  
        // If new messages are found and the chat is not already visible, show the chat
        if (!this.showChat) {
          this.showChat = true;
        }
        this.playNewMessageSound();
        this.isNewMessageSoundPlayed = true;
    
        this.chatMessages = [...this.chatMessages, ...newMessages];
        this.updateViewportScale(1); // Reset the scale to default when chat opens
        this.cd.detectChanges();
        this.scrollToBottom();
        this.resetHideMessagesTimeout();
        
      }
    } catch (error) {
      console.error('Error loading chat messages:', error);
    }
  }

 
  public onInputFocus(): void {
    this.highlightChat = false;
  }

  // Method to play the new message sound
  private playNewMessageSound(): void {
    const audio = new Audio("assets/sounds/notification/new_message.mp3");
    this.highlightChat = true; // Highlight chat on new message
    clearTimeout(this.highlightTimeout); // Clear any existing timeout

    audio.play().then(() => {
      console.log("New message sound played.");
    }).catch(error => {
      console.error("Error playing sound:", error);
    });

    // Set a timeout to remove the highlight after 30 seconds
    this.highlightTimeout = setTimeout(() => {
      this.highlightChat = false;
      this.cd.markForCheck(); // Ensure change detection is triggered
    }, 30000); // 30 seconds
  }

  public sendNewMessage(): void {
    if (this.newMessage.trim()) {
      const messageToSend = this.newMessage;
      this.newMessage = ''; // Clear the message input right away
      this.showMessages = true; // Ensure messages are shown when sending a new one
      clearTimeout(this.hideMessagesTimeout); // Clear any existing timeout to hide messages
  
      this.chatService.postMessage(this.userStore.sessionKey, 1, messageToSend)
        .then(success => {
          if (success) {
            // Load new messages only after the view has been updated
            setTimeout(() => {
              this.loadChatMessages();
              this.resetHideMessagesTimeout(); // Start the timer to hide messages after 1 minute
            });
          } else {
            // Handle the case where the message was not sent successfully
            console.error('Message failed to send');
          }
        })
        .catch(error => {
          console.error('Error sending message:', error);
        });
  
      this.cd.markForCheck(); // Mark for check immediately after updating the model
      if (window.innerWidth <= 768) {
        this.showChat = false; // Close chat on mobile after sending a message
      }
    }
  }
  
  public resetHideMessagesTimeout(): void {
    // Clear any existing timeout
    clearTimeout(this.hideMessagesTimeout);
  
    // Set a new timeout to hide messages after 1 minute
    this.hideMessagesTimeout = setTimeout(() => {
      this.showMessages = false;
      this.cd.markForCheck(); // Ensure change detection is triggered to update the view
    }, 60000); // 60 seconds or 1 minute
  }
  
  toggleChat() {
    this.showChat = !this.showChat;
    if (!this.showChat) {
      // Additional actions when chat is closed, if any.
    }
    // Ensure UI updates reflect the change in chat visibility.
    this.cd.markForCheck();
  }

  private updateViewportScale(scale: number): void {
		const viewportMetaTag = this.renderer.selectRootElement('meta[name="viewport"]', true);
		this.renderer.setAttribute(
		  viewportMetaTag,
		  'content',
		  `width=device-width, initial-scale=${scale}, maximum-scale=${scale}, user-scalable=no`
		);
	  
		// Log the updated scale value to the console
		console.log(`Viewport scale updated to: ${scale}`);
	  }
  // Additional methods...
}