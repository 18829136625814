import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";
import {TournamentDTO} from "../dto/tour/TournamentDTO";
import {RegisteredPlayerDTO} from "../dto/tour/RegisteredPlayerDTO";
import {TournamentWinnerDTO} from "../dto/tour/TournamentWinnerDTO";
import {TournamentCategory} from "../../game/enums/TournamentCategory";


export class TournamentListService extends AbstractAMFService {
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.Tournament", TournamentDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.TWinner", TournamentWinnerDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.RegisteredPlayer", RegisteredPlayerDTO);
	}
	
	/** Returns tournaments list of selected type.
	 * @param	sessionKey -- session key
	 * @param	category	-- Type of tournament you'd like to recieve ( SitnGo = 0, Scheduled = 1, InPlay = 2, Completed = 3).
	 */
	public getPlaying(sessionKey: string, category: TournamentCategory): Promise<TournamentDTO[]> {
		return this.send(TournamentServices.getPlaying, [sessionKey, category]);
	}
	
	public getOfficialTourRounds(sessionKey: string, otId: number): Promise<TournamentDTO[]> {
		return this.send(TournamentServices.getOfficialTourRounds, [sessionKey, otId]);
	}
	
	public myTournaments(sessionKey: string): Promise<TournamentDTO[]> {
		return this.send(TournamentServices.myTournaments, [sessionKey]);
	}
	
	public getOfficialRegisteredPlayers(sessionKey: string, officialTourId: number): Promise<RegisteredPlayerDTO[]> {
		return this.send(TournamentServices.getOfficialRegisteredPlayers, [sessionKey, officialTourId]);
	}
	
}


export class TournamentServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.TournamentList";
	public static readonly getPlaying = new ServiceDescriptor(TournamentServices.NS, "GetPlaying");
	public static readonly getOfficialTourRounds = new ServiceDescriptor(TournamentServices.NS, "GetOfficialTourRounds");
	public static readonly myTournaments = new ServiceDescriptor(TournamentServices.NS, "MyTournaments");
	public static readonly getOfficialRegisteredPlayers = new ServiceDescriptor(TournamentServices.NS, "GetOfficialRegisteredPlayers");
	
}

interface ITournamentData {
	roomId?: number;
	tourId?: number;
	tourName?: string;
}
