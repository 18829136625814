import { Entity } from "ecsy";
import {
	PlayerIdComponent,
	TileDataComponent,
	TileNodesComponent,
	TilePositionComponent,
	TileRotationComponent,
	TileState,
	TileTypeComponent
} from "../ecs/common/components";
import * as THREE from "three";
import { Quaternion, Vector3 } from "three";
import { TilePositionTransform } from "../TilePositionTransform";
import { TileDimensions } from "../TileDimensions";
import { PlayersArray } from "../ecs/wrappers/PlayersArray";
import { TileType } from "../enums/TileType";
import { DiscardsTilesHelper } from "./DiscardsTilesHelper";
import { LocationTransform } from "./rules-helper";

type TileTransform = { tileEntity: Entity, newTilePos: Vector3, newTileRot: Quaternion };

export class DiscardsLayoutHelper {

	private static readonly tilesPerRowCommon = 14;
	private static readonly startPointCommon = new THREE.Vector3(-170, 0, -170);
	private static readonly tilesPerRowSeparate = 6;
	private static readonly startPointSeparate = new THREE.Vector3(-57, 0, 70);

	public static updateTilesPositions_Separate(allTiles: Array<Entity>, playerId: number, players: PlayersArray) {
		if (!allTiles?.length) {
			return;
		}
		const discards = DiscardsTilesHelper.getDiscardTiles(allTiles);
		const firstDiscard: Entity = discards.find(tile => tile.getComponent(TileNodesComponent).previous == null);
		if (!firstDiscard) {
			console.warn("there is discarded tilesTransform, but cannot find the first one!");
			return;
		}
		this.applyTileTransforms(
			this.getPlayerDiscardsTransforms(
				this.getPlayerDiscardsList(firstDiscard, playerId),
				this.startPointSeparate,
				this.tilesPerRowSeparate,
				players.getPlayerById(playerId).locationTransform
			)
		);
	}

	/**
	 * Updates tiles positions.
	 * @param allTiles -- array of all tiles
	 */
	public static updateTilesPositions_Common(allTiles: Array<Entity>) {
		if (!allTiles?.length) {
			return;
		}
		const discards = DiscardsTilesHelper.getDiscardTiles(allTiles);
		const firstDiscard: Entity = discards.find(tile => tile.getComponent(TileNodesComponent).previous == null);
		if (!firstDiscard) {
			console.warn("there is discarded tiles, but cannot find the first one!");
			return;
		}
		// Calculate tile transforms depending on tile index, as in common mode all discards are in single positions (no rotated tiles)
		const positionTile = (tileEntity: Entity, index: number) => {
			const tilePosAdj = TilePositionTransform.CONCEALED.OPENED;
			const rowIndex = Math.trunc(index / this.tilesPerRowCommon);
			const columnIndex = index % this.tilesPerRowCommon;
			const newPos = this.startPointCommon.clone()
				.add(tilePosAdj.position)
				.add(new Vector3(TileDimensions.TWG * columnIndex, 0, (rowIndex + 1) * TileDimensions.THG));
			const oldPos = tileEntity.getComponent(TilePositionComponent).position;
			if (!this.isPositionsEqual(newPos, oldPos)) {
				tileEntity.getMutableComponent(TilePositionComponent).position = newPos;
			}

			// TODO: add check if rotation has actually changed ?
			tileEntity.getMutableComponent(TileRotationComponent).rotation = tilePosAdj.rotation;

			const nodes = tileEntity.getComponent(TileNodesComponent);
			if (nodes.next) {
				positionTile(nodes.next, index + 1);
			}
			return true;
		};
		positionTile(firstDiscard, 0);
	}

	public static updateSlotTilePosition_Separate(allTiles: Array<Entity>, players: PlayersArray) {
		try {
			console.log('updateSlotTilePosition_Separate called with:', allTiles, players);

			if (!allTiles?.length) {
				console.log('No tiles provided. Returning...');
				return;
			}

			const discards = DiscardsTilesHelper.getDiscardTiles(allTiles);
			console.log('Discards:', discards);

			const slotTile = DiscardsTilesHelper.getSlotTile(allTiles);
			console.log('Slot tile:', slotTile);

			const firstDiscard: Entity = discards.find(tile => tile.getComponent(TileNodesComponent).previous == null) ?? slotTile;
			console.log('First discard:', firstDiscard);

			if (!(slotTile && firstDiscard)) {
				console.warn("There are discarded tiles, but cannot find the first one!");
				return;
			}

			const slotPlayerId = slotTile.getComponent(PlayerIdComponent).playerId;
			console.log('Slot player id:', slotPlayerId);

			const tileTransforms = this.getPlayerDiscardsTransforms(
				[...this.getPlayerDiscardsList(firstDiscard, slotPlayerId), slotTile],
				this.startPointSeparate,
				this.tilesPerRowSeparate,
				players.getPlayerById(slotPlayerId).locationTransform
			);
			console.log('Tile transforms:', tileTransforms);

			const slotTransforms = tileTransforms[tileTransforms.length - 1];
			console.log('Slot transforms:', slotTransforms);

			const newPos = slotTransforms.newTilePos;
			const oldPos = slotTile.getComponent(TilePositionComponent).position;

			console.log('New position:', newPos);
			console.log('Old position:', oldPos);

			if (!this.isPositionsEqual(newPos, oldPos)) {
				console.log('Positions are not equal. Updating position...');
				slotTile.getMutableComponent(TilePositionComponent).position = newPos;
			}

			// TODO: add check if rotation has actually changed ?
			slotTile.getMutableComponent(TileRotationComponent).rotation = slotTransforms.newTileRot;
			console.log('Updated tile rotation:', slotTile.getMutableComponent(TileRotationComponent).rotation);
		} catch (error) {
			console.error(`Error in updateSlotTilePosition_Separate: ${error.message}, Stack: ${error.stack}`);
			// handle the error, for example you might want to rollback some operation or notify the user, etc.
		}
	}

	public static updateSlotTilePosition_Common(allTiles: Array<Entity>) {
		console.log('updateSlotTilePosition_Common called with:', allTiles);
		// Check if 'allTiles' array is defined and log the result
		console.log("'allTiles' defined:", !!allTiles);

		// If 'allTiles' is defined, log its length
		if (allTiles) {
			console.log("'allTiles' length:", allTiles.length);

			// For additional debug, you can log all tiles inside the 'allTiles' array
			console.log("'allTiles' content:", allTiles);
		}
		if (!allTiles?.length) {
			console.warn('No tiles found, exiting function');
			return;
		}

		const discards = DiscardsTilesHelper.getDiscardTiles(allTiles);
		console.log('Discards:', discards);

		const slotTile = DiscardsTilesHelper.getSlotTile(allTiles);
		console.log('SlotTile:', slotTile);

		if (!slotTile) {
			console.warn('No slot tile found, exiting function');
			return;
		}

		const slotTileIndex = discards.length;
		console.log('SlotTile index:', slotTileIndex);

		const rowIndex = Math.trunc(slotTileIndex / this.tilesPerRowCommon);
		console.log('Row index:', rowIndex);

		const columnIndex = slotTileIndex % this.tilesPerRowCommon;
		console.log('Column index:', columnIndex);

		const tilePosAdj = TilePositionTransform.CONCEALED.OPENED;
		const newPos = this.startPointCommon.clone()
			.add(tilePosAdj.position)
			.add(new Vector3(TileDimensions.TWG * columnIndex + 10, 0, (rowIndex + 1) * TileDimensions.THG + 10));

		console.log('New position:', newPos);

		const oldPos = slotTile.getComponent(TilePositionComponent).position;
		console.log('Old position:', oldPos);

		if (!this.isPositionsEqual(newPos, oldPos)) {
			slotTile.getMutableComponent(TilePositionComponent).position = newPos;
			console.log('Updated position:', newPos);
		}

		slotTile.getMutableComponent(TileRotationComponent).rotation = tilePosAdj.rotation;
		console.log('Updated rotation:', tilePosAdj.rotation);
	}


	private static isPositionsEqual(p1: THREE.Vector3, p2: THREE.Vector3, delta: number = 1): boolean {
		return Math.abs(p1.x - p2.x) < delta
			&& Math.abs(p1.y - p2.y) < delta
			&& Math.abs(p1.z - p2.z) < delta;
	}

	/**
	 * Get array of discards (entities) by player.
	 * Note, that tiles in returned array are not in link (nodes) order (and not actually linked), but in insert order instead
	 * @param tileEntity -- starting tile to build tiles array. Usually it is first tile of discards. We go from this tile till the last tile (by tile nodes) collecting all tiles with the given playerId
	 * @param byPlayerId -- playerId to filter the tiles
	 * @param acc -- Optional. An array containing all the collected tiles. Will be returned as the result.
	 */
	private static getPlayerDiscardsList(tileEntity, byPlayerId, acc: Entity[] = []): Entity[] {
		if (tileEntity.getComponent(PlayerIdComponent).playerId === byPlayerId) {
			acc.push(tileEntity);
		}
		const nodes = tileEntity.getComponent(TileNodesComponent);
		if (nodes.next) {
			return this.getPlayerDiscardsList(nodes.next, byPlayerId, acc);
		}
		else {
			return acc;
		}
	}

	/**
	 * Calc tile position and transform based on index (and not link(nodes) order)
	 * @param tiles
	 * @param starPoint
	 * @param tilesPerRow
	 * @param lc
	 */
	private static getPlayerDiscardsTransforms(tiles: Entity[], starPoint: Vector3, tilesPerRow: number, lc: LocationTransform): TileTransform[] {
		return tiles.reduce<{ rowIndex: number, xPos: number, tilesTransform: TileTransform[] }>((acc, tileEntity, index) => {
			const tileState = tileEntity.getComponent(TileDataComponent).state;
			const tileType = tileEntity.getComponent(TileTypeComponent).value;
			// const tileState = Math.random() < .4 ? TileState.R_LEFT : TileState.NORMAL;
			const tilePosAdj = tileState === TileState.R_LEFT ? TilePositionTransform.CONCEALED.OPENED_LEFT : TilePositionTransform.CONCEALED.OPENED;
			const tileW = tileState === TileState.R_LEFT ? TileDimensions.THG : TileDimensions.TWG;
			const rowIndex = Math.trunc(index / tilesPerRow) > 3 ? 3 : Math.trunc(index / tilesPerRow);
			if (rowIndex !== acc.rowIndex) {
				acc.xPos = -tileW / 2;
			}
			acc.tilesTransform.push({
				tileEntity,
				newTilePos: starPoint.clone()
					.add(tilePosAdj.position)
					.add(new Vector3(acc.xPos + (tileType === TileType.SLOT ? 5 : 0), 0, (rowIndex + 1) * TileDimensions.THG + (tileType === TileType.SLOT ? 5 : 0)))
					.applyQuaternion(lc.quaternion),
				newTileRot: lc.quaternion.clone().multiply(tilePosAdj.rotation),
			});
			acc.xPos += tileW;
			acc.rowIndex = rowIndex;
			return acc;
		}, { rowIndex: -1, xPos: 0, tilesTransform: [] })
			.tilesTransform;
	}

	private static applyTileTransforms(tileTransforms: { tileEntity: Entity, newTilePos: Vector3, newTileRot: Quaternion }[]) {
		tileTransforms
			.map(tile => ({ ...tile, oldTilePos: tile.tileEntity.getComponent(TilePositionComponent).position }))
			.forEach(tile => {
				if (!this.isPositionsEqual(tile.newTilePos, tile.oldTilePos)) {
					tile.tileEntity.getMutableComponent(TilePositionComponent).position = tile.newTilePos;
				}
				// TODO: add check if rotation has actually changed ?
				tile.tileEntity.getMutableComponent(TileRotationComponent).rotation = tile.newTileRot;
			});
	}
}
