import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CurrencyType} from "../../../game/enums/CurrencyType";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: "app-not-enough-dialog",
	template: `
       <h1 mat-dialog-title>Oops!</h1>
       <div mat-dialog-content>
           <span [innerHTML]="messageHTML"></span>
           <br>
           <span>You need {{currencySign}}{{data.requiredAmount}} more</span>
       </div>
       <div mat-dialog-actions>
           <button mat-raised-button color="primary" (click)="onSubmit()">{{btnSubmitLabel}}</button>
           <button mat-raised-button mat-dialog-close cdk-focus-start>{{"Lobby.Dialog.NoChips.DoItLater"|translate}}</button>
       </div>
	`,
	styles: [`:host {
       user-select: none;
   }
	`
	]
})
export class NotEnoughDialogComponent {
	// "1314": "You do not have enough Golden Coins",
	// "1315": "You don't have enough money in your account",
	// "1316": "You don't have enough Dragon Chips",
	// "1703": "You don't have enough money in your account", // tour register?
	
	currencySign: string;
	messageHTML: string;
	btnSubmitLabel: string;
	
	constructor(
		public dialogRef: MatDialogRef<NotEnoughDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: NotEnoughData,
		translateService: TranslateService
	) {
		
		switch (data.currency) {
			case CurrencyType.Usd:
				this.currencySign = "$";
				this.messageHTML = translateService.instant("Lobby.Dialog.NoMoney.Text");
				this.btnSubmitLabel = translateService.instant("Lobby.Dialog.NoMoney.btnSubmit");
				break;
			case CurrencyType.GoldenCoin:
				this.currencySign = "";
				this.messageHTML = translateService.instant("Lobby.Dialog.NoCoins.Text");
				this.btnSubmitLabel = translateService.instant("Lobby.Dialog.NoCoins.btnSubmit");
				break;
			case CurrencyType.DragonChip:
				this.currencySign = "";
				this.messageHTML = translateService.instant("Lobby.Dialog.NoChips.DontHave");
				this.btnSubmitLabel = translateService.instant("Lobby.Dialog.NoChips.btnSubmit");
				break;
		}
	}
	
	onSubmit() {
		this.dialogRef.close(true);
	}
}

export type NotEnoughData = {
	currency: CurrencyType;
	fullAmount: number;
	availableAmount: number;
	requiredAmount: number;
};
