import {GuildDTO} from "./GuildDTO";

export class JackpotParticipantDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.Jackpot.Participant"
	
	public UserId: number;
	public UserName: string;
	public Score: number;
	public Guild: GuildDTO;
	
}

