export class ChatMessageDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.ChatMessage"
	
	public Id: number;
	public ChatId: number;
	public Colour: string;
	public UserId: number;
	public UserName: string;
	public Message: string;
	public PostTime: Date;
}
