import {OnlineGameDTO} from "../dto/OnlineGameDTO";
import {GameState} from "../../game/enums/GameState";
import {GameType} from "../../game/enums/GameType";

export const OnlineGameHelper = {
	
	isSchool(game: OnlineGameDTO) {
		return game?.PracticeLevel > 0;
	},
	isPractice: (game: OnlineGameDTO) => {
		return game?.Name === "Practice - Random Wall" ?? false;
	},
	isPrivate: (game: OnlineGameDTO) => {
		return game?.Name.substr(0, 3) === "PG-";
	},
	playersCount: (dto: OnlineGameDTO): number => {
		if (!dto) {
			return 0;
		}
		let cnt = 0;
		if (dto.SouthId !== 0) {
			cnt++;
		}
		if (dto.WestId !== 0) {
			cnt++;
		}
		if (dto.EastId !== 0) {
			cnt++;
		}
		if (dto.NorthId !== 0) {
			cnt++;
		}
		return cnt;
	},
	playersToStart: (dto: OnlineGameDTO): number => {
		if (!dto || dto.StateId >= GameState.PLAYING) {
			return 0;
		}
		switch (dto.GameTypeId) {
			case GameType.AS: // two players two bots
				return 2 - OnlineGameHelper.playersCount(dto); // TODO: how should be hidden bots counted?
				break;
			case GameType.SM: // three players
				return 3 - OnlineGameHelper.playersCount(dto);
				break;
			default:
				return 4 - OnlineGameHelper.playersCount(dto);
		}
	},
};
