import {TileState} from "../ecs/common/components";

export class TileDataHelper {
	static getTileState(tileId): TileState {
		return Math.trunc((tileId % 1000) / 100);
	}
	
	static setState(tileId: number, newState: TileState): number {
		return this.clearTileState(tileId) + newState * 100;
	}
	
	static getTinyId(tileId: number): number {
		return this.clearTileState(this.removeHighlightState(tileId));
	}
	
	static removeHighlightState(tileId): number {
		return (Math.trunc((tileId % 1000) / 100) === 2) ? tileId - 200 : tileId;
	}
	
	static clearTileState(tileId): number {
		return tileId - Math.trunc((tileId % 1000) / 100) * 100;
	}
	
}
