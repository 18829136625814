export enum Tiles {
	BAMBOO_2 = 12,
	CHARACTER_2 = 22,
	DOT_2 = 32,
	DRAGON_GREEN = 41,
	DRAGON_RED = 42,
	DRAGON_WHITE = 43,
	WIND_EAST = 51,
	WIND_SOUTH = 52,
	WIND_WEST = 53,
	WIND_NORTH = 54,
	FLOWER_ = 61,
	JOKER = 81,
}
