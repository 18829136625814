import {injectable} from "inversify";

interface IAppVersion {
	version: string;
	build: number;
	revision: number;
}

/**
 * Currently logged user data
 */
@injectable()
export class AppStore {
	
	private appVersion: IAppVersion;
	
	public setAppVersion(v: IAppVersion) {
		this.appVersion = v ?? {version: "0", build: 0, revision: 0};
	}
	
	public get version(): string {
		return this.appVersion.version + ` b${this.appVersion.build}.${this.appVersion.revision}`;
	}
}
