import {PointsDescriptionDTO} from "./PointsDescriptionDTO";
import {GameUserPointsDTO} from "./GameUserPointsDTO";

export class DealSnapshotDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.DealSnapshot"
	
	public PtsDescr: Array<PointsDescriptionDTO>;
	public TotalPoints: Array<GameUserPointsDTO>;
	public RoundWind: string;
	public Slot: number;
	public StateId: number;
	public CoffeeBreak: boolean;
}
