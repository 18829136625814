import {Component, OnDestroy, OnInit} from "@angular/core";
import {loglite} from "../utils/logger-ite/LoggerLite";
import {Router} from "@angular/router";
import {AppEventType} from "../game/AppEventsPipe";
import {AppEventsService} from "./services/AppEventsService";
import {environment} from "../environments/environment";
import container from "../inversify/inversify.config";
import {TYPES} from "../inversify/inversify.types";
import {TexturePack} from "../game/graphics/TexturePack";
import {AppStore} from "../store/AppStore";
import {ChatGamController} from "../game/ChatGamController";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {commander} from "../commander/Commander";
import {Commands} from "../commands/Commands";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
	title = "MahjongTime LT";
	version: string;
	g1visible = Math.random() > .5;
	private destroy$: Subject<boolean> = new Subject<boolean>();
	
	constructor(
		private router: Router, private appEventsService: AppEventsService,
		private snackBar: MatSnackBar,
	) {
		// need to inject appEventsService to run service handlers
		
		// commander.executeCommand(Commands.ENTER_GAME);
		this.appEventsService.send(AppEventType.SwitchLanguage);
		this.chatGamController.privateMessages$.pipe(takeUntil(this.destroy$)).subscribe(value => {
			
			console.log("AppComponent.privateMessages$: ", value);
			
			const sn = (name, param) => {
				switch (name) {
					case ServerNotifications.RELOAD_CHIPS:
					case ServerNotifications.RELOAD_ACCOUNT:
						commander.executeCommand(Commands.GET_INFO).catch();
						break;
				}
			};
			const tnr = (param: string) => {
				/*
				* //"tournr#$#{0}@$@{1}@$@{2}", Tour.RoomId, Tour.Id, GameId
					var vars:Array = params.split('@$@');
					
					newTourRoundData = null;
					newTourRoundData = new SendLVO();
					newTourRoundData.roomId = int(vars[0]);
					newTourRoundData.tourId = int(vars[1]);
					newTourRoundData.gameId = int(vars[2]);
					Alert.yesLabel = CoreSettings.Res.getValue('Lobby.MainTabs.Games.btnJoinGame');
					Alert.show(CoreSettings.Res.getValue('Lobby.Dialog.NextTourRound.Text')
										, CoreSettings.Res.getValue('Lobby.Dialog.NextTourRound.Caption'), Alert.YES | Alert.CANCEL, null, newTourRound_listener, null, Alert.YES);
					Alert.yesLabel = CoreSettings.Res.getValue('UI.Basic.YES');
				* */
				const [roomId, tourId, gameId] = param.split("@$@");
				this.snackBar.open("Invite to the tournament game: " + gameId);
			};
			
			value.forEach(pm => {
				console.log("AppComponent.privateMessage: " + pm.Message, pm);
				if (pm.FromUserId === 1) {
					const [x, ...y] = pm.Message.split("#$#");
					switch (x) {
						case "sysnotify":
							sn(y[0], y[1]);
							break;
						case "tournr": // "tournr#$#{0}@$@{1}@$@{2}", Tour.RoomId, Tour.Id, GameId
							tnr(y[0]);
							break;
					}
				}
			});
		});
	}
	
	ngOnInit(): void {
		loglite.info("App init");
		if (environment.production) {
			loglite.wrapConsole();
		}
		else {
			loglite.wrapConsole();
		}
		
		try {
			const ba = require("../build.args").buildArgs;
			const appStore = container.get<AppStore>(TYPES.AppStore);
			appStore.setAppVersion({version: ba.package.version, build: ba.tcProps.buildNumber, revision: ba.tcProps.revNumber});
			this.version = appStore.version;
		}
		catch (e) {
			console.warn("AppComponent.ngOnInit: cannot set app version");
		}
		
		const tp = container.get<TexturePack>(TYPES.TexturePack);
		tp.loadTextTexture();
		/*.then(
			() => commander.executeCommand(Commands.ENTER_GAME, {gameType: GameType.HK})
		);*/
		
	}
	
	ngOnDestroy(): void {
		console.warn("on destroy");
		this.destroy$.next(true);
		this.destroy$.complete();
	}
	
	private get chatGamController() {
		return container.get<ChatGamController>(TYPES.ChatGamController);
	}
}


enum ServerNotifications {
	RELOAD_CHIPS = "CHIP.BONUS",
	EMAIL_BONUS = "CHIP.BONUS.EmailVerificationBonus",
	RELOAD_ACCOUNT = "REL.ACCOUNT",
	WIN_JACKPOT = "WIN.JACKPOT",
	
	/*Notification: CROWN.UPG, Message: {previous crown}|{achieved crown}
	Notification: GAME.WIN, Message {game id}|{game name}
	Notification: GAME.DRAW, Message {game id}|{game name}
	Notification: GUILD.WIN, Message {guild id}|{guild name}
	Notification: GUILD.ACTIVE.PLAYER, Message {guild id}|{guild name}
	Notification: TOP.100, Message: {game type}|{previous rank}|{achieved rank}
	Game type: HongKong, ChineseOfficial, AmericanModern, JapaneseModern, EuropeanClassical, WorldSeries, Taiwanese
	Previous rank, if user just enters top 100 then will be 101.
	*/
	CROWN_UPG = "CROWN.UPG",
	GAME_WIN = "GAME.WIN",
	GAME_DRAW = "GAME.DRAW",
	GUILD_WIN = "GUILD.WIN",
	TOP_100 = "TOP.100",
	NEW_MEMBER_PROMO = "NEW.MEMBER.PROMO",
	OFFER_FULLVERSION = "OFFER.FULLVERSION",
	CHIP_BONUS_FRIENDS = "CHIP.BONUS.FRIENDS",
	
	EMAIL_UNVERIFIED = "EMAIL.UNVERIFIED",
	OFFER_INVITETOSTART = "OFFER.INVITETOSTART",
	OFFER_INVITETOSTART_OK = "OFFER.INVITETOSTART.OK",
	
	REFRESH_GAMETAB = "REFRESH.GAMETAB",
	GAME_AUTOSEAT = "GAME.REQUESTAUTOSEAT",
	
}
