import { AMFConnectionBroker, BrokerErrors, IBrokerError } from "./AMFConnectionBroker";
import { inject, injectable } from "inversify";
import { TYPES } from "../../inversify/inversify.types";
import { AppEventsPipe, AppEventType } from "../../game/AppEventsPipe";
import { ServiceError, ServiceErrors } from "../ServiceError";
import { ServiceDescriptor } from "../ServiceDescriptor";

@injectable()
export abstract class AbstractAMFService {

	constructor(
		@inject(TYPES.AMFConnectionBroker) protected conn: AMFConnectionBroker,
		@inject(TYPES.AppEventsPipe) protected appEvents: AppEventsPipe,
	) {
		this.registerClassAliases();
	}
	protected async send(target: ServiceDescriptor, data: Array<any>) {
		console.log(`AbstractAMFService.SEND: ${target}, data=`, data);
		const targetURI = target.uri;

		return this.conn.call(targetURI, data)
			.then(result => {
				// Log the raw result from the call
				console.log(`AbstractAMFService.CALL_SUCCESS: ${target}, result=`, result);
				console.log(`AbstractAMFService.RAW_RESULT: `, result);

				let se: ServiceError = null;
				if (result.Error) { // if server returned Error
					se = new ServiceError(result.Error.Id, result.Error.Message, target, data);
					console.error(`AbstractAMFService.SERVER_ERROR: `, se); // Log server error
					throw se;
				}

				// Log the successful result after error checking
				console.log(`AbstractAMFService.PROCESSED_RESULT: ${target}, data=`, result.Result);
				return result.Result;
			}, (err: IBrokerError) => { // handle connection or parsing errors
				console.error(`AbstractAMFService.BROKER_ERROR: ${target}, error=`, err); // Log broker error
				const se: ServiceError = new ServiceError(
					err.id === BrokerErrors.PARSING ? ServiceErrors.ERROR_PROCESSING : ServiceErrors.CONNECTION,
					err.message,
					target,
					data
				);
				throw se;
			})
			.catch(err => {
				console.error(`AbstractAMFService.CATCH_ERROR: ${target}, error=`, err); // Log error caught from the catch block
				const se = (err instanceof ServiceError) ? err : new ServiceError(ServiceErrors.UNKNOWN_ERROR, err, target, data);
				this.appEvents.send(AppEventType.ServiceError, se);
				throw se;
			});
	}

	protected registerClassAlias(name: string, class1: any) {
		this.conn.registerDTO(name, class1);
	}

	protected abstract registerClassAliases();
}

