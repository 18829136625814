import {Component} from "@angular/core";
import {NavigationService} from "../../services/navigation.service";
import {Router} from "@angular/router";
import {GameType} from "../../../game/enums/GameType";
import {environment} from "../../../environments/environment";
import {NavPage} from "../../enums/NavPage";
import container from "../../../inversify/inversify.config";
import {OnlineUserService} from "../../../net/service/OnlineUserService";
import {TYPES} from "../../../inversify/inversify.types";

type TypeInfo = { gameType: GameType, name: string, characteristics: string[], players?: number };

@Component({
	selector: "app-welcome-wiz",
	templateUrl: "./welcome-wiz.component.html",
	styleUrls: ["./welcome-wiz.component.scss"]
})
export class WelcomeWizComponent {
	
	TYPE_INFO: TypeInfo[] = [
		{
			gameType: GameType.WP, name: "Wright-Patterson", characteristics: [
				"Wright-Patterson Mah Jongg is a 144-tile, 4-player game. ",
				"Goal: create a 'Mahjong' hand from sets and a pair. )",
				"This variant doesn't use Jokers but include Flower tiles for bonus points.",
				"Only the winner is paid."				
			]
		},
	
	];
	
	public isDebug = environment.isDebug;
	
	constructor(private navigationService: NavigationService, private router: Router) {
		this.getGamesCount();
	}
	
	public selectGameType(type: GameType) {
		console.log(`WelcomeWizComponent.navigate: gt=${type}, ${GameType[type]}`);
		this.navigationService.navigate({
			page: NavPage.Lobby,
			gameType: type,
			
		});
	}
	
	private getGamesCount() {
		const ou = container.get<OnlineUserService>(TYPES.OnlineUserService);
		ou.getGameTypesInfo()
			.then(res => {
				res?.forEach(data => {
					const pl = this.TYPE_INFO.find(gm => gm.gameType === data.GameTypeId);
					if (pl) {
						pl.players = data.PlayersOnline;
					}
				});
				console.log("WelcomeWizComponent.games: ", res);
			})
			.catch();
	}
	
}

