import { Component, Input, OnInit } from "@angular/core";
import { OnlineGameDTO } from "../../../../../net/dto/OnlineGameDTO";
import { GameType } from "../../../../../game/enums/GameType";
import { GameWorld } from "../../../../../game/ecs/GameWorld";

@Component({
  selector: "app-table-info",
  templateUrl: "./table-info.component.html",
  styleUrls: ["./table-info.component.scss"]
})
export class TableInfoComponent implements OnInit {
  gameName: string;
  minPoints: number;
  dealNumber: number;
  roundWind: string;

  private _gameSettings: OnlineGameDTO;
  private _gameWorld: GameWorld;

  @Input()
  set gameSettings(value: OnlineGameDTO) {
    this._gameSettings = value;
    if (value) {
      this.updateGameSettings(value);
    }
  }

  @Input()
  set gameWorld(value: GameWorld) {
    this._gameWorld = value;
  }

  constructor() {}

  ngOnInit(): void {}

  private updateGameSettings(dto: OnlineGameDTO): void {
    const getDeclarePoints = (_dto: OnlineGameDTO): number | undefined => {
      return _dto.GameTypeId === GameType.WP || _dto.GameTypeId === GameType.AS ? undefined : _dto.DeclarePoints;
    };
    this.gameName = dto.Name;
    this.minPoints = getDeclarePoints(dto);
    this.roundWind = dto.RoundWind;
  }

  private updateGameWorld(gameWorld: GameWorld) {
    if (!gameWorld?.gameStorage?.dealState) {
      return;
    }
    try {
      this.dealNumber = gameWorld.gameStorage.dealState.dealNum;
    } catch (e) {
      console.error("TableInfoComponent.updateGameWorld: " + e);
    }
  }
}