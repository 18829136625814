import {Component, EventEmitter, Input, Output} from "@angular/core";
import {OnlineGameDTO} from "../../../../net/dto/OnlineGameDTO";
import {GameState} from "../../../../game/enums/GameState";

@Component({
	selector: "table-card-small",
	template: `
       <div class="game-card no-select" (click)="onAction()"><!--(click)="onAction('join')"-->
           <span class="mat-card-title">{{table.Name}}</span>
           <span class="material-icons icon action-icon"
                 (click)="onAction()">{{getTableActionIcon(table)}}</span>
       </div>`,
	styleUrls: ["./table-card-small.component.scss"]
})
export class TableCardSmallComponent {
	
	@Input()
	public table: OnlineGameDTO;
	
	@Output()
	actionEmitter = new EventEmitter<{ action: TableCardAction, table: OnlineGameDTO }>();
	
	constructor() {
	
	}
	
	public getStateClass(table: OnlineGameDTO): string {
		switch (table.StateId) {
			case GameState.EMPTY:
				return "stateArea";
			case GameState.WAITING:
			case GameState.READY_TO_START:
				return "stateAreaWelcome";
			case GameState.PLAYING:
				return "stateAreaInGame";
			default:
				return "stateArea";
			
		}
	}
	
	public getTableAction(table: OnlineGameDTO): TableCardAction {
		if (table.StateId < GameState.PLAYING) {
			return "join";
		}
		if ((table.StateId === GameState.PLAYING || table.StateId === GameState.PAUSED) && table.IsMyGame) {
			return "join";
		}
		if (table.StateId > GameState.PLAYING) {
			return "watch";
		}
		return "noAction";
	}
	
	public getTableActionIcon(table: OnlineGameDTO): string {
		const action = this.getTableAction(table);
		switch (action) {
			case "join":
				return "arrow_forward";
			case "watch":
			case "review":
				return "remove_red_eye";
			default:
				return "";
		}
	}
	
	public onAction(action?: TableCardAction) {
		this.actionEmitter.emit({action: this.getTableAction(this.table), table: this.table});
		
	}
	
}


export type TableCardAction = "join" | "watch" | "review" | "noAction";

