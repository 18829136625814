import {enableProdMode, ViewEncapsulation} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";

import "reflect-metadata"; // REQUIRED for InversifyJS to avoid "TypeError: Reflect.hasOwnMetadata is not a function" error

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, [{
	defaultEncapsulation: ViewEncapsulation.None
}]).catch(err => console.log(err));

export type int = number;
