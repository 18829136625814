export enum NavPage {
	Games = "games",
	GamesExpert = "gamesExpert",
	Wizard = "wizard",
	Root = "",
	Login = "login",
	Lobby = "lobby",
	Table = "table",
	TableInGame = "ingame",
	CreatePrivate = "create-private",
	EnterGame = "game",
	Tournaments = "tournaments",
}
