<meta name="viewport" content="width=device-width, initial-scale=1">

<app-player-info-bar
        *ngFor="let player of playerInfos"
        [playerInfo]="player"
        [moveNow]="gameEventsService.moveNow$|async"
>
</app-player-info-bar>

<div class="bottom-right-box" [style.bottom]="bottomRightBoxPosition">
    
    <!-- Apply the above-timer class to the app-chat component -->
    <app-chat ></app-chat>
    <app-mini-move-timer [moveTimer]="gameEventsService.moveTimer$|async"></app-mini-move-timer>
</div>

<div class="ttContainer"
     [ngStyle]="{'left.px': (appEventsService.tooltipPos$|async)?.point.x, 'top.px': (appEventsService.tooltipPos$|async)?.point.y }">
    <span [ngStyle]="{'opacity': (appEventsService.tooltip$|async)?.message ? 1 : 0}"
          class="ttText">{{(appEventsService.tooltip$|async)?.message}}</span>
</div>

<div class="containerX">
    <button mat-fab class="fab-secondary" color="secondary"
            matTooltip="{{'Game.Buttons.SortTiles'|translate}}"
            (click)="onSortTiles()">
        <mat-icon>sort_by_alpha</mat-icon>
    </button>
    <!--<button *ngIf="hasAutoPassOption" mat-mini-fab class="fab-secondary" color="secondary"
            matTooltip="{{autoPassMode.toolTip}}"
            (click)="onAutoPassChange()">
        <mat-icon>{{autoPassMode.icon}}</mat-icon>
    </button>
    -->
    <button mat-mini-fab class="fab-secondary" color="secondary"
            matTooltip="Turn Sounds ON/OFF"
            (click)="onSoundToggle()">
        <mat-icon>{{sounds.iconName}}</mat-icon>
    </button>
    <button mat-mini-fab class="fab-secondary" color="secondary"
            matTooltip="{{'Game.Buttons.LeaveGame'|translate}}"
            (click)="onLeaveGame()">
        <mat-icon>exit_to_app</mat-icon>
    </button>

    

</div>
<!--<div #actM style="width: 100px; height: 100px; background-color: #ffad2d; position: absolute;"
     [matMenuTriggerFor]="menu"></div>-->


<!--<button id="uiActionMenu" mat-icon-button [matMenuTriggerFor]="menu"

        [style.top.px]="actionMenu?.position.y"
        [style.left.px]="actionMenu?.position.x"
>
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" yPosition="above" overlapTrigger="false">
    <button mat-menu-item *ngFor="let item of actionMenu?.items">
        <mat-icon>dialpad</mat-icon>
        <span>{{item.label}}</span>
    </button>

    &lt;!&ndash;<button mat-menu-item>
        <mat-icon>dialpad</mat-icon>
        <span>Redial</span>
    </button>
    <button mat-menu-item disabled>
        <mat-icon>voicemail</mat-icon>
        <span>Check voice mail</span>
    </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon>
        <span>Disable alerts</span>
    </button>&ndash;&gt;
</mat-menu>-->


<div [matMenuTriggerFor]="contextmenu"
     style="visibility: hidden; position: fixed"
     [style.top.px]="actionMenu?.position.y"
     [style.left.px]="actionMenu?.position.x"
>

</div>
<mat-menu #contextmenu="matMenu" yPosition="above" overlapTrigger="false">
    <button mat-menu-item *ngFor="let item of actionMenu?.items" (click)="onActionMenuItemClick(item, actionMenu)">
        <mat-icon>dialpad</mat-icon>
        <span>{{item.label}}</span>
    </button>

    <!--<button mat-menu-item>
        <mat-icon>dialpad</mat-icon>
        <span>Redial</span>
    </button>
    <button mat-menu-item disabled>
        <mat-icon>voicemail</mat-icon>
        <span>Check voice mail</span>
    </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon>
        <span>Disable alerts</span>
    </button>-->
</mat-menu>