// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Core.Core.TourPlayerScoreResults"
export class TourPlayerScoreResultsDTO {
	public Index: number;
	public TourName: string;
	public StartDate: Date;
	public FinishDate: Date;
	
	public UserName: string;
	public GamePoints: number;
	public TourPoints: number;
	
	public Player2: string;
	public Player2GamePoints: number;
	public Player2TourPoints: number;
	
	public Player3: string;
	public Player3GamePoints: number;
	public Player3TourPoints: number;
	
	public Player4: string;
	public Player4GamePoints: number;
	public Player4TourPoints: number;
	
}
