import {PlayerServiceDTO} from "../dto/PlayerServiceDTO";
import {OnlineUserDTO} from "../dto/OnlineUserDTO";
import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";
import {OnlineGameTypesInfoDTO} from "../dto/OnlineGameTypesInfoDTO";

export class OnlineUserService extends AbstractAMFService {
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.PlayerService", PlayerServiceDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.OnlineUser", OnlineUserDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.OnlineGameTypesInfo", OnlineGameTypesInfoDTO);
	}
	
	public getAll(sessionKey: string, tabId: number): Promise<Array<OnlineUserDTO>> {
		return this.send(OnlineUserServices.GetAll,
			[sessionKey, tabId]);
	}
	
	public findUser(sessionKey: string, pattern: string): Promise<Array<OnlineUserDTO>> {
		return this.send(OnlineUserServices.Find,
			[sessionKey, pattern]);
	}
	
	public updateOnlineStatus(sessionKey: string, status: string): Promise<boolean> {
		return this.send(OnlineUserServices.UpdateOnlineStatus,
			[sessionKey, status]);
	}
	
	public inviteUser(sessionKey: string, gameTypeID: number, isCash: boolean, partnerID: number, excludedPtnID: string): Promise<Array<any>> {
		return this.send(OnlineUserServices.Invite,
			[sessionKey, gameTypeID, isCash, partnerID, excludedPtnID]);
	}
	
	public userOfferChoice(sessionKey: string, bonusId: number, roomId: number, gameId: number, userAnswer: string): Promise<boolean> {
		return this.send(OnlineUserServices.UserOfferChoice,
			[sessionKey, bonusId, roomId, gameId, userAnswer]);
	}
	
	public getPS(sessionKey: string): Promise<PlayerServiceDTO[]> {
		return this.send(OnlineUserServices.GetPs,
			[sessionKey]);
	}
	
	public getGameTypesInfo(): Promise<OnlineGameTypesInfoDTO[]> {
		return this.send(OnlineUserServices.GetGameTypesInfo,
			[]);
	}
	
}

export class OnlineUserServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.OnlineUser";
	public static readonly GetAll = new ServiceDescriptor(OnlineUserServices.NS, "GetAll");
	public static readonly Find = new ServiceDescriptor(OnlineUserServices.NS, "Find");
	public static readonly UpdateOnlineStatus = new ServiceDescriptor(OnlineUserServices.NS, "UpdateOnlineStatus");
	public static readonly Invite = new ServiceDescriptor(OnlineUserServices.NS, "Invite");
	public static readonly UserOfferChoice = new ServiceDescriptor(OnlineUserServices.NS, "UserOfferChoice");
	public static readonly GetPs = new ServiceDescriptor(OnlineUserServices.NS, "GetPs");
	public static readonly GetGameTypesInfo = new ServiceDescriptor(OnlineUserServices.NS, "GetGameTypesInfo");
}
