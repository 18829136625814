import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { EngineComponent } from "./engine/engine.component";
import { LoginComponent } from "./ui/login/login.component";
import { LobbyComponent } from "./ui/lobby/lobby.component";
import { GamesExpertComponent } from "./ui/lobby/gamesexpert/games-expert.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TableComponent } from "./ui/lobby/table/table.component";
import { WaitingComponent } from "./ui/lobby/table/waiting/waiting.component";
import { IngameComponent } from "./ui/lobby/table/ingame/ingame.component";
import { MaterialModule } from "./material.module";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatListModule } from "@angular/material/list";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { LeaveGameDialogComponent } from "./ui/lobby/table/ingame/LeaveGameDialogComponent";
import { EndGameDialogComponent } from "./ui/lobby/table/ingame/EndGameDialogComponent";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FabMenuComponent } from "./ui/components/fab-menu/fab-menu.component";
import { InGameDialogComponent } from "./ui/lobby/table/ingame/InGameDialogComponent";
import { gsap } from "gsap";
import { GameStatePipe } from "./pipes/GameStatePipe";
import { PlayerGameResultsComponent } from "./ui/components/PlayerGameResults/player-game-results.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { commander } from "../commander/Commander";
import { Commands } from "../commands/Commands";
import { RegisterCommands } from "../commands/RegisterCommands";
import { WelcomeWizComponent } from "./ui/welcome-wiz/welcome-wiz.component";
import { GamesComponent } from "./ui/lobby/games/games.component";
import { TableCardComponent } from "./ui/lobby/games/table-card.component";
import { MatTabsModule } from "@angular/material/tabs";
import { DebugHelperComponent } from "./ui/lobby/table/debug-helper/debug-helper.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { TableInfoComponent } from "./ui/lobby/table/table-info/table-info.component";
import { MiniMoveTimerComponent } from "./ui/lobby/table/ingame/mini-move-timer/mini-move-timer.component";
import { PlayerInfoBarComponent } from "./ui/lobby/table/ingame/player-info-bar/player-info-bar.component";
import { AppErrorHandler } from "./AppErrorHandler";
import { TableCardSmallComponent } from "./ui/lobby/games/table-card-small.component";
import { BugReportDialogComponent } from "./ui/dialogs/BugReportDialog";
import { JoinGameIdComponent } from "./ui/lobby/table/join-game-id/join-game-id.component";
import { CreatePrivateComponent } from "./ui/lobby/createprivate/create-private.component";
import { HelpTipsComponent } from "./ui/components/help-tips/help-tips.component";
import { TournamentsComponent } from "./ui/lobby/tournaments/tournaments.component";
import { NotEnoughDialogComponent } from "./ui/dialogs/not-enough-dialog.component";
import { MatTableModule } from "@angular/material/table";
// import { MaterialExtensionsModule, MaterialExtensionsExperimentalModule } from '@ng-matero/extensions';
import { MtxTooltipModule } from "@ng-matero/extensions/tooltip";
import { MaterialExtensionsExperimentalModule, MaterialExtensionsModule } from "@ng-matero/extensions";
import { CountDownComponent } from "./ui/components/count-down/count-down.component";
import { SettingsDialogComponent } from "./ui/dialogs/settings-dialog/settings-dialog.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { JackpotMessageComponent } from './ui/dialogs/jackpot-message/jackpot-message.component';
import { ChatComponent } from './ui/components/chat/chat.component';


// @ngx-translate
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
	// return new TranslateHttpLoader(httpClient, "mahjong.web/lite/assets/i18n/");
	// return new TranslateHttpLoader(httpClient, "assets/i18n/");
	// default path is "assets/i18n/" if no custom path is passed
	return new TranslateHttpLoader(httpClient, environment.i18n);
	// return new TranslateHttpLoader(httpClient);
}

@NgModule({
	declarations: [
		AppComponent,
		EngineComponent,
		LoginComponent,
		LobbyComponent,
		GamesExpertComponent,
		TableComponent,
		WaitingComponent,
		IngameComponent,
		EndGameDialogComponent,
		InGameDialogComponent,
		LeaveGameDialogComponent,
		BugReportDialogComponent,
		TableCardComponent,
		TableCardSmallComponent,
		FabMenuComponent,
		GameStatePipe,
		PlayerGameResultsComponent,
		WelcomeWizComponent,
		GamesComponent,
		DebugHelperComponent,
		TableInfoComponent,
		MiniMoveTimerComponent,
		PlayerInfoBarComponent,
		JoinGameIdComponent,
		CreatePrivateComponent,
		HelpTipsComponent,
		TournamentsComponent,
		NotEnoughDialogComponent,
		CountDownComponent,
		SettingsDialogComponent,
		JackpotMessageComponent,
		ChatComponent,
		GamesComponent,

	],
	imports: [
		BrowserModule,
		HttpClientModule, // @ngx-translate
		TranslateModule.forRoot({ // @ngx-translate
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AppRoutingModule,
		MaterialModule,
		BrowserAnimationsModule,
		MatInputModule,
		ReactiveFormsModule,
		MatListModule,
		MatButtonToggleModule,
		MatDialogModule,
		ContentLoaderModule,
		MatMenuModule,
		MatTooltipModule,
		MatTabsModule,
		MatExpansionModule,
		MatSelectModule,
		MatTableModule,
		MaterialExtensionsModule,
		MaterialExtensionsExperimentalModule,
		MtxTooltipModule,
		MatTableModule,
		MatSlideToggleModule,
		FormsModule,
	],
	providers: [
		{ provide: ErrorHandler, useClass: AppErrorHandler },

	],
	bootstrap: [
		AppComponent
	]
})

export class AppModule {
	constructor() {
		commander.registerCommand(Commands.REGISTER_COMMANDS, RegisterCommands);
		commander.executeCommand(Commands.REGISTER_COMMANDS);

		regPlugin();

	}
}

// Directional rotation is not built into the plugin.
const regPlugin = () => {
	// DirectionalRotationPlugin start
	gsap.registerPlugin({
		name: "directionalRotation",
		init(target, values) {
			if (typeof (values) !== "object") {
				values = { rotation: values };
			}
			var data = this,
				cap = values.useRadians ? Math.PI * 2 : 360,
				min = 1e-6,
				p, v, start, end, dif, split;
			data.endValues = {};
			data.target = target;
			for (p in values) {
				if (p !== "useRadians") {
					end = values[p];
					split = (end + "").split("_");
					v = split[0];
					start = parseFloat(target[p]);
					end = data.endValues[p] = (typeof (v) === "string" && v.charAt(1) === "=") ? start + parseInt(v.charAt(0) + "1", 10) * Number(v.substr(2)) : +v || 0;
					dif = end - start;
					if (split.length) {
						v = split.join("_");
						if (~v.indexOf("short")) {
							dif = dif % cap;
							if (dif !== dif % (cap / 2)) {
								dif = (dif < 0) ? dif + cap : dif - cap;
							}
						}
						if (v.indexOf("_cw") !== -1 && dif < 0) {
							dif = ((dif + cap * 1e10) % cap) - ((dif / cap) | 0) * cap;
						}
						else if (v.indexOf("ccw") !== -1 && dif > 0) {
							dif = ((dif - cap * 1e10) % cap) - ((dif / cap) | 0) * cap;
						}
					}
					if (dif > min || dif < -min) {
						data.add(target, p, start, start + dif);
						data._props.push(p);
					}
				}
			}
		},
		render(progress, data) {
			if (progress === 1) {
				for (let p in data.endValues) {
					data.target[p] = data.endValues[p];
				}
			}
			else {
				let pt = data._pt;
				while (pt) {
					pt.r(progress, pt.d);
					pt = pt._next;
				}
			}
		}
	});
	// DirectionalRotationPlugin end.
};