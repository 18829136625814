import {TileDescrDTO} from "./TileDescrDTO";
import {UserSeatDTO} from "./UserSeatDTO";

export class GameSnapshotDTO {
	// [amf.const.CLASS_ALIAS] = "Mahjong.GameServer.Service.Model.GameSnapshot"
	
	public RoomId: number;
	public GameId: number;
	public CurrentDeal: number;
	public DealDice: string;
	
	public DealStateId: number;
	public LastPeek: number;
	public PlayersTile: Array<TileDescrDTO>;
	public Players: Array<UserSeatDTO>;
	
	public PlayTimeSecLeft: number;
	public BetsTotalCount: number;
	public RcrCounterValue: number;
	public RenchanCounter: number;
	public PlayedDeals: number;
	public RoundWind: string;
	public SideMoveNow: string;
	public UserIdMoveNow: number;
	public CoffeeBreakSecLeft: number;
}
