import {ChatMessageDTO} from "../dto/ChatMessageDTO";
import {ChatMessagesDTO} from "../dto/ChatMessagesDTO";
import {ServiceDescriptor} from "../ServiceDescriptor";
import {AbstractAMFService} from "./AbstractAMFService";
import {PrivateChatMessageDTO} from "../dto/PrivateChatMessageDTO";


export class ChatService extends AbstractAMFService {
	
	protected registerClassAliases() {
		this.registerClassAlias("Mahjong.GameServer.Service.Model.ChatMessage", ChatMessageDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.PrivateChatMessage", PrivateChatMessageDTO);
		this.registerClassAlias("Mahjong.GameServer.Service.Model.ChatMessages", ChatMessagesDTO);
	}
	
	public join(sessionKey: string, chatId: number): Promise<number> {
		return this.send(ChatServices.Join,
			[sessionKey, chatId]);
	}
	
	public chatGetMessages(sessionKey: string, chatId: number, peak: number): Promise<ChatMessagesDTO> {
		return this.send(ChatServices.CGM,
			[sessionKey, chatId, peak]);
	}


	public postMessage(sessionKey: string, chatId: number, message: string): Promise<boolean> {
		return this.send(ChatServices.PostMessage,
			[sessionKey, chatId, message]);
	}	
	
	
	public postPrivateMessage(sessionKey: string, toUserID: number, message: string): Promise<boolean> {
		return this.send(ChatServices.PostPrivateMessage,
			[sessionKey, toUserID, message]);
	}
	
	public postVoiceMessage(sessionKey: string, chatId: number, message: string): Promise<boolean> {
		return this.send(ChatServices.PostVoiceMessage,
			[sessionKey, chatId, message]);
	}
}


export class ChatServices {
	private static NS = "Mahjong.GameServer.Web.FluorineService.Chat";
	public static readonly Join = new ServiceDescriptor(ChatServices.NS, "Join");
	public static readonly CGM = new ServiceDescriptor(ChatServices.NS, "Cgm");
	public static readonly PostMessage = new ServiceDescriptor(ChatServices.NS, "PostMessage");
	public static readonly PostPrivateMessage = new ServiceDescriptor(ChatServices.NS, "PostPrivateMessage");
	public static readonly PostVoiceMessage = new ServiceDescriptor(ChatServices.NS, "PostVoiceMessage");
}


