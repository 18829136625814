import {Entity, System} from "ecsy";
import {IExtSystem} from "./IExtSystem";
import {GameActionsChangedQuery, GameStorageQuery, playerAddedOrRemovedQuery, PlayersQuery} from "./queries";
import {PlayersArray} from "./wrappers/PlayersArray";
import {GameStorageWrapper} from "./wrappers/GameStorageWrapper";
import {ConcealedToSlotPrepare, GameMoveAction} from "./common/GameAction";
import {MoveType} from "../enums/MoveType";
import {GameEventsPipe} from "../GameEventsPipe";
import {GameStore} from "../../store/GameStore";
import {GameGraphics} from "../GameGraphics";
import {AudioListener, AudioLoader, Mesh, PositionalAudio} from "three";
import {PlayerIdComponent, PlayerSoundComponent, PlayerStoreComponent, PlaySoundComponent} from "./common/components";
import {Sound} from "../enums/Sound";
import {Gender} from "../enums/Gender";
import {ActionMoveType} from "../enums/ActionMoveType";
import {GameType} from "../enums/GameType";
import {UserStore} from "../../store/UserStore";

export class SoundsSystem extends System implements IExtSystem {
	private gameEvents: GameEventsPipe;
	private userStore: UserStore;
	private gameStore: GameStore;
	private gameGraphics: GameGraphics;
	
	private audioListenerRef: AudioListener;
	
	init(attributes) {
		this.gameEvents = attributes.gameEvents;
		this.userStore = attributes.userStore;
		this.gameStore = attributes.gameStore;
		this.gameGraphics = attributes.gameGraphics;
		
		this.addSoundListener();
	}
	
	unregister(): void {
		try {
			this.removeSoundListener();
			this.gameEvents = null;
		}
		catch (e) {
			console.error("SoundsSystem.unregister: " + e);
		}
	}
	
	execute(delta: number, time: number): void {
		// Create sound container for new player
		if (this.queries.playersChanged.added.length > 0) {
			try {
				this.queries.playersChanged.added.forEach(pl => {
					this.addSoundContainer(pl);
				});
			}
			catch (e) {
				console.error("SoundsSystem.execute: Error creating player sound container");
			}
		}
		// Remove sound container for player left the game
		if (this.queries.playersChanged.removed.length > 0) {
			try {
				this.queries.playersChanged.removed.forEach(pl => {
					this.removeSoundContainer(pl);
				});
			}
			catch (e) {
				console.error("SoundsSystem.execute: Error removing player sound container");
			}
		}
		
		if (this.gameActionsQR.length > 0 && !this.gameStorageQW.gameActions.justProcessed.isEmpty) {
			try {
				const pending = this.gameStorageQW.gameActions.justProcessed.getAll();
				if (pending.find(action => action instanceof ConcealedToSlotPrepare)) {
					this.playSound(Sound.GAME.TILE_SLOT, this.gameStorageQW.myPlayerId);
				}
				pending.filter(action => action instanceof GameMoveAction)
					.forEach((action: GameMoveAction) => this.processGameAction(action));
			}
			catch (e) {
				console.error("SoundsSystem.execute: Error processing game actions");
			}
		}
		
		
		if (this.queries.soundsToPlay.added.length > 0) {
			
			this.queries.soundsToPlay.added.forEach(entity => {
				const soundsON = this.userStore.settings.sndMaster;
				if (soundsON) {
					const playerId = entity.getComponent(PlaySoundComponent).playerId;
					const soundId = entity.getComponent(PlaySoundComponent).soundId;
					this.playSound(soundId, playerId ?? this.gameStorageQW.myPlayerId); // play from south location if no player id was provided
				}
				entity.remove();
			});
		}
	}
	
	/* ~ ~ ~ */
	private processGameAction(action: GameMoveAction): void {
		switch (action.gameMessage.Type) {
			
			case MoveType.FROM_WALL_TO_CONCEALED:
			case MoveType.FROM_DEADWALL_TO_CONCEALED:
			case MoveType.FROM_SLOT_TO_MELDED:
				this.playSound(Sound.GAME.TILE, action.gameMessage.UserId);
				break;
			case MoveType.FROM_CONCEALED_TO_SLOT:
				if (!this.gameStorageQW.dealState.gameSnapshotProcessing && action.gameMessage.UserId !== this.gameStorageQW.myPlayerId) {
					if (this.gameStorageQW.tableOptions.rulesSettings.gameType === GameType.WP) {
						this.playTileName(+action.gameMessage.Message, action.gameMessage.UserId);
					}
					else {
						this.playSound(Sound.GAME.TILE_SLOT, action.gameMessage.UserId);
					}
					
				}
				break;
			case MoveType.MAKE_MOVE:
				if (!this.gameStorageQW.dealState.gameSnapshotProcessing) {
					this.playCombinationName(action.gameMessage.Message as ActionMoveType, action.gameMessage.UserId);
				}
				/*else {
					console.log("SoundsSystem.processGameAction: snapshot processing in progress. skip: " + action.gameMessage.Message);
				}*/
				break;
			case MoveType.READY:
				this.playCombinationName(ActionMoveType.READY as ActionMoveType, action.gameMessage.UserId);
				break;
		}
	}
	
	/* ~ ~ ~ */
	private addSoundListener(): void {
		// create an AudioListener and add it to the camera
		this.audioListenerRef = new AudioListener();
		this.gameGraphics.camera.add(this.audioListenerRef);
		
	}
	
	private removeSoundListener(): void {
		if (this.audioListenerRef) {
			this.gameGraphics.camera.remove(this.audioListenerRef);
		}
	}
	
	private addSoundContainer(player: Entity): void {
		const positionalAudio = new PositionalAudio(this.audioListenerRef);
		positionalAudio.setRefDistance(350);
		
		const soundMesh: Mesh = new Mesh();
		soundMesh.add(positionalAudio);
		
		const playerTransform = player.getComponent(PlayerStoreComponent).locationTransform;
		soundMesh.position.set(0, 0, 500).applyQuaternion(playerTransform.quaternion);
		player.addComponent(PlayerSoundComponent, {soundMesh, positionalAudio});
		
		this.gameGraphics.scene.add(soundMesh);
	}
	
	private removeSoundContainer(player: Entity): void {
		const soundMesh = player.getComponent(PlayerSoundComponent)?.soundMesh;
		if (soundMesh) {
			this.gameGraphics.scene.remove(soundMesh);
			
			const positionalAudio = player.getComponent(PlayerSoundComponent)?.positionalAudio;
			if (positionalAudio) {
				soundMesh.remove(positionalAudio);
				positionalAudio.listener = null;
				// positionalAudio.disconnect();
			}
		}
		player.removeComponent(PlayerSoundComponent);
		this.gameGraphics.scene.remove(soundMesh);
	}
	
	private playCombinationName(move: ActionMoveType, playerId: number) {
		let moveName: string;
		switch (move) {
			case ActionMoveType.CHOW:
			case ActionMoveType.CHOW_1:
			case ActionMoveType.CHOW_2:
			case ActionMoveType.CHOW_3:
				moveName = "chow";
				break;
			case ActionMoveType.PUNG:
				moveName = "pung";
				break;
			case ActionMoveType.KONG:
			case ActionMoveType.KONG_1:
			case ActionMoveType.KONG_SELF:
			case ActionMoveType.KONG_SELF_PROMOTED:
			case ActionMoveType.KONG_SELF_CONCEALED:
				moveName = "kong";
				break;
			case ActionMoveType.QIUNT:
				moveName = "quint";
				break;
			case ActionMoveType.SEXTET:
				moveName = "sextet";
				break;
			case ActionMoveType.MAHJONG:
				moveName = "mahjong";
				break;
			case ActionMoveType.MAHJONG_SELF:
				moveName = "mahjong"; // mahjong_self_J
				break;
			case ActionMoveType.READY:
				moveName = "riichi_J";
				break;
			default:
				console.warn("SoundsSystem.playCombinationName: unknown combination: " + move);
		}
		const playerGender = this.getPlayerGender(playerId);
		if (moveName) {
			this.playSound(`voices/g${playerGender}/${moveName}.mp3`, playerId);
		}
	}
	
	/* ~ ~ ~ */
	private playSound(soundPath: string, playerId: number): void {
		try {
			if (!this.userStore.settings.sndMaster) {
				return; // Sounds are OFF
			}
			const playerSoundComponent = this.playersChangedResultsQ
				.find(pl => pl.getComponent(PlayerIdComponent)?.playerId === playerId)
				?.getComponent(PlayerSoundComponent);
			console.log(`SoundsSystem.playSound: ${playerId}, ${soundPath}`);
			// do not load sound if players audio is busy
			if (playerSoundComponent) {
				const loader = new AudioLoader();
				loader.loadAsync("assets/sounds/" + soundPath)
					.then(audioBuffer => {
						// TODO: better to use a pool of positional audio objects and add new on necessity
						const pa = new PositionalAudio(this.audioListenerRef).setRefDistance(500);
						playerSoundComponent.soundMesh.add(pa);
						// set the audio object buffer to the loaded object
						pa.setBuffer(audioBuffer);
						pa.play();
						pa.onEnded = () => {
							playerSoundComponent.soundMesh.remove(pa);
							pa.listener = null;
							// pa.disconnect();
						};
					})
					.catch(err => console.warn("SoundsSystem.playSound: " + err));
			}
		}
		catch (e) {
			console.error("SoundsSystem.playSound: " + e);
		}
	}
	
	private playTileName(tileId: number, playerId: number) {
		const tileTinyId = tileId % 100;
		if (tileTinyId < 10) { // skip closed tiles with tinyId=1
			return;
		}
		const playerGender = this.getPlayerGender(playerId);
		this.playSound(`voices/g${playerGender}/tile${tileTinyId}.mp3`, playerId);
	}
	
	/* ~ ~ ~ */
	
	/* ~ ~ ~ */
	private getPlayerGender(playerId: number): Gender {
		return this.playersChangedResultsQ
			.find(pl => pl.getComponent(PlayerIdComponent)?.playerId === playerId)
			?.getComponent(PlayerStoreComponent)?.gender ?? Gender.MALE;
	}
	
	/* ~ ~ ~ */
	private get playersQRA() {
		return new PlayersArray().setPlayerEntities(this.queries.players.results);
	}
	
	private get gameStorageQW(): GameStorageWrapper {
		const gameStorage = this.queries.gameStorage.results[0];
		return gameStorage ? new GameStorageWrapper(gameStorage) : null;
	}
	
	private get gameActionsQR() {
		return this.queries.gameActionsChanged.results;
	}
	
	private get playersChangedResultsQ() {
		return this.queries.playersChanged.results;
	}
	
	/*private get playersChangedAddedQ() {
		return this.queries.playersChanged.added;
	}*/
	/*private get playersChangedRemovedQ() {
		return this.queries.playersChanged.removed;
	}*/
	
}

SoundsSystem.queries = {
	gameStorage: GameStorageQuery,
	players: PlayersQuery,
	playersChanged: playerAddedOrRemovedQuery,
	gameActionsChanged: GameActionsChangedQuery,
	soundsToPlay: {
		components: [PlaySoundComponent], // we can query only one component as there is only one entity with this component (GameStorage)
		listen: {added: true, removed: false, changed: false}
	},
};
