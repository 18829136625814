import {NgModule} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSnackBarModule} from "@angular/material/snack-bar";

@NgModule({
	declarations: [],
	imports: [
		MatButtonModule,
		MatToolbarModule,
		MatIconModule,
		MatCardModule,
		MatCheckboxModule,
		MatSnackBarModule
	],
	exports: [
		MatButtonModule,
		MatToolbarModule,
		MatIconModule,
		MatCardModule,
		MatCheckboxModule,
		MatSnackBarModule
	],
	
})


export class MaterialModule {


}
