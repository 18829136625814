import {ActionMenuSystem} from "../ActionMenuSystem";
import {ActionMenuWrapper} from "../wrappers/ActioMenuWrapper";
import {DiscardsTilesHelper} from "../../helpers/DiscardsTilesHelper";
import {TileWrapper} from "../wrappers/TileWrapper";
import {Tiles} from "../../enums/Tiles";
import {TileSetHelper} from "../../helpers/TileSetHelper";
import {GameDialogType, IGameDialogButtonConfig, IGameDialogConfig} from "../../../app/ui/lobby/table/ingame/InGameDialogComponent";
import {InGameDialogWP} from "./GameRulesWPSystem";
import {ActionMoveType} from "../../enums/ActionMoveType";

export class ActionMenuWPSystem extends ActionMenuSystem {
	
	/*
	*/
	protected onActionMenuClick_Kong(actionMenuItem: ActionMenuWrapper): void {
		try {
			const slotTileW = TileWrapper.wrap(DiscardsTilesHelper.getSlotTile(this.allTilesQ));
			// if (actionMenuItem.declarationId !== ActionMoveType.KONG_SELF) {
			if (slotTileW?.tinyId === Tiles.BAMBOO_2 || slotTileW?.tinyId === Tiles.CHARACTER_2 || slotTileW?.tinyId === Tiles.DOT_2 || slotTileW?.tinyId === Tiles.DRAGON_WHITE) { // no slot tile (kong_self)
				const playerId = this.gameStorageQ.myPlayerId;
				const slotTileId = slotTileW.tinyId;
				const playerTiles = TileSetHelper.getPlayerConcealedTiles(playerId, this.allTilesQ);
				const jCount = TileSetHelper.getTilesCountWithId({tileTinyId: 81, tileSet: playerTiles});
				const stCount = TileSetHelper.getTilesCountWithId({tileTinyId: slotTileId, tileSet: playerTiles});
				if (stCount > 0 && jCount + stCount > 3) {
					const buttons: IGameDialogButtonConfig[] = [
						{id: ActionMoveType.KONG, label: "Kong", data: {tileId: slotTileId}},
						{id: ActionMoveType.KONG_1, label: "Kong with Jokers", data: {tileId: Tiles.JOKER}},
					];
					
					const dialogCfg: IGameDialogConfig = {
						id: InGameDialogWP.KongWithJokersDialog,
						type: GameDialogType.Regular,
						// title: "Multiple Choice Chow",
						titleResourceKey: "Enum.GameMove.kong",
						// message: "",
						// messageResourceKey: "Enum.GameMove.kong",
						buttons,
					};
					this.displayGameDialog(dialogCfg);
				}
				else {
					super.onActionMenuClick_Kong(actionMenuItem);
				}
			}
			else {
				super.onActionMenuClick_Kong(actionMenuItem);
			}
		}
		catch (e) {
			console.warn("ActionMenuJMSystem.onActionMenuClick_Chow: -- " + e);
		}
	}
	
}
